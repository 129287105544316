import MediaPostImage
    from "../../../../fan-page/media-section/media-gallery/media-post/media-post-content/media-post-image";
import MediaPostVideo
    from "../../../../fan-page/media-section/media-gallery/media-post/media-post-content/media-post-video";
import {CHAT_MEDIA_TYPES} from "../../../../../utilities/constants/chat";

const ChatMediaPost = ({
    className,
    size = '1920-1080',
    thumbSize = 128,
    type,
    ...rest
}) => {

    switch (type) {
        case CHAT_MEDIA_TYPES.IMAGE:
            return <MediaPostImage {...rest} size={size} />
        case CHAT_MEDIA_TYPES.VIDEO:
            return <MediaPostVideo {...rest} />
        default:
            return <></>
    }
}

export default ChatMediaPost
