import {ThemeProvider} from 'react-jss'
import {useDispatch, useSelector} from 'react-redux'
import {getAppTheme} from './theme'
import {selectTheme, setAlertMessage} from './store/slices/app'
import AppRoutes from './routes'
import SharedLayout from './containers/shared-layout'
import usePusher from './hooks/usePusher'
import {useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState} from 'react'
import {
    incrementNotificationsCounter, refreshChatToken,
    selectAccessToken, selectChatToken,
    selectUser,
} from './store/slices/user'
import PusherInstance from './services/pusher'
import {
    PRIVATE_CHANNEL_NAME,
    USER_EVENTS_NAMES,
} from './utilities/constants/notifications'
import {
    addNotification,
    setIsSubscribed,
} from './store/slices/notifications'
import {FAILURE} from './utilities/constants'
import PubNub from 'pubnub'
import {PubNubProvider} from 'pubnub-react'
import {
    addMessage,
    deleteMessage,
    increaseUnreadMessageCount,
    setUnreadMessageCounts
} from "./store/slices/messages";
import {pubnubConfig} from "./utilities/constants/chat";
import {
    selectIsChatLoaded,
    selectMyChannelLastReadTimetokens,
    setChannelPresence,
    setIsChatLoaded,
    setMyConversation
} from "./store/slices/conversations";
import {PubNubManager} from "./utilities/pubnubManager";
import {dateToPubnubTimetoken} from "./utilities/helpers";
import {httpFetchChatChannels, httpRefreshChatToken} from "./http-requests/messages";
import {models} from "./models";

const App = () => {
    const dispatch = useDispatch()
    const themeName = useSelector(selectTheme)
    const isAuthenticated = useSelector(selectAccessToken)
    const user = useSelector(selectUser)
    const myChannelLastReadTimetokens = useSelector(selectMyChannelLastReadTimetokens)
    const isChatLoaded = useSelector(selectIsChatLoaded)
    const chatToken = useSelector(selectChatToken)
    const listenerRef = useRef(null)

    const pusher = usePusher()

    const [pubnub, setPubnub] = useState({})
    const [isInitialUnreadCountFetched, setIsInitialUnreadCountFetched] = useState(false)
    const [islListenerUp, setIsListenerUp] = useState(false)


    /*useEffect(() => {
        httpRefreshChatToken()
    }, [])*/

    // Initialize pubnub object
    useLayoutEffect(() => {
        if (isAuthenticated) {
            if (user?.pubnub_id) {
                setPubnub(
                    new PubNub({
                        ...pubnubConfig,
                        uuid: user?.pubnub_id,
                    })
                )
            }
            if (!PusherInstance.getAuthHeaders())
                PusherInstance.setAuthHeaders(isAuthenticated)
            initNotifications()
        } else {
            setIsInitialUnreadCountFetched(false)
            leaveChat()
            pusher?.disconnect()
        }
    }, [isAuthenticated])


    const leaveChat = useCallback(() => {
        if (Object.keys(pubnub).length) {
            pubnub.unsubscribeAll()
        }
    }, [pubnub])


    // Set pubnub token and start chat
    useLayoutEffect(() => {
        if (Object.keys(pubnub).length && chatToken && islListenerUp) {
            pubnub.setToken(chatToken)
            initChat()
        }
    }, [pubnub, chatToken])


    // Load initial unread messages
    useEffect(
        () => {
            if (isChatLoaded && !!myChannelLastReadTimetokens.length && !isInitialUnreadCountFetched) {
                // It's actually the channel id (uuid)
                const channelsNames = []
                const channelsTimetokens = []
                myChannelLastReadTimetokens.forEach(({channel, lastReadTimetoken, createdAt}) => {
                    channelsNames.push(channel)
                    channelsTimetokens.push(lastReadTimetoken ?? dateToPubnubTimetoken(createdAt))
                })
                if (!!channelsNames?.length) {
                     pubnub.messageCounts({
                            channels: channelsNames,
                            channelTimetokens: channelsTimetokens,
                        },
                        (status, results) => {
                            console.log(status, results)
                            if (!results) return
                            dispatch(setUnreadMessageCounts(results.channels))
                            setIsInitialUnreadCountFetched(true)
                        }
                    )
                }
            }
        },
        [isChatLoaded, myChannelLastReadTimetokens, isInitialUnreadCountFetched]
    )


    const loadConversations = async () => {
        try {
            const {data: {data, current_page, last_page, total, per_page}} = await httpFetchChatChannels({
                page: 1,
                limit: 30
            })
            const myConversations = data.map(conversation => models.conversations({
                data: conversation,
                extra: {myID: user.id}
            }))
            dispatch(setMyConversation({data: myConversations, current_page, last_page, total, per_page}))
            dispatch(setIsChatLoaded(true))
        } catch (err) {
            console.log('[loadConversations]', err)
        }
    }

    useEffect(
        () => {
            if (Object.keys(pubnub).length) {
                const pubnubListener = {
                    message: function ({
                                           channel,
                                           message,
                                           userMetadata,
                                           publisher,
                                           timetoken,
                                       }) {
                        if (publisher !== user?.pubnub_id) {
                            dispatch(increaseUnreadMessageCount({channel}))
                        }
                        dispatch(
                            addMessage({
                                channel,
                                message,
                                timetoken,
                                meta: userMetadata,
                                uuid: publisher,
                            })
                        )
                    },
                    status: function (s) {
                        console.log('status', s)
                        if (s.statusCode === 403) {
                            dispatch(refreshChatToken())
                        }
                    },
                    messageAction: function (message) {
                        let action = message?.data
                        if (action?.type === 'delete_for_all') {
                            dispatch(deleteMessage({
                                timetoken: action?.messageTimetoken,
                                actions: {
                                    delete_for_all: {
                                        initiator_data: [{
                                            actionTimetoken: action?.messageTimetoken,
                                            uuid: action?.uuid,
                                        }],
                                    },
                                },
                            }))
                        } else if (action?.type === 'delete_for_me') {
                            dispatch(deleteMessage({
                                timetoken: action?.messageTimetoken,
                                actions: {
                                    delete_for_me: {
                                        initiator_data: [{
                                            actionTimetoken: action?.messageTimetoken,
                                            uuid: action?.uuid,
                                        }],
                                    },
                                },
                            }))
                        }
                    },
                    presence: function ({channel, occupancy}) {
                        dispatch(setChannelPresence({channel, occupancy}))
                    }
                }
                if (listenerRef.current) {
                    pubnub.removeListener(listenerRef.current)
                }
                pubnub.addListener(pubnubListener)
                listenerRef.current = pubnubListener
                setIsListenerUp(true)
                console.log('listeners up')
            }
        },
        [pubnub]
    )

    const initChat = async () => {
        try {
            if (Object.keys(pubnub).length && chatToken) {
                try {
                    const data = await PubNubManager.subscribeToChannel({
                        pubnub,
                        channelGroupName: user?.channels_groups[0]?.name,
                        withPresence: false
                    })
                    loadConversations()
                } catch (err) {
                    console.log('[subscribeToChannel]', err)
                }


            }
        } catch (e) {
            console.log('[initChat]', e)
        }
    }

    const initNotifications = useCallback(async () => {
        if (pusher) {
            const channel = pusher.subscribe(
                `${PRIVATE_CHANNEL_NAME}.${user.id}.notifications`
            )
            channel.bind('pusher:subscription_error', () => {
                dispatch(setIsSubscribed(false))
                dispatch(
                    setAlertMessage({
                        message: 'Cannot subscribe to notification channel',
                        variant: FAILURE,
                    })
                )
            })
            channel.bind('pusher:subscription_succeeded', () => {
                dispatch(setIsSubscribed(true))
            })
            channel.bind(USER_EVENTS_NAMES.userNotification, async (data) => {
                await dispatch(incrementNotificationsCounter())
                dispatch(addNotification(data[0]))
            })
        }
    }, [dispatch, pusher, user])

    return (
        <ThemeProvider theme={getAppTheme({name: themeName})}>
            <PubNubProvider client={pubnub}>
                <SharedLayout>
                    <AppRoutes/>
                </SharedLayout>
            </PubNubProvider>
        </ThemeProvider>
    )
}

export default App
