import { createUseStyles, useTheme } from 'react-jss'
import Box from '../../../../components/Box'
import cx from 'classnames'

const useStyles = createUseStyles((theme) => ({
    root: ({ discountAmount }) => ({
        minHeight: 56,
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing,
        color: theme.palette.action.inactive,
        padding: 0,
        justifyContent: 'center',
    }),
    label: {
        fontSize: 10,
        letterSpacing: 0.5,
        fontWeight: 500,
        padding: [0, theme.spacing * 2],
        marginTop: 8,
    },
    price: {
        ...theme.utils.flexbox.spaceBetween,
        padding: [4, theme.spacing * 2],
        '& p': {
            color: theme.palette.primary.background,
            fontWeight: 700,
        },
    },
    discount: {
        display: 'flex',
        flexDirection: 'row',
        maxHeight: 56,
        minHeight: 24,
        backgroundColor: theme.palette.primary.backgroundColor,
        borderBottomLeftRadius: 10,
        borderBottomRightRadius: 10,
        '& *': {
            display: 'flex',
            alignItems: 'center',
            fontSize: 10,
            padding: [0, 6],
            color: '#808080',
            textOverflow: 'ellipsis',
        },
        '& div': {
            backgroundColor: theme.palette.error.main,
            color: theme.palette.common.white,
            whiteSpace: 'nowrap',
            fontWeight: 700,
            letterSpacing: 0.25,
            borderBottomLeftRadius: 10,
        },
    },
    discountPrice: {
        color: theme.palette.primary.middle,
    },
    discountText: {
        textDecoration: 'line-through',
    },
}))

const currency = '$'

const MonthlyPriceBox = ({
    text,
    amount,
    label,
    date,
    discountAmount,
    isHappyTokenSelected,
    classname,
}) => {
    const classes = useStyles({ discountAmount })
    return (
        <Box classNames={cx(classes.root, classname)}>
            {label && <div className={classes.label}>{label}</div>}
            <div className={classes.price}>
                <p>{text}</p>
                <div>
                    {discountAmount ? (
                        <span className={classes.discountPrice}>
                            <span className={classes.discountText}>
                                {currency}
                                {amount}
                            </span>{' '}
                            / {currency}
                            {(amount - (amount / 100) * discountAmount)?.toFixed(
                                2
                            )}
                        </span>
                    ) : (
                        <span className={classes.discountPrice}>
                            {currency}
                            {amount}
                        </span>
                    )}
                </div>
            </div>
            {discountAmount && !isHappyTokenSelected && (
                <div className={classes.discount}>
                    <div>{discountAmount}% DISCOUNT</div>
                    <p>
                        Only if you sign up today {date} you will receive the
                        following discount
                    </p>
                </div>
            )}
        </Box>
    )
}

export default MonthlyPriceBox
