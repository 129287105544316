import { createUseStyles } from 'react-jss'
import { useFormContext } from 'react-hook-form'
import cx from 'classnames'
import { httpUpdateProfileHeader } from '../../../../http-requests'
import { useState } from 'react'
import { PROFILE_MODEL } from '../ProfileModel'
import LocalStorageManager from '../../../../utilities/localStorage'
import { selectUser, updateUser } from '../../../../store/slices/user'
import { useDispatch, useSelector } from 'react-redux'
import { handleApiError } from '../../../../utilities/helpers'
import colors from '../../../../theme/colors'
import Spinner from '../../../../components/Spinner'
import { CameraIcon } from '../../../../theme/icons'
import Camera from "../../../../asset/images/Camera.png";

const useStyles = createUseStyles((theme) => ({
    bannerPic: ({ banner, loading }) => ({
        '& label': {
            background: loading
                ? `linear-gradient(to top, #A00B4899, #ffffff99), url(${banner}) no-repeat top center`
                : banner
                ? `url("${banner}")`
                : colors.greyscale[600],
            backgroundSize: 'cover',
            backgroundPosition: 'center center',
            height: 200,
            width: '100%',
            display: banner ? 'inline-block' : 'grid',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
            position: 'relative',
            '& input': {
                display: 'none',
            },
            '&:hover': {
                background: colors.greyscale[400],
                backgroundSize: 'cover',
                '& .hoveredContent': {
                    display: 'block',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                },
            },
            '& .hoveredContent': {
                display: 'none',
                '& svg > path': {
                    stroke: colors.common.white,
                },
                '& svg > circle': {
                    stroke: colors.common.white,
                },
            },
        },
    }),
    textContainer: {
        display: 'flex',
        flexDirection: 'row',
        textAlign: "center",
        verticalAlign: "middle",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: colors.transparency.light["A05"],
        borderRadius: 100,
        '& p':{
            color: colors.transparency.light["A65"],
            padding: 18,
        },
        '& img': {
            width: 24,
            margin: [0, 0, 2, 18],
        },
        '& input': {
            display: 'none',
        },
    },
}))

const BannerImageUploader = ({ field, className }) => {
    const dispatch = useDispatch()
    const user = useSelector(selectUser)
    const [loading, setLoading] = useState(false)
    const {
        register,
        setValue,
        setError,
        formState: { touchedFields, errors },
    } = useFormContext() // retrieve all hook methods
    const classes = useStyles({
        banner: user?.header && user?.header['720'],
        loading,
    })

    const uploader = async (e) => {
        setLoading(true)
        try {
            const formData = new FormData()
            formData.append(field, e?.target?.files[0])
            const { data: user } = await httpUpdateProfileHeader(formData)
            //set field
            setValue(PROFILE_MODEL.header, { ...user?.header })
            //set value
            LocalStorageManager.user.set(user)
            dispatch(updateUser(user))
        } catch (error) {
            handleApiError({
                isReduxError: false,
                error,
                callbackOnFieldError: setError,
            })
        } finally {
            setLoading(false)
        }
    }

    return (
        <div className={cx(classes.bannerPic, className)}>
            <label htmlFor={'header'}>
                <div className={classes.textContainer}>
                {loading && <Spinner size={56} />}
                    {!user?.header && <img src={Camera} />}
                    {!user?.header && <p>  Click here to upload a banner image </p>}
                    </div>
                <input
                    type="file"
                    id="header"
                    onChange={uploader}
                    accept="image/jpg, image/png, image/jpeg, image/heif, image/heic"
                />
                <input type={'text'} {...register(field)} />
            </label>
        </div>
    )
}

export default BannerImageUploader
