import { createUseStyles } from 'react-jss'
import Divider from '../../../../components/Divider'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { WALLET_MODEL, walletStepThreeSchema } from '../WalletModel'
import Button from '../../../../components/Button'
import colors from '../../../../theme/colors'
import Select from '../../../../components/Select'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
    addCreatedWalletToList,
    selectCurrentWallet,
    selectTokens,
    setCurrentWallet,
    updateWallet,
} from '../../../../store/slices/wallet'
import { handleApiError, remapApiOptions } from '../../../../utilities/helpers'
import {
    httpAddWalletsNetworkToken,
    httpGetNetworksStandard,
} from '../../../../http-requests/wallet'
import { setAlertMessage } from '../../../../store/slices/app'
import { SUCCESS } from '../../../../utilities/constants'
import Spinner from '../../../../components/Spinner'
import UserAvatar from '../../../homepage/posts-feed/post/post-header/user-info/user-avatar'

const useStyles = createUseStyles((theme) => ({
    root: {
        padding: 16,
        '& p': {
            marginBottom: 16,
            '& b': {
                fontSize: 16,
                fontWeight: 700,
                color: theme.palette.secondary.dark,
            },
        },
    },
    fields: {
        display: 'grid',
        gap: 16,
        marginBottom: 24,
    },
    select: {
        '& > div': {
            padding: [4, 0],
        }
    },
    next: {
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        right: 16,
    },
    tokenLabel: {
        display: 'grid',
        alignItems: 'center',
        gridTemplateColumns: 'auto 1fr',
        gridColumnGap: 8,
    },
}))

const TokenStep = ({
    standards,
    onClose,
    isTokenStepEdit,
    isNetworkStepEdit,
    currentWalletNetworkIndex,
}) => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const tokens = useSelector(selectTokens)
    const currentWallet = useSelector(selectCurrentWallet)
    const [networkStandards, setNetworkStandards] = useState(standards)
    const [loading, setLoading] = useState(false)

    const {
        handleSubmit,
        setValue,
        control,
        formState: { errors, isSubmitting },
    } = useForm({
        shouldUnregister: true,
        mode: 'onBlur',
        reValidateMode: 'onChange',
        nativeValidation: false,
        defaultValues: {},
        resolver: yupResolver(walletStepThreeSchema),
    })

    useEffect(() => {
        if (!standards) {
            fetchNetworkStandards()
        }
    }, [])

    useEffect(() => {
        if (!!networkStandards?.length) {
            const newStandard =
                currentWallet?.wallet_networks[
                    currentWalletNetworkIndex ? currentWalletNetworkIndex : 0
                ]?.network_standard

            setValue(WALLET_MODEL.networkStandardId, {
                label: newStandard?.name,
                value: newStandard?.id,
            })
        }
    }, [networkStandards])

    const onSubmitHandler = async (values) => {
        setLoading(true)
        try {
            const { data } = await httpAddWalletsNetworkToken(
                currentWallet?.id,
                currentWallet?.wallet_networks[
                    currentWalletNetworkIndex ? currentWalletNetworkIndex : 0
                ]?.id,
                values?.[WALLET_MODEL.tokenId].value
            )
            const createdWallet = {
                ...currentWallet,
                wallet_networks: [{ ...data }],
            }
            dispatch(setCurrentWallet(createdWallet))
            if (isTokenStepEdit) {
                dispatch(updateWallet({ wallet: createdWallet }))
            } else {
                dispatch(addCreatedWalletToList(createdWallet))
            }
            dispatch(
                setAlertMessage({
                    message: isTokenStepEdit
                        ? 'Token successfully added!'
                        : isNetworkStepEdit
                        ? 'Network successfully added!'
                        : 'Wallet successfully added!',
                    variant: SUCCESS,
                })
            )
            onClose()
        } catch (error) {
            handleApiError({
                isReduxError: false,
                error,
            })
        } finally {
            setLoading(false)
        }
    }

    const fetchNetworkStandards = async () => {
        try {
            const { data: standards } = await httpGetNetworksStandard(
                currentWallet?.wallet_networks[
                    currentWalletNetworkIndex ? currentWalletNetworkIndex : 0
                ]?.network_id
            )
            setNetworkStandards(
                standards?.map((standard) => ({
                    label: standard?.name,
                    value: standard?.value,
                }))
            )
        } catch (error) {
            handleApiError({
                isReduxError: false,
                error,
            })
        }
    }

    const remapTokenApiOptions = (options) => {
        const tokenAlreadyAdded =
            currentWallet?.wallet_networks[
                currentWalletNetworkIndex ? currentWalletNetworkIndex : 0
            ]?.tokens
        const filteredTokens = options?.filter(
            ({ id: id1 }) =>
                !tokenAlreadyAdded.some(({ id: id2 }) => id2 === id1)
        )

        return filteredTokens?.map(({ id, name, avatar, ...rest }) => ({
            value: id,
            label: (
                <span className={classes.tokenLabel}>
                    <UserAvatar avatar={avatar?.[128]} width={24} height={24} />{' '}
                    {name}
                </span>
            ),
            //data: rest,
        }))
    }

    return (
        <div className={classes.root}>
            {loading && <Spinner />}
            {!isTokenStepEdit && (
                <p>
                    <b>STEP {!isNetworkStepEdit ? '3' : '2'}: </b>
                    Add a Token for configured Network. By default Happy token
                    is already added during wallet creation.
                </p>
            )}
            <form onSubmit={handleSubmit(onSubmitHandler)}>
                <div className={classes.fields}>
                    <Select
                        control={control}
                        label={'Token Name'}
                        placeholder={'Select a token'}
                        name={WALLET_MODEL.tokenId}
                        options={remapTokenApiOptions(tokens)}
                        errors={errors[WALLET_MODEL.tokenId]}
                        multiValueCustomStyle={multiValueCustomStyle}
                        multiValueLabelCustomStyle={multiValueLabelCustomStyle}
                        singleValueCustomStyle={multiValueLabelCustomStyle}
                        className={classes.select}
                    />
                </div>
                <div className={classes.fields}>
                    <Select
                        disabled={true}
                        control={control}
                        helpText={
                            'The standard interface implemented by the token'
                        }
                        label={'Token Standard'}
                        placeholder={'Add Token standard'}
                        name={WALLET_MODEL.networkStandardId}
                        options={remapApiOptions(networkStandards)}
                        errors={errors[WALLET_MODEL.networkStandardId]}
                        multiValueCustomStyle={multiValueCustomStyle}
                        multiValueLabelCustomStyle={multiValueLabelCustomStyle}
                        singleValueCustomStyle={multiValueLabelCustomStyle}
                        className={classes.select}
                    />
                </div>
                <Divider />
                <Button
                    disabled={isSubmitting}
                    width={'100%'}
                    type={'submit'}
                    variant={'filled'}
                >
                    {isTokenStepEdit
                        ? 'Add Token'
                        : isNetworkStepEdit
                        ? 'Create Network'
                        : 'Create Wallet'}
                </Button>
            </form>
        </div>
    )
}

const multiValueCustomStyle = {
    padding: '10px 4px',
    borderRadius: 8,
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'rgba(121, 65, 117, 0.05)',
}

const multiValueLabelCustomStyle = {
    color: '#2E2E2E',
    fontWeight: 400,
    fontSize: 16,
    letterSpacing: 0.5,
}

export default TokenStep
