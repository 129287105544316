import { createUseStyles } from 'react-jss'
import Button from '../../../../components/Button'
import { useDispatch } from 'react-redux'
import { setUnsubscribeModalOpen } from '../../../../store/slices/app'
const useStyles = createUseStyles((theme) => ({
    root: {
        boxShadow: 'inset 0px 1px 0px rgba(0, 0, 0, 0.1)',
        padding: theme.spacing * 2,
    },
    buttonStyle: {
        background: theme.palette.common.white,
        '&:hover': {
            background: theme.palette.primary.unsubscribeHover,
        },
    }
}))

const UnsubscribeButton = ({ user }) => {
    const dispatch = useDispatch()

    const classes = useStyles()
    return (
        <>
            <div className={classes.root}>
                <Button className={classes.buttonStyle}
                    width={'100%'}
                    variant={'ghost'}
                    onClick={() =>
                        dispatch(
                            setUnsubscribeModalOpen({ isOpen: true, user })
                        )
                    }
                >
                    Unsubscribe
                </Button>
            </div>
        </>
    )
}

export default UnsubscribeButton
