import { createUseStyles } from 'react-jss'
import React from 'react'
import colors from '../../../../../../../theme/colors'
import dayjs from 'dayjs'
import UserAvatar from '../../../../../../homepage/posts-feed/post/post-header/user-info/user-avatar'
import ChatContextMenu from '../../../chatContextMenu/ChatContextMenu'
import { useWindowSize } from '../../../../../../../hooks/useWindowSize'
import {useSelector} from "react-redux";
import {selectUser} from "../../../../../../../store/slices/user";
import {selectPresence} from "../../../../../../../store/slices/conversations";

const useStyles = createUseStyles((theme) => ({
    itemText: {
        display: 'flex',
        flexDirection: 'column',
        gap: 8,
    },
    date: {
        padding: [0, 50],
        fontSize: 10,
        fontWeight: 500,
        color: '#AEAEAE',
        letterSpacing: 0.25,
    },
    row: ({ isMine }) => ({
        display: 'flex',
        justifyContent: 'flex-end',
        flexDirection: isMine ? 'row' : 'row-reverse',
        gap: 8,
    }),
    chatBubble: ({ isMine }) => ({
        padding: 10,
        maxWidth: 261,
        background: isMine ? colors.secondary[500] : colors.primary[500],
        borderRadius: isMine ? [16, 16, 0, 16] : [16, 16, 16, 0],
        fontSize: 16,
        fontWeight: 400,
        letterSpacing: 0.75,
        color: '#ffffff',
        wordBreak: 'break-word',
    }),
    avatar: {
        placeSelf: 'flex-end',
    },
    image: {
        width: 100,
        height: 100,
        objectFit: 'cover',
    },
    divider: {
        height: 16,
        borderLeft: '1px solid #E1E1E1',
    },
    overlay: {
        position: 'fixed',
        left: 0,
        width: '100%',
        height: '150vh',
        zIndex: 1,
    },
}))

const ItemText = ({ message, userData, hasContextMenu = true }) => {
    const me = useSelector(selectUser)
    const isMine = message?.uuid === me?.pubnub_id
    const date = dayjs(message?.timetoken / 10000).format('h:mm A ')
    const { width } = useWindowSize()
    const isMobile = width <= 468
    const chatPresence = useSelector(selectPresence)
    const isOnline = chatPresence[userData?.channel]?.occupancy > 1

    const avatar = userData?.avatar?.[128]

    const classes = useStyles({ isMine })
    return (
        <div className={classes.itemText}>
            <div className={classes.row}>
                <div className={classes.date}>{date}</div>
            </div>
            <div className={classes.row}>
                {hasContextMenu && (
                    <ChatContextMenu
                        isMobile={isMobile}
                        message={message}
                        isMine={isMine}
                    />
                )}
                <div className={classes.chatBubble}>
                    {/*TODO CHAT implement correct map when B-E is ready*/}
                    {message?.message}
                </div>
                <div className={classes.avatar}>
                    <UserAvatar
                        avatar={avatar}
                        height={42}
                        width={42}
                        isOnline={isMine || isOnline}
                    />
                </div>
            </div>
        </div>
    )
}

export default ItemText
