import { createUseStyles } from 'react-jss'
import colors from '../../../theme/colors'
import Tabs from '../../../components/Tabs'
import { useEffect, useState } from 'react'
import RevenueAndSpent from './revenue-spent'
import MySubscriptions from './my-subscriptions'
import MyFans from './my-fans'
import { useSelector } from 'react-redux'
import { selectUser } from '../../../store/slices/user'

const useStyles = createUseStyles((theme) => ({
    sectionTitle: {
        fontWeight: 'bold',
        fontSize: '18px',
        lineHeight: '140%',
        color: colors.tertiary[800],
        marginBottom: 24,
    },
    topCard: {
        background: colors.common.white,
        boxShadow:
            '0px 1px 3px rgba(20, 20, 42, 0.1), 0px 0px 1px rgba(20, 20, 42, 0.05)',
        borderTopLeftRadius: 12,
        borderTopRightRadius: 12,
        position: 'relative',
    },
    tabLink: {
        fontSize: 14,
        [theme.mediaQueries.s]: {
            fontSize: 10,
            padding: [16, 8],
        },
    },
}))

const Payments = () => {
    const classes = useStyles()
    const user = useSelector(selectUser)
    const [activeTab, setActiveTab] = useState(0)
    const [tabTitles, setTabTitles] = useState([])

    useEffect(() => {
        if (user?.is_creator) {
            setTabTitles([
                'Revenue & spent',
                `MY SUBSCRIPTIONS (${user?.subscribed_to_count})`,
                `MY FANS (${user?.users_subscribed_count})`,
            ])
        } else {
            setTabTitles([
                'Spent',
                `MY SUBSCRIPTIONS (${user?.subscribed_to_count})`,
            ])
        }
    }, [user])

    return (
        <>
            <p className={classes.sectionTitle}>Payments</p>

            <Tabs
                activeTab={activeTab}
                setActiveCallback={setActiveTab}
                tabLinksClassName={classes.topCard}
                tabLinkClassName={classes.tabLink}
                tabLinkActiveClassName={classes.tabLink}
                titles={tabTitles}
            >
                <RevenueAndSpent />
                <MySubscriptions />
                {user?.is_creator && <MyFans />}
            </Tabs>
        </>
    )
}

export default Payments
