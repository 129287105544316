import { useCallback, useState } from 'react'
import { createUseStyles } from 'react-jss'
import Autolink from '../../../../../../components/Autolink'

const useStyles = createUseStyles((theme) => ({
    description: {
        padding: 16,
        color: theme.palette.grey[600],
        '& > p': {
            overflow: 'hidden',
            display: '-webkit-box',
            '-webkit-line-clamp': ({ showMore }) => (showMore ? 'unset' : 3),
            '-webkit-box-orient': 'vertical',
            margin: 0,
            whiteSpace: 'pre-line',
            wordBreak: 'break-word',
        },
        '& span': {
            cursor: 'pointer',
            fontWeight: 700,
            fontSize: 14,
        },
    },
}))

const PostDescription = ({ text }) => {
    const [showMoreVisible, setShowMoreVisible] = useState(false)
    const [showMore, setShowMore] = useState(false)

    const classes = useStyles({ showMore })

    const paragraphRef = useCallback((node) => {
        if (node !== null) {
            if (
                node.offsetHeight < node.scrollHeight ||
                node.offsetWidth < node.scrollWidth
            ) {
                setShowMoreVisible(true)
            } else {
            }
        }
    }, [])

    return text ? (
        <div className={classes.description}>
            <p ref={paragraphRef}>
                <Autolink text={text} />
            </p>
            {showMoreVisible && (
                <span onClick={() => setShowMore(!showMore)}>
                    {!showMore ? 'Read more' : 'Hide'}
                </span>
            )}
        </div>
    ) : null
}

export default PostDescription
