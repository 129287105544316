import * as yup from 'yup'

const SUBSCRIPTION_MODEL = {
    token: 'token',
}

const subscriptionValidationSchema = yup.object().shape({
    [SUBSCRIPTION_MODEL.token]: yup
        .object()
        .required('You must choose a token to pay with')
        .typeError('Please select a valid token'),
})

export { SUBSCRIPTION_MODEL, subscriptionValidationSchema as validationSchema }
