import React, {useEffect, useState, useMemo} from "react";
import {createUseStyles} from "react-jss";
import {handleApiError} from "../../../../utilities/helpers";
import {useForm, useWatch} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {SUBSCRIPTION_BUNDLE, validationSchema} from "./BundleFormModel";
import Select from "../../../../components/Select";
import Divider from "../../../../components/Divider";
import Button from "../../../../components/Button";
import {
    httpGetDiscountLabels,
    httpGetDurationLabels,
    httpStoreBundles
} from "../../../../http-requests/subscription";
import Spinner from "../../../../components/Spinner";

const useStyles = createUseStyles((theme) => ({

    priceHint: {
        padding: theme.spacing,
        fontSize: 12,
        color: theme.palette.label.color,
        letterSpacing: 0.5,
    },

    divider: {
        padding: theme.spacing,
        '& div': {
            margin: 0,
        }
    },

    wrapperSelect: {
        ...theme.utils.grid.start,
        gridTemplateColumns: '1fr ',
        gridTemplateRows: 'auto auto',
        gap: theme.spacing * 2,
        width: '100%',
        padding: 0,
        [theme.mediaQueries.mUp]: {
            ...theme.utils.grid.start,
            gridTemplateColumns: '1fr 1fr',
            gridTemplateRows: '1fr',
            gap: theme.spacing * 2,
            paddingBottom: theme.spacing,
            paddingTop: theme.spacing
        },
    },

    select: {
        minWidth: '100%',
        minHeight: '100%'
    },

    containerButton: {
        ...theme.utils.grid.start,
        gridTemplateColumns: '1fr 1fr',
        gridTemplateRows: 'auto',
        marginTop: theme.spacing,
        marginBottom: theme.spacing * 3,
        width: '100%',
        padding: 0,
        gap: theme.spacing * 2,
        [theme.mediaQueries.mUp]: {
            ...theme.utils.grid.start,
            gridTemplateColumns: '1fr 1fr',
            gridTemplateRows: 'auto auto',
            gap: theme.spacing * 2,
            marginBottom: theme.spacing,
        },
    },

    button: {
        paddingBottom: theme.spacing * 2,
    },
}))

const BundleForm = ({
                        setIsOpenBundle,
                        isOpenBundle,
                        setIsLoading,
                        isLoading,
                        setBundlesList,
                        bundlesList,
                        inputPriceMonthlyPlan,
                        ...props
                    }) => {

    const [durationLabel, setDurationLabel] = useState([]);
    const [discountLabel, setDiscountLabel] = useState([]);

    const formMethods = useForm({
        shouldUnregister: false,
        mode: 'all',
        reValidateMode: 'all',
        nativeValidation: false,
        defaultValues: {},
        resolver: yupResolver(validationSchema),
    })
    const {
        control,
        handleSubmit,
        setError,
        reset,
        formState: { isValid },
    } = formMethods

    const showDiscount = useWatch({
        control,
        name: SUBSCRIPTION_BUNDLE.discount,
    })

    const showDuration = useWatch({
        control,
        name: SUBSCRIPTION_BUNDLE.duration
    })

    useEffect(() => {
        getSelectLabels()
    }, []);

    const getSelectLabels = async () => {
        setIsLoading(true)
        try {
            const {data: durationData} = await httpGetDurationLabels()
            const {data: discountData} = await httpGetDiscountLabels()

            setDiscountLabel(discountData.map(({value, label}) => ({
                value: value,
                label: label.replace("_percent", "% discount")
            })))

            setDurationLabel(durationData.map(({value, label}) => ({
                value: value,
                label: label.replace("_", " ")
            })))

            setIsLoading(false)
        } catch (error) {
            handleApiError(
                {
                    isReduxError: false,
                    error,
                    callbackOnFieldError: setError,
                });
        } finally {
            setIsLoading(false)
        }
    }

    const storeBundle = async (formData) => {
        setIsLoading(true)
        try {
            const {data} = await httpStoreBundles({
                discount_percentage: formData.discount.value,
                duration_in_months: formData.duration.value
            })
            setBundlesList((prevBundleList) =>  [...prevBundleList, data])
            reset({[SUBSCRIPTION_BUNDLE.duration] : " " , [SUBSCRIPTION_BUNDLE.discount]: " "})
        } catch (error) {
            handleApiError(
                {
                    isReduxError: false,
                    error,
                    callbackOnFieldError: setError,
                });
        } finally {
            setIsLoading(false)
        }
    }

    const indicatorsContainerCustomStyle = {
        alignItems: 'center',
    }

    const amount = useMemo(() => {
        const subscription = parseInt(inputPriceMonthlyPlan)
        const discount = parseInt(showDiscount?.label?.replace(/[^\d]/g, ""))
        const duration = parseInt(showDuration?.value)

        return (subscription - (subscription / 100) * discount) * duration
    }, [inputPriceMonthlyPlan, showDiscount, showDuration])


    const bundleDurations = useMemo(() => {
        return bundlesList.map(({duration_in_months}) => {
            return duration_in_months
        })
    }, [bundlesList])

    const classes = useStyles()
    return <>
        {isLoading && <Spinner overlayFullscreen={true}/>}
        <form onSubmit={handleSubmit(storeBundle)}>
            <div className={classes.wrapperSelect}>
                <Select
                    className={classes.select}
                    control={control}
                    label={'Discount Percent'}
                    placeholder={'Select a discount...'}
                    options={discountLabel}
                    name={SUBSCRIPTION_BUNDLE.discount}
                    isDisabled={bundlesList.length >= 4}
                    isClearable
                    indicatorsContainerCustomStyle={indicatorsContainerCustomStyle}
                />

                <Select
                    className={classes.select}
                    control={control}
                    label={'Duration'}
                    placeholder={'Select a duration...'}
                    options={durationLabel.filter(
                        (label) => !bundleDurations.includes(label.value)
                    )}
                    name={SUBSCRIPTION_BUNDLE.duration}
                    isDisabled={bundlesList.length >= 4}
                    isClearable
                    indicatorsContainerCustomStyle={indicatorsContainerCustomStyle}
                />
            </div>

            <div className={classes.divider}>
                <Divider/>
            </div>

            {isValid && showDiscount && showDuration &&
                <span className={classes.priceHint}>
                    Get {showDuration?.label} for {amount?.toFixed(2)}$ (TOTAL)
                    {showDiscount?.label?.replace(/[^%\d]/g, "")} OFF
                </span>
            }

            <div className={classes.containerButton}>
                <Button
                    width={'100%'}
                    variant={"ghost"}
                    onClick={() => setIsOpenBundle(!isOpenBundle)}
                >
                    Cancel
                </Button>
                <Button
                    width={'100%'}
                    type={"submit"}
                    disabled={!inputPriceMonthlyPlan || bundlesList?.length >= 4}
                >
                    Create Bundle
                </Button>
            </div>
        </form>
    </>
}

export default BundleForm