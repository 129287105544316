import { createUseStyles } from 'react-jss'
import LoginForm from './login-form'
import { Link } from 'react-router-dom'
import {
    ROUTE_FORGOT_PASSWORD,
    ROUTE_SIGNUP,
} from '../../../utilities/constants'
import colors from "../../../theme/colors";

const useStyles = createUseStyles((theme) => ({
    root: {
        display: 'grid',
        gridTemplateRows: 'auto 1fr',
        gap: 16,
        alignItems: 'center',
    },
    content: {
        display: 'grid',
        gridAutoFlow: 'row',
        textAlign: 'center',
        gap: 24,
    },
    title: {
        ...theme.pageStyle.title,
    },
    subtitle: {
        ...theme.pageStyle.sectionTitle,
    },
}))

const forgotColor = {
    color: colors.secondary[600],
}

const Login = () => {
    const classes = useStyles()

    return (
        <div className={classes.root}>
            <div className={classes.content}>
                <div>
                    <h5 className={classes.title}>Log In to your account</h5>
                    <p className={classes.subtitle}>
                        Enter the data required to Log In
                    </p>
                </div>
                <LoginForm />
                <p>
                    <Link style={forgotColor} to={ROUTE_FORGOT_PASSWORD}>
                        Forgot your password?
                    </Link>
                </p>
            </div>
            <p>
                Don't have an account yet?{' '}
                <Link to={ROUTE_SIGNUP}>Sign Up</Link>
            </p>
        </div>
    )
}

export default Login
