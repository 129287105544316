import React, {useEffect} from 'react'
import {createUseStyles} from 'react-jss'
import {useWindowSize} from '../../hooks/useWindowSize'
import MessagesPanel from './messagesPanel/MessagesPanel'
import {useDispatch, useSelector} from 'react-redux'
import {useParams} from 'react-router-dom'
import ChatPanel from './chatPanel/ChatPanel'
import { selectUser, userMe} from '../../store/slices/user'
import {
    selectIsChatLoaded,
    selectMyConversationsByUUID,
} from "../../store/slices/conversations";
import {usePubNub} from "pubnub-react";
import Spinner from "../../components/Spinner";
import {
    resetMessages,
    setActiveChannel
} from "../../store/slices/messages";

const useStyles = createUseStyles((theme) => ({
    messages: {
        alignSelf: 'center',
        width: '100%',
        height: '100%',
        display: 'flex',
        overflow: 'hidden',
        [theme.mediaQueries.mUp]: {
            margin: [-20, -10],
            marginTop: 0,
            padding: [24, 24, 0, 24],
        },
    },
    navigationArea: {
        height: '100%',
        width: '100%',
        borderRight: '2px solid #F3F3F3',
        [theme.mediaQueries.mUp]: {
            maxWidth: 360,
        }
    },
    chatArea: {
        height: '100%',
        width: '100%',
        maxWidth: 723,
    },
    noChat: {
        height: '100%',
        width: '100%',
        ...theme.utils.flexbox.centered,
    },
    messageEmptySub: {
        height: '100%',
        width: '100%',
        ...theme.utils.flexbox.centered,
    }
}))

const Messages = () => {
    const {width} = useWindowSize()
    const dispatch = useDispatch()
    const params = useParams()
    const me = useSelector(selectUser)
    const hasChatToken = me?.chat_token
    const hasChannelsGroup = me?.channels_groups // array of channel groups, always available
    const isChatLoaded = useSelector(selectIsChatLoaded)
    const channelUUID = params?.channelUUID
    const myConversationsByUUID = useSelector(selectMyConversationsByUUID)
    const newActiveConversation = myConversationsByUUID[channelUUID]

    const isMobile = width <= 448
    const pubnub = usePubNub()

    useEffect(() => {
        dispatch(userMe())
        return () => {
            dispatch(resetMessages())
        }
    }, [])

    // needed for addMessage inside the store
    useEffect(
        () => {
            dispatch(setActiveChannel(channelUUID))
            return () => {
                dispatch(setActiveChannel(null))
            }
        },
        [channelUUID]
    )


    const classes = useStyles({})

    return !Object.keys(pubnub).length || !isChatLoaded ?
        (hasChatToken ? <Spinner /> : <div className={classes.messageEmptySub}>Subscribe to someone to start using the chat!</div>) : <div className={classes.messages}>
            {/* Empty state */}
            {!hasChannelsGroup && (
                <div className={classes.noChat}>You have no active chats </div>
            )}
            {/* Desktop version - panel + chat */}
            {!isMobile && hasChannelsGroup && (
                <>
                    <div className={classes.navigationArea}>
                        <MessagesPanel
                            extended
                            channelUUID={channelUUID}
                        />
                    </div>
                    <div className={classes.chatArea}>
                        {newActiveConversation ? (
                            <ChatPanel
                                channelUUID={channelUUID}
                                currentConversation={newActiveConversation}
                            />
                        ) : (
                            <div className={classes.noChat}>
                                No chat selected
                            </div>
                        )}
                    </div>
                </>
            )}
            {/* Mobile version - panel */}
            {isMobile && !newActiveConversation && (
                <div className={classes.navigationArea}>
                    <MessagesPanel
                        channelUUID={channelUUID}
                        isMobilePage={true}
                    />
                </div>
            )}
            {/* Mobile version - chat */}
            {isMobile && newActiveConversation && (
                <div className={classes.chatArea}>
                    {
                        <ChatPanel
                            uuid={channelUUID}
                            currentConversation={newActiveConversation}
                            isMobile={true}
                        />
                    }
                </div>
            )}
        </div>
}

export default Messages
