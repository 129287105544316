import * as yup from 'yup'

const POST_MODEL = {
    content: 'content',
}

const postValidationSchema = yup.object().shape({
    [POST_MODEL.content]: yup.string(),
})

export { POST_MODEL, postValidationSchema as validationSchema }
