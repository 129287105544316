import {createUseStyles} from 'react-jss'
import UserInfo from '../../../homepage/posts-feed/post/post-header/user-info'
import Checkbox from '../../../../components/Checkbox'
import {useFormContext} from 'react-hook-form'
import {KEEP_SUPPORTING_MODEL} from '../../KeepSupportingModel'
import {useEffect} from 'react'

const useStyles = createUseStyles((theme) => ({
    subscriptionRenewalCard: {
        display: 'flex',
        flex: 1,
        flexBasis: '30%',
        flexDirection: 'column',
        gap: 10,
        overflow: 'hidden',
        justifyContent: 'space-between',
        borderRadius: 16,
        minWidth: 250,
        minHeight: 138,
        margin: [2, 2, theme.spacing * 2, 2],
        boxShadow:
            '0px 1px 3px rgba(20, 20, 42, 0.1), 0px 0px 1px rgba(20, 20, 42, 0.05)',
        width: '100%',
        [theme.mediaQueries.mUp]: {
            width: 337,
            maxWidth: 337,
        },
    },
    infoWrapper: {
        ...theme.utils.flexbox.spaceBetween,
        padding: [0, theme.spacing * 2],
        paddingTop: theme.spacing * 2,
    },
    priceWrapper: {
        ...theme.utils.flexbox.spaceBetween,
        alignItems: 'start',
        flexDirection: 'column',
        padding: [0, theme.spacing * 2],
        paddingBottom: ({isPlanChanged}) => !isPlanChanged && theme.spacing * 2,
        '& label': {
            ...theme.utils.flexbox.spaceBetween,
            width: '100%',
            color: theme.palette.label.color,
            fontSize: 8,
            letterSpacing: 0.5,
            flexGrow: 1,
            margin: 0,
            fontWeight: 500,
            '& p': {
                color: theme.palette.primary.middle,
            },
        },
        '& p': {
            fontSize: 12,
            letterSpacing: 0.5,
            color: theme.palette.primary.middle,
        },
        '& span': {
            ...theme.utils.flexbox.spaceBetween,
            width: '100%',
            '& :nth-child(1)': {
                color: theme.palette.text.primary,
                fontSize: 16,
                letterSpacing: 0.5,
                fontWeight: 700,
            },
            '& :nth-child(2)': {
                color: theme.palette.primary.middle,
                fontSize: 16,
                letterSpacing: 0.5,
            },
        },
    },
    checkbox: {
        margin: 0,
        alignSelf: 'start',
    },
    changePlanWrapper: {
        display: 'grid',
        gridTemplateColumns: '76px 1fr',
        gridTemplateRows: '24px',
        fontSize: 10,
        letterSpacing: 0.25,
    },
    planTitle: {
        ...theme.utils.flexbox.centered,
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,

    },
    planDescription: {
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'center',
        color: theme.palette.label.color,
        padding: [0, theme.spacing],
    },
    divider: {
        placeSelf: 'center',
        width: '90%',
        border: '0.5px solid #F3F3F3',
    },
    bold: {
        fontWeight: 700,
    }
}))

const currency = '$'
const SubscriptionRenewalCard = ({
                                     subscriptionID,
                                     user,
                                     price,
                                     index,
                                     defaultChecked,
                                     selectAll,
                                     multiplier,
                                     lastPrice,
                                 }) => {
    const {register, setValue} = useFormContext()
    const isPlanChanged = lastPrice !== price

    useEffect(() => {
        if (selectAll) {
            setValue(
                `${KEEP_SUPPORTING_MODEL.checkbox}.${index}`,
                `${subscriptionID}`
            )
        }
    }, [selectAll, user])

    const classes = useStyles({isPlanChanged})
    return (
        <div className={classes.subscriptionRenewalCard}>
            <div className={classes.infoWrapper}>
                <UserInfo
                    name={user?.display_name}
                    username={user?.username}
                    avatar={user?.avatar?.[128]}
                    category={user?.category?.name}
                    isOnline={user?.is_online}
                />
                <Checkbox
                    className={classes.checkbox}
                    id={`${KEEP_SUPPORTING_MODEL.checkbox}.${index}`}
                    value={subscriptionID}
                    defaultChecked={defaultChecked}
                    {...register(`${KEEP_SUPPORTING_MODEL.checkbox}.${index}`)}
                />
            </div>
            <div className={classes.divider}/>
            <div className={classes.priceWrapper}>
                <label>
                    SUBSCRIPTION {multiplier > 1 && <p>x{multiplier}</p>}
                </label>
                <span>
                    <p>Monthly Price:</p>
                    <p>
                        {currency}
                        {price?.toFixed(2)}
                    </p>
                </span>
            </div>
            {isPlanChanged &&
                <div className={classes.changePlanWrapper}>
                    <div className={classes.planTitle}>
                        Change Plan
                    </div>
                    <div className={classes.planDescription}>
                        <span>The creator changed his plan from
                            <span className={classes.bold}> {currency}{lastPrice} </span>
                            to
                            <span className={classes.bold}> {currency}{price} </span>
                        </span>
                    </div>
                </div>
            }
        </div>
    )
}

export default SubscriptionRenewalCard
