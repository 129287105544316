import React from 'react'
import { createUseStyles, useTheme } from 'react-jss'
import { TimelineIcon, TimelineIconFilled } from '../theme/icons'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { setSignupStatus } from '../store/slices/user'
import { useDispatch } from 'react-redux'

const useStyles = createUseStyles((theme) => ({
    root: {
        ...theme.utils.grid.centered,
        ...theme.utils.grid.colFlow,
        gridTemplateColumns: ({ stepNames }) =>
            `repeat(${stepNames.length}, 60px)`,
        gridColumnGap: theme.spacing * 6,

        [theme.mediaQueries.xsUp]: {
            gridTemplateColumns: ({ stepNames }) =>
                `repeat(${stepNames.length}, 70px)`,
            gridColumnGap: theme.spacing * 8,
        },
        [theme.mediaQueries.sUp]: {
            gridTemplateColumns: ({ stepNames }) =>
                `repeat(${stepNames.length}, 75px)`,
            gridColumnGap: theme.spacing * 9,
        },
        [theme.mediaQueries.mUp]: {
            gridTemplateColumns: ({ stepNames }) =>
                `repeat(${stepNames.length}, 64px)`,
        },
    },
    item: {
        ...theme.utils.grid.centered,
        gridRowGap: theme.spacing,
        justifyItems: 'center',
        borderRadius: '50%',
        '& svg': {
            width: 60,
        },
        '& > p': {
            ...theme.typography.subtitle1,
            fontWeight: 500,
            color: theme.palette.text.primary,
        },

        [theme.mediaQueries.xsUp]: {
            '& svg': {
                width: 70,
            },
        },
        [theme.mediaQueries.sUp]: {
            '& svg': {
                width: 75,
            },
        },
        [theme.mediaQueries.mUp]: {
            '& svg': {
                width: 80,
            },
        },
    },
    iconWrapper: {
        position: 'relative',
    },
    progressBar: {
        position: 'absolute',
        left: '100%',
        top: '50%',
        transform: 'translateY(-50%)',

        height: 2,
        width: theme.spacing * 4,
        backgroundColor: theme.palette.secondary.main,
        placeSelf: 'center',

        [theme.mediaQueries.xsUp]: {
            width: theme.spacing * 6,
        },
        [theme.mediaQueries.sUp]: {
            width: theme.spacing * 7,
        },
    },
    progressBarDisabled: {
        extend: 'progressBar',
        backgroundColor: theme.palette.disabled.light,
    },
}))

const Timeline = ({
    stepNames = [],
    height = '100%',
    step = '',
    stepValues = [],
    className,
}) => {
    const theme = useTheme()
    const dispatch = useDispatch()
    const stepIndex = stepValues.indexOf(step)

    const clickHandler = (currentIndex) => {
        if (currentIndex < stepIndex) {
            dispatch(setSignupStatus(stepValues[currentIndex]))
        }
    }

    const classes = useStyles({ height, stepNames })
    return (
        <div className={cx(classes.root, className)}>
            {stepNames?.map((item, index) => {
                return (
                    <div className={classes.item} key={index}>
                        <div
                            style={{
                                cursor:
                                    index < stepIndex ? 'pointer' : 'normal',
                            }}
                            className={classes.iconWrapper}
                            onClick={() => clickHandler(index)}
                        >
                            {index < stepIndex ? (
                                <TimelineIconFilled
                                    fill={theme.palette.secondary.main}
                                    stroke="#fff"
                                    d={'M1 7.31372L6.65685 12.9706L17.9706 1.65687'}
                                />
                                ) : (
                                <TimelineIcon
                                    fill={
                                        index > stepIndex
                                            ? theme.palette.disabled.light
                                            : theme.palette.secondary.main
                                    }
                                    stroke={
                                        index > stepIndex
                                            ? theme.palette.disabled.light
                                            : theme.palette.secondary.main
                                    }
                                    d={index == 0 ? 'M5.44498 13C5.31298 13 5.20498 12.958 5.12098 12.874C5.04898 12.79 5.01298 12.688 5.01298 12.568V3.244L2.27698 5.35C2.16898 5.422 2.06098 5.452 1.95298 5.44C1.84498 5.416 1.74898 5.35 1.66498 5.242L0.908984 4.27C0.836984 4.162 0.806984 4.048 0.818984 3.928C0.842984 3.808 0.908984 3.712 1.01698 3.64L5.04898 0.526C5.12098 0.466 5.19298 0.429999 5.26498 0.417999C5.33698 0.405999 5.41498 0.399999 5.49898 0.399999H7.08298C7.20298 0.399999 7.30498 0.441999 7.38898 0.526C7.47298 0.609999 7.51498 0.711999 7.51498 0.831999V12.568C7.51498 12.688 7.47298 12.79 7.38898 12.874C7.30498 12.958 7.20298 13 7.08298 13H5.44498Z'
                                        : index == 1 ? 'M0.669734 13C0.549734 13 0.447734 12.958 0.363734 12.874C0.279734 12.79 0.237734 12.688 0.237734 12.568V11.65C0.237734 11.542 0.261734 11.416 0.309734 11.272C0.369734 11.116 0.495734 10.96 0.687734 10.804L3.53173 7.978C4.32373 7.354 4.95973 6.826 5.43973 6.394C5.93173 5.95 6.28573 5.548 6.50173 5.188C6.72973 4.816 6.84373 4.45 6.84373 4.09C6.84373 3.55 6.69373 3.112 6.39373 2.776C6.10573 2.44 5.64373 2.272 5.00773 2.272C4.58773 2.272 4.23373 2.362 3.94573 2.542C3.65773 2.71 3.42973 2.938 3.26173 3.226C3.10573 3.514 2.99773 3.832 2.93773 4.18C2.91373 4.336 2.84773 4.444 2.73973 4.504C2.63173 4.564 2.52373 4.594 2.41573 4.594H0.813735C0.705734 4.594 0.615734 4.558 0.543734 4.486C0.471734 4.414 0.435734 4.33 0.435734 4.234C0.447734 3.718 0.555734 3.22 0.759734 2.74C0.963734 2.248 1.25773 1.816 1.64173 1.444C2.03773 1.06 2.51773 0.754 3.08173 0.526C3.64573 0.298 4.28173 0.184 4.98973 0.184C5.98573 0.184 6.80773 0.352 7.45573 0.688C8.11573 1.024 8.60773 1.48 8.93173 2.056C9.26774 2.632 9.43574 3.28 9.43574 4C9.43574 4.564 9.32774 5.086 9.11174 5.566C8.89574 6.034 8.58374 6.49 8.17573 6.934C7.76773 7.366 7.27573 7.81 6.69973 8.266L4.14373 10.858H9.27374C9.40574 10.858 9.50773 10.9 9.57973 10.984C9.66373 11.056 9.70574 11.158 9.70574 11.29V12.568C9.70574 12.688 9.66373 12.79 9.57973 12.874C9.50773 12.958 9.40574 13 9.27374 13H0.669734Z'
                                            : index == 2 ? 'M5.92906 13.18C5.08905 13.18 4.36305 13.078 3.75105 12.874C3.13905 12.67 2.62905 12.406 2.22105 12.082C1.82505 11.746 1.52505 11.386 1.32105 11.002C1.11705 10.606 1.00905 10.216 0.997055 9.832C0.997055 9.724 1.03305 9.64 1.10505 9.58C1.18905 9.508 1.28505 9.472 1.39305 9.472H3.01305C3.13305 9.472 3.23505 9.496 3.31905 9.544C3.40305 9.592 3.47505 9.688 3.53505 9.832C3.64305 10.156 3.81705 10.414 4.05705 10.606C4.30905 10.798 4.59706 10.936 4.92106 11.02C5.24505 11.092 5.58705 11.128 5.94705 11.128C6.66705 11.128 7.23705 10.96 7.65705 10.624C8.08905 10.276 8.30506 9.784 8.30506 9.148C8.30506 8.512 8.10706 8.056 7.71106 7.78C7.31505 7.492 6.76305 7.348 6.05506 7.348H4.20105C4.06905 7.348 3.96105 7.312 3.87705 7.24C3.79305 7.156 3.75105 7.048 3.75105 6.916V6.16C3.75105 6.04 3.77505 5.938 3.82305 5.854C3.88305 5.758 3.94305 5.68 4.00305 5.62L7.22505 2.47H2.07705C1.94505 2.47 1.83705 2.428 1.75305 2.344C1.68105 2.26 1.64505 2.158 1.64505 2.038V0.85C1.64505 0.717999 1.68105 0.609999 1.75305 0.526C1.83705 0.441999 1.94505 0.399999 2.07705 0.399999H9.79906C9.93105 0.399999 10.0391 0.441999 10.1231 0.526C10.2071 0.609999 10.2491 0.717999 10.2491 0.85V1.93C10.2491 2.038 10.2251 2.134 10.1771 2.218C10.1291 2.302 10.0751 2.374 10.0151 2.434L6.90105 5.638L7.11705 5.656C7.84905 5.716 8.49706 5.884 9.06105 6.16C9.63706 6.424 10.0811 6.814 10.3931 7.33C10.7171 7.846 10.8791 8.494 10.8791 9.274C10.8791 10.09 10.6631 10.792 10.2311 11.38C9.81106 11.956 9.22906 12.4 8.48505 12.712C7.74106 13.024 6.88906 13.18 5.92906 13.18Z'
                                                : ''
                                    }
                                />
                            )}
                            {index < stepNames.length - 1 && (
                                <div
                                    className={
                                        index < stepIndex
                                            ? classes.progressBar
                                            : classes.progressBarDisabled
                                    }
                                    width={"50px"}
                                />
                            )}
                        </div>
                        <p>{stepNames[index]}</p>
                    </div>
                )
            })}
        </div>
    )
}

export default Timeline

Timeline.propTypes = {
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}
