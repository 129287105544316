import { createUseStyles } from 'react-jss'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { components } from 'react-select'
import paymentProviderLogo from '../../../../../asset/images/8-pay-logo.png'
import { useWindowSize } from '../../../../../hooks/useWindowSize'
import { httpSendTip, httpUserTokens } from '../../../../../http-requests'
import { handleApiError } from '../../../../../utilities/helpers'
import { CHAT_TIP_MODEL, validationSchema } from './chatTipMODEL'
import colors from '../../../../../theme/colors'
import Media from '../../../../../components/Media'
import Divider from '../../../../../components/Divider'
import UserInfo from '../../../../homepage/posts-feed/post/post-header/user-info'
import { tipAssets } from '../../../../../utilities/constants/tips'
import Radio from '../../../../../components/Radio'
import Box from '../../../../../components/Box'
import Button from '../../../../../components/Button'
import Input from '../../../../../components/Input'
import Popover from '../../../../../components/Popover'
import Select from '../../../../../components/Select'
import TextareaAutoresizing from '../../../../../components/TextareaAutoresizing'
import { useDispatch } from 'react-redux'
import { addMessage } from '../../../../../store/slices/messages'
import { DevTool } from '@hookform/devtools'
import { TIP_MODEL } from '../../../../creator-page/tip-modal/tipModel'

const useStyles = createUseStyles((theme) => ({
    tipModal: {
        display: 'flex',
        flexDirection: 'column',
        gap: 16,
        color: theme.palette.secondary.main,
    },
    userInfo: {
        padding: 16,
        borderBottom: `1px solid ${colors.greyscale[200]}`,
    },
    action: {
        borderTop: `1px solid ${theme.palette.grey[300]}`,
        padding: theme.spacing * 2,
    },
    divider: {
        margin: 0,
    },
    formWrapper: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing * 2,
        margin: [0, theme.spacing * 2, theme.spacing * 2, theme.spacing * 2],
        '& > p': {
            fontSize: 12,
        },
    },
    paymentProvider: {
        ...theme.utils.flexbox.centered,
        gap: theme.spacing,
        padding: 0,
        fontSize: 12,
        color: theme.palette.text.secondary,
        minHeight: 40,
        background: '#FAFAFA',
        boxShadow: 'none',
        '& div': {
            height: 20,
            width: 43,
        },
    },
    select: {
        display: 'flex',
        flexDirection: 'column',
        '& div > div > div': {
            alignItems: 'center',
        },
        '& div > div > div > div': {
            display: 'flex !important',
            placeContent: 'space-between !important',
            alignItems: 'center !important',
        },
        '& label': {
            marginTop: 0,
            marginBottom: 8,
        },
    },
    customValue: {
        minHeight: 40,
        width: 111,
        borderRadius: 8,
        marginRight: 8,
        color: theme.palette.secondary.dark,
        border: '1px solid #41D3BD',
        fontWeight: 700,
        fontSize: 14,
        letterSpacing: 0.5,
        '& span': {
            marginLeft: 5,
        },
    },
    currency: {
        color: theme.palette.input.placeholder,
    },
    tipSection: {},
    tipItem: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: 48,
        borderBottom: `1px solid #F3F3F3`,
        '& p': {
            fontSize: 16,
            fontWeight: 400,
            letterSpacing: 0.5,
            color: colors.tertiary[800],
        },
    },
    tipInfoWrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 12,
    },
    image: {
        height: 32,
        width: 32,
    },
    radio: {
        marginRight: 4,
    },
}))

const currency = '$'

const TipChatModal = ({ onClose, user, media, date, onSuccess }) => {
    const dispatch = useDispatch()
    const [isLoading, setIsLoading] = useState(false)
    const [tokens, setTokens] = useState(false)
    const { width } = useWindowSize()
    const [isMobile, setIsMobile] = useState(width <= 768)

    useEffect(() => {
        setIsMobile(width <= 448)
    }, [width])

    useEffect(() => {
        fetchTokens()
    }, [])

    const fetchTokens = async () => {
        try {
            const { data } = await httpUserTokens(user?.id)
            setTokens(
                data?.map(({ id, symbol, discount_percentage }) => ({
                    value: id,
                    label: symbol,
                    discount: discount_percentage,
                })),
            )
        } catch (error) {
            handleApiError({
                isReduxError: false,
                error,
            })
        }
    }

    const formMethods = useForm({
        shouldUnregister: false,
        mode: 'all',
        reValidateMode: 'all',
        nativeValidation: false,
        defaultValues: {},
        resolver: yupResolver(validationSchema),
    })
    const {
        handleSubmit,
        control,
        watch,
        setValue,
        register,
        formState: { errors, touchedFields },
    } = formMethods

    const selectedAmount = watch(CHAT_TIP_MODEL.amount)

    const CustomSingleValue = ({ children, ...props }) => {
        return (
            <components.SingleValue {...props}>
                {children}
                {props?.data?.discount && (
                    <div className={classes.customValue}>
                        <span>{props?.data?.discount}% Discount</span>
                    </div>
                )}
            </components.SingleValue>
        )
    }

    const onSubmit = async (formValues) => {
        const dataToSend = {
            token_id: formValues?.token?.value,
            usd_value:
                formValues?.amount !== 'custom'
                    ? +formValues?.amount
                    : +formValues?.customAmount,
            ...(formValues?.content && { description: formValues?.content }),
        }
        try {

            const { data } = await httpSendTip(user?.id, dataToSend)
            onClose()
        } catch (error) {
            if (error.response?.status === 302 && error.response?.data?.url) {
                window.location.replace(error.response?.data?.url)
            } else {
                if (
                    error.response?.status === 302 &&
                    error.response?.data?.url
                ) {
                    window.location.replace(error.response?.data?.url)
                } else {
                    handleApiError({
                        isReduxError: false,
                        error,
                    })
                }
            }
        } finally {
            setIsLoading(false)
        }
    }

    const setValueOverride = (value) => {
        setValue(CHAT_TIP_MODEL.content, value)
    }

    const classes = useStyles({ isMobile })
    return (
        <Popover onClose={onClose} title={'Subscriptions'} maxWidth={539}>
            <div className={classes.tipModal}>
                <UserInfo
                    classname={classes.userInfo}
                    avatar={user?.avatar?.[128]}
                    name={user?.display_name}
                    username={user?.username}
                    isVerified={user?.is_verified}
                    isOnline={user?.is_online}
                />
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className={classes.formWrapper}>
                        <TextareaAutoresizing
                            withEmoji
                            name={CHAT_TIP_MODEL.content}
                            callback={setValueOverride}
                            placeholder={'Message (Optional)'}
                            {...register(CHAT_TIP_MODEL.content)}
                        />
                        <div className={classes.tipSection}>
                            {Object.entries(tipAssets).map((item, index) => {
                                return (
                                    <div key={index} className={classes.tipItem}>
                                        <div className={classes.tipInfoWrapper}>
                                            <Media
                                                className={classes.image}
                                                image={item[1].image}
                                            />
                                            <p>{item[1].name}</p>
                                        </div>
                                        <Radio
                                            className={classes.radio}
                                            value={item[1].value}
                                            {...register(TIP_MODEL.amount)}
                                        />
                                    </div>
                                )
                            })}
                        </div>
                        <Input
                            type={'text'}
                            placeholder={`Enter tip amount`}
                            statusIcon={
                                <span className={classes.currency}>
                                    {currency}
                                </span>
                            }
                            iconPosition={'right'}
                            {...register(CHAT_TIP_MODEL.customAmount)}
                            errors={errors[CHAT_TIP_MODEL.customAmount]}
                            touched={touchedFields[CHAT_TIP_MODEL.customAmount]}
                            inputProps={{
                                disabled: selectedAmount !== 'custom',
                            }}
                        />
                        <Divider className={classes.divider} height={0.5} />
                        <Select
                            label={'Token Name'}
                            placeholder={'Choose a Token to pay with...'}
                            options={tokens}
                            control={control}
                            components={{ SingleValue: CustomSingleValue }}
                            isSearchable
                            name={CHAT_TIP_MODEL.token}
                            errors={errors[CHAT_TIP_MODEL.token]}
                            className={classes.select}
                        />
                        <Box classNames={classes.paymentProvider}>
                            POWERED BY
                            <Media image={paymentProviderLogo} />
                        </Box>
                    </div>
                    <div className={classes.action}>
                        <Button
                            disabled={isLoading}
                            width={'100%'}
                            type={'submit'}
                        >
                            Confirm Tip
                        </Button>
                    </div>
                </form>
            </div>
            {/*<DevTool control={control} />*/}
        </Popover>
    )
}

export default TipChatModal
