import { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { createUseStyles, useTheme } from 'react-jss'
import cx from 'classnames'
import Input from '../../../../../components/Input'
import ToggleSwitch from '../../../../../components/ToggleSwitch'
import Button from '../../../../../components/Button'
import {
    EyeOpenIcon,
    HideIcon,
    LockIcon,
    MailIcon,
    SideArrowIcon,
} from '../../../../../theme/icons'
import smallLogo from '../../../../../asset/images/smallLogo.svg'
import { SIGNUP_MODEL } from '../signupModel'
import {
    retrieveSingleValueForRs,
    retrieveValuesForRs,
} from '../../../../../utilities/helpers'
import { useSelector } from 'react-redux'
import { selectUser } from '../../../../../store/slices/user'
import {
    EXT_PRIVACY_POLICY_COOKIE_POLICY,
    EXT_TERMS_CONDITIONS,
} from '../../../../../utilities/constants/externalLinks'

const useStyles = createUseStyles((theme) => ({
    formContent: {
        display: 'grid',
        gap: 24,
        '& p': {
            textAlign: 'center',
            ...theme.typography.body2,
        },
    },
    card: {
        background: theme.palette.grey[50],
        boxShadow:
            '0px 1px 3px rgba(20, 20, 42, 0.1), 0px 0px 1px rgba(20, 20, 42, 0.05)',
        borderRadius: 16,
        padding: 16,
        display: 'grid',
        gridTemplateColumns: '1fr auto',
        gap: 16,
        alignItems: 'center',
        border: `2px solid transparent`,
    },
    content: {
        display: 'grid',
        gap: 8,
        gridAutoFlow: 'row',
        fontSize: 12,
    },
    cardTitle: {
        color: theme.palette.text.primary,
        fontWeight: 500,
    },
    cardContent: {
        letterSpacing: 0.5,
        color: theme.palette.grey[600],
    },
    cardError: {
        color: theme.palette.error.main,
        borderColor: theme.palette.error.main,
    },
    fields: {
        display: 'grid',
        gap: 16,
    },
}))

const SignupInitialStep = () => {
    const user = useSelector(selectUser)
    const {
        register,
        reset,
        formState: { errors, touchedFields, isSubmitting },
    } = useFormContext()

    const [showPassword, setShowPassword] = useState(false)
    const theme = useTheme()

    useEffect(() => {
        reset({
            [SIGNUP_MODEL.email]: user && user[SIGNUP_MODEL.email],
            [SIGNUP_MODEL.password]: user && user[SIGNUP_MODEL.password],
        })
    }, [user])

    const classes = useStyles()

    return (
        <div className={classes.formContent}>
            <div className={classes.fields}>
                <Input
                    type={'email'}
                    placeholder={'Enter your email'}
                    touched={touchedFields[SIGNUP_MODEL.email]}
                    errors={errors[SIGNUP_MODEL.email]}
                    {...register(SIGNUP_MODEL.email)}
                    icon={<MailIcon />}
                />
                <Input
                    type={showPassword ? 'text' : 'password'}
                    placeholder={'Enter your password'}
                    touched={touchedFields[SIGNUP_MODEL.password]}
                    errors={errors[SIGNUP_MODEL.password]}
                    {...register(SIGNUP_MODEL.password)}
                    icon={<LockIcon />}
                    statusIcon={showPassword ? <EyeOpenIcon /> : <HideIcon />} // TODO: find new icon "EyeOpenIcon"
                    statusIconCallback={() => setShowPassword(!showPassword)}
                />
                <div
                    className={cx(
                        classes.card,
                        errors[SIGNUP_MODEL.adult]?.message
                            ? classes.cardError
                            : null
                    )}
                >
                    <div className={classes.content}>
                        <div className={classes.cardTitle}>Adult contents</div>
                        <div className={classes.cardContent}>
                            Switch on if you have more of 18 years and you are agree to see and create adult content.
                        </div>
                    </div>
                    <div>
                        <ToggleSwitch
                            hint={'+18'}
                            inputBgColor={theme.palette.secondary.main}
                            pointerBackground={smallLogo}
                            {...register(SIGNUP_MODEL.adult)}
                        />
                    </div>
                </div>
            </div>
            <Button
                width={'100%'}
                type={'submit'}
                variant={'filled'}
                icon={<SideArrowIcon />}
                disabled={isSubmitting}
            >
                Continue
            </Button>
            <p>
                By signin up, you agree to Happyfans{' '}
                <a href={EXT_TERMS_CONDITIONS} target={'_blank'}>
                    Terms of Use
                </a>
                ,{' '}
                <a href={EXT_PRIVACY_POLICY_COOKIE_POLICY} target={'_blank'}>
                    Privacy and Cookie Policy
                </a>
            </p>
        </div>
    )
}

export default SignupInitialStep
