import * as yup from 'yup'

const COMMENT_MODEL = {
    parent: 'parent',
    message: 'comment',
}

const commentValidationSchema = yup.object().shape({
    [COMMENT_MODEL.message]: yup.string().required('You must write a comment'),
    [COMMENT_MODEL.parent]: yup.number().nullable(),
})

export { COMMENT_MODEL, commentValidationSchema as validationSchema }
