import { useCallback, useEffect, useMemo, useRef } from 'react'
import { createUseStyles } from 'react-jss'
import LightGallery from 'lightgallery/react'
import ResponsiveImage from '../../../../../../components/ResponsiveImage'
import PostDescription from '../post-description'

const useStyles = createUseStyles((theme) => ({
    content: {
        display: 'grid',
        gap: 8,
        gridTemplateRows: 'auto 220px',
        [theme.mediaQueries.xsUp]: {
            gridTemplateRows: 'auto 300px',
        },
        [theme.mediaQueries.mUp]: {
            gridTemplateRows: 'auto 360px',
        },
    },
    gallery: {},
}))

const PostImageContent = ({ media, content }) => {
    const lightGallery = useRef()

    const mediaRemapped = useMemo(() => {
        return media.map((media) => ({
            id: media.id,
            size: '1920-1080',
            src: media.original,
            thumb: media[720],
        }))
    }, [media])

    const openGallery = useCallback(() => {
        lightGallery.current.openGallery()
    }, [])

    const onInit = useCallback((detail) => {
        if (detail) {
            lightGallery.current = detail.instance
        }
    }, [])

    const gallerySettings = {
        mode: 'lg-fade',
        keyPress: true,
        download: false,
        enableDrag: false,
        dynamic: true,
        licenseKey: process.env.REACT_APP_LG_PLUGIN ?? '0000-0000-000-0000',
        dynamicEl: mediaRemapped,
        onInit,
    }

    useEffect(() => {
        lightGallery.current.refresh()
    }, [mediaRemapped])

    const classes = useStyles()

    return (
        <div className={classes.content}>
            <PostDescription text={content} />
            <ResponsiveImage media={media[0]} alt={''} onClick={openGallery} />
            <LightGallery innerRef={lightGallery} {...gallerySettings} />
        </div>
    )
}

export default PostImageContent
