import { createUseStyles } from 'react-jss'
import CoverInfo from './cover-info'
import cx from 'classnames'
import { MessagesLabelIcon, SettingIconFilled } from '../../../../theme/icons'
import UserInfo from '../../../homepage/posts-feed/post/post-header/user-info'
import { httpFollowUser, httpUnfollowUser } from '../../../../http-requests'
import { handleApiError } from '../../../../utilities/helpers'
import { useState } from 'react'
import Button from '../../../../components/Button'
import {
    PROFILE,
    ROUTE_MESSAGES_CHAT,
    ROUTE_SIGNUP,
    USER_SETTINGS,
} from '../../../../utilities/constants'
import { useNavigate } from 'react-router-dom'
import { selectUser } from '../../../../store/slices/user'
import { useDispatch, useSelector } from 'react-redux'
import {selectMyConversations, setActiveChat} from '../../../../store/slices/conversations'
import {setActiveChannel} from "../../../../store/slices/messages";

const useStyles = createUseStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: theme.palette.common.white,
    },
    avatarWrapper: {
        ...theme.utils.flexbox.spaceBetween,
        width: '100%',
        padding: [0, theme.spacing * 2],
        transform: 'translateY(-34px)',
        marginBottom: -34,
    },
    userInfo: {
        '& a': {
            fontSize: 16,
        },
    },
    favorites: {
        display: 'flex',
        flexDirection: 'row',
        gap: theme.spacing,
        paddingTop: theme.spacing * 2,
        cursor: 'pointer',
        '& svg': {
            cursor: 'pointer',
        },
    },
    followBtn: {
        borderRadius: 50,
    },
    unfollowBtn: {
        borderRadius: 50,
        color: theme.palette.common.white,
        background: theme.palette.secondary.main,
    },
    chatButton: {
        borderRadius: 50,
        color: theme.palette.common.white,
        borderColor: theme.palette.primary.main,
        background: theme.palette.primary.main,
        '&:hover': {
            borderColor: theme.palette.primary.middle,
            background: theme.palette.primary.middle,
        },
    },
}))

const CoverHeader = ({ user, className, isMe }) => {
    const loggedUser = useSelector(selectUser)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [isFollowed, setIsFollowed] = useState(user?.is_followed)
    const myConversationsByUserId = useSelector(selectMyConversations)
    const isOnline = user?.is_online

    const setFollow = async () => {
        if (loggedUser) {
            const httpCall = isFollowed ? httpUnfollowUser : httpFollowUser
            try {
                const { data } = await httpCall(user?.id)
                setIsFollowed(!isFollowed)
            } catch (error) {
                handleApiError({
                    isReduxError: false,
                    error,
                })
            }
        } else {
            navigate(ROUTE_SIGNUP)
        }
    }

    const handleChatRedirect = () => {
        const targetConversation = myConversationsByUserId.byId[user?.id]
        if(targetConversation){
            navigate(ROUTE_MESSAGES_CHAT.replace(':channelUUID', targetConversation?.channel))
        }else{
            navigate(ROUTE_MESSAGES_CHAT)
        }

    }

    const classes = useStyles()
    return (
        <div className={cx(classes.root, className)}>
            <div className={classes.avatarWrapper}>
                <div className={classes.userInfo}>
                    <UserInfo
                        name={user?.display_name}
                        username={user?.username}
                        direction={'vertical'}
                        avatarHeight={68}
                        avatarWidth={68}
                        avatar={user?.avatar?.[128]}
                        isVerified={user?.is_verified}
                        dotSize={16}
                        category={
                            user?.category?.name|| user?.categories?.[0]?.name
                        }
                        isOnline={isOnline}
                    />
                </div>
                <div className={classes.favorites}>
                    {!isMe && user?.is_creator && (
                        <>
                            {user?.subscription?.status === 'active' && (
                                <Button
                                    width={84}
                                    height={40}
                                    onClick={handleChatRedirect}
                                    size={'small'}
                                    variant={'ghost'}
                                    data={'secondary'}
                                    icon={<MessagesLabelIcon />}
                                    iconPosition={'left'}
                                    className={classes.chatButton}
                                >
                                    Chat
                                </Button>
                            )}
                            <Button
                                width={108}
                                height={40}
                                onClick={setFollow}
                                size={'small'}
                                variant={'ghost'}
                                data={'secondary'}
                                className={
                                    isFollowed
                                        ? classes.unfollowBtn
                                        : classes.followBtn
                                }
                            >
                                {isFollowed ? `Unfollow` : `Follow`}
                            </Button>
                        </>
                    )}
                    {isMe && (
                        <SettingIconFilled
                            onClick={() =>
                                navigate(
                                    USER_SETTINGS.replace(':section', PROFILE)
                                )
                            }
                        />
                    )}
                    {/*<SquareIconFramed*/}
                    {/*    onClick={() =>*/}
                    {/*        user?.website_link &&*/}
                    {/*        window.open(user?.website_link, '_blank')*/}
                    {/*    }*/}
                    {/*/>*/}
                </div>
            </div>
            <CoverInfo user={user} />
        </div>
    )
}

export default CoverHeader
