export default ({data: originalResponse = {} , extra: extraPayload = {}}) => {

    const myLastReadTimetoken = originalResponse.last_reads_timetokens?.find(
        ({user_id}) => user_id === extraPayload.myID
    )
    const targetUser = originalResponse.users?.find(
        ({id}) => id !== extraPayload.myID
    )
    return ({
        ...targetUser,
        channel: originalResponse.name,
        channelID: originalResponse.id,
        status: targetUser.subscription?.status,
        createdAt: originalResponse.created_at,
        lastActivityAt: originalResponse.last_activity_at,
        lastReadTimetoken: myLastReadTimetoken?.timetoken,
        dollar_price: targetUser?.subscriptions_dollar_price, // TODO: remove, just for backward compatibility
        subscription_current_dollar_price: targetUser?.subscriptions_dollar_price, // TODO: use this instead od "dollar_price"
        mediaCount: originalResponse.media_count,
    })
}