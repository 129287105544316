import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { selectAccessToken, selectUser } from '../../store/slices/user'
import {
    ROUTE_KEEP_SUPPORTING,
    ROUTE_LOGIN,
    ROUTE_PAYMENT_CHECK_STATUS,
} from '../../utilities/constants'

const PrivateRoute = (props) => {
    const accessToken = useSelector(selectAccessToken)
    const location = useLocation()
    const user = useSelector(selectUser)
    const skipCheckRoutes = [ROUTE_PAYMENT_CHECK_STATUS, ROUTE_KEEP_SUPPORTING]


    const correctPaymentRedirect = () => {
        const canSkip = skipCheckRoutes.includes(location?.pathname)

        if(user?.has_pending_payment && !canSkip){
            return <Navigate to={ROUTE_PAYMENT_CHECK_STATUS} />
        }else if(user?.has_subscriptions_to_renew && !canSkip){
            return <Navigate to={ROUTE_KEEP_SUPPORTING} />
        }else{
            return <Outlet />
        }
    }

    return accessToken ? correctPaymentRedirect() : (<Navigate to={ROUTE_LOGIN} />)
}

export default PrivateRoute
