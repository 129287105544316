/**
 * Example usage with React (inside TelegramExplanation.js):
 * TagManager.initialize({gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID})
 *
 */

export const TagManager = {
    gtm: function (args) {
        const snippets = Snippets.tags(args)

        // script to add inside <head/>
        const script = () => {
            const script = document.createElement('script')
            script.innerHTML = snippets.script
            return script
        }
        // noscript to add inside <body/>
        const noScript = () => {
            const noscript = document.createElement('noscript')
            noscript.innerHTML = snippets.iframe
            return noscript
        }
        return { noScript, script }
    },
    initialize: function ({ gtmId }) {
        const gtm = this.gtm({ id: gtmId })
        document.head.appendChild(gtm.script())
        document.body.insertBefore(gtm.noScript(), document.body.childNodes[0])
    },
}

const Snippets = {
    tags: function ({ id }) {
        if (!id) console.warn('[gtm]', 'GTM Id is required')

        // script to add inside <head/>
        const script = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','${id}');`

        // noscript to add inside <body/>
        const iframe = `<iframe src="https://www.googletagmanager.com/ns.html?id=${id}"
                      height="0" width="0" style="display:none;visibility:hidden"></iframe>`

        return { iframe, script }
    },
}
