import { createUseStyles } from 'react-jss'
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import PostPage from './post/PostPage'
import { watchSections } from '../../utilities/constants/watchSections'
import { createBrowserHistory } from 'history'

const useStyles = createUseStyles((theme) => ({
    watch: {
        height: '100%',
    },
}))

const Watch = () => {
    const location = useLocation()
    let history = createBrowserHistory()
    const navigate = useNavigate()
    const { section, id } = useParams()

    useEffect(() => {
        const forceNavigate =
            history?.action === 'POP' && location?.pathname !== '/watch/post'
        if (location.pathname === '/watch' && forceNavigate) {
            navigate('/watch/post')
        }
    }, [location, history])

    const sectionRenderer = () => {
        switch (section) {
            case watchSections.POST:
                return <PostPage id={id} />
            default:
                return <></>
        }
    }

    const classes = useStyles()
    return (
        <div className={classes.watch}>
            <div>{sectionRenderer()}</div>
        </div>
    )
}

export default Watch
