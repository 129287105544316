export const PubNubManager =  {
    // Send message
    sendMessage: async ({pubnub, channel, text}) => {
        await pubnub.publish({
            channel: channel,
            message: text,
            meta: {
                type: 'text',
            },
        })
    },
    markAsRead: async ({pubnub, channel, timetoken}) => {
        pubnub.objects.setMemberships(
            {
                channels: [
                    {
                        id: channel,
                        custom: {
                            lastReadTimetoken: timetoken,
                        },
                    },
                ],
            },
            /*() => {
                dispatch(clearUnreadMessageCount({ channel: user?.channel }))
            }*/
        )
    },
    // Fetch message history
    fetchMessageHistory: async ({pubnub, channels = [], start, count = 20}) => {
        const {channels: channelsHistories} = await pubnub.fetchMessages({
            channels,
            count,
            includeMeta: true,
            includeMessageActions: channels.length === 1,
            start,
        })
        return channelsHistories
    },
    // Only channel names (includes presence channels)
    getChannelsInChannelGroup: async ({pubnub, channelGroup}) => {
        const {channels} = await pubnub.channelGroups.listChannels({
            channelGroup,
        });
        return channels
    },
    // Only channel names (doesn't include presence channels)
    getUserMemberships: async ({pubnub, uuid, limit = 100, page }) => {
        const {data, next, prev, totalCount} =
            await pubnub.objects.getMemberships({
                uuid,
                include: {
                    customChannelFields: true,
                    channelFields: true,
                    totalCount: true,
                    customFields: true,
                },
                sort: {updated: 'asc'},
                limit,
                ...(page && {page: {next: page}})
            })
        return {data, next, prev, totalCount}
    },
    getChannelMembers: async ({pubnub, channel, status}) => {
        const {data} = await pubnub.objects.getChannelMembers({
            channel: channel,
            include: {
                UUIDFields: true,
                customFields: true,
                customUUIDFields: true,
            },
        })
        return data
    },
    getAllUsersMetadata: async ({pubnub, limit = 100, page}) => {
        const {data, next, totalCount} = await pubnub.objects.getAllUUIDMetadata({
            include: {
                customFields: true,
                totalCount: true
            },
            limit,
            ...(page && {page: {next: page}})
        })
        return {data, next, totalCount}
    },
    getUserMetadata: async ({pubnub, uuid}) => {
        const {data} = await pubnub.objects.getUUIDMetadata({
            uuid,
            include: {
                customFields: true,
            },
        })
        return {data}
    },
    markMessageAsRead: async ({pubnub, channel, messageTimetoken}) => {
        const {data} = await pubnub.objects.setMemberships({
            channels: [
                {
                    id: channel,
                    custom: {
                        lastReadTimetoken: messageTimetoken,
                    },
                },
            ],
        },)
        return {data}
    },
    getChannelsPresence: async ({pubnub, channels}) => {
        const {channels: channelPresence, totalChannels, totalOccupancy} = await pubnub.hereNow({
            channels,
            includeUUIDs: true,
            includeState: true,
        });
        return {channels: channelPresence, totalChannels, totalOccupancy}
    },
    subscribeToChannel: async ({pubnub, channelGroupName, withPresence = false}) => {
        const data = await pubnub.subscribe({
            channelGroups: [channelGroupName],
            withPresence
        });
        return data
    },
}