import {createSelector, createSlice} from '@reduxjs/toolkit'
import dayjs from 'dayjs'
import { normalize, schema } from 'normalizr'

const sliceName = 'messages'
const messageSchema = new schema.Entity(sliceName, {}, {idAttribute: 'timetoken'})

const messagesSlice = createSlice({
    name: sliceName,
    initialState: {
        activeChannel: null,
        messages: { byId: {}, allIds: []},
        latestMessages: {},
        chatMedia: {},
        messagesDate: {},
        unreadMessageCounts: {},
        messageToDelete: false,
        messageToReport: false,
        contextMenuOpen: false,
        firstMessageTimeToken: null,
        isLatestMessagesFetched: false,
    },
    reducers: {
        setChannels: (state, action) => {
            state.channels = action.payload
        },
        setMessages: (state, action) => {
            const {messages, start} = action.payload

            // Used to handle history pagination
            state.firstMessageTimeToken = messages[0]?.timetoken
            if(messages[0]?.channel === state.activeChannel){
                // Add converted timetoken to message
                const messagesWithDate = messages.map(message => (
                    {
                        ...message,
                        date: dayjs(message.timetoken / 10000).format('YYYYMMDD')
                    }))
                // Normalize messages by timetoken
                const { entities, result: allIds } = normalize(messagesWithDate, [messageSchema])
                // Set messages
                state.messages.allIds = start ? [...allIds, ...state.messages.allIds ] : allIds
                state.messages.byId = start ? { ...entities[messageSchema.key], ...state.messages.byId,  } : entities[messageSchema.key]
            }
        },
        addMessage: (state, action) => {
            let message = action.payload
            if(message.channel === state.activeChannel){
                state.messages.byId[message.timetoken] = message
                state.messages.allIds.push(message.timetoken)
            }
            // Update latest message
            if(message?.meta?.type !== 'expired'){
                state.latestMessages[message.channel] = message
            }
        },
        deleteMessage: (state, action) => {
            let message = action.payload
            state.messages.byId[message.timetoken] = {...state.messages.byId[message.timetoken], ...message}
        },
        setChatMedia: (state, action) => {
            state.chatMedia[action.payload.id] = action.payload
        },
        resetMessages: (state) => {
            state.messages = { byId: {}, allIds: []}
            state.messagesDate = {}
            state.chatMedia = {}
        },
        setContextMenuOpen: (state, action) => {
            state.contextMenuOpen = action.payload
        },
        setMessageToDelete: (state, action) => {
            state.messageToDelete = action.payload
        },
        setMessageToReport: (state, action) => {
            state.messageToReport = action.payload
        },
        setUnreadMessageCounts: (state, action) => {
            state.unreadMessageCounts = action.payload
            state.isUnreadMessagesCountFetched = true
        },
        increaseUnreadMessageCount: (state, action) => {
            if (!state.unreadMessageCounts[action.payload.channel]) {
                state.unreadMessageCounts[action.payload.channel] = 0
            }
            state.unreadMessageCounts[action.payload.channel]++
        },
        clearUnreadMessageCount: (state, action) => {
            state.unreadMessageCounts[action.payload.channel] = 0
        },
        setLatestMessages: (state, action) => {
            state.isLatestMessagesFetched = true
            state.latestMessages = action.payload
        },
        setActiveChannel: (state, action) => {
            state.activeChannel = action.payload
        },
    },
})

const selectSelf = (state) => state[sliceName]

export const selectMessages = createSelector(
    selectSelf,
    (state) => state?.messages
)

export const selectContextMenuOpen = createSelector(
    selectSelf,
    (state) => state?.contextMenuOpen
)

export const selectMessageToDelete = createSelector(
    selectSelf,
    (state) => state?.messageToDelete
)
export const selectMessageToReport = createSelector(
    selectSelf,
    (state) => state?.messageToReport
)
export const selectFirstMessageTimeToken = createSelector(
    selectSelf,
    (state) => state?.firstMessageTimeToken
)
export const selectChatMedia = createSelector(
    selectSelf,
    (state) => state?.chatMedia
)
export const selectUnreadMessageCounts = createSelector(
    selectSelf,
    (state) => state.unreadMessageCounts
)

export const selectIsLatestMessagesFetched = createSelector(
    selectSelf,
    (state) => state.isLatestMessagesFetched
)

export const selectLatestMessages = createSelector(
    selectSelf,
    (state) => state.latestMessages
)


export const {
    setMessages,
    addMessage,
    deleteMessage,
    resetMessages,
    setContextMenuOpen,
    setMessageToDelete,
    setMessageToReport,
    setChatMedia,
    setUnreadMessageCounts,
    increaseUnreadMessageCount,
    clearUnreadMessageCount,
    setLatestMessages,
    setActiveChannel,
} = messagesSlice.actions
export default messagesSlice.reducer
