import { createUseStyles } from 'react-jss'
import Box from '../../../components/Box'
import colors from '../../../theme/colors'
import NotificationsGeneralPanel from './notifications-settings-panels/NotificationsGeneralPanel'
import Spinner from '../../../components/Spinner'
import { useState } from 'react'
import NotificationSettingsLoader from './NotificationSettingsLoader'

const useStyles = createUseStyles((theme) => ({
    notificationSection: {
        position: 'relative',
        minHeight: '80vh',
    },
    sectionTitle: {
        fontWeight: 'bold',
        fontSize: '18px',
        lineHeight: '140%',
        color: colors.tertiary[800],
    },
    sectionSubtitle: {
        marginTop: 8,
        marginBottom: 24,
    },
    boxWrapper: {
        position: 'relative',
        overflow: 'scroll',
        maxHeight: 'calc(100vh - 265px)',
        borderRadius: 12,
    },
}))

const NotificationsSection = () => {
    const classes = useStyles()

    return (
        <div className={classes.notificationSection}>
            <p className={classes.sectionTitle}>Notifications Settings</p>
            <p className={classes.sectionSubtitle}>
                Select when and how you’ll be notified
            </p>

            <div className={classes.boxWrapper}>
                <Box>
                    <NotificationsGeneralPanel />
                </Box>
            </div>
        </div>
    )
}

export default NotificationsSection
