const PAYMENT_TYPES = {
    subscription: 'Subscription',
    subscription_rebill: 'Subscription Renew',
    subscription_payment: 'Subscription Payment',
    post: 'Post Premium',
    bundle: 'Bundle',
    tip: 'Tip',
}

export { PAYMENT_TYPES }
