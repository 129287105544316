import { createUseStyles } from 'react-jss'
import UserAvatar from '../../../../../homepage/posts-feed/post/post-header/user-info/user-avatar'
import colors from '../../../../../../theme/colors'
import { DownArrowIcon } from '../../../../../../theme/icons'
import { PAYMENT_TYPES } from '../../../paymentsTypes'

const useStyles = createUseStyles((theme) => ({
    root: {
        display: 'grid',
        gridTemplateColumns: '1fr',
        padding: [0, 6],
        backgroundColor: ({ expanded, index }) =>
            expanded === index ? '#F9F9F9' : '#FCFCFC',
        boxShadow: ({ expanded, index }) =>
            expanded === index
                ? 'inset 0px -0.5px 0px rgba(0, 0, 0, 0.05)'
                : 'unset',
    },
    alwaysVisible: {
        display: 'grid',
        gridAutoFlow: 'column',
        gridTemplateColumns: 'auto 1fr auto',
        gridColumnGap: 16,
        alignItems: 'center',
        height: 40,
        boxShadow: 'inset 0px -0.5px 0px rgba(0, 0, 0, 0.05)',
        '& > span': {
            fontSize: 12,
            color: colors.greyscale[600],
            '& > a': {
                fontSize: 12,
                fontWeight: 400,
                color: colors.primary[500],
            },
        },
    },
    accordion: ({ expanded, index }) => ({
        maxHeight: expanded === index ? '100%' : '0',
        overflow: 'hidden',
        transition: 'ease-in-out 400ms',
        paddingBottom: expanded === index ? 16 : 0,
    }),
    expander: ({ expanded, index }) => ({
        '& > svg': {
            transform: expanded === index ? 'rotate(180deg)' : 'rotate(0deg)',
            transition: 'ease-in-out 400ms',
            '& path': {
                stroke: colors.greyscale[500],
            },
        },
    }),
    detail: {
        display: 'grid',
        gridAutoFlow: 'column',
        gridTemplateColumns: '1fr auto',
        alignItems: 'center',
        height: 40,
        borderBottom: '1px solid #E1E1E1',
        '& > b': {
            fontSize: 12,
            fontWeight: 600,
            color: colors.tertiary[500],
        },
        '& > span': {
            fontSize: 12,
            color: colors.greyscale[600],
        },
    },
    address: {
        maxWidth: '100%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
}))

const RevenueTableMobileRow = ({
    user,
    date,
    token,
    amount,
    type,
    externalID,
    index,
    expandToggleCb,
    expanded,
}) => {
    const classes = useStyles({ index, expanded })

    return (
        <div className={classes.root}>
            <div className={classes.alwaysVisible}>
                <span>{date}</span>
                <span className={classes.address}>
                    <a
                        href={`https://bscscan.com/tx/${externalID}`}
                        target={'_blank'}
                    >
                        {externalID}
                    </a>
                </span>
                <span
                    className={classes.expander}
                    onClick={() => expandToggleCb(index)}
                >
                    <DownArrowIcon data-color width={16} />
                </span>
            </div>

            <div className={classes.accordion}>
                <div className={classes.detail}>
                    <b>Type:</b> <span>{PAYMENT_TYPES[type]}</span>
                </div>
                <div className={classes.detail}>
                    <b>Crypto:</b>{' '}
                    <UserAvatar width={24} height={24} avatar={token?.avatar?.[128]} />
                </div>
                <div className={classes.detail}>
                    <b>Amount:</b> <span>${amount}</span>
                </div>
                <div className={classes.detail}>
                    <b>Subject:</b>{' '}
                    <UserAvatar
                        width={24}
                        height={24}
                        username={user?.username}
                        avatar={user?.avatar?.[128]}
                    />
                </div>
            </div>
        </div>
    )
}

export default RevenueTableMobileRow
