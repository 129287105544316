import axios from '../utilities/axios'

export const httpFetchNestedComments = ({ commentID, page, limit }) => {
    const url = `comments/${commentID}/nested-comments`
    return axios.get(url, { params: { page, limit } })
}

export const httpReplyToComment = ({ parentCommentID, comment }) => {
    const url = `/comments/${parentCommentID}`
    return axios.post(url, { comment })
}

export const httpLikeComment = ({ commentID }) => {
    const url = `comments/${commentID}/likes`
    return axios.post(url)
}

export const httpUnlikeComment = ({ commentID }) => {
    const url = `comments/${commentID}/likes`
    return axios.delete(url)
}
