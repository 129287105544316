import React from 'react'
import { createUseStyles } from 'react-jss'
import { Link } from 'react-router-dom'
import cx from 'classnames'

const useStyles = createUseStyles((theme) => ({
    linkWrapper: {
        width: '100%',
    },
}))

const LinkWrapper = ({ callback, pathname, children, classname, ...props }) => {
    const classes = useStyles({})
    return pathname ? (
        <Link
            to={pathname}
            {...props}
            className={cx(classname, classes.linkWrapper)}
            onClick={callback}
        >
            {children}
        </Link>
    ) : (
        <>{children}</>
    )
}

export default LinkWrapper
