import { createUseStyles, useTheme } from 'react-jss'
import React, { useEffect, useRef, useState } from 'react'
import Button from '../../../../components/Button'
import Popover from '../../../../components/Popover'
import { InfoIcon, PlusFramedIcon } from '../../../../theme/icons'
import Banner from '../../../../components/Banner'
import Media from '../../../../components/Media'
import MediaThumbnail from '../../../../components/MediaThumbnail'
import { useForm, FormProvider, useWatch } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import {
    handleApiError, secondsToMinutes,
} from '../../../../utilities/helpers'
import { httpCreatePost, httpUploadPostMedia } from '../../../../http-requests'
import Spinner from '../../../../components/Spinner'
import EmojiInput from '../../../../components/EmojiInput'
import TextArea from '../../../../components/TextArea'
import { POST_MODEL, validationSchema } from '../postModel'
import PublicPostSection from '../public-post-section'
import MediaLoader from '../../../../components/MediaLoader'
import axios from 'axios'
import { DevTool } from '@hookform/devtools'
import PaidPostSection from '../paid-post-section'
import Divider from '../../../../components/Divider'

const useStyles = createUseStyles((theme) => ({
    root: {
        ...theme.utils.grid.centered,
        gridTemplateColumns: '1fr',
        color: theme.palette.secondary.main,
    },
    bold: {
        fontWeight: 700,
    },
    body: {
        ...theme.utils.grid.centered,
        gridRowGap: theme.spacing * 2,
        gridTemplateColumns: '1fr',
        padding: theme.spacing * 2,
        paddingBottom: 0,
    },
    formContent: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing * 2,
    },
    action: {
        borderTop: `1px solid ${theme.palette.grey[300]}`,
        padding: theme.spacing * 2,
    },
    textArea: {
        zIndex: theme.zIndex.tooltip,
        '& textarea': {
            padding: [8, 24, 8, 12],
        },
    },
    mediaWrapper: {
        display: 'flex',
        flexDirection: 'row',
        gap: theme.spacing * 2,
        overflow: 'auto',
    },
    singleThumbnail: {
        ...theme.utils.flexbox.centered,
        height: 158,
        width: 158,
        borderRadius: 12,
        overflow: 'hidden',
    },
    uploadThumbnail: {
        extend: 'singleThumbnail',
        border: ` 2px solid ${theme.palette.disabled.light}`,
    },
    image: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
    },
    uploader: {
        ...theme.utils.flexbox.centered,
        flexDirection: 'column',
        gap: theme.spacing,
        ...theme.typography.label,
        color: theme.palette.primary.background,
        fontWeight: 600,
        cursor: ({ loading }) => (loading ? 'wait' : 'pointer'),
        '& input': {
            display: 'none',
        },
    },
    textActions: {
        display: 'flex',
        alignItems: 'center',
        gap: 8,
        '& > span:not(:last-child)': {
            paddingRight: 8,
            borderRight: `1px solid ${theme.palette.grey[300]}`,
        },
    },
    emojiInput: {
        zIndex: 2,
        '& > section': {
            bottom: 'unset !important',
            top: 40,
            right: '0 !important',
        },
    },
    banner: {
        padding: theme.spacing * 2,
    },
    bannerBody: {
        display: 'grid',
        gridTemplateColumns: ({ uploadedMediaError }) =>
            uploadedMediaError ? 'auto auto' : 'auto',
        gap: theme.spacing,
        '& svg': {
            width: 20,
        },
    },
    bannerTextWrapper: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing,
    },
    divider: {
        margin: [0, theme.spacing * 2],
    },
}))

const PostCreationModal = ({ onClose, onSuccess, user }) => {
    const theme = useTheme()
    const uploaderRef = useRef()
    const videoRefs = useRef([])
    const cancelFileUpload = useRef(null)
    const [loading, setLoading] = useState(false)
    const [media, setMedia] = useState([])
    const [uploadedMediaError, setUploadedMediaError] = useState(false)
    const [isLoadingPost, setIsLoadingPost] = useState(false)
    const [uploadError, setUploadError] = useState('')
    const [isLoadingCompleted, setIsLoadingCompleted] = useState(false)
    const [isMediaLoaderCanceled, setIsMediaLoaderCanceled] = useState(false)
    const [progress, setProgress] = useState(0)

    const currency = 'USD'
    const currencySymbol = '$'

    const {
        upload_limits,
    } = user

    const formMethods = useForm({
        shouldUnregister: false,
        mode: 'all',
        reValidateMode: 'all',
        nativeValidation: false,
        defaultValues: {},
        resolver: yupResolver(validationSchema),
    })
    const {
        handleSubmit,
        register,
        setValue,
        control,
        getValues,
        setError,
        setFocus,
        trigger,
        formState: { touchedFields, errors, isSubmitting, isValid },
    } = formMethods

    const isPremium = useWatch({
        control,
        name: POST_MODEL.isPremium,
        defaultValue: false,
    })

    const isPublic = useWatch({
        control,
        name: POST_MODEL.isPublic,
    })

    const onSubmit = async (formValues) => {
        let dataToSend = {
            content: formValues?.content,
            is_subscription_related: !isPublic,
            ...(isPublic && {
                price: 0,
            }),
            ...(isPremium && {
                is_subscription_related: false,
                price: formValues?.price,
            }),
            media: media?.map((mediaItem) => {
                return { path_location: mediaItem?.media }
            }),
        }
        try {
            setIsLoadingPost(true)
            await httpCreatePost(dataToSend)
            onSuccess()
            onClose()
        } catch (error) {
            setUploadError(error)
            handleApiError({
                isReduxError: false,
                error: error,
                callbackOnFieldError: setError,
            })
        } finally {
            setIsLoadingPost(false)
        }
    }

    const progressFn = (progressEvent) => {
        setProgress(
            Math.round((progressEvent.loaded * 100) / progressEvent.total)
        )
    }

    const cancelUpload = () => {
        if (cancelFileUpload.current) {
            cancelFileUpload.current.abort()
        }
    }

    const checkMedia = async (e) => {
        const mediaType = e?.target?.files[0]?.type.split(/(\/)/)[0]
        const vid = document.createElement('video')
        const fileURL = URL.createObjectURL(e?.target?.files[0])
        vid.src = fileURL
        if (mediaType !== 'video') {
            await uploader(e)
        } else {
            vid.ondurationchange = async () => {
                if (vid?.duration <= upload_limits?.max_video_duration_in_seconds) {
                    await uploader(e)
                }
                URL.revokeObjectURL(fileURL)
            }
        }
    }

    const uploader = async (e) => {
        const mediaType = e?.target?.files[0]?.type.split(/(\/)/)[0]
        const file = e?.target?.files[0]
        const fileSize = e?.target?.files[0]?.size / 1000000

        if (
            (mediaType === 'image' && fileSize >= (upload_limits?.max_image_size_in_mb ?? 5)) ||
            (mediaType === 'video' && fileSize >= (upload_limits?.max_video_size_in_mb ?? 100))
        ) {
            setUploadedMediaError(true)
            uploaderRef.current.value = ''
            return null
        } else {
            setUploadedMediaError(false)
        }

        const formData = new FormData()
        formData.append(`${mediaType}`, file)

        if (formData && (mediaType === 'video' || mediaType === 'image')) {
            const controller = new AbortController()
            cancelFileUpload.current = controller
            try {
                setIsMediaLoaderCanceled(false)
                setLoading(true)
                const options = {
                    onUploadProgress: progressFn,
                    signal: controller.signal,
                    /*cancelToken: new CancelToken((cancel) => {
                        cancelFileUpload.current = cancel
                    }),*/
                }
                const { data } = await httpUploadPostMedia(
                    formData,
                    mediaType,
                    options
                )
                setMedia((media) => [
                    ...media,
                    {
                        id: data?.id,
                        media: data?.path_location,
                        type: mediaType,
                        fileName: e?.target?.files[0].name,
                        fileSize: e?.target?.files[0].size / 1000000,
                        preview: data?.[128],
                        ...(mediaType === 'video' && {
                            videoPreview: data['original'],
                        }),
                    },
                ])
                setLoading(false)
                setProgress(0)
            } catch (error) {
                if (axios.isCancel(error)) {
                    setLoading(false)
                    setProgress(0)
                    setIsMediaLoaderCanceled(true)
                } else {
                    setUploadError(error)
                    setProgress(0)
                    handleApiError({
                        isReduxError: false,
                        error: error,
                        callbackOnFieldError: setError,
                    })
                }
            } finally {
                if (uploaderRef.current) {
                    uploaderRef.current.value = ''
                }
            }
        }
    }

    const handleRemove = (e, arrIndex) => {
        e.preventDefault()
        e.stopPropagation()
        setMedia(media.filter((mediaItem) => mediaItem?.id !== arrIndex))
        uploaderRef.current.value = ''
    }

    const handleVideoPlay = (index) => {
        videoRefs?.current[index].currentTime > 0.1 &&
        !videoRefs?.current[index].paused &&
        !videoRefs?.current[index].ended
            ? videoRefs?.current[index].pause()
            : videoRefs?.current[index].play()
    }

    useEffect(() => {
        setFocus(POST_MODEL.content)
    }, [setFocus])

    useEffect(() => {
        if (!isPremium) {
            setValue(POST_MODEL.price, 0, { shouldValidate: true })
        } else {
            setValue(POST_MODEL.price, '', { shouldValidate: true })
        }
    }, [isPremium])

    const classes = useStyles({ uploadedMediaError, loading })
    return (
        <>
            {loading && !isMediaLoaderCanceled && (
                <MediaLoader
                    progress={progress}
                    isCompleted={isLoadingCompleted}
                    onClose={() => {
                        setLoading(false)
                        setUploadError('')
                    }}
                    onCancel={() => {
                        cancelUpload()
                        setIsMediaLoaderCanceled(true)
                    }}
                    error={uploadError}
                />
            )}
            <Popover
                onClose={onClose}
                title={'New Post'}
                maxWidth={539}
                closeOnOverlay={false}
            >
                {isLoadingPost && <Spinner size={56} />}
                <div className={classes.root}>
                    <FormProvider {...formMethods}>
                        <form
                            onSubmit={handleSubmit(onSubmit)}
                            className={classes.formContent}
                        >
                            <div className={classes.body}>
                                <TextArea
                                    placeholder={'Compose new post'}
                                    setFocus={setFocus}
                                    icon={
                                        <EmojiInput
                                            onSelection={(textWithEmoji) => {
                                                setValue(
                                                    POST_MODEL.content,
                                                    textWithEmoji
                                                )
                                                setFocus(POST_MODEL.content)
                                                trigger(POST_MODEL.content)
                                            }}
                                            getCurrentValue={() =>
                                                getValues(POST_MODEL.content)
                                            }
                                            className={classes.emojiInput}
                                        />
                                    }
                                    className={classes.textArea}
                                    errors={errors[POST_MODEL.content]}
                                    {...register(POST_MODEL.content)}
                                />
                                <Banner
                                    className={classes.banner}
                                    variant={
                                        uploadedMediaError ? 'error' : 'default'
                                    }
                                    text={
                                        <div className={classes.bannerBody}>
                                            {uploadedMediaError && (
                                                <InfoIcon
                                                    stroke={
                                                        theme.palette.error.main
                                                    }
                                                />
                                            )}
                                            <div
                                                className={
                                                    classes.bannerTextWrapper
                                                }
                                            >
                                                {uploadedMediaError && (
                                                    <div
                                                        className={classes.bold}
                                                    >
                                                        The selected media
                                                        exceeds the limits
                                                    </div>
                                                )}
                                                <span>
                                        Remember, you can upload up to{' '}
                                                    <span className={classes.bold}>
                                            {upload_limits?.max_video_size_in_mb ?? 300}MB.
                                        </span>
                                        Videos cannot exceed {`${secondsToMinutes(upload_limits?.max_video_duration_in_seconds ?? 220)} `}
                                                    and you cannot upload photos
                                        larger than {upload_limits?.max_image_size_in_mb ?? 5}MB.
                                    </span>
                                            </div>
                                        </div>
                                    }
                                    icon={
                                        uploadedMediaError ? null : (
                                            <InfoIcon
                                                stroke={
                                                    theme.palette[
                                                        uploadedMediaError
                                                            ? 'error'
                                                            : 'tertiary'
                                                    ].main
                                                }
                                            />
                                        )
                                    }
                                />
                                <div className={classes.mediaWrapper}>
                                    <MediaThumbnail htmlFor={'photo'}>
                                        <div className={classes.uploader}>
                                            <PlusFramedIcon htmlFor={'photo'} />
                                            Add photo/video
                                            {!loading && (
                                                <input
                                                    type="file"
                                                    id="photo"
                                                    onChange={uploader}
                                                    accept="image/jpg, image/png, image/jpeg, image/heif, image/heic, video/mp4, video/mov, video/qt, video/quicktime"
                                                    ref={uploaderRef}
                                                />
                                            )}
                                        </div>
                                    </MediaThumbnail>
                                    {media.map((mediaItem, index) => {
                                        return (
                                            <MediaThumbnail
                                                type={mediaItem?.type}
                                                count={index + 1}
                                                length={media.length}
                                                fileSize={`${mediaItem?.fileSize.toFixed(
                                                    2
                                                )}MB`}
                                                fileName={`${mediaItem?.fileName}`}
                                                handleRemove={(e) =>
                                                    handleRemove(
                                                        e,
                                                        mediaItem?.id
                                                    )
                                                }
                                                handlePlay={() =>
                                                    handleVideoPlay(index)
                                                }
                                            >
                                                {mediaItem?.type === 'image' ? (
                                                    <Media
                                                        className={
                                                            classes.image
                                                        }
                                                        image={
                                                            mediaItem?.preview
                                                        }
                                                    />
                                                ) : (
                                                    <video
                                                        ref={(el) =>
                                                            (videoRefs.current[
                                                                index
                                                            ] = el)
                                                        }
                                                        className={
                                                            classes.image
                                                        }
                                                    >
                                                        <source
                                                            src={`${mediaItem?.videoPreview}#t=0.1`}
                                                        />
                                                    </video>
                                                )}
                                            </MediaThumbnail>
                                        )
                                    })}
                                </div>
                            </div>
                            <Divider className={classes.divider} />
                            <PublicPostSection
                                user={user}
                                isPublic={isPublic}
                                isPremium={isPremium}
                            />
                            <PaidPostSection
                                user={user}
                                currency={currency}
                                currencySymbol={currencySymbol}
                                isPublic={isPublic}
                                isPremium={isPremium}
                            />
                            <div className={classes.action}>
                                <Button
                                    width={'100%'}
                                    type={'submit'}
                                    disabled={!isValid || isSubmitting}
                                >
                                    Publish Post
                                </Button>
                            </div>
                        </form>
                    </FormProvider>
                </div>
                {/*<DevTool control={control} />*/}
            </Popover>
        </>
    )
}

export default PostCreationModal
