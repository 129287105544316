import { createUseStyles } from 'react-jss'
import { NavLink } from 'react-router-dom'
import { ArrowRightIcon } from '../../../../theme/icons'
import colors from '../../../../theme/colors'
import Divider from '../../../../components/Divider'

const useStyles = createUseStyles((theme) => ({
    root: {},
    tab: {
        display: 'grid',
        gridTemplateColumns: 'auto 1fr auto',
        gridColumnGap: 24,
        alignItems: 'center',
        '& span': {
            transition: 'ease-in-out 300ms',
            color: theme.palette.label.color,
        },
        '& svg': {
            transition: 'ease-in-out 300ms',
            fill: theme.palette.label.color,
        },
        '& .withStroke, .onlyPath, .withRectStroke': {
            '& path': {
                transition: 'ease-in-out 300ms',
            },
        },
        '& .withStroke': {
            '& path': {
                stroke: theme.palette.label.color,
            },
        },
        '& .onlyPath': {
            '& path': {
                fill: theme.palette.label.color,
            },
        },
        '& .onlyStroke': {
            '& path': {
                stroke: theme.palette.label.color,
                fill: 'none',
            },
        },
        '&:hover': {
            textDecoration: 'none',
            '& span': {
                color: theme.palette.text.primary,
            },
            '& svg': {
                fill: theme.palette.text.primary,
            },
            '& .withStroke': {
                '& path': {
                    stroke: 'url(#gradient)',
                },
            },
            '& .onlyPath': {
                '& path': {
                    fill: 'url(#gradient)',
                },
            },
            '& .onlyStroke': {
                '& path': {
                    stroke: 'url(#gradient)',
                },
            },
        },
    },
    tabSelected: {
        '& span': {
            color: theme.palette.text.primary,
        },
        '& svg': {
            fill: theme.palette.text.primary,
        },
        '& .withStroke': {
            '& path': {
                stroke: 'url(#gradient)',
            },
        },
        '& .onlyPath': {
            '& path': {
                fill: 'url(#gradient)',
            },
        },
        '& .onlyStroke': {
            '& path': {
                stroke: 'url(#gradient)',
            },
        },
    },
}))

const SidebarItem = ({ link, icon, label, withDivider }) => {
    const classes = useStyles()

    return (
        <>
            <NavLink
                to={link}
                className={(navData) =>
                    navData.isActive
                        ? `${classes.tab} ${classes.tabSelected}`
                        : `${classes.tab}`
                }
            >
                {icon}
                <span>{label}</span>
                <ArrowRightIcon data-color width={34} />
            </NavLink>
            {withDivider && <Divider />}
        </>
    )
}

export default SidebarItem
