import { createUseStyles, useTheme } from 'react-jss'
import { Picker as EmojiPicker } from 'emoji-mart'
import 'emoji-mart/css/emoji-mart.css'
import { SmileIcon } from '../theme/icons'
import { useEffect, useRef, useState } from 'react'
import { useWindowSize } from '../hooks/useWindowSize'
import useClickOutside from '../hooks/useClickOutside'
import cx from 'classnames'

const useStyles = createUseStyles((theme) => ({
    root: {},
    selectorWrapper: {
        position: 'relative',
    },
    emojiIcon: {
        cursor: ({ disabled }) => !disabled && 'pointer',
    },
    emojiPicker: {
        position: 'absolute',
    },
}))

const EmojiInput = ({
    onSelection,
    getCurrentValue,
    className,
    disabled,
    emojiPickerStyles = {
        position: 'absolute',
        bottom: 40,
        right: -96,
        maxWidth: 320,
    },
}) => {
    const emojiPickerRef = useRef()
    const [showPicker, setShowPicker] = useState(false)
    const { width } = useWindowSize()
    const [isMobile, setIsMobile] = useState(width <= 448)
    useEffect(() => {
        setIsMobile(width <= 448)
    }, [width])

    useClickOutside([emojiPickerRef], () => setShowPicker(false))

    const addEmoji = (emoji) => {
        if ('native' in emoji) {
            onSelection(`${getCurrentValue()}${emoji.native}`)
        }
    }

    const theme = useTheme()
    const classes = useStyles({ disabled })

    return (
        <span
            className={cx(classes.selectorWrapper, className)}
            ref={emojiPickerRef}
        >
            <span
                className={classes.emojiIcon}
                onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    setShowPicker(!showPicker)
                }}
            >
                <SmileIcon
                    width={isMobile ? 22 : 24}
                    height={isMobile ? 22 : 24}
                />
            </span>
            {showPicker && !disabled && (
                <EmojiPicker
                    style={emojiPickerStyles}
                    emoji=""
                    title=""
                    native={true}
                    onSelect={addEmoji}
                    showPreview={false}
                    showSkinTones={false}
                    color={theme.palette.primary.main}
                />
            )}
        </span>
    )
}

export default EmojiInput
