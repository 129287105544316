import React, { useEffect } from 'react'
import { createUseStyles } from 'react-jss'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { CloseIcon } from '../theme/icons'
import { useWindowSize } from '../hooks/useWindowSize'

const useStyles = createUseStyles((theme) => ({
    overlay: {
        ...theme.utils.flexbox.centered,
        position: 'fixed',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        overflow: 'visible',
        backgroundColor: 'rgba(31, 31, 31, 0.1)',
        backdropFilter: 'blur(4px)',
        '-webkit-backdrop-filter': 'blur(4px)',
        zIndex: theme.zIndex.modalOverlay,
    },
    popover: {
        display: 'flex',
        flexDirection: 'column',
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: theme.palette.common.white,
        borderRadius: 16,
        width: '100%',
        zIndex: theme.zIndex.modal,
        maxWidth: ({ maxWidth }) => maxWidth,
        maxHeight: '100vh',
        [theme.mediaQueries.m]: {
            width: ({ width }) => `calc(${width}px - ${theme.spacing * 2}px`,
        },
    },
    header: ({ title, fontWeight, headerFontSize }) => ({
        flexDirection: 'row-reverse',
        ...(title
            ? {
                  ...theme.utils.flexbox.spaceBetween,
                  borderBottom: `1px solid ${theme.palette.grey[300]}`,
              }
            : {
                  ...theme.utils.flexbox.centered,
                  justifyContent: 'end',
              }),
        minHeight: 64,
        fontSize: headerFontSize,
        fontWeight: fontWeight,
        color: theme.palette.primary.background,
        padding: [0, theme.spacing * 2],

        '& svg': {
            cursor: 'pointer',
            height: 24,
        },
    }),
    body: {
        overflowY: 'scroll',
        maxHeight: ({ maxHeight }) => maxHeight ?? '100vh',
        '&::-webkit-scrollbar': {
            display: ({ hideScrollabar }) =>
                hideScrollabar ? 'none' : 'block',
        },
    },
}))

const Popover = ({
    onClose,
    maxWidth,
    maxHeight,
    children,
    title,
    fontWeight = 700,
    headerFontSize = 18,
    className,
    bodyClassName,
    hideScrollabar = true,
    closeOnOverlay = true,
}) => {
    const { width } = useWindowSize()
    useEffect(() => {
        window.document.body.style['overflow-y'] = 'hidden' // lock body scroll
        return () => (window.document.body.style['overflow-y'] = 'auto') // unlock body scroll;
    }, [])

    const classes = useStyles({
        maxWidth,
        maxHeight,
        title,
        width,
        fontWeight,
        hideScrollabar,
        headerFontSize,
    })

    return (
        <>
            <div
                className={classes.overlay}
                {...(closeOnOverlay && { onClick: onClose })}
            />
            <div className={cx(classes.popover, className)}>
                <header className={classes.header}>
                    <CloseIcon onClick={onClose} />
                    {title}
                </header>
                <div className={cx(classes.body, bodyClassName)}>
                    {children}
                </div>
            </div>
        </>
    )
}

Popover.propTypes = {
    maxWidth: PropTypes.number,
    maxHeight: PropTypes.number,
}

export default Popover
