import React from 'react'
import { createUseStyles } from 'react-jss'
import { useNavigate } from 'react-router-dom'
import Back from '../../../../components/Back'

const useStyles = createUseStyles((theme) => ({
    messagesPanelHeader: {
        ...theme.utils.flexbox.centered,
        flexDirection: 'column',
        paddingTop: 8,
    },
    section: {
        ...theme.utils.flexbox.spaceBetween,
        width: '100%',
        flexDirection: 'column',
        gap: 16,
        padding: 16,
        borderBottom: '1px solid #F3F3F3',
    },
    title: {
        color: theme.palette.text.primary,
        width: '100%',
        fontWeight: 600,
        letterSpacing: 0.5,
        fontSize: 16,
    },
    back: {
        width: '100%',
        display: 'flex',
        padding: [0, 10],
    },
}))

const MessagesPanelHeader = ({ isMobilePage, children }) => {
    const navigate = useNavigate()

    const classes = useStyles({})
    return (
        <div className={classes.messagesPanelHeader}>
            {isMobilePage && (
                <div className={classes.back}>
                    <Back callback={() => navigate(-1)} />
                </div>
            )}
            <div className={classes.section}>
                <div className={classes.title}>Chat</div>
                {children}
            </div>
        </div>
    )
}

export default MessagesPanelHeader
