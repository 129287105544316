import React, { forwardRef, useEffect, useState } from 'react'
import { createUseStyles } from 'react-jss'
import EmojiInput from './EmojiInput'
import colors from '../theme/colors'

const useStyles = createUseStyles((theme) => ({
    textarea: {
        boxSizing: 'border-box',
        border: 'none',
        borderRadius: '3px',
        resize: 'none',
        lineHeight: '24px',
        overflow: 'auto',
        height: 'auto',
        padding: 0,
    },
    inputArea: {
        width: '100%',
        minHeight: 48,
        height: 'auto',
        display: 'grid',
        gridTemplateColumns: '1fr min-content min-content min-content',
        gap: 8,
        alignItems: 'center',
        border: `2px solid ${colors.greyscale[300]}`,
        borderRadius: 30,
        padding: [6, 8],
        '& input': {
            width: 50,
            overflowWrap: 'break-word',
            border: 'none',
            padding: [0, 0, 0, 8],
        },
    },
    emojiInput: {
        marginTop: 4,
        placeSelf: 'flex-end',
    },
}))

const TextareaAutoresizing = forwardRef(function TextArea(
    { placeholder, callback, withEmoji, setValue, ...props },
    ref
) {
    const [rows, setRows] = useState(1)
    const [text, setText] = useState('')

    const minRows = 1
    const maxRows = 10

    const handleResize = (event) => {
        const textareaLineHeight = 24

        const previousRows = event.target.rows
        event.target.rows = minRows // reset number of rows in textarea

        const currentRows = ~~(event.target.scrollHeight / textareaLineHeight)

        if (currentRows === previousRows) {
            event.target.rows = currentRows
        }

        if (currentRows >= maxRows) {
            event.target.rows = maxRows
            event.target.scrollTop = event.target.scrollHeight
        }

        setText({
            type: 'text',
            value: event.target.value,
        })
        setRows(currentRows < maxRows ? currentRows : maxRows)
    }

    useEffect(() => {
        if (callback && !!text?.value) {
            callback(text?.value)
        }
    }, [text])

    const classes = useStyles()
    return (
        <div className={classes.inputArea}>
            <textarea
                {...props}
                rows={rows}
                value={text?.value}
                placeholder={placeholder ?? 'Write a message...'}
                className={classes.textarea}
                onChange={handleResize}
                ref={ref}
            />
            {withEmoji && (
                <EmojiInput
                    emojiPickerStyles={{
                        position: 'absolute',
                        top: 40,
                        right: 0,
                        maxWidth: 320,
                        zIndex: 2,
                    }}
                    className={classes.emojiInput}
                    getCurrentValue={() => text?.value ?? ''}
                    onSelection={(textWithEmoji) =>
                        setText({
                            type: 'text',
                            value: textWithEmoji,
                        })
                    }
                />
            )}
        </div>
    )
})

export default TextareaAutoresizing
