import { createUseStyles } from 'react-jss'
import { ROUTE_HOME } from '../../utilities/constants'
import { Link } from 'react-router-dom'
import logo from '../../asset/images/logoWhite.png'
import image from '../../asset/images/pageNotFound/404.svg'
import Button from "../../components/Button";
import {SideArrowIcon} from "../../theme/icons";

const useStyles = createUseStyles((theme) => ({
    root: {
        display: 'grid',
        alignContent: 'center',
        padding: [56, 0],
        width: '100%',
        height: '100%',
        position: 'fixed',
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        overflow: 'auto',
        backgroundColor: theme.palette.text.primary,
    },
    text: {
        padding: 16,
        display: 'grid',
        alignContent: 'end',
        justifyContent: 'center',
        gap: 8,
        justifyItems: 'center',
        textAlign: 'center',
        '& h1': {
            fontSize: 32,
            fontWeight: 700,
            color: theme.palette.common.white,
            fontFamily: theme.typography.defaultFontFamily,
            letterSpacing: -0.5,
            [theme.mediaQueries.lUp]: {
                fontSize: 56,
            },
        },
        '& h2': {
            fontSize: 32,
            fontWeight: 700,
            color: theme.palette.common.white,
            fontFamily: theme.typography.defaultFontFamily,
            letterSpacing: -0.5,
            [theme.mediaQueries.lUp]: {
                fontSize: 56,
            },
            marginTop: -16,
        },
        '& span': {
            fontSize: 32,
            fontWeight: 700,
            color: theme.palette.secondary.main,
            fontFamily: theme.typography.defaultFontFamily,
            letterSpacing: -0.5,
            [theme.mediaQueries.lUp]: {
                fontSize: 56,
            },
        },
        '& p': {
            maxWidth: 624,
            fontSize: 16,
            color: theme.palette.common.white,
            [theme.mediaQueries.lUp]: {
                fontSize: 24,
            },
        },
        '& a': {
            color: theme.palette.primary.main,
            fontWeight: 500,
        },
    },
    logo: {
        maxWidth: 186,
    },
    background: {
        width: '100%',
        paddingTop: '63%',
        backgroundImage: `url('${image}')`,
        backgroundSize: '150%',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center bottom',
        [theme.mediaQueries.lUp]: {
            backgroundSize: 'contain',
            paddingTop: '48%',
        },
    },
    button:{
        textAlign: 'center',
        '& a': {
            color: theme.palette.common.white,
            fontWeight: "bold",
            width: 231,
            height: 56,
            backgroundColor: theme.palette.primary.main,
            margin: [10, 0],
            padding: [16, 32],
            borderRadius: [12, 12, 12, 12],
            fontSize: 18,
            letterSpacing: 1.25,
        },
    },
}))

const buttonStyle = {
    width: '231px',
    height: '56px',
}

const PageNotFound = () => {
    const classes = useStyles()

    return (
        <div className={classes.root}>
            <div className={classes.text}>
                <img className={classes.logo} src={logo} alt={'logo'} />
                <h1>Error <span>404</span></h1>
                <h2>Page not found</h2>
                <p>
                    The page you are locking for doesn’t exist or an other error
                    occured
                </p>
            </div>
            <div className={classes.background} />
            <div className={classes.button}>
                <a href={ROUTE_HOME}>Let's get you back</a>
            </div>
        </div>
    )
}

export default PageNotFound
