import { createUseStyles } from 'react-jss'
import Button from '../../../../../../components/Button'
import { setSubscriptionModalOpen } from '../../../../../../store/slices/app'
import { useDispatch } from 'react-redux'

const useStyles = createUseStyles((theme) => ({
    root: {
        padding: [16],
    },
}))

const ExpiredSubscriptionFooter = ({ user }) => {
    const dispatch = useDispatch()

    const classes = useStyles()
    return (
        <div className={classes.root}>
            <Button
                width={'100%'}
                variant={'filled'}
                size={'medium'}
                onClick={() => dispatch(setSubscriptionModalOpen({ isOpen: true, user }))}
            >
                Subscribe for ${user?.subscriptions_dollar_price}/month
            </Button>
        </div>
    )
}

export default ExpiredSubscriptionFooter
