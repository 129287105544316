import React from 'react'
import { createUseStyles } from 'react-jss'
import { notificationsPlatformAssets } from '../../../../../utilities/constants/notifications'

const useStyles = createUseStyles((theme) => ({
    platformSection: ({ color }) => ({
        display: 'flex',
        flexDirection: 'column',
        gap: 8,
        width: '100%',
        minHeight: 73,
        border: `1px solid ${color}`,
        borderRadius: 10,
        padding: 12,
    }),
    labelWrapper: {
        ...theme.utils.flexbox.spaceBetween,
        '& svg': {
            cursor: 'pointer',
            width: 18,
        },
    },
    label: ({ color, backgroundColor }) => ({
        width: 'max-content',
        display: 'flex',
        alignItems: 'center',
        gap: 4,
        padding: 4,
        borderRadius: 100,
        backgroundColor: backgroundColor,
        fontSize: 10,
        color: color,
        '& svg': {
            width: 16,
        },
    }),
    message: {
        fontSize: 12,
        fontWeight: 400,
        color: '#585858',
    },
}))

const PlatformSection = ({ type, message }) => {
    const asset = notificationsPlatformAssets?.[type]

    const classes = useStyles({
        color: asset?.color,
        backgroundColor: asset?.backgroundColor,
    })
    return (
        <div className={classes.platformSection}>
            <div className={classes.labelWrapper}>
                <div className={classes.label}>
                    {asset?.icon} {asset?.type} message
                </div>
            </div>
            <div className={classes.message}>{message}</div>
        </div>
    )
}

export default PlatformSection
