import { createUseStyles } from 'react-jss'
import React, { useMemo } from 'react'
import ItemText from '../itemText/ItemText'
import ChatContextMenu from '../../../chatContextMenu/ChatContextMenu'
import dayjs from 'dayjs'
import UserAvatar from '../../../../../../homepage/posts-feed/post/post-header/user-info/user-avatar'
import { TipFilledIcon } from '../../../../../../../theme/icons'
import colors from '../../../../../../../theme/colors'
import { tipAssets } from '../../../../../../../utilities/constants/tips'
import {useSelector} from "react-redux";
import {selectUser} from "../../../../../../../store/slices/user";
import {selectPresence} from "../../../../../../../store/slices/conversations";

const useStyles = createUseStyles((theme) => ({
    itemTip: {
        display: 'flex',
        flexDirection: 'column',
        gap: 8,
    },
    row: ({ isMine }) => ({
        display: 'flex',
        justifyContent: 'flex-end',
        flexDirection: isMine ? 'row' : 'row-reverse',
        gap: 8,
    }),
    tipWrapper: {
        display: 'flex',
        flexDirection: 'column',
        gap: 8,
    },
    tipFrame: ({ hasText, isMine }) => ({
        display: 'flex',
        alignItems: 'center',
        gap: 8,
        padding: 10,
        minHeight: 60,
        minWidth: 186,
        borderRadius: 16,
        boxShadow:
            '0px 1px 3px rgba(20, 20, 42, 0.1), 0px 0px 1px rgba(20, 20, 42, 0.05)',
        backgroundColor: 'white',
        ...(isMine
            ? { marginRight: hasText ? 50 : 0 }
            : { marginLeft: hasText ? 50 : 0 }),
    }),
    date: {
        fontSize: 10,
        fontWeight: 500,
        color: '#AEAEAE',
        letterSpacing: 0.25,
    },
    avatar: {
        placeSelf: 'flex-end',
    },
    textWrapper: {
        display: 'flex',
        flexDirection: 'column',
        gap: 8,
        fontSize: 14,
        letterSpacing: 0.75,
        color: colors.tertiary[800],
    },
    tipTitle: {
        fontWeight: 600,
    },
    tipInfo: {
        fontWeight: 400,
    },
}))

const ItemTip = ({ message, isMobile, userData, hasContextMenu = false }) => {
    const me = useSelector(selectUser)
    const isMine = message?.uuid === me?.pubnub_id
    const date = dayjs(message?.timetoken / 10000).format('h:mm A ')
    const chatPresence = useSelector(selectPresence)
    const isOnline = chatPresence[userData?.channel]?.occupancy > 1

    const messageResponse = useMemo(
        () => {
            let response = {}
            try {
                response = JSON.parse(message?.meta?.response)
            }catch (err){
                console.log('cannot read tip data' )
            }
            return response
        },
        [message]
    )
    const hasText = messageResponse?.description

    const avatar = userData?.avatar?.[128]

    const classes = useStyles({ isMine, hasText })
    return (
        <div className={classes.itemTip}>
            <div className={classes.row}>
                {hasText && (
                    <ItemText
                        message={{...message, message: messageResponse.description}}
                        userData={userData}
                        isMobile={isMobile}
                        isMine={isMine}
                    />
                )}
            </div>
            <div className={classes.tipWrapper}>
                {!hasText && (
                    <div className={classes.row}>
                        <div className={classes.date}>{date}</div>
                    </div>
                )}
                <div className={classes.row}>
                    {!hasText && (
                        <ChatContextMenu
                            isMobile={isMobile}
                            message={{...message, message: messageResponse.description}}
                            isMine={isMine}
                        />
                    )}
                    <div className={classes.tipFrame}>
                        <TipFilledIcon />
                        <div className={classes.textWrapper}>
                            <div className={classes.tipTitle}>
                                I sent you a tip
                            </div>
                            <div className={classes.tipInfo}>
                                {tipAssets.hasOwnProperty(messageResponse?.total_token_amount_usd_value)
                                    ? tipAssets[messageResponse?.total_token_amount_usd_value]?.name
                                    : `$${messageResponse?.total_token_amount_usd_value}`}
                            </div>
                        </div>
                    </div>
                    {!hasText && (
                        <div className={classes.avatar}>
                            <UserAvatar
                                avatar={avatar}
                                height={42}
                                width={42}
                                isOnline={isMine || isOnline}
                            />
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default ItemTip
