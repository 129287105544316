import React from 'react'

export const searchbarCustomStyle = (theme) => {
    return {
        input: (provided) => ({
            ...provided,
            padding: 0,
            margin: 0,
            overflow: 'hidden',
            minWidth: '100%',
            '& input': {
                borderRadius: 0,
            },
        }),
        selectContainer: (provided) => ({
            ...provided,
            width: '100%',
        }),
        container: (provided) => ({
            ...provided,
            position: 'unset',
            width: '100%',
            overflow: 'hidden',

        }),
        valueContainer: (provided) => ({
            ...provided,
            padding: 0,
            display: 'grid',
            gridTemplateColumns: 'min-content 1fr',
            gap: 8,
            '& div:nth-child(1)': {
                gridColumnStart: 1,
                gridRowStart: 1,
            },
            '& div:nth-child(2)': {
                gridColumnStart: 2,
            },
            '& div:nth-child(3)': {
                gridColumnStart: 2,
            },
        }),
        control: () => ({
            ...theme.utils.flexbox.centered,
            width: '100%',
            borderRadius: 100,
            backgroundColor: theme.palette.disabled.light,
            [theme.mediaQueries.mUp]: {
                width: 260,
            },
        }),
        menu: () => ({
            position: 'fixed',
            left: 0,
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: 'white',
            borderRadius: 14,
            marginTop: 30,
            overflow: 'hidden',
            zIndex: theme.zIndex.modal,
            '&::-webkit-scrollbar': {
                display: 'none',
            },
            [theme.mediaQueries.mUp]: {
                position: 'absolute',
                left: '50%',
                transform: 'translateX(-50%)',
                width: 280,
                marginTop: 20,
                boxShadow: '0px 8px 16px rgba(20, 20, 42, 0.1), 0px 0px 1px rgba(48, 49, 51, 0.05)',
            },
        }),
        menuList: () => ({
            padding: theme.spacing,
            '& > div:not(:last-child)': {
                borderBottom: `1px solid ${theme.palette.divider.external}`,
            },
            [theme.mediaQueries.mUp]: {
                '& > div:not(:last-child)': {
                    borderBottom: 0,
                },
            },
        }),
        option: () => ({
            width: '100%',
            '& :hover': {
                backgroundColor: '#FAFAFA',
                borderRadius: 8,
            },
            padding: theme.spacing,
            [theme.mediaQueries.mUp]: {
                padding: 0,
            },
        }),
        loadingIndicator: (provided) => ({
            ...provided,
            marginRight: '-14px !important',
        }),
        indicatorSeparator: () => ({
            display: 'none',
        }),
        indicatorContainer: () => ({
            padding: 0,
        }),
    }
}

