import { createUseStyles } from 'react-jss'
import Button from '../../../../components/Button'
import Accordion from '../../../../components/Accordion'
import Banner from '../../../../components/Banner'
import { CheckedIcon } from '../../../../theme/icons'
import { useDispatch, useSelector } from 'react-redux'
import { selectUser } from '../../../../store/slices/user'
import { ROUTE_SIGNUP } from '../../../../utilities/constants'
import { useNavigate } from 'react-router-dom'
import {setSubscriptionModalOpen} from '../../../../store/slices/app'

const useStyles = createUseStyles((theme) => ({
    root: {
        padding: theme.spacing * 2,
    },
    bold: {
        fontWeight: 700,
    },
    accordionItems: {
        width: '100%',
    },
}))

// MOCK
const currency = '$'

const CoverFooter = ({ user }) => {
    const dispatch = useDispatch()
    const userMe = useSelector(selectUser)
    const navigate = useNavigate()
    const canReactivate = user?.subscription?.status === 'inactive'

    const handleSubscription = () => {
        userMe
            ? dispatch(setSubscriptionModalOpen({ isOpen: true, user }))
            : navigate(ROUTE_SIGNUP)
    }
    const classes = useStyles()
    return (
        <>
            {user?.is_creator && (
                <div className={classes.root}>
                    {user?.subscription?.status === 'active' ? (
                        <Accordion
                            itemsClassName={classes.accordionItems}
                            label={
                                <span>
                                    <span className={classes.bold}>
                                        Subscribed:
                                    </span>{' '}
                                    {!!user?.subscriptions_dollar_price
                                        ? `$${user?.subscriptions_dollar_price?.toFixed(
                                              2
                                          )} per month`
                                        : `Free`}
                                </span>
                            }
                        >
                            <Banner
                                data={'secondary'}
                                text={'Full access to this user’s content'}
                                icon={<CheckedIcon />}
                                height={40}
                            />
                            <Banner
                                data={'secondary'}
                                text={'Cancel your subscriptions at any time'}
                                icon={<CheckedIcon />}
                                height={40}
                            />
                        </Accordion>
                    ) : (
                        <Button width={'100%'} onClick={handleSubscription}>
                            {canReactivate
                                ? 'Reactivate'
                                : !!user?.subscriptions_dollar_price
                                ? `Subscribe for ${currency}${user?.subscriptions_dollar_price}/month`
                                : 'Subscribe for Free'}
                        </Button>
                    )}
                </div>
            )}
        </>
    )
}

export default CoverFooter
