import { createUseStyles } from 'react-jss'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { VerifiedBadgeIcon } from '../theme/icons'
import { getInitials } from '../utilities/helpers'

const useStyles = createUseStyles((theme) => ({
    root: {
        textAlign: 'left',
        display: 'grid',
        gridTemplateColumns: 'auto 1fr auto',
        gridTemplateRows: 'auto',
        gridTemplateAreas: `'image content action'`,
        gap: theme.spacing * 1.5,
        padding: ({ image }) => (!image ? 16 : '8px 16px 8px 8px'),
        boxShadow:
            '0px 1px 3px rgba(20, 20, 42, 0.1), 0px 0px 1px rgba(20, 20, 42, 0.05)',
        height: ({ image }) => (!image ? 116 : 100),
        borderRadius: theme.spacing * 2,
        cursor: 'pointer',
        margin: [16, 'auto'],
    },
    image: {
        ...theme.utils.grid.centered,
        width: 84,
        height: 84,
        gridArea: 'image',
        backgroundColor: ({ color }) =>
            color
                ? theme.palette[color].backgroundLight
                : theme.palette.primary.background,
        backgroundSize: ({ backgroundSize }) => backgroundSize,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundImage: ({ image }) => `url('${image}')`,
        borderRadius: 12,
        '& svg': {
            padding: theme.spacing * 4,
            width: 84,
            height: 84,
        },
        fontSize: 28,
        fontWeight: 500,
        color: theme.palette.primary.main,
    },
    content: {
        gridArea: 'content',
        display: 'grid',
        alignContent: 'center',
        wordBreak: 'break-word',
    },
    overline: ({ isCreators, image }) => ({
        display: 'flex',
        alignItems: 'center',
        ...(image
            ? {
                  ...theme.typography.h5,
                  color: theme.palette.text.primary,
                  fontSize:  isCreators ? 10 : 'max(0.83rem, min(1rem + 16vw, 1.25rem))',
                  fontWeight: isCreators ? 400 : 700,
                  letterSpacing: 0,
              }
            : {
                  ...theme.typography.label,
                  color: theme.palette.text.primary,
              }),
    }),
    category: {
        display: "contents",
        color: theme.palette.primary.middle,
        fontSize:  10,
        '& span': {
            color: theme.palette.text.primary,
            display: "contents",
            fontWeight: 900,
        },
    },
    text: ({ isCreators }) => ({
        ...theme.typography.overline,
        fontSize:  isCreators ? 14 : 'max(0.75rem, min(1rem + 16vw, 0.75rem))',
        fontWeight: isCreators ? 800 : 600,
        letterSpacing: isCreators ? 0.1 : 0.5,
        textTransform: isCreators ? 'none': 'uppercase',
        justifyContent: "start",
        margin: [4, 0],
        color: isCreators
            ? theme.palette.text.primary
            : theme.palette.quaternary.main,
        '& svg': {
            height: 16,
            margin: 'auto',
            verticalAlign: 'middle',
        },
    }),
    description: {
        fontSize: 12,
        fontWeight: 400,
        color: theme.palette.quaternary.main,
    },
    action: {
        ...theme.utils.grid.centered,
        gridArea: 'action',
    },
}))

const FormCard = ({
    image,
    color = 'primary',
    overline,
    text,
    backgroundSize = 'cover',
    description,
    children,
    className,
    action,
    hasBadge,
    isCreators,
    category
}) => {
    const classes = useStyles({ color, image, action, hasBadge, backgroundSize, isCreators })

    return (
        <label className={cx(classes.root, className)}>
            {!!image ? (
                <div className={classes.image} />
            ) : (
                <div className={classes.image}>{getInitials(text)}</div>
            )}
            <div className={classes.content}>
                <div className={cx(classes.text, className)}>
                    <div style={{float: 'left'}}>
                        {text}
                    </div>
                    {hasBadge && <VerifiedBadgeIcon width={20}/>}
                </div>
                <div className={classes.overline}>
                    {overline}
                    { isCreators
                        ? <div className={classes.category}>
                              <span>&nbsp;·&nbsp;</span>
                              {category}
                          </div>
                        : ''}
                </div>
                {description && (
                    <div className={classes.description}>{description}</div>
                )}
            </div>
            {children && <div className={classes.action}>{children}</div>}
        </label>
    )
}

export default FormCard

FormCard.propTypes = {
    color: PropTypes.oneOf(['primary', 'secondary', 'tertiary', 'quaternary']),
}
