import {createUseStyles} from 'react-jss'
import colors from '../../../../../theme/colors'
import Box from '../../../../../components/Box'
import Divider from '../../../../../components/Divider'
import Button from '../../../../../components/Button'
import WalletToken from '../wallet-token'

const useStyles = createUseStyles((theme) => ({
    network: {
        display: 'grid',
        gridTemplateColumns: '1fr',
        alignItems: 'center',
        marginTop: 16,
        padding: 16,
        '& h6': {
            fontSize: 14,
            fontWeight: 700,
            display: 'grid',
            gridTemplateColumns: '1fr',
            color: theme.palette.text.primary,
        },
        '& > span': {
            textTransform: 'uppercase',
            color: colors.greyscale[500],
        },
    },
    token: {
        display: 'grid',
        gridTemplateColumns: 'auto 1fr',
        alignItems: 'center',
        gridColumnGap: 16,
        padding: 16,
        maxHeight: 88,
        marginTop: 16,
        '& > span': {
            fontSize: 16,
            fontWeight: 700,
            color: colors.tertiary[500],
        },
    },
    addToken: {
        marginTop: 16,
        '&:hover': {
            backgroundColor: theme.palette.primary.unsubscribeHover,
        },
    },
}))

const WalletNetwork = ({
                           wallet,
                           network,
                           walletNetworkId,
                           tokens,
                           onAddTokenCb,
                           networkIndex
                       }) => {
    const classes = useStyles()

    return (
        <Box classNames={classes.network}>
            <h6>Network type </h6>
            <span>{network?.name}</span>
            <Divider margin={'16px 0 0'}/>
            {tokens?.map((token) => (
                <WalletToken
                    token={token}
                    wallet={wallet}
                    key={token?.id}
                    networkIndex={networkIndex}
                    walletNetworkId={walletNetworkId}
                />
            ))}
            <Button
                width={'100%'}
                variant={'ghost'}
                type={'button'}
                className={classes.addToken}
                onClick={() => onAddTokenCb(wallet?.id, networkIndex)}
            >
                Add Token
            </Button>
        </Box>
    )
}

export default WalletNetwork
