import { createUseStyles } from 'react-jss'
import React from 'react'
import CreatorSearchbar from '../creator-searchbar/CreatorSearchbar'
import { BackIcon } from '../../../theme/icons'
import SidePopover from '../../../components/SidePopover'

const useStyles = createUseStyles((theme) => ({
    searchbarMenu: {
        display: 'flex',
        flexDirection: 'column',
        gridTemplateColumns: '1fr',
        gridTemplateRows: 'min-content 1fr auto',
        width: '100%',
        overflow: 'scroll',
        '&::-webkit-scrollbar': {
            display: 'none',
        },
    },
    header: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing * 3,
        padding: theme.spacing * 2,
        borderBottom: `1px solid ${theme.palette.divider.external}`,
        '& > svg': {
            transform: ({ isOpen }) =>
                isOpen ? 'rotate(180deg)' : 'rotate(0deg)',
            transition: 'ease-in-out 400ms',
        },
    },
}))


const SearchbarMenuMobile = ({ isOpen, onClose }) => {

    const classes = useStyles({ isOpen })
    return (
        <menu>
            <SidePopover
                side={'right'}
                isOpen={isOpen}
                bodyClassName={classes.searchbarMenu}
                onClose={onClose}
            >
                <div className={classes.header}>
                    <BackIcon onClick={onClose} />
                    {isOpen && <CreatorSearchbar
                        placeholder={'Search on Happy Fans'}
                        maxDigits={30}
                        onRedirect={onClose}
                    />}
                </div>
            </SidePopover>
        </menu>
    )
}

export default SearchbarMenuMobile
