import { useState } from 'react'
import { createUseStyles } from 'react-jss'
import Comment from './comment'
import CommentInput from './comment-input'
import { handleApiError } from '../../../../../../utilities/helpers'
import { httpFetchComments } from '../../../../../../http-requests/post'
import { LoaderIcon } from '../../../../../../theme/icons'
import CommentsLoader from './comment/loaders/CommentsLoader'

const useStyles = createUseStyles((theme) => ({
    commentSection: {},
    commentList: {},
    commentStats: {
        padding: 16,
        color: theme.palette.grey[400],
        fontSize: 12,
        display: 'flex',
    },
    showMoreComments: {
        flex: 1,
        cursor: 'pointer',
    },
    commentsCount: {
        marginLeft: 'auto',
    },
    loadMoreSpinnerWrapper: {
        position: 'relative',
    },
}))

const CommentSection = ({ postID, initialComments, totalComments }) => {
    const [currentPage, setCurrentPage] = useState(0)
    const [lastPage, setLastPage] = useState(0)
    const [perPage, setPerPage] = useState(0)
    const [total, setTotal] = useState(0)
    const [comments, setComments] = useState(initialComments ?? [])
    const [visibleRepliesCount, setVisibleRepliesCount] = useState(0)
    const [isLoading, setIsLoading] = useState(false)

    const fetchLimit = 5

    const fetchComments = async (page = 1) => {
        if (page === 1 || (currentPage < lastPage && perPage >= fetchLimit))
            try {
                setIsLoading(true)
                const {
                    data: { data, current_page, last_page, total, per_page },
                } = await httpFetchComments({ postID, page, limit: fetchLimit })

                setComments(current_page === 1 ? data : [...comments, ...data])
                setCurrentPage(current_page)
                setLastPage(last_page)
                setTotal(total)
                setPerPage(per_page)
            } catch (error) {
                handleApiError({
                    isReduxError: false,
                    error,
                })
            } finally {
                setIsLoading(false)
            }
    }

    const classes = useStyles()

    return (
        <div className={classes.commentSection}>
            <CommentInput
                postID={postID}
                submitCallback={(newComment) => {
                    setComments((currentComments) => [
                        ...currentComments,
                        newComment,
                    ])
                }}
            />
            <ul className={classes.commentList}>
                {comments?.map(
                    (
                        {
                            id,
                            created_at,
                            comment,
                            likes_count,
                            user,
                            is_liked,
                            nested_comments_count,
                        },
                        idx
                    ) => {
                        return (
                            <Comment
                                id={id}
                                key={idx}
                                isLiked={is_liked}
                                date={created_at}
                                likes={likes_count}
                                content={comment}
                                user={user}
                                depth={0}
                                nestedCommentsCount={nested_comments_count}
                                canReply
                                setVisibleRepliesCount={(e) =>
                                    setVisibleRepliesCount(
                                        visibleRepliesCount + e
                                    )
                                }
                            />
                        )
                    }
                )}
                {isLoading && (
                    <div className={classes.loadMoreSpinnerWrapper}>
                        <CommentsLoader rows={2} />
                    </div>
                )}
            </ul>

            <div className={classes.commentStats}>
                {(!currentPage || currentPage < lastPage) &&
                    comments.length < totalComments && (
                        <div
                            className={classes.showMoreComments}
                            onClick={() => fetchComments(currentPage + 1)}
                        >
                            <span>Show more comments</span>
                        </div>
                    )}
                {!!comments?.length && (
                    <div className={classes.commentsCount}>
                        {comments?.length + visibleRepliesCount} of{' '}
                        {totalComments}
                    </div>
                )}
            </div>
        </div>
    )
}

export default CommentSection
