import { createUseStyles } from 'react-jss'
import {
    CameraIcon,
    AvatarIcon,
} from '../../../../theme/icons'
import { useFormContext } from 'react-hook-form'
import cx from 'classnames'
import { httpUpdateProfileAvatar } from '../../../../http-requests'
import { useState } from 'react'
import { PROFILE_MODEL } from '../ProfileModel'
import LocalStorageManager from '../../../../utilities/localStorage'
import { selectUser, updateUser } from '../../../../store/slices/user'
import { useDispatch, useSelector } from 'react-redux'
import { handleApiError } from '../../../../utilities/helpers'
import colors from '../../../../theme/colors'
import Spinner from '../../../../components/Spinner'

const useStyles = createUseStyles((theme) => ({
    avatarPic: ({ avatar, loading }) => ({
        '& label': {
            background: loading
                ? `linear-gradient(to top, #A00B4899, #ffffff99), url(${avatar}) no-repeat top center`
                : avatar
                ? `url("${avatar}")`
                : colors.greyscale[600],
            backgroundSize: 'cover',
            borderRadius: 72,
            height: 72,
            width: 72,
            display: avatar ? 'inline-block' : 'grid',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '6px 12px',
            cursor: 'pointer',
            border: `2px solid ${colors.common.white}`,
            position: 'relative',
            overflow: 'hidden',
            '& input': {
                display: 'none',
            },
            '&:hover': {
                background: colors.greyscale[400],
                backgroundSize: 'cover',
                '& .hoveredContent': {
                    display: avatar ? 'block' : 'none',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                },
            },
            '& .hoveredContent': {
                display: 'none',
                '& svg > path': {
                    zIndex: 2,
                    stroke: colors.common.white,
                },
                '& svg > circle': {
                    zIndex: 2,
                    stroke: colors.common.white,
                },
            },
        },
    }),
}))

const AvatarImageUploader = ({ field, className }) => {
    const dispatch = useDispatch()
    const user = useSelector(selectUser)
    const [loading, setLoading] = useState(false)
    const {
        register,
        setValue,
        setError,
        formState: { touchedFields, errors },
    } = useFormContext() // retrieve all hook methods
    const classes = useStyles({
        avatar: user?.avatar?.[128],
        loading,
    })

    const uploader = async (e) => {
        setLoading(true)
        try {
            const formData = new FormData()
            formData.append(field, e?.target?.files[0])
            const { data: user } = await httpUpdateProfileAvatar(formData)
            //set field
            setValue(PROFILE_MODEL.avatar, { ...user?.avatar })
            //set value
            LocalStorageManager.user.set(user)
            dispatch(updateUser(user))
        } catch (error) {
            handleApiError({
                isReduxError: false,
                error,
                callbackOnFieldError: setError,
            })
        } finally {
            setLoading(false)
        }
    }

    return (
        <div className={cx(classes.avatarPic, className)}>
            <label htmlFor={'avatar'}>
                <span className={'hoveredContent'}>
                    <CameraIcon data-color width={24} />
                </span>
                {loading && <Spinner size={16} />}
                {!user?.avatar && (
                    <AvatarIcon width={40} data-color stroke={'#fff'} />
                )}
                <input
                    type="file"
                    id="avatar"
                    onChange={uploader}
                    accept="image/jpg, image/png, image/jpeg, image/heif, image/heic"
                />
                <input type={'text'} {...register(field)} />
            </label>
        </div>
    )
}

export default AvatarImageUploader
