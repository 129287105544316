import { createUseStyles } from 'react-jss'
import React, { useEffect, useState } from 'react'
import Popover from '../../../components/Popover'
import Button from '../../../components/Button'
import Banner from '../../../components/Banner'
import { CheckedIcon } from '../../../theme/icons'
import Divider from '../../../components/Divider'
import UserInfo from '../../homepage/posts-feed/post/post-header/user-info'
import { handleApiError } from '../../../utilities/helpers'
import { useParams } from 'react-router-dom'
import Select from '../../../components/Select'
import { useForm } from 'react-hook-form'
import Box from '../../../components/Box'
import Media from '../../../components/Media'
import paymentProviderLogo from '../../../asset/images/8-pay-logo.png'
import { PREMIUM_POST_MODEL, validationSchema } from './premiumPostModel'
import { yupResolver } from '@hookform/resolvers/yup'
import dayjs from 'dayjs'
import { components } from 'react-select'
import { httpPurchasePost, httpUserTokens } from '../../../http-requests'
import MonthlyPriceBox from '../subscription-modal/monthly-price-box'
import colors from '../../../theme/colors'
import lockedMedia from '../../../asset/images/lockedMedia.png'
import mobileLockedMedia from '../../../asset/images/mobileLockedMedia.png'

import { useWindowSize } from '../../../hooks/useWindowSize'

const useStyles = createUseStyles((theme) => ({
    premiumPostModal: {
        display: 'flex',
        flexDirection: 'column',
        color: theme.palette.secondary.main,
    },
    titleWrapper: {
        display: 'flex',
        flexDirection: 'column',
        gap: 8,
        fontWeight: 700,
        whiteSpace: 'nowrap',
        color: theme.palette.primary.background,
    },
    title: {
        fontWeight: 400,
        '& span': {
            color: colors.primary[500],
        },
    },
    subtitle: {
        fontSize: 12,
    },
    banner: {
        fontSize: 16,
        letterSpacing: 0.5,
        color: colors.greyscale[600],
        border: `2px solid #F3F3F3`,
        borderRadius: 8,
        backgroundColor: '#FFFFFF',
    },
    body: {
        display: 'flex',
        flexDirection: 'column',
        gap: 16,
        margin: theme.spacing * 2,
        padding: theme.spacing * 2,
        boxShadow:
            '0px 1px 3px rgba(20, 20, 42, 0.1), 0px 0px 1px rgba(20, 20, 42, 0.05)',
        borderRadius: 16,
    },
    mediaSection: {
        height: '100%',
        ...theme.utils.flexbox.spaceBetween,
        flexDirection: 'column',
        gap: 16,
        [theme.mediaQueries.mUp]: {
            flexDirection: 'row',
        },
    },
    infoWrapper: {
        height: '100%',
        width: '100%',
        flexGrow: 1,
        display: 'flex',
        gap: 16,
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    lockedMedia: ({ isMobile }) => ({
        height: 124,
        minWidth: isMobile ? '100%' : 124,
        borderRadius: 12,
    }),
    action: {
        borderTop: `1px solid ${theme.palette.grey[300]}`,
        padding: theme.spacing * 2,
    },
    divider: {
        margin: 0,
    },
    amountSection: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing * 2,
        margin: [0, theme.spacing * 2, theme.spacing * 2, theme.spacing * 2],
        '& > p': {
            fontSize: 12,
        },
    },
    paymentProvider: {
        ...theme.utils.flexbox.centered,
        gap: theme.spacing,
        padding: 0,
        fontSize: 12,
        color: theme.palette.text.secondary,
        minHeight: 40,
        background: '#FAFAFA',
        boxShadow: 'none',
        '& div': {
            height: 20,
            width: 43,
        },
    },
    select: {
        display: 'flex',
        flexDirection: 'column',
        '& div > div > div': {
            alignItems: 'center',
        },
        '& div > div > div > div': {
            display: 'flex !important',
            placeContent: 'space-between !important',
            alignItems: 'center !important',
        },
        '& label': {
            marginTop: 0,
            marginBottom: 8,
        },
    },
    customValue: {
        minHeight: 40,
        width: 111,
        borderRadius: 8,
        marginRight: 8,
        color: theme.palette.secondary.dark,
        border: '1px solid #41D3BD',
        fontWeight: 700,
        fontSize: 14,
        letterSpacing: 0.5,
        '& span': {
            marginLeft: 5,
        },
    },
}))

const PremiumPostModal = ({ onClose, user }) => {
    const [isLoading, setIsLoading] = useState(false)
    const [tokens, setTokens] = useState(false)
    const { width } = useWindowSize()
    const [isMobile, setIsMobile] = useState(width <= 768)
    const postID = user?.premiumPost?.postID

    useEffect(() => {
        setIsMobile(width <= 448)
    }, [width])

    useEffect(() => {
        fetchTokens()
    }, [])

    const fetchTokens = async () => {
        try {
            const { data } = await httpUserTokens(user?.id)
            setTokens(
                data?.map(({ id, symbol, discount_percentage }) => ({
                    value: id,
                    label: symbol,
                    discount: discount_percentage,
                }))
            )
        } catch (error) {
            handleApiError({
                isReduxError: false,
                error,
            })
        }
    }

    const formMethods = useForm({
        shouldUnregister: false,
        mode: 'onBlur',
        reValidateMode: 'onChange',
        nativeValidation: false,
        defaultValues: {},
        resolver: yupResolver(validationSchema),
    })
    const {
        handleSubmit,
        control,
        watch,
        setError,
        formState: { errors },
    } = formMethods

    const selectedToken = watch(PREMIUM_POST_MODEL.token)
    const happyDiscount =
        tokens &&
        tokens?.filter((token) => token?.label === 'HAPPY')?.[0]?.discount

    const isHappyTokenSelected = selectedToken?.label === 'HAPPY'

    const CustomSingleValue = ({ children, ...props }) => {
        return (
            <components.SingleValue {...props}>
                {children}
                {props?.data?.discount && (
                    <div className={classes.customValue}>
                        <span>{props?.data?.discount}% Discount</span>
                    </div>
                )}
            </components.SingleValue>
        )
    }

    const onSubmit = async (formValues) => {
        const dataToSend = {
            token_id: formValues?.token?.value,
        }
        try {
            const { data } = await httpPurchasePost(postID, dataToSend)
        } catch (error) {
            if (error.response?.status === 302 && error.response?.data?.url) {
                window.location.replace(error.response?.data?.url)
            } else {
                handleApiError({
                    isReduxError: false,
                    callbackOnFieldError: setError,
                    error,
                })
            }
        } finally {
            setIsLoading(false)
        }
    }

    const classes = useStyles({ isMobile })
    return (
        <Popover
            onClose={onClose}
            title={'Subscriptions'}
            maxWidth={539}
            closeOnOverlay={false}
        >
            <div className={classes.premiumPostModal}>
                <div className={classes.body}>
                    <div className={classes.mediaSection}>
                        <div className={classes.infoWrapper}>
                            <div className={classes.titleWrapper}>
                                <span>
                                    Premium content:{' '}
                                    <span className={classes.title}>
                                        unlock for{' '}
                                        <span>${user?.premiumPost?.price}</span>
                                    </span>
                                </span>
                                <p className={classes.subtitle}>
                                    Published on:{' '}
                                    {dayjs(user?.premiumPost?.date).format(
                                        'D MMM YYYY'
                                    )}
                                </p>
                            </div>
                            <Divider className={classes.divider} height={0.5} />
                            <UserInfo
                                avatar={user?.avatar?.[128]}
                                name={user?.display_name}
                                username={user?.username}
                                isOnline={user?.is_online}
                            />
                        </div>
                        {isMobile && (
                            <Banner
                                data={'secondary'}
                                text={"Full access to post's media"}
                                icon={<CheckedIcon />}
                                height={40}
                                className={classes.banner}
                            />
                        )}
                        <Media
                            image={isMobile ? mobileLockedMedia : lockedMedia}
                            className={classes.lockedMedia}
                        />
                    </div>
                    {!isMobile && (
                        <Banner
                            data={'secondary'}
                            text={"Full access to post's media"}
                            icon={<CheckedIcon />}
                            height={40}
                            className={classes.banner}
                        />
                    )}
                </div>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <section className={classes.amountSection}>
                        <Select
                            label={'Token Name'}
                            placeholder={'Choose a Token to pay with...'}
                            options={tokens}
                            control={control}
                            components={{ SingleValue: CustomSingleValue }}
                            isSearchable
                            name={PREMIUM_POST_MODEL.token}
                            errors={errors[PREMIUM_POST_MODEL.token]}
                            className={classes.select}
                        />
                        {happyDiscount && (
                            <p>
                                Only if you pay with "HAPPY" you get{' '}
                                {happyDiscount}% discount on all payments.
                            </p>
                        )}

                        <MonthlyPriceBox
                            text={'Total Amount:'}
                            amount={user?.premiumPost?.price}
                            discountAmount={
                                isHappyTokenSelected && selectedToken?.discount
                            }
                            isHappyTokenSelected={isHappyTokenSelected}
                        />
                        <Box classNames={classes.paymentProvider}>
                            POWERED BY
                            <Media image={paymentProviderLogo} />
                        </Box>
                    </section>
                    <div className={classes.action}>
                        <Button
                            disabled={isLoading}
                            width={'100%'}
                            type={'submit'}
                        >
                            Unlock
                        </Button>
                    </div>
                </form>
            </div>
        </Popover>
    )
}

export default PremiumPostModal
