import { createSlice, createSelector, createAsyncThunk } from '@reduxjs/toolkit'
import {
    httpDeleteWallet,
    httpFetchNetworks,
    httpFetchTokens,
    httpFetchWallets,
} from '../../http-requests/wallet'
import store from '../../store'
import { SUCCESS } from '../../utilities/constants'
import { setAlertMessage } from './app'
import {handleApiError} from "../../utilities/helpers";

const sliceName = 'wallet'

export const fetchWallets = createAsyncThunk(
    `wallets/`,
    async ({ page, limit }) => {
        const { data: wallets } = await httpFetchWallets(page, limit)
        return wallets
    }
)

export const fetchTokens = createAsyncThunk(`tokens/`, async () => {
    const { data: tokens } = await httpFetchTokens()
    return tokens
})

export const fetchNetworks = createAsyncThunk(`networks/`, async () => {
    const { data: networks } = await httpFetchNetworks()
    return networks.map((network) => ({
        value: network?.id,
        label: network?.name,
    }))
})

export const deleteWallet = createAsyncThunk(
    `wallet/delete`,
    async ({ id, password , onCloseCb}, { rejectWithValue }) => {
        try {
            await httpDeleteWallet(id, password)
            store.dispatch(
                setAlertMessage({
                    message: 'Wallet successfully deleted!',
                    variant: SUCCESS,
                })
            )
            onCloseCb();
            return { id: id }
        } catch (error) {
            handleApiError({
                isReduxError: false,
                error,
            })
            return rejectWithValue(error?.response?.data)
        }
    }
)

const wallet = createSlice({
    name: sliceName,
    initialState: {
        currentWallet: null,
        tokens: null,
        networks: null,
        wallets: {
            walletsList: [],
            currentPage: 0,
            lastPage: 0,
            total: 0,
            perPage: 15,
        },
    },
    reducers: {
        setCurrentWallet: (state, action) => {
            state.currentWallet = action.payload
        },
        addCreatedWalletToList: (state, action) => {
            state.wallets = {
                ...state.wallets,
                walletsList: [action.payload, ...state?.wallets?.walletsList],
            }
        },
        updateWallet: (state, action) => {
            const newWallet = action?.payload?.wallet
            let newWalletsList = state.wallets.walletsList

            newWalletsList = newWalletsList.map((wallet) => {

                if (+wallet.id === +newWallet?.id) {
                    wallet = { ...newWallet }
                }
                return wallet
            })

            state.wallets = {
                ...state.wallets,
                walletsList: [...newWalletsList],
            }
        },
        resetWallets: (state, action) => {
            state.currentWallet = null
            state.tokens = null
            state.networks = null
            state.wallets = {
                walletsList: [],
                currentPage: 0,
                lastPage: 0,
                total: 0,
                perPage: 15,
            }
        },
    },
    extraReducers: {
        [fetchWallets.fulfilled]: (state, action) => {
            const newWalletsList = [
                ...state.wallets?.walletsList,
                ...action.payload.data,
            ]
            state.wallets = {
                walletsList: [...newWalletsList],
                currentPage: action.payload.current_page,
                lastPage: action.payload.last_page,
                total: action.payload.total,
                perPage: +action.payload.per_page,
            }
        },
        [fetchTokens.fulfilled]: (state, action) => {
            state.tokens = action.payload
        },
        [fetchNetworks.fulfilled]: (state, action) => {
            state.networks = action.payload
        },
        [deleteWallet.fulfilled]: (state, action) => {
            const idToDelete = action.payload?.id
            let newWalletsList = state.wallets?.walletsList
            state.wallets = {
                ...state.wallets,
                walletsList: newWalletsList?.filter(
                    (wallet) => wallet?.id !== idToDelete
                ),
            }
        },
    },
})

// Selectors
const selectSelf = (state) => state[sliceName]
export const selectMainWallet = createSelector(selectSelf, (state) =>
    state.wallets?.walletsList?.filter((wallet) => !!+wallet?.is_main)
)
export const selectTokens = createSelector(selectSelf, (state) => state.tokens)
export const selectNetworks = createSelector(
    selectSelf,
    (state) => state.networks
)
export const selectCurrentWallet = createSelector(
    selectSelf,
    (state) => state.currentWallet
)

//Actions history
export const selectWallets = createSelector(
    selectSelf,
    (state) => state.wallets?.walletsList
)

export const selectWalletsLastPage = createSelector(
    selectSelf,
    (state) => state.wallets?.lastPage
)
export const selectWalletsCurrentPage = createSelector(
    selectSelf,
    (state) => state.wallets?.currentPage
)
export const selectWalletsTotal = createSelector(
    selectSelf,
    (state) => state.wallets?.total
)
export const selectWalletsPerPage = createSelector(
    selectSelf,
    (state) => state.wallets?.perPage
)

export const {
    setCurrentWallet,
    addCreatedWalletToList,
    updateWallet,
    setNewWallets,
    setNewTokens,
    resetWallets,
} = wallet.actions
export default wallet.reducer
