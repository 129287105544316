import React from 'react'
import {createUseStyles} from 'react-jss'

const useStyles = createUseStyles((theme) => ({
    progressBar: {
        height: 4,
        width: '100%',
        maxWidth: 380,
        backgroundColor: theme.palette.common.white,
        borderRadius: 30,
        overflow: 'hidden',
    },
    bar: {
        backgroundImage: 'linear-gradient(to left , #FFA133 , #FF8A00 10px)',
        height: '100%',
        maxWidth: ({progress}) => `${progress}%`,
        transition: '0.5s'
    },
}))

const ProgressBar = ({
                         progress = 0
}) => {

    const classes = useStyles({progress})
    return (
        <div className={classes.progressBar}>
            <div className={classes.bar}></div>
        </div>
    )
}

export default ProgressBar
