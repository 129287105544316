import * as yup from 'yup'

const SUBSCRIPTION_BUNDLE = {
    discount: 'discount',
    duration: 'duration',
}

const SubscriptionBundleFormValidationSchema = yup.object().shape({
    [SUBSCRIPTION_BUNDLE.discount]: yup
        .object()
        .nullable()
        .required('Discount is not selected'),

    [SUBSCRIPTION_BUNDLE.duration]: yup
        .object()
        .nullable()
        .required('Duration is not selected')
})

export {
    SUBSCRIPTION_BUNDLE,
    SubscriptionBundleFormValidationSchema as validationSchema,
}