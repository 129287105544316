import { createSlice, createSelector, createAsyncThunk } from '@reduxjs/toolkit'
import {
    httpDeleteActivity,
    httpDeleteProfile,
    httpFetchActivities,
    httpGetUserMe,
    httpLoginUser,
    httpLogoutUser,
    httpRefreshAccessToken,
    httpRegisterUser,
    httpSignupAttempt,
} from '../../http-requests'
import LocalStorageManager from '../../utilities/localStorage'
import {
    ACCESS_TOKEN,
    CURRENT_USER,
    REFRESH_TOKEN,
} from '../../utilities/constants'
import { SIGNUP_STATUSES } from '../../pages/authentication/signup/signup-form/signupModel'
import PusherInstance from '../../services/pusher'
import { PRIVATE_CHANNEL_NAME } from '../../utilities/constants/notifications'
import { setIsSubscribed } from './notifications'
import {httpRefreshChatToken} from "../../http-requests/messages";

const sliceName = 'user'

export const refreshChatToken = createAsyncThunk(
    `${sliceName}/refreshChatToken`,
    async (authData, { dispatch, getState, rejectWithValue }) => {
        try {
            const store = getState()
            if(!store.user.isFetchingChatRefreshToken){
                dispatch(setIsFetchingChatToken(true))
                const { data } = await httpRefreshChatToken()
                dispatch(setChatToken(data.token))
            }

        } catch (err) {
            return rejectWithValue(err?.response?.data)
        }
    }
)

export const login = createAsyncThunk(
    `${sliceName}/login`,
    async (authData, { rejectWithValue }) => {
        try {
            const { data: userData } = await httpLoginUser(authData)
            LocalStorageManager.setAuthData(userData)
            PusherInstance.setAuthHeaders(userData[ACCESS_TOKEN])
            return userData
        } catch (err) {
            return rejectWithValue(err?.response?.data)
        }
    }
)

export const logout = createAsyncThunk(
    `${sliceName}/logout`,
    async (authData, { rejectWithValue, getState, dispatch }) => {
        try {
            const response = await httpLogoutUser()
            const pusher = PusherInstance.getInstance()
            const state = getState()
            const isSubscribed = state.notifications?.isSubscribedToChannel
            const user = state.user?.user
            if (pusher?.connection.state === 'connected' && isSubscribed) {
                pusher.unsubscribe(
                    `${PRIVATE_CHANNEL_NAME}.${user?.id}.notifications`
                )
                dispatch(setIsSubscribed(false))
            }
            PusherInstance.removeAuthHeaders()
            LocalStorageManager.removeAuthData()
            return response.data
        } catch (err) {
            return rejectWithValue(err?.response?.data)
        }
    }
)

export const userDelete = createAsyncThunk(
    `profile/delete`,
    async (password, thunkAPI) => {
        try {
            const response = await httpDeleteProfile({ password })
            LocalStorageManager.removeAuthData()
            return response.data
        } catch (err) {
            return thunkAPI.rejectWithValue(err?.response?.data)
        }
    }
)

export const userMe = createAsyncThunk(`${sliceName}/me`, async () => {
    const response = await httpGetUserMe()
    LocalStorageManager.user.set(response.data)
    return response.data
})

export const signup = createAsyncThunk(
    `${sliceName}/signup`,
    async (userData, { rejectWithValue }) => {
        try {
            const { data: authData } = await httpRegisterUser(userData)
            LocalStorageManager.removeAuthData()
            return authData
        } catch (err) {
            return rejectWithValue(err?.response?.data)
        }
    }
)

export const signupAttempt = createAsyncThunk(
    `${sliceName}/signupAttempt`,
    async (userData, { rejectWithValue }) => {
        try {
            const { data } = await httpSignupAttempt(userData)
        } catch (err) {
            return rejectWithValue(err?.response?.data)
        }
    }
)

export const attemptRefresh = createAsyncThunk(
    `${sliceName}/refresh`,
    async (refreshToken, { rejectWithValue }) => {
        try {
            const { data: authData } = await httpRefreshAccessToken({
                refresh_token: refreshToken,
            })
            LocalStorageManager.accessToken.set(authData[ACCESS_TOKEN])
            LocalStorageManager.refreshToken.set(authData[REFRESH_TOKEN])
            return authData
        } catch (err) {
            LocalStorageManager.removeAuthData()
            return rejectWithValue(err?.response?.data)
        }
    }
)

export const fetchActivities = createAsyncThunk(
    `profile/activities`,
    async ({ page, limit = 12 }) => {
        const { data: newActivities } = await httpFetchActivities(page, limit)
        return newActivities
    }
)

export const deleteActivity = createAsyncThunk(
    `profile/delete-activity`,
    async (id, { rejectWithValue }) => {
        try {
            await httpDeleteActivity(id)
            return { id: id }
        } catch (err) {
            return rejectWithValue(err?.response?.data)
        }
    }
)

const user = createSlice({
    name: sliceName,
    initialState: {
        user: LocalStorageManager.user.get() || null,
        accessToken: LocalStorageManager.accessToken.get() || null,
        refreshToken: LocalStorageManager.refreshToken.get() || null,
        updatingUserData: false,
        signupStatus: SIGNUP_STATUSES.initial,
        isFetchingChatRefreshToken: false,
        chatToken: null,
        pubnubID: null,
        activities: {
            grouped: [],
            currentPage: 0,
            lastPage: 0,
            total: 0,
            perPage: 12,
        },
    },
    reducers: {
        setChatToken: (state, action) => {
            state.chatToken = action.payload
        },
        setIsFetchingChatToken: (state, action) => {
            state.isFetchingChatRefreshToken = action.payload
        },
        setNewAccessToken: (state, action) => {
            state.accessToken = action.payload[ACCESS_TOKEN]
        },
        setNewRefreshToken: (state, action) => {
            state.refreshToken = action.payload[REFRESH_TOKEN]
        },
        updateUser: (state, action) => {
            state.user = action.payload
            state.chatToken = action.payload?.chat_token?.token
            state.pubnubID = action.payload?.pubnub_id
        },
        setSignupStatus: (state, action) => {
            state.signupStatus = action.payload
        },
        resetActivities: (state, action) => {
            state.activities = {
                grouped: [],
                currentPage: 0,
                lastPage: 0,
                total: 0,
                perPage: 12,
            }
        },
        removeAuth: (state, action) => {
            state.accessToken = null
            state.refreshToken = null
            state.user = null
        },
        resetUnreadNotificationsCounter: (state, action) => {
            state.user.unseen_notifications_count = 0
        },
        incrementNotificationsCounter: (state, action) => {
            if (!action.payload?.unreadFilter) {
                state.user.unseen_notifications_count += 1
            } else {
                state.user.unseen_notifications_count += 1
                state.user.notifications_count += 1
            }
        },
        decrementNotificationsCounter: (state, action) => {
            if (action.payload.isDelete) {
                if (!action.payload.seen_at) {
                    state.user.unseen_notifications_count -= 1
                    state.user.notifications_count -= 1
                } else {
                    state.user.notifications_count -= 1
                }
            } else {
                state.user.unseen_notifications_count -= 1
            }
        },
    },
    extraReducers: {
        [logout.fulfilled]: (state, action) => {
            state.accessToken = null
            state.refreshToken = null
            state.user = null
        },
        [login.fulfilled]: (state, action) => {
            state.accessToken = action.payload[ACCESS_TOKEN]
            state.refreshToken = action.payload[REFRESH_TOKEN]
            state.user = action.payload[CURRENT_USER]
            state.pubnubID = action.payload.user.pubnub_id
        },
        [attemptRefresh.fulfilled]: (state, action) => {
            state.accessToken = action.payload[ACCESS_TOKEN]
            state.refreshToken = action.payload[REFRESH_TOKEN]
        },
        [attemptRefresh.rejected]: (state, action) => {
            state.accessToken = null
            state.refreshToken = null
            state.user = null
        },
        [signup.fulfilled]: (state, action) => {
            state.user = action.payload
            state.signupStatus = SIGNUP_STATUSES.completed
        },
        [userMe.fulfilled]: (state, action) => {
            state.user = action.payload
            state.chatToken = action.payload.chat_token?.token
            state.pubnubID = action.payload.pubnub_id
            state.updatingUserData = false
        },
        [userMe.pending]: (state) => {
            state.updatingUserData = true
        },
        [refreshChatToken.fulfilled]: (state) => {
            state.isFetchingChatRefreshToken = false
        },
        [userDelete.fulfilled]: (state) => {
            state.refreshToken = null
            state.accessToken = null
            state.user = null
        },
        [fetchActivities.fulfilled]: (state, action) => {
            state.activities = {
                grouped: [...state.activities?.grouped, ...action.payload.data],
                currentPage: action.payload.current_page,
                lastPage: action.payload.last_page,
                total: action.payload.total,
                perPage: +action.payload.per_page,
            }
        },
        [deleteActivity.fulfilled]: (state, action) => {
            const idToDelete = action.payload?.id
            let newActivities = state.activities?.grouped
            state.activities = {
                ...state.activities,
                grouped: newActivities?.filter(
                    (activity) => activity?.id !== idToDelete
                ),
            }
        },
    },
})

// Selectors
const selectSelf = (state) => state[sliceName]
export const selectUser = createSelector(selectSelf, (state) => state.user)
export const selectAccessToken = createSelector(
    selectSelf,
    (state) => state.accessToken
)
export const selectRefreshToken = createSelector(
    selectSelf,
    (state) => state.refreshToken
)
export const selectIsUpdatingUserData = createSelector(
    selectSelf,
    (state) => state.updatingUserData
)

export const selectSignupStatus = createSelector(
    selectSelf,
    (state) => state.signupStatus
)

//Actions history
export const selectUserActivities = createSelector(
    selectSelf,
    (state) => state.activities?.grouped
)

export const selectActivitiesLastPage = createSelector(
    selectSelf,
    (state) => state.activities?.lastPage
)
export const selectActivitiesCurrentPage = createSelector(
    selectSelf,
    (state) => state.activities?.currentPage
)
export const selectActivitiesTotal = createSelector(
    selectSelf,
    (state) => state.activities?.total
)
export const selectActivitiesPerPage = createSelector(
    selectSelf,
    (state) => state.activities?.perPage
)

export const selectAllNotificationsCount = createSelector(
    selectSelf,
    (state) => state?.user?.notifications_count
)
export const selectUnseenNotificationsCount = createSelector(
    selectSelf,
    (state) => state?.user?.unseen_notifications_count
)

export const selectChatToken = createSelector(
    selectSelf,
    (state) => state?.chatToken
)

export const {
    setNewAccessToken,
    setNewRefreshToken,
    updateUser,
    setSignupStatus,
    resetActivities,
    removeAuth,
    resetUnreadNotificationsCounter,
    incrementNotificationsCounter,
    decrementNotificationsCounter,
    setIsFetchingChatToken,
    setChatToken
} = user.actions
export default user.reducer
