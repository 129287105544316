import React, { useRef, useState } from 'react'
import { createUseStyles } from 'react-jss'
import Button from '../../../../../components/Button'
import colors from '../../../../../theme/colors'
import Divider from '../../../../../components/Divider'
import EmojiInput from '../../../../../components/EmojiInput'
import {
    CameraIcon,
    TipsRoundDisabledIcon,
    TipsRoundIcon,
    VideoIcon,
} from '../../../../../theme/icons'
import { useDispatch, useSelector } from 'react-redux'
import { selectUser } from '../../../../../store/slices/user'
import {setChatTipModalOpen, setPpvModalOpen} from "../../../../../store/slices/conversations";

const useStyles = createUseStyles((theme) => ({
    chatInput: {
        width: '100%',
        ...theme.utils.flexbox.start,
        flexDirection: 'row',
        gap: 8,
        padding: 24,
        backgroundColor: 'white',
        boxShadow: 'inset 0px 1px 0px rgba(0, 0, 0, 0.05)',
    },
    inputArea: {
        width: '100%',
        minHeight: 48,
        height: 'auto',
        display: 'grid',
        gridTemplateColumns: '1fr min-content min-content min-content',
        gap: 8,
        alignItems: 'center',
        border: `2px solid ${colors.greyscale[300]}`,
        borderRadius: 30,
        padding: [6, 8],
        '& input': {
            width: 50,
            overflowWrap: 'break-word',
            border: 'none',
            padding: [0, 0, 0, 8],
        },
    },
    submitButton: {
        maxHeight: 32,
        maxWidth: 67,
        placeSelf: 'flex-end',
        '& span': {
            fontSize: 14,
            letterSpacing: 0.75,
            fontWeight: 700,
        },
    },
    banner: {
        height: 48,
        display: 'flex',
        alignItems: 'center',
        backgroundColor: colors.primary[100],
        borderRadius: 100,
        gap: 6,
        padding: [0, 16],
        alignSelf: 'flex-end',
        cursor: 'pointer',
        opacity: ({isExpired}) => isExpired ? 0.55 : 1,
        '& p': {
            fontSize: 18,
            fontWeight: 700,
            color: colors.primary[500],
        },
        '& svg': {
            height: 20,
        },
        // TODO remove when PPV is implemented
        // [theme.mediaQueries.mUp]: {
        //     width: 134,
        // },
    },
    bannerDivider: {
        width: 1,
        height: 16,
        backgroundColor: colors.primary[400],
        margin: 0,
    },
    divider: {
        height: 32,
        width: 1,
        margin: 0,
        placeSelf: 'flex-end',
        backgroundColor: colors.greyscale[300],
    },
    externalDivider: {
        extend: 'divider',
        marginBottom: 10,
    },
    emojiInput: {
        marginTop: 4,
        placeSelf: 'flex-end',
    },

    textarea: {
        boxSizing: 'border-box',
        border: 'none',
        borderRadius: '3px',
        resize: 'none',
        lineHeight: '24px',
        overflow: 'auto',
        height: 'auto',
        padding: 0,
        '&:disabled': {
            backgroundColor: 'white',
        },
    },
    tip: {
        placeSelf: 'flex-end',
            cursor: 'pointer',
            '&:hover': {
                '& > svg > rect:first-child': {
                    fill: `#FFE8CC`,
                },
                '& > svg > path': {
                    stroke: `#FF8A00`,
                },
                '& > svg > rect:not(:first-child)': {
                    stroke: `#FF8A00`,
                },
            },
    },
    expiredTip: {
          placeSelf: 'flex-end',
    }
}))

const ChatInput = ({ channelUUID, onInput, isExpired, hasWallet }) => {
    const dispatch = useDispatch()
    const userMe = useSelector(selectUser)
    const inputRef = useRef()
    const [rows, setRows] = useState(1)
    const [text, setText] = useState('')
    const minRows = 1
    const maxRows = 10

    const handleSubmit = (e) => {
        if (!isExpired) {
            e.preventDefault()
            if (text) {
                onInput(text)
                inputRef.current.value = ''
                inputRef.current.rows = minRows
                setText('')
            }
        }
    }

    const handleChange = (event) => {
        const textareaLineHeight = 24

        const previousRows = event.target.rows
        event.target.rows = minRows // reset number of rows in textarea

        const currentRows = ~~(event.target.scrollHeight / textareaLineHeight)

        if (currentRows === previousRows) {
            event.target.rows = currentRows
        }

        if (currentRows >= maxRows) {
            event.target.rows = maxRows
            event.target.scrollTop = event.target.scrollHeight
        }

        setText({
            type: 'text',
            value: event.target.value,
        })
        setRows(currentRows < maxRows ? currentRows : maxRows)
    }

    const onEnterPress = (e) => {
        if (e?.key === 'Enter' && e?.shiftKey === false) {
            e.preventDefault()
            handleSubmit(e)
        }
    }

    const classes = useStyles({ isExpired })
    return (
        <div className={classes.chatInput}>
            {userMe?.is_creator && userMe?.is_creator_verified ? (
                <div
                    className={classes.banner}
                    onClick={() => !isExpired && dispatch(setPpvModalOpen(true))}
                >
                    <CameraIcon stroke={'#8555E7'} />
                    <Divider className={classes.bannerDivider} />
                    <VideoIcon stroke={'#8555E7'} />
                    {/*TODO remove when PPV is implemented*/}
                    {/*{!isMobile && <p>PPV</p>}*/}
                </div>
            ) : (
                //TODO CHAT handle tip sending
                <div className={isExpired ? classes.expiredTip : classes.tip}>
                    {isExpired || !hasWallet ? (
                        <TipsRoundDisabledIcon />
                    ) : (
                        <TipsRoundIcon
                            onClick={() => {
                                dispatch(setChatTipModalOpen(channelUUID))
                            }}
                        />
                    )}
                </div>
            )}
            <Divider className={classes.externalDivider} />
            <form onSubmit={handleSubmit} className={classes.inputArea}>
                <textarea
                    rows={rows}
                    value={text?.value}
                    placeholder={'Write a message...'}
                    className={classes.textarea}
                    onChange={handleChange}
                    onKeyDown={onEnterPress}
                    ref={inputRef}
                    disabled={isExpired}
                />

                <EmojiInput
                    className={classes.emojiInput}
                    getCurrentValue={() => text?.value ?? ''}
                    disabled={isExpired}
                    onSelection={(textWithEmoji) =>
                        setText({
                            type: 'text',
                            value: textWithEmoji,
                        })
                    }
                />
                <Divider className={classes.divider} />
                <Button
                    data={'secondary'}
                    variant={'round'}
                    type={'submit'}
                    className={classes.submitButton}
                    disabled={isExpired}
                >
                    Send
                </Button>
            </form>
        </div>
    )
}

export default ChatInput
