import { createUseStyles } from 'react-jss'
import cx from 'classnames'
import { useState } from 'react'
import { MinusIcon, PlusIcon } from '../theme/icons'

const useStyles = createUseStyles((theme) => ({
    root: {
        ...theme.utils.flexbox.start,
        flexDirection: 'column',
        gap: theme.spacing * 2,
        color: theme.palette.primary.background,
    },
    panel: {
        cursor: 'pointer',
        ...theme.utils.flexbox.spaceBetween,
        width: '100%',
    },
    itemWrapper: {
        ...theme.utils.flexbox.centered,
        flexDirection: 'column',
        gap: theme.spacing * 2,
    },
}))

const Accordion = ({
    label,
    labelClassname,
    onClick,
    className,
    children,
    itemsClassName,
}) => {
    const [accordionOpen, setAccordionOpen] = useState(false)

    const classes = useStyles({})
    return (
        <div className={cx(classes.root, className)} onClick={onClick}>
            <div
                className={cx(classes.panel, labelClassname)}
                onClick={() => setAccordionOpen(!accordionOpen)}
            >
                {label}
                {accordionOpen ? <MinusIcon /> : <PlusIcon />}
            </div>
            {accordionOpen && (
                <div className={cx(classes.itemWrapper, itemsClassName)}>
                    {children}
                </div>
            )}
        </div>
    )
}

export default Accordion
