import * as yup from 'yup'
import {
    FacebookMonoIcon,
    InstagramMonoIcon,
    SnapchatMonoIcon, TikTokIcon,
    TwitterMonoIcon,
    YoutubeMonoIcon,
} from '../../../theme/icons'

const re =
    /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm

const PROFILE_MODEL = {
    avatar: 'avatar',
    header: 'header',
    username: 'username',
    displayedName: 'display_name',
    bio: 'description',
    location: 'location',
    website: 'website_link',
    youtube: 'youtube_link',
    isCreator: 'is_creator',
    categoryID: 'category_id',
    categoryIDs: 'categories',
}

const PROFILE_MODEL_SOCIALS = [
    {
        name: 'TikTok',
        formField: 'tiktok_username',
        placeholder: 'Enter your username',
        icon: <TikTokIcon data-color width={20} />,
    },
    {
        name: 'Youtube',
        formField: 'youtube_link',
        placeholder: 'Enter the url',
        icon: <YoutubeMonoIcon data-color width={20} />,
    },
    {
        name: 'Facebook',
        formField: 'facebook_username',
        placeholder: 'Enter your username',
        icon: <FacebookMonoIcon data-color width={20} />,
    },
    {
        name: 'Instagram',
        formField: 'instagram_username',
        placeholder: 'Enter your username',
        icon: <InstagramMonoIcon data-color width={20} />,
    },
    {
        name: 'Twitter',
        formField: 'twitter_username',
        placeholder: 'Enter your username',
        icon: <TwitterMonoIcon data-color width={20} />,
    },
    {
        name: 'Snapchat',
        formField: 'snapchat_username',
        placeholder: 'Enter your username',
        icon: <SnapchatMonoIcon data-color width={20} />,
    },

    /*{name: "Telegram", formField: "telegram_url", icon: <TelegramMonoIcon data-color width={20}/>},*/
]

const profileValidationSchema = yup.object().shape({
    [PROFILE_MODEL.username]: yup.string().required('Username is mandatory'),
    [PROFILE_MODEL.displayedName]: yup
        .string()
        .required('Display Name is mandatory'),
    [PROFILE_MODEL.bio]: yup.string().nullable(),
    [PROFILE_MODEL.location]: yup.string().nullable(),
    [PROFILE_MODEL.website]: yup
        .string()
        .matches(re, { excludeEmptyString: true, message: 'URL is not valid' })
        .nullable(),
    [PROFILE_MODEL.youtube]: yup
        .string()
        .matches(re, { excludeEmptyString: true, message: 'URL is not valid' })
        .nullable(),
    [PROFILE_MODEL.categoryIDs]: yup
        .array(
            yup.object({
                value: yup.number(),
                label: yup.string(),
            })
        )
        .compact()
        .min(1, 'Choose at least one category'),
    [PROFILE_MODEL.isCreator]: yup
        .bool()
        .typeError('You must choose an account type to continue')
        .oneOf([true, false], 'You must choose an account type to continue'),
    [PROFILE_MODEL.categoryID]: yup
        .object()
        .nullable()
        .when(PROFILE_MODEL.isCreator, {
            is: true,
            then: yup
                .object({
                    value: yup.number(),
                    label: yup.string(),
                })
                .typeError('You must choose a category to be a Creator')
                .required('You must choose a category to be a Creator'),
        }),
})

export {
    PROFILE_MODEL,
    PROFILE_MODEL_SOCIALS,
    profileValidationSchema as validationSchema,
}
