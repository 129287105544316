import {createUseStyles} from 'react-jss'
import React from 'react'
import Popover from '../../../../../components/Popover'
import Button from '../../../../../components/Button'
import colors from '../../../../../theme/colors'
import Radio from '../../../../../components/Radio'
import {useForm} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import {DELETE_MESSAGE_MODEL} from './deleteMessageMODEL'
import {validationSchema} from './deleteMessageMODEL'
import {usePubNub} from 'pubnub-react'
import {httpDeleteMedia} from "../../../../../http-requests/messages";
import {handleApiError} from "../../../../../utilities/helpers";
import {useSelector} from "react-redux";
import {selectActiveChat} from "../../../../../store/slices/conversations";

const useStyles = createUseStyles((theme) => ({
    deleteMessageModal: {
        color: theme.palette.secondary.main,
    },
    body: {
        ...theme.utils.flexbox.centered,
        flexDirection: 'column',
        gap: theme.spacing,
        gridTemplateColumns: '1fr',
        padding: theme.spacing * 2,
    },
    action: {
        display: 'flex',
        gap: 16,
        justifyContent: 'flex-end',

        borderTop: `1px solid ${theme.palette.grey[300]}`,
        padding: theme.spacing * 2,
    },
    title: {
        fontSize: 28,
        fontWeight: 700,
        color: theme.palette.primary.background,
        '& p': {
            marginBottom: 8,
        },
    },
    optionWrapper: {
        display: 'flex',
        flexDirection: 'column',
        gap: 16,
    },
    option: {
        display: 'flex',
        flexDirection: 'column',
        gap: 8,
        color: theme.palette.grey[600],
        '& p ': {
            paddingRight: 32,
        },
    },
    optionTitle: {
        ...theme.utils.flexbox.spaceBetween,
        fontWeight: 600,
    },

    cancelButton: {
        width: 'min-content',
        color: colors.primary[400],
    },
    divider: {
        height: 1,
        backgroundColor: theme.palette.grey[300],
    },
    radio: {
        margin: 0,
    },
}))

const DeleteMessageModal = ({message, onClose, isMobile, userMe}) => {
    const pubnub = usePubNub()
    const isMine = message?.uuid === userMe?.pubnub_id
    const startTimeToken = +message.timetoken - 10
    const activeConversation = useSelector(selectActiveChat)

    const formMethods = useForm({
        shouldUnregister: false,
        mode: 'all',
        reValidateMode: 'all',
        nativeValidation: false,
        resolver: yupResolver(validationSchema),
    })
    const {handleSubmit, register, setError} = formMethods


    const onSubmit = (formValues) => {
        if (formValues?.[DELETE_MESSAGE_MODEL.forAll] === 'true') {
            handleDeleteForAll()
            deleteMedia()
            // hardDelete()
        } else {
            handleDeleteForMe()
        }
    }

    const deleteMedia = async () => {
        let mediaID =  {'media_ids': message?.meta?.media}
        try {
            const data = await httpDeleteMedia({channelID: activeConversation?.channelID, media_ids: mediaID})
        } catch (error) {
            handleApiError(
                {
                    isReduxError: false,
                    error: error,
                    callbackOnFieldError: setError,
                });
        }
    }

    const handleDeleteForAll = async () => {
        try {
            const result = await pubnub.addMessageAction({
                channel: message?.channel,
                messageTimetoken: message.timetoken,
                action: {
                    type: 'delete_for_all',
                    value: 'initiator_data',
                },
            })
        } catch (status) {
            console.log(status)
        } finally {
            onClose()
        }
    }

    const handleDeleteForMe = async () => {
        try {
            if (message?.actions?.delete_for_me) {
                handleDeleteForAll()
            } else {
                const result = await pubnub.addMessageAction({
                    channel: message?.channel,
                    messageTimetoken: message.timetoken,
                    action: {
                        type: 'delete_for_me',
                        value: 'initiator_data',
                    },
                })
            }
        } catch (status) {
            console.log(status)
        } finally {
            onClose()
        }
    }

    // const hardDelete = async () => {
    //     const result = await pubnub.deleteMessages({
    //         channel: message?.channel,
    //         start: startTimeToken,
    //         end: message.timetoken,
    //     })
    //     console.log(result)
    // }

    const classes = useStyles({})
    return (
        <Popover
            onClose={onClose}
            title={isMine ? 'Delete Message' : 'Delete for you'}
            maxWidth={isMobile ? 326 : 420}
        >
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className={classes.deleteMessageModal}>
                    <div className={classes.body}>
                        {isMine ? (
                            <div className={classes.optionWrapper}>
                                <div className={classes.option}>
                                    <div className={classes.optionTitle}>
                                        <span>For All</span>
                                        <Radio
                                            className={classes.radio}
                                            value={true}
                                            defaultChecked={true}
                                            {...register(
                                                DELETE_MESSAGE_MODEL.forAll
                                            )}
                                        />
                                    </div>
                                    <p>
                                        This message will be removed for all the
                                        member of the chat.
                                    </p>
                                </div>
                                <div className={classes.divider}/>
                                <div className={classes.option}>
                                    <div className={classes.optionTitle}>
                                        <span>Just for me</span>
                                        <Radio
                                            className={classes.radio}
                                            value={false}
                                            {...register(
                                                DELETE_MESSAGE_MODEL.forAll
                                            )}
                                        />
                                    </div>
                                    <p>
                                        This message will be removed for you.
                                        Other chat members will still be able to
                                        see.
                                    </p>
                                </div>
                            </div>
                        ) : (
                            <p>
                                This message will be removed for you. Other chat
                                members will still be able to see.
                            </p>
                        )}
                    </div>
                    <div className={classes.action}>
                        <Button
                            variant={'borderless'}
                            width={'100%'}
                            onClick={() => onClose()}
                            className={classes.cancelButton}
                        >
                            Cancel
                        </Button>
                        <Button width={178} type={'submit'}>
                            Delete Message
                        </Button>
                    </div>
                </div>
            </form>
        </Popover>
    )
}

export default DeleteMessageModal
