import * as yup from 'yup'

const DELETE_ACCOUNT_MODEL = {
    password: 'password',
}

const deletePasswordValidationSchema = yup.object().shape({
    [DELETE_ACCOUNT_MODEL.password]: yup
        .string()
        .required('The current password is mandatory')
        .min(6, 'Password too short')
        .matches(
            /^(?=.*[0-9])(?=.*[!#$%&*+\-./:;=?@\\^_])(?=.*[A-Z])[a-zA-Z0-9!#$%&*+\-./:;=?@\\^_]{6,16}$/,
            'Password must include at least one number, one uppercase letter and one special character.'
        ),
})

export {
    DELETE_ACCOUNT_MODEL,
    deletePasswordValidationSchema as validationSchema,
}
