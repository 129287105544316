import { createSelector, createSlice } from '@reduxjs/toolkit'

const sliceName = 'app'

const appSlice = createSlice({
    name: sliceName,
    initialState: {
        theme: 'light',
        banner: {
            message: null,
            title: null,
            variant: 'fail',
            visible: false,
        },
        isSubscriptionModalOpen: false,
        isUnsubscribeModalOpen: false,
        isPremiumPostModalOpen: false,
        isTipModalOpen: false,
        subscriptionModalUser: null,
    },
    reducers: {
        setAlertMessage: (state, action) => {
            state.banner.message = action.payload.message
            state.banner.title = action.payload.title
            state.banner.variant = action.payload.variant ?? 'fail'
            state.banner.visible = true
        },
        toggleAlert: (state, action) => {
            state.banner.visible = action.payload.visible
        },
        setSubscriptionModalOpen: (state, action) => {
            state.isSubscriptionModalOpen = action.payload.isOpen
            state.subscriptionModalUser = action.payload.user
        },
        setUnsubscribeModalOpen: (state, action) => {
            state.isUnsubscribeModalOpen = action.payload.isOpen
            state.subscriptionModalUser = action.payload.user
        },
        setPremiumPostModalOpen: (state, action) => {
            state.isPremiumPostModalOpen = action.payload.isOpen
            state.subscriptionModalUser = action.payload.user
        },
        setTipModalOpen: (state, action) => {
            state.isTipModalOpen = action.payload.isOpen
            state.subscriptionModalUser = action.payload.user
        },
    },
})

// Selectors
const selectSelf = (state) => state[sliceName]
export const selectTheme = createSelector(selectSelf, (state) => state.theme)
export const selectAlertMessage = createSelector(
    selectSelf,
    (state) => state?.banner
)
export const selectIsSubscriptionModalOpen = createSelector(
    selectSelf,
    (state) => state?.isSubscriptionModalOpen
)

export const selectIsUnsubscribeModalOpen = createSelector(
    selectSelf,
    (state) => state?.isUnsubscribeModalOpen
)

export const selectIsPremiumPostModalOpen = createSelector(
    selectSelf,
    (state) => state?.isPremiumPostModalOpen
)

export const selectSubscriptionModalUser = createSelector(
    selectSelf,
    (state) => state?.subscriptionModalUser
)
export const selectIsTipModalOpen = createSelector(
    selectSelf,
    (state) => state?.isTipModalOpen
)
export const {
    setAlertMessage,
    toggleAlert,
    setSubscriptionModalOpen,
    setUnsubscribeModalOpen,
    setPremiumPostModalOpen,
    setTipModalOpen,

} = appSlice.actions

export default appSlice.reducer
