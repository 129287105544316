import { createUseStyles } from 'react-jss'
import colors from '../../../../../theme/colors'
import NotificationOptionLabel from './notification-option-label/NotificationOptionLabel'
import React from 'react'

const useStyles = createUseStyles((theme) => ({
    notificationOptionItem: {
        position: 'relative',
        ...theme.utils.flexbox.spaceBetween,
        height: 56,
        '& p': {
            fontWeight: 400,
            letterSpacing: 0.5,
            fontSize: 14,
            color: colors.greyscale[500],
        },
    },
    buttonsLabel: {
        display: 'flex',
        gap: 8,
        height: 24,
        padding: 4,
        backgroundColor: colors.greyscale[150],
    },
    overlay: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(255,255,255,0.5)',
    },
}))

const NotificationOptionItem = ({
    description,
    preference,
    optionID,
    loading,
    handlePreferenceChange,
}) => {
    const classes = useStyles()
    return (
        <div className={classes.notificationOptionItem}>
            <p>{description}</p>
            {loading && <div className={classes.overlay}></div>}
            <div className={classes.buttonsLabel}>
                <NotificationOptionLabel
                    text={'None'}
                    isActive={
                        !preference?.is_notification_active &&
                        !preference?.is_email_active
                    }
                    onClick={() =>
                        handlePreferenceChange(optionID, {
                            is_notification_active: false,
                            is_email_active: false,
                        })
                    }
                />
                <NotificationOptionLabel
                    text={'In-app'}
                    isActive={preference?.is_notification_active}
                    onClick={() =>
                        handlePreferenceChange(optionID, {
                            is_notification_active:
                                !preference?.is_notification_active,
                            is_email_active: preference?.is_email_active,
                        })
                    }
                />
                <NotificationOptionLabel
                    text={'E-mail'}
                    isActive={preference?.is_email_active}
                    onClick={() =>
                        handlePreferenceChange(optionID, {
                            is_notification_active:
                                preference?.is_notification_active,
                            is_email_active: !preference?.is_email_active,
                        })
                    }
                />
            </div>
        </div>
    )
}

export default NotificationOptionItem
