import { createUseStyles } from 'react-jss'
import React, { useEffect, useState } from 'react'
import Button from '../../../../components/Button'
import Popover from '../../../../components/Popover'
import { SuccessFramedIcon } from '../../../../theme/icons'
import { useWindowSize } from '../../../../hooks/useWindowSize'

const useStyles = createUseStyles((theme) => ({
    tipSuccessModal: {
        color: theme.palette.secondary.main,
    },
    body: {
        ...theme.utils.flexbox.centered,
        flexDirection: 'column',
        gap: theme.spacing,
        gridTemplateColumns: '1fr',
        padding: theme.spacing * 2,
    },
    action: {
        borderTop: `1px solid ${theme.palette.grey[300]}`,
        padding: theme.spacing * 2,
    },
    title: {
        fontSize: 28,
        fontWeight: 700,
        color: theme.palette.primary.background,
        '& p': {
            marginBottom: 8,
        },
    },
}))

const TipSuccessModal = ({ onClose }) => {
    const { width } = useWindowSize()
    const [isMobile, setIsMobile] = useState(width <= 448)

    useEffect(() => {
        setIsMobile(width <= 448)
    }, [width])

    const classes = useStyles()
    return (
        <Popover
            onClose={onClose}
            title={false}
            maxWidth={isMobile ? 326 : 420}
        >
            <div className={classes.tipSuccessModal}>
                <div className={classes.body}>
                    <SuccessFramedIcon />
                    <span className={classes.title}>Success</span>
                    <p>Your tip was sent successfully!</p>
                </div>
                <div className={classes.action}>
                    <Button width={'100%'} onClick={() => onClose()}>
                        Continue
                    </Button>
                </div>
            </div>
        </Popover>
    )
}

export default TipSuccessModal
