import { createUseStyles } from 'react-jss'
import React, { useEffect, useMemo, useState } from 'react'
import Popover from '../../../components/Popover'
import Button from '../../../components/Button'
import Banner from '../../../components/Banner'
import { CheckedIcon } from '../../../theme/icons'
import Divider from '../../../components/Divider'
import UserInfo from '../../homepage/posts-feed/post/post-header/user-info'
import {
    httpSubscribeToUser,
    httpUnsubscribeToUser,
} from '../../../http-requests/subscription'
import { handleApiError } from '../../../utilities/helpers'
import { useNavigate, useParams } from 'react-router-dom'
import { ROUTE_FANPAGE } from '../../../utilities/constants'
import MonthlyPriceBox from './monthly-price-box'
import Select from '../../../components/Select'
import { useForm } from 'react-hook-form'
import Box from '../../../components/Box'
import Media from '../../../components/Media'
import paymentProviderLogo from '../../../asset/images/8-pay-logo.png'
import { SUBSCRIPTION_MODEL, validationSchema } from './subscriptionModel'
import { yupResolver } from '@hookform/resolvers/yup'
import dayjs from 'dayjs'
import { components } from 'react-select'
import { httpPaidSubscribeToUser, httpUserTokens } from '../../../http-requests'
import { useDispatch } from 'react-redux'
import { resetMessages } from '../../../store/slices/messages'

const useStyles = createUseStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        color: theme.palette.secondary.main,
    },
    bold: {
        fontWeight: 700,
        color: theme.palette.primary.background,
    },
    free: {
        fontWeight: 400,
    },
    body: {
        ...theme.utils.grid.centered,
        gridRowGap: theme.spacing * 2,
        gridTemplateColumns: '1fr',
        margin: theme.spacing * 2,
        padding: theme.spacing * 2,
        boxShadow:
            '0px 1px 3px rgba(20, 20, 42, 0.1), 0px 0px 1px rgba(20, 20, 42, 0.05)',
        borderRadius: 16,
    },
    action: {
        borderTop: `1px solid ${theme.palette.grey[300]}`,
        padding: theme.spacing * 2,
    },
    divider: {
        margin: 0,
    },
    amountSection: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing * 2,
        margin: [0, theme.spacing * 2, theme.spacing * 2, theme.spacing * 2],
        '& > p': {
            fontSize: 12,
        },
    },
    paymentProvider: {
        ...theme.utils.flexbox.centered,
        gap: theme.spacing,
        padding: 0,
        fontSize: 12,
        color: theme.palette.text.secondary,
        minHeight: 40,
        background: '#FAFAFA',
        boxShadow: 'none',
        '& div': {
            height: 20,
            width: 43,
        },
    },
    select: {
        '& div > div > div': {
            alignItems: 'center',
        },
        '& div > div > div > div': {
            display: 'flex !important',
            placeContent: 'space-between !important',
            alignItems: 'center !important',
        },
    },
    customValue: {
        minHeight: 40,
        width: 111,
        borderRadius: 8,
        marginRight: 8,
        color: theme.palette.secondary.dark,
        border: '1px solid #41D3BD',
        fontWeight: 700,
        fontSize: 14,
        letterSpacing: 0.5,
        '& span': {
            marginLeft: 5,
        },
    },
}))

const SubscriptionModal = ({ onClose, user, postID }) => {
    const { username, id } = useParams()
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(false)
    const [tokens, setTokens] = useState(false)
    const canReactivate = user?.subscription?.status === 'inactive'

    const fetchTokens = async () => {
        try {
            const { data } = await httpUserTokens(user?.id)
            setTokens(
                data?.map(({ id, symbol, discount_percentage }) => ({
                    value: id,
                    label: symbol,
                    discount: discount_percentage,
                }))
            )
        } catch (error) {
            handleApiError({
                isReduxError: false,
                error,
            })
        }
    }

    useEffect(() => {
        fetchTokens()
    }, [])

    const date = new Date()
    const discount = dayjs(date).format('DD') > 15 && 50
    const parsedDate = dayjs(date).format('MM/DD/YYYY')

    const formMethods = useForm({
        shouldUnregister: false,
        mode: 'onBlur',
        reValidateMode: 'onChange',
        nativeValidation: false,
        defaultValues: {},
        resolver: yupResolver(validationSchema),
    })
    const {
        handleSubmit,
        control,
        watch,
        setError,
        formState: { errors },
    } = formMethods

    const selectedToken = watch(SUBSCRIPTION_MODEL.token)
    const happyDiscount =
        tokens &&
        tokens?.filter((token) => token?.label === 'HAPPY')?.[0]?.discount

    const isHappyTokenSelected = selectedToken?.label === 'HAPPY'

    const CustomSingleValue = ({ children, ...props }) => {
        return (
            <components.SingleValue {...props}>
                {children}
                {props?.data?.discount && (
                    <div className={classes.customValue}>
                        <span>{props?.data?.discount}% Discount</span>
                    </div>
                )}
            </components.SingleValue>
        )
    }

    const onSubscribeHandler = async () => {
        try {
            setIsLoading(true)

            const httpCall =
                user?.subscription?.status === 'active'
                    ? httpUnsubscribeToUser
                    : httpSubscribeToUser
            const { data } = await httpCall({ userID: user.id })
            if (!username && !id) {
                navigate(
                    `${ROUTE_FANPAGE.replace(':username', user.username)}/post`
                )
                onClose()
            } else {
                onClose()
                window.location.reload()
            }
        } catch (error) {
            handleApiError({
                isReduxError: false,
                error,
                callbackOnFieldError: setError,
            })
        } finally {
            setIsLoading(false)
        }
    }

    const onSubmit = async (formValues) => {
        let dataToSend = {
            userID: user.id,
            tokenID: formValues?.token?.value,
        }
        try {
            await httpPaidSubscribeToUser(dataToSend)
            if (!username) {
                navigate(
                    `${ROUTE_FANPAGE.replace(':username', user.username)}/post`
                )
                onClose()
            } else {
                onClose()
                window.location.reload()
            }
        } catch (error) {
            if (error.response?.status === 302 && error.response?.data?.url) {
                window.location.replace(error.response?.data?.url)
            } else {
                handleApiError({
                    isReduxError: false,
                    error,
                })
            }
        } finally {
            setIsLoading(false)
        }
    }

    const calculateFinalPrice = useMemo(() => {
        let finalPrice = user?.subscriptions_dollar_price
        if (discount) {
            finalPrice = finalPrice - (finalPrice / 100) * discount
        }
        if (selectedToken?.discount) {
            finalPrice =
                finalPrice - (finalPrice / 100) * selectedToken?.discount
        }
        return finalPrice?.toFixed(2)
    }, [user, selectedToken, discount])

    const classes = useStyles()
    return (
        <Popover
            onClose={onClose}
            title={'Subscriptions'}
            maxWidth={539}
            closeOnOverlay={false}
        >
            <div className={classes.root}>
                <div className={classes.body}>
                    <UserInfo
                        avatar={user?.avatar?.[128]}
                        name={user?.display_name}
                        username={user?.username}
                        isOnline={user?.is_online}
                    />
                    <Divider className={classes.divider} />
                    {!!user?.subscriptions_dollar_price && !canReactivate ? (
                        <MonthlyPriceBox
                            date={parsedDate}
                            label={'SUBSCRIPTION'}
                            text={'Monthly Price:'}
                            amount={user?.subscriptions_dollar_price?.toFixed(
                                2
                            )}
                            discountAmount={discount}
                        />
                    ) : (
                        <span className={classes.bold}>
                            Subscribe:{' '}
                            <span className={classes.free}>Free</span>
                        </span>
                    )}
                    <Banner
                        data={'secondary'}
                        text={'Full access to this user’s content'}
                        icon={<CheckedIcon />}
                        height={40}
                    />
                    <Banner
                        data={'secondary'}
                        text={'Cancel your subscriptions at any time'}
                        icon={<CheckedIcon />}
                        height={40}
                    />
                </div>
                {!!user?.subscriptions_dollar_price && !canReactivate ? (
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <section className={classes.amountSection}>
                            <Select
                                label={'Token Name'}
                                placeholder={'Choose a Token to pay with...'}
                                options={tokens}
                                control={control}
                                components={{ SingleValue: CustomSingleValue }}
                                isSearchable
                                name={SUBSCRIPTION_MODEL.token}
                                errors={errors[SUBSCRIPTION_MODEL.token]}
                                className={classes.select}
                            />
                            {happyDiscount && (
                                <p>
                                    Only if you pay with "HAPPY" you get{' '}
                                    {happyDiscount}% discount on all payments.
                                </p>
                            )}

                            <MonthlyPriceBox
                                text={'Total Amount:'}
                                amount={calculateFinalPrice}
                                isHappyTokenSelected={isHappyTokenSelected}
                            />
                            <Box classNames={classes.paymentProvider}>
                                POWERED BY
                                <Media image={paymentProviderLogo} />
                            </Box>
                        </section>
                        <div className={classes.action}>
                            <Button
                                disabled={isLoading}
                                width={'100%'}
                                type={'submit'}
                            >
                                {user?.subscription?.status === 'active'
                                    ? 'Unsubscribe'
                                    : 'Subscribe'}
                            </Button>
                        </div>
                    </form>
                ) : (
                    <div className={classes.action}>
                        <Button
                            disabled={isLoading}
                            onClick={onSubscribeHandler}
                            width={'100%'}
                        >
                            {user?.subscription?.status === 'active'
                                ? 'Unsubscribe'
                                : canReactivate
                                ? 'Reactivate'
                                : 'Subscribe'}
                        </Button>
                    </div>
                )}
            </div>
        </Popover>
    )
}

export default SubscriptionModal
