import { createUseStyles } from 'react-jss'
import Divider from '../../../../components/Divider'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { WALLET_MODEL } from '../WalletModel'
import Input from '../../../../components/Input'
import Button from '../../../../components/Button'
import colors from '../../../../theme/colors'
import { SideArrowIcon } from '../../../../theme/icons'
import { handleApiError } from '../../../../utilities/helpers'
import { httpStoreWallet } from '../../../../http-requests/wallet'
import { useDispatch } from 'react-redux'
import { setCurrentWallet } from '../../../../store/slices/wallet'
import { useState } from 'react'
import Spinner from '../../../../components/Spinner'

const validationSchema = yup.object().shape({
    [WALLET_MODEL.address]: yup
        .string()
        .required('Wallet address is mandatory'),
})

const useStyles = createUseStyles((theme) => ({
    root: {
        padding: 16,
        '& p': {
            marginBottom: 16,
            '& b': {
                fontSize: 16,
                fontWeight: 700,
                color: theme.palette.secondary.dark,
            },
        },
    },
    fields: {
        display: 'grid',
        gap: 16,
    },
    next: {
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        right: 16,
    },
}))

const AddressStep = ({ setPopoverStepCb }) => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const {
        register,
        handleSubmit,
        setError,
        formState: { touchedFields, errors, isSubmitting },
    } = useForm({
        shouldUnregister: true,
        mode: 'onBlur',
        reValidateMode: 'onChange',
        nativeValidation: false,
        defaultValues: {},
        resolver: yupResolver(validationSchema),
    })

    const onSubmit = async (values) => {
        setLoading(true)
        try {
            const { data } = await httpStoreWallet(
                values?.[WALLET_MODEL?.address]
            )
            dispatch(setCurrentWallet({ ...data }))
            setPopoverStepCb(2)
        } catch (error) {
            handleApiError({
                isReduxError: false,
                error,
                callbackOnFieldError: setError,
            })
        } finally {
            setLoading(false)
        }
    }

    return (
        <div className={classes.root}>
            {loading && <Spinner />}
            <p>
                <b>STEP 1: </b>
                Set up your Wallet address
            </p>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className={classes.fields}>
                    <Input
                        iconPosition={null}
                        type={'text'}
                        label={'Wallet Address *'}
                        placeholder={'Wallet address'}
                        touched={touchedFields[WALLET_MODEL.address]}
                        errors={errors[WALLET_MODEL.address]}
                        {...register(WALLET_MODEL.address)}
                    />
                </div>
                <Divider />
                <Button
                    disabled={isSubmitting}
                    width={'100%'}
                    type={'submit'}
                    variant={'filled'}
                >
                    Continue
                    <span className={classes.next}>
                        <SideArrowIcon data-color />
                    </span>
                </Button>
            </form>
        </div>
    )
}

export default AddressStep
