export const POST_TYPES = {
    video: 'video',
    gallery: 'gallery',
    image: 'image',
    locked: 'locked',
    premium: 'premium',
    text: 'text',
}

export const MEDIA_POST_TYPES = {
    video: 'video',
    image: 'image',
    locked: 'locked',
    premium: 'premium',
}

export const POST_ACTION_NAMES = {
    like: 'like',
    comment: 'comment',
    save: 'save',
    tip: 'tip',
}
