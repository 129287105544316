import SidebarItem from './sidebar-item'

const SectionsSidebar = ({ itemsToRender }) =>
    itemsToRender.map(({ label, icon, link }, index) => (
        <SidebarItem
            label={label}
            icon={icon}
            link={link}
            key={index}
            withDivider={index < itemsToRender?.length - 1}
        />
    ))

export default SectionsSidebar
