import * as yup from 'yup'

const TIP_MODEL = {
    token: 'token',
    amount: 'amount',
    customAmount: 'customAmount',
}

const tipValidationSchema = yup.object().shape({
    [TIP_MODEL.token]: yup
        .object()
        .required('You must choose a token to pay with')
        .typeError('Please select a valid token'),
    [TIP_MODEL.amount]: yup.string().required('Please enter an amount'),
    [TIP_MODEL.customAmount]: yup.mixed().when(TIP_MODEL.amount, {
        is: (amount) => amount === 'custom',
        then: yup
            .number()
            .min(1, 'Amount cannot be less than 1$')
            .required('Please enter an amount')
            .typeError('Please enter a valid amount')
            .test(
                'maxDigitsAfterDecimal',
                'Price can have a maximum of 2 decimals',
                (number) => /^\d+(\.\d{1,2})?$/.test(number)
            ),
        otherwise: yup.mixed().notRequired(),
    }),
})

export { TIP_MODEL, tipValidationSchema as validationSchema }
