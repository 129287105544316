import {createUseStyles} from 'react-jss'
import colors from '../../../../theme/colors'
import Box from '../../../../components/Box'
import {TrashIcon} from '../../../../theme/icons'
import Button from '../../../../components/Button'
import React, {useEffect, useMemo, useRef, useState} from 'react'
import Popover from '../../../../components/Popover'
import DeleteWallet from './delete-wallet'
import WalletNetwork from './wallet-network'
import {useDispatch, useSelector} from 'react-redux'
import {
    resetWallets,
    selectWalletsCurrentPage,
    selectWalletsLastPage,
} from '../../../../store/slices/wallet'
import WalletBoxLoader from './wallet-box-loader'

const useStyles = createUseStyles((theme) => ({
    currentWallet: {
        background: '#FAFAFA',
        marginTop: 16,
        marginBottom: 16,
        padding: 16,
        '& p': {
            color: theme.palette.primary.middle,
        },
    },
    walletLabel: {
        fontWeight: 500,
        color: theme.palette.text.primary,
    },
    walletTitle: {
        display: 'grid',
        gridTemplateColumns: '1fr auto',
        alignItems: 'center',
        '& p': {
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            '& > strong': {
                fontSize: 14,
                fontWeight: 700,
                color: theme.palette.text.primary,
            },
        },
        '& > span': {
            display: 'grid',
            gridTemplateColumns: '1fr auto auto',
            alignItems: 'center',
            '& > button': {
                width: 122,
                backgroundColor: theme.palette.secondary.main,
                border: theme.palette.secondary.main,
                '&:hover': {
                    backgroundColor: theme.palette.secondary.dark,
                },
            },
        },
        '& svg': {
            cursor: 'pointer',
        },
        [theme.mediaQueries.s]: {
            gridTemplateColumns: '1fr',
            '& > span': {
                marginTop: 16,
                '& > button': {
                    width: '100%!important',

                },
            },
        },
    },
    add: {
        marginTop: 24,
        '& > span': {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        '& svg': {
            '& path': {
                stroke: colors.common.white,
            },
        },
    },
    hDivider: {
        width: 1,
        height: '100%',
        display: 'block',
        margin: [0, 12],
        backgroundColor: colors.greyscale[300],
    },
    scrollable: {
        maxHeight: 'calc(100vh - 380px)',
        overflow: 'scroll',
        '&::-webkit-scrollbar': {
            display: 'none',
        },
    },
}))

const WalletBox = ({
                       wallets,
                       fetchInitialCb,
                       fetchMoreCb,
                       isLoadingMore,
                       openCb,
                       addNetworkCb,
                       onAddTokenCb,
                   }) => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const [walletToDelete, setWalletToDelete] = useState(null)
    const [showPopover, setShowPopover] = useState(false)
    // Pagination
    const endRef = useRef(null)
    const currentPage = useSelector(selectWalletsCurrentPage)
    const lastPage = useSelector(selectWalletsLastPage)
    const showBottomLoader = currentPage < lastPage

    useEffect(() => {
        if (!currentPage) {
            fetchInitialCb()
        }
        return () => dispatch(resetWallets())
    }, [])

    const intersectionObserver = useMemo(
        () =>
            new IntersectionObserver(
                (entries) => {
                    const first = entries[0]
                    if (first.isIntersecting) {
                        fetchMoreCb()
                    }
                },
                {
                    threshold: 0,
                    rootMargin: '0px 0px 16px 0px',
                }
            ),
        [wallets, currentPage, lastPage]
    )

    useEffect(() => {
        if (endRef.current) {
            intersectionObserver.observe(endRef.current)
        }
        return () => {
            if (endRef.current) {
                intersectionObserver.unobserve(endRef.current)
            }
        }
    }, [endRef.current, intersectionObserver])

    const onOpenDelete = (wallet) => {
        setWalletToDelete(wallet)
        setShowPopover(!showPopover)
    }

    return (
        <>
            {showPopover && (
                <Popover
                    onClose={() => setShowPopover(!showPopover)}
                    title={'Delete Wallet'}
                    maxWidth={539}
                    maxHeight={600}
                >
                    <DeleteWallet
                        wallet={walletToDelete}
                        onCloseCb={() => setShowPopover(!showPopover)}
                    />
                </Popover>
            )}

            <Box>
                <p className={classes.walletLabel}>Your Wallet</p>
                <div className={classes.scrollable}>
                    {wallets?.map((wallet, index) => (
                        <Box
                            classNames={classes.currentWallet}
                            key={wallet?.id}
                        >
                            <div className={classes.walletTitle}>
                                <p>
                                    <strong>{index + 1}. Wallet address</strong>
                                    <br/>
                                    {wallet?.address}
                                </p>
                                <span>
                                    {wallets?.length > 1 && (
                                        <TrashIcon
                                            stroke={'#ccc'}
                                            width={20}
                                            onClick={() => onOpenDelete(wallet)}
                                        />
                                    )}
                                    {wallets?.length > 1 && (
                                        <span className={classes.hDivider}/>
                                    )}
                                    <Button
                                        width={122}
                                        variant={'filled'}
                                        type={'button'}
                                        size={'small'}
                                        onClick={() => addNetworkCb(wallet?.id)}
                                    >
                                        Add Network
                                    </Button>
                                </span>
                            </div>

                            {wallet?.wallet_networks?.map(
                                ({
                                     network,
                                     network_id,
                                     network_standard_id,
                                     id,
                                     tokens,
                                 }, index) => (
                                    <WalletNetwork
                                        networkIndex={index}
                                        key={network_id}
                                        wallet={wallet}
                                        network={network}
                                        tokens={tokens}
                                        walletNetworkId={id}
                                        onAddTokenCb={onAddTokenCb}
                                    />
                                )
                            )}
                        </Box>
                    ))}
                    {isLoadingMore && (
                        <>
                            <WalletBoxLoader
                                classNames={classes.currentWallet}
                            />
                            <WalletBoxLoader
                                classNames={classes.currentWallet}
                            />
                            <WalletBoxLoader
                                classNames={classes.currentWallet}
                            />
                        </>
                    )}
                    {showBottomLoader && (
                        <div ref={endRef} style={{height: 1}}/>
                    )}
                </div>
            </Box>
            <Button
                className={classes.add}
                width={'100%'}
                variant={'filled'}
                type={'button'}
                onClick={openCb}
            >
                Add wallet
            </Button>
        </>
    )
}

export default WalletBox
