import * as yup from 'yup'

const CHAT_TIP_MODEL = {
    content: 'content',
    token: 'token',
    amount: 'amount',
    customAmount: 'customAmount',
}

const tipValidationSchema = yup.object().shape({
    [CHAT_TIP_MODEL.content]: yup.string().nullable(),
    // [CHAT_TIP_MODEL.token]: yup
    //     .object()
    //     .required('You must choose a token to pay with')
    //     .typeError('Please select a valid token'),
    [CHAT_TIP_MODEL.amount]: yup.string().required('Please enter an amount'),
    [CHAT_TIP_MODEL.customAmount]: yup.mixed().when(CHAT_TIP_MODEL.amount, {
        is: (amount) => amount === 'custom',
        then: yup
            .number()
            .min(1, 'Amount cannot be less than 1$')
            .required('Please enter an amount')
            .typeError('Please enter a valid amount')
            .test(
                'maxDigitsAfterDecimal',
                'Price can have a maximum of 2 decimals',
                (number) => /^\d+(\.\d{1,2})?$/.test(number)
            ),
        otherwise: yup.mixed().notRequired(),
    }),
})

export { CHAT_TIP_MODEL, tipValidationSchema as validationSchema }
