import { createUseStyles } from 'react-jss'
import cx from 'classnames'
import PropTypes from 'prop-types'

const useStyles = createUseStyles((theme) => ({
    label: ({ color, isLight, minHeight, isActive, minWidth }) => ({
        ...theme.utils.flexbox.centered,
        padding: theme.spacing / 2,
        borderRadius: 100,
        fontWeight: 500,
        minHeight: minHeight ?? 32,
        minWidth: minWidth ?? 32,
        fontSize: 12,
        whiteSpace: 'nowrap',
        color: isLight ? theme.palette[color].main : theme.palette.common.white,
        backgroundColor: `${
            theme.palette[color][isLight ? 'buttonLight' : 'main']
        }`,
        ...(!isActive && {
            color: theme.palette.label.color,
            border: `1px solid ${theme.palette.label.borderColor}`,
            backgroundColor: `transparent`,
        }),
    }),
}))

const Label = ({
    color = 'primary',
    minHeight,
    minWidth,
    className,
    isLight = false,
    callBack,
    children,
    isActive = true,
}) => {
    const classes = useStyles({ color, isLight, minHeight, minWidth, isActive })
    return (
        <div className={cx(classes.label, className)} onClick={callBack}>
            {children}
        </div>
    )
}

export default Label

Label.propTypes = {
    color: PropTypes.string,
    minHeight: PropTypes.number,
    className: PropTypes.string,
    isLight: PropTypes.bool,
    callBack: PropTypes.func,
    children: PropTypes.node,
}
