import { createUseStyles } from 'react-jss'
import Box from '../../../../components/Box'
import colors from '../../../../theme/colors'
import Media from '../../../../components/Media'
import { HappyLogoIcon, VerifiedBadgeIcon } from '../../../../theme/icons'
import cx from 'classnames'
import Spinner from '../../../../components/Spinner'
import { httpFollowUser, httpUnfollowUser } from '../../../../http-requests'
import { handleApiError } from '../../../../utilities/helpers'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

const useStyles = createUseStyles((theme) => ({
    item: {
        padding: 8,
        overflow: 'hidden',
        height: '100%',
        display: 'grid',
        alignItems: 'center',
        gap: 8,
        gridTemplateRows: '163px 1fr',
    },
    mainBg: {
        height: '100%',
        overflow: 'hidden',
        borderRadius: 6,
        cursor: 'pointer',
    },
    subscription: {
        background: 'rgba(252, 252, 252, 0.1)',
        backdropFilter: 'blur(8px)',
        borderRadius: '5px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: 14,
        fontWeight: 700,
        color: colors.common.white,
        position: 'absolute',
        top: '8px',
        left: '8px',
        padding: '4px',
    },
    name: {
        display: 'inline-flex',
        alignItems: 'center',
        position: 'absolute',
        bottom: 24,
        left: 8,
        '& span': {
            fontSize: 14,
            fontWeight: 700,
            color: colors.common.white,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: 135,
        },
        '& svg': {
            margin: 4,
        },
    },
    username: {
        position: 'absolute',
        bottom: 8,
        left: 8,
        fontSize: 12,
        fontWeight: 400,
        color: colors.common.white,
    },
    followBtn: {
        fontSize: '14px',
        width: '168px',
        fontWeight: 700,
        height: '40px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 12,
        background: 'transparent',
        color: theme.palette.primary.main,
        transition: 'ease-in-out 300ms',
        cursor: 'pointer',
        border: `2px solid ${theme.palette.primary.main}`,
    },
    active: {
        transition: 'ease-in 300ms',
        background: theme.palette.primary.main,
        border: `2px solid ${theme.palette.primary.main}`,
        color: colors.common.white,
    },
}))

const SuggestionItem = ({
    itemIndex,
    groupIndex,
    grouped,
    setGroupedCb,
    item: {
        avatar,
        subscriptions_dollar_price,
        display_name,
        is_verified,
        username,
        is_followed,
    },
    ...rest
}) => {
    const classes = useStyles()
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()

    const onFollowToggle = async (itemIndex, groupIndex) => {
        const newGrouped = grouped
        const user = newGrouped[groupIndex][itemIndex]
        const userID = user?.id
        const isFollowed = user?.is_followed
        setLoading(true)
        try {
            isFollowed
                ? await httpUnfollowUser(userID)
                : await httpFollowUser(userID)
            newGrouped[groupIndex][itemIndex].is_followed = !user?.is_followed
            setGroupedCb([...newGrouped])
        } catch (error) {
            handleApiError({
                isReduxError: false,
                error,
            })
        } finally {
            setLoading(false)
        }
    }

    const onProfileClick = () => {
        navigate(`/${username}`)
    }

    return (
        <Box classNames={classes.item} {...rest}>
            <Media
                className={classes.mainBg}
                image={avatar?.[128]}
                variant={'cover'}
                onClick={onProfileClick}
                gradient={
                    'linear-gradient(180deg, rgba(39, 8, 51, 0) 26.04%, #270833 100%)'
                }
            >
                <p className={classes.subscription}>
                    {+subscriptions_dollar_price > 0 ? (
                        <HappyLogoIcon width={16} />
                    ) : (
                        'Free'
                    )}
                </p>
                <p className={classes.name}>
                    <span>{display_name}</span>
                    {is_verified && <VerifiedBadgeIcon width={14} />}
                </p>
                <p className={classes.username}>@{username}</p>
            </Media>

            <button
                className={cx(classes.followBtn, is_followed && classes.active)}
                type={'button'}
                onClick={() => onFollowToggle(itemIndex, groupIndex)}
                disabled={loading}
            >
                {is_followed && !loading ? 'Unfollow' : 'Follow'}
                {loading && <Spinner size={24} />}
            </button>
        </Box>
    )
}

export default SuggestionItem
