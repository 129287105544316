import { createUseStyles } from 'react-jss'
import logo from '../../../asset/images/logo.png'
import React, { useMemo } from 'react'
import { useWindowSize } from '../../../hooks/useWindowSize'
import backgroundLeft from '../asset/background-left.png'
import backgroundRight from '../asset/background-right.png'
import backgroundMobile from '../asset/background-mobile.png'

const useStyles = createUseStyles((theme) => ({
    header: {
        ...theme.utils.flexbox.centered,
        gap: theme.spacing,
        flexDirection: 'column',
        textAlign: 'center',
        paddingTop: theme.spacing * 8,
        paddingBottom: theme.spacing * 2,
        '& h1': {
            padding: 6,
            color: theme.palette.text.primary,
            letterSpacing: 0.25,
            fontSize: 18,
        },
        '& p': {
            fontSize: 12,
        },
        [theme.mediaQueries.xxsUp]: {
            '& h1': {
                fontSize: 28,
            },
            '& p': {
                fontSize: 16,
            },
        },
        [theme.mediaQueries.mUp]: {
            padding: 0,
            height: 293,
            maxWidth: 580,
            minWidth: 445,
            '& h1': {
                fontSize: 28,
            },
        },
    },
    logo: {
        maxWidth: 160,
        maxHeight: 40,
        marginBottom: theme.spacing * 2,
        [theme.mediaQueries.mUp]: {
            maxWidth: 224,
            maxHeight: 56,
        },
    },
    backgroundContainer: {
        backgroundColor: theme.palette.quaternary.backgroundLight,
        display: 'flex',
        justifyContent: 'space-between',
        width: '100vw',
    },
    background: {
        maxHeight: '100%',
        width: 430,
        backgroundRepeat: 'no-repeat',
    },
    backgroundLeft: {
        extend: 'background',
        backgroundImage: `url(${backgroundLeft})`,
        backgroundSize: 450,
        backgroundPosition: 'top right',
    },
    backgroundRight: {
        extend: 'background',
        backgroundImage: `url(${backgroundRight})`,
        backgroundSize: 450,
        backgroundPosition: 'top left',
    },
    backgroundMobile: {
        extend: 'background',
        ...theme.utils.flexbox.centered,
        backgroundSize: '100%',
        width: '100%',
        backgroundPosition: 'top',
        backgroundImage: `url(${backgroundMobile})`,
    },
}))

const KeepSupportingHeader = ({}) => {
    const { width } = useWindowSize()
    const isMobile = width < 768


    const classes = useStyles()
    const memoizedHeader = useMemo(() => {
        return (
            <div className={classes.header}>
                <div className={classes.logo}>
                    <img src={logo} alt={'Happyfans'} />
                </div>
                <h1>Keep supporting your Creators</h1>
                <p>
                    It’s time to renew your subscriptions, support your favorite
                    creators and keep watching their fantastic content!
                </p>
            </div>
        )
    }, [classes])

    return (
        <div className={classes.backgroundContainer}>
            {isMobile ? (
                <div className={classes.backgroundMobile}>
                    {memoizedHeader}
                </div>
            ) : (
                <>
                    <div className={classes.backgroundLeft} />
                    {memoizedHeader}
                    <div className={classes.backgroundRight} />
                </>
            )}
        </div>
    )
}

export default KeepSupportingHeader
