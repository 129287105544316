import {createUseStyles} from 'react-jss'
import colors from '../../../theme/colors'
import Box from '../../../components/Box'
import {
    CloseIcon, CopyIcon,
    DownArrowIcon,
    MoreIcon,
    StarIcon,
    TrashIcon,
} from '../../../theme/icons'
import {useDispatch, useSelector} from 'react-redux'
import {selectUser, updateUser} from '../../../store/slices/user'
import {useForm, FormProvider} from 'react-hook-form'
import {useEffect, useState} from 'react'
import {
    copyTextToClipboard,
    handleApiError,
    iterateOverTouchedFields,
    remapApiOptions,
    retrieveSingleValueForRs,
    retrieveValuesForRs,
} from '../../../utilities/helpers'
import {
    httpDeleteProfileAvatar,
    httpDeleteProfileHeader,
    httpFetchCategories,
    httpUpdateProfile,
} from '../../../http-requests'
import {yupResolver} from '@hookform/resolvers/yup'
import ProfileCard from '../../landing-page/profile-section/profile-card'
import Select from '../../../components/Select'
import {components} from 'react-select'
import {
    PROFILE_MODEL,
    PROFILE_MODEL_SOCIALS,
    validationSchema,
} from './ProfileModel'
import Input from '../../../components/Input'
import TextArea from '../../../components/TextArea'
import ToggleSwitch from '../../../components/ToggleSwitch'
import Button from '../../../components/Button'
import {DevTool} from '@hookform/devtools'
import Divider from '../../../components/Divider'
import PreLabeledField from './pre-labeled-field'
import cx from 'classnames'
import AvatarImageUploader from './avatar-image-uploader'
import BannerImageUploader from './banner-image-uploader'
import ActionsDropdown from '../../../components/ActionsDropdown'
import LocalStorageManager from '../../../utilities/localStorage'
import {setAlertMessage} from '../../../store/slices/app'
import {SUCCESS} from '../../../utilities/constants'
import Spinner from '../../../components/Spinner'
import Banner from "../../../components/Banner";
import * as routeNames from "../../../utilities/constants";

const useStyles = createUseStyles((theme) => ({
    sectionTitle: {
        fontWeight: 'bold',
        fontSize: '18px',
        lineHeight: '140%',
        color: colors.tertiary[800],
    },
    sectionSubtitle: {
        marginTop: 8,
        marginBottom: 24,
    },
    roleTitle: {
        fontSize: 16,
        fontWeight: 700,
        color: theme.palette.primary.background,
        marginTop: 8,
        marginBottom: 16,

    },
    noPad: {padding: 0},
    mbForm: {
        marginTop: 24,
        marginBottom: 24,
    },
    mbFormInput: {
        marginTop: 0,
    },
    mbFormInputEnd: {
        marginTop: -4,
    },
    mbFormButton: {
        marginTop: 26,
    },
    smallDivider: {margin: [16, 0, 0]},
    pictureBox: {
        height: 260,
        marginTop: 24,
        marginBottom: 24,
        padding: 0,
        overflow: 'hidden',
    },
    header: {'& > label': {margin: 0}},
    avatar: {
        position: 'absolute',
        bottom: 16,
        left: 16,
        '& > label': {margin: 0},
    },
    more: {
        position: 'absolute',
        top: '16px',
        right: '15px',
        zIndex: 2,
        background: '#cccccc99',
        borderRadius: '24px',
        height: '24px',
        width: '24px',
        cursor: 'pointer',
        '& svg': {
            transform: 'rotate(90deg)',
            '& path': {
                stroke: colors.common.white,
            },
        },
    },
    socialsInput: {
        '& input': {
            padding: [8, 16],
        },
    },
    scrollable: {
        height: 'calc(100vh - 265px)',
        overflow: 'scroll',
        '&::-webkit-scrollbar': {
            display: 'none',
        },
        padding: [0, 4, 40],
    },
    accordion: ({socialsExpanded}) => ({
        maxHeight: socialsExpanded ? 600 : 330,
        overflow: 'hidden',
        transition: 'ease-in-out 400ms',
    }),
    cardWrapper: {
        paddingBottom: theme.spacing * 5,

    },
    expander: ({socialsExpanded}) => ({
        position: 'absolute',
        cursor: 'pointer',
        background: colors.common.white,
        color: colors.greyscale[600],
        fontSize: 16,
        bottom: '0',
        right: '0',
        left: '0',
        borderRadius: 24,
        padding: [18, 24],
        '& svg': {
            transform: socialsExpanded ? 'rotate(180deg)' : 'rotate(0deg)',
            transition: 'ease-in-out 400ms',
            marginLeft: 16,
            '& path': {
                stroke: colors.greyscale[700],
            },
        },
    }),
    accountDivider: {
        margin: [100, 0],
    },
    mailButtonsContainer: {
        gap: '24px',
        gridTemplateColumns: '1fr',
        display: 'grid',
        justifyItems: 'center',
        [theme.mediaQueries.mUp]: {
            gridTemplateColumns: '1fr 1fr',
            gap: 0,
        },
    },
    mailButton: {
        textDecoration: 'none',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '12px',
        position: 'relative',
        maxWidth: '217px',
        height: '48px',
        color: colors?.quaternary[500],
        background: 'rgba(233, 88, 128, 0.05)',
        borderRadius: '12px',
    },
    wrapperBanner: {
        padding: [theme.spacing * 2, 0]
    },

    referral: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: 8,
    },

    referralLink: {
        width: '100%',
        '& textarea': {
            padding: [14, 16],
            maxHeight: 56,
            whiteSpace: 'nowrap',
            overflowY: 'hidden',
        }
    },

    copyLink: {
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
    }
}))

const Profile = () => {
    const user = useSelector(selectUser)
    const dispatch = useDispatch()
    const [categories, setCategories] = useState([])
    const [socialsExpanded, setSocialsExpanded] = useState(false)
    const [showActions, setShowActions] = useState(false)
    const [loading, setLoading] = useState(false)
    const classes = useStyles({socialsExpanded})
    //forms
    const formMethods = useForm({
        shouldUnregister: false,
        mode: 'onBlur',
        reValidateMode: 'onChange',
        criteriaMode: 'firstError',
        shouldFocusError: true,
        nativeValidation: false,
        defaultValues: {},
        resolver: yupResolver(validationSchema),
    })
    const {
        handleSubmit,
        control,
        register,
        setError,
        setFocus,
        reset,
        watch,
        setValue,
        formState: {touchedFields, errors, isSubmitting},
    } = formMethods
    const methods = useForm()

    const onSubmit = async (formValues) => {
        let dataToSend = iterateOverTouchedFields(touchedFields, formValues)
        dataToSend = {
            ...dataToSend,
            username: formValues?.username,
            [PROFILE_MODEL.categoryIDs]: formValues[
                PROFILE_MODEL?.categoryIDs
                ]?.map((e) => e.value),
            [PROFILE_MODEL.categoryID]:
            formValues[PROFILE_MODEL?.categoryID]?.value,
            [PROFILE_MODEL.youtube]: !!formValues[PROFILE_MODEL?.youtube]
                ? formValues[PROFILE_MODEL?.youtube]
                : null,
            [PROFILE_MODEL.website]: !!formValues[PROFILE_MODEL?.website]
                ? formValues[PROFILE_MODEL?.website]
                : null,
        }
        try {
            setLoading(true)
            const {data: user} = await httpUpdateProfile(dataToSend)
            dispatch(
                setAlertMessage({
                    message: 'Profile saved successfully!',
                    variant: SUCCESS,
                })
            )
            LocalStorageManager.user.set(user)
            dispatch(updateUser(user))
        } catch (error) {
            handleApiError({
                isReduxError: false,
                error,
                callbackOnFieldError: setError,
                callbackOnFocusError: setFocus,
            })
        } finally {
            setLoading(false)
        }
    }

    const getCategories = async () => {
        try {
            const {data} = await httpFetchCategories()
            setCategories(remapApiOptions(data.data))
        } catch (e) {
            console.log('error', e)
        }
    }

    const onDeleteImage = async (type) => {
        try {
            type === 'avatar'
                ? await httpDeleteProfileAvatar()
                : await httpDeleteProfileHeader()
            if (type === 'avatar') {
                setValue(PROFILE_MODEL.avatar, null)
                LocalStorageManager.user.set({...user, avatar: null})
                dispatch(updateUser({...user, avatar: null}))
            } else {
                setValue(PROFILE_MODEL.header, null)
                LocalStorageManager.user.set({...user, header: null})
                dispatch(updateUser({...user, header: null}))
            }
            setShowActions(false)
        } catch (error) {
            handleApiError({
                isReduxError: false,
                error,
                callbackOnFieldError: setError,
            })
        }
    }

    const actions = [
        {
            title: 'Delete Banner image',
            icon: <TrashIcon width={16} data-colors/>,
            callback: () => onDeleteImage('header'),
            hoverType: 'danger',
        },
        {
            title: 'Delete Avatar image',
            icon: <TrashIcon width={16} data-colors/>,
            callback: () => onDeleteImage('avatar'),
            hoverType: 'danger',
        },
    ]

    useEffect(() => {
        if (!categories.length) {
            getCategories()
        }
    }, [])

    // Refresh form when user changes
    useEffect(() => {
        reset({
            ...user,
            [PROFILE_MODEL.categoryIDs]: retrieveValuesForRs(
                categories,
                user?.[PROFILE_MODEL.categoryIDs]
            ),
            [PROFILE_MODEL.isCreator]: user?.[PROFILE_MODEL.isCreator],
            [PROFILE_MODEL.categoryID]: retrieveSingleValueForRs(
                categories,
                user?.category?.id
            ),
        })
    }, [categories, user])

    const watchIsCreator = watch(PROFILE_MODEL.isCreator)

    const referralLink = `${window.location.origin}${routeNames.ROUTE_SIGNUP}?r=${user?.referral_code}`
    const copyReferralLink = () => {
        copyTextToClipboard(referralLink)
        dispatch(
            setAlertMessage({
                message: 'Referral link copied to clipboard!',
                variant: SUCCESS,
            })
        )
    }

    return (
        <>
            {loading && <Spinner overlayFullscreen={true} size={40}/>}
            <p className={classes.sectionTitle}>Edit Profile</p>
            <p className={classes.sectionSubtitle}>Change profile and cover photos</p>

            <FormProvider {...formMethods}>
                <form
                    onSubmit={handleSubmit(onSubmit)}
                    className={classes.scrollable}
                >
                    <Box classNames={classes.pictureBox}>
                        {(user?.avatar || user?.header) && (
                            <span
                                className={classes.more}
                                onClick={() => setShowActions(!showActions)}
                            >
                                <MoreIcon width={24} data-color/>
                            </span>
                        )}
                        <ActionsDropdown
                            actions={actions}
                            rightMargin={48}
                            openToggle={() => setShowActions(!showActions)}
                            isOpened={showActions}
                        />

                        <BannerImageUploader
                            field={PROFILE_MODEL?.header}
                            className={classes.header}
                        />
                        <AvatarImageUploader
                            field={PROFILE_MODEL?.avatar}
                            className={classes.avatar}
                        />
                    </Box>

                    <Box classNames={classes.mbForm}>
                        <ProfileCard
                            withIcon={false}
                        >
                            <Input
                                iconPosition={null}
                                type={'text'}
                                placeholder={'Username'}
                                className={classes.mbFormInput}
                                helpText={'https:/app.happyfans.club/Username'}
                                touched={touchedFields[PROFILE_MODEL.username]}
                                errors={errors[PROFILE_MODEL.username]}
                                {...register(PROFILE_MODEL.username)}
                            />
                            <Input
                                iconPosition={null}
                                type={'text'}
                                placeholder={'Display Name'}
                                className={classes.mbFormInput}
                                touched={
                                    touchedFields[PROFILE_MODEL.displayedName]
                                }
                                errors={errors[PROFILE_MODEL.displayedName]}
                                {...register(PROFILE_MODEL.displayedName)}
                            />
                            <TextArea
                                placeholder={'About me (optional)'}
                                className={classes.mbFormInput}
                                rows={6}
                                rootClassName={classes.noPad}
                                touched={touchedFields[PROFILE_MODEL.bio]}
                                errors={errors[PROFILE_MODEL.bio]}
                                {...register(PROFILE_MODEL.bio)}
                            />
                            <Input
                                iconPosition={null}
                                type={'text'}
                                className={classes.mbFormInputEnd}
                                placeholder={'Location (optional)'}
                                touched={touchedFields[PROFILE_MODEL.location]}
                                errors={errors[PROFILE_MODEL.location]}
                                {...register(PROFILE_MODEL.location)}
                            />
                        </ProfileCard>
                    </Box>

                    <Box classNames={classes.mbForm}>
                        <ProfileCard title={'Website Url'} withIcon={false}>
                            <Input
                                iconPosition={null}
                                type={'text'}
                                placeholder={'Enter the url'}
                                touched={touchedFields[PROFILE_MODEL.website]}
                                errors={errors[PROFILE_MODEL.website]}
                                {...register(PROFILE_MODEL.website)}
                            />
                        </ProfileCard>
                    </Box>

                    <Box classNames={cx(classes.mbForm, classes.accordion)}>
                        <ProfileCard
                            title={'On the Web'}
                            withIcon={false}
                            className={classes.cardWrapper}
                        >
                            {PROFILE_MODEL_SOCIALS?.map(
                                (
                                    {icon, name, placeholder, formField},
                                    index
                                ) => (
                                    <div key={index}>
                                        <PreLabeledField
                                            icon={icon}
                                            title={name}
                                        >
                                            <Input
                                                rootClassName={classes.socialsInput}
                                                iconPosition={null}
                                                type={'text'}
                                                placeholder={placeholder}
                                                variant={'small'}
                                                {...register(formField)}
                                                touched={
                                                    touchedFields[formField]
                                                }
                                                errors={errors[formField]}
                                            />
                                        </PreLabeledField>
                                        {index <
                                            PROFILE_MODEL_SOCIALS.length -
                                            1 && (
                                                <Divider
                                                    className={classes.smallDivider}
                                                />
                                            )}
                                    </div>
                                )
                            )}
                        </ProfileCard>
                        <div
                            className={classes.expander}
                            onClick={() => setSocialsExpanded(!socialsExpanded)}
                        >
                            <span>
                                {' '}
                                {socialsExpanded ? 'See less' : 'See more'}{' '}
                                <DownArrowIcon data-color width={16}/>{' '}
                            </span>
                        </div>
                    </Box>

                    <Box classNames={classes.mbForm}>
                        <div className={classes.roleTitle}>
                            <span>{'Account HappyFans'}</span>
                        </div>
                        <ProfileCard
                            title={"I'm a Fan"}
                            description={
                                'Select the categories of content you want to view'
                            }
                        >
                            <Select
                                isSearchable
                                isMulti
                                control={control}
                                placeholder={
                                    'Choose a at least one category...'
                                }
                                name={PROFILE_MODEL.categoryIDs}
                                options={categories}
                                components={{MultiValueRemove}}
                                multiValueCustomStyle={multiValueCustomStyle}
                                multiValueLabelCustomStyle={
                                    multiValueLabelCustomStyle
                                }
                            />
                        </ProfileCard>

                        <Divider margin={'25px 0'}/>

                        {!user?.is_creator_verified && user?.is_creator &&
                            <div className={classes.wrapperBanner}>
                                <Banner
                                    text={'Your request is being approved!'}
                                />
                            </div>}

                        <ProfileCard
                            type={'creator'}
                            title={"I'm a Creator"}
                            icon={<StarIcon/>}
                            description={
                                'Select the category of content you want to create'
                            }
                            action={
                                <ToggleSwitch
                                    isSmall
                                    {...register(PROFILE_MODEL.isCreator)}
                                />
                            }
                        >
                            <Select
                                disabled={!watchIsCreator}
                                control={control}
                                placeholder={'Choose your category...'}
                                name={PROFILE_MODEL.categoryID}
                                options={categories}
                                errors={errors[PROFILE_MODEL.categoryID]}
                                components={{
                                    MultiValueRemove,
                                }}
                                multiValueCustomStyle={multiValueCustomStyle}
                                multiValueLabelCustomStyle={
                                    multiValueLabelCustomStyle
                                }
                                singleValueCustomStyle={
                                    multiValueLabelCustomStyle
                                }
                            />
                        </ProfileCard>
                    </Box>


                    <Box classNames={classes.mbForm}>
                        <div className={classes.roleTitle}>
                            <span>{'Referral'}</span>
                        </div>


                        <div className={classes.referralContainer}>
                            <div className={classes.referral}>
                                 <textarea
                                     className={classes.referralLink}
                                     rows={1}
                                     readOnly
                                     defaultValue={referralLink}
                                 />
                                <CopyIcon
                                    className={classes.copyLink}
                                    width={16}
                                    height={16}
                                    onClick={copyReferralLink}
                                />
                            </div>
                        </div>
                    </Box>

                    {/* <div
                        className={cx(
                            classes.mbForm,
                            classes.mailButtonsContainer
                        )}
                    >
                        <a
                            href={
                                'mailto:business@happyfans.club?subject=Request profile deletion'
                            }
                            className={classes.mailButton}
                        >
                            Request profile deletion{' '}
                        </a>
                        <a
                            href={
                                'mailto:business@happyfans.club?subject=Request data exportation'
                            }
                            className={classes.mailButton}
                        >
                            Request data exportation{' '}
                        </a>
                    </div>*/}

                    <Button
                        width={'100%'}
                        margin
                        variant={'filled'}
                        type={'submit'}
                        disabled={isSubmitting}
                    >
                        Save Changes
                    </Button>
                    {process.env.NODE_ENV === 'development' && (
                        <DevTool control={control}/>
                    )}
                </form>
            </FormProvider>
        </>
    )
}

const multiValueCustomStyle = {
    padding: '10px 4px',
    borderRadius: 8,
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'rgba(121, 65, 117, 0.05)',
}

const multiValueLabelCustomStyle = {
    color: '#2E2E2E',
    fontWeight: 400,
    fontSize: 14,
    letterSpacing: 0.5,
}

const MultiValueRemove = (props) => {
    return (
        <components.MultiValueRemove {...props}>
            <CloseIcon width={20} height={20} stroke={'#2E2E2E'}/>
        </components.MultiValueRemove>
    )
}

export default Profile
