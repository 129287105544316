import {
    httpDeletePost,
    httpFetchFeed,
    httpFetchSinglePost,
    httpFetchUserPosts,
    httpLikePost,
    httpSavePost,
    httpUnlikePost,
    httpUnsavePost,
} from '../../http-requests/'
import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit'
import { normalize, schema } from 'normalizr'

const sliceName = 'timeline'

/* Schemas to normalize data from API */
const postSchema = new schema.Entity(sliceName)

export const fetchTimelinePosts = createAsyncThunk(
    `${sliceName}/fetchTimelinePosts`,
    async ({ page, limit = 12 }) => {
        const { data } = await httpFetchFeed({ page, limit })
        const { entities, result: allIds } = normalize(data.data, [postSchema])
        return {
            ...data,
            data: entities[postSchema.key],
            allIds,
        }
    }
)

export const fetchUserPosts = createAsyncThunk(
    `${sliceName}/fetchUserPosts`,
    async ({ userID, page, limit = 12 }) => {
        const { data } = await httpFetchUserPosts({ userID, page, limit })
        const { entities, result: allIds } = normalize(data.data, [postSchema])
        return {
            ...data,
            data: entities[postSchema.key],
            allIds,
        }
    }
)

export const fetchSinglePost = createAsyncThunk(
    `${sliceName}/fetchSinglePost`,
    async ({ postID }) => {
        const { data } = await httpFetchSinglePost(postID)
        return data
    }
)

export const savePost = createAsyncThunk(
    `${sliceName}/savePost`,
    async ({ postID }) => {
        const { data } = await httpSavePost(postID)
        return { postID }
    }
)

export const likePost = createAsyncThunk(
    `${sliceName}/likePost`,
    async ({ postID }) => {
        const { data } = await httpLikePost(postID)
        return { postID }
    }
)

export const unlikePost = createAsyncThunk(
    `${sliceName}/unlikePost`,
    async ({ postID }) => {
        const { data } = await httpUnlikePost(postID)
        return { postID }
    }
)

export const unsavePost = createAsyncThunk(
    `${sliceName}/unsavePost`,
    async ({ postID }) => {
        const { data } = await httpUnsavePost(postID)
        return { postID }
    }
)

export const deletePost = createAsyncThunk(
    `${sliceName}/deletePost`,
    async ({ postID }) => {
        const { data } = await httpDeletePost(postID)
        return { postID }
    }
)

const timeline = createSlice({
    name: sliceName,
    initialState: {
        isLoading: false,
        byId: {},
        allIds: [],
        currentPage: 0,
        lastPage: 0,
        total: 0,
        perPage: 12,
    },
    reducers: {
        updateTimelinePostCommentCount: (state, action) => {
            state.byId[action.payload.postID]['comments_count'] += 1
        },
        resetTimelinePostsPagination: (state, action) => {
            state.byId = {}
            state.allIds = []
            state.currentPage = 0
            state.lastPage = 0
            state.total = 0
        },
    },
    extraReducers: {
        [fetchUserPosts.fulfilled]: (state, action) => {
            state.allIds =
                action.payload.current_page === 1
                    ? action.payload.allIds
                    : [...state.allIds, ...action.payload.allIds]
            state.byId =
                action.payload.current_page === 1
                    ? action.payload.data
                    : { ...state.byId, ...action.payload.data }
            state.currentPage = action.payload.current_page
            state.lastPage = action.payload.last_page
            state.total = action.payload.total
            state.perPage = +action.payload.per_page
        },
        [fetchTimelinePosts.fulfilled]: (state, action) => {
            state.allIds =
                action.payload.current_page === 1
                    ? action.payload.allIds
                    : [...state.allIds, ...action.payload.allIds]
            state.byId =
                action.payload.current_page === 1
                    ? action.payload.data
                    : { ...state.byId, ...action.payload.data }
            state.currentPage = action.payload.current_page
            state.lastPage = action.payload.last_page
            state.total = action.payload.total
            state.perPage = +action.payload.per_page
        },
        [fetchSinglePost.fulfilled]: (state, action) => {
            state.byId[action.payload.id] = action.payload
        },
        [likePost.pending]: (state, action) => {
            state.isLoading = true
        },
        [likePost.fulfilled]: (state, action) => {
            state.isLoading = false
            state.byId[action.payload.postID]['likes_count'] += 1
            state.byId[action.payload.postID]['is_liked'] = true
        },
        [unlikePost.pending]: (state, action) => {
            state.isLoading = true
        },
        [unlikePost.fulfilled]: (state, action) => {
            state.isLoading = false
            state.byId[action.payload.postID]['likes_count'] -= 1
            state.byId[action.payload.postID]['is_liked'] = false
        },
        [savePost.pending]: (state, action) => {
            state.isLoading = true
        },
        [savePost.fulfilled]: (state, action) => {
            state.isLoading = false
            state.byId[action.payload.postID]['is_saved'] = true
        },
        [unsavePost.pending]: (state, action) => {
            state.isLoading = true
        },
        [unsavePost.fulfilled]: (state, action) => {
            state.isLoading = false
            state.byId[action.payload.postID]['is_saved'] = false
        },
        [deletePost.fulfilled]: (state, action) => {
            const { [action.payload.postID]: removedValue, ...restOfPosts } =
                state.byId
            state.byId = restOfPosts
            state.allIds = state.allIds.filter(
                (postID) => postID !== action.payload.postID
            )
        },
    },
})

export default timeline.reducer
export const { updateTimelinePostCommentCount, resetTimelinePostsPagination } =
    timeline.actions

const selectSelf = (state) => state[sliceName]
export const selectTimelinePostsById = createSelector(
    selectSelf,
    (state) => state.byId
)

export const selectTimelinePostsAllIds = createSelector(
    selectSelf,
    (state) => state.allIds
)

export const selectTimelinePostsLastPage = createSelector(
    selectSelf,
    (state) => state.lastPage
)
export const selectTimelinePostsCurrentPage = createSelector(
    selectSelf,
    (state) => state.currentPage
)
export const selectTimelinePostsTotal = createSelector(
    selectSelf,
    (state) => state.total
)
export const selectTimelinePostsPerPage = createSelector(
    selectSelf,
    (state) => state.perPage
)

export const selectIsTimelineLoading = createSelector(
    selectSelf,
    (state) => state?.isLoading
)
