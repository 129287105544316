export const UnsubscribeSVG = ({ ...props }) => {
    return (
        <svg width="270" height="230" viewBox="0 0 270 230" fill="none" xmlns="http://www.w3.org/2000/svg">
            <ellipse cx="127.5" cy="208.117" rx="126.919" ry="21.4177" fill="#F3F3F3"/>
            <path d="M199.043 99.0816C196.23 97.4574 192.632 98.4213 191.008 101.235L181.205 118.215C179.581 121.028 180.545 124.625 183.358 126.249L197.756 134.562L197.756 142.486L204.618 138.524L219.016 146.837C221.829 148.461 225.426 147.497 227.051 144.684L236.854 127.704C238.478 124.89 237.514 121.293 234.701 119.669L199.043 99.0816Z" fill="#6426E1"/>
            <path d="M211.016 129.99C210.378 129.622 209.901 129.215 209.587 128.771C209.277 128.321 209.102 127.871 209.062 127.422C209.033 126.971 209.12 126.554 209.322 126.173C209.357 126.112 209.407 126.073 209.471 126.055C209.535 126.038 209.598 126.047 209.659 126.083L211.024 126.871C211.113 126.922 211.169 126.981 211.193 127.049C211.227 127.115 211.243 127.182 211.239 127.253C211.209 127.444 211.213 127.618 211.249 127.775C211.292 127.936 211.365 128.077 211.466 128.199C211.575 128.325 211.71 128.435 211.873 128.529C212.064 128.639 212.264 128.7 212.474 128.713C212.684 128.725 212.882 128.681 213.067 128.58C213.263 128.476 213.426 128.311 213.555 128.087C213.673 127.883 213.727 127.679 213.718 127.475C213.716 127.275 213.659 127.083 213.547 126.901C213.435 126.719 213.277 126.568 213.073 126.451C212.91 126.357 212.766 126.301 212.641 126.283C212.519 126.258 212.409 126.253 212.31 126.268C212.21 126.283 212.122 126.295 212.044 126.305C211.97 126.307 211.899 126.289 211.831 126.25L210.496 125.479C210.435 125.444 210.393 125.392 210.369 125.324C210.355 125.253 210.366 125.187 210.402 125.126L212.821 121.782C212.894 121.688 212.974 121.635 213.063 121.622C213.155 121.603 213.246 121.619 213.334 121.67L217.419 124.029C217.494 124.072 217.541 124.131 217.562 124.206C217.582 124.281 217.57 124.356 217.527 124.431L216.974 125.388C216.931 125.463 216.872 125.511 216.797 125.531C216.722 125.551 216.647 125.539 216.572 125.496L213.618 123.79L212.721 125.038C212.929 125.023 213.149 125.041 213.381 125.094C213.624 125.143 213.919 125.268 214.266 125.468C214.592 125.657 214.869 125.889 215.098 126.167C215.335 126.448 215.509 126.757 215.623 127.094C215.743 127.435 215.785 127.785 215.749 128.145C215.724 128.502 215.61 128.857 215.406 129.21C215.128 129.693 214.762 130.052 214.308 130.288C213.862 130.528 213.355 130.63 212.788 130.592C212.225 130.548 211.634 130.347 211.016 129.99Z" fill="white"/>
            <path d="M217.71 133.842C217.113 133.497 216.649 133.084 216.319 132.604C215.99 132.124 215.806 131.619 215.767 131.09C215.74 130.558 215.869 130.043 216.155 129.548C216.277 129.337 216.427 129.148 216.606 128.979C216.795 128.808 216.99 128.658 217.191 128.529C217.396 128.394 217.587 128.283 217.765 128.195C217.946 128.1 218.093 128.027 218.205 127.974L221.392 126.594C221.446 126.58 221.513 126.565 221.595 126.549C221.681 126.526 221.775 126.543 221.876 126.602L223.231 127.385C223.293 127.42 223.33 127.473 223.343 127.544C223.36 127.608 223.351 127.671 223.316 127.732C223.3 127.759 223.281 127.785 223.259 127.808C223.24 127.824 223.22 127.835 223.199 127.841L220.741 128.922C220.79 128.932 220.841 128.952 220.895 128.984C220.96 129.012 221.017 129.04 221.064 129.068C221.362 129.258 221.625 129.5 221.853 129.795C222.082 130.09 222.257 130.413 222.38 130.765C222.503 131.116 222.55 131.483 222.52 131.864C222.501 132.242 222.385 132.615 222.173 132.982C221.903 133.45 221.532 133.811 221.06 134.064C220.592 134.31 220.065 134.422 219.479 134.401C218.904 134.377 218.315 134.191 217.71 133.842ZM218.547 132.369C218.744 132.482 218.951 132.548 219.168 132.564C219.389 132.574 219.595 132.53 219.787 132.433C219.99 132.332 220.16 132.163 220.297 131.926C220.434 131.688 220.497 131.461 220.486 131.246C220.478 131.025 220.413 130.824 220.29 130.644C220.167 130.465 220.004 130.316 219.8 130.199C219.596 130.081 219.383 130.012 219.159 129.992C218.942 129.975 218.736 130.019 218.54 130.123C218.348 130.22 218.183 130.388 218.046 130.626C217.908 130.863 217.844 131.093 217.851 131.315C217.87 131.534 217.939 131.737 218.057 131.923C218.18 132.102 218.343 132.251 218.547 132.369Z" fill="white"/>
            <path d="M206.429 121.276C206.822 121.11 207.154 120.82 207.37 120.445C207.714 119.849 207.707 119.093 207.352 118.469C207.261 118.309 207.129 118.175 206.969 118.083L203.788 116.247L204.808 114.662C205.055 114.279 205.131 113.824 205.025 113.383C204.974 113.166 204.878 112.961 204.744 112.783C204.61 112.604 204.441 112.454 204.248 112.343C203.558 111.945 202.68 112.057 202.113 112.616L198.591 116.084L198.587 116.081L195.309 121.759L201.575 125.376C201.697 125.447 201.83 125.492 201.968 125.508C202.755 125.603 203.518 125.221 203.913 124.537C204.01 124.369 204.081 124.191 204.125 124.004C204.518 123.839 204.849 123.549 205.066 123.174C205.163 123.006 205.234 122.828 205.278 122.641C205.671 122.476 206.003 122.186 206.219 121.811C206.313 121.642 206.385 121.462 206.429 121.276ZM196.207 115.273L193.419 120.101C193.283 120.336 193.363 120.635 193.598 120.771L194.46 121.269L197.738 115.591L196.876 115.093C196.641 114.958 196.342 115.038 196.207 115.273Z" fill="#FCFCFC"/>
            <path d="M147.31 19.5571L140.909 17.9363L138.615 11.7063C138.553 11.5357 138.436 11.3908 138.283 11.2943C137.899 11.0516 137.39 11.1685 137.146 11.5549L133.629 17.1861L127.032 17.4673C126.85 17.4747 126.678 17.5428 126.539 17.6588C126.371 17.8001 126.266 18.0026 126.246 18.2218C126.227 18.441 126.294 18.659 126.434 18.8279L130.661 23.9286L128.878 30.3324C128.836 30.4813 128.837 30.639 128.88 30.7875C128.923 30.936 129.006 31.0694 129.12 31.1725C129.234 31.2757 129.375 31.3445 129.527 31.3712C129.678 31.3978 129.834 31.3813 129.976 31.3233L136.107 28.845L141.603 32.5215C141.752 32.6229 141.934 32.6702 142.114 32.6575C142.567 32.6261 142.91 32.2317 142.88 31.7753L142.441 25.1426L147.619 21.011C147.76 20.8979 147.861 20.7417 147.905 20.5639C148.021 20.12 147.751 19.6703 147.31 19.5571Z" fill="#FFBB38"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M15.8567 44.8012C13.1682 46.7379 12.5771 50.4739 14.5365 53.1458L34.5796 80.4773C36.539 83.1492 40.3069 83.7452 42.9954 81.8085L64.4596 66.3464L74.7006 70.9589L73.4298 59.8847L95.0117 44.3378C97.7003 42.4011 98.2914 38.6651 96.332 35.9932L76.2889 8.66164C74.3295 5.98977 70.5616 5.39381 67.8731 7.33053L15.8567 44.8012Z" fill="#E84672"/>
            <path d="M47.0199 40.0394C43.2968 39.4619 39.7954 42.0129 39.1999 45.7368L39.1788 45.7335C35.4516 45.159 31.9502 47.71 31.3588 51.4309C30.7633 55.1547 33.3 58.6365 37.0242 59.2069L37.0453 59.2102L50.5095 61.2856L50.5307 61.2889L52.6853 47.8155C53.2807 44.0916 50.744 40.6099 47.0199 40.0394Z" fill="#FCFCFC"/>
            <path d="M62.5524 46.3907C62.4516 46.4641 62.3413 46.4955 62.2212 46.4848C62.1104 46.4673 62.0183 46.4083 61.9449 46.3075L61.2341 45.3319C61.0338 45.0571 60.9848 44.7492 61.087 44.4083L61.495 41.9655C61.8614 40.7169 62.1298 39.7572 62.3 39.0862C62.4703 38.4153 62.5586 37.8531 62.5649 37.3998C62.5712 36.9465 62.4775 36.587 62.284 36.3213C61.7567 35.5976 61.1358 35.4961 60.4213 36.0167C60.0457 36.2903 59.8406 36.6151 59.8059 36.9909C59.7645 37.3576 59.8352 37.7338 60.0181 38.1194C60.1166 38.3702 60.0376 38.589 59.7811 38.7759L58.0635 40.0273C57.981 40.0874 57.8923 40.1099 57.7973 40.095C57.7023 40.0801 57.6247 40.0314 57.5647 39.949C57.1558 39.3493 56.9257 38.6966 56.8743 37.9909C56.8254 37.2693 56.9768 36.5629 57.3288 35.8718C57.6832 35.1649 58.2268 34.5444 58.9596 34.0105C59.7292 33.4499 60.4788 33.093 61.2087 32.9399C61.9385 32.7867 62.609 32.8312 63.22 33.0731C63.831 33.3151 64.3534 33.7338 64.7872 34.3293C65.2544 34.9705 65.48 35.7036 65.4641 36.5286C65.4506 37.3377 65.2598 38.3462 64.8916 39.554L64.5585 41.7529L68.0899 39.18C68.1907 39.1066 68.2965 39.0786 68.4074 39.096C68.5274 39.1067 68.6241 39.1625 68.6975 39.2632L69.7086 40.6511C69.782 40.7518 69.8055 40.861 69.7789 40.9785C69.7615 41.0893 69.7024 41.1815 69.6016 41.2549L62.5524 46.3907Z" fill="white"/>
            <path d="M75.8471 36.7046C75.7555 36.7714 75.6497 36.7994 75.5297 36.7886C75.4122 36.7621 75.32 36.703 75.2533 36.6114L74.122 35.0587L69.8485 38.1722C69.7478 38.2456 69.642 38.2736 69.5311 38.2562C69.4203 38.2388 69.3281 38.1797 69.2547 38.079L68.3037 36.7736C68.2035 36.6362 68.1505 36.4575 68.1445 36.2375L68.1987 27.9102C68.2135 27.6611 68.3263 27.4597 68.537 27.3062L70.5432 25.8446C70.6439 25.7711 70.7498 25.7431 70.8606 25.7605C70.9714 25.7779 71.0636 25.837 71.137 25.9378L75.0213 31.2693L76.2306 30.3883C76.3405 30.3082 76.4542 30.2814 76.5717 30.308C76.6826 30.3254 76.7714 30.3799 76.8381 30.4715L77.8292 31.8319C77.9026 31.9326 77.9306 32.0384 77.9132 32.1493C77.8958 32.2601 77.8367 32.3523 77.736 32.4257L76.513 33.3167L77.6443 34.8694C77.711 34.961 77.739 35.0668 77.7283 35.1868C77.7109 35.2977 77.6518 35.3898 77.551 35.4632L75.8471 36.7046ZM72.7554 33.0674L70.3928 29.8245L70.4469 34.7493L72.7554 33.0674Z" fill="white"/>
            <path d="M141.906 156.016C141.906 156.016 130.686 200.09 126.249 217.51H81.0723C86.144 199.653 98.6021 156.016 98.6021 156.016H141.906Z" fill="#6B2D66"/>
            <path d="M135.041 157.887C133.974 162.906 132.846 167.905 131.649 172.894C130.468 177.882 129.23 182.855 127.982 187.828C126.713 192.796 125.433 197.758 124.091 202.705C122.765 207.657 121.371 212.593 119.914 217.509C120.98 212.489 122.109 207.49 123.3 202.502C124.481 197.513 125.719 192.541 126.973 187.568C128.242 182.6 129.527 177.638 130.864 172.691C132.195 167.744 133.584 162.808 135.041 157.887Z" fill="#270833"/>
            <path d="M161.99 200.094C161.99 200.094 162.999 210.216 163.696 217.514H120.6C120.335 211.225 119.986 203.225 119.97 203.22C119.965 203.215 119.95 203.21 119.924 203.194C119.736 203.095 119.024 202.741 118.035 202.247C116.22 201.342 113.473 199.979 111.237 198.871C111.138 198.825 111.049 198.773 110.956 198.731C109 197.763 107.507 197.02 107.502 197.014C107.491 196.988 107.429 196.697 107.335 196.172C107.091 194.913 106.607 192.322 105.993 189.05C105.234 184.998 104.287 179.885 103.372 174.912C101.65 165.596 100.043 156.748 100.006 156.176C100.006 156.098 100.001 156.051 100.001 156.025C100.001 156.009 100.001 156.004 100.001 156.004L158.302 156.025C158.302 156.02 168.242 181.029 161.99 200.094Z" fill="#6B2D66"/>
            <path d="M125.525 205.541C123.538 204.163 121.39 203.003 119.283 201.812C117.13 200.594 114.945 199.429 112.703 198.384C108.323 196.345 103.704 194.873 99.1006 193.442C98.9809 193.406 98.9029 193.598 99.0226 193.645C103.46 195.549 107.756 197.733 112.173 199.695C114.394 200.678 116.63 201.624 118.852 202.602C121.073 203.58 123.242 204.688 125.479 205.625C125.536 205.656 125.577 205.578 125.525 205.541Z" fill="#270833"/>
            <path d="M113.39 183.826C113.338 181.652 113.182 179.478 113.073 177.303C112.818 172.174 112.62 167.014 112.142 161.896C112.121 161.693 111.824 161.698 111.824 161.901C111.762 165.87 111.913 169.849 111.996 173.818C112.038 175.805 112.095 177.792 112.147 179.774C112.199 181.662 112.324 183.576 112.183 185.465C112.079 186.895 111.762 189.678 109.895 189.85C108.136 190.011 107.174 188.112 106.732 186.724C105.619 183.192 105.218 179.373 104.859 175.701C104.646 173.527 103.965 164.668 104.084 162.468C104.089 162.327 103.871 162.333 103.866 162.473C103.746 164.715 103.621 173.73 103.767 175.961C103.907 178.104 104.084 180.258 104.407 182.38C104.698 184.289 105.021 186.271 105.785 188.055C106.43 189.558 107.205 190.895 108.896 191.223C109.109 191.265 109.317 191.285 109.525 191.285C109.546 191.748 109.608 192.211 109.64 192.664C109.692 193.382 109.78 194.1 109.874 194.812C109.967 195.509 110.129 196.211 110.316 196.893C110.498 197.569 110.68 198.277 111.044 198.88C111.107 198.979 111.242 198.922 111.247 198.818C111.299 198.136 111.169 197.444 111.075 196.768C110.982 196.071 110.883 195.364 110.737 194.672C110.597 193.99 110.472 193.309 110.332 192.633C110.238 192.175 110.175 191.707 110.087 191.244C111.169 191.072 112.069 190.36 112.579 189.283C113.369 187.618 113.432 185.642 113.39 183.826Z" fill="#270833"/>
            <path d="M152.793 178.933C150.426 179.016 148.085 179.261 145.718 178.834C143.487 178.434 141.359 177.471 139.596 175.817C135.736 172.202 134.098 166.496 133.109 161.164C133.089 161.044 132.932 161.06 132.943 161.185C133.328 165.788 134.134 170.563 136.688 174.366C137.801 176.025 139.175 177.445 140.787 178.439C142.462 179.474 144.381 179.885 146.254 179.963C148.449 180.056 150.702 179.781 152.829 179.245C152.996 179.198 152.97 178.928 152.793 178.933Z" fill="#6B2D66"/>
            <path d="M121.114 217.512H120.339C120.209 215.41 120.105 213.309 120.037 211.207C119.996 209.813 119.985 208.414 119.965 207.025C119.949 205.746 119.892 204.466 119.918 203.192V203.082C119.918 202.994 119.918 202.916 119.923 202.833C119.923 202.739 120.053 202.739 120.063 202.827C120.079 202.942 120.089 203.056 120.1 203.171C120.272 204.638 120.36 206.125 120.49 207.597C120.734 210.406 120.896 213.215 121.041 216.035C121.073 216.529 121.093 217.018 121.114 217.512Z" fill="#270833"/>
            <path d="M154.738 160.352C155.227 161.933 155.638 163.535 156.039 165.142C156.444 166.75 156.809 168.362 157.141 169.985C157.813 173.231 158.353 176.503 158.744 179.801C159.134 183.099 159.347 186.423 159.311 189.757C159.233 193.081 158.946 196.457 157.891 199.682L157.911 199.5C158.14 202.501 158.359 205.497 158.546 208.504L158.811 213.009C158.894 214.512 158.978 216.015 159.04 217.519C158.837 216.026 158.655 214.533 158.478 213.04L157.953 208.556C157.615 205.565 157.303 202.574 157.006 199.583L156.996 199.495L157.027 199.401C157.511 197.861 157.781 196.249 157.979 194.636C158.171 193.018 158.265 191.385 158.281 189.747C158.296 188.108 158.265 186.469 158.182 184.826C158.088 183.187 157.958 181.549 157.807 179.91C157.48 176.633 157.043 173.366 156.522 170.11C156.257 168.482 155.981 166.854 155.695 165.226C155.388 163.592 155.071 161.975 154.738 160.352Z" fill="#270833"/>
            <path d="M157.172 112.159C152.356 119.327 143.175 122.718 134.992 123.967C126.649 125.236 117.145 125.257 109.218 122.011C105.743 120.596 102.471 118.375 100.26 115.311C97.8779 112.024 97.3161 108.398 99.2771 104.954C100.858 102.177 104.64 99.966 104.442 97.5628C104.234 95.1127 102.133 93.4326 101.394 91.1698C100.135 87.305 102.003 84.1683 104.526 81.375C110.482 74.7376 106.034 64.7503 109.368 56.854C111.267 52.3597 118.997 46.0136 130.763 48.6457C135.237 49.6444 138.561 53.3897 140.693 57.3794C143.195 62.0714 142.254 68.6047 145.208 73.0678C147.731 76.8703 150.982 77.2344 153.422 81.2449C154.478 82.9823 155.097 85.063 153.542 89.5157C152.98 91.1282 152.444 93.5522 153.24 95.3416C154.343 97.8176 157.448 98.7332 158.66 101.318C160.33 104.856 159.295 108.996 157.172 112.159Z" fill="#270833"/>
            <path d="M102.897 116.093C98.9127 115.781 96.7591 110.449 99.0115 107.442C100.442 105.539 102.741 104.514 104.177 102.615C106.533 99.489 103.99 97.065 102.341 94.3341C98.7722 88.4094 102.58 83.8891 106.658 79.6965C109.753 76.513 110.148 73.1319 110.008 68.8457C110.008 68.7832 109.893 68.778 109.893 68.8457C109.847 72.0135 109.675 75.145 107.704 77.7874C106.101 79.9357 103.859 81.5015 102.32 83.7226C99.7397 87.4418 99.7085 91.2495 102.065 95.0572C102.741 96.1495 103.958 97.3511 104.296 98.5787C105.098 101.471 103.032 103.172 101.077 104.795C99.5733 106.038 97.9087 107.416 97.4718 109.43C96.8112 112.473 99.7865 116.28 102.861 116.348C103.022 116.343 103.058 116.109 102.897 116.093Z" fill="#270833"/>
            <path d="M105.29 118.92C102.757 117.645 100.026 116.527 98.393 114.067C95.9742 110.425 98.3566 107.336 100.588 104.428C101.545 103.179 102.325 101.947 102.138 100.318C101.857 97.8736 99.9171 95.9646 99.4229 93.5614C98.2473 87.8239 103.626 83.8498 106.133 79.3659C108.692 74.7832 109.223 69.623 109.873 64.5149C109.889 64.4057 109.717 64.3849 109.707 64.4941C108.838 71.0223 107.454 77.1968 103.361 82.5129C100.51 86.2166 97.4775 90.2271 99.3397 95.0959C99.7974 96.2923 100.604 97.3483 101.035 98.5446C101.889 100.922 101.368 102.55 99.9171 104.423C98.5178 106.228 96.8897 108.074 96.796 110.477C96.614 115.133 101.415 117.817 105.129 119.294C105.379 119.393 105.525 119.034 105.29 118.92Z" fill="#270833"/>
            <path d="M159.597 99.7793C157.433 97.1264 154.223 95.9612 154.743 91.9039C155.102 89.1054 156.429 86.7906 155.664 83.9088C155.035 81.5212 153.36 79.7631 151.435 78.3066C148.985 76.4548 146.327 75.0087 144.46 72.4962C142.421 69.7549 141.354 66.4414 140.272 63.2528C140.251 63.1904 140.142 63.2216 140.158 63.284C141.37 67.643 142.785 72.1009 146.337 75.1647C148.194 76.7617 150.39 77.9164 152.231 79.5394C155.539 82.4524 155.753 85.8959 154.697 89.9116C154.213 91.753 153.797 93.5997 154.743 95.3734C155.685 97.142 157.495 98.1616 158.816 99.6077C164.408 105.756 157.095 114.578 150.66 116.227C150.374 116.3 150.436 116.753 150.738 116.685C155.565 115.598 160.413 110.776 161.157 105.86C161.48 103.712 160.985 101.491 159.597 99.7793Z" fill="#270833"/>
            <path d="M159.617 103.273C159.367 100.36 157.453 98.867 155.903 96.6719C153.464 93.2231 155.497 89.5195 155.513 85.7014C155.523 83.0433 154.306 80.869 152.553 79.0484C150.192 76.5932 147.273 74.9286 145.281 72.0677C143.18 69.0455 142.056 65.4043 141.968 61.659C141.968 61.5862 141.864 61.607 141.864 61.6746C141.832 66.9024 143.752 71.688 147.456 75.1679C149.807 77.3786 152.886 79.1056 154.327 82.1954C155.851 85.4725 154.369 88.656 154.14 92.0319C153.994 94.2218 154.592 95.9332 155.924 97.577C156.891 98.7681 158.046 99.8345 158.639 101.312C159.44 103.309 159.154 105.614 158.452 107.606C156.241 113.916 150.088 118.197 144.054 119.601C143.84 119.648 143.882 120.002 144.095 119.955C149.474 118.764 154.452 115.466 157.505 110.592C158.847 108.454 159.836 105.853 159.617 103.273Z" fill="#270833"/>
            <path d="M113.546 99.0025C91.2459 118.036 70.491 138.973 68.6132 149.901C66.0175 165.028 75.2402 169.491 79.4224 169.642C90.8089 170.063 112.76 124.449 120.438 107.013C127.892 90.0867 118.441 94.8203 113.546 99.0025Z" fill="#F7A9A0"/>
            <path d="M108.974 135.851L94.3984 113.718C94.3984 113.718 108.828 100.464 117.759 95.2415C122.763 92.3129 124.308 95.3299 123.169 100.438C119.185 118.327 108.974 135.851 108.974 135.851Z" fill="#E84672"/>
            <path d="M99.9168 108.829C99.8856 109.536 99.2978 110.103 98.5747 110.103C98.5331 110.103 98.4915 110.103 98.4551 110.098C98.9128 109.698 99.4018 109.276 99.9168 108.829ZM116.115 107.414C115.371 107.414 114.773 108.017 114.773 108.756C114.773 109.5 115.376 110.098 116.115 110.098C116.854 110.098 117.457 109.495 117.457 108.756C117.462 108.017 116.859 107.414 116.115 107.414ZM107.345 121.542C106.601 121.542 106.003 122.145 106.003 122.884C106.003 123.628 106.606 124.226 107.345 124.226C108.083 124.226 108.687 123.623 108.687 122.884C108.692 122.14 108.089 121.542 107.345 121.542Z" fill="white"/>
            <path d="M98.3933 112.185C100.916 115.405 107.512 124.337 111.039 130.698C111.059 130.735 110.924 130.834 110.898 130.797C107.163 125.944 100.427 115.754 98.1904 112.326C98.1488 112.269 98.3413 112.128 98.3933 112.185Z" fill="#270833"/>
            <path opacity="0.1" d="M123.164 100.437C119.185 118.326 108.974 135.851 108.974 135.851L95.876 115.954L121.104 94.0859C123.404 94.1171 123.991 96.7232 123.164 100.437Z" fill="black"/>
            <path d="M97.9668 157.883L102.602 138.574C102.602 138.574 95.8081 129.902 99.6626 121.585C101.608 117.371 112.729 99.4931 119.913 94.281C126.649 89.3914 134.571 92.3668 137.708 96.6218C146.733 108.851 154.302 117.959 159.337 157.877H97.9668V157.883Z" fill="#E84672"/>
            <path d="M142.244 97.537C163.233 112.934 185.345 139.364 186.011 154.891C186.666 170.226 178.016 168.884 176.554 168.463C161.11 164.025 141.692 121.881 134.472 104.7C127.31 87.6433 137.052 93.7293 142.244 97.537Z" fill="#F7A9A0"/>
            <path d="M167.285 118.783L141.625 125.42C141.625 125.42 133.744 107.48 131.689 97.3415C130.535 91.6612 133.89 91.1723 138.358 93.9032C154.005 103.454 167.285 118.783 167.285 118.783Z" fill="#E84672"/>
            <path d="M141.478 121.15C145.343 119.803 155.924 116.448 163.086 115.168C163.128 115.163 163.18 115.319 163.138 115.335C157.338 117.301 145.515 120.38 141.551 121.389C141.473 121.405 141.406 121.176 141.478 121.15Z" fill="#270833"/>
            <path d="M144.756 124.61C143.69 124.793 142.634 125.001 141.578 125.229L141.812 125.344C140.824 122.566 139.752 119.825 138.623 117.11C137.484 114.394 136.298 111.705 134.972 109.062C135.866 111.871 136.896 114.634 137.978 117.37C139.065 120.106 140.21 122.821 141.443 125.495L141.521 125.661L141.677 125.614C142.707 125.297 143.737 124.969 144.756 124.61Z" fill="#270833"/>
            <path d="M137.114 114.547C136.849 113.964 136.485 113.46 136.105 112.971C135.71 112.487 135.304 112.035 134.82 111.598C134.893 111.91 135.044 112.196 135.195 112.466C135.346 112.742 135.533 112.997 135.725 113.241C136.121 113.73 136.558 114.188 137.114 114.547Z" fill="#270833"/>
            <path d="M133.161 94.0952C133.005 95.2708 129.447 102.48 123.034 102.163C119.304 101.976 120.578 95.406 120.578 95.406L117.244 85.2783L129.427 75.7539C129.427 75.7539 133.224 93.5958 133.161 94.0952Z" fill="#F7A9A0"/>
            <path d="M130.815 82.4225C128.626 89.887 123.091 93.4866 120.396 94.839L117.244 85.2783L129.427 75.7539C129.427 75.7539 130.056 78.7657 130.815 82.4225Z" fill="#F59A99"/>
            <path d="M102.701 72.1463C103.497 89.5877 114.036 91.0806 117.796 90.9038C121.209 90.7425 132.814 89.7646 133.823 72.3336C134.832 54.9026 126.468 49.7581 118.639 49.7113C110.816 49.6644 101.905 54.7049 102.701 72.1463Z" fill="#F7A9A0"/>
            <path opacity="0.5" d="M107.877 76.5944C107.882 76.7921 107.861 77.073 107.997 77.2082C108.132 77.3434 108.444 77.4579 108.569 77.5775C108.605 77.6139 108.912 77.7024 108.954 78.1705C108.985 78.5346 108.72 79.138 109.006 79.138C109.307 79.138 109.151 78.6023 109.162 78.441C109.177 78.1809 109.089 77.8012 109.229 77.6607C109.37 77.5203 109.703 77.4267 109.739 77.2134C109.776 76.9949 109.724 76.8857 109.833 76.7556C109.947 76.6256 110.036 76.532 110.02 76.3603C110.004 76.1887 109.88 76.0742 109.88 76.0742C109.88 76.0742 109.526 77.0469 109.027 77.0469C108.527 77.0469 107.877 76.5944 107.877 76.5944Z" fill="white"/>
            <path d="M105.817 71.2443C106.113 70.9582 106.431 70.7397 106.748 70.4848C107.107 70.1987 107.367 69.9335 107.846 69.8918C108.106 69.871 108.392 70.1207 108.433 70.3704C108.537 70.9478 108.178 71.4107 107.762 71.7749C107.305 72.1702 106.852 72.3575 106.249 72.3731C105.666 72.3887 105.427 71.624 105.817 71.2443Z" fill="#270833"/>
            <path d="M118.8 69.8634C118.41 69.7333 118.025 69.6813 117.624 69.5929C117.172 69.494 116.823 69.3692 116.376 69.546C116.137 69.6397 115.991 69.9934 116.059 70.2327C116.22 70.7945 116.745 71.0545 117.281 71.195C117.864 71.3458 118.353 71.3146 118.904 71.0649C119.44 70.8257 119.315 70.035 118.8 69.8634Z" fill="#270833"/>
            <path d="M119.346 83.0712C119.06 83.3469 118.769 83.7266 118.337 83.7526C117.926 83.7838 117.442 83.5914 117.068 83.4353C117.032 83.4197 116.99 83.4613 117.016 83.4977C117.318 83.9555 117.963 84.252 118.514 84.1635C119.05 84.0751 119.326 83.6174 119.461 83.1284C119.476 83.0608 119.388 83.0348 119.346 83.0712Z" fill="#270833"/>
            <path d="M117.677 81.3866C115.586 81.2409 114.697 81.7247 113.521 82.0524C113.386 82.0524 113.068 82.0004 113.027 81.8755V81.8651C113.022 81.8235 112.741 79.3943 112.741 79.3943C112.439 79.6024 110.899 80.5907 110.858 80.1226C110.525 76.2577 110.15 71.9558 110.629 68.0389C110.639 67.9089 110.837 67.9089 110.842 68.0389C111.31 71.7426 111.128 75.5034 111.539 79.2331C112.038 78.9574 112.84 78.458 113.308 78.4008C113.438 78.4164 113.479 81.0329 113.578 81.423C113.578 81.423 115.212 80.4399 117.792 81.1525C118.364 81.3086 118.437 81.4438 117.677 81.3866Z" fill="#270833"/>
            <path d="M110.217 75.8599C110.217 76.1512 110.144 76.4633 109.973 76.7546C109.796 77.0459 109.458 77.2436 109.125 77.3008C108.781 77.3632 108.386 77.2124 108.157 76.9731C107.928 76.7338 107.783 76.4633 107.694 76.1824C107.637 76.0056 107.601 75.8235 107.575 75.6466C107.517 75.2617 107.528 74.8768 107.647 74.5127C107.674 74.7936 107.731 75.0692 107.814 75.3241C107.892 75.5686 107.98 75.7975 108.09 76.0212C108.319 76.4789 108.662 76.8378 109.042 76.7546C109.447 76.7026 109.697 76.3281 109.78 75.8183C109.869 75.3189 109.863 74.7779 109.848 74.2266C110.087 74.7259 110.228 75.2773 110.217 75.8599Z" fill="#270833"/>
            <path opacity="0.5" d="M117.417 75.1565C117.359 75.3697 117.526 75.713 117.775 75.7443C118.025 75.7807 118.098 75.7651 118.28 75.9471C118.462 76.1292 118.561 76.28 118.743 76.3112C118.925 76.3425 118.972 76.5973 119.05 76.9198C119.123 77.2424 119.216 80.1553 119.123 80.3946C119.029 80.6339 119.034 81.0916 119.378 81.0916C119.721 81.0916 119.461 80.0149 119.414 79.7756C119.367 79.5363 119.279 76.4309 119.336 76.1344C119.388 75.8379 119.508 75.7286 119.528 75.5102C119.549 75.2917 119.409 75.2085 119.528 75.0524C119.648 74.8964 119.825 74.9016 119.763 74.6311C119.7 74.3606 119.456 74.293 119.456 74.293C119.456 74.293 119.17 75.068 118.816 75.1877C118.462 75.3073 117.417 75.1565 117.417 75.1565Z" fill="white"/>
            <path d="M117.073 74.7172C117.188 74.9825 117.375 75.2426 117.651 75.4402C117.926 75.6379 118.311 75.6847 118.644 75.6119C118.988 75.5339 119.289 75.2426 119.404 74.9305C119.518 74.6236 119.549 74.3115 119.518 74.0202C119.503 73.8329 119.461 73.656 119.419 73.4792C119.32 73.1047 119.159 72.7509 118.904 72.4648C118.993 72.7353 119.045 73.0058 119.071 73.2763C119.097 73.5312 119.102 73.7809 119.092 74.0254C119.06 74.5351 118.889 74.9981 118.504 75.0709C118.108 75.1801 117.734 74.9357 117.458 74.5039C117.183 74.0826 116.975 73.578 116.772 73.0682C116.74 73.6196 116.829 74.1866 117.073 74.7172Z" fill="#270833"/>
            <path d="M133.303 73.0338C129.625 73.58 128.117 65.2052 128.117 65.2052C128.117 65.2052 121.729 65.044 118.488 61.2415C118.488 61.2415 120.787 64.6122 123.461 65.7254C123.908 65.9127 111.107 66.6565 108.371 57.1946C108.371 57.1946 108.043 67.0154 102.607 69.892C102.347 70.0272 101.224 61.0178 103.486 58.0529C105.177 55.8369 106.259 54.4533 106.259 54.4533C106.259 54.4533 111.149 48.7002 119.066 48.5909C126.983 48.4817 133.646 56.7316 134.77 59.9723C136.975 66.3548 135.05 72.779 133.303 73.0338Z" fill="#270833"/>
            <path d="M123.092 65.9965C116.174 66.7976 110.005 63.2864 108.309 56.3577C108.267 56.186 107.997 56.2329 108.002 56.4045C108.21 63.6713 116.746 67.6611 123.175 66.1057C123.326 66.0745 123.253 65.9809 123.092 65.9965Z" fill="#270833"/>
            <path d="M133.225 72.6497C129.193 71.3337 128.007 67.6873 127.118 63.9368C127.092 63.8276 126.899 63.8536 126.915 63.968C127.451 67.7029 128.86 72.1556 133.136 72.9826C133.36 73.0242 133.438 72.7173 133.225 72.6497Z" fill="#270833"/>
            <path d="M108.08 55.757C108.069 55.7153 108.007 55.7257 108.007 55.7674C107.799 59.8351 108.694 66.5037 103.814 68.3035C103.684 68.3503 103.742 68.5428 103.877 68.5064C108.709 67.1956 109.261 59.7363 108.08 55.757Z" fill="#270833"/>
            <path d="M129.906 72.6238C129.906 72.6238 132.964 66.7199 135.597 67.7394C138.229 68.759 136.429 76.9673 133.693 78.4238C130.957 79.8803 129.562 77.373 129.562 77.373L129.906 72.6238Z" fill="#F7A9A0"/>
            <path d="M135.025 70.0793C135.072 70.0689 135.087 70.1313 135.051 70.1521C133.142 71.2393 132.33 73.2523 131.841 75.2914C132.341 74.4643 133.105 73.9494 134.198 74.5268C134.255 74.558 134.218 74.6464 134.156 74.6412C133.308 74.5736 132.731 74.7973 132.257 75.5307C131.925 76.0509 131.732 76.6803 131.524 77.2577C131.451 77.4501 131.113 77.3981 131.155 77.1796C131.16 77.164 131.16 77.1484 131.165 77.1328C130.775 74.5736 132.294 70.7035 135.025 70.0793Z" fill="#270833"/>
            <path d="M80.4883 169.128C87.6199 168.28 91.9998 155.188 100.453 134.391C101.935 130.739 84.1608 109.132 81.5807 112.461C78.3296 116.653 69.3775 140.155 68.3735 146.579C66.3969 159.245 72.1604 170.117 80.4883 169.128Z" fill="#F7A9A0"/>
            <path d="M91.792 155.441C92.4682 153.667 93.1913 151.909 93.9143 150.156C94.6321 148.398 95.3812 146.655 96.1198 144.907L98.3566 139.679C98.7363 138.811 99.1004 137.937 99.4541 137.058C99.8235 136.184 100.172 135.305 100.453 134.395C100.073 136.262 99.3813 138.046 98.7259 139.825C98.0653 141.604 97.3318 143.362 96.5932 145.11C95.8441 146.853 95.0795 148.595 94.2784 150.312C93.4826 152.034 92.6711 153.75 91.792 155.441Z" fill="#270833"/>
            <path d="M74.6738 128.682C75.2408 121.628 76.6661 111.75 79.0537 109.566C81.5557 107.272 90.6847 99.5523 93.3844 98.4444C96.0841 97.3364 108.095 91.9682 110.42 93.1438C112.282 94.0853 110.415 98.9542 104.703 101.56C99.6837 103.849 97.1453 105.867 95.7148 106.585C94.2531 107.319 90.9292 112.369 92.032 114.461C92.6822 115.693 96.4483 116.968 98.6746 115.803C100.901 114.637 101.213 110.075 103.705 107.792C106.196 105.508 110.821 105.503 111.159 106.736C111.497 107.964 110.004 108.635 107.887 112.999C106.524 115.808 106.795 122.341 103.621 127.22C102.025 129.67 99.3664 136.105 99.3664 136.105L74.6738 128.682Z" fill="#F7A9A0"/>
            <path d="M106.03 105.721C106.826 105.492 107.69 105.196 108.257 104.556C108.496 104.286 108.673 103.953 108.725 103.594C108.751 103.401 108.746 103.199 108.709 103.006C108.678 102.829 108.61 102.564 108.454 102.46C108.407 102.429 108.475 102.371 108.501 102.413C108.558 102.512 108.657 102.59 108.725 102.678C108.782 102.756 108.829 102.845 108.87 102.933C108.954 103.115 108.995 103.303 109 103.5C109.016 103.932 108.865 104.348 108.61 104.691C108.007 105.508 107.013 105.768 106.041 105.789C105.999 105.789 105.994 105.732 106.03 105.721Z" fill="#270833"/>
            <path d="M130.503 100.951L128.085 133.181L95.9691 132.312L95.4541 100.004L110.269 100.404L111.788 104.79L110.877 110.402L112.864 113.695L110.924 117.461L112.24 125.128V118.08L115.111 113.679L113.665 109.887L116.968 104.659L117.619 100.602L130.503 100.951Z" fill="#EBEBEB"/>
            <path d="M79.0479 109.56C81.0713 105.939 86.4031 99.3175 91.9169 96.831C95.6466 95.1509 109.124 100.784 108.885 103.312C108.646 105.84 106.409 105.976 103.069 105.773C101.852 105.7 95.3293 103.77 94.3149 105.175C92.8584 107.193 91.2459 113.893 91.2459 113.893L79.0479 109.56Z" fill="#F7A9A0"/>
            <path d="M108.511 102.351C106.737 99.7608 103.091 98.5332 99.4858 97.6073C99.4286 97.5917 99.4026 97.6801 99.4598 97.6957C100.38 97.935 106.305 100.26 108.474 102.398C108.495 102.424 108.526 102.377 108.511 102.351Z" fill="#270833"/>
            <path d="M79.0479 109.567C81.0713 105.947 86.4031 99.3247 91.9169 96.8383C95.6466 95.1582 106.487 102.737 106.248 105.265C106.008 107.793 102.804 108.178 99.1733 107.143C98.003 106.81 93.9924 104.318 92.9781 105.718C91.5216 107.736 87.9792 112.74 87.9792 112.74L79.0479 109.567Z" fill="#F7A9A0"/>
            <path d="M90.0181 109.646C90.3458 109.074 90.455 108.876 90.7931 108.309C91.126 107.747 91.4694 107.185 91.8439 106.65C92.1664 106.187 92.4785 105.672 92.9467 105.344C93.3992 105.027 93.961 104.923 94.502 104.943C95.74 104.985 96.9 105.708 97.9819 106.249C99.1887 106.852 101.675 107.799 103.517 107.497C104.026 107.414 104.526 107.3 104.957 107.014C105.238 106.832 105.623 106.598 105.795 106.265C106.523 104.855 105.426 103.352 104.333 102.306C102.299 100.361 99.6361 99.0135 97.0664 97.9315C96.437 97.6662 95.7972 97.4165 95.1574 97.1668C94.4291 96.8807 93.6749 96.6103 92.8686 96.6519C91.5786 96.7143 89.5395 98.15 88.9049 98.5817C88.8581 98.6129 88.8165 98.5349 88.8633 98.5037C89.628 97.9627 91.5006 96.2409 93.5865 96.2305C94.3511 96.2253 95.0846 96.5738 95.792 96.8339C96.5358 97.1044 97.2693 97.4009 97.9975 97.713C99.4124 98.3216 100.796 99.0082 102.096 99.8301C103.329 100.61 104.645 101.521 105.514 102.712C106.268 103.742 106.565 105.25 105.951 106.41C105.436 107.388 104.234 107.685 103.683 107.789C103.132 107.893 102.601 107.914 102.039 107.867C101.462 107.82 99.3188 107.331 98.4137 107.029C97.2589 106.644 96.1977 105.942 95.0169 105.656C94.45 105.521 93.8414 105.547 93.342 105.864C92.8738 106.155 92.4993 106.67 92.1508 107.081C91.3549 108.023 90.8296 108.631 90.1949 109.693C90.0545 109.953 89.8776 109.885 90.0181 109.646Z" fill="#270833"/>
            <path d="M77.5762 112.707C78.2992 111.001 79.2823 104.072 80.4839 101.898C81.6855 99.7232 87.3034 92.1131 90.1435 92.2639C93.7067 92.4564 90.3828 99.0522 88.6767 101.788C86.9705 104.524 87.5427 108.972 87.5427 108.972L77.5762 112.707Z" fill="#F7A9A0"/>
            <path d="M91.0122 97.269C90.3984 98.1377 89.8678 99.0948 89.3476 100.021C88.8535 100.9 88.2709 101.774 87.912 102.72C87.5531 103.667 87.423 104.671 87.3866 105.675C87.3658 106.169 87.3814 106.663 87.4074 107.157C87.4334 107.698 87.5166 108.239 87.5322 108.78C87.5322 108.838 87.6207 108.843 87.6207 108.786C87.6051 107.808 87.7247 106.799 87.8339 105.826C87.9536 104.817 88.1929 103.802 88.5518 102.85C89.2748 100.947 90.388 99.2248 91.1266 97.3262C91.1683 97.2534 91.0538 97.2118 91.0122 97.269Z" fill="#270833"/>
            <path d="M87.6257 109.719C87.7193 110.551 87.9638 111.352 88.2863 112.112C88.5932 112.881 88.9678 113.625 89.3735 114.343C89.7792 115.066 90.2214 115.768 90.6999 116.439C91.1629 117.121 91.7143 117.729 92.2605 118.374C93.3528 119.644 94.3672 121.048 95.0174 122.619C95.6936 124.18 96.0265 125.86 96.1149 127.529C95.8236 125.881 95.3919 124.263 94.6689 122.775C93.9458 121.287 92.9627 119.961 91.8703 118.702C91.3241 118.078 90.7676 117.428 90.2942 116.72C89.8156 116.018 89.3891 115.29 89.0094 114.53C88.2863 113.032 87.5997 111.415 87.6257 109.719Z" fill="#270833"/>
            <path d="M179.16 168.101C172.237 168.173 164.424 161.812 144.464 142.971C141.702 140.365 154.858 115.917 157.953 118.554C161.849 121.873 176.247 135.585 177.173 136.719C184.986 146.295 183.857 168.049 179.16 168.101Z" fill="#F7A9A0"/>
            <path d="M166.437 129.11C166.437 129.11 162.302 119.534 158.567 115.175C153.573 109.339 146.853 106.358 143.966 105.276C137.932 103.024 121.791 101.744 120.61 106.332C119.544 110.483 136.111 110.956 136.111 110.956C136.111 110.956 119.299 107.684 118.93 112.923C118.566 118.156 137.828 117.609 137.828 117.609C137.828 117.609 120.48 114.639 120.953 120.096C121.312 124.221 138.79 124.71 138.79 124.71C138.79 124.71 123.861 122.26 124.048 126.515C124.256 131.217 140.356 132.684 140.356 132.684C142.655 143.426 148.143 147.603 152.398 150.105C153.433 150.708 166.437 129.11 166.437 129.11Z" fill="#F7A9A0"/>
            <path d="M141.999 111.712C140.038 111.254 138.056 110.781 136.059 110.5C134.061 110.219 132.059 110.011 130.046 109.917C125.109 109.688 121.983 110.318 122.181 110.302C126.847 109.881 134.004 110.77 136.002 111.051C137.994 111.332 139.997 111.509 141.989 111.79C142.041 111.795 142.046 111.722 141.999 111.712Z" fill="#270833"/>
            <path d="M141.12 118.942C138.592 118.468 136.096 117.906 133.547 117.542C131.008 117.183 128.569 117.064 126.009 116.876C125.812 116.861 125.869 116.975 126.067 116.996C128.615 117.282 130.946 117.745 133.49 118.057C136.023 118.375 138.592 118.593 141.11 118.999C141.157 119.009 141.162 118.947 141.12 118.942Z" fill="#270833"/>
            <path d="M141.479 125.269C137.651 124.349 133.656 124.135 129.733 124.016C129.536 124.011 129.629 124.146 129.827 124.161C133.739 124.505 136.808 124.744 141.448 125.399C141.521 125.415 141.562 125.29 141.479 125.269Z" fill="#270833"/>
            <path d="M171.686 131.32C173.288 132.714 174.807 134.197 176.268 135.742C177.007 136.512 177.694 137.323 178.354 138.161C179.015 138.993 179.618 139.924 180.05 140.907C180.913 142.873 181.522 144.923 181.99 146.998C182.432 149.079 182.76 151.196 182.729 153.324C182.64 152.268 182.49 151.217 182.287 150.182C182.094 149.141 181.871 148.111 181.6 147.092C181.059 145.058 180.456 143.024 179.577 141.12C179.166 140.158 178.619 139.284 177.974 138.447C177.345 137.604 176.648 136.808 175.967 136.007C175.275 135.206 174.567 134.421 173.844 133.645C173.142 132.865 172.409 132.095 171.686 131.32Z" fill="#270833"/>
            <path d="M212.866 59.4623C211.573 71.8177 200.505 80.793 188.149 79.4998C183.156 78.9749 178.719 76.8559 175.282 73.6934L164.027 75.3451L169.661 65.6144C168.297 62.2918 167.721 58.5916 168.118 54.7826C169.411 42.4271 180.48 33.4519 192.835 34.745C205.191 36.0382 214.16 47.1068 212.866 59.4623Z" fill="#6B2D66"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M190.576 70.3817C190.576 70.3817 196.12 67.693 200.755 61.5537C204.34 56.8164 204.148 49.4479 199.033 47.7835C196.25 46.8792 194.153 48.5157 192.743 50.4038L190.531 56.841H192.291L190.518 61.2967H191.958L189.782 66.7318L190.582 62.5066H189.174L190.198 58.2366H188.162L189.648 52.3994C188.404 50.0038 185.906 46.6001 182.125 47.7835C176.998 49.3903 176.818 56.8164 180.403 61.5537C185.038 67.6866 190.576 70.3817 190.576 70.3817Z" fill="#E84672"/>
            <g clip-path="url(#clip0_8617_45832)">
                <circle cx="35.1073" cy="164.666" r="21.0285" transform="rotate(-30 35.1073 164.666)" fill="#FCFCFC"/>
                <path d="M24.593 146.455C14.5514 152.252 11.0985 165.138 16.8961 175.18C22.6936 185.222 35.5798 188.674 45.6215 182.877C55.6631 177.079 59.116 164.193 53.3184 154.151C47.5209 144.11 34.6347 140.657 24.593 146.455ZM42.0961 172.565L43.2369 174.541L39.5947 176.644L38.4591 174.677C33.8137 176.468 30.8908 174.185 29.9256 172.514L33.5679 170.411C33.7383 170.66 34.9088 172.065 38.2615 170.129C40.7747 168.678 41.2887 166.961 40.8523 166.205C40.5117 165.615 39.8009 164.384 36.1587 166.487C29.8212 170.146 26.8975 167.269 25.7199 165.229C24.3657 162.884 24.877 159.441 28.1184 156.766L26.9902 154.812L30.6325 152.709L31.7985 154.729C35.3874 153.653 38.1175 155.58 39.1962 157.449L37.3751 158.5L35.5729 159.584C35.1479 158.907 34.111 157.956 31.953 159.202C29.5874 160.568 28.8533 162.245 29.3622 163.126C29.7554 163.807 30.4136 164.947 34.0558 162.845C40.3933 159.186 43.317 162.063 44.4946 164.102C45.8488 166.448 45.3375 169.89 42.0961 172.565Z" fill="#FFBB38"/>
                <circle cx="35.1073" cy="164.666" r="20.0285" transform="rotate(-30 35.1073 164.666)" stroke="#FCFCFC" stroke-opacity="0.25" stroke-width="2"/>
            </g>
            <defs>
                <clipPath id="clip0_8617_45832">
                    <rect width="50.4683" height="50.4683" fill="white" transform="translate(0.978516 155.211) rotate(-30)"/>
                </clipPath>
            </defs>
        </svg>
    )
}
