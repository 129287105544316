import { combineReducers, configureStore } from '@reduxjs/toolkit'
import appReducer from './slices/app'
import userReducer from './slices/user'
import timelineReducer from './slices/timeline'
import walletReducer from './slices/wallet'
import notificationsReducer from './slices/notifications'
import messagesReducer from './slices/messages'
import conversationsReducer from './slices/conversations'

const combinedReducer = combineReducers({
    app: appReducer,
    user: userReducer,
    timeline: timelineReducer,
    wallet: walletReducer,
    notifications: notificationsReducer,
    messages: messagesReducer,
    conversations: conversationsReducer
})

const rootReducer = (state, action) => {
    if (
        action.type === 'user/logout/fulfilled' ||
        action.type === 'profile/delete/fulfilled'
    ) {
        // check for action type
        state = undefined
    }
    return combinedReducer(state, action)
}

const store = configureStore({
    reducer: rootReducer,
})

export default store
