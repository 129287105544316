import {createUseStyles, useTheme} from 'react-jss'
import React, {useEffect, useRef, useState} from 'react'
import {useForm, FormProvider} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import {POST_MODEL, validationSchema} from './postModel'
import {handleApiError} from '../../../../../utilities/helpers'
import MediaLoader from '../../../../../components/MediaLoader'
import Popover from '../../../../../components/Popover'
import Spinner from '../../../../../components/Spinner'
import TextArea from '../../../../../components/TextArea'
import EmojiInput from '../../../../../components/EmojiInput'
import Banner from '../../../../../components/Banner'
import {InfoIcon, PlusFramedIcon} from '../../../../../theme/icons'
import Media from '../../../../../components/Media'
import Button from '../../../../../components/Button'
import useFileUploader from '../../../../../hooks/useFileUploader'
import MediaThumbnail from './mediaThumbnail/MediaThumbnail'
import colors from '../../../../../theme/colors'
import {
    httpSendMedia,
    httpSetLastMessageSent,
    httpUploadChatMedia
} from '../../../../../http-requests/messages'
import {usePubNub} from "pubnub-react";
import {useDispatch} from "react-redux";
import {setConversationMediaCounter} from "../../../../../store/slices/conversations";

const useStyles = createUseStyles((theme) => ({
    root: {
        ...theme.utils.grid.centered,
        gridTemplateColumns: '1fr',
        color: theme.palette.secondary.main,
    },
    bold: {
        fontWeight: 700,
    },
    body: {
        ...theme.utils.grid.centered,
        gridRowGap: theme.spacing * 2,
        gridTemplateColumns: '1fr',
        padding: theme.spacing * 2,
        paddingBottom: 0,
    },
    formContent: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing * 2,
    },
    action: {
        borderTop: `1px solid ${theme.palette.grey[300]}`,
        padding: theme.spacing * 2,
    },
    textArea: {
        zIndex: theme.zIndex.tooltip,
        '& textarea': {
            padding: [8, 24, 8, 12],
        },
    },
    mediaWrapper: {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing * 2,
        overflow: 'auto',
        '&::-webkit-scrollbar': {
            display: 'visible !important',
        },
    },
    image: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
    },
    uploader: {
        ...theme.utils.flexbox.centered,
        flexDirection: 'column',
        gap: theme.spacing,
        ...theme.typography.label,
        color: theme.palette.primary.background,
        fontWeight: 600,
        cursor: ({loading}) => (loading ? 'wait' : 'pointer'),
        '& input': {
            display: 'none',
        },
    },
    emojiInput: {
        zIndex: 2,
        '& > section': {
            bottom: 'unset !important',
            top: 40,
            right: '0 !important',
        },
    },
    banner: {
        padding: theme.spacing * 2,
        border: `1px solid ${colors.primary[400]}`,
    },
    bannerBody: {
        display: 'grid',
        gridTemplateColumns: ({uploadedMediaError}) =>
            uploadedMediaError ? 'auto auto' : 'auto',
        gap: theme.spacing,
        '& svg': {
            width: 20,
        },
    },
    bannerTextWrapper: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing,
    },
    divider: {
        margin: [0, theme.spacing * 2],
    },
}))

const PPVmodal = ({onClose, user, isExpired}) => {
    const theme = useTheme()
    const videoRefs = useRef([])
    const [isLoadingPost, setIsLoadingPost] = useState(false)
    const [uploadError, setUploadError] = useState('')
    const pubnub = usePubNub()
    const {
        component: FileUploader,
        states: {
            uploaderRef,
            cancelFileUpload,
            media,
            isMediaLoaderCanceled,
            uploadedMediaError,
            progress,
            loading,
        },
        setStates: {setMedia, setLoading, setIsMediaLoaderCanceled},
    } = useFileUploader({
        uploadHandler: httpUploadChatMedia,
        handlerParams: {name: 'channel_name', value: user?.channel},
    })
    const dispatch = useDispatch()

    const formMethods = useForm({
        shouldUnregister: false,
        mode: 'all',
        reValidateMode: 'all',
        nativeValidation: false,
        defaultValues: {},
        resolver: yupResolver(validationSchema),
    })
    const {
        handleSubmit,
        register,
        setValue,
        getValues,
        setError,
        setFocus,
        trigger,
        formState: {errors, isSubmitting, isValid},
    } = formMethods


    const onSubmit = async (formValues) => {
        let dataToSend = {
            message: formValues?.content,
            media: media?.map((mediaItem) => {
                return mediaItem?.id
            })
        }
        let mediaID =  {'media_ids': dataToSend.media}
        try {
            if (!!media.length && !isExpired) {
                setIsLoadingPost(true)
                if (Object.keys(pubnub).length && user?.channel) {
                    console.log(dataToSend?.message)
                    await httpSendMedia({channelID: user?.channelID, media_ids: mediaID})
                    await pubnub.publish({
                        channel: user?.channel,
                        message: dataToSend?.message.length ? dataToSend?.message : ' ',
                        meta: {
                            type: 'media',
                            media: dataToSend.media,
                        },
                    })
                    httpSetLastMessageSent({channelID: user?.channelID})
                    dispatch(setConversationMediaCounter(
                        {
                            userID: user.id,
                            mediaCount: mediaID.media_ids.length
                        }
                    ))
                }
            }
        } catch (error) {
            setUploadError(error)
            handleApiError({
                isReduxError: false,
                error: error,
                callbackOnFieldError: setError,
            })
        } finally {
            setIsLoadingPost(false)
            onClose()
        }
    }

    const cancelUpload = () => {
        if (cancelFileUpload.current) {
            cancelFileUpload.current.abort()
        }
    }

    const handleRemove = (e, arrIndex) => {
        e.preventDefault()
        e.stopPropagation()
        setMedia(media.filter((mediaItem) => mediaItem?.id !== arrIndex))
        uploaderRef.current.value = ''
    }

    const handleVideoPlay = (index) => {
        videoRefs?.current[index].currentTime > 0.1 &&
        !videoRefs?.current[index].paused &&
        !videoRefs?.current[index].ended
            ? videoRefs?.current[index].pause()
            : videoRefs?.current[index].play()
    }

    useEffect(() => {
        setFocus(POST_MODEL.content)
    }, [setFocus])

    const classes = useStyles({uploadedMediaError, loading})
    return (
        <>
            {loading && !isMediaLoaderCanceled && (
                <MediaLoader
                    progress={progress}
                    onClose={() => {
                        setLoading(false)
                        setUploadError('')
                    }}
                    onCancel={() => {
                        cancelUpload()
                        setIsMediaLoaderCanceled(true)
                    }}
                    error={uploadError}
                />
            )}
            <Popover
                onClose={onClose}
                title={'Content'}
                maxWidth={539}
                closeOnOverlay={true}
            >
                {isLoadingPost && <Spinner size={56}/>}
                <div className={classes.root}>
                    <FormProvider {...formMethods}>
                        <form
                            onSubmit={handleSubmit(onSubmit)}
                            className={classes.formContent}
                        >
                            <div className={classes.body}>
                                <TextArea
                                    placeholder={'Compose new chat content'}
                                    setFocus={setFocus}
                                    icon={
                                        <EmojiInput
                                            onSelection={(textWithEmoji) => {
                                                setValue(
                                                    POST_MODEL.content,
                                                    textWithEmoji
                                                )
                                                setFocus(POST_MODEL.content)
                                                trigger(POST_MODEL.content)
                                            }}
                                            getCurrentValue={() =>
                                                getValues(POST_MODEL.content)
                                            }
                                            className={classes.emojiInput}
                                        />
                                    }
                                    className={classes.textArea}
                                    errors={errors[POST_MODEL.content]}
                                    {...register(POST_MODEL.content)}
                                />
                                <Banner
                                    className={classes.banner}
                                    variant={
                                        uploadedMediaError ? 'error' : 'default'
                                    }
                                    text={
                                        <div className={classes.bannerBody}>
                                            {uploadedMediaError && (
                                                <InfoIcon
                                                    stroke={
                                                        theme.palette.error.main
                                                    }
                                                />
                                            )}
                                            <div
                                                className={
                                                    classes.bannerTextWrapper
                                                }
                                            >
                                                {uploadedMediaError && (
                                                    <div
                                                        className={classes.bold}
                                                    >
                                                        The selected media
                                                        exceeds the limits
                                                    </div>
                                                )}
                                                <span>
                                                    Remember, you can upload up
                                                    to{' '}
                                                    <span
                                                        className={classes.bold}
                                                    >
                                                        100MB.
                                                    </span>
                                                    Videos cannot exceed 3
                                                    minutes and 30 seconds and
                                                    you cannot upload photos
                                                    larger than 5MB.
                                                </span>
                                            </div>
                                        </div>
                                    }
                                    icon={
                                        uploadedMediaError ? null : (
                                            <InfoIcon
                                                stroke={
                                                    theme.palette[
                                                        uploadedMediaError
                                                            ? 'error'
                                                            : 'tertiary'
                                                        ].main
                                                }
                                            />
                                        )
                                    }
                                />
                                <div className={classes.mediaWrapper}>
                                    <FileUploader>
                                        <MediaThumbnail>
                                            <div className={classes.uploader}>
                                                <PlusFramedIcon/>
                                                Add photo/video
                                            </div>
                                        </MediaThumbnail>
                                    </FileUploader>
                                    {media?.map((mediaItem, index) => {
                                        return (
                                            <MediaThumbnail
                                                type={mediaItem?.type}
                                                count={index + 1}
                                                length={media.length}
                                                fileSize={`${mediaItem?.fileSize.toFixed(
                                                    2
                                                )}MB`}
                                                fileName={`${mediaItem?.fileName}`}
                                                handleRemove={(e) =>
                                                    handleRemove(
                                                        e,
                                                        mediaItem?.id
                                                    )
                                                }
                                                handlePlay={() =>
                                                    handleVideoPlay(index)
                                                }
                                            >
                                                {mediaItem?.type === 'image' ? (
                                                    <Media
                                                        className={
                                                            classes.image
                                                        }
                                                        image={
                                                            mediaItem?.preview
                                                        }
                                                    />
                                                ) : (
                                                    <video
                                                        ref={(el) =>
                                                            (videoRefs.current[
                                                                index
                                                                ] = el)
                                                        }
                                                        className={
                                                            classes.image
                                                        }
                                                    >
                                                        <source
                                                            src={`${mediaItem?.videoPreview}#t=0.1`}
                                                        />
                                                    </video>
                                                )}
                                            </MediaThumbnail>
                                        )
                                    })}
                                </div>
                            </div>
                            <div className={classes.action}>
                                <Button
                                    width={'100%'}
                                    type={'submit'}
                                    disabled={!isValid || isSubmitting}
                                >
                                    Send
                                </Button>
                            </div>
                        </form>
                    </FormProvider>
                </div>
                {/*<DevTool control={control} />*/}
            </Popover>
        </>
    )
}

export default PPVmodal
