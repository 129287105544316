import { createUseStyles } from 'react-jss'
import colors from '../../../../theme/colors'

const useStyles = createUseStyles((theme) => ({
    root: {
        display: 'grid',
        gridTemplateColumns: '88px 1fr',
        gridColumnGap: theme.spacing * 2,
        alignItems: 'center',

    },
    badge: {
        display: 'grid',
        gridTemplateColumns: 'auto 1fr',
        gap: 8,
        alignItems: 'center',
        marginRight: 24,
        backgroundColor: theme.palette.disabled.light,
        borderRadius: 100,
        width: 32,
        height: 32,
        '& svg': {
            margin: 6,
            fill: colors.greyscale[500],
            '& path': {
                fill: colors.greyscale[500],
            },
        },
        '& span': {
            letterSpacing: 0.5,
            fontSize: 12,
            lineHeight: '24px',
            fontWeight: 700,
            color: colors.greyscale[500],
        },
    },
}))

const PreLabeledField = ({ icon, title, children }) => {
    const classes = useStyles()

    return (
        <div className={classes.root}>
            <span className={classes.badge}>
                {' '}
                {icon} <span> {title}</span>{' '}
            </span>
            {children}
        </div>
    )
}

export default PreLabeledField
