import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit'
import {
    httpDeleteNotification,
    httpFetchNotificationHistory,
    httpMarkAllNotificationsAsRead,
    httpMarkNotificationAsRead,
    httpMarkNotificationAsUnread,
} from '../../http-requests/notifications'
import dayjs from 'dayjs'

const sliceName = 'notifications'

export const fetchNotificationsHistory = createAsyncThunk(
    `${sliceName}/fetchNotificationHistory`,
    async ({ limit, page, seen }) => {
        const { data } = await httpFetchNotificationHistory({
            limit,
            page,
            seen,
        })
        return data
    }
)

export const deleteNotification = createAsyncThunk(
    `${sliceName}/deleteNotification`,
    async (id) => {
        const { data } = await httpDeleteNotification(id)
        return id
    }
)

export const markAllNotificationsAsRead = createAsyncThunk(
    `${sliceName}/markAllNotificationsAsRead`,
    async () => {
        const { data } = await httpMarkAllNotificationsAsRead()
        return data
    }
)

export const markSingleNotificationAsRead = createAsyncThunk(
    `${sliceName}/markNotificationAsRead`,
    async (id) => {
        const { data } = await httpMarkNotificationAsRead(id)
        return data
    }
)

export const markNotificationAsUnread = createAsyncThunk(
    `${sliceName}/markNotificationAsUnread`,
    async (id) => {
        const { data } = await httpMarkNotificationAsUnread(id)
        return data
    }
)

const notifications = createSlice({
    name: sliceName,
    initialState: {
        isSubscribedToChannel: false,
        notifications: [],
        unreadFilterActive: false,
        isNotificationsFetched: false,
        currentPage: 0,
        lastPage: 0,
        total: 0,
        perPage: 12,
        isLoading: false,
    },
    reducers: {
        addNotification: (state, action) => {
            state.notifications = [action.payload, ...state.notifications]
        },
        setIsSubscribed: (state, action) => {
            state.isSubscribedToChannel = action.payload
        },
        setCurrentPage: (state, action) => {
            state.currentPage = action.payload
        },
        setUnreadFilterActive: (state, action) => {
            state.unreadFilterActive = action.payload
        },
        resetNotificationHistory: (state) => {
            state.notifications = []
        },
        setIsLoading: (state, action) => {
            state.isLoading = action.payload
        },
    },
    extraReducers: {
        [fetchNotificationsHistory.fulfilled]: (state, action) => {
            state.notifications = state.notifications.concat(
                action.payload.data
            )
            state.currentPage = action.payload.current_page
            state.lastPage = action.payload.last_page
            state.total = action.payload.total
            state.perPage = action.payload.per_page
            state.isNotificationsFetched = true
        },
        [deleteNotification.fulfilled]: (state, action) => {
            state.notifications = state.notifications.filter(
                (notification) => notification.id !== action.payload
            )
        },
        [markAllNotificationsAsRead.fulfilled]: (state, action) => {
            state.notifications = state.notifications.map((notification) => {
                notification.read = true
            })
        },
        [markSingleNotificationAsRead.fulfilled]: (state, action) => {
            if (state.unreadFilterActive) {
                state.notifications = state.notifications.filter(
                    (notification) => notification.id !== action.payload.id
                )
            } else {
                state.notifications = state.notifications.map(
                    (notification) => {
                        if (notification.id === action.payload.id) {
                            return action.payload
                        }
                        return notification
                    }
                )
            }
        },
        [markNotificationAsUnread.fulfilled]: (state, action) => {
            state.notifications = state.notifications.map((notification) => {
                if (notification.id === action.payload.id) {
                    return action.payload
                }
                return notification
            })
        },
        [markAllNotificationsAsRead.fulfilled]: (state, action) => {
            if (state.unreadFilterActive) {
                state.notifications = []
            } else {
                state.notifications = state.notifications.map(
                    (notification) => {
                        notification.seen_at = dayjs()
                        return notification
                    }
                )
            }
        },
    },
})

export const selectSelf = (state) => state[sliceName]

export const selectNotifications = createSelector(
    selectSelf,
    (state) => state?.notifications
)
export const selectUnreadFilterActive = createSelector(
    selectSelf,
    (state) => state?.unreadFilterActive
)
export const selectIsNotificationsFetched = createSelector(
    selectSelf,
    (state) => state?.isNotificationsFetched
)
export const selectCurrentPage = createSelector(
    selectSelf,
    (state) => state?.currentPage
)
export const selectLastPage = createSelector(
    selectSelf,
    (state) => state?.lastPage
)
export const selectTotal = createSelector(selectSelf, (state) => state?.total)
export const selectPerPage = createSelector(
    selectSelf,
    (state) => state?.perPage
)
export const selectIsLoading = createSelector(
    selectSelf,
    (state) => state?.isLoading
)

export const {
    addNotification,
    setIsSubscribed,
    setCurrentPage,
    setUnreadFilterActive,
    resetNotificationHistory,
    setIsLoading,
} = notifications.actions
export default notifications.reducer
