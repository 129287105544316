import { createUseStyles } from 'react-jss'
import PostDescription from '../post-description'

const useStyles = createUseStyles((theme) => ({
    content: {
        display: 'grid',
        gridTemplateRows: 'auto',
    },
    gallery: {},
}))

const PostTextContent = ({ content }) => {
    const classes = useStyles()

    return (
        <div className={classes.content}>
            <PostDescription text={content} />
        </div>
    )
}

export default PostTextContent
