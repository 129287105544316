import * as yup from 'yup'

const KEEP_SUPPORTING_MODEL = {
    checkbox: 'checkbox',
}

const keepSupportingValidationSchema = yup.object().shape({
    [KEEP_SUPPORTING_MODEL.checkbox]: yup
        .array(yup.number().nullable())
        .min(1)
        .compact(),
})

export {
    KEEP_SUPPORTING_MODEL,
    keepSupportingValidationSchema as validationSchema,
}
