import { createUseStyles } from 'react-jss'
import colors from '../../../../../../theme/colors'
import { setUnsubscribeModalOpen } from '../../../../../../store/slices/app'
import { useDispatch } from 'react-redux'

const useStyles = createUseStyles((theme) => ({
    root: {
        padding: [8, 16, 12],
        textAlign: 'right',
        '& span': {
            fontSize: '12px',
            color: colors.primary[500],
            cursor: 'pointer',
        },
    },
}))

const ActiveSubscriptionFooter = ({ user }) => {
    const dispatch = useDispatch()

    const classes = useStyles()
    return (
        <div className={classes.root}>
            <span
                onClick={() =>
                    dispatch(setUnsubscribeModalOpen({ isOpen: true, user }))
                }
            >
                Unsubscribe
            </span>
        </div>
    )
}

export default ActiveSubscriptionFooter
