export const ROUTE_WATCH = '/watch'
export const ROUTE_WATCH_SECTION = '/watch/:section'
export const ROUTE_WATCH_SECTION_ID = '/watch/:section/:id'
export const ROUTE_SHOWCASE = '/showcase'
export const ROUTE_LOGIN = '/login'
export const ROUTE_SIGNUP = '/signup'
export const ROUTE_FORGOT_PASSWORD = '/forgot-password'
export const ROUTE_RESET_PASSWORD = '/reset-password/:token'
export const ROUTE_ACTIVATION = '/signup-activate/:token'
export const USER_SETTINGS = '/settings/:section'
export const USER_SETTINGS_MOBILE = '/settings' //it seams necessary due tu react v6 removed optional parameters handling with "?"
export const ROUTE_HOME = '/home'
export const ROUTE_NOT_FOUND = '/404'
export const ROUTE_FANPAGE = '/:username'
export const ROUTE_KEEP_SUPPORTING = '/keep-supporting'
export const ROUTE_PAYMENT_CHECK_STATUS = '/payment-check'
export const ROUTE_SEARCH = '/search/:name'
export const ROUTE_NOTIFICATIONS = '/notifications'
export const ROUTE_MESSAGES = '/messages'
//export const ROUTE_MESSAGES_CHAT = '/messages/:id'
export const ROUTE_MESSAGES_CHAT = '/messages/:channelUUID'
