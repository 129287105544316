import { createUseStyles } from 'react-jss'
import React, { useState } from 'react'
import { MoreVerticalIcon } from '../../../../../theme/icons'
import {
    setContextMenuOpen,
    setMessageToDelete,
} from '../../../../../store/slices/messages'
import { useDispatch } from 'react-redux'

const useStyles = createUseStyles((theme) => ({
    chatContextMenu: ({ isMine }) => ({
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: isMine ? 'flex-end' : 'flex-start',
        cursor: 'pointer',
    }),
    contextMenu: ({ isMine }) => ({
        zIndex: 2,
        position: 'absolute',
        ...theme.utils.flexbox.centered,
        height: 40,
        backgroundColor: '#FCFCFC',
        borderRadius: 6,
        gap: 4,
        ...(isMine ? { right: 28 } : { left: 28 }),
        top: '50%',
        padding: 8,
        transform: 'translateY(-50%)',
        boxShadow:
            '0px 1px 3px rgba(20, 20, 42, 0.1), 0px 0px 1px rgba(20, 20, 42, 0.05)',
    }),
    contextLabel: {
        ...theme.utils.flexbox.centered,
        borderRadius: 2,
        height: 24,
        padding: [2, 8],
        fontSize: 14,
        fontWeight: 400,
        letterSpacing: 0.5,
        color: '#585858',
        '&:hover': {
            backgroundColor: '#FBF4F6',
            color: '#E84672',
        },
    },
    divider: {
        height: 16,
        borderLeft: '1px solid #E1E1E1',
    },
}))

const ChatContextMenu = ({ message, isMobile, isMine }) => {
    const dispatch = useDispatch()
    const [isContextMenuOpen, setIsContextMenuOpen] = useState(false)

    const handleDelete = () => {
        dispatch(setMessageToDelete(message))
        isMobile && dispatch(setContextMenuOpen(false))
        setIsContextMenuOpen(false)
    }

    //TODO CHAT implement when requested
    // const handleReport = () => {
    //     console.log('report')
    // }

    const handleContextMenuOpen = () => {
        isMobile && dispatch(setContextMenuOpen(message))
        setIsContextMenuOpen(!isContextMenuOpen)
    }

    const classes = useStyles({ isMine })
    return (
        <div className={classes.chatContextMenu}>
            <MoreVerticalIcon onClick={handleContextMenuOpen} />
            {isContextMenuOpen && !isMobile && (
                <>
                    <div
                        className={classes.overlay}
                        onClick={() => setIsContextMenuOpen(false)}
                    />
                    <div className={classes.contextMenu}>
                        <div
                            className={classes.contextLabel}
                            onClick={handleDelete}
                        >
                            Delete
                        </div>
                        {/*TODO CHAT implement when report messagge is requested*/}
                        {/*{!isMine && (*/}
                        {/*    <>*/}
                        {/*        <Divider className={classes.divider} />*/}
                        {/*        <div*/}
                        {/*            className={classes.contextLabel}*/}
                        {/*            onClick={handleReport}*/}
                        {/*        >*/}
                        {/*            Report*/}
                        {/*        </div>*/}
                        {/*    </>*/}
                        {/*)}*/}
                    </div>
                </>
            )}
        </div>
    )
}

export default ChatContextMenu
