import axios from '../utilities/axios'

export const httpPaymentsSpent = (page, limit) => {
    const url = `profile/payments/spent`
    return axios.get(url, { params: { page, limit } })
}

export const httpPaymentsRevenue = (page, limit) => {
    const url = `profile/payments/revenue`
    return axios.get(url, { params: { page, limit } })
}

export const httpPaymentsSubscriptions = (page, limit, status) => {
    const url = `profile/subscriptions`
    return axios.get(url, { params: { page, limit, status } })
}

export const httpPaymentsFans = (page, limit, status) => {
    const url = `profile/subscribers`
    return axios.get(url, { params: { page, limit, status } })
}
