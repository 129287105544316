import { createUseStyles, useTheme } from 'react-jss'
import React, { useEffect } from 'react'
import Popover from '../../../../components/Popover'
import Banner from '../../../../components/Banner'
import { InfoIcon } from '../../../../theme/icons'
import Button from '../../../../components/Button'
import { PriceChangeSVG } from '../../../../asset/images/PriceChangeSVG'
import PropTypes from 'prop-types'
import { SUBSCRIPTIONS_MODEL } from '../subscription-form/SubscriptionModel'

const useStyles = createUseStyles((theme) => ({
    root: {
        ...theme.utils.grid.centered,
        gridTemplateColumns: '1fr',
        color: theme.palette.secondary.main,
    },
    body: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: theme.spacing,
        padding: theme.spacing * 2,
        textAlign: 'center',
        '& h1': {
            fontSize: '28px !important',
            letterSpacing: 0.25,
            fontWeight: 700,
            color: theme.palette.primary.background,
        },
        '& p': {
            fontSize: '16px !important',
            fontWeight: '400 !important',
            color: `${theme.palette.text.secondary} !important`,
            letterSpacing: '0.25px !important',
            marginTop: -8,
        },
    },
    action: {
        borderTop: `1px solid ${theme.palette.grey[300]}`,
        padding: theme.spacing * 2,
    },
    bannerWrapper: {
        margin: theme.spacing * 2,
        borderRadius: 12,
    },
}))

const textMap = {
    free_to_paid: {
        banner: 'Attention, switching from a free to a paid profile could have consequences!',
        paragraph:
            'Switching from a free to a paid profile could have consequences, including the loss of your fans!',
    },
    paid_to_free: {
        banner: 'Attention, you are switching from paid to free profile!',
        paragraph:
            'Are you sure that you want switching from paid to free profile?',
    },
    amount_change: {
        banner: 'Attention, changing the rate could have consequences!',
        paragraph:
            'Changing the monthly plan could have consequences, including the loss of your fans!',
    },
}

const MonthlyPlanModal = ({ onClose, variant, setValue, currentPrice }) => {
    useEffect(() => {
        if (variant === 'paid_to_free')
            setValue(SUBSCRIPTIONS_MODEL.subscriptionPrice, 0)
    }, [variant, setValue])

    const handleOnClose = () => {
        if (variant === 'paid_to_free') {
            setValue(SUBSCRIPTIONS_MODEL.isFree, 'false')
            setValue(SUBSCRIPTIONS_MODEL.subscriptionPrice, currentPrice)
        }
        onClose()
    }

    const classes = useStyles()
    return (
        <Popover
            onClose={handleOnClose}
            title={'Monthly Plan'}
            maxWidth={539}
            closeOnOverlay={false}
            fontWeight={500}
            headerFontSize={16}
        >
            <div className={classes.root}>
                <div className={classes.bannerWrapper}>
                    <Banner
                        data={'tertiary'}
                        text={textMap[variant].banner}
                        icon={<InfoIcon />}
                        iconSize={22}
                        height={58}
                    />
                </div>
                <div className={classes.body}>
                    <PriceChangeSVG />
                    <h1>Price change alert</h1>
                    <p>{textMap[variant].paragraph}</p>
                </div>
                <div className={classes.action}>
                    <Button width={'100%'} type={'submit'}>
                        I confirm changing my plan
                    </Button>
                </div>
            </div>
        </Popover>
    )
}

export default MonthlyPlanModal

MonthlyPlanModal.propTypes = {
    variant: PropTypes.oneOf(['free_to_paid', 'paid_to_free', 'amount_change']),
}
