import { createUseStyles } from 'react-jss'
import defaultCover from '../creator-cover/asset/defaultCover.png'
import CoverHeader from './cover-header'
import CoverFooter from './cover-footer'
import { useEffect, useState } from 'react'
import Media from '../../../components/Media'
import { selectUser } from '../../../store/slices/user'
import { useSelector } from 'react-redux'
import Button from '../../../components/Button'
import UnsubscribeButton from './unsubscribe-button'

const useStyles = createUseStyles((theme) => ({
    root: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        maxWidth: 636,
        overflow: 'hidden',
        borderRadius: 16,
        boxShadow: '0px 1px 3px rgba(20, 20, 42, 0.1)',
        margin: '0 auto',
        [theme.mediaQueries.xxsUp]: {},
        '& img': {
            width: '100%',
            height: '100%',
            objectFit: 'cover',
        },
    },
    image: {
        backgroundColor: theme.palette.primary.background,
        height: 150,
        [theme.mediaQueries.sUp]: {
            height: 208,
        },
    },
    content: {
        ...theme.utils.flexbox.centered,
        padding: theme.spacing * 2,
    },
    menu: {
        position: 'absolute',
        margin: theme.spacing * 2,
        top: 0,
        right: 0,
        cursor: 'pointer',
    },
    optionButton: {
        maxWidth: 24,
        maxHeight: 24,
        borderRadius: 4,
        transform: 'rotate(90deg)',
        background: 'rgba(252, 252, 252, 0.1)',
        backdropFilter: 'blur(8px)',
        '-webkit-backdrop-filter': 'blur(8px)',
    },
    unsubscribe: {
        borderTop: `1px solid ${theme.palette.divider.internal}`,
        padding: theme.spacing * 2,
    },
}))

const CreatorCover = ({ user }) => {
    const userMe = useSelector(selectUser)

    const [isMe, setIsMe] = useState(user?.id === userMe?.id)
    useEffect(() => {
        setIsMe(user?.id === userMe?.id)
    }, [user, userMe])

    const classes = useStyles()
    return (
        <div className={classes.root}>
            <Media
                className={classes.image}
                {...(user?.header?.[720] && { image: user?.header?.[720] })}
                //image={user?.header?.[720] ?? defaultCover}
            />
            <CoverHeader user={user} isMe={isMe} />
            {!isMe && (
                <CoverFooter
                    user={user}
                    isSubscribed={user?.subscription?.status === 'active'}
                />
            )}
            {user?.subscription?.status === 'active' && (
                <UnsubscribeButton user={user} />
            )}
        </div>
    )
}

export default CreatorCover
