import Cookies from 'js-cookie'
import {
    ACCESS_TOKEN,
    REFRESH_TOKEN,
    CURRENT_USER,
    LOCAL_STORAGE_ACCESS_TOKEN,
    LOCAL_STORAGE_REFRESH_TOKEN,
    LOCAL_STORAGE_USER,
    KNOWN_COOKIES,
} from './constants'

const accessToken = {
    set: (value) => {
        localStorage.setItem(LOCAL_STORAGE_ACCESS_TOKEN, value)
    },
    get: () => {
        /* Logic used to impersonate an user */
        const adminAuth = Cookies.get(KNOWN_COOKIES.adminCookie)
        if (adminAuth) {
            Cookies.remove(KNOWN_COOKIES.adminCookie)
            sessionStorage.setItem(LOCAL_STORAGE_ACCESS_TOKEN, adminAuth)
        }
        const sessionAuth = sessionStorage.getItem(LOCAL_STORAGE_ACCESS_TOKEN)
        if (sessionAuth) {
            return sessionStorage.getItem(LOCAL_STORAGE_ACCESS_TOKEN)
        }
        return localStorage.getItem(LOCAL_STORAGE_ACCESS_TOKEN)
    },
    remove: () => {
        sessionStorage.removeItem(LOCAL_STORAGE_ACCESS_TOKEN)
        localStorage.removeItem(LOCAL_STORAGE_ACCESS_TOKEN)
    },
}

const refreshToken = {
    set: (value) => {
        localStorage.setItem(LOCAL_STORAGE_REFRESH_TOKEN, value)
    },
    get: () => {
        return localStorage.getItem(LOCAL_STORAGE_REFRESH_TOKEN)
    },
    remove: () => {
        localStorage.removeItem(LOCAL_STORAGE_REFRESH_TOKEN)
    },
}

const user = {
    set: (value) => {
        localStorage.setItem(LOCAL_STORAGE_USER, JSON.stringify(value))
    },
    get: () => {
        return JSON.parse(localStorage.getItem(LOCAL_STORAGE_USER))
    },
    remove: () => {
        localStorage.removeItem(LOCAL_STORAGE_USER)
    },
}

const setAuthData = (authData) => {
    accessToken.set(authData[ACCESS_TOKEN])
    refreshToken.set(authData[REFRESH_TOKEN])
    user.set(authData[CURRENT_USER])
}

const removeAuthData = () => {
    accessToken.remove()
    refreshToken.remove()
    user.remove()
}

const LocalStorageManager = {
    accessToken,
    refreshToken,
    user,
    setAuthData,
    removeAuthData,
}

export default LocalStorageManager
