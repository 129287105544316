import React, { useEffect, useMemo, useRef, useState } from 'react'
import { createUseStyles } from 'react-jss'
import Spinner from '../../../components/Spinner'
import MediaGallery from './media-gallery'
import { LoaderIcon } from '../../../theme/icons'
import { useOutletContext } from 'react-router-dom'
import { httpFetchUserMedias } from '../../../http-requests'
import { handleApiError } from '../../../utilities/helpers'

const useStyles = createUseStyles((theme) => ({
    postFeed: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 16,
        padding: [16, 0],
        [theme.mediaQueries.lUp]: {
            padding: 16,
        },
    },
    loadMoreSpinnerWrapper: {
        position: 'relative',
        width: 20,
        height: 20,
        animation: 'spin 1s linear infinite',
        margin: '0 auto',
    },
}))

const MediaSection = () => {
    const userID = useOutletContext()
    /* Pagination */
    const endRef = useRef()
    const [currentPage, setCurrentPage] = useState(0)
    const [lastPage, setLastPage] = useState(0)
    const [perPage, setPerPage] = useState(0)
    const [total, setTotal] = useState(0)
    const [medias, setMedias] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [isLoadingMore, setIsLoadingMore] = useState(false)
    const fetchLimit = 12

    const fetchMedias = async (page) => {
        try {
            page === 1 ? setIsLoading(true) : setIsLoadingMore(true)
            const {
                data: { data, current_page, last_page, total, per_page },
            } = await httpFetchUserMedias({
                userID,
                page,
                limit: fetchLimit,
            })
            setCurrentPage(current_page)
            setLastPage(last_page)
            setTotal(total)
            setPerPage(per_page)
            setMedias(current_page === 1 ? data : [...medias, ...data])
        } catch (error) {
            handleApiError({
                isReduxError: false,
                error,
            })
        } finally {
            setIsLoading(false)
            setIsLoadingMore(false)
        }
    }

    useEffect(() => {
        if (!currentPage) {
            fetchMedias(1)
        }
    }, [])

    const fetchMore = async () => {
        if (currentPage < lastPage && perPage >= fetchLimit) {
            fetchMedias(currentPage + 1)
        } else if (currentPage < lastPage && perPage < fetchLimit) {
            fetchMedias(1)
        }
    }

    const intersectionObserver = useMemo(
        () =>
            new IntersectionObserver(
                (entries) => {
                    const first = entries[0]
                    if (first.isIntersecting) {
                        fetchMore()
                    }
                },
                {
                    threshold: 0,
                    rootMargin: '0px 0px 100px 0px',
                }
            ),
        [medias, currentPage, lastPage]
    )

    useEffect(() => {
        if (endRef.current) {
            intersectionObserver.observe(endRef.current)
        }
        return () => {
            if (endRef.current) {
                intersectionObserver.unobserve(endRef.current)
            }
        }
    }, [endRef.current, intersectionObserver])

    const classes = useStyles()

    return (
        <div className={classes.postFeed}>
            {isLoading && <Spinner overlayFullscreen />}
            {!!medias.length && <MediaGallery items={medias} />}
            {!!medias.length && <div ref={endRef} />}
            {isLoadingMore && (
                <div className={classes.loadMoreSpinnerWrapper}>
                    <LoaderIcon />
                </div>
            )}
        </div>
    )
}

export default MediaSection
