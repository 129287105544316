import { createUseStyles } from 'react-jss'
import lockedBg from '../../../../../../../asset/images/feed/post-locked-small.jpg'
import { LockIcon } from '../../../../../../../theme/icons'
import { useDispatch } from 'react-redux'
import {
    setPremiumPostModalOpen,
    setSubscriptionModalOpen,
} from '../../../../../../../store/slices/app'

const useStyles = createUseStyles((theme) => ({
    thumb: {
        position: 'relative',
        height: '100%',
        width: '100%',
        overflow: 'hidden',
        borderRadius: 16,
        cursor: 'pointer',
        '& > span': {
            position: 'absolute',
            display: 'flex',
            top: 8,
            right: 8,
            padding: 4,
            backgroundColor: 'rgba(252, 252, 252, 0.1)',
            borderRadius: 6,
        },
        '& > img': {
            height: '100%',
            width: '100%',
            objectFit: 'cover',
            objectPosition: 'center center',
        },
    },
}))

const MediaPostLocked = ({ user, post }) => {
    const dispatch = useDispatch()
    const isPremium = post?.price > 0

    const classes = useStyles()
    return (
        <div
            className={classes.thumb}
            onClick={() =>
                isPremium
                    ? dispatch(
                          setPremiumPostModalOpen({
                              isOpen: true,
                              user: {
                                  premiumPost: {
                                      price: post?.price,
                                      date: post?.created_at,
                                      postID: post?.id,
                                  },
                                  ...user,
                              },
                          })
                      )
                    : dispatch(setSubscriptionModalOpen({ isOpen: true, user }))
            }
        >
            <span>
                <LockIcon width={16} height={16} stroke={'#fff'} />
            </span>
            <img src={lockedBg} alt={'Subscribe to unlock content'} />
        </div>
    )
}

export default MediaPostLocked
