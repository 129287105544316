import { createUseStyles } from 'react-jss'
import Button from './Button'
import success from '../asset/images/checkIcon.png'

const useStyles = createUseStyles((theme) => ({
    paymentSuccess: {
        padding: [0, 16, 16],
        display: 'grid',
        alignItems: 'center',
        justifyItems: 'center',
        textAlign: 'center',
        '& h4': {
            color: theme.palette.secondary.main,
            margin: [8, 0],
        },
        '& p': {
            marginBottom: 24,
        },
        '& img': {
            maxHeight: 80,
        },
    },
}))

const PaymentSuccess = ({ onClose }) => {
    const classes = useStyles()

    return (
        <div className={classes.paymentSuccess}>
            <img src={success} alt={'success'} />
            <h4>Success</h4>
            <p>Subscritpion completed successfully</p>
            <Button
                width={'100%'}
                variant={'filled'}
                type={'button'}
                onClick={onClose}
            >
                Close
            </Button>
        </div>
    )
}

export default PaymentSuccess
