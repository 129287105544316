import axios from '../utilities/axios'

export const httpRefreshChatToken = () => {
    return axios.get(`profile/chat/token/refresh`)
}

export const httpUploadChatMedia = (media, type, options) => {
    return axios.post(`/upload/chat/${type}`, media, options)
}

export const httpRetrieveChatMedia = (id) => {
    return axios.get(`/media/${id}`)
}

export const httpFetchChatGallery = ({ channel, page, limit }) => {
    const url = `/channels/${channel}/media`
    return axios.get(url, {
        params: { page, limit },
    })
}

export const httpFetchChatChannels = ({page, limit = 20, name}) => {
    const url = `/channels`
    return axios.get(url, {
        params: { page, limit, response_type: 'extended', name },
    })
}

export const httpSetLastReadTimetoken = ({channelID, timetoken}) => {
    const url = `/channels/${channelID}/channels_reads_timetokens`
    return axios.post(url, {timetoken})
}

export const httpSetLastMessageSent = ({channelID}) => {
    const url = `/channels/${channelID}/messages`
    return axios.post(url)
}
export const httpSendMedia = ({channelID, media_ids}) => {
    const url = `/channels/${channelID}/media/multiple-send`
    return axios.post(url, media_ids)
}
export const httpDeleteMedia = ({channelID, media_ids}) => {
    const url = `/channels/${channelID}/media/multiple-delete`
    return axios.post(url, media_ids)
}