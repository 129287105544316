import { createUseStyles } from 'react-jss'
import PropTypes from 'prop-types'
import cx from 'classnames'

const useStyles = createUseStyles((theme) => ({
    cardContent: {
        display: 'grid',
        gap: 8,
    },
    subscriptionChoice: {
        display: 'flex',
        ...theme.utils.flexbox.spaceBetween,
        gap: 16,
        alignItems: 'center',
    },
    roleTitle: {
        fontSize: 16,
        fontWeight: 400,
        color: theme.palette.label.color,
        letterSpacing: 0.5,
    },
}))

const SubscriptionSettingsCard = ({
    title,
    description,
    action,
    children,
    className,
}) => {
    const classes = useStyles()

    return (
        <div className={cx(classes.cardContent, className)}>
            {description && <p>{description}</p>}
            <div className={classes.subscriptionChoice}>
                <span className={classes.roleTitle}>{title}</span>
                {action}
            </div>
            {children}
        </div>
    )
}

export default SubscriptionSettingsCard

