import { createUseStyles } from 'react-jss'
import PostDescription from '../post-description'
import bgImageL from '../../../../../../asset/images/feed/post-locked-large.jpg'
import bgImageM from '../../../../../../asset/images/feed/post-locked-medium.jpg'
import ResponsiveImage from '../../../../../../components/ResponsiveImage'
import logoSmall from '../../../../../../asset/images/logo_small.svg'
import { useDispatch } from 'react-redux'
import { setPremiumPostModalOpen } from '../../../../../../store/slices/app'
import colors from '../../../../../../theme/colors'

const useStyles = createUseStyles((theme) => ({
    content: {
        display: 'flex',
        flexDirection: 'column',
        gap: 8,
    },
    image: {
        flexGrow: 1,
        maxHeight: 300,
    },
    lockedContent: {
        position: 'relative',
    },
    cta: {
        minWidth: 230,
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        display: 'flex',
        alignItems: 'center',
        gap: 8,
        backgroundColor: colors.transparency.light.A05,
        padding: 8,
        borderRadius: 100,
        color: theme.palette.common.white,
        fontWeight: 700,
        cursor: 'pointer',
        whiteSpace: 'nowrap',
        '& > span': {
            display: 'flex',
            borderRadius: '50%',
            padding: [1, 2],
        },
    },
}))

const PostPremiumContent = ({
    content,
    price,
    user,
    date,
    postID,
    ...props
}) => {
    const dispatch = useDispatch()

    const classes = useStyles()
    return (
        <div className={classes.content}>
            <PostDescription text={content} />
            <div className={classes.lockedContent}>
                <ResponsiveImage
                    className={classes.image}
                    media={{
                        small: bgImageM,
                        medium: bgImageM,
                        large: bgImageL,
                        original: bgImageL,
                    }}
                    sizes={{
                        small: 'small',
                        medium: 'medium',
                        large: 'large',
                        original: 'original',
                    }}
                />
                <div
                    className={classes.cta}
                    onClick={() =>
                        dispatch(
                            setPremiumPostModalOpen({
                                isOpen: true,
                                user: {
                                    premiumPost: {
                                        price,
                                        date,
                                        postID,
                                    },
                                    ...user,
                                },
                            })
                        )
                    }
                >
                    <span>
                        <img src={logoSmall} alt={'locked'} />
                    </span>
                    Premium content post
                </div>
            </div>
        </div>
    )
}

export default PostPremiumContent
