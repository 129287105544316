import { createUseStyles } from 'react-jss'
import Button from '../../../../components/Button'
import Box from '../../../../components/Box'
import Divider from '../../../../components/Divider'
import React, { useEffect, useState } from 'react'
import SubscriptionsFanPopover from './SubscriptionsFanPopover'
import { httpListBundles } from '../../../../http-requests/subscription'
import { handleApiError } from '../../../../utilities/helpers'
import { useWindowSize } from '../../../../hooks/useWindowSize'
import colors from '../../../../theme/colors'

const useStyles = createUseStyles((theme) => ({
    box: {
        marginTop: theme.spacing * 3,
        marginBottom: theme.spacing * 2,
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        maxWidth: 636,
        overflow: 'hidden',
        borderRadius: 16,
        boxShadow: '0px 1px 3px rgba(20, 20, 42, 0.1)',
        margin: '0 auto',
    },

    sectionTitle: {
        letterSpacing: 0.5,
        fontSize: 16,
        fontWeight: 500,
        lineHeight: '140%',
        color: theme.palette.primary.background,
        margin: [theme.spacing, 0],
    },

    wrapperSubtitle: {
        display: 'flex',
        paddingBottom: theme.spacing,
    },

    subtitle: {
        fontSize: 12,
        fontWeight: 400,
        color: theme.palette.text.primary,
        '& strong': {
            fontWeight: 700,
        },
    },
    button: {
        '&:hover': {
            backgroundColor: colors.primary[100],
        },
    },
}))

const SubscriptionsFanBox = ({ user, ...props }) => {
    const [popOver, setPopOver] = useState({ isOpen: false })
    const [bundles, setBundles] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const { width } = useWindowSize()
    const [isMobile, setIsMobile] = useState(width <= 448)

    useEffect(() => {
        setIsMobile(width <= 448)
    }, [width])

    useEffect(() => {
        getBundle()
    }, [user])

    const getBundle = async () => {
        try {
            const { data } = await httpListBundles({ user_id: user?.id })
            setBundles(data.data)
        } catch (error) {
            handleApiError({
                isReduxError: false,
                error,
            })
        }
    }

    const classes = useStyles()
    return (
        <>
            {!!bundles.length && (
                <Box classNames={classes.box}>
                    <p className={classes.sectionTitle}>Bundles</p>
                    {bundles.map(
                        (
                            {
                                duration_in_months,
                                discount_percentage,
                                dollar_price,
                                id,
                            },
                            index
                        ) => {
                            return (
                                <div key={index}>
                                    <Divider />

                                    <div className={classes.wrapperSubtitle}>
                                        <p className={classes.subtitle}>
                                            <strong>
                                                {discount_percentage}% OFF ·
                                            </strong>
                                            ${dollar_price?.toFixed(2)} for{' '}
                                            {duration_in_months} months
                                        </p>
                                    </div>

                                    <Button
                                        size={isMobile ? 'small' : 'large'}
                                        width={'100%'}
                                        variant={'ghost'}
                                        className={classes.button}
                                        onClick={() => {
                                            setPopOver({
                                                data: {
                                                    duration_in_months,
                                                    discount_percentage,
                                                    dollar_price,
                                                    id,
                                                },
                                                isOpen: true,
                                            })
                                        }}
                                    >
                                        Subscribe for $
                                        {dollar_price?.toFixed(2)}
                                    </Button>
                                </div>
                            )
                        }
                    )}
                </Box>
            )}

            {popOver?.isOpen && (
                <SubscriptionsFanPopover
                    user={user}
                    data={popOver?.data}
                    setPopOver={setPopOver}
                    popOver={popOver}
                    setIsLoading={setIsLoading}
                    isLoading={isLoading}
                />
            )}
        </>
    )
}

export default SubscriptionsFanBox
