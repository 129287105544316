import * as yup from 'yup'

const UNSUBSCRIBE_MODEL = {
    unsubscribeReason: 'unsubscribe_reason',
}

const postValidationSchema = yup.object().shape({
    [UNSUBSCRIBE_MODEL.unsubscribeReason]: yup
        .object()
        .nullable()
        .required('You must choose a reason'),
})

export { UNSUBSCRIBE_MODEL, postValidationSchema as validationSchema }
