import { createUseStyles } from 'react-jss'
import { ClockIcon } from '../../../../../../theme/icons'
import Button from '../../../../../../components/Button'
import colors from '../../../../../../theme/colors'
import { useWindowSize } from '../../../../../../hooks/useWindowSize'
import { setSubscriptionModalOpen } from '../../../../../../store/slices/app'
import { useDispatch } from 'react-redux'
import dayjs from "dayjs";

const useStyles = createUseStyles((theme) => ({
    root: {
        padding: [10],
        display: 'grid',
        alignItems: 'center',
        gridTemplateColumns: '24px auto 1fr',
        gridColumnGap: 16,
        backgroundColor: '#F9F9F9',
        marginTop: '-1px',
        paddingLeft: 16,
    },
    info: {
        display: 'grid',
        alignItems: 'center',
        gridColumnGap: 8,
        gridTemplateColumns: 'auto 1px auto',
        '& > p': {
            fontSize: 16,
            fontWeight: 600,
            color: '#808080',
        },
        '& > span': {
            height: '100%',
            width: '1px',
            backgroundColor: '#808080',
        },
        '& > small': {
            fontSize: 12,
            color: '#808080',
        },
    },
    align: {
        margin: 'auto 0 0 auto',
    },
}))

const InactiveSubscriptionFooter = ({ user }) => {
    const dispatch = useDispatch()
    const classes = useStyles()
    const { width } = useWindowSize()
    const isMobile = width <= 468

    const firstDayOfMonth = dayjs().startOf('month').add(1, 'month').format('D MMMM')
    const now = dayjs().format('D MMMM')
    const diff = dayjs(firstDayOfMonth).diff(dayjs(now), 'day')

    return (
        <div className={classes.root}>
            <ClockIcon width={24} stroke={'#808080'} />
            <span className={classes.info}>
                <p>Expire on {firstDayOfMonth}</p>
                {!isMobile && <span />}
                {!isMobile && <small>{diff} {diff === 1 ? 'day' : 'days'} left</small>}
            </span>
            <Button
                width={106}
                variant={'filled'}
                size={'small'}
                className={classes.align}
                onClick={() => dispatch(setSubscriptionModalOpen({ isOpen: true, user }))}
            >
                Reactivate
            </Button>
        </div>
    )
}

export default InactiveSubscriptionFooter
