import axios from '../utilities/axios'

export const httpSignupAttempt = (data) => {
    const url = '/auth/signup-attempt'
    return axios.post(url, data)
}

export const httpRegisterUser = (data) => {
    const url = '/auth/signup'
    const options = {
        withCredentials: true
    };
    return axios.post(url, data, options)
}

export const httpActivateAccount = (activationToken) => {
    const url = `/auth/signup/activate/${activationToken}`
    return axios.get(url)
}

export const httpLoginUser = (data) => {
    const url = '/auth/login'
    return axios.post(url, data)
}

export const httpLogoutUser = () => {
    const url = '/auth/logout'
    return axios.get(url)
}

export const httpGetUserMe = () => {
    return axios.get('/profile/me', { params: { response_type: 'extended' } })
}

export const httpResetPassword = (data) => {
    const url = '/password_recovery/create'
    return axios.post(url, data)
}

export const httpResetPasswordPermission = (access_token) => {
    const url = `/password_recovery/find/${access_token}`
    return axios.get(url)
}

export const httpResetPasswordConfirmation = (data) => {
    const url = '/password_recovery/reset'
    return axios.post(url, data)
}

export const httpChangePassword = (data) => {
    const url = '/profile/change-password'
    return axios.post(url, data)
}

export const httpUpdateProfile = (data) => {
    const url = `/profile/update`
    return axios.patch(url, data)
}

export const httpUpdateProfileAvatar = (data) => {
    const url = `/profile/avatar`
    return axios.post(url, data)
}

export const httpDeleteProfileAvatar = () => {
    const url = `/profile/avatar`
    return axios.delete(url)
}

export const httpUpdateProfileHeader = (data) => {
    const url = `/profile/header`
    return axios.post(url, data)
}

export const httpDeleteProfileHeader = () => {
    const url = `/profile/header`
    return axios.delete(url)
}

export const httpRefreshAccessToken = ({ refresh_token }) => {
    const url = '/auth/refresh'
    return axios.post(url, { refresh_token: refresh_token })
}

export const httpFetchGenderOptions = () => {
    const url = `/genders`
    return axios.get(url)
}

export const httpFetchSubscriptions = (status, page, limit) => {
    const url = `/profile/subscriptions`
    return axios.get(url, { params: { status: status, page, limit } })
}

export const httpDeleteProfile = ({ password }) => {
    const url = `/profile/delete`
    return axios.post(url, { password })
}

export const httpUnsubscribeUser = (unsubscribeToken) => {
    const url = `/profile/unsubscribe/${unsubscribeToken}`
    return axios.get(url)
}

export const httpCheckUsername = (username) => {
    const url = '/public/signup-attempt'
    return axios.get(url, { params: { username: username } })
}

export const httpFetchCategories = () => {
    const url = '/public/categories'
    return axios.get(url)
}

export const httpFetchFeaturedCreators = (categories, page, limit) => {
    const url = '/public/featuredCreators'
    return axios.get(url, { params: { categories: categories, page, limit } })
}

export const httpFetchFeaturedByUser = () => {
    const url = '/profile/featuredCreators'
    return axios.get(url, { params: { page: 1, limit: 50 } })
}

export const httpFetchActivities = (page, limit) => {
    const url = '/profile/activities'
    return axios.get(url, { params: { page, limit } })
}

export const httpDeleteActivity = (id) => {
    const url = `/profile/activities/${id}`
    return axios.delete(url)
}

export const httpFetchUserByUsername = (username) => {
    const url = `/users/${username}`
    return axios.get(url, {
        params: { response_type: 'extended' },
    })
}

export const httpFetchUserByUsernamePublic = (username) => {
    const url = `/public/users/${username}`
    return axios.get(url, {
        params: { response_type: 'extended' },
    })
}

export const httpFetchUserPosts = ({ userID, page, limit }) => {
    const url = `/users/${userID}/posts`
    return axios.get(url, {
        params: { response_type: 'extended', page, limit },
    })
}

export const httpFetchUserMedias = ({ userID, page, limit }) => {
    const url = `/users/${userID}/media`
    return axios.get(url, {
        params: { response_type: 'extended', page, limit },
    })
}

export const httpFetchUserFollowers = (user) => {
    const url = `/users/${user}/followers`
    return axios.get(url)
}

export const httpFetchUserFollowing = (user) => {
    const url = `/users/${user}/followings`
    return axios.get(url)
}

export const httpFollowUser = (userID) => {
    const url = `/users/${userID}/follow`
    return axios.post(url)
}

export const httpUnfollowUser = (userID) => {
    const url = `/users/${userID}/unfollow`
    return axios.delete(url)
}

export const httpUserTokens = (userID) => {
    const url = `/users/${userID}/tokens`
    return axios.get(url)
}

export const httpLockTransactions = (data) => {
    const url = `/profile/setPendingPayment`
    return axios.post(url, data)
}

export const httpSearchUser = ({ name, page, limit, categoriesID = [] }) => {
    const url = `/users/search`
    return axios.get(url, {
        params: {
            name,
            page,
            limit,
            response_type: 'extended',
            categories_ids: categoriesID,
        },
    })
}

export const httpSubscriptionToRenew = () => {
    const url = `/profile/subscriptions/to-renew`
    return axios.get(url)
}

export const httpSendTip = (userID, data) => {
    const url = `/users/${userID}/tips`
    return axios.post(url, data)
}

export const httpReferralCode = (referralCode) => {
    const url = `/refer`
    const options = {
        withCredentials: true
    };

    return axios.post(url, { referral_code: referralCode }, options);
};
