import { createUseStyles } from 'react-jss'
import colors from '../../../../theme/colors'
import { notificationsSettingsAssets } from '../../../../utilities/constants/notifications'
import NotificationOptionItem from './notification-option-item/NotificationOptionItem'
import React, { useEffect, useState } from 'react'
import {
    httpFetchNotificationOptions,
    httpUpdateNotificationOptions,
} from '../../../../http-requests/notifications'
import { handleApiError } from '../../../../utilities/helpers'
import NotificationSettingsLoader from '../NotificationSettingsLoader'

const useStyles = createUseStyles((theme) => ({
    notificationsGeneralPanel: {},
    title: {
        fontWeight: 500,
        letterSpacing: 0.5,
        fontSize: 16,
        color: colors.tertiary[800],
    },
}))

const NotificationsGeneralPanel = ({}) => {
    const [options, setOptions] = useState([])
    const [loadingInitial, setLoadingInitial] = useState(false)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        fetchInitialPreferences()
    }, [])

    const fetchInitialPreferences = async () => {
        try {
            setLoadingInitial(true)
            const { data } = await httpFetchNotificationOptions()
            setOptions(data?.data)
            setLoadingInitial(false)
        } catch (error) {
            handleApiError({
                isReduxError: false,
                error,
            })
        }
    }

    const postPreferencesChanges = async (id, values) => {
        try {
            setLoading(id)
            const { data } = await httpUpdateNotificationOptions(id, values)
            setOptions(
                options?.map((option) => {
                    if (option?.id === id) {
                        return {
                            ...option,
                            ...values,
                        }
                    }
                    return option
                })
            )
            setLoading(false)
        } catch (error) {
            handleApiError({
                isReduxError: false,
                error,
            })
        }
    }

    const handlePreferenceChange = async (optionID, data) => {
        if (!loading) {
            await postPreferencesChanges(optionID, data)
        }
    }

    const classes = useStyles()
    return (
        <div className={classes.notificationsGeneralPanel}>
            <div className={classes.title}>General Notifications</div>
            {loadingInitial && <NotificationSettingsLoader />}

            {options?.map(
                ({ is_notification_active, is_email_active, type, id }, index) => {
                    return (
                        <NotificationOptionItem
                            key={index}
                            optionID={id}
                            handlePreferenceChange={handlePreferenceChange}
                            loading={loading === id}
                            description={
                                notificationsSettingsAssets[type]?.description
                            }
                            preference={{
                                is_notification_active,
                                is_email_active,
                            }}
                        />
                    )
                }
            )}
        </div>
    )
}

export default NotificationsGeneralPanel
