import { useDispatch, useSelector } from 'react-redux'
import { createUseStyles } from 'react-jss'
import {Link, useLocation, useNavigate} from 'react-router-dom'
import Timeline from '../../../components/Timeline'
import {
    selectSignupStatus,
    selectUser,
    setSignupStatus,
    updateUser,
} from '../../../store/slices/user'
import SignupForm from './signup-form'
import mailImage from '../../../asset/images/mail.png'
import { SIGNUP_STATUSES } from './signup-form/signupModel'
import { ROUTE_LOGIN } from '../../../utilities/constants'
import { useEffect } from 'react'
import Button from '../../../components/Button'
import {httpReferralCode} from "../../../http-requests";
import {handleApiError} from "../../../utilities/helpers";

const useStyles = createUseStyles((theme) => ({
    root: {
        display: 'grid',
        gridTemplateRows: ({ image }) => (image ? '1fr' : 'auto 1fr'),
        gap: 16,
        alignItems: ({ image }) => (image ? 'start' : 'end'),
    },
    content: {
        display: 'grid',
        gridTemplateRows: 'auto auto 1fr',
        textAlign: 'center',
        gap: 24,
        height: '100%',
    },
    title: {
        ...theme.pageStyle.title,
    },
    subtitle: {
        ...theme.pageStyle.sectionTitle,
        marginBottom: 8,
    },
    iconCard: {
        justifySelf: 'center',
        width: 160,
        height: 160,
        borderRadius: 12,
        backgroundColor: theme.palette.common.white,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        backgroundImage: ({ image }) => `url(${image})`,
    },
    wrapper: {
        display: 'grid',
        gap: 16,
        gridRowStart: 3,
    },
    fakeLink: {
        ...theme.link,
        cursor: 'normal',
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'none',
        },
    },
}))

const margin_top = {
    marginTop: '24px',
}

const Signup = () => {
    const user = useSelector(selectUser)
    const step = useSelector(selectSignupStatus)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {search} = useLocation()
    const referralCode = search.split('=')[1]

    useEffect(() => {
        if (referralCode) {
            getReferredBy()
        }
        return () => {
            dispatch(setSignupStatus(SIGNUP_STATUSES.initial))
            dispatch(updateUser(null))
        }
    }, [])

    const getReferredBy = async () => {
        try {
            await httpReferralCode(referralCode)
        } catch (error) {
            handleApiError({
                isReduxError: false,
                error,
            })
        }
    }

    const classes = useStyles({image: mailImage})

    return (
        <div className={classes.root}>
            <div className={classes.content}>
                {step === SIGNUP_STATUSES.completed ? (
                    <div
                        className={classes.wrapper}
                        style={{ alignSelf: 'center' }}
                    >
                        <div className={classes.iconCard} />
                        <div>
                            <h5 className={classes.title}>
                                Email Confirmation
                            </h5>
                            <p className={classes.subtitle}>
                                We have sent email to{' '}
                                <span className={classes.fakeLink}>
                                    {user?.email}
                                </span>{' '}
                                to confirm the validity of our email address.
                            </p>
                            <Button
                                style={margin_top}
                                variant={'filled'}
                                width={'100%'}
                                onClick={() => navigate(ROUTE_LOGIN)}
                            >
                                Log In
                            </Button>
                        </div>
                    </div>
                ) : (
                    <>
                        <div>
                            <h5 className={classes.title}>
                                Sign Up to HappyFans
                            </h5>
                            <p className={classes.subtitle}>
                                Enter the data required to create your account
                            </p>
                        </div>
                        <Timeline
                            stepNames={['SIGNUP', 'ACCOUNT', 'FINISH']}
                            stepValues={Object.values(SIGNUP_STATUSES)}
                            step={step}
                        />
                        <SignupForm />
                    </>
                )}
            </div>
            {SIGNUP_STATUSES.initial === step && (
                <p>
                    Already have an account?{' '}
                    <Link to={ROUTE_LOGIN}>Log In</Link>
                </p>
            )}
        </div>
    )
}

export default Signup
