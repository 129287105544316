import { createUseStyles, useTheme } from 'react-jss'
import cx from 'classnames'
import { components } from 'react-select'
import React, { useCallback, useState } from 'react'
import AsyncSelect from 'react-select/async'
import { asyncDebounce } from '../utilities/helpers'
import { SearchIcon } from '../theme/icons'
import PropTypes from 'prop-types'

const useStyles = createUseStyles((theme) => ({
    searchbar: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        cursor: 'pointer',
        padding: [0, 14],
        maxWidth: '100%',
        maxHeight: '100%',
        height: 48,
        borderRadius: 100,
        width: '100%',
        backgroundColor: theme.palette.disabled.light,
    },
}))

const searchbarCustomStyle = (theme) => {
    return {
        input: (provided) => ({
            ...provided,
            padding: 0,
            margin: 0,
            overflow: 'hidden',
            minWidth: '100%',
            '& input': {
                borderRadius: 0,
            },
        }),
        container: (provided) => ({
            ...provided,
            position: 'unset',
            width: '100%',
            overflow: 'hidden',
        }),
        valueContainer: (provided) => ({
            ...provided,
            padding: 0,
            display: 'grid',
            gridTemplateColumns: 'min-content 1fr',
            gap: 8,
            '& div:nth-child(1)': {
                gridColumnStart: 1,
                gridRowStart: 1,
            },
            '& div:nth-child(2)': {
                gridColumnStart: 2,
            },
            '& div:nth-child(3)': {
                gridColumnStart: 2,
            },
        }),
        control: () => ({
            ...theme.utils.flexbox.centered,
            width: '100%',
            borderRadius: 100,
            backgroundColor: theme.palette.disabled.light,
        }),
        loadingIndicator: (provided) => ({
            ...provided,
            marginRight: '-14px !important',
        }),
        indicatorSeparator: () => ({
            display: 'none',
        }),
        indicatorContainer: () => ({
            padding: 0,
        }),
    }
}

const AsyncSearchbar = ({
    className,
    maxDigits = 50,
    placeholder,
    searchResults,
    searchInput,
    onEnterKeyPress,
    fetchLimit = 15,
    apiCall,
    ...props
}) => {
    const theme = useTheme()
    const customStyles = searchbarCustomStyle(theme)
    const [savedInput, setSavedInput] = useState('')

    const getSearchResults = useCallback(async (name) => {
        if (!name || name.length >= maxDigits) {
            return []
        }
        try {
            const {
                data: { data },
            } = await apiCall({
                name: name,
                page: 1,
                limit: fetchLimit
            })
            searchResults && searchResults(data)
            return data
        } catch (error) {
            console.log(error)
        }
    }, [])

    const debouncedGetSearchResults = useCallback(
        asyncDebounce(getSearchResults, 500),
        []
    )

    const handleInputChange = (inputValue, { action }) => {
        if (
            action !== 'input-blur' &&
            action !== 'menu-close' &&
            inputValue?.length <= maxDigits
        ) {
            setSavedInput(inputValue)
            searchInput && searchInput(inputValue)
        }
    }

    const handleKeyPress = (e) => {
        if (e?.keyCode === 13 && onEnterKeyPress) {
            onEnterKeyPress()
        }
    }

    const classes = useStyles({})
    return (
        <div className={cx(classes.searchbar, className)} {...props}>
            <AsyncSelect
                // isLoading={true}
                menuIsOpen={false}
                inputValue={savedInput}
                loadOptions={debouncedGetSearchResults}
                controlShouldRenderValue={false}
                styles={customStyles}
                placeholder={placeholder}
                onKeyDown={handleKeyPress}
                onInputChange={handleInputChange}
                components={{
                    ValueContainer: CustomContainer,
                    DropdownIndicator: CustomDropdown,
                }}
            />
        </div>
    )
}

const CustomContainer = ({ children, classes, ...props }) => (
    <components.ValueContainer {...props}>
        <SearchIcon />
        {children}
    </components.ValueContainer>
)

const CustomDropdown = ({ children, ...props }) => (
    <components.DropdownIndicator {...props}>
        {children}
        <hr />
    </components.DropdownIndicator>
)

export default AsyncSearchbar

AsyncSearchbar.propTypes = {
    className: PropTypes.string,
    maxDigits: PropTypes.number,
    placeholder: PropTypes.string,
    searchResults: PropTypes.func,
    searchInput: PropTypes.func,
    onEnterKeyPress: PropTypes.func,
    onLoad: PropTypes.func,
    fetchLimit: PropTypes.number,
    apiCall: PropTypes.func,
}
