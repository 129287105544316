export const themeNames = {
    light: 'light',
    dark: 'dark',
}

export const DEFAULT = 'default'
export const SUCCESS = 'success'
export const FAILURE = 'fail'
export const PENDING = 'pending'
export const PAYMENTS_API_DELAY = 60000
