import { createUseStyles } from 'react-jss'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import SidePopover from './SidePopover'
import {
    BackIcon,
    BurgerRoundIcon,
    FacebookFrameIcon,
    LogoutIcon,
    QuestionmarkMonoIcon,
    SettingIcon,
    TelegramFrameIcon,
    TwitterMonoFrameIcon,
} from '../theme/icons'
import SocialBarNoColor from './SocialBarNoColor'
import MenuUserInfo from './MenuUserInfo'
import MenuItem from './MenuItem'
import { logout, selectUser } from '../store/slices/user'
import { ROUTE_LOGIN } from '../utilities/constants'
import { useNavigate } from 'react-router-dom'
import {
    EXT_ACCEPTABLE_USE_POLICY,
    EXT_COMMUNITY_GUIDELINES,
    EXT_COPYRIGHT,
    EXT_PRIVACY_POLICY_COOKIE_POLICY,
    EXT_SUPPORT_LINK,
    EXT_TERMS_CONDITIONS,
} from '../utilities/constants/externalLinks'

const useStyles = createUseStyles((theme) => ({
    menu: {
        ...theme.utils.grid.centered,
        gridTemplateColumns: '1fr',
        gridTemplateRows: 'min-content 1fr auto',
        width: '100%',
        overflow: 'scroll',
        '&::-webkit-scrollbar': {
            display: 'none',
        },
    },
    header: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing * 3,
        padding: theme.spacing * 3,
        '& svg': {
            transform: ({ isOpen }) =>
                isOpen ? 'rotate(180deg)' : 'rotate(0deg)',
            transition: 'ease-in-out 400ms',
        },
    },
    itemWrapper: {
        ...theme.utils.flexbox.start,
        flexDirection: 'column',
        alignSelf: 'start',
        padding: [0, theme.spacing * 2],
        marginBottom: theme.spacing * 3,
    },
    footer: {
        ...theme.utils.flexbox.centered,
        flexDirection: 'column',
        gap: theme.spacing * 2,
        backgroundColor: theme.palette.primary.background,
        height: 140,
        color: theme.palette.common.white,
        fontSize: 12,
        '& p': {
            color: theme.palette.common.white,
            fontWeight: 700,
        },
    },
    links: {
        display: 'grid',
        gridAutoFlow: 'column',
        gridAutoColumns: 'auto',
        gap: 4,
        position: 'relative',
        whiteSpace: 'nowrap',

        '& a': {
            color: theme.palette.common.white,
            fontWeight: 400,
        },
    },
}))

const Menu = ({ callback, isOpen }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const user = useSelector(selectUser)

    const handleLogout = async () => {
        await dispatch(logout())
        navigate(ROUTE_LOGIN)
    }

    const classes = useStyles({ isOpen })
    return (
        <>
            <BurgerRoundIcon onClick={() => callback()} />
            <menu>
                <SidePopover
                    side={'right'}
                    isOpen={isOpen}
                    bodyClassName={classes.menu}
                    onClose={callback}
                >
                    <div className={classes.header}>
                        <BackIcon onClick={callback} />
                        <MenuUserInfo user={user} />
                    </div>
                    <div className={classes.itemWrapper}>
                        <MenuItem
                            icon={
                                <QuestionmarkMonoIcon
                                    data-color
                                    width={24}
                                    stroke={'#ccc'}
                                />
                            }
                            text={'Support and Assistance'}
                            topDivider={true}
                            callback={() =>
                                window.open(EXT_SUPPORT_LINK, '_blank')
                            }
                            onCloseCb={callback}
                        />
                        <MenuItem
                            icon={
                                <QuestionmarkMonoIcon
                                    data-color
                                    width={24}
                                    stroke={'#ccc'}
                                />
                            }
                            text={'Acceptable Use Policy'}
                            topDivider={true}
                            callback={() =>
                                window.open(EXT_ACCEPTABLE_USE_POLICY, '_blank')
                            }
                            onCloseCb={callback}
                        />
                        <MenuItem
                            icon={
                                <QuestionmarkMonoIcon
                                    data-color
                                    width={24}
                                    stroke={'#ccc'}
                                />
                            }
                            text={'Community Guidelines'}
                            topDivider={true}
                            callback={() =>
                                window.open(EXT_COMMUNITY_GUIDELINES, '_blank')
                            }
                            onCloseCb={callback}
                        />
                        <MenuItem
                            icon={
                                <QuestionmarkMonoIcon
                                    data-color
                                    width={24}
                                    stroke={'#ccc'}
                                />
                            }
                            text={'Copyright and Trademark Policy'}
                            topDivider={true}
                            callback={() =>
                                window.open(EXT_COPYRIGHT, '_blank')
                            }
                            onCloseCb={callback}
                        />
                        <MenuItem
                            icon={
                                <SettingIcon
                                    data-color
                                    width={24}
                                    stroke={'#ccc'}
                                />
                            }
                            text={'Settings'}
                            topDivider={true}
                            to={'/settings'}
                            onCloseCb={callback}
                        />
                        <MenuItem
                            icon={
                                <LogoutIcon
                                    data-color
                                    width={24}
                                    stroke={'#ccc'}
                                />
                            }
                            text={'Log Out'}
                            topDivider={true}
                            callback={handleLogout}
                            onCloseCb={callback}
                        />
                    </div>
                    <div className={classes.footer}>
                        <p>© 2021 Happyfans</p>
                        <div className={classes.links}>
                            <a href={EXT_TERMS_CONDITIONS} target={'_blank'}>
                                Terms & Conditions
                            </a>
                            /
                            <a
                                href={EXT_PRIVACY_POLICY_COOKIE_POLICY}
                                target={'_blank'}
                            >
                                Privacy Policy & Cookie Policy
                            </a>
                        </div>
                        <SocialBarNoColor
                            icons={[
                                <a
                                    href={'https://twitter.com/HappyFansToken'}
                                    target={'_blank'}
                                >
                                    <TwitterMonoFrameIcon />
                                </a>,
                                <a
                                    href={
                                        'https://www.facebook.com/HappyFansToken'
                                    }
                                    target={'_blank'}
                                >
                                    {' '}
                                    <FacebookFrameIcon />
                                </a>,
                                <a
                                    href={'https://t.me/happyfanstoken'}
                                    target={'_blank'}
                                >
                                    {' '}
                                    <TelegramFrameIcon />
                                </a>,
                            ]}
                        />
                    </div>
                </SidePopover>
            </menu>
        </>
    )
}

export default Menu
