import React from 'react'
import { createUseStyles } from 'react-jss'
import UserInfo from '../../../homepage/posts-feed/post/post-header/user-info'
import { StarIcon } from '../../../../theme/icons'
import colors from '../../../../theme/colors'
import { useNavigate } from 'react-router-dom'
import Back from '../../../../components/Back'
import { ROUTE_MESSAGES } from '../../../../utilities/constants'
import {useDispatch, useSelector} from 'react-redux'
import {selectPresence, setActiveChat} from '../../../../store/slices/conversations'
import {setActiveChannel} from "../../../../store/slices/messages";

const useStyles = createUseStyles((theme) => ({
    chatPanelHeader: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'start',
        padding: 16,
        backgroundColor: '#ffffff',
        gap: 8,
        borderBottom: '2px solid #F3F3F3',
        [theme.mediaQueries.mUp]: {
            borderRadius: [0, 12, 0, 0],
        },
    },
    labelWrapper: {
        display: 'flex',
        alignSelf: 'center',
        padding: [0, 8],
        borderRight: '1px solid #E1E1E1',
    },
    creatorLabel: {
        ...theme.utils.flexbox.centered,
        gap: 4,
        padding: 6,
        borderRadius: 100,
        backgroundColor: colors.quaternary[100],
        fontSize: 12,
        fontWeight: 500,
        color: colors.quaternary[400],
        '& span': {
            lineHeight: 0,
        },
        '& svg': {
            width: 12,
            height: 12,
        },
    },
    back: {
        placeSelf: 'center',
    },
}))

const ChatPanelHeader = ({ currentConversation, isMobile }) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const chatPresence = useSelector(selectPresence)
    const isOnline = chatPresence[currentConversation?.channel]?.occupancy > 1

    const handleBack = () => {
        navigate(ROUTE_MESSAGES)
    }

    const classes = useStyles()
    return (
        <div className={classes.chatPanelHeader}>
            {isMobile && (
                <Back callback={handleBack} className={classes.back} />
            )}
            {currentConversation?.is_creator && (
                <div className={classes.labelWrapper}>
                    <div className={classes.creatorLabel}>
                        <StarIcon />
                        <span>Creator</span>
                    </div>
                </div>
            )}
            <UserInfo
                name={currentConversation?.display_name}
                username={currentConversation?.username}
                avatar={currentConversation?.avatar?.[128]}
                category={currentConversation?.category?.name}
                isVerified={currentConversation?.is_verified}
                avatarWidth={40}
                avatarHeight={40}
                isOnline={isOnline}
            />
        </div>
    )
}

export default ChatPanelHeader
