import {createSelector, createSlice} from '@reduxjs/toolkit'
import { normalize, schema } from 'normalizr'

const sliceName = 'conversations'
const conversationSchema = new schema.Entity(sliceName)
const chatUsersSchema = new schema.Entity('chatUsers')
const chatPresenceSchema = new schema.Entity('chatPresence')

const conversationsSlice = createSlice({
    name: sliceName,
    initialState: {
        channels: [],
        activeChat: null,
        ppvModalOpen: false,
        tipModalOpen: false,
        myConversations: { byId: {}, allIds: [], total: 0, currentPage: 0, lastPage: null, perPage: null},
        presence: {},
        isPresenceFetched: false,
        chatUsers: { byId: {}, allIds: []},
        isChatLoaded: false,

    },
    reducers: {
        setIsChatLoaded: (state, action) => {
            state.isChatLoaded = action.payload
        },
        setActiveChat: (state, action) => {
            state.activeChat = action.payload
        },
        setPpvModalOpen: (state, action) => {
            state.ppvModalOpen = action.payload
        },
        setChatTipModalOpen: (state, action) => {
            state.tipModalOpen = action.payload
        },
        setMyConversation: (state, action) =>{
            const sliceSection = state.myConversations
            if(action.payload.data.length) {
                const { entities, result: allIds } = normalize(action.payload.data, [conversationSchema])
                sliceSection.allIds = +action.payload.current_page === 1
                    ? allIds : [...sliceSection.allIds, ...allIds]
                sliceSection.byId = +action.payload.current_page === 1
                    ? entities[conversationSchema.key] : { ...sliceSection.byId, ...entities[conversationSchema.key] }
                sliceSection.currentPage = +action.payload.current_page
                sliceSection.lastPage = +action.payload.last_page
                sliceSection.total = +action.payload.total
                sliceSection.perPage = +action.payload.per_page
            }

        },
        setChatPresence: (state, action) => {
            state.presence = action.payload
            state.isPresenceFetched = true
        },
        setChannelPresence: (state, action) => {
            state.presence[action.payload.channel] = action.payload.occupancy
        },
        setChatUsers: (state, action) =>{
            if(!action.payload.data.length) {
                state.chatUsers.allIds = []
                state.chatUsers.byId = {}
            } else{
                const { entities, result: allIds } = normalize(action.payload.data, [chatUsersSchema])
                state.chatUsers.allIds =
                    !action.payload.currentPage
                        ? allIds
                        : [...state.chatUsers.allIds, ...allIds]
                state.chatUsers.byId =
                    !action.payload.currentPage
                        ? entities[chatUsersSchema.key]
                        : { ...state.chatUsers.byId, ...entities[chatUsersSchema.key] }
            }

        },
        setConversationMediaCounter: (state, action) => {
            const currentMediaCount = state.myConversations.byId[action.payload.userID]?.mediaCount
            if(currentMediaCount){
                state.myConversations.byId[action.payload.userID].mediaCount += action.payload.mediaCount
            }
        }
    },

})

const selectSelf = (state) => state[sliceName]

export const selectActiveChat = createSelector(
    selectSelf,
    (state) => state?.activeChat
)
export const selectPpvModalOpen = createSelector(
    selectSelf,
    (state) => state?.ppvModalOpen
)
export const selectTipModalOpen = createSelector(
    selectSelf,
    (state) => state?.tipModalOpen
)

export const selectMyConversations = createSelector(
    selectSelf,
    (state) => state.myConversations
)

export const selectMyConversationsByUUID = createSelector(
    selectSelf,
    (state) => {
        let conversationByUUID = {}
        state.myConversations.allIds.forEach(id => {
            conversationByUUID[state.myConversations.byId[id].channel] =  state.myConversations.byId[id]
        })
        return conversationByUUID
    }
)

export const selectChatUsersByUUID = createSelector(
    selectSelf,
    (state) => state.chatUsers
)

export const selectMyChannelIDs = createSelector(
    selectSelf,
    (state) => Object.values(state.myConversations.byId)
        .map(({channel}) => channel)
)

export const selectMyChannelLastReadTimetokens = createSelector(
    selectSelf,
    (state) => Object.values(state.myConversations.byId)
        .map(({channel, lastReadTimetoken, createdAt}) => ({channel, lastReadTimetoken, createdAt}))
)

export const selectIsChatLoaded = createSelector(
    selectSelf,
    (state) => state.isChatLoaded
)

export const selectIsPresenceFetched = createSelector(
    selectSelf,
    (state) => state.isPresenceFetched
)

export const selectPresence = createSelector(
    selectSelf,
    (state) => state.presence
)

export const {
    setActiveChat,
    setChatTipModalOpen,
    setPpvModalOpen,
    setMyConversation,
    setChatUsers,
    setIsChatLoaded,
    setChatPresence,
    setConversationMediaCounter,
    setChannelPresence,
} = conversationsSlice.actions
export default conversationsSlice.reducer
