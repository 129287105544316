import surprisedEmoji from '../../asset/images/tips/surprisedEmoji.png'
import winkingEmoji from '../../asset/images/tips/winkingEmoji.png'
import sunglassesEmoji from '../../asset/images/tips/sunglassesEmoji.png'
import loveEmoji from '../../asset/images/tips/loveEmoji.png'
import happyEmoji from '../../asset/images/tips/happyEmoji.png'

export const tipAssets = {
    1: {
        image: winkingEmoji,
        name: 'Good / $1.00',
        value: 1,
    },
    5: {
        image: happyEmoji, name: 'Amazing! / $5.00', value: 5,
    },
    10: {
        image: surprisedEmoji, name: 'Wow / $10.00', value: 10,
    },
    20: {
        image: loveEmoji, name: 'I’m HAPPY Fan / $20.00', value: 20,
    },
    'custom': {
        image: sunglassesEmoji, name: 'Custom Tip', value: 'custom',
    },
}

