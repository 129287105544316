import { createUseStyles } from 'react-jss'
import Divider from './Divider'
import { ArrowRightIcon } from '../theme/icons'
import { NavLink } from 'react-router-dom'
import colors from '../theme/colors'

const useStyles = createUseStyles((theme) => ({
    divider: {
        width: '100%',
        height: 1,
    },
    tab: {
        display: 'grid',
        gridTemplateColumns: 'auto 1fr auto',
        gridColumnGap: 24,
        alignItems: 'center',
        cursor: 'pointer',
        fontWeight: 500,
        '& span': {
            transition: 'ease-in-out 300ms',
            color: theme.palette.label.color,
        },
        '&:hover': {
            textDecoration: 'none',
            '& span': {
                color: theme.palette.text.primary,
            },
        },
    },
    tabSelected: {
        '& span': {
            color: theme.palette.text.primary,
        },
    },
}))

const MenuItem = ({
    icon,
    to,
    callback = Function.prototype,
    text,
    withArrow = false,
    topDivider = true,
    onCloseCb = Function.prototype,
}) => {
    const classes = useStyles()

    return (
        <>
            {topDivider && <Divider className={classes.divider} />}
            {to ? (
                <NavLink
                    onClick={onCloseCb}
                    className={(navData) =>
                        navData.isActive
                            ? `${classes.tab} ${classes.tabSelected}`
                            : `${classes.tab}`
                    }
                    to={to}
                >
                    {icon}
                    <span>{text}</span>
                    {withArrow && <ArrowRightIcon data-color width={34} />}
                </NavLink>
            ) : (
                <div
                    className={classes.tab}
                    onClick={() => {
                        callback()
                        onCloseCb()
                    }}
                >
                    {icon}
                    <span>{text}</span>
                    {withArrow && <ArrowRightIcon data-color width={34} />}
                </div>
            )}
        </>
    )
}

export default MenuItem
