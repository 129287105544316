import {createUseStyles} from 'react-jss'
import cx from 'classnames'
import React, {useState} from 'react'
import {AccordionArrowIcon} from '../../../../theme/icons'
import Checkbox from '../../../../components/Checkbox'
import Button from '../../../../components/Button'
import UserAvatar from "../../../homepage/posts-feed/post/post-header/user-info/user-avatar";

const useStyles = createUseStyles((theme) => ({
    keepSupportingAccordion: {
        ...theme.utils.flexbox.start,
        flexDirection: 'column',
        color: theme.palette.secondary.main,
        width: '100%',
        gap: theme.spacing * 2,
        padding: 2,
    },
    panel: {
        ...theme.utils.flexbox.spaceBetween,
        width: '100%',
        background: '#FCFCFC',
        boxShadow: '0px 1px 3px rgba(20, 20, 42, 0.1), 0px 0px 1px rgba(20, 20, 42, 0.05)',
        borderRadius: 16,
        minHeight: 72,
        //risky biscuits
        [theme.mediaQueries.mUp]: {
            padding: [0, theme.spacing],
        },
    },
    iconWrapper: ({accordionOpen}) => ({
        ...theme.utils.flexbox.centered,
        border: `1px solid #F3F3F3`,
        borderRadius: 12,
        minWidth: 48,
        minHeight: 48,
        margin: theme.spacing,
        cursor: 'pointer',
        '& svg': {
            transform: accordionOpen ? 'rotate(180deg)' : 'rotate(0deg)',
            transition: 'ease-in-out 400ms',
        },
    }),
    itemWrapper: ({accordionOpen}) => ({
        ...theme.utils.flexbox.centered,
        width: '100%',
        flexDirection: 'column',
        gap: theme.spacing * 2,
        overflow: 'hidden',
        maxHeight: accordionOpen ? 2000 : 0,
        transition: 'ease-in-out 400ms',
    }),
    accordionLabel: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: '100%',
        width: '100%',
        fontSize: 14,
        zIndex: 2,
        [theme.mediaQueries.mUp]: {
            padding: [0, theme.spacing],
        },
    },
    mobileWrapper: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
    },
    flexCenteredWrapper: {
        ...theme.utils.flexbox.centered,
        height: '100%',
        gap: theme.spacing * 2,
        borderBottom: '1px solid #F3F3F3',
        minHeight: 48,
        [theme.mediaQueries.mUp]: {
            borderBottom: 0,
        },
    },
    flexSpacedWrapper: {
        ...theme.utils.flexbox.spaceBetween,
        width: '100%',
        height: '100%',
        gap: theme.spacing * 2,
        borderBottom: '1px solid #F3F3F3',
        padding: [4, theme.spacing],
        [theme.mediaQueries.mUp]: {
            padding: 0,
            borderBottom: 0,
        },
    },
    token: {
        ...theme.utils.flexbox.centered,
        gap: theme.spacing,
        fontWeight: 700,
        fontSize: 16,
        padding: [0, theme.spacing],
        whiteSpace: 'nowrap',
        color: theme.palette.text.primary,
        '& svg': {
            height: 40,
        },
        [theme.mediaQueries.mUp]: {
            padding: 0
        },
    },
    buttonWrapper: {
        padding: theme.spacing * 2,
    },
    button: {
        width: '100%',
        minHeight: 48,
        maxHeight: 48,
        '& span': {
            fontSize: 16,
        },
        [theme.mediaQueries.mUp]: {
            minWidth: 143,
        },
    },
    checkbox: {
        width: '100%',
        color: theme.palette.common.black,
        gridTemplateColumns: 'min-content auto',
        justifyContent: 'center',
        fontWeight: 400,
        margin: 0,
    },
    bold: {
        fontWeight: 700,
    },
    divider: {
        height: '100%',
        borderRight: '1px solid #E1E1E1',
    },
    totalDivider: {
        height: '100%',
        borderRight: '1px solid #F3F3F3',
        [theme.mediaQueries.mUp]: {
            height: '50%',
        },
    },
    total: {
        width: '100%',
        ...theme.utils.flexbox.centered,
        gap: theme.spacing / 2,
        fontSize: 16,
        color: theme.palette.primary.middle,
        fontWeight: 700,
        '& p': {
            fontSize: 16,
            fontWeight: 700,
            color: theme.palette.secondary.main,
        },
    },
}))

const currency = '$'
const KeepSupportingAccordion = ({
                                     token,
                                     totalAmount = 0,
                                     labelClassname,
                                     onClick,
                                     className,
                                     children,
                                     itemsClassName,
                                     isMobile,
                                     selectAllCallback,
                                     isSelectAllChecked,
                                     isDisabled = false,
                                     expiredSubscriptions,
                                     defaultOpen
                                 }) => {
    const [accordionOpen, setAccordionOpen] = useState(defaultOpen)


    const renderLabelMobile = () => {
        return (
            <div className={classes.mobileWrapper}>
                <div className={classes.flexSpacedWrapper}>
                    <div className={classes.token}>
                        <UserAvatar
                            avatar={token?.avatar?.[128]}
                        />
                        {token?.name} ({token?.symbol})
                    </div>
                    <div className={classes.iconWrapper} onClick={() => setAccordionOpen(!accordionOpen)}>
                        <AccordionArrowIcon/>
                    </div>
                </div>
                <div className={classes.flexCenteredWrapper}>
                    <div className={classes.total}>
                        <p>Total:</p>
                        {currency}
                        {totalAmount}
                    </div>
                    <div className={classes.totalDivider}/>
                    <Checkbox
                        onClick={selectAllCallback}
                        className={classes.checkbox}
                        value={true}
                        checked={isSelectAllChecked}
                    >
                        <span>Select all <span className={classes.bold}>{expiredSubscriptions?.length}</span></span>
                    </Checkbox>
                </div>
                <div className={classes.buttonWrapper}>
                    <Button
                        className={classes.button}
                        disabled={isDisabled}
                        type={'submit'}>
                        Pay Now
                    </Button>
                </div>
            </div>
        )
    }

    const renderLabelDesktop = () => {
        return (
            <>
                <div className={classes.flexCenteredWrapper}>
                    <div className={classes.token}>
                        <UserAvatar
                            avatar={token?.avatar?.[128]}
                        />
                        {token?.name} ({token?.symbol})
                    </div>
                    <div className={classes.divider}/>
                    <Checkbox
                        onClick={selectAllCallback}
                        className={classes.checkbox}
                        value={true}
                        checked={isSelectAllChecked}
                    >
                        <span>Select all <span className={classes.bold}>{expiredSubscriptions?.length}</span></span>
                    </Checkbox>
                </div>
                <div className={classes.flexCenteredWrapper}>
                    <div className={classes.total}>
                        <p>Total:</p>
                        {currency}
                        {totalAmount}
                    </div>
                    <div className={classes.totalDivider}/>
                    <Button
                        className={classes.button}
                        disabled={isDisabled}
                        type={'submit'}>
                        Pay Now
                    </Button>
                    <div className={classes.iconWrapper} onClick={() => setAccordionOpen(!accordionOpen)}>
                        <AccordionArrowIcon/>
                    </div>
                </div>
            </>
        )
    }

    const classes = useStyles({isMobile, accordionOpen})
    return (
        <div className={cx(classes.keepSupportingAccordion, className)} onClick={onClick}>
            <div className={cx(classes.panel, labelClassname)}>
                <div className={classes.accordionLabel}>
                    {isMobile
                        ? renderLabelMobile()
                        : renderLabelDesktop()
                    }

                </div>
            </div>
            <div className={cx(classes.itemWrapper, itemsClassName)}>
                {children}
            </div>
        </div>
    )
}

export default KeepSupportingAccordion
