export const pubnubConfig = {
    publishKey: process.env.REACT_APP_PUBNUB_PUBLISH_KEY,
    subscribeKey: process.env.REACT_APP_PUBNUB_SUBSCRIBE_KEY,
}

export const MESSAGE_TYPES = {
    TEXT: 'text',
    MEDIA: 'media',
    TIP: 'payment',
    EXPIRED: 'expired',
}

export const CHAT_MEDIA_TYPES = {
    IMAGE: 'chat_image',
    VIDEO: 'chat_video',
}
