import React, {useMemo} from 'react'
import {createUseStyles} from 'react-jss'
import colors from '../../../../../theme/colors'
import dayjs from 'dayjs'

const useStyles = createUseStyles((theme) => ({
    notificationsPanelSection: {
        padding: 24,
    },
    line: {
        position: 'relative',
        height: 1,
        width: '100%',
        backgroundColor: colors.greyscale[300],
    },
    text: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        padding: 8,
        backgroundColor: colors.greyscale[150],
        fontSize: 10,
        fontWeight: 500,
        letterSpacing: 0.5,
        color: colors.greyscale[400],
    },
}))

const ChatSection = ({date, today, yesterday}) => {

    const label = useMemo(() => {
        if (date === today) {
            return 'TODAY'
        } else if (date === yesterday) {
            return 'YESTERDAY'
        } else {
            return dayjs(date, 'YYYYMMDD').format('YYYY-MM-DD')
        }
    }, [date])

    const classes = useStyles()
    return (
            <div className={classes.notificationsPanelSection}>
                <div className={classes.line}>
                    <span className={classes.text}>{label}</span>
                </div>
            </div>
    )
}

export default ChatSection
