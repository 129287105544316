import { createUseStyles } from 'react-jss'
import MediaPost from './media-post'
import LightGallery from 'lightgallery/react'
import lgVideo from 'lightgallery/plugins/video'
import { useCallback, useEffect, useMemo, useRef } from 'react'
import cx from 'classnames'

const useStyles = createUseStyles((theme) => ({
    root: {},
    gallery: {
        padding: 16,
        display: 'grid',
        justifyContent: 'center',
        gridTemplateColumns: '136px 136px',
        gridAutoRows: '136px',
        gap: 16,
        [theme.mediaQueries.xsUp]: {
            gridTemplateColumns: '162px 162px',
            gridAutoRows: '162px',
        },
        [theme.mediaQueries.sUpM]: {
            rowGap: 16,
            columnGap: 24,
            gridTemplateColumns: '188px 188px 188px',
            gridAutoRows: '188px',
        },
        [theme.mediaQueries.mUp]: {
            gridTemplateColumns: '196px 196px 196px',
            gridAutoRows: '196px',
        },
    },
}))

const MediaGallery = ({ items, classname }) => {
    const lightGallery = useRef()

    const onInit = useCallback((detail) => {
        if (detail) {
            lightGallery.current = detail.instance
        }
    }, [])

    const openGallery = useCallback((slideIndex) => {
        lightGallery.current.openGallery(slideIndex)
    }, [])

    const mediaRemapped = useMemo(() => {
        return items.map((media) =>
            media.type === 'video'
                ? {
                      video: {
                          source: [
                              {
                                  src: media.original,
                                  type: 'video/mp4',
                              },
                          ],
                          attributes: {
                              preload: false,
                              controls: true,
                              controlsList: 'nodownload',
                              onContextMenu: 'return false;',
                          },
                      },
                  }
                : {
                      id: media.id,
                      size: '1920-1080',
                      src: media.original,
                      thumb: media[720],
                  }
        )
    }, [items])

    const gallerySettings = {
        plugins: [lgVideo],
        mode: 'lg-fade',
        keyPress: true,
        download: false,
        enableDrag: false,
        onInit,
        licenseKey: process.env.REACT_APP_LG_PLUGIN ?? '0000-0000-000-0000',
        dynamic: true,
        dynamicEl: mediaRemapped,
    }

    useEffect(() => {
        lightGallery.current.refresh(mediaRemapped)
    }, [mediaRemapped])

    const classes = useStyles()

    return (
        <>
            <div className={cx(classname, classes.gallery)}>
                {items.map(({ id, subtitle, user, ...rest }, idx) => {
                    return (
                        <MediaPost
                            key={id}
                            subtitle={subtitle}
                            user={user}
                            onClick={() => openGallery(idx)}
                            {...rest}
                        />
                    )
                })}
            </div>
            <LightGallery {...gallerySettings} />
        </>
    )
}

export default MediaGallery
