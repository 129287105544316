import { createUseStyles } from 'react-jss'
import { BurgerRoundIcon, HomeRoundIcon, SearchRoundIcon } from '../theme/icons'
import React, { useState } from 'react'
import Menu from './Menu'
import { useNavigate } from 'react-router-dom'
import { ROUTE_HOME } from '../utilities/constants'
import { useSelector } from 'react-redux'
import { selectUser } from '../store/slices/user'
import SearchbarMenuMobile from '../pages/search-page/search-menu-mobile'
import NotificationsMenuIcon from '../pages/notifications-page/NotificationsMenuIcon'
import MessagesMenuIcon from '../pages/messages-page/MessagesMenuIcon'

const useStyles = createUseStyles((theme) => ({
    headerMobile: {
        ...theme.utils.flexbox.spaceBetween,
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 1030,
        gridTemplateColumns: '1fr auto',
        padding: [0, theme.spacing * 2],
        alignItems: 'center',
        justifyContent: 'center',
        height: theme.sizing.headerHeightMobile,
        boxShadow: 'inset 0px 1px 0px rgba(0, 0, 0, 0.05)',
        backgroundColor: '#FCFCFC',
        '& img': {
            maxWidth: 160,
        },
        [theme.mediaQueries.mUp]: {
            padding: [0, 72],
        },
    },
    userInfo: {
        ...theme.utils.flexbox.centered,
        gap: 8,
    },
}))

const HeaderMobile = () => {
    const navigate = useNavigate()
    const loggedUser = useSelector(selectUser)
    const [isSearchbarMenuOpen, setIsSearchbarMenuOpen] = useState(false)
    const [activePanel, setActivePanel] = useState(null)

    const handlePanelQueue = (panel) => {
        if (activePanel === panel) {
            setActivePanel(null)
        } else {
            setActivePanel(panel)
        }
    }

    const classes = useStyles()

    return (
        <header className={classes.headerMobile}>
            <div className={classes.userInfo}>
                <MessagesMenuIcon
                    callback={() => handlePanelQueue('messages')}
                    isOpen={activePanel === 'messages'}
                />
                <NotificationsMenuIcon
                    callback={() => handlePanelQueue('notifications')}
                    isOpen={activePanel === 'notifications'}
                />
                <HomeRoundIcon onClick={() => navigate(ROUTE_HOME)} />
                {loggedUser && (
                    <SearchRoundIcon
                        onClick={() =>
                            setIsSearchbarMenuOpen(!isSearchbarMenuOpen)
                        }
                    />
                )}
                {loggedUser && (
                    <Menu
                        callback={() => handlePanelQueue('menu')}
                        isOpen={activePanel === 'menu'}
                    />
                )}
            </div>
            <SearchbarMenuMobile
                isOpen={isSearchbarMenuOpen}
                onClose={() => setIsSearchbarMenuOpen(false)}
            />
        </header>
    )
}

export default HeaderMobile
