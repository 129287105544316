import { useCallback, useEffect, useMemo, useRef } from 'react'
import { createUseStyles } from 'react-jss'
import LightGallery from 'lightgallery/react'
import { Splide, SplideSlide } from '@splidejs/react-splide'
import PostDescription from '../post-description'
import ResponsiveVideo from '../../../../../../components/ResponsiveVideo'
import ResponsiveImage from '../../../../../../components/ResponsiveImage'
import lgVideo from 'lightgallery/plugins/video'

const useStyles = createUseStyles((theme) => ({
    content: {
        display: 'grid',
        gap: 8,
        gridTemplateRows: 'auto 220px',
        [theme.mediaQueries.xsUp]: {
            gridTemplateRows: 'auto 460px auto',
        },
        [theme.mediaQueries.mUp]: {
            gridTemplateRows: 'auto 460px auto',
        },
    },
    slide: {},
    carousel: {},
}))

const PostGalleryContent = ({ content, media }) => {
    const lightGallery = useRef()

    const openGallery = useCallback((slideIndex) => {
        lightGallery.current.openGallery(slideIndex)
    }, [])

    const onInit = useCallback((detail) => {
        if (detail) {
            lightGallery.current = detail.instance
        }
    }, [])

    const mediaRemapped = useMemo(() => {
        return media.map((media) =>
            media.type === 'video'
                ? {
                      video: {
                          source: [
                              {
                                  src: media.original,
                                  type: 'video/mp4',
                              },
                          ],
                          attributes: {
                              preload: false,
                              controls: true,
                              controlsList: 'nodownload',
                              onContextMenu: 'return false;',
                          },
                      },
                  }
                : {
                      id: media.id,
                      size: '1920-1080',
                      src: media.original,
                      thumb: media[720],
                  }
        )
    }, [media])

    const classes = useStyles()

    const gallerySettings = {
        plugins: [lgVideo],
        mode: 'lg-fade',
        keyPress: true,
        download: false,
        enableDrag: false,
        dynamic: true,
        licenseKey: process.env.REACT_APP_LG_PLUGIN ?? '0000-0000-000-0000',
        dynamicEl: mediaRemapped,
        onInit,
    }

    useEffect(() => {
        lightGallery.current.refresh(mediaRemapped)
    }, [mediaRemapped])

    /* Todo: change arrows style and pass it to <Splide/> */
    /*renderControls={() => (
        <div className="splide__arrows">
            <div className=" splide__arrow--prev" role="button">
                <---
            </div>
            <div className="splide__arrow--next" role="button">
                --->
            </div>
        </div>
    )}*/

    const carouselOptions = {
        pagination: false,
        keyboard: false,
        fixedHeight: 460,
    }
    const onCarouselClickHandler = (slider, slide, event) => {
        openGallery(slide.index)
    }

    return (
        <div className={classes.content}>
            <PostDescription text={content} />
            <Splide
                onClick={onCarouselClickHandler}
                options={carouselOptions}
                className={classes.carousel}
            >
                {media.map(({ type, original, ...rest }, idx) => {
                    return (
                        <SplideSlide key={idx}>
                            {type === 'video' ? (
                                <ResponsiveVideo
                                    fullHeight={true}
                                    video={original}
                                />
                            ) : (
                                <ResponsiveImage
                                    fit={'cover'}
                                    media={{ original, ...rest }}
                                    alt={''}
                                />
                            )}
                        </SplideSlide>
                    )
                })}
            </Splide>
            <LightGallery ref={lightGallery} {...gallerySettings} />
        </div>
    )
}

export default PostGalleryContent
