import React, {createContext} from 'react';

export const PusherContext = createContext(null);

const PusherProvider = ({children, pusher}) => {
    return (
        <PusherContext.Provider value={{pusher}}>
            {children}
        </PusherContext.Provider>
    );
};

export default PusherProvider;