import { createUseStyles } from 'react-jss'
import colors from '../../../../theme/colors'
import { useState } from 'react'
import Box from '../../../../components/Box'
import UserAvatar from '../../../homepage/posts-feed/post/post-header/user-info/user-avatar'
import { useSelector } from 'react-redux'
import { selectUser } from '../../../../store/slices/user'
import { VerifiedBadgeIcon } from '../../../../theme/icons'
import RevenueTable from './revenue-table'
import Tabs from '../../../../components/Tabs'
import SpentTable from './spent-table'

const useStyles = createUseStyles((theme) => ({
    root: {
        background: colors.common.white,
        boxShadow:
            '0px 1px 3px rgba(20, 20, 42, 0.1), 0px 0px 1px rgba(20, 20, 42, 0.05)',
        borderBottomLeftRadius: 12,
        borderBottomRightRadius: 12,
        padding: '16px',
        position: 'relative',
        marginBottom: 16,
    },
    resume: {
        padding: '16px',
        display: 'grid',
        gridTemplateColumns: '48px 1fr auto',
        gridColumnGap: 8,
        alignItems: 'center',
    },
    userInfos: {
        '& h4': {
            fontSize: 16,
            fontWeight: 600,
            color: theme.palette.text.primary,
        },
        '& p': {
            fontSize: 12,
            color: colors.greyscale[500],
        },
    },
    total: {
        '& p': {
            fontSize: 10,
            color: '#808080',
            fontWeight: 500,
            textAlign: 'right',
        },
        '& h3': {
            fontSize: 24,
            fontWeight: 500,
            color: theme.palette.primary.background,
            lineHeight: '28px',
            letterSpacing: '0.75px',
        },
    },
    tabSelector: {
        display: 'grid',
        gridTemplateColumns: 'min-content',
        marginBottom: 16,
        '& > div': {
            display: 'grid',
            gridTemplateColumns: '90px 90px',
            borderRadius: 6,
            backgroundColor: theme.palette.grey[200],
        },
    },
    tabLink: {
        textTransform: 'none',
        border: 'none',
        padding: [8, 16],
        color: theme.palette.input.placeholder,
        fontWeight: 500,
    },
    activeTabLink: {
        extend: 'tabLink',
        color: theme.palette.common.white,
        backgroundColor: theme.palette.secondary.main,
        borderRadius: 6,
    },
}))

const RevenueAndSpent = () => {
    const classes = useStyles()
    const user = useSelector(selectUser)
    const [activeTab, setActiveTab] = useState(user?.is_creator ? 0 : 1)

    return (
        <>
            <div className={classes.root}>
                {user?.is_creator && (
                    <Tabs
                        activeTab={activeTab}
                        setActiveCallback={setActiveTab}
                        titles={['Revenue', 'Spent']}
                        tabLinksClassName={classes.tabSelector}
                        tabLinkClassName={classes.tabLink}
                        tabLinkActiveClassName={classes.activeTabLink}
                    />
                )}

                <Box classNames={classes.resume}>
                    <UserAvatar username={user?.username} avatar={user?.avatar?.[128]} />
                    <div className={classes.userInfos}>
                        <h4>
                            {user?.display_name}{' '}
                            {user?.is_verified && (
                                <VerifiedBadgeIcon height={14} />
                            )}
                        </h4>
                        <p>@{user?.username}</p>
                    </div>
                    <div className={classes.total}>
                        <p>Total {activeTab === 0 ? 'Revenue' : 'Spent'}</p>
                        <h3>
                            ${' '}
                            {activeTab === 0
                                ? user?.total_revenue_amount_usd_value?.toFixed(2)
                                : user?.total_spent_amount_usd_value?.toFixed(2)
                            }
                        </h3>
                    </div>
                </Box>
            </div>

            {activeTab === 0 ? <RevenueTable /> : <SpentTable />}
        </>
    )
}

export default RevenueAndSpent
