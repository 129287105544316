import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useForm, FormProvider } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { DevTool } from '@hookform/devtools'
import {
    setSignupStatus,
    signupAttempt,
    signup,
    selectSignupStatus,
    updateUser,
    selectUser,
} from '../../../../store/slices/user'
import { handleApiError } from '../../../../utilities/helpers'
import { SIGNUP_STATUSES, validationSchema } from './signupModel'
import Divider from '../../../../components/Divider'
import SocialBarColor from '../../../../components/SocialBarColor'
import StepSwitch from './step-switch'
import { unwrapResult } from '@reduxjs/toolkit'
import { useSearchParams } from 'react-router-dom'
import { FAN_REFERRAL } from '../../../../utilities/constants'

const SignupForm = () => {
    const dispatch = useDispatch()
    const step = useSelector(selectSignupStatus)
    const user = useSelector(selectUser)
    const [searchParams, setSearchParams] = useSearchParams()

    const [signupData, setSignupData] = useState({})
    const formMethods = useForm({
        shouldUnregister: true,
        mode: 'onBlur',
        reValidateMode: 'onChange',
        nativeValidation: false,
        defaultValues: {},
        resolver: yupResolver(validationSchema[step]),
    })
    const { handleSubmit, control, setError, setFocus } = formMethods

    const attemptToSignup = async (values) => {
        try {
            const data = await dispatch(signupAttempt(values))
            unwrapResult(data)
            await dispatch(updateUser(values))
            dispatch(setSignupStatus(SIGNUP_STATUSES.roleChoice))
            setSignupData(values)
        } catch (error) {
            handleApiError({
                isReduxError: true,
                error,
                callbackOnFieldError: setError,
                callbackOnFocusError: setFocus,
            })
        }
    }

    const completeSignup = async (values) => {
        const dataToSend = {
            ...signupData,
            ...values,
            ...(searchParams.get(FAN_REFERRAL) && {
                [FAN_REFERRAL]: searchParams.get(FAN_REFERRAL),
            }),
        }
        try {
            const data = await dispatch(signup(dataToSend))
            unwrapResult(data)
        } catch (error) {
            handleApiError({
                error,
                callbackOnFieldError: setError,
            })
        }
    }

    const goNextStep = (values, stepToSet) => {
        dispatch(updateUser({ ...user, ...values }))
        setSignupData((signupData) => ({ ...signupData, ...values }))
        dispatch(setSignupStatus(stepToSet))
    }

    const onSubmitHandler = (values) => {
        switch (step) {
            case SIGNUP_STATUSES.roleChoice:
                return goNextStep(values, SIGNUP_STATUSES.categoriesChoice)
            case SIGNUP_STATUSES.categoriesChoice:
                return goNextStep(values, SIGNUP_STATUSES.firstFollowChoice)
            case SIGNUP_STATUSES.firstFollowChoice:
                return completeSignup(values)
            default:
                return attemptToSignup(values)
        }
    }
    return (
        <FormProvider {...formMethods}>
            <form onSubmit={handleSubmit(onSubmitHandler)}>
                <StepSwitch />
                {!step && (
                    <>
                        <Divider text={'OR'} />
                        <SocialBarColor />
                    </>
                )}
                {process.env.NODE_ENV === 'development' && (
                    <DevTool control={control} />
                )}
            </form>
        </FormProvider>
    )
}

export default SignupForm
