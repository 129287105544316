import {createUseStyles, useTheme} from 'react-jss'
import React, {useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import UserInfo from "../../homepage/posts-feed/post/post-header/user-info";
import Label from "../../../components/Label";
import Button from "../../../components/Button";
import {useWindowSize} from "../../../hooks/useWindowSize";
import {setSubscriptionModalOpen, setUnsubscribeModalOpen} from "../../../store/slices/app";
import {useDispatch} from "react-redux";
import UnsubscribeButton from "../../creator-page/creator-cover/unsubscribe-button";

const useStyles = createUseStyles((theme) => ({
    searchResultItem: {
        display: 'flex',
        gridRowGap: theme.spacing * 2,
        gridColumnGap: theme.spacing,
        justifyContent: 'space-between',
        alignItems: 'center',
        flexWrap: 'wrap',
        padding: theme.spacing * 2,
        minHeight: 80,
        width: '100%',
        borderRadius: 16,
        boxShadow: ' 0px 1px 3px rgba(20, 20, 42, 0.1), 0px 0px 1px rgba(20, 20, 42, 0.05)',
        '&:hover': {
            boxShadow: '0px 16px 24px rgba(20, 20, 42, 0.1), 0px 0px 1px rgba(20, 20, 42, 0.05)',
        },
        [theme.mediaQueries.mUp]:{
            display: 'grid',
            gridTemplateColumns: 'auto auto',
        }
    },
    actionWrapper: {
        display: 'flex',
        gap: theme.spacing,
        alignItems: 'center',
    },
    label: {
        maxHeight: 32,
    },
    button: {
        flexGrow: 1,
        maxHeight: 40,
        '& span': {
            fontSize: 14,
            letterSpacing: 0.75,
        },
        [theme.mediaQueries.mUp]: {
            maxWidth: 221,
        }
    },
    divider: {
        height: 16,
        borderRight: `1px solid ${theme.palette.divider.external}`
    }
}))


const SearchResultItem = ({user}) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const {width} = useWindowSize()
    const [isMobile, setIsMobile] = useState(width <= 448)
    const canReactivate = user?.subscription?.status === 'inactive'

    useEffect(() => {
        setIsMobile(width <= 448)
    }, [width])

    const renderCorrectButton = () => {
        return (
            <Button
                onClick={() => dispatch(
                    user?.subscription?.status === 'active'
                        ? setUnsubscribeModalOpen({isOpen: true, user})
                        : setSubscriptionModalOpen({isOpen: true, user})
                )}
                className={classes.button}
                variant={user?.subscription?.status === 'active' ? 'ghost' : 'filled'}
            >
                {user?.subscription?.status === 'active'
                    ? 'Unsubscribe'
                    : canReactivate
                        ? 'Reactivate'
                        : 'Subscribe'}</Button>
        )
    }

    const classes = useStyles({})
    return (
        <div className={classes.searchResultItem}>
            <UserInfo
                avatar={user?.avatar?.[128]}
                name={user?.display_name}
                username={user?.username}
                category={user?.category?.name}
                usernameMaxWidth={120}
                isOnline={user?.is_online}
            />
            <div className={classes.actionWrapper}>
                <Label color={'secondary'} isLight={true} className={classes.label}>
                    {user?.followings_count ?? 0} Following
                </Label>
                {!isMobile &&
                    <>
                        <div className={classes.divider}/>
                        {renderCorrectButton()}
                    </>
                }
            </div>
            {isMobile && renderCorrectButton()}
        </div>
    )
}

export default SearchResultItem
