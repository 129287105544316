import React from 'react'
import ContentLoader from 'react-content-loader'
import Box from '../../../../../components/Box'

const SubscriptionCardLoader = ({ classNames, ...rest }) => {
    return (
        <Box classNames={classNames}>
            <ContentLoader
                width={'100%'}
                primarycolor="#d9d9d9"
                secondarycolor="#ecebeb"
                {...rest}
            >
                <rect x="48" y="8" rx="3" ry="0" width="88" height="6" />
                <rect x="48" y="26" rx="3" ry="0" width="52" height="6" />
                <rect x="0" y="60" rx="3" ry="0" width="100%" height="8" />
                <rect x="0" y="88" rx="3" ry="0" width="100%" height="8" />
                <rect x="0" y="118" rx="3" ry="0" width="100%" height="8" />
                <circle cx="20" cy="20" r="20" />
            </ContentLoader>
        </Box>
    )
}

export default SubscriptionCardLoader
