import { createUseStyles } from 'react-jss'
import LogBar from '../../components/LogBar'
import SubscriptionModal from '../../pages/creator-page/subscription-modal'
import {
    selectIsSubscriptionModalOpen,
    selectSubscriptionModalUser,
    setSubscriptionModalOpen,
} from '../../store/slices/app'
import { useDispatch, useSelector } from 'react-redux'

const useStyles = createUseStyles((theme) => ({
    root: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
}))

const SharedLayout = ({ children }) => {
    const classes = useStyles()

    return (
        <div className={classes.root}>
            {children}
            {process.env.NODE_ENV === 'development' && <LogBar />}
        </div>
    )
}

export default SharedLayout
