import React from 'react'
import ChatSection from '../chatSection/ChatSection'
import ItemText from './messageItemVariants/itemText/ItemText'
import ItemMedia from './messageItemVariants/itemMedia/ItemMedia'
import {createUseStyles} from 'react-jss'
import ItemTip from './messageItemVariants/itemTip/ItemTip'
import ItemExpired from './messageItemVariants/itemExpired/ItemExpired'
import ItemDeleted from './messageItemVariants/itemDeleted/ItemDeleted'
import {MESSAGE_TYPES} from "../../../../../utilities/constants/chat";
import {useSelector} from "react-redux";
import {selectUser} from "../../../../../store/slices/user";

const useStyles = createUseStyles((theme) => ({
    messageItem: ({isMine}) => ({
        display: 'flex',
        flexDirection: 'column',
        alignItems: isMine ? 'flex-end' : 'flex-start',
        padding: [12, 16],
    }),
}))

const MessageItem = ({message, user, today, yesterday}) => {
    const me = useSelector(selectUser)
    const type = message?.meta?.type
    const isMine = message?.uuid === me?.pubnub_id
    const userData = isMine ? me : user


    const renderMessageVariant = () => {
        switch (type) {
            case MESSAGE_TYPES.TEXT:
                return (
                    <ItemText
                        message={message}
                        userData={userData}
                    />
                )
            case MESSAGE_TYPES.MEDIA:
                return <ItemMedia message={message} userData={userData}/>
            case MESSAGE_TYPES.TIP:
                return <ItemTip message={message} userData={userData} isMine={isMine}/>
            case MESSAGE_TYPES.EXPIRED:
                return <ItemExpired message={message}/>
            default:
                return <ItemText message={message}/>
        }
    }

    const classes = useStyles({isMine})
    return (
        <>
            {message?.placeholder ? (
                <ChatSection
                    date={message?.placeholder}
                    today={today}
                    yesterday={yesterday}
                />
            ) : (
                <div className={classes.messageItem}>
                    <ItemDeleted
                        message={message}
                        userData={userData}
                    >
                        {renderMessageVariant()}
                    </ItemDeleted>
                </div>
            )}
        </>
    )
}

export default MessageItem
