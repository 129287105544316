import React from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { createBrowserHistory } from 'history'
import { BrowserRouter as Router } from 'react-router-dom'
import App from './App'
import reportWebVitals from './reportWebVitals'
import store from './store'
import { axiosAttachInterceptors } from './utilities/axios/utils'
import { TagManager } from './tagManager'
import PusherProvider from './context/PusherProvider'
import PusherInstance from './services/pusher'

const history = createBrowserHistory({ basename: '/' })
const pusherInstance = PusherInstance.getInstance()
const { requestInterceptor, responseInterceptor } = axiosAttachInterceptors()
TagManager.initialize({ gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID })

render(
    <React.StrictMode>
        <Provider store={store}>
            <PusherProvider pusher={pusherInstance}>
                <Router history={history} basename={'/'}>
                    <App />
                </Router>
            </PusherProvider>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
