import { createUseStyles } from 'react-jss'
import Button from '../../../../../../components/Button'
import colors from '../../../../../../theme/colors'
import { useDispatch } from 'react-redux'
import { useState } from 'react'
import Spinner from '../../../../../../components/Spinner'
import { handleApiError } from '../../../../../../utilities/helpers'
import Divider from '../../../../../../components/Divider'
import WalletToken from '../index'
import Input from '../../../../../../components/Input'
import {
    DELETE_ACCOUNT_MODEL,
    validationSchema,
} from '../../../../delete-account-section/DeleteAccountModel'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { SUCCESS } from '../../../../../../utilities/constants'
import { httpDeleteToken } from '../../../../../../http-requests/wallet'
import { updateWallet } from '../../../../../../store/slices/wallet'
import { setAlertMessage } from '../../../../../../store/slices/app'

const useStyles = createUseStyles((theme) => ({
    root: {
        padding: 16,
        '& span': {
            '& svg': { margin: '32px auto', display: 'block' },
        },
        '& p': {
            marginBottom: 32,
            textAlign: 'center',
            '& b': {
                fontSize: 16,
                fontWeight: 700,
                color: colors.quaternary[500],
            },
        },
    },
    walletTitle: {
        display: 'grid',
        gridTemplateColumns: '1fr auto',
        alignItems: 'center',
        '& p': {
            margin: 0,
            textAlign: 'left',
            '& > strong': {
                fontSize: 14,
                fontWeight: 700,
                color: theme.palette.primary.background,
            },
        },
    },
    divider: {
        width: '100%',
        height: 1,
        color: colors.greyscale[200],
        margin: [16, 0],
    },
    fields: {
        display: 'grid',
        gap: 16,
        margin: [16, 0, 32],
    },
}))

const DeleteToken = ({ token, wallet, walletNetworkId, onCloseCb }) => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const {
        register,
        handleSubmit,
        watch,
        formState: { touchedFields, errors, isSubmitting },
    } = useForm({
        shouldUnregister: true,
        mode: 'onBlur',
        reValidateMode: 'onChange',
        nativeValidation: false,
        defaultValues: {},
        resolver: yupResolver(validationSchema),
    })
    const passwordFilled = watch(DELETE_ACCOUNT_MODEL.password)

    const onDeleteToken = async (values) => {
        try {
            setLoading(true)
            const { data: newNetworkObj } = await httpDeleteToken(
                wallet?.id,
                walletNetworkId,
                token?.id,
                values?.[DELETE_ACCOUNT_MODEL.password]
            )
            const walletNetworks = wallet.wallet_networks?.map((item) => {
                if (+item?.id === +walletNetworkId) {
                    item = { ...newNetworkObj }
                }
                return item
            })
            dispatch(
                updateWallet({
                    wallet: { ...wallet, wallet_networks: [...walletNetworks] },
                })
            )
            dispatch(
                setAlertMessage({
                    message: 'Token successfully removed!',
                    variant: SUCCESS,
                })
            )
            onCloseCb()
        } catch (error) {
            handleApiError({
                isReduxError: false,
                error,
            })
        } finally {
            setLoading(false)
        }
    }

    return (
        <div className={classes.root}>
            {loading && <Spinner />}
            <div className={classes.walletTitle}>
                <p>
                    <strong>Wallet address</strong>
                    <br />
                    {wallet?.address}
                </p>
            </div>
            <Divider className={classes.divider} />
            <WalletToken token={token} />

            <form onSubmit={handleSubmit(onDeleteToken)}>
                <div className={classes.fields}>
                    <Input
                        iconPosition={null}
                        type={'password'}
                        label={'Password'}
                        helpText={
                            'Enter the password to be able to delete your wallet'
                        }
                        placeholder={'Enter your Password'}
                        touched={touchedFields[DELETE_ACCOUNT_MODEL.password]}
                        errors={errors[DELETE_ACCOUNT_MODEL.password]}
                        {...register(DELETE_ACCOUNT_MODEL.password)}
                    />
                </div>

                <Button
                    disabled={isSubmitting || !passwordFilled}
                    width={'100%'}
                    type={'submit'}
                    variant={'filled'}
                >
                    Delete Token
                </Button>
            </form>
        </div>
    )
}

export default DeleteToken
