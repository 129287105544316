import { createUseStyles } from 'react-jss'
import CreatorCover from '../creator-page/creator-cover'
import {
    Link,
    Outlet,
    useParams,
    useNavigate,
    useLocation,
} from 'react-router-dom'
import {
    httpFetchUserByUsername,
    httpFetchUserByUsernamePublic,
} from '../../http-requests'
import React, { useEffect, useState } from 'react'
import Spinner from '../../components/Spinner'
import { ROUTE_HOME, ROUTE_SIGNUP } from '../../utilities/constants'
import PostCreation from '../creator-page/post-creation'
import RouteTabs from '../../components/RouteTabs'
import { useSelector } from 'react-redux'
import { selectUser } from '../../store/slices/user'
import TokenPurchaseBanner from '../creator-page/token-purchase-banner'
import Divider from '../../components/Divider'
import HeaderMobile from '../../components/HeaderMobile'
import Header from '../../components/Header'
import { useWindowSize } from '../../hooks/useWindowSize'
import notFoundUserIcon from '../../asset/images/fanPage/userIcon.png'
import { httpFetchTokens } from '../../http-requests/wallet'
import { handleApiError } from '../../utilities/helpers'
import Popover from '../../components/Popover'
import PaymentError from '../../components/PaymentError'
import PaymentSuccess from '../../components/PaymentSuccess'
import SubscriptionsFanBox from '../user-settings/subscription-settngs/bundle-fan-subscription/SubscriptionsFanBox'

const useStyles = createUseStyles((theme) => ({
    root: {
        display: 'block',
        padding: [16, 8],
        '& aside': {
            display: 'none',
        },
        marginBottom: theme.spacing * 5,
        [theme.mediaQueries.lUp]: {
            padding: [32, 16],
            '& aside': {
                display: 'block',
            },
            display: 'grid',
            gridTemplateColumns: '680px 1fr',
        },
    },
    feed: {
        margin: '0 auto',
        width: '100%',
    },
    sidebar: {
        display: 'none',
        [theme.lUp]: {
            display: 'block',
        },
    },
    anonymousContent: {
        margin: '32px auto',
        textAlign: 'center',
    },
    notFoundUser: {
        display: 'flex',
        alignItems: 'center',
        height: '100%',
        justifyContent: 'center',
        '& > div': {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            '& h5': {
                color: theme.palette.secondary.main,
                [theme.mediaQueries.lUp]: {
                    fontSize: 28,
                },
            },
        },
    },
}))

const FanPage = ({ ...props }) => {
    const loggedUser = useSelector(selectUser)
    let { username } = useParams()
    const navigate = useNavigate()
    const location = useLocation()
    const [happyPrice, setHappyPrice] = useState('0')
    const [currentUser, setCurrentUser] = useState(null)
    const [isLoading, setIsLoading] = useState(true)
    const [isMe, setIsMe] = useState(currentUser?.id === loggedUser?.id)
    //payment success
    const [showPaymentSuccess, setShowPaymentSuccess] = useState(
        location.state?.paymentOperationStatus === 'success'
    )
    //payment error
    const [showPaymentError, setShowPaymentError] = useState(
        location.state?.paymentOperationStatus === 'failure'
    )

    useEffect(() => {
        setIsMe(currentUser?.id === loggedUser?.id)
    }, [currentUser, loggedUser])

    const { width } = useWindowSize()
    const [isMobile, setIsMobile] = useState(width <= 448)

    useEffect(() => {
        setIsMobile(width <= 448)
    }, [width])

    const getUser = async (username) => {
        try {
            const { data } = !loggedUser
                ? await httpFetchUserByUsernamePublic(username)
                : await httpFetchUserByUsername(username)
            setCurrentUser(data)
        } catch (error) {
            /* if (error.response?.status === 404) {
                navigate(ROUTE_NOT_FOUND)
            }*/
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        if (loggedUser?.username === username) {
            setCurrentUser(loggedUser)
            setIsLoading(false)
        } else {
            getUser(username)
        }
    }, [username])

    useEffect(() => {
        const oldSearchParams = window.location.search
        if (currentUser) {
            navigate(`/${username}/post${oldSearchParams}`, { replace: true })
        } else {
            return
        }
    }, [currentUser])

    const getHappyTokenPrice = async () => {
        try {
            const { data } = await httpFetchTokens()
            setHappyPrice(
                data.filter((item) => item.symbol === 'HAPPY')[0].usd_value
            )
        } catch (error) {
            handleApiError({
                isReduxError: false,
                error,
            })
        }
    }

    useEffect(() => {
        getHappyTokenPrice()
    }, [])

    const classes = useStyles()

    return isLoading ? (
        <Spinner overlayFullscreen />
    ) : (
        <>
            {showPaymentError && (
                <Popover
                    onClose={() => setShowPaymentError(!showPaymentError)}
                    maxWidth={360}
                >
                    <PaymentError
                        onClose={() => setShowPaymentError(!showPaymentError)}
                    />
                </Popover>
            )}
            {showPaymentSuccess && (
                <Popover
                    onClose={() => setShowPaymentSuccess(false)}
                    maxWidth={360}
                >
                    <PaymentSuccess
                        onClose={() => setShowPaymentSuccess(false)}
                    />
                </Popover>
            )}
            {loggedUser && (isMobile ? <HeaderMobile /> : <Header />)}
            {currentUser ? (
                <div className={classes.root}>
                    <main className={classes.feed}>
                        <CreatorCover user={currentUser} />
                        <Divider height={0} margin={'12px 0'} />
                        {isMe && currentUser.is_creator && (
                            <PostCreation user={loggedUser} />
                        )}
                        {currentUser.is_creator && (
                            <>
                                {!isMe &&
                                    currentUser?.subscription?.status !==
                                        ('inactive' || 'expired') &&
                                    currentUser?.is_subscribed === false && (
                                        <SubscriptionsFanBox
                                            user={currentUser}
                                        />
                                    )}
                                <RouteTabs
                                    sections={[
                                        {
                                            route: 'post',
                                            title: `${currentUser?.posts_count} Post`,
                                        } /* Todo: find a nice way to map this in routeNames */,
                                        {
                                            route: 'media',
                                            title: `${currentUser?.media_count} Media`,
                                        },
                                    ]}
                                    parentRoute={`/${username}`}
                                />
                                {loggedUser ? (
                                    <Outlet context={currentUser.id} />
                                ) : (
                                    <div className={classes.anonymousContent}>
                                        <Link to={ROUTE_SIGNUP}>
                                            Sign-Up to see posts or media!
                                        </Link>
                                    </div>
                                )}
                            </>
                        )}
                    </main>
                    <aside>
                        <TokenPurchaseBanner amount={happyPrice} />
                    </aside>
                </div>
            ) : (
                <div className={classes.notFoundUser}>
                    <div>
                        <img src={notFoundUserIcon} />
                        <h5>This User could not be found</h5>
                        <Link to={ROUTE_HOME}>Back to the home page</Link>
                    </div>
                </div>
            )}
        </>
    )
}

export default FanPage
