import { createUseStyles } from 'react-jss'
import Media from '../../../../../../../components/Media'
import defaultAvatar from '../../../../../../../asset/images/roundDefaultAvatar.jpg'
import { ROUTE_FANPAGE } from '../../../../../../../utilities/constants'
import { useNavigate } from 'react-router-dom'
import cx from 'classnames'

const useStyles = createUseStyles((theme) => ({
    avatar: {
        position: 'relative',
        boxShadow: ({ withBoxShadow }) =>
            withBoxShadow ? '0px 0px 0px 4px #FCFCFC' : 'none',
        width: ({ width }) => width ?? 48,
        height: ({ height }) => height ?? 48,
        borderRadius: '50%',
        cursor: ({ username }) => (username ? 'pointer' : 'default'),
        backgroundColor: theme.palette.grey[200],
    },
    onlineDot: {
        position: 'absolute',
        bottom: 1,
        right: 1,
        width: ({ dotSize }) => dotSize,
        height: ({ dotSize }) => dotSize,
        borderRadius: '50%',
        backgroundColor: ({isOnline}) => isOnline ?  theme.palette.success.main : theme.palette.grey[300],
        border: `2px solid ${theme.palette.common.white}`,
    },
}))

const UserAvatar = ({
    isOnline = false,
    avatar,
    width,
    height,
    dotSize = 12,
    withBoxShadow = true,
    username,
    customIcon,
}) => {
    const navigate = useNavigate()

    const classes = useStyles({
        width,
        height,
        dotSize,
        withBoxShadow,
        username,
        isOnline
    })
    return (
        <Media
            image={avatar ?? defaultAvatar}
            className={classes.avatar}
            onClick={() =>
                username &&
                navigate(`${ROUTE_FANPAGE.replace(':username', username)}`)
            }
        >
            <span className={classes.onlineDot} />
            {customIcon}
        </Media>
    )
}

export default UserAvatar
