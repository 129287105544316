import { createUseStyles } from 'react-jss'
import colors from '../../../../../theme/colors'
import Box from '../../../../../components/Box'
import UserAvatar from '../../../../homepage/posts-feed/post/post-header/user-info/user-avatar'
import { EditIcon, TrashIcon } from '../../../../../theme/icons'
import Popover from '../../../../../components/Popover'
import { useEffect, useState } from 'react'
import DeleteToken from './delete-token'
import EditToken from './edit-token'
import { useWindowSize } from '../../../../../hooks/useWindowSize'

const useStyles = createUseStyles((theme) => ({
    token: {
        display: 'grid',
        gridTemplateColumns: 'auto 1fr auto auto',
        alignItems: 'center',
        gridColumnGap: 16,
        padding: 12,
        maxHeight: 88,
        marginTop: 16,
        '& > span': {
            fontSize: 16,
            fontWeight: 700,
            color: theme.palette.text.primary,
        },
        '& > svg': {
            cursor: 'pointer',
        },
    },
    overflowVisible:{
        overflowY: "visible"
    }
}))

const WalletToken = ({ token, wallet, walletNetworkId,networkIndex }) => {
    const [showDeletePopover, setShowDeletePopover] = useState(false)
    const [showEditPopover, setShowEditPopover] = useState(false)
    const { width } = useWindowSize()
    const isMobile = width <= 448
    const isHappy = token?.symbol === 'HAPPY'
    const classes = useStyles()

    return (
        <>
            {showDeletePopover && (
                <Popover
                    title={'Delete Token'}
                    maxWidth={539}
                    maxHeight={600}
                    onClose={() => setShowDeletePopover(!showDeletePopover)}
                >
                    <DeleteToken
                        token={token}
                        walletNetworkId={walletNetworkId}
                        wallet={wallet}
                        onCloseCb={() =>
                            setShowDeletePopover(!showDeletePopover)
                        }
                    />
                </Popover>
            )}
            {showEditPopover && (
                <Popover
                    bodyClassName={classes.overflowVisible}
                    title={'Edit Token'}
                    maxWidth={539}
                    maxHeight={isMobile ? 580 : 700}
                    onClose={() => setShowEditPopover(!showEditPopover)}
                >
                    <EditToken
                        token={token}
                        walletNetworkId={walletNetworkId}
                        networkIndex={networkIndex}
                        wallet={wallet}
                        onCloseCb={() => setShowEditPopover(!showEditPopover)}
                    />
                </Popover>
            )}

            <Box classNames={classes.token}>
                <UserAvatar
                    avatar={token?.avatar?.[128]}
                    width={32}
                    height={32}
                />
                <span>
                    {token?.name} ({token?.symbol})
                </span>
                {wallet && !isHappy && (
                    <EditIcon
                        width={20}
                        onClick={() => setShowEditPopover(!showEditPopover)}
                    />
                )}
                {wallet && !isHappy && (
                    <TrashIcon
                        width={20}
                        onClick={() => setShowDeletePopover(!showDeletePopover)}
                    />
                )}
            </Box>
        </>
    )
}

export default WalletToken
