import React from 'react'
import ContentLoader from 'react-content-loader'

const NotificationSettingsLoader = ({ classNames, ...rest }) => {
    return (
        <ContentLoader
            height={450}
            width={'100%'}
            primarycolor="#d9d9d9"
            secondarycolor="#ecebeb"
            {...rest}
        >
            <rect x="8" y="24" rx="3" ry="3" width="100%" height="25" />
            <rect x="8" y="64" rx="3" ry="3" width="100%" height="25" />
            <rect x="8" y="104" rx="3" ry="3" width="100%" height="25" />
            <rect x="8" y="144" rx="3" ry="3" width="100%" height="25" />
            <rect x="8" y="184" rx="3" ry="3" width="100%" height="25" />
            <rect x="8" y="224" rx="3" ry="3" width="100%" height="25" />
            <rect x="8" y="264" rx="3" ry="3" width="100%" height="25" />
            <rect x="8" y="304" rx="3" ry="3" width="100%" height="25" />
            <rect x="8" y="344" rx="3" ry="3" width="100%" height="25" />
            <rect x="8" y="384" rx="3" ry="3" width="100%" height="25" />
        </ContentLoader>
    )
}

export default NotificationSettingsLoader
