import { createUseStyles } from 'react-jss'
import cx from 'classnames'
import logoIcon from './asset/happyPurchaseLogo.png'
import Media from '../../../components/Media'
import Button from '../../../components/Button'
import { SideArrowIcon } from '../../../theme/icons'
import { EXT_TOKEN_BUY } from '../../../utilities/constants/externalLinks'

const useStyles = createUseStyles((theme) => ({
    root: {
        ...theme.utils.flexbox.centered,
        maxWidth: 416,
        maxHeight: 347,
        padding: theme.spacing * 2,
        borderRadius: 8,
        boxShadow:
            '0px 1px 3px rgba(20, 20, 42, 0.1), 0px 0px 1px rgba(20, 20, 42, 0.05)',
        backgroundColor: '#FFF',
    },
    container: {
        ...theme.utils.flexbox.centered,
        flexDirection: 'column',
        borderRadius: 8,
        width: '100%',
        backgroundColor: `#F9F4EF`,
        padding: theme.spacing * 2,
    },
    logo: {
        height: 138,
        width: 110,
    },
    exchangeLogo: {
        height: 20,
        width: 100,
    },
    tokenInfo: {
        ...theme.typography.paragraph,
        color: theme.palette.quaternary.main,
        textAlign: 'center',
        '& p': {
            fontWeight: 700,
            color: theme.palette.primary.background,
        },
    },
    button: {
        marginTop: theme.spacing * 3,
    },
}))

const TokenPurchaseBanner = ({ className, amount }) => {
    const currency = '$'

    const classes = useStyles()
    return (
        <div className={cx(classes.root, className)}>
            <div className={classes.container}>
                <Media image={logoIcon} className={classes.logo} />
                <div className={classes.tokenInfo}>
                    <p>Price Happyfans (HAPPY)</p>
                    <span>
                        {currency}
                        {amount}
                    </span>
                </div>
                <Button
                    width={'100%'}
                    icon={<SideArrowIcon />}
                    className={classes.button}
                    onClick={() => window.open(EXT_TOKEN_BUY, '_blank')}
                >
                    Buy Happy Now
                </Button>
            </div>
        </div>
    )
}

export default TokenPurchaseBanner
