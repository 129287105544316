import { createUseStyles } from 'react-jss'
import React, { useEffect, useState } from 'react'
import { useWindowSize } from '../../../../../hooks/useWindowSize'
import Popover from '../../../../../components/Popover'
import Spinner from '../../../../../components/Spinner'
import Button from '../../../../../components/Button'
import Divider from '../../../../../components/Divider'
import Select from '../../../../../components/Select'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { handleApiError } from '../../../../../utilities/helpers'
import { UNSUBSCRIBE_MODEL, validationSchema } from '../UnsubscribeModel'
import { DevTool } from '@hookform/devtools'
import {
    httpUnsubscribeReasons,
    httpUnsubscribeToUser,
} from '../../../../../http-requests/subscription'
import { useParams } from 'react-router-dom'
import dayjs from 'dayjs'

const useStyles = createUseStyles((theme) => ({
    root: {
        color: theme.palette.secondary.main,
    },
    bold: {
        fontWeight: 700,
    },
    body: {
        ...theme.utils.flexbox.start,
        flexDirection: 'column',
        gap: theme.spacing,
        padding: theme.spacing * 2,
        '& p > span': {
            color: theme.palette.quaternary.main,
        },
    },
    action: {
        ...theme.utils.flexbox.centered,
        gap: theme.spacing * 2,
        borderTop: `1px solid ${theme.palette.grey[300]}`,
        padding: theme.spacing * 2,
    },
    title: {
        fontSize: 28,
        fontWeight: 700,
    },
    button: {
        maxWidth: 186,
    },
    divider: {
        margin: [0, theme.spacing * 2],
    },
    select: {
        height: ({ isSelectOpen }) => isSelectOpen && 250,
        width: '100%',
        '& div': {
            alignItems: 'center',
        },
    },
}))

const UnsubscribeModal = ({ onClose, callBack, user }) => {
    const { width } = useWindowSize()
    const { username } = useParams()
    const [isMobile, setIsMobile] = useState(width <= 448)
    const [isLoading, setIsLoading] = useState(false)
    const [options, setOptions] = useState(false)
    const [isSelectOpen, setIsSelectOpen] = useState(false)

    useEffect(() => {
        fetchUnsubscribeReasons()
    }, [])

    const fetchUnsubscribeReasons = async () => {
        try {
            setIsLoading(true)
            const { data } = await httpUnsubscribeReasons()
            setOptions(data)
            setIsLoading(false)
        } catch (error) {
            handleApiError({
                isReduxError: false,
                error: error,
                callbackOnFieldError: setError,
            })
        }
    }

    const formMethods = useForm({
        shouldUnregister: false,
        mode: 'all',
        reValidateMode: 'all',
        nativeValidation: false,
        defaultValues: {},
        resolver: yupResolver(validationSchema),
    })
    const {
        handleSubmit,
        control,
        setError,
        formState: { errors, isSubmitting, isValid },
    } = formMethods

    const onSubmit = async (formValues) => {
        try {
            setIsLoading(true)
            await httpUnsubscribeToUser({
                userID: user.id,
                reason: formValues?.unsubscribe_reason?.value,
            })
            onClose()
            window.location.reload()
        } catch (error) {
            handleApiError({
                isReduxError: false,
                error: error,
                callbackOnFieldError: setError,
            })
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        setIsMobile(width <= 448)
    }, [width])

    const classes = useStyles({ isSelectOpen })
    return (
        <Popover
            onClose={onClose}
            title={'Subscriptions'}
            maxWidth={isMobile ? 326 : 420}
        >
            {isLoading && <Spinner />}
            <div className={classes.root}>
                <div className={classes.body}>
                    <p>
                        By canceling your subscriptions, you will still be able
                        to see the contents of the following creator until the
                        expiration date, scheduled for:{' '}
                        <span>
                            {dayjs()
                                .add(1, 'month')
                                .startOf('month')
                                .format('MM/DD/YY')}
                        </span>
                    </p>
                </div>
                <Divider className={classes.divider} />
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className={classes.body}>
                        <p>Why do you want to unsubscribe?</p>
                        <Select
                            onMenuOpen={() => setIsSelectOpen(true)}
                            onMenuClose={() => setIsSelectOpen(false)}
                            className={classes.select}
                            isClearable
                            control={control}
                            placeholder={'Select reason'}
                            name={UNSUBSCRIBE_MODEL.unsubscribeReason}
                            options={options}
                            errors={errors[UNSUBSCRIBE_MODEL.unsubscribeReason]}
                        />
                    </div>
                    <div className={classes.action}>
                        <Button
                            width={'100%'}
                            variant={'ghost'}
                            onClick={onClose}
                            className={classes.button}
                        >
                            Back
                        </Button>
                        <Button
                            width={'100%'}
                            type={'submit'}
                            disabled={!isValid || isSubmitting}
                            onClick={callBack}
                            className={classes.button}
                        >
                            Unsubscribe
                        </Button>
                    </div>
                </form>
                {/*<DevTool control={control}/>*/}
            </div>
        </Popover>
    )
}

export default UnsubscribeModal
