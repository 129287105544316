import React, { useEffect, useMemo, useRef, useState } from 'react'
import { createUseStyles } from 'react-jss'
import NotificationsPanelHeader from './panelHeader/NotificationsPanelHeader'
import NotificationsPanelSection from './panelSection/NotificationsPanelSection'
import { useDispatch, useSelector } from 'react-redux'
import {
    fetchNotificationsHistory,
    resetNotificationHistory,
    selectCurrentPage,
    selectIsLoading,
    selectIsNotificationsFetched,
    selectLastPage,
    selectNotifications,
    selectPerPage,
    selectUnreadFilterActive,
    setIsLoading,
} from '../../../store/slices/notifications'
import dayjs from 'dayjs'
import {
    selectAllNotificationsCount,
    selectUnseenNotificationsCount,
} from '../../../store/slices/user'
import NotificationLoader from './notificationLoader/NotificationLoader'

const useStyles = createUseStyles((theme) => ({
    notificationsPanel: {
        zIndex: 2,
        width: '100%',
        minHeight: '90vh',
        maxHeight: '90vh',
        overflowY: 'auto',

        backgroundColor: '#FCFCFC',
        borderRadius: 12,
        boxShadow: ({ isMobilePage }) =>
            !isMobilePage &&
            '0px 8px 16px rgba(20, 20, 42, 0.1), 0px 0px 1px rgba(48, 49, 51, 0.05)',
        paddingBottom: ({ isMobilePage }) => isMobilePage && 80,

        [theme.mediaQueries.mUp]: {
            minHeight: 200,
            maxWidth: 636,
            paddingBottom: ({ extended }) => extended && 80,
        },
    },
    endRef: {
        height: 1,
    },
}))

const NotificationsPanel = ({ extended, isMobilePage, onClose }) => {
    const dispatch = useDispatch()
    const notifications = useSelector(selectNotifications)
    const allNotificationsCount = useSelector(selectAllNotificationsCount)
    const unseenCount = useSelector(selectUnseenNotificationsCount)
    const unreadFilterActive = useSelector(selectUnreadFilterActive)
    const isLoading = useSelector(selectIsLoading)

    const isNotificationsFetched = useSelector(selectIsNotificationsFetched)
    const currentPage = useSelector(selectCurrentPage)
    const lastPage = useSelector(selectLastPage)
    const perPage = useSelector(selectPerPage)

    const [isLoadingMore, setIsLoadingMore] = useState(false)
    const [unreadFilter, setUnreadFilter] = useState(false)
    const limit = 10

    useEffect(() => {
        dispatch(resetNotificationHistory())
        fetchInitial()
    }, [unreadFilterActive])

    const fetchInitial = async () => {
        if (isLoading) return
        try {
            dispatch(setIsLoading(true))
            await dispatch(
                fetchNotificationsHistory({
                    limit: 10,
                    page: 1,
                    ...(unreadFilterActive && { seen: false }),
                })
            )
            setUnreadFilter(!unreadFilter)
            dispatch(setIsLoading(false))
        } catch (e) {
            console.log(e)
        }
    }

    const notificationsByDate = useMemo(() => {
        const dict = {}
        notifications?.forEach((notification) => {
            const date = dayjs(notification?.created_at).format('DD/MM/YYYY')
            if (!dict[date]) {
                dict[date] = []
            }
            dict[date].push(notification)
        })
        return dict
    }, [notifications])

    useEffect(() => {
        window.document.body.style['overflow-y'] = 'hidden' // lock body scroll
        return () => (window.document.body.style['overflow-y'] = 'auto') // unlock body scroll;
    }, [])

    const fetchMore = async () => {
        if (currentPage < lastPage && perPage >= limit && !isLoadingMore) {
            try {
                setIsLoadingMore(true)
                await dispatch(
                    fetchNotificationsHistory({
                        limit,
                        page: currentPage + 1,
                        ...(unreadFilterActive && { seen: false }),
                    })
                )
                setIsLoadingMore(false)
            } catch (e) {
                console.log(e)
            }
        }
    }

    const intersectionObserver = useMemo(
        () =>
            new IntersectionObserver(
                (entries) => {
                    const first = entries[0]
                    if (first.isIntersecting) {
                        fetchMore()
                    }
                },
                {
                    threshold: 0,
                    rootMargin: '0px 0px 100px 0px',
                }
            ),
        [notifications, currentPage, lastPage, isLoadingMore, isLoading]
    )

    const endRef = useRef(null)
    useEffect(() => {
        if (endRef.current) {
            intersectionObserver.observe(endRef.current)
        }
        return () => {
            if (endRef.current) {
                intersectionObserver.unobserve(endRef.current)
            }
        }
    }, [endRef.current, intersectionObserver])

    const classes = useStyles({ isMobilePage, extended })
    return (
        <>
            <div className={classes.notificationsPanel}>
                <NotificationsPanelHeader
                    extended={extended}
                    isMobilePage={isMobilePage}
                    notificationsCount={allNotificationsCount}
                    unreadFilterActive={unreadFilterActive}
                    notReadCount={unseenCount}
                    onClose={onClose}
                />
                {!isNotificationsFetched || isLoading ? (
                    <NotificationLoader rows={limit} />
                ) : (
                    Object.entries(notificationsByDate).map(
                        (notification, index) => {
                            return (
                                <NotificationsPanelSection
                                    extended={extended}
                                    date={notification[0]}
                                    notifications={notification[1]}
                                    key={index}
                                    unreadFilterActive={unreadFilterActive}
                                />
                            )
                        }
                    )
                )}
                {isLoadingMore && <NotificationLoader rows={2} />}
                {!isLoadingMore && !isLoading && (
                    <div className={classes.endRef} ref={endRef} />
                )}
            </div>
        </>
    )
}

export default NotificationsPanel
