import { createUseStyles } from 'react-jss'
import React, { useEffect, useState } from 'react'
import Post from '../../homepage/posts-feed/post'
import Spinner from '../../../components/Spinner'
import { handleApiError } from '../../../utilities/helpers'
import { useDispatch, useSelector } from 'react-redux'
import {
    fetchSinglePost,
    selectTimelinePostsById,
} from '../../../store/slices/timeline'
import notFoundUserIcon from '../../../asset/images/fanPage/userIcon.png'
import { Link } from 'react-router-dom'
import { ROUTE_HOME } from '../../../utilities/constants'

const useStyles = createUseStyles((theme) => ({
    postPage: {
        ...theme.utils.flexbox.centered,
        padding: [16, 8],
        paddingBottom: 100,
        [theme.mediaQueries.mUp]: {
            margin: 24,
            padding: 0,
        },
    },
    notFoundUser: {
        display: 'flex',
        alignItems: 'center',
        height: '60vh',
        justifyContent: 'center',
        '& > div': {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            '& h5': {
                color: theme.palette.secondary.main,
                [theme.mediaQueries.lUp]: {
                    fontSize: 28,
                },
            },
        },
    },
}))

const PostPage = ({ id }) => {
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const post = useSelector(selectTimelinePostsById)

    useEffect(() => {
        fetchPost()
    }, [])

    const fetchPost = async () => {
        try {
            setLoading(true)
            await dispatch(fetchSinglePost({ postID: id }))
            setLoading(false)
        } catch (error) {
            handleApiError({
                isReduxError: false,
                error,
            })
        }
    }

    const classes = useStyles()
    return (
        <div className={classes.postPage}>
            {loading && <Spinner />}
            {!!Object.keys(post)?.length && (
                <Post
                    key={id}
                    id={id}
                    user={post[id]?.user}
                    content={post[id]?.content}
                    date={post[id]?.created_at}
                    totalComments={post[id]?.comments_count ?? 0}
                    totalLikes={post[id]?.likes_count ?? 0}
                    media={post[id]?.media}
                    {...post[id]}
                />
            )}
            {!loading && !Object.keys(post)?.length && (
                <div className={classes.notFoundUser}>
                    <div>
                        <img src={notFoundUserIcon} />
                        <h5>This Post could not be found</h5>
                        <Link to={ROUTE_HOME}>Back to the home page</Link>
                    </div>
                </div>
            )}
        </div>
    )
}

export default PostPage
