import { createUseStyles } from 'react-jss'
import colors from '../../../theme/colors'
import { useEffect, useState } from 'react'
import Popover from '../../../components/Popover'
import AddressStep from './address-step'
import TokenStep from './token-step'
import NetworkStep from './network-step'
import NoWalletBox from './no-wallet'
import WalletBox from './wallet-box'
import { handleApiError } from '../../../utilities/helpers'
import Spinner from '../../../components/Spinner'
import { useDispatch, useSelector } from 'react-redux'
import {
    fetchNetworks,
    fetchTokens,
    fetchWallets,
    resetWallets,
    selectWallets,
    selectWalletsCurrentPage,
    selectWalletsLastPage,
    selectWalletsPerPage,
    setCurrentWallet,
} from '../../../store/slices/wallet'

const useStyles = createUseStyles((theme) => ({
    sectionTitle: {
        fontWeight: 'bold',
        fontSize: '18px',
        lineHeight: '140%',
        color: theme.palette.text.primary,
        marginBottom: 24,
    },
    underDev: {
        height: 438,
        display: 'grid',
        alignItems: 'center',
        '& > div': {
            display: 'grid',
            justifyItems: 'center',
        },
        '& h4': {
            color: theme.palette.text.primary,
            fontSize: 28,
            fontWeight: 700,
        },
        '& p': {
            maxWidth: 390,
            margin: '0 auto',
            textAlign: 'center',
        },
    },
    popoverBody:{
        overflowY: ({popoverStep}) => +popoverStep === 3 ? "visible" : "hidden"
    }
}))

const Wallet = () => {
    const dispatch = useDispatch()
    const [showPopover, setShowPopover] = useState(false)
    const [popoverStep, setPopoverStep] = useState(1) //could be 1,2,3
    const [isNetworkStepEdit, setIsNetworkStepEdit] = useState(false)
    const [isTokenStepEdit, setIsTokenStepEdit] = useState(false)
    const [loading, setLoading] = useState(false)
    const [networkStandards, setNetworkStandards] = useState(null)
    const [currentWalletNetworkIndex,setCurrentWalletNetworkIndex] = useState(null)
    //pagination
    const wallets = useSelector(selectWallets)
    const fetchLimit = 15
    const currentPage = useSelector(selectWalletsCurrentPage)
    const lastPage = useSelector(selectWalletsLastPage)
    const perPage = useSelector(selectWalletsPerPage)
    const fetchMoreCondition = currentPage < lastPage && perPage >= fetchLimit
    const [isLoadingMore, setIsLoadingMore] = useState(false)
    const classes = useStyles({popoverStep})

    useEffect(() => {
        if (!currentPage) {
            fetchInitial()
        }
        dispatch(fetchTokens())
        dispatch(fetchNetworks())
        return () => dispatch(resetWallets())
    }, [])

    const fetchInitial = async () => {
        try {
            setLoading(true)
            await dispatch(fetchWallets({ page: 1, limit: fetchLimit }))
        } catch (error) {
            handleApiError({
                isReduxError: false,
                error,
            })
        } finally {
            setLoading(false)
        }
    }

    const fetchMore = async () => {
        if (fetchMoreCondition) {
            setIsLoadingMore(true)
            await dispatch(
                fetchWallets({ page: currentPage + 1, limit: fetchLimit })
            )
            setIsLoadingMore(false)
        } else if (currentPage < lastPage && perPage < fetchLimit) {
            fetchInitial()
        }
    }

    const popoverStepRenderer = () => {
        switch (popoverStep) {
            case 3:
                return {
                    component: (
                        <TokenStep
                            standards={networkStandards}
                            isNetworkStepEdit={isNetworkStepEdit}
                            isTokenStepEdit={isTokenStepEdit}
                            currentWalletNetworkIndex={currentWalletNetworkIndex}
                            onClose={onCloseWalletProcess}
                        />
                    ),
                    title: 'Add Token',
                }
            case 2:
                return {
                    component: (
                        <NetworkStep
                            setPopoverStepCb={setPopoverStep}
                            isNetworkStepEdit={isNetworkStepEdit}
                            setNetworkStandardsCb={setNetworkStandards}
                        />
                    ),
                    title: 'Add Network',
                }
            default:
                return {
                    component: (
                        <AddressStep setPopoverStepCb={setPopoverStep}/>
                    ),
                    title: 'Add Wallet',
                }
        }
    }

    const onCloseWalletProcess = () => {
        setShowPopover(!showPopover)
        setIsNetworkStepEdit(false)
        setIsTokenStepEdit(false)
        setCurrentWalletNetworkIndex(null)
        setPopoverStep(1)
        dispatch(setCurrentWallet(null))
    }

    const onAddNetworkToWallet = (id) => {
        const selectedWallet = wallets?.filter(
            (wallet) => +wallet.id === +id
        )[0]
        setIsNetworkStepEdit(true)
        setPopoverStep(2)
        dispatch(setCurrentWallet({ ...selectedWallet }))
        setShowPopover(!showPopover)
    }

    const onAddTokenToWallet = (id,networkIndex) => {
        const selectedWallet = wallets?.filter(
            (wallet) => +wallet.id === +id
        )[0]
        setIsTokenStepEdit(true)
        setPopoverStep(3)
        setCurrentWalletNetworkIndex(networkIndex)
        dispatch(setCurrentWallet({ ...selectedWallet }))
        setShowPopover(!showPopover)
    }

    return (
        <>
            {showPopover && (
                <Popover
                    bodyClassName={classes.popoverBody}
                    onClose={onCloseWalletProcess}
                    title={popoverStepRenderer()?.title}
                    maxWidth={539}
                    maxHeight={600}
                >
                    {popoverStepRenderer()?.component}
                </Popover>
            )}
            <p className={classes.sectionTitle}>Crypto Wallet</p>
            {loading && <Spinner />}
            {!!wallets?.length && !loading && (
                <WalletBox
                    wallets={wallets}
                    onAddTokenCb={onAddTokenToWallet}
                    addNetworkCb={onAddNetworkToWallet}
                    fetchInitialCb={fetchInitial}
                    fetchMoreCb={fetchMore}
                    isLoadingMore={isLoadingMore}
                    openCb={() => setShowPopover(!showPopover)}
                />
            )}
            {wallets?.length === 0 && !loading && (
                <NoWalletBox openCb={() => setShowPopover(!showPopover)} />
            )}
        </>
    )
}

export default Wallet
