import { createUseStyles } from 'react-jss'
import {
    BookmarkFilledIcon,
    BookmarkIcon,
    CommentIcon,
    LikeFilledIcon,
    LikeIcon,
    TipsIcon,
} from '../../../../../../theme/icons'
import { POST_ACTION_NAMES} from '../../../../../../utilities/constants'
import { useWindowSize } from '../../../../../../hooks/useWindowSize'
import React, { useEffect, useState } from 'react'
import Tooltip from "../../../../../../components/Tooltip";


const useStyles = createUseStyles((theme) => ({
    actionBar: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: 20,
    },
    iconWrapper: {
        display: 'flex',
        gap: 20,
    },
    action: {
        display: 'grid',
        gridTemplateColumns: 'auto auto auto',
        gap: 4,
        alignItems: 'center',
        color: theme.palette.grey[400],
        cursor: 'pointer',
        padding: 6,
        borderRadius: 6,
        '&  > span': {
            fontWeight: 700,
        },
        '&:hover': {
            color: theme.palette.tertiary.main,
            backgroundColor: theme.palette.secondary.backgroundLight,
            '& > svg > path': {
                stroke: `${theme.palette.tertiary.main}`,
            },
        },
    },
    comments: ({ locked }) => ({
        display: 'grid',
        gridTemplateColumns: 'auto auto auto',
        gap: 4,
        alignItems: 'center',
        color: theme.palette.grey[400],
        padding: 6,
        borderRadius: 6,
        '&  > span': {
            fontWeight: 700,
        },
        ...(!locked && {
            cursor: 'pointer',
            '&:hover': {
                color: theme.palette.primary.main,
                backgroundColor: theme.palette.primary.buttonLight,
                '& > svg > path': {
                    stroke: `${theme.palette.primary.main}`,
                },
            },
        }),
    }),
    like: ({ isLiked, locked }) => ({
        display: 'grid',
        gridTemplateColumns: 'auto auto auto',
        gap: 4,
        alignItems: 'center',
        color: theme.palette.grey[400],

        padding: 6,
        borderRadius: 6,
        '&  > span': {
            fontWeight: 700,
        },
        ...(!locked && {
            cursor: 'pointer',
            '&:hover': {
                color: theme.palette.tertiary.main,
                backgroundColor: theme.palette.secondary.backgroundLight,
                '& > svg > path': {
                    stroke: isLiked
                        ? `transparent`
                        : `${theme.palette.tertiary.main}`,
                },
            },
        }),
    }),
    tip: ({ locked }) => ({
        ...(!locked ? {
            cursor: 'pointer',
            '&:hover': {
                '& > svg > rect:first-child': {
                    fill: `#FFE8CC`,
                },
                '& > svg > path': {
                    stroke: `#FF8A00`,
                },
                '& > svg > rect:not(:first-child)': {
                    stroke: `#FF8A00`,
                },
            },
        } : {
            cursor: 'not-allowed',
        }),
    }),
    save: {
        extend: 'action',
        '&:hover': {
            '& > svg > path': {
                stroke: ({ isSaved }) =>
                    isSaved ? `transparent` : `${theme.palette.tertiary.main}`,
            },
        },
        gridTemplateColumns: 'auto',
    },
}))

const Actionbar = ({
    user,
    locked,
    isSaved,
    isLiked,
    totalLikes,
    totalComments,
    callbacks,
    isMine,
}) => {
    const { width } = useWindowSize()
    const [isMobile, setIsMobile] = useState(width <= 448)
    useEffect(() => {
        setIsMobile(width <= 448)
    }, [width])

    const classes = useStyles({ isSaved, isLiked, locked })
    const hasWallet = user?.has_wallet

    return (
        <div className={classes.actionBar}>
            <div className={classes.iconWrapper}>
                <div
                    className={classes.like}
                    {...(!locked && {
                        onClick: callbacks[POST_ACTION_NAMES.like],
                    })}
                >
                    {isLiked ? (
                        <LikeFilledIcon />
                    ) : (
                        <LikeIcon width={24} height={24} />
                    )}
                    <span>{totalLikes}</span>
                    {!isMobile && ` likes`}
                </div>
                <div
                    className={classes.comments}
                    {...(!locked && {
                        onClick: callbacks[POST_ACTION_NAMES.comment],
                    })}
                >
                    <CommentIcon width={24} height={24} />{' '}
                    <span>{totalComments}</span>
                    {!isMobile && ` comments`}
                </div>
                {!isMine && (
                    <div
                        className={classes.tip}
                        {...(!locked && hasWallet && {
                            onClick: callbacks[POST_ACTION_NAMES.tip],
                        })}
                    >
                        {hasWallet ? <TipsIcon/> : <Tooltip
                            content={'Tips are not available for this user.'}
                        >
                            <TipsIcon/>
                        </Tooltip>}
                    </div>
                )}
            </div>
            <div
                className={classes.save}
                onClick={callbacks[POST_ACTION_NAMES.save]}
            >
                {isSaved ? (
                    <BookmarkFilledIcon width={24} height={24} />
                ) : (
                    <BookmarkIcon />
                )}
            </div>
        </div>
    )
}

export default Actionbar
