import { useFormContext } from 'react-hook-form'
import { createUseStyles, useTheme } from 'react-jss'
import Banner from '../../../../../components/Banner'
import { InfoIcon, SideArrowIcon } from '../../../../../theme/icons'
import FormCard from '../../../../../components/FormCard'
import Checkbox from '../../../../../components/Checkbox'
import { SIGNUP_MODEL } from '../signupModel'
import Spinner from '../../../../../components/Spinner'
import Button from '../../../../../components/Button'
import { useEffect } from 'react'
import {
    remapApiOptions,
    retrieveSingleValueForRs,
    retrieveValuesForRs,
} from '../../../../../utilities/helpers'
import { useSelector } from 'react-redux'
import { selectUser } from '../../../../../store/slices/user'

const useStyles = createUseStyles((theme) => ({
    formContent: {
        display: 'grid',
        gap: 16,
        gridTemplateRows: 'auto 1fr auto',
        height: '100%',
    },
    categoryChoice: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
            display: 'none',
        },
        padding: 3,
    },
    banner: {
        fontSize: 12,
        color: theme.palette.primary.middle,
        backgroundColor: theme.palette.primary.buttonLight,
    },
    fields: {
        position: 'relative',
        minHeight: 240,
        [theme.mediaQueries.mUp]: {
            height: '50vh',
        },
    },
    errors: {
        fontSize: 12,
        color: theme.palette.error.main,
    },
}))

const SignupCategoriesChoice = ({ categories }) => {
    const user = useSelector(selectUser)
    const {
        register,
        reset,
        formState: { errors },
    } = useFormContext()

    useEffect(() => {
        reset({
            [SIGNUP_MODEL.categoryIDs]: retrieveValuesForRs(
                remapApiOptions(categories),
                user[SIGNUP_MODEL.categoryIDs]?.map((item) => ({ id: item }))
            ),
        })
    }, [categories, user])

    const hasErrors = !!errors[SIGNUP_MODEL.categoryIDs]
    const theme = useTheme()
    const classes = useStyles()

    return !categories.length ? (
        <Spinner />
    ) : (
        <div className={classes.formContent}>
            <Banner
                icon={
                    <InfoIcon
                        stroke={
                            hasErrors
                                ? theme.palette.error.main
                                : theme.palette.tertiary.main
                        }
                    />
                }
                variant={hasErrors ? 'error' : 'default'}
                text={
                    hasErrors
                        ? errors[SIGNUP_MODEL.categoryIDs].message
                        : 'Select the categories of content you want to view.'
                }
                className={classes.banner}
            />
            <div className={classes.fields}>
                <div className={classes.categoryChoice}>
                    {categories.map(({ id, name, avatar }, idx) => (
                        <FormCard
                            key={idx}
                            image={avatar ? avatar[128] : null}
                            color={'quaternary'}
                            overline={name}
                            text={'Content'}
                            backgroundSize={'75%'}
                        >
                            <Checkbox
                                id={`${SIGNUP_MODEL.categoryIDs}.${idx}`}
                                value={id}
                                defaultChecked={false}
                                {...register(
                                    `${SIGNUP_MODEL.categoryIDs}.${idx}`
                                )}
                            />
                        </FormCard>
                    ))}
                </div>
            </div>
            <Button
                width={'100%'}
                variant={'filled'}
                icon={<SideArrowIcon />}
                type={'submit'}
            >
                Continue
            </Button>
        </div>
    )
}

export default SignupCategoriesChoice
