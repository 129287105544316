import { createUseStyles, useTheme } from 'react-jss'
import PropTypes from 'prop-types'
import cx from 'classnames'

const useStyles = createUseStyles((theme) => ({
    root: ({
        size,
        variant,
        collapsed,
        icon,
        data,
        disabled,
        width,
        isLight,
        iconPosition,
    }) => ({
        ...theme.utils.flexbox.centered,
        border: '2px solid',
        borderRadius: 12,
        position: 'relative',
        backgroundColor: `${
            theme.palette[data][isLight ? 'buttonLight' : 'main']
        }`,
        borderColor: `${theme.palette[data][isLight ? 'buttonLight' : 'main']}`,

        color:
            variant === 'ghost' || variant === 'borderless' || isLight
                ? theme.palette[data].dark
                : theme.palette.common.white,

        ...(!disabled && {
            cursor: 'pointer',
            '&:hover': {
                backgroundColor:
                    variant === 'ghost'
                        ? 'none'
                        : variant === 'borderless'
                        ? theme.palette[data].hover
                        : theme.palette[data].dark,
                borderColor: theme.palette[data].dark,
                color:
                    variant === 'ghost'
                        ? theme.palette[data].darker
                        : variant === 'borderless'
                        ? theme.palette[data].dark
                        : theme.palette.common.white,
                '& svg': {
                    fill:
                        !disabled &&
                        (variant === 'ghost' || variant === 'borderless') &&
                        `${theme.palette[data].darker} !important`,
                },
            },
        }),

        ...(collapsed && {
            height: theme.spacing * 7,
            width: theme.spacing * 7,
        }),
        ...(!collapsed && {
            width: width,
            aspectRatio: 'unset',
            height: theme.spacing * 6,
        }),

        ...(size === 'large' && {
            height: theme.spacing * 7,
        }),
        ...(size === 'medium' && {
            height: theme.spacing * 6,
        }),
        ...(size === 'small' && {
            height: theme.spacing * 5,
        }),

        ...((variant === 'filled' || variant === 'round') && {
            color: theme.palette.common.white,
        }),

        ...(variant === 'ghost' && {
            backgroundColor: 'unset',
            '&:hover': {
                backgroundColor: theme.palette[data].buttonHover,
            },
        }),
        ...(variant === 'borderless' && {
            backgroundColor: isLight
                ? `${theme.palette[data][isLight ? 'buttonLight' : 'main']}`
                : 'unset',
            border: 0,
        }),
        ...(variant === 'round' && {
            borderRadius: 100,
        }),

        ...(disabled && {
            backgroundColor: theme.palette.disabled.light,
            borderColor:
                variant === 'filled' || variant === 'round'
                    ? theme.palette.disabled.light
                    : theme.palette.disabled.main,
            color: theme.palette.disabled.main,
            '&:hover': {
                backgroundColor: theme.palette.disabled.light,
                color: theme.palette.disabled.main,
                borderColor:
                    variant === 'filled' || variant === 'round'
                        ? theme.palette.disabled.light
                        : theme.palette.disabled.main,
            },
        }),

        '& > span': {
            ...theme.utils.grid.centered,
            whiteSpace: 'nowrap',
            width: '100%',
            textAlign: 'center',
            gridTemplateColumns:
                icon && !collapsed
                    ? `${
                          iconPosition === 'left'
                              ? '24px min-content'
                              : 'min-content 24px'
                      }`
                    : '1fr',
            padding: [0, theme.spacing * 2],
            gridColumnGap: theme.spacing,
            ...(size === 'large' && {
                ...theme.typography.buttonBig,
            }),
            ...(size === 'medium' && {
                ...theme.typography.buttonMedium,
            }),
            ...(size === 'small' && {
                ...theme.typography.buttonSmall,
            }),
            '& svg': {
                ...theme.utils.flexbox.centered,
                width: 22,
                fill: disabled
                    ? `${theme.palette.disabled} !important`
                    : variant !== 'ghost' && variant !== 'borderless'
                    ? `${theme.palette.common.white} !important`
                    : `${theme.palette[data].main} !important`,
            },
        },
    }),
}))

const Button = ({
    children,
    variant = 'filled',
    type = 'button',
    collapsed = false,
    data = 'primary',
    transparency = 'default',
    alert,
    icon,
    iconPosition = 'right',
    size = 'large',
    width = 220,
    height,
    disabled = false,
    isLight = false,
    onClick,
    className,
    ...props
}) => {
    const classes = useStyles({
        size,
        variant,
        collapsed,
        icon,
        data,
        disabled,
        width,
        isLight,
        iconPosition,
    })

    return (
        <button
            className={cx(classes.root, className)}
            {...props}
            type={type}
            onClick={onClick}
            disabled={disabled}
        >
            {collapsed ? (
                <span>{icon}</span>
            ) : icon ? (
                iconPosition === 'left' ? (
                    <span>
                        {icon}
                        <span>{children}</span>
                    </span>
                ) : (
                    <span>
                        <span>{children}</span>
                        {icon}
                    </span>
                )
            ) : (
                <span>{children}</span>
            )}
        </button>
    )
}

export default Button

Button.propTypes = {
    data: PropTypes.oneOf(['primary', 'secondary', 'tertiary']),
    variant: PropTypes.oneOf(['filled', 'ghost', 'borderless', 'round']),
    size: PropTypes.oneOf(['large', 'medium', 'small']),
    iconPosition: PropTypes.oneOf(['left', 'right']),
    transparency: PropTypes.oneOf(['low', 'medium', 'high']),
}
