import { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import { handleApiError } from '../utilities/helpers'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'

const usePagination = ({
    ref,
    callback,
    params = [],
    fetchLimit = 10,
    debug = false,
    dispatchCallback,
    dependencies = [],
} = {}) => {
    const dispatch = useDispatch()
    const isInitialMount = useRef(true)

    const [isIntersecting, setIsIntersecting] = useState(false)
    const [receivedData, setReceivedData] = useState([])
    const [total, setTotal] = useState(0)
    const [previousPage, setPreviousPage] = useState(0)
    const [currentPage, setCurrentPage] = useState(0)
    const [lastPage, setLastPage] = useState(0)
    const [perPage, setPerPage] = useState(10)
    const [isLoading, setIsLoading] = useState(false)
    const [isLoadingMore, setIsLoadingMore] = useState(false)
    const [fetchHistory, setFetchHistory] = useState({})
    const [reset, setReset] = useState(false)

    const fetchMoreCondition =
        currentPage < lastPage && previousPage < currentPage && !isLoadingMore

    useEffect(() => {
        fetchInitial()
    }, [])

    useLayoutEffect(() => {
        if (isInitialMount.current) {
            if (debug) console.log('usePagination debug on')
            isInitialMount.current = false
        } else {
            fetchInitial()
            setReset(true)
        }
    }, dependencies)

    const fetchInitial = async () => {
        try {
            setIsLoading(true)
            const {
                data: { data, current_page, last_page, per_page, total },
            } = await callback({ page: 1, limit: fetchLimit, ...params })
            setReceivedData(data)
            dispatchCallback && dispatch(dispatchCallback(data))
            setFetchHistory({ [current_page]: true })
            setCurrentPage(+current_page)
            setPreviousPage(current_page - 1)
            setLastPage(+last_page)
            setPerPage(+per_page)
            setTotal(+total)
            setReset(false)
            setIsLoading(false)
        } catch (error) {
            handleApiError({
                isReduxError: false,
                error,
            })
        }
    }

    const fetchMore = async (page) => {
        if (fetchMoreCondition) {
            try {
                setIsLoadingMore(true)
                const {
                    data: { data, current_page, last_page, per_page, total },
                } = await callback({ page: page, limit: fetchLimit, ...params })
                setReceivedData([...receivedData, ...data])
                dispatchCallback && dispatch(dispatchCallback(data))
                setFetchHistory(prevHistory => ({ ...prevHistory, [page]: true }))
                setCurrentPage(+current_page)
                setPreviousPage(current_page - 1)
                setLastPage(+last_page)
                setPerPage(+per_page)
                setTotal(+total)
                setReset(false)
                setIsLoadingMore(false)
            } catch (error) {
                handleApiError({
                    isReduxError: false,
                    error,
                })
            }
            setIsLoadingMore(false)
        } else if (currentPage < lastPage && perPage < fetchLimit) {
            await fetchInitial()
        }
    }

    const intersectionObserver = useMemo(
        () =>
            new IntersectionObserver(
                (entries) => {
                    const first = entries[0]
                    if (first.isIntersecting) {
                        setIsIntersecting(true)
                        if (isLoadingMore) return
                        fetchMore(currentPage + 1, false)
                    } else {
                        setIsIntersecting(false)
                    }
                },
                {
                    threshold: 0,
                    rootMargin: '0px 0px 100px 0px',
                }
            ),
        [
            ref,
            receivedData,
            currentPage,
            lastPage,
            perPage,
            total,
            previousPage,
            fetchHistory,
            isLoadingMore,
        ]
    )

    useEffect(() => {
        if (ref.current) {
            intersectionObserver.observe(ref.current)
        }
        return () => {
            if (ref.current) {
                intersectionObserver.unobserve(ref.current)
            }
        }
    }, [ref.current, intersectionObserver])

    return {
        isIntersecting,
        receivedData,
        total,
        currentPage,
        lastPage,
        perPage,
        isLoading,
        isLoadingMore,
        reset,
        fetchHistory,
    }
}
export default usePagination

usePagination.propTypes = {
    ref: PropTypes.object,
    endpoint: PropTypes.func,
    params: PropTypes.array,
    fetchLimit: PropTypes.number,
    debug: PropTypes.bool,
}
