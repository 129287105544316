import React from 'react'
import ContentLoader from 'react-content-loader'
import Box from '../../../../../components/Box'
import { useWindowSize } from '../../../../../hooks/useWindowSize'

const RevenueSpentRowLoader = ({ classNames, ...rest }) => {
    const { width } = useWindowSize()
    const isMobile = width <= 468

    return (
        <Box classNames={classNames}>
            <ContentLoader
                height={40}
                width={'100%'}
                primarycolor="#d9d9d9"
                secondarycolor="#ecebeb"
                {...rest}
            >
                <rect x="0" y="16" rx="0" ry="0" width="100" height="8" />
                <rect x="116" y="16" rx="0" ry="0" width="200" height="8" />
                <rect
                    x="332"
                    y="16"
                    rx="0"
                    ry="0"
                    width={isMobile ? '40' : '100'}
                    height="8"
                />
                {!isMobile && (
                    <>
                        <rect
                            x="448"
                            y="16"
                            rx="0"
                            ry="0"
                            width="70"
                            height="8"
                        />
                        <rect
                            x="528"
                            y="16"
                            rx="0"
                            ry="0"
                            width="70"
                            height="8"
                        />
                        <rect
                            x="620"
                            y="16"
                            rx="0"
                            ry="0"
                            width="70"
                            height="8"
                        />
                    </>
                )}
            </ContentLoader>
        </Box>
    )
}

export default RevenueSpentRowLoader
