import { createUseStyles } from 'react-jss'
import Alert from '../../components/Alert'
import {
    selectAlertMessage,
    selectIsSubscriptionModalOpen,
    selectSubscriptionModalUser,
    setSubscriptionModalOpen,
    toggleAlert,
} from '../../store/slices/app'
import { useDispatch, useSelector } from 'react-redux'
import SubscriptionModal from '../../pages/creator-page/subscription-modal'

const useStyles = createUseStyles((theme) => ({
    root: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
    },
    alerts: {
        pointerEvents: 'none',
        position: 'fixed',
        top: 68,
        left: 8,
        right: 8,
        zIndex: 1029,
        [theme.mediaQueries.mUp]: {
            top: 132,
        },
    },
}))

const PublicLayout = ({ children }) => {
    const dispatch = useDispatch()
    const isSubscriptionModalOpen = useSelector(selectIsSubscriptionModalOpen)
    const user = useSelector(selectSubscriptionModalUser)

    const {
        variant: alertVariant,
        message: alertMessage,
        title: alertTitle,
        visible: alertVisbile,
    } = useSelector(selectAlertMessage)

    const classes = useStyles()

    return (
        <div className={classes.root}>
            <div className={classes.alerts}>
                {alertVisbile && (
                    <Alert
                        visible={alertVisbile}
                        variant={alertVariant}
                        message={alertMessage}
                        title={alertTitle}
                        dismissTimeout={5000}
                        dismissCallback={() =>
                            dispatch(toggleAlert({ visible: false }))
                        }
                    />
                )}
            </div>
            {children}
            {isSubscriptionModalOpen && (
                <SubscriptionModal
                    onClose={() =>
                        dispatch(
                            setSubscriptionModalOpen({
                                isOpen: false,
                                user: null,
                            })
                        )
                    }
                    user={user}
                />
            )}
        </div>
    )
}

export default PublicLayout
