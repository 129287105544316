import { createUseStyles } from 'react-jss'
import { VerifiedBadgeIcon } from '../../../../../../theme/icons'
import { Link } from 'react-router-dom'
import UserAvatar from './user-avatar'
import PropTypes from 'prop-types'
import { ROUTE_FANPAGE } from '../../../../../../utilities/constants'
import cx from 'classnames'

const useStyles = createUseStyles((theme) => ({
    root: ({ direction }) => ({
        display: 'grid',
        ...(direction === 'horizontal'
            ? { gridTemplateColumns: 'auto 1fr' }
            : { gridTemplateRows: 'auto 1fr' }),
        gap: 8,
        alignItems: 'center',
    }),
    avatar: {
        position: 'relative',
        width: 48,
        height: 48,
        borderRadius: '50%',
    },
    onlineDot: {
        position: 'absolute',
        bottom: 1,
        right: 1,
        width: 12,
        height: 12,
        borderRadius: '50%',
        backgroundColor: theme.palette.success.main,
        border: `2px solid ${theme.palette.common.white}`,
    },
    info: {
        maxWidth: 130,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        [theme.mediaQueries.xxsUp]: {
            maxWidth: '100%',
        },
    },
    name: {
        display: 'flex',
        gap: 4,
        alignItems: 'center',
        marginBottom: 4,
        '& > a': {
            fontWeight: 700,
            color: theme.palette.primary.background,
            fontSize: 14,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: 120,
            [theme.mediaQueries.sUpM]: {
                maxWidth: 400,
            },
        },
    },
    usernameWrapper: {
        display: 'flex',
        fontSize: 12,
        color: theme.palette.text.primary,
    },
    username: {
        color: theme.palette.text.secondary,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: ({ usernameMaxWidth }) => usernameMaxWidth,
        [theme.mediaQueries.mUp]: {
            maxWidth: `240px !important`,
        },
    },
    category: {
        color: theme.palette.primary.middle,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: 80,
        [theme.mediaQueries.mUp]: {
            maxWidth: 200,
        },
        '& span': {
            fontWeight: 900,
            padding: [0, 4],
        },
    },
}))

const UserInfo = ({
    name,
    username,
    isVerified = false,
    isOnline = false,
    avatar,
    direction = 'horizontal',
    avatarWidth = 48,
    avatarHeight = 48,
    dotSize,
    category,
    usernameMaxWidth = 116,
    classname,
}) => {
    const classes = useStyles({ direction, usernameMaxWidth })

    return (
        <div className={cx(classes.root, classname)}>
            <UserAvatar
                avatar={avatar}
                isOnline={isOnline}
                height={avatarHeight}
                width={avatarWidth}
                dotSize={dotSize}
                username={username}
            />
            <div className={classes.info}>
                <div className={classes.name}>
                    <Link to={ROUTE_FANPAGE.replace(':username', username) + '/post'}>
                        {name}
                    </Link>
                    {isVerified && <VerifiedBadgeIcon width={20} height={20} />}
                </div>
                <div className={classes.usernameWrapper}>
                    <div className={classes.username}>@{username}</div>
                    {category && (
                        <div className={classes.category}>
                            <span>·</span>
                            {category}
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default UserInfo

UserInfo.propTypes = {
    direction: PropTypes.oneOf(['horizontal', 'vertical']),
}
