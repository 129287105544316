import React, { useEffect, useState } from 'react'
import { createUseStyles } from 'react-jss'
import ChatPanelHeader from './panelHeader/ChatPanelHeader'
import colors from '../../../theme/colors'
import ChatGallery from './chatGallery/ChatGallery'
import Chat from './chat/Chat'

const useStyles = createUseStyles((theme) => ({
    chatPanel: {
        height: '100%',
        maxHeight: `calc(100vh - 72px)`,
        display: 'grid',
        gridTemplateRows: 'min-content min-content 1fr',
        backgroundColor: '#ffffff',
        boxShadow:
            '0px 1px 3px rgba(20, 20, 42, 0.1), 0px 0px 1px rgba(20, 20, 42, 0.05)',
        [theme.mediaQueries.mUp]: {
            minHeight: 0,
            maxHeight: '90vh',
            borderRadius: [0, 12, 0, 0],
        },
    },
    tabsWrapper: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
    },
    tab: {
        height: 46,
        ...theme.utils.flexbox.centered,
        cursor: 'pointer',
        borderBottom: `2px solid ${theme.palette.grey[300]}`,
        fontSize: 10,
        letterSpacing: 0.5,
        fontWeight: 600,
        color: theme.palette.text.inactive,
        [theme.mediaQueries.mUp]: {
            fontSize: 12,
        },
    },
    activeTab: {
        extend: 'tab',
        borderBottomColor: colors.quinary[500],
        color: theme.palette.text.primary,
    },
    hidden: {
        display: 'none !important',
    },
}))

const CHAT_TAB_TYPES = {
    conversation: 'conversation',
    media: 'media'
}

const ChatPanel = ({ channelUUID, currentConversation, isMobile }) => {
    const [activeTab, setActiveTab] = useState(CHAT_TAB_TYPES.conversation)
    const [mediaCount, setMediaCount] = useState(0)

    useEffect(() => {
        setActiveTab(CHAT_TAB_TYPES.conversation)
    }, [channelUUID])

    const classes = useStyles({})
    return (
        <div className={classes.chatPanel}>
            {/* header with user info */}
            <ChatPanelHeader currentConversation={currentConversation} isMobile={isMobile} />
            {/* Tab switch */}
            <div className={classes.tabsWrapper}>
                <div
                    className={
                        activeTab === CHAT_TAB_TYPES.conversation
                            ? classes.activeTab
                            : classes.tab
                    }
                    onClick={() => setActiveTab(CHAT_TAB_TYPES.conversation)}
                >
                    CONVERSATION
                </div>
                <div
                    className={
                        activeTab === CHAT_TAB_TYPES.media ? classes.activeTab : classes.tab
                    }
                    onClick={() => setActiveTab(CHAT_TAB_TYPES.media)}
                >
                    {`MEDIA (${currentConversation?.mediaCount ?? 0})`}
                </div>
            </div>
            {/* standard chat */}
            <Chat
                currentConversation={currentConversation}
                channelUUID={channelUUID}
                classname={activeTab !== CHAT_TAB_TYPES.conversation && classes.hidden}
                isPanelOnTop={activeTab === CHAT_TAB_TYPES.conversation}
                isMobile={isMobile}
            />
            {/* gallery of media */}
            {/* TODO here we need to refresh medias  after message sent */}
            {currentConversation?.channel && <ChatGallery
                user={currentConversation}
                uuid={channelUUID}
                classname={activeTab !== CHAT_TAB_TYPES.media && classes.hidden}
                onLoad={(e) => setMediaCount(e)}
            />}
        </div>
    )
}

export default ChatPanel
