import { createUseStyles } from 'react-jss'
import colors from '../../../../theme/colors'
import Box from '../../../../components/Box'
import walletImg from '../../../../asset/images/settings/wallet.png'
import Button from '../../../../components/Button'

const useStyles = createUseStyles((theme) => ({
    empty: {
        height: 'auto',
        display: 'grid',
        '& > div': {
            display: 'grid',
            justifyItems: 'center',
        },
        '& h4': {
            color: theme.palette.text.primary,
            fontSize: 28,
            fontWeight: 700,
            marginTop: 16,
        },
        '& p': {
            maxWidth: 470,
            margin: '0 auto 40px',
        },
        '& h2': {
            fontWeight: 500,
            fontSize: 16,
            textAlign: 'left',
            color: theme.palette.primary.background,
            paddingBottom: 80,
            letterSpacing: 0.5,

        },
    },
    add: {
        marginTop: 24,
        '& span': {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        '& svg': {
            '& path': {
                stroke: colors.common.white,
            },
        },
    },
}))

const NoWalletBox = ({ openCb }) => {
    const classes = useStyles()

    return (
        <Box classNames={classes.empty}>
            <h2>
                Your Wallet
            </h2>
            <div>
                <img src={walletImg} alt={'wallet-dev'} />
                <h4>No wallets</h4>
                <p>
                    There are no wallets yet, they will appear here once
                    generated
                </p>
                <Button
                    className={classes.add}
                    width={'100%'}
                    variant={'filled'}
                    type={'button'}
                    onClick={openCb}
                >
                    Add wallet
                </Button>
            </div>
        </Box>
    )
}

export default NoWalletBox