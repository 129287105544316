import React from 'react'
import { createUseStyles } from 'react-jss'
import { useDispatch, useSelector } from 'react-redux'
import {
    selectContextMenuOpen,
    setContextMenuOpen,
    setMessageToDelete,
} from '../../../../../store/slices/messages'
import colors from '../../../../../theme/colors'
import { FlagIcon, TrashIcon } from '../../../../../theme/icons'

const useStyles = createUseStyles((theme) => ({
    chatContextMenuMobile: {
        position: 'relative',
        height: 40,
        background: 'white',
    },
    overlay: {
        position: 'absolute',
        height: '100vh',
        bottom: 40,
        width: '100%',
    },
    actionWrapper: {
        height: '100%',
        ...theme.utils.flexbox.centered,
    },
    actionItem: {
        width: '100%',
        height: '100%',
        ...theme.utils.flexbox.centered,
        gap: 8,
        cursor: 'pointer',
        '& p': {
            fontSize: 14,
            color: ' #585858',
            fontWeight: 400,
            letterSpacing: 0.5,
        },
    },
    divider: {
        height: 32,
        width: 1,
        margin: 0,
        backgroundColor: colors.greyscale[300],
    },
}))

const ChatContextMenuMobile = ({ classNames, rows = 1, ...rest }) => {
    const dispatch = useDispatch()
    const message = useSelector(selectContextMenuOpen)

    //TODO CHAT implement when api is ready
    const handleDelete = () => {
        dispatch(setMessageToDelete(message))
    }

    //TODO CHAT implement when api is ready
    // const handleReport = () => {
    //     console.log('report')
    // }

    const classes = useStyles()
    return (
        <div className={classes.chatContextMenuMobile}>
            <div
                className={classes.overlay}
                onClick={() => dispatch(setContextMenuOpen(false))}
            />
            <div className={classes.actionWrapper}>
                <div className={classes.actionItem} onClick={handleDelete}>
                    <TrashIcon height={16} stroke={'#585858'} />
                    <p>Delete</p>
                </div>
                {/*TODO CHAT implement when report messagge is requested*/}
                {/*<div className={classes.divider} />*/}
                {/*<div className={classes.actionItem} onClick={handleReport}>*/}
                {/*    <FlagIcon />*/}
                {/*    <p>Report</p>*/}
                {/*</div>*/}
            </div>
        </div>
    )
}

export default ChatContextMenuMobile
