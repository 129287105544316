import React from 'react'
import logo from '../../src/asset/images/logoSpinner.png'
import { createUseStyles } from 'react-jss'
import cx from 'classnames'

const useStyles = createUseStyles((theme) => ({
    root: {
        zIndex: 14,
        width: ({ size }) => size,
        height: 'auto',
        animation: '$pulse 1.5s ease-in-out infinite',
    },
    '@keyframes pulse': {
        '0%': {
            transform: 'scale(0.95)',
        },
        '70%': {
            transform: 'scale(1.2)',
        },
        '100%': {
            transform: 'scale(0.95)',
        },
    },
    center: {
        position: ({ overlayFullscreen }) =>
            overlayFullscreen ? 'fixed' : 'absolute',
        left: '50%',
        'margin-left': ({ size }) => -size / 2,
        top: '50%',
        'margin-top': ({ size }) => -size / 2,
    },

    overlay: {
        'z-index': 13,
        position: ({ overlayFullscreen }) =>
            overlayFullscreen ? 'fixed' : 'absolute',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        'background-color': 'rgba(255, 255, 255, 0.5)',
    },
}))

const Spinner = ({
    className,
    size = 100,
    color = '#333',
    strokeLineCap = 'square',
    overlay = true,
    overlayFullscreen = false,
    center = true,
    ...props
}) => {
    const classes = useStyles({ size, overlayFullscreen })

    return (
        <>
            {overlay && <div className={classes.overlay} />}
            <img

                src={logo}
                className={cx(
                    classes.root,
                    center && classes.center,
                    className
                )}
                {...props}
            />
        </>
    )
}

export default Spinner
