import { createUseStyles } from 'react-jss'
import Button from '../../../../../components/Button'
import colors from '../../../../../theme/colors'
import { useDispatch } from 'react-redux'
import { useState } from 'react'
import Spinner from '../../../../../components/Spinner'
import { deleteWallet } from '../../../../../store/slices/wallet'
import Divider from '../../../../../components/Divider'
import Input from '../../../../../components/Input'
import {
    DELETE_ACCOUNT_MODEL,
    validationSchema,
} from '../../../delete-account-section/DeleteAccountModel'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

const useStyles = createUseStyles((theme) => ({
    root: {
        padding: 16,
    },
    walletTitle: {
        display: 'grid',
        gridTemplateColumns: '1fr auto',
        alignItems: 'center',
        '& p': {
            margin: 0,
            textAlign: 'left',
            color: theme.palette.primary.middle,
            '& > strong': {
                fontSize: 14,
                fontWeight: 700,
                color: theme.palette.text.primary,
            },
        },
    },
    divider: {
        width: '100%',
        height: 1,
        color: colors.greyscale[200],
        margin: [16, 0],
    },
    fields: {
        display: 'grid',
        gap: 16,
        margin: [16, 0],
    },
}))

const DeleteWallet = ({ wallet, onCloseCb }) => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const {
        register,
        handleSubmit,
        watch,
        formState: { touchedFields, errors, isSubmitting },
    } = useForm({
        shouldUnregister: true,
        mode: 'onBlur',
        reValidateMode: 'onChange',
        nativeValidation: false,
        defaultValues: {},
        resolver: yupResolver(validationSchema),
    })
    const passwordFilled = watch(DELETE_ACCOUNT_MODEL.password)

    const onDeleteWallet = async (values) => {
        try {
            setLoading(true)
            await dispatch(
                deleteWallet({
                    id: wallet?.id,
                    password: values?.[DELETE_ACCOUNT_MODEL.password],
                    onCloseCb: onCloseCb
                })
            )
        } finally {
            setLoading(false)
        }
    }

    return (
        <div className={classes.root}>
            {loading && <Spinner />}
            <div className={classes.walletTitle}>
                <p>
                    <strong>Wallet address</strong>
                    <br />
                    {wallet?.address}
                </p>
            </div>
            <Divider className={classes.divider} />

            <form onSubmit={handleSubmit(onDeleteWallet)}>
                <div className={classes.fields}>
                    <Input
                        iconPosition={null}
                        type={'password'}
                        label={'Password'}
                        helpText={
                            'Enter the password to be able to delete your wallet'
                        }
                        placeholder={'Enter your Password'}
                        touched={touchedFields[DELETE_ACCOUNT_MODEL.password]}
                        errors={errors[DELETE_ACCOUNT_MODEL.password]}
                        {...register(DELETE_ACCOUNT_MODEL.password)}
                    />
                </div>
                <Divider className={classes.divider} />
                <Button
                    disabled={isSubmitting || !passwordFilled}
                    width={'100%'}
                    type={'submit'}
                    variant={'filled'}
                >
                    Delete Wallet
                </Button>
            </form>
        </div>
    )
}

export default DeleteWallet
