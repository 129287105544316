import React from 'react'
import ContentLoader from 'react-content-loader'
import Box from '../../../../../components/Box'

const WalletBoxLoader = ({ classNames, ...rest }) => (
    <Box classNames={classNames}>
        <ContentLoader
            speed={1}
            width={'100%'}
            height={164}
            backgroundColor="#f6f6ef"
            foregroundColor="#e8e8e3"
            style={{ margin: '0' }}
            {...rest}
        >
            <rect x="0" y="0" rx="0" ry="0" width="108" height="12" />
            <rect x="0" y="24" rx="0" ry="0" width="60" height="12" />
            <rect x="500" y="0" rx="0" ry="0" width="80" height="32" />
            <rect x="0" y="48" rx="0" ry="0" width="100%" height="80" />
            <rect x="0" y="160" rx="0" ry="0" width="100%" height="5" />
        </ContentLoader>
    </Box>
)

export default WalletBoxLoader
