import { createUseStyles } from 'react-jss'
import React, { useEffect, useState } from 'react'
import Popover from '../../../components/Popover'
import Button from '../../../components/Button'
import Divider from '../../../components/Divider'
import UserInfo from '../../homepage/posts-feed/post/post-header/user-info'
import { handleApiError } from '../../../utilities/helpers'
import Select from '../../../components/Select'
import { useForm } from 'react-hook-form'
import Box from '../../../components/Box'
import Media from '../../../components/Media'
import { yupResolver } from '@hookform/resolvers/yup'
import dayjs from 'dayjs'
import { components } from 'react-select'
import { httpSendTip, httpUserTokens } from '../../../http-requests'
import colors from '../../../theme/colors'
import { useWindowSize } from '../../../hooks/useWindowSize'
import { TIP_MODEL } from './tipModel'
import { validationSchema } from './tipModel'
import paymentProviderLogo from '../../../asset/images/8-pay-logo.png'
import Input from '../../../components/Input'
import Radio from '../../../components/Radio'
import { DevTool } from '@hookform/devtools'
import { tipAssets } from '../../../utilities/constants/tips'
import { PlayIcon } from '../../../theme/icons'

const useStyles = createUseStyles((theme) => ({
    tipModal: {
        display: 'flex',
        flexDirection: 'column',
        color: theme.palette.secondary.main,
    },
    titleWrapper: {
        display: 'flex',
        flexDirection: 'column',
        gap: 8,
        fontWeight: 700,
        whiteSpace: 'nowrap',
        color: theme.palette.primary.background,
    },
    title: {
        fontWeight: 400,
        '& span': {
            color: colors.primary[500],
        },
    },
    subtitle: {
        fontSize: 12,
    },
    body: {
        display: 'flex',
        flexDirection: 'column',
        gap: 16,
        margin: theme.spacing * 2,
        padding: theme.spacing * 2,
        boxShadow:
            '0px 1px 3px rgba(20, 20, 42, 0.1), 0px 0px 1px rgba(20, 20, 42, 0.05)',
        borderRadius: 16,
    },
    mediaSection: {
        height: '100%',
        ...theme.utils.flexbox.spaceBetween,
        flexDirection: 'column',
        gap: 16,
        [theme.mediaQueries.mUp]: {
            flexDirection: 'row',
        },
    },
    infoWrapper: {
        height: '100%',
        width: '100%',
        flexGrow: 1,
        display: 'flex',
        gap: 16,
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    mediaThumb: ({ isMobile }) => ({
        height: 124,
        minWidth: isMobile ? '100%' : 124,
        borderRadius: 12,
    }),
    action: {
        borderTop: `1px solid ${theme.palette.grey[300]}`,
        padding: theme.spacing * 2,
    },
    divider: {
        margin: 0,
    },
    formWrapper: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing * 2,
        margin: [0, theme.spacing * 2, theme.spacing * 2, theme.spacing * 2],
        '& > p': {
            fontSize: 12,
        },
    },
    paymentProvider: {
        ...theme.utils.flexbox.centered,
        gap: theme.spacing,
        padding: 0,
        fontSize: 12,
        color: theme.palette.text.secondary,
        minHeight: 40,
        background: '#FAFAFA',
        boxShadow: 'none',
        '& div': {
            height: 20,
            width: 43,
        },
    },
    select: {
        display: 'flex',
        flexDirection: 'column',
        '& div > div > div': {
            alignItems: 'center',
        },
        '& div > div > div > div': {
            display: 'flex !important',
            placeContent: 'space-between !important',
            alignItems: 'center !important',
        },
        '& label': {
            marginTop: 0,
            marginBottom: 8,
        },
    },
    customValue: {
        minHeight: 40,
        width: 111,
        borderRadius: 8,
        marginRight: 8,
        color: theme.palette.secondary.dark,
        border: '1px solid #41D3BD',
        fontWeight: 700,
        fontSize: 14,
        letterSpacing: 0.5,
        '& span': {
            marginLeft: 5,
        },
    },
    currency: {
        color: theme.palette.input.placeholder,
    },
    tipSection: {},
    tipItem: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: 48,
        borderBottom: `1px solid #F3F3F3`,
        '& p': {
            fontSize: 16,
            fontWeight: 400,
            letterSpacing: 0.5,
            color: colors.tertiary[800],
        },
    },
    tipInfoWrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 12,
    },
    image: {
        height: 32,
        width: 32,
    },
    radio: {
        marginRight: 4,
    },
    videoWrapper: {
        position: 'relative',
    },
    playIcon: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 24,
        width: 24,
        margin: 8,
        right: 0,
        borderRadius: 6,
        backdropFilter: 'blur(2px)',
        position: 'absolute',
        zIndex: 1,
        backgroundColor: '#FCFCFC1A',
    },
}))

const currency = 'USD'

const TipModal = ({ onClose, user, media, date, onSuccess }) => {
    const [isLoading, setIsLoading] = useState(false)
    const [tokens, setTokens] = useState(false)
    const { width } = useWindowSize()
    const [isMobile, setIsMobile] = useState(width <= 768)

    useEffect(() => {
        setIsMobile(width <= 448)
    }, [width])

    useEffect(() => {
        fetchTokens()
    }, [])

    const fetchTokens = async () => {
        try {
            const { data } = await httpUserTokens(user?.id)
            setTokens(
                data?.map(({ id, symbol, discount_percentage }) => ({
                    value: id,
                    label: symbol,
                    discount: discount_percentage,
                })),
            )
        } catch (error) {
            handleApiError({
                isReduxError: false,
                error,
            })
        }
    }

    const formMethods = useForm({
        shouldUnregister: false,
        mode: 'all',
        reValidateMode: 'all',
        nativeValidation: false,
        defaultValues: {},
        resolver: yupResolver(validationSchema),
    })
    const {
        handleSubmit,
        control,
        watch,
        setError,
        register,
        formState: { errors, touchedFields },
    } = formMethods

    const selectedAmount = watch(TIP_MODEL.amount)

    const CustomSingleValue = ({ children, ...props }) => {
        return (
            <components.SingleValue {...props}>
                {children}
                {props?.data?.discount && (
                    <div className={classes.customValue}>
                        <span>{props?.data?.discount}% Discount</span>
                    </div>
                )}
            </components.SingleValue>
        )
    }

    const onSubmit = async (formValues) => {
        const dataToSend = {
            token_id: formValues?.token?.value,
            usd_value:
                formValues?.amount !== 'custom'
                    ? +formValues?.amount
                    : +formValues?.customAmount,
        }
        try {
            const { data } = await httpSendTip(user?.id, dataToSend)
            onSuccess()
        } catch (error) {
            if (error.response?.status === 302 && error.response?.data?.url) {
                window.location.replace(error.response?.data?.url)
            } else {
                if (
                    error.response?.status === 302 &&
                    error.response?.data?.url
                ) {
                    window.location.replace(error.response?.data?.url)
                } else {
                    handleApiError({
                        isReduxError: false,
                        error,
                    })
                }
            }
        } finally {
            setIsLoading(false)
        }
    }

    const classes = useStyles({ isMobile })
    const renderMedia = () => {
        if (media) {
            switch (media[0]?.type) {
                case 'image':
                    return (
                        <Media
                            image={media[0]['720']}
                            className={classes.mediaThumb}
                        />
                    )

                case 'video':
                    return (
                        <div className={classes.videoWrapper}>
                            <div className={classes.playIcon}>
                                <PlayIcon />
                            </div>
                            <Media
                                image={media[0]['banner_720']}
                                className={classes.mediaThumb}
                            />
                        </div>
                    )
                default:
                    return null
            }
        } else {
            return null
        }
    }
    return (
        <Popover onClose={onClose} title={'Tip'} maxWidth={539}>
            <div className={classes.tipModal}>
                <div className={classes.body}>
                    <div className={classes.mediaSection}>
                        <div className={classes.infoWrapper}>
                            <div className={classes.titleWrapper}>
                                <span>You are sending a tip for this post</span>
                                <p className={classes.subtitle}>
                                    Published on:{' '}
                                    {dayjs(date).format('D MMM YYYY')}
                                </p>
                            </div>
                            {isMobile && renderMedia()}
                            <Divider className={classes.divider} height={0.5} />
                            <UserInfo
                                avatar={user?.avatar?.[128]}
                                name={user?.display_name}
                                username={user?.username}
                                isVerified={user?.is_verified}
                                isOnline={user?.is_online}
                            />
                        </div>
                        {!isMobile && renderMedia()}
                    </div>
                </div>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className={classes.formWrapper}>
                        <div className={classes.tipSection}>
                            {Object.entries(tipAssets).map((item, index) => {
                                return (
                                    <div key={index} className={classes.tipItem}>
                                        <div className={classes.tipInfoWrapper}>
                                            <Media
                                                className={classes.image}
                                                image={item[1].image}
                                            />
                                            <p>{item[1].name}</p>
                                        </div>
                                        <Radio
                                            className={classes.radio}
                                            value={item[1].value}
                                            {...register(TIP_MODEL.amount)}
                                        />
                                    </div>
                                )
                            })}
                        </div>
                        <Input
                            type={'text'}
                            placeholder={`Enter tip amount`}
                            statusIcon={
                                <span className={classes.currency}>
                                    {currency}
                                </span>
                            }
                            iconPosition={'right'}
                            {...register(TIP_MODEL.customAmount)}
                            errors={errors[TIP_MODEL.customAmount]}
                            touched={touchedFields[TIP_MODEL.customAmount]}
                            inputProps={{
                                disabled: selectedAmount !== 'custom',
                            }}
                        />
                        <Divider className={classes.divider} height={0.5} />
                        <Select
                            label={'Token Type'}
                            placeholder={'Choose a Token to pay with...'}
                            options={tokens}
                            control={control}
                            components={{ SingleValue: CustomSingleValue }}
                            isSearchable
                            name={TIP_MODEL.token}
                            errors={errors[TIP_MODEL.token]}
                            className={classes.select}
                        />
                        <Box classNames={classes.paymentProvider}>
                            POWERED BY
                            <Media image={paymentProviderLogo} />
                        </Box>
                    </div>
                    <div className={classes.action}>
                        <Button
                            disabled={isLoading}
                            width={'100%'}
                            type={'submit'}
                        >
                            Confirm Tip
                        </Button>
                    </div>
                </form>
            </div>
            {/*<DevTool control={control} />*/}
        </Popover>
    )
}

export default TipModal
