import React, {  useMemo } from 'react'
import { createUseStyles } from 'react-jss'
import dayjs from 'dayjs'
import UserInfo from '../../../homepage/posts-feed/post/post-header/user-info'
import colors from '../../../../theme/colors'
import { CreatorIcon, FanIcon } from '../../../../theme/icons'
import { useNavigate } from 'react-router-dom'
import { ROUTE_MESSAGES_CHAT } from '../../../../utilities/constants'
import { selectUnreadMessageCounts} from '../../../../store/slices/messages'
import {useDispatch, useSelector} from 'react-redux'
import {selectPresence} from "../../../../store/slices/conversations";
import {pubnubTimetokenToDate} from "../../../../utilities/helpers";

const useStyles = createUseStyles((theme) => ({
    messageToast: {
        position: 'relative',
        height: 80,
        ...theme.utils.flexbox.spaceBetween,
        whiteSpace: 'nowrap',
        gap: 16,
        padding: [16, 20, 16, 12],
        cursor: 'pointer',
        backgroundColor: ({ isActive }) => (isActive ? '#FAFAFA' : '#ffffff'),
        borderBottom: '1px solid #E6E6E6',
    },
    col: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
    },
    elapsedTime: ({ extended }) => ({
        fontSize: 10,
        width: 'min-content',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        textAlign: 'end',
        color: theme.palette.text.disabled,
        ...(extended && {
            padding: [0, 4],
        }),
        fontWeight: 500,
        letterSpacing: 0.25,
    }),
    activeChat: {
        position: 'absolute',
        left: 0,
        top: 0,
        height: '100%',
        width: 4,
        backgroundColor: colors.primary[400],
    },
    infoWrapper: {
        display: 'grid',
        justifyContent: 'center',
        alignItems: 'center',
        gridTemplateColumns: '24px auto',
        gap: 8,
    },
    userInfo: {
        pointerEvents: 'none',
    },
    unreadCount: {
        height: 18,
        width: 18,
        ...theme.utils.flexbox.centered,
        borderRadius: '50%',
        backgroundColor: colors.error[500],
        fontSize: 8,
        color: 'white',
    },
}))

const MessageToast = ({ conversation, extended, isActive, channelUUID }) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const unreadCount = useSelector(selectUnreadMessageCounts)
    const chatPresence = useSelector(selectPresence)
    const isOnline = chatPresence[conversation?.channel]?.occupancy > 1

    // if current uuid params is different from current user conversation,
    // redirect to current user conversation
    const handleRedirect = () => {
        if (channelUUID !== conversation.channel) {
            navigate(ROUTE_MESSAGES_CHAT.replace(':channelUUID', conversation.channel))
        }
    }

    const parsedDate = useMemo(() => {
        if(!conversation?.latestMessageDate) return ''
        const formattedDate = pubnubTimetokenToDate(conversation?.latestMessageDate)
        const secondsDiff = dayjs().diff(dayjs(formattedDate), 's')
        switch (true) {
            case secondsDiff < 60:
                return `now`
            case secondsDiff < 3600:
                const minutes = dayjs().diff(dayjs(formattedDate), 'm')
                return `${minutes} ${minutes > 1 ? `minutes` : `minute`} ago`
            case secondsDiff < 86400:
                const hours = dayjs().diff(dayjs(formattedDate), 'h')
                return `${hours} ${hours > 1 ? `hours` : `hour`} ago`
            default:
                return dayjs(formattedDate).format('hh:mm A')
        }
    }, [conversation])

    const classes = useStyles({ extended, isActive })

    return (
        <div className={classes.messageToast} onClick={handleRedirect}>
            {isActive && <div className={classes.activeChat} />}
            <div className={classes.infoWrapper}>
                {conversation?.is_creator ? <CreatorIcon /> : <FanIcon />}
                <UserInfo
                    name={conversation?.display_name}
                    username={conversation?.username}
                    avatar={conversation?.avatar?.[128]}
                    category={conversation?.category?.name}
                    isVerified={conversation?.is_verified}
                    classname={classes.userInfo}
                    //isOnline={conversation.is_online}
                    isOnline={isOnline}
                />
            </div>
            <div className={classes.col}>
                <div className={classes.elapsedTime}>{parsedDate}</div>
                {unreadCount[conversation?.channel] > 0 && (
                    <div className={classes.unreadCount}>
                        {unreadCount[conversation?.channel] < 99 ? unreadCount[conversation?.channel] : '99+'}
                    </div>
                )}
            </div>
        </div>
    )
}

export default MessageToast
