import { createUseStyles } from 'react-jss'
import { MoreIcon, TrashIcon } from '../../../../../theme/icons'
import { useState } from 'react'
import colors from '../../../../../theme/colors'
import ActionsDropdown from '../../../../../components/ActionsDropdown'
import { useDispatch, useSelector } from 'react-redux'
import { deleteActivity, selectUser } from '../../../../../store/slices/user'
import dayjs from 'dayjs'
import { setAlertMessage } from '../../../../../store/slices/app'
import { SUCCESS } from '../../../../../utilities/constants'
import UserAvatar from '../../../../homepage/posts-feed/post/post-header/user-info/user-avatar'

const useStyles = createUseStyles((theme) => ({
    log: {
        display: 'grid',
        gridColumnGap: 16,
        gridTemplateColumns: 'auto 1fr auto',
        alignItems: 'center',
        position: 'relative',
    },
    details: {
        '& p > span': {
            fontWeight: 600,
        },
    },
    more: {
        cursor: 'pointer',
        '&:hover': {
            '& path': {
                transition: 'ease-in-out 300ms',
                stroke: colors.common.offBlack,
            },
        },
    },
    actionsWrapper: {
        background: colors.common.white,
        padding: 8,
        zIndex: 3,
        position: 'absolute',
        right: 32,
        minWidth: 180,
        borderRadius: 12,
    },
    transparentOverlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        backgroundColor: 'transparent',
        overflow: 'visible',
        zIndex: 2,
    },
    action: {
        display: 'grid',
        alignItems: 'center',
        gridTemplateColumns: '1fr auto',
        height: 36,
        padding: 8,
        '& span': {
            color: colors.common.offBlack,
        },
        '& svg > path ': {
            stroke: colors.common.offBlack,
        },
    },
    hDanger: {
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: colors.transparency.red.R1,
            transition: 'ease-in-out 300ms',
            '& span': {
                transition: 'ease-in-out 300ms',
                color: colors.error[500],
            },
            '& svg > path ': {
                transition: 'ease-in-out 300ms',
                stroke: colors.error[500],
            },
        },
    },
    hNormal: {
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: colors.greyscale[200],
            transition: 'ease-in-out 300ms',
            '& span': {
                transition: 'ease-in-out 300ms',
                color: colors.common.offBlack,
            },
            '& svg > path ': {
                transition: 'ease-in-out 300ms',
                stroke: colors.common.offBlack,
            },
        },
    },
}))

const ActivityLog = ({ log }) => {
    const user = useSelector(selectUser)
    const dispatch = useDispatch()
    const [showActions, setShowActions] = useState(false)
    const classes = useStyles()

    const onDelete = async () => {
        await dispatch(deleteActivity(log?.id))
        dispatch(
            setAlertMessage({
                message: 'Activity successfully removed.',
                visible: true,
                variant: SUCCESS,
            })
        )
        setShowActions(!showActions)
    }

    const actions = [
        {
            title: 'Delete',
            icon: <TrashIcon width={18} data-colors />,
            callback: onDelete,
            hoverType: 'danger',
        },
    ]

    return (
        <div className={classes.log}>
            <UserAvatar avatar={log?.receiver?.avatar?.[128]} />
            <div className={classes.details}>
                <p>
                    <span>{user?.display_name} </span>
                    {log?.post_like_id || log?.comment_like_id
                        ? 'send a like to'
                        : 'replied to'}
                    <span> {log?.receiver?.display_name} </span>{' '}
                    {log?.post_like_id ? 'post' : 'comment'}
                </p>
                <small>{dayjs(log?.created_at).format('HH:mm')}</small>
            </div>

            <MoreIcon
                width={32}
                data-color
                onClick={() => setShowActions(!showActions)}
                className={classes.more}
            />
            <ActionsDropdown
                actions={actions}
                openToggle={() => setShowActions(!showActions)}
                isOpened={showActions}
            />
        </div>
    )
}

export default ActivityLog
