import React, { useEffect, useMemo, useRef} from 'react'
import {createUseStyles} from 'react-jss'
import cx from 'classnames'
import Spinner from '../../../../components/Spinner'
import {LoaderIcon} from '../../../../theme/icons'
import usePagination from '../../../../hooks/usePagination'
import {httpFetchChatGallery} from "../../../../http-requests/messages";
import ChatMediaGallery from "./chatMediaGallery/ChatMediaGallery";
import dayjs from "dayjs";
import colors from "../../../../theme/colors";

const useStyles = createUseStyles((theme) => ({
    chatGallery: {
        // display: 'flex',
        // flexDirection: 'column',
        // alignItems: 'center',
        // gap: 16,
        width: '100%',
        padding: [16, 0],
        overflowY: 'scroll',
        [theme.mediaQueries.lUp]: {
            padding: 16,
        },
    },
    loadMoreSpinnerWrapper: {
        position: 'relative',
        width: 20,
        height: 20,
        animation: 'spin 1s linear infinite',
        margin: '0 auto',
    },

    gallery: {
        padding: 16,
        display: 'grid',
        justifyContent: 'center',
        gridTemplateColumns: '136px 136px',
        gridAutoRows: '136px',
        gap: 16,
        [theme.mediaQueries.xsUp]: {
            gridTemplateColumns: '162px 162px',
            gridAutoRows: '162px',
        },
        [theme.mediaQueries.lUp]: {
            gridTemplateColumns: '196px 196px 196px',
            gridAutoRows: '196px',
        },
    },
    line: {
        margin: [14, 0],
        position: 'relative',
        height: 1,
        width: '100%',
        backgroundColor: colors.greyscale[300],
    },
    text: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        padding: 8,
        backgroundColor: 'white',
        fontSize: 10,
        fontWeight: 500,
        letterSpacing: 0.5,
        color: colors.greyscale[400],
    },
}))

const ChatGallery = ({user, classname, onLoad}) => {
    const today = dayjs().format('YYYYMMDD')
    const yesterday = dayjs().subtract(1, 'day').format('YYYYMMDD')
    const endRef = useRef()

    const {receivedData, isLoading, isLoadingMore, total} = usePagination({
        ref: endRef,
        params: {channel: user?.channelID},
        callback: httpFetchChatGallery,
        dependencies: [user],
    })

    useEffect(() => {
        onLoad(total)
    }, [total])


    const mediaByDate = useMemo(() => {
        const mediaByDate = {}
        receivedData.forEach((media) => {
                const date = dayjs(media.created_at).format('YYYYMMDD')
                if (!mediaByDate[date]) {
                    mediaByDate[date] = {
                        [media.id]: media
                    }
                } else {
                    mediaByDate[date][media.id] = media
                }
            }
        )
        return mediaByDate
    }, [receivedData])

    const renderLabel = (date) => {
        if (date === today) {
            return 'TODAY'
        } else if (date === yesterday) {
            return 'YESTERDAY'
        } else {
            return dayjs(date).format('DD/MM/YYYY')
        }
    }

    const classes = useStyles()
    return (
        <div className={cx(classname, classes.chatGallery)}>
            {isLoading && <Spinner overlayFullscreen/>}
            {Object.keys(mediaByDate).reverse().map((date) => {
                return (
                    <>
                        <div className={classes.line}>
                            <span className={classes.text}>
                                {renderLabel(date)}
                            </span>
                        </div>
                        {Object.values(mediaByDate[date]).length &&
                            <ChatMediaGallery
                                items={Object.values(mediaByDate[date]).reverse()}
                                classname={classes.gallery}
                            />
                        }
                    </>
                )
            })}
            {!!receivedData?.length && <div ref={endRef}/>}
            {isLoadingMore && (
                <div className={classes.loadMoreSpinnerWrapper}>
                    <LoaderIcon/>
                </div>
            )}
        </div>
    )
}

export default ChatGallery
