import { createUseStyles } from 'react-jss'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { useWatch, useFormContext } from 'react-hook-form'
import Radio from '../../../components/Radio'
import { useEffect } from 'react'
import { SIGNUP_MODEL } from '../signup/signup-form/signupModel'
import { retrieveSingleValueForRs } from '../../../utilities/helpers'
import { useSelector } from 'react-redux'
import { selectUser } from '../../../store/slices/user'
import Media from '../../../components/Media'

const useStyles = createUseStyles((theme) => ({
    root: {
        textAlign: 'left',
        display: 'grid',
        gridTemplateColumns: '1fr',
        gridTemplateRows: '1fr auto',
        gridTemplateAreas: `'head' 'childs'`,
        padding: 0,
        boxShadow: '0px 1px 3px rgba(20, 20, 42, 0.1)',
        borderRadius: theme.spacing * 2,
        border: `2px solid transparent`,
    },
    head: {
        gridArea: 'head',
        display: 'grid',
        gridTemplateAreas: `'image content action'`,
        gridTemplateColumns: 'auto 1fr auto',
        gridTemplateRows: 'auto',
        gap: theme.spacing * 2,
        minHeight: 84,
        margin: 0,
        cursor: 'pointer',
        padding: 16,
    },
    childsContainer: {
        gridArea: 'childs',
    },
    childsSlider: {
        maxHeight: 180,
        overflowY: 'scroll',
        '&::-webkit-scrollbar': {
            ' -webkit-appearance': 'none !important',
        },
        '&::-webkit-scrollbar:vertical': {
            width: '6px !important',
        },
        '&::-webkit-scrollbar-track': {
            '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.3) !important',
            '-webkit-border-radius': '10px !important',
            borderRadius: '10px !important',
        },
        '&::-webkit-scrollbar-thumb': {
            borderRadius: '8px !important',
            backgroundColor: ' rgba(0, 0, 0, .5) !important',
        },
    },
    child: {
        display: 'grid',
        gridTemplateRows: 'auto',
        gridTemplateColumns: '1fr auto',
        alignItems: 'center',
        gap: theme.spacing * 2,
        padding: [12, 16],
        '& label': {
            margin: 0,
        },
        '& span': {
            fontSize: 16,
            fontWeight: 700,
            color: theme.palette.text.primary,
        },
    },
    image: {
        ...theme.utils.grid.centered,
        width: 84,
        height: 84,
        gridArea: 'image',
        backgroundColor: ({ color }) => theme.palette[color].backgroundLight,
        borderRadius: 12,
        '& svg': {
            width: 84,
            height: 84,
            padding: theme.spacing * 2,
        },
    },
    imageContainer: {
        width: 84,
        height: 84,
        backgroundImage: ({ image }) => `url('${image}')`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'contain',
    },
    content: {
        gridArea: 'content',
        display: 'grid',
        alignContent: 'center',
    },
    overline: ({ hasBadge, image }) => ({
        justifyContent: 'start',
        color: theme.palette.quaternary.main,
        ...(image
            ? {
                  ...theme.typography.small,
                  textTransform: 'uppercase',
                  fontWeight: 600,
              }
            : {
                  ...theme.typography.label,
              }),
        ...(hasBadge && {
            ...theme.typography.overline,
            color: theme.palette.text.primary,
            fontWeight: 700,
        }),
        '& svg': {
            height: 20,
        },
    }),
    text: ({ hasBadge }) => ({
        ...theme.typography.h5,
        color: theme.palette.text.primary,
        ...(hasBadge && {
            ...theme.typography.overline,
            color: theme.palette.text.inactive,
            fontWeight: 400,
            letterSpacing: '0.25px',
        }),
    }),
    description: {
        ...theme.typography.subtitle2,
        color: theme.palette.text.secondary,
    },
    action: {
        ...theme.utils.grid.centered,
        gridArea: 'action',
    },
    cardError: {
        color: theme.palette.error.main,
        borderColor: theme.palette.error.main,
    },
    radioError: {
        borderColor: theme.palette.error.main,
    },
}))

const CardSelector = ({
    image,
    icon,
    color = 'primary',
    overline,
    text,
    className,
    action,
    inputCreatorName = '',
    inputCategoryName = '',
    categories,
    type = 'fan',
    defaultChecked,
}) => {
    const user = useSelector(selectUser)
    const {
        register,
        formState: { errors },
    } = useFormContext()
    const showCategory = useWatch({
        name: inputCreatorName,
        defaultValue: user && user[SIGNUP_MODEL.isCreator],
    })
    const classes = useStyles({ color, action, image })
    return (
        <div
            className={cx(
                classes.root,
                className,
                errors[inputCreatorName]?.message ? classes.cardError : null
            )}
        >
            <label className={classes.head}>
                <div className={classes.image}>
                    {icon}
                    {image && <div className={classes.imageContainer} />}
                </div>
                <div className={classes.content}>
                    <div className={classes.overline}>{overline}</div>
                    <div className={classes.text}>{text}</div>
                </div>
                <div className={classes.action}>
                    <Radio
                        inputClassName={
                            errors[inputCreatorName] ? classes.radioError : null
                        }
                        {...register(inputCreatorName)}
                        defaultChecked={defaultChecked}
                        value={type === 'creator'}
                    />
                </div>
            </label>
            {(showCategory === 'true' || showCategory === true) &&
                type === 'creator' && (
                    <div className={classes.childsContainer}>
                        <div className={classes.childsSlider}>
                            {categories.map(({ id, name }, idx) => {
                                return (
                                    <div className={classes.child} key={idx}>
                                        <span>{name}</span>
                                        <Radio
                                            inputClassName={
                                                errors[inputCategoryName]
                                                    ? classes.radioError
                                                    : null
                                            }
                                            value={id}
                                            {...register(inputCategoryName)}
                                            defaultChecked={
                                                user &&
                                                user[
                                                    SIGNUP_MODEL.categoryID
                                                ] === id
                                            }
                                        />
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                )}
        </div>
    )
}

export default CardSelector

CardSelector.propTypes = {
    color: PropTypes.oneOf(['primary', 'secondary', 'tertiary', 'quaternary']),
}
