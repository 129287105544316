import React from 'react'
import ContentLoader from 'react-content-loader'
import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles((theme) => ({
    notificationLoader: {
        margin: [0, 16],
    },
}))

const NestedCommentsLoader = ({ classNames, rows = 1, ...rest }) => {
    const rowMapper = Array.from(Array(rows).keys())

    const classes = useStyles()
    return (
        <div className={classes.notificationLoader}>
            {rowMapper.map((row, index) => (
                <ContentLoader
                    speed={1}
                    width={'100%'}
                    height={50}
                    backgroundColor="#f6f6ef"
                    foregroundColor="#e8e8e3"
                    style={{ margin: '0' }}
                    key={index}
                    {...rest}
                >
                    <circle cx="24" cy="20" r="12" />
                    <rect x="45" y="15" rx="0" ry="0" width="100" height="10" />
                    <rect
                        x="155"
                        y="15"
                        rx="0"
                        ry="0"
                        width="100%"
                        height="10"
                    />
                    <rect x="45" y="35" rx="0" ry="0" width="50" height="6" />
                    <rect x="100" y="35" rx="0" ry="0" width="50" height="6" />
                </ContentLoader>
            ))}
        </div>
    )
}

export default NestedCommentsLoader
