import * as yup from 'yup'

const SUBSCRIPTIONS_MODEL = {
    isFree: 'is_free',
    subscriptionPrice: 'subscription_price',
}

const decimalRegex = /^[1-9]\d?(?:\.\d{0,2})?$/
const subscriptionsValidationSchema = yup.object().shape({
    [SUBSCRIPTIONS_MODEL.isFree]: yup.bool(),
    [SUBSCRIPTIONS_MODEL.subscriptionPrice]: yup
        .string()
        .typeError('You must specify a valid price')
        .when('is_free', {
            is: true,
            then: yup
                .string()
                .test(
                    'amount',
                    'Monthly price must be minimum $0 USD',
                    (n) => n === '0'
                )
                .typeError('You must specify a valid price'),
        })
        .when('is_free', {
            is: false,
            then: yup
                .string()
                .test(
                    'amount',
                    'Monthly price must be minimum $4.99 USD',
                    (n) => n >= 4.99
                )
                 .test(
                    'max_amount',
                    'Monthly price can be maximum $99.99 USD',
                    (n) => n <= 99.99
                )
                .matches(decimalRegex, 'You must specify a valid price')
                .typeError('You must specify a valid price'),
        }),
})

export {
    SUBSCRIPTIONS_MODEL,
    subscriptionsValidationSchema as validationSchema,
}
