import React, { useMemo } from 'react'
import { createUseStyles } from 'react-jss'
import NotificationItem from '../notificationItem/NotificationItem'
import dayjs from 'dayjs'

const useStyles = createUseStyles((theme) => ({
    notificationsPanelSection: {
        display: 'flex',
        flexDirection: 'column',
    },
    date: {
        width: '100%',
        display: 'flex',
        justifyContent: 'left',
        alignItems: 'center',
        height: 28,
        backgroundColor: '#F9F9F9',
        fontSize: 12,
        fontWeight: 400,
        color: theme.palette.disabled.main,
        padding: [0, 16],
    },
    text: {
        fontWeight: 500,
    },
    itemWrapper: {
        display: 'flex',
        flexDirection: 'column',
        margin: [0, 16],
        '& > div:not(:last-child)': {
            borderBottom: '1px solid #F3F3F3',
        },
    },
    divider: {
        padding: [0, 4],
    },
}))

const NotificationsPanelSection = ({
    unreadFilterActive,
    extended,
    notifications,
    date,
}) => {
    const today = dayjs().format('DD/MM/YYYY')
    const yesterday = dayjs().subtract(1, 'day').format('DD/MM/YYYY')

    const label = useMemo(() => {
        if (date === today) {
            return 'Today'
        } else if (date === yesterday) {
            return 'Yesterday'
        }
    }, [date])

    const classes = useStyles({})
    return (
        <div className={classes.notificationsPanelSection}>
            <div className={classes.date}>
                <span className={classes.text}>{label}</span>
                {label && <div className={classes.divider}> · </div>}
                {date}
            </div>
            <div className={classes.itemWrapper}>
                {notifications?.map((notification, index) => {
                    return (
                        <NotificationItem
                            extended={extended}
                            data={notification}
                            key={index}
                            unreadFilterActive={unreadFilterActive}
                        />
                    )
                })}
            </div>
        </div>
    )
}

export default NotificationsPanelSection
