import {createUseStyles} from 'react-jss'
import React from 'react'
import {DownArrowIcon} from "../../../theme/icons";

const useStyles = createUseStyles((theme) => ({
    filterAccordion: ({isOpen}) => ({
        display: 'flex',
        justifyContent: 'space-between',
        padding: theme.spacing * 2,
        width: '100%',
        height: 56,
        borderRadius: 12,
        border: `2px solid ${theme.palette[isOpen ? 'primary' : 'disabled'].main}`,
        fontWeight: 400,
        letterSpacing: 0.5,
        color: isOpen ? theme.palette.common.black : theme.palette.disabled.main,
        transition: 'ease-in-out 400ms',
        '& svg': {
            transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)',
            width: 15,
            transition: 'ease-in-out 400ms',
        }
    })
}))


const FiltersAccordion = ({isOpen, callback}) => {

    const classes = useStyles({isOpen})
    return (
        <div
            className={classes.filterAccordion}
            onClick={callback}
        >
            Filter by Category
            <DownArrowIcon/>
        </div>
    )
}

export default FiltersAccordion
