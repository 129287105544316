import axios from '../utilities/axios'

export const httpSavePost = (postID) => {
    const url = `/posts/${postID}/save-post`
    return axios.post(url)
}

export const httpUnsavePost = (postID) => {
    const url = `/posts/${postID}/unsave-post`
    return axios.delete(url)
}

export const httpLikePost = (postID) => {
    const url = `/posts/${postID}/likes`
    return axios.post(url)
}

export const httpUnlikePost = (postID) => {
    const url = `/posts/${postID}/likes`
    return axios.delete(url)
}

export const httpDeletePost = (postID) => {
    const url = `/posts/${postID}`
    return axios.delete(url)
}

export const httpFetchFeed = ({ page, limit }) => {
    const url = `/timeline`
    return axios.get(url, {
        params: { response_type: 'extended', page, limit },
    })
}

export const httpFetchComments = ({ postID, page, limit }) => {
    const url = `/posts/${postID}/comments`
    return axios.get(url, { params: { page, limit } })
}

export const httpCommentPost = ({ postID, comment }) => {
    const url = `/posts/${postID}/comments`
    return axios.post(url, { comment })
}

export const httpCreatePost = (postData) => {
    const url = `/posts`
    return axios.post(url, postData)
}

export const httpUploadPostMedia = (media, type, options) => {
    const url = `/upload/${type}`
    return axios.post(url, media, options)
}

export const httpPurchasePost = (postID, data) => {
    const url = `/posts/${postID}/purchase`
    return axios.post(url, data)
}

export const httpPaidSubscribeToUser = ({ userID, tokenID, bundleID }) => {
    const url = `/users/${userID}/subscribe`
    return axios.post(url, { token_id: tokenID, bundle_id: bundleID })
}

export const httpFetchSinglePost = (postID) => {
    const url = `/posts/${postID}`
    return axios.get(url, { params: { response_type: 'extended' } })
}
