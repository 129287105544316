import { createUseStyles, useTheme } from 'react-jss'
import {
    FacebookIcon, FansLabelIcon, FollowersLabelIcon,
    InstagramIcon,
    LinkIcon,
    MapPinIcon,
    SnapchatIcon,
    YoutubeIcon,
} from '../../../../../theme/icons'
import React, { useEffect, useState } from 'react'
import Label from '../../../../../components/Label'
import Divider from '../../../../../components/Divider'
import Banner from '../../../../../components/Banner'
import { useWindowSize } from '../../../../../hooks/useWindowSize'
import Social from '../../../../../components/Social'
import {
    httpFetchUserFollowers,
    httpFetchUserFollowing,
} from '../../../../../http-requests'
import { capitalize, handleApiError } from '../../../../../utilities/helpers'
import Spinner from '../../../../../components/Spinner'
import '../../../../../../src/asset/images/fanIcon.png'

const useStyles = createUseStyles((theme) => ({
    root: {
        ...theme.utils.flexbox.spaceBetween,
        flexWrap: 'wrap',
        padding: theme.spacing * 2,
        borderBottom: '1px solid rgba(20, 20, 42, 0.1)',
    },
    flexItem: {
        ...theme.utils.flexbox.centered,
        gap: theme.spacing,
        cursor: 'default',
    },
    labelWrapper: {
        extend: 'flexItem',
        ...theme.utils.flexbox.spaceBetween,
        [theme.mediaQueries.s]: {
            width: '100%',
        },
        '& p': {
            fontSize: 12,
            color: theme.palette.primary.middle,
            padding: theme.spacing,
            cursor: 'pointer',
            whiteSpace: 'nowrap',
        },
    },
    socialWrapper: {
        extend: 'flexItem',
        '& svg': {
            cursor: 'pointer',
            width: 32,
        },
    },
    moreInfo: {
        extend: 'flexItem',
        gap: theme.spacing * 2,
        paddingTop: theme.spacing * 2,
        flexDirection: 'column',
        alignItems: 'start',
        width: '100%',
    },
    address: {
        extend: 'flexItem',
        fontSize: 12,
        fontWeight: 400,
        color: theme.palette.text.secondary,
    },
    divider: {
        margin: 0,
        width: '100%',
    },
    labelCounter: {
        color: theme.palette.label.color,
        backgroundColor: theme.palette.label.background,
        border: '1px solid ' + theme.palette.label.borderColor,
    }
}))

const CoverInfo = ({ user }) => {
    const [moreInfo, setMoreInfo] = useState(false)

    const { width } = useWindowSize()
    const [isMobile, setIsMobile] = useState(width <= 448)
    useEffect(() => {
        setIsMobile(width <= 448)
    }, [width])

    const socials = {
        tiktok: user?.tiktok_username,
        instagram: user?.instagram_username,
        facebook: user?.facebook_username,
        snapchat: user?.snapchat_username,
        youtube: user?.youtube_link,
        twitter: user?.twitter_username,
    }

    const renderSocial = () => {
        return Object.entries(socials).map(
            (social, idx) =>
                social[1] && (
                    <Social
                        key={idx}
                        name={capitalize(social[0])}
                        url={social[1]}
                    />
                )
        )
    }

    const classes = useStyles()
    return (
        <div className={classes.root}>
            <div className={classes.labelWrapper}>
                <div className={classes.flexItem}>
                    <Label className={classes.labelCounter} isLight={true}>
                        <FansLabelIcon/>
                        &nbsp;{user?.users_subscribed_count}&nbsp;Fans&nbsp;
                    </Label>
                    <Label className={classes.labelCounter} isLight={true}>
                        <FollowersLabelIcon/>
                        &nbsp;{user?.followers_count}&nbsp;Followers&nbsp;
                    </Label>
                </div>
                <div>
                    <p onClick={() => setMoreInfo(!moreInfo)}>
                        {!moreInfo ? 'More info' : 'Less info'}
                    </p>
                </div>
            </div>
            {!isMobile && (
                <div className={classes.socialWrapper}>{renderSocial()}</div>
            )}

            {moreInfo && (
                <div className={classes.moreInfo}>
                    {isMobile && (
                        <div className={classes.socialWrapper}>
                            {renderSocial()}
                        </div>
                    )}
                    <div className={classes.address}>
                        <MapPinIcon />
                        {user?.location}
                    </div>
                    <Divider className={classes.divider} />
                    <p>{user?.description}</p>
                    {user?.website_link && (
                        <Banner
                            data={'secondary'}
                            url={user?.website_link}
                            icon={<LinkIcon />}
                            height={40}
                        />
                    )}
                </div>
            )}
        </div>
    )
}

export default CoverInfo
