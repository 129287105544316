import React, {useEffect} from 'react'
import {createUseStyles, useTheme} from 'react-jss'
import {CloseIcon, LoadingErrorIcon} from '../theme/icons'
import ProgressBar from "./ProgressBar";
import logo from "../asset/images/mediaLoaderSpinner.png";

const useStyles = createUseStyles((theme) => ({
    mediaLoader: {
        ...theme.utils.flexbox.centered,
        padding: theme.spacing * 3,
        flexDirection: 'column',
        gap: theme.spacing * 2,
        position: 'fixed',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        overflow: 'visible',
        backgroundColor: 'rgba(80, 26, 188, 0.8)',
        backdropFilter: 'blur(4px)',
        '-webkit-backdrop-filter': 'blur(4px)',
        zIndex: 2000,
    },
    icon: {
        height: 64,
        width: 64,
        margin: [4, 0],
    },
    action: {
        ...theme.typography.small,
        display: 'flex',
        alignItems: 'center',
        justifyContent: ({error}) => !!error ? 'center' : 'space-between',
        cursor: 'pointer',
        padding: [0, theme.spacing * 2],
        marginTop: theme.spacing * 2,
        height: 40,
        width: 140,
        borderRadius: 50,
        border: '2px solid white',
        color: theme.palette.common.white,
        fontWeight: 400,
        '& svg': {
            height: 20,
            fill: 'white',
        }
    },
    statusText: {
        fontSize: 14,
        color: theme.palette.common.white,
        letterSpacing: 0.5,
        fontWeight: 400,
    },
    errorText: {
        fontSize: 14,
        color: theme.palette.common.white,
        letterSpacing: 0.5,
        fontWeight: 700,
    },
}))

const MediaLoader = ({
                         error,
                         onClose,
                         onCancel,
                         progress = 0,
                     }) => {
    const theme = useTheme()
    useEffect(() => {
        window.document.body.style['overflow-y'] = 'hidden' // lock body scroll
        return () => (window.document.body.style['overflow-y'] = 'auto') // unlock body scroll;
    }, [])

    const classes = useStyles({error})
    return (
        <>
            <div className={classes.mediaLoader}>
                <div className={classes.icon}>
                    {!!error
                        ? <LoadingErrorIcon/>
                        : <img
                            src={logo}
                        />
                    }
                </div>
                {!!error &&
                    <div className={classes.errorText}>
                        Error during the upload
                    </div>
                }
                <div className={classes.statusText}>
                    {!!error
                        ? error + ''
                        : 'We are loading your media...'
                    }
                </div>
                {!error && <ProgressBar progress={progress}/>}
                {!!error
                    ?
                    <div className={classes.action} onClick={onClose}>
                        Back to Post
                    </div>
                    :
                    <div className={classes.action} onClick={onCancel}>
                        Cancel Loader
                        <CloseIcon stroke={theme.palette.common.white}/>
                    </div>
                }
            </div>
        </>
    )
}

export default MediaLoader
