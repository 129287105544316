import {createUseStyles} from 'react-jss'
import SubscriptionRenewalCard from './SubscriptionRenewalCard'
import {useSelector} from 'react-redux'
import {selectUser} from '../../../store/slices/user'
import React, {useEffect, useRef, useState} from 'react'
import {
    handleApiError,
} from '../../../utilities/helpers'
import {useForm, FormProvider} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import {KEEP_SUPPORTING_MODEL, validationSchema} from '../KeepSupportingModel'
import {DevTool} from '@hookform/devtools'
import UnsubscribeAlertModal from '../unsubscribe-alert-modal'
import Spinner from '../../../components/Spinner'
import {httpMultipleSubscribe} from '../../../http-requests/subscription'
import KeepSupportingAccordion from './keep-supporting-accordion/KeepSupportingAccordion'

const useStyles = createUseStyles((theme) => ({
    keepSupportingList: {
        backgroundColor: '#FAFAFA',
        border: '1px solid #F3F3F3',
        borderRadius: 16,
        padding: 14,
        paddingBottom: 0,
    },
    cardList: {
        position: 'relative',
        display: 'flex',
        flexWrap: 'wrap',
        gridRowGap: theme.spacing,
        gridColumnGap: 14,
        width: '100%',
        overflow: 'auto',
        [theme.mediaQueries.mUp]: {
            '&::-webkit-scrollbar': {
                display: 'none',
            },
        },
    },
    gradient: {
        width: '100%',
        background:
            'linear-gradient(180deg, #FCFCFC 27.6%, rgba(252, 252, 252, 0) 100%)',
        pointerEvents: 'none',
    },
    bottomGradient: {
        extend: 'gradient',
        position: 'fixed',
        height: 160,
        bottom: 0,
        transform: 'rotate(180deg)',
    },
    topGradient: {
        extend: 'gradient',
        position: 'absolute',
        height: 100,
        zIndex: 2,
        top: 0,
    },
    loadMoreSpinnerWrapper: {
        position: 'relative',
        width: 20,
        height: 20,
        animation: 'spin 1s linear infinite',
        margin: '0 auto',
    },
    unsubscribe: {
        cursor: 'pointer',
        fontWeight: 500,
        fontSize: 12,
        color: theme.palette.primary.middle,
        marginBottom: theme.spacing * 3,
        '&:hover':{
            textDecoration: 'underline'
        }
    },
}))

const KeepSupportingList = ({expiredSubscriptions, isMobile, token, index}) => {
    const ref = useRef()
    const [bulkUnsubscribeModalOpen, setBulkUnsubscribeModalOpen] =
        useState(false)
    const [selectAll, setSelectAll] = useState(true)
    const [isLoading, setIsLoading] = useState(false)

    const formMethods = useForm({
        shouldUnregister: false,
        mode: 'all',
        reValidateMode: 'all',
        criteriaMode: 'firstError',
        shouldFocusError: true,
        nativeValidation: false,
        defaultValues: {},
        resolver: yupResolver(validationSchema),
    })
    const {
        handleSubmit,
        setError,
        reset,
        watch,
        trigger,
        control,
        formState: {isValid},
    } = formMethods

    const onSubmit = async (formValues) => {
        const dataToSend = {
            subscriptions_to_renew : formValues?.checkbox,
            subscriptions_to_delete: expiredSubscriptions?.filter((sub) => !formValues?.checkbox?.includes(sub?.id)),
            token_id: token?.id,
        }
        setIsLoading(true)
        try {
            if (!!formValues?.checkbox.length) {
                const resp = await httpMultipleSubscribe(dataToSend)
            }
        } catch (error) {
            if (error.response?.status === 302 && error.response?.data?.url) {
                window.location.replace(error.response?.data?.url)
            } else {
                handleApiError({
                    isReduxError: false,
                    error,
                    callbackOnFieldError: setError,
                })
            }
        } finally {
            setIsLoading(false)
        }
    }

    const valueList = watch(KEEP_SUPPORTING_MODEL.checkbox)

    const getTotal = () => {
        let t = 0
        expiredSubscriptions?.forEach((v) => {
            if (valueList?.includes(`${v?.id}`)) {
                t += +v?.dollar_price
                t *= v?.price_multiplier ?? 1
            }
        })
        t = t?.toFixed(2)
        return t
    }

    const revalidateFields = async () => {
        await trigger()
    }

    useEffect(() => {
        if (!selectAll) {
            reset({
                [KEEP_SUPPORTING_MODEL.checkbox]: valueList?.map((v) => false),
            })
        }
        revalidateFields()
    }, [selectAll])


    const classes = useStyles({})
    return (
        <div className={classes.keepSupportingList}>
            <form
                onSubmit={handleSubmit(onSubmit)}
                className={classes.cardList}
            >
                <KeepSupportingAccordion
                    token={token}
                    defaultOpen={index === 0}
                    expiredSubscriptions={expiredSubscriptions}
                    selectAllCallback={() => setSelectAll(!selectAll)}
                    isSelectAllChecked={selectAll && !!valueList?.find((v) => v !== false)}
                    isMobile={isMobile}
                    isDisabled={!isValid}
                    totalAmount={getTotal()}
                >
                    {isLoading && <Spinner/>}
                    <div className={classes.cardList} ref={ref}>
                        <FormProvider {...formMethods}>
                            {expiredSubscriptions?.map((sub, index) => {
                                    return (
                                        <SubscriptionRenewalCard
                                            subscriptionID={sub?.id}
                                            user={sub?.subscribed_to}
                                            price={sub?.dollar_price}
                                            index={index}
                                            defaultChecked={selectAll}
                                            selectAll={selectAll}
                                            multiplier={sub?.price_multiplier}
                                            lastPrice={sub?.last_subscription_dollar_price}
                                        />
                                    )
                                },
                            )}
                        </FormProvider>
                    </div>
                    <div className={classes.unsubscribe} onClick={() => setBulkUnsubscribeModalOpen(true)}>
                        No, I don't want to keep supporting creators
                    </div>
                    {bulkUnsubscribeModalOpen && (
                        <UnsubscribeAlertModal
                            token={token}
                            userList={expiredSubscriptions?.map((v) => v?.id)}
                            onClose={() => setBulkUnsubscribeModalOpen(false)}
                        />
                    )}
                    {/*<DevTool control={control} />*/}
                </KeepSupportingAccordion>
            </form>
        </div>
    )
}

export default KeepSupportingList
