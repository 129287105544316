import { createUseStyles } from 'react-jss'
import Button from '../../components/Button'
import { AlertIcon } from '../../theme/icons'
import * as Icons from '../../theme/icons'
import Input from '../../components/Input'
import { useForm } from 'react-hook-form'
import { DevTool } from '@hookform/devtools'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import Select from '../../components/Select'
import Banner from '../../components/Banner'
import Divider from '../../components/Divider'
import Header from '../../components/Header'
import CreatorCover from '../creator-page/creator-cover'
import PostCreation from '../creator-page/post-creation'
import { useSelector } from 'react-redux'
import { selectUser } from '../../store/slices/user'
import TokenPurchaseBanner from '../creator-page/token-purchase-banner'

const useStyles = createUseStyles((theme) => ({
    root: {
        ...theme.utils.grid.centered,
        padding: [32, 16, 64, 16],
    },
}))

const validationSchema = yup.object().shape({
    firstName: yup.string().required('Il campo è obbligatorio'),
    age: yup.number().required('Il campo è obbligatorio'),
    scelta: yup
        .object()
        .shape({ label: yup.string(), value: yup.number() })
        .required('Il campo è obbligatorio'),
})

const selectOptions = [
    { label: 'Scelta 1', value: 1 },
    { label: 'Scelta 2', value: 2 },
    { label: 'Scelta 3', value: 3 },
]

const Showcase = (props) => {
    const {
        register,
        handleSubmit,
        setError,
        watch,
        control,
        formState: {
            errors,
            isDirty,
            isSubmitting,
            touchedFields,
            submitCount,
        },
    } = useForm({
        mode: 'onBlur',
        reValidateMode: 'onChange',
        nativeValidation: false,
        defaultValues: {
            firstName: '',
        },
        resolver: yupResolver(validationSchema),
    })

    const onSubmit = (data) => {
        console.log(data)
    }
    const user = useSelector(selectUser)

    const classes = useStyles()

    return (
        <div className={classes.root}>
            <h1>Heading 1</h1>
            <h2>Heading 2</h2>
            <h3>Heading 3</h3>
            <h4>Heading 4</h4>
            <h5>Heading 5</h5>
            <h6>Heading 6</h6>
            <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla
                suscipit a tortor a ullamcorper. Nullam cursus, massa a mollis
                laoreet, massa nisl efficitur tortor, eu congue nibh lectus id
                neque. Fusce sit amet nisl sodales, pharetra dui feugiat, mollis
                leo. Aliquam odio mauris, facilisis sed quam sed, consectetur
                aliquam justo. Nulla vitae vehicula dolor. Donec fermentum et
                elit varius ullamcorper. Vestibulum eu condimentum neque, id
                fringilla mi. Duis vel ipsum tellus. In luctus, diam ac rhoncus
                sollicitudin, lacus erat elementum quam, vitae lacinia enim
                lectus non libero. In nec convallis lorem. Praesent ut dui quam.
                Vivamus quis metus ut lacus gravida dignissim. Etiam accumsan
                magna diam, lacinia tincidunt turpis ullamcorper in. Vivamus
                ullamcorper tortor eget egestas volutpat. Sed quis turpis at
                arcu condimentum scelerisque mattis ac justo.
            </p>
            <label>Label</label>
            <a href={'/ciao'}>Hello</a>
            <Button
                variant={'ghost'}
                size={'small'}
                icon={<AlertIcon />}
                iconPosition={'left'}
                data={'primary'}
                disabled={false}
                collapsed={false}
            >
                Button
            </Button>
            <h6>Icons</h6>
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                {Object.keys(Icons).map((iconKey) => {
                    const Icon = Icons[iconKey]
                    return (
                        <div
                            key={iconKey}
                            style={{
                                display: 'inline-flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                textAlign: 'center',
                                width: '25%',
                                marginBottom: '20px',
                            }}
                        >
                            <Icon style={{ width: 30, height: 30 }} />
                            <span>{iconKey}</span>
                        </div>
                    )
                })}
            </div>
            <h6>Controls</h6>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Input
                    touched={touchedFields.firstName}
                    errors={errors.firstName}
                    {...register('firstName')}
                    label={'Text'}
                    type={'text'}
                    helpText={
                        "Help text because youd probably don't know what to do about this fu**ing input"
                    }
                />
                <Input
                    touched={touchedFields.age}
                    errors={errors.age}
                    {...register('age')}
                    type={'number'}
                    label={'Number'}
                />
                <Input type={'password'} label={'Password'} />
                <Input type={'email'} label={'Email'} />
                <Select
                    placeholder={'Fai una scelta...'}
                    options={selectOptions}
                    name={'scelta'}
                    control={control}
                    isClearable
                    isSearchable
                    label={'Select'}
                />
                <Button type="submit" disabled={isSubmitting}>
                    Submit Form
                </Button>
                <DevTool control={control} />
            </form>
            <Banner text={'Ciao a tutti!'} />
            <Divider text={'OR'} />
            <Header />
            <CreatorCover user={user} />
            <PostCreation user={user} />
            <TokenPurchaseBanner />
        </div>
    )
}

export default Showcase
