import React, {useCallback, useEffect, useMemo, useRef} from 'react'
import {createUseStyles} from 'react-jss'
import LightGallery from 'lightgallery/react'
import lgVideo from 'lightgallery/plugins/video'
import {Splide, SplideSlide} from '@splidejs/react-splide'
import ResponsiveVideo from '../../../../../../../components/ResponsiveVideo'
import ResponsiveImage from '../../../../../../../components/ResponsiveImage'
import {PlayRoundIcon} from '../../../../../../../theme/icons'
import {CHAT_MEDIA_TYPES} from "../../../../../../../utilities/constants/chat";

const useStyles = createUseStyles((theme) => ({
    content: {
        display: 'grid',
        // gap: 8,
        gridTemplateRows: 'auto 220px',
        position: 'relative',
        [theme.mediaQueries.xsUp]: {
            gridTemplateRows: 'auto 208px auto',
        },
        [theme.mediaQueries.mUp]: {
            gridTemplateRows: 'auto 208px auto',
        },
    },
    slide: {
        borderRadius: 12,
        overflow: 'hidden',
    },
    carousel: {
        minHeight: 220,
        [theme.mediaQueries.xsUp]: {
            minHeight: 208,
        },
    },
    mediaWrapper: {
        position: 'relative',
        height: 208,
        cursor: 'pointer',
    },
    overlay: {
        height: '100%',
        width: '100%',
        position: 'absolute',
        bottom: 0,
        display: 'flex',
        alignItems: 'start',
        justifyContent: 'end',
        flexDirection: 'column',
        background:
            'linear-gradient(180deg, rgba(39, 8, 51, 0) 26.04%, #270833 100%)',
        padding: 16,
    },
    playButton: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 48,
        height: 48,
    },
    mediaInfo: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: 8,
        fontSize: 12,
        fontWeight: 400,
        letterSpacing: 0.25,
        color: theme.palette.common.white,
    },
    row: {
        width: '100%',
        ...theme.utils.flexbox.spaceBetween,
    },
    bold: {
        fontWeight: 700,
    },
}))

const ChatGalleryContent = ({media}) => {
    const lightGallery = useRef()

    const openGallery = useCallback((slideIndex) => {
        lightGallery.current.openGallery(slideIndex)
    }, [])

    const onInit = useCallback((detail) => {
        if (detail) {
            lightGallery.current = detail.instance
        }
    }, [])

    const mediaRemapped = useMemo(() => {
        return media.map((media) =>
            media.type === CHAT_MEDIA_TYPES.VIDEO
                ? {
                    video: {
                        source: [
                            {
                                src: media.original,
                                type: 'video/mp4',
                            },
                        ],
                        attributes: {
                            preload: false,
                            controls: true,
                            controlsList: 'nodownload',
                            onContextMenu: 'return false;',
                        },
                    },
                }
                : {
                    id: media.id,
                    size: '1920-1080',
                    src: media.original,
                    thumb: media['720'],
                }
        )
    }, [media])

    const classes = useStyles()

    const gallerySettings = {
        plugins: [lgVideo],
        mode: 'lg-fade',
        keyPress: true,
        download: false,
        enableDrag: false,
        dynamic: true,
        licenseKey: process.env.REACT_APP_LG_PLUGIN ?? '0000-0000-000-0000',
        dynamicEl: mediaRemapped,
        onInit,
    }

    useEffect(() => {
        lightGallery.current.refresh(mediaRemapped)
    }, [mediaRemapped])

    const carouselOptions = {
        pagination: false,
        keyboard: false,
        fixedHeight: 208,
        arrows: media?.length > 1 && true,
    }

    const onCarouselClickHandler = (slider, slide, event) => {
        openGallery(slide.index)
    }

    return (
        <div className={classes.content}>
            <Splide
                onClick={onCarouselClickHandler}
                options={carouselOptions}
                className={classes.carousel}
            >
                {media.map(({type, original, ...rest}, idx) => {
                    return (
                        <SplideSlide key={idx} className={classes.slide}>
                            <div className={classes.mediaWrapper}>
                                {type === CHAT_MEDIA_TYPES.VIDEO ? (
                                    <ResponsiveVideo
                                        fullHeight={true}
                                        video={original}
                                        controls={false}
                                    />
                                ) : (
                                    <ResponsiveImage
                                        fit={'cover'}
                                        media={{original, ...rest}}
                                        alt={''}
                                    />
                                )}
                                {type === CHAT_MEDIA_TYPES.VIDEO && (
                                    <div className={classes.playButton}>
                                        <PlayRoundIcon/>
                                    </div>
                                )}
                                <div className={classes.overlay}>
                                    <div className={classes.mediaInfo}>
                                        <div className={classes.row}>
                                            <div className={classes.bold}>
                                                {' '}
                                                {idx + 1} OF {media.length}
                                            </div>
                                        </div>
                                        <div className={classes.row}>
                                            <div>{type === CHAT_MEDIA_TYPES.IMAGE ? 'Image' : 'Video'}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SplideSlide>
                    )
                })}
            </Splide>
            <LightGallery innerRef={lightGallery} {...gallerySettings} />
        </div>
    )
}

export default ChatGalleryContent
