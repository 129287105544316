import React from 'react'
import { createUseStyles } from 'react-jss'
import { NotificationsRoundIcon } from '../../theme/icons'
import NotificationsPanel from './notificationsPanel/NotificationsPanel'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { ROUTE_NOTIFICATIONS } from '../../utilities/constants'
import colors from '../../theme/colors'
import { selectUnseenNotificationsCount } from '../../store/slices/user'

const useStyles = createUseStyles((theme) => ({
    notificationsMenuIcon: {
        ...theme.utils.flexbox.centered,
        height: 46,
        width: 46,
        backgroundColor: theme.palette.common.white,
        borderRadius: '50%',
        border: `1px solid #F3F3F3`,
    },
    iconWrapper: {
        ...theme.utils.flexbox.centered,
        height: '100%',
        width: '100%',
        position: 'relative',
    },
    badge: {
        position: 'absolute',
        ...theme.utils.flexbox.centered,
        backgroundColor: colors.error[500],
        width: 16,
        height: 16,
        top: 6,
        right: 6,
        border: `2px solid #F3F3F3`,
        borderRadius: '50%',
        color: 'white',
        fontSize: 8,
        fontWeight: 600,
    },
    overlay: {
        height: '110vh',
        width: '110vw',
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 1,
        [theme.mediaQueries.m]: {
            backdropFilter: 'blur(4px)',
        },
        [theme.mediaQueries.mUp]: {
            top: theme.sizing.headerHeight,
            backdropFilter: 'blur(4px)',
        },
    },
    notificationsPanelWrapper: {
        zIndex: 2,
        position: 'fixed',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        width: '100%',
        minHeight: 200,
        maxHeight: '90vh',
        maxWidth: 'calc(100vw - 32px)',

        backgroundColor: '#FCFCFC',
        borderRadius: 12,

        [theme.mediaQueries.mUp]: {
            position: 'absolute',
            bottom: 'auto',
            left: 'auto',
            transform: 'translateX(0)',
            top: 70,
            right: 72,
            maxWidth: 344,
            maxHeight: '80vh',
        },
    },
}))

const NotificationsMenuIcon = ({ callback, isOpen }) => {
    const location = useLocation()
    const unseenCount = useSelector(selectUnseenNotificationsCount)

    const handleClick = () => {
        if (location.pathname !== ROUTE_NOTIFICATIONS) {
            callback()
        }
    }

    const classes = useStyles({})
    return (
        <div className={classes.notificationsMenuIcon}>
            <div className={classes.iconWrapper}>
                <NotificationsRoundIcon onClick={handleClick} />
                {unseenCount > 0 && (
                    <div className={classes.badge}>
                        {unseenCount < 10 ? unseenCount : '9+'}
                    </div>
                )}
            </div>
            {isOpen && (
                <>
                    <div
                        className={classes.overlay}
                        onClick={() => callback()}
                    />
                    <div className={classes.notificationsPanelWrapper}>
                        <NotificationsPanel onClose={() => callback()} />
                    </div>
                </>
            )}
        </div>
    )
}

export default NotificationsMenuIcon
