import OperationLoadingSpinner from '../../components/OperationLoadingSpinner'
import { useEffect } from 'react'
import { userMe } from '../../store/slices/user'
import { useDispatch } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import {
    TRANSACTION_ACTION,
    PAYMENT_ERROR,
    PAYMENTS_API_DELAY,
    ROUTE_FANPAGE,
    ROUTE_HOME,
    TRANSACTION_HASH, TRANSACTION_USER,
    TRANSACTION_USERNAME,
} from '../../utilities/constants'
import { httpLockTransactions } from '../../http-requests'

const PaymentCheck = () => {
    const [searchParam] = useSearchParams()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const transactionID = searchParam.get(TRANSACTION_HASH)
    const transactionUser = searchParam.get(TRANSACTION_USER)
    const transactionAction = searchParam.get(TRANSACTION_ACTION)
    const transactionUsername = searchParam.get(TRANSACTION_USERNAME)


    useEffect(() => {
        if (transactionAction && transactionAction !== PAYMENT_ERROR) {
            lockOtherTransactions()
        }

    }, [])

    const lockOtherTransactions = async () => {
        const redirectState = {
            replace: true,
            state: {
                paymentOperationStatus:
                    transactionAction === PAYMENT_ERROR || !transactionAction ? 'failure' : 'success',
            },
        }

        try {
            // if status 200, wait for transaction
            await httpLockTransactions({
                [TRANSACTION_USER]: transactionUser,
                [TRANSACTION_USERNAME]: transactionUsername,
                [TRANSACTION_HASH]: transactionID,
                [TRANSACTION_ACTION]: transactionAction,
            })
            if (transactionID) {
                setTimeout(async () => {
                    await dispatch(userMe())
                    navigate(
                        transactionUsername
                            ? ROUTE_FANPAGE.replace(':username', transactionUsername)
                            : ROUTE_HOME,
                        redirectState
                    )
                }, PAYMENTS_API_DELAY)
            }
        } catch (e) {
            console.log(e)
            // if response status 400, can redirect to home cause it isn't in pending yet
            if(e.response?.status === 400){
                await dispatch(userMe())
                navigate(
                    transactionUsername
                        ? ROUTE_FANPAGE.replace(':username', transactionUsername)
                        : ROUTE_HOME,
                    redirectState
                )
            }
        }
    }

    return (
        <OperationLoadingSpinner
            transactionID={transactionID}
            message={'We are verifying your payment...'}
        />
    )
}

export default PaymentCheck
