import { createUseStyles, useTheme } from 'react-jss'
import Banner from '../../../../../components/Banner'
import FormCard from '../../../../../components/FormCard'
import Button from '../../../../../components/Button'
import Checkbox from '../../../../../components/Checkbox'
import { SIGNUP_MODEL } from '../signupModel'
import { useFormContext } from 'react-hook-form'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { httpFetchFeaturedCreators } from '../../../../../http-requests'
import { useSelector } from 'react-redux'
import { selectUser } from '../../../../../store/slices/user'
import { InfoIcon, LoaderIcon } from '../../../../../theme/icons'
import Spinner from '../../../../../components/Spinner'
import cx from 'classnames'
import colors from '../../../../../theme/colors'

const useStyles = createUseStyles((theme) => ({
    categoryChoice: {
        position: 'absolute',
        height: '100%',
        width: '100%',
        overflowY: 'scroll',
        overflowX: 'hidden',
        background: '#fff',
        padding: 3,
        '&::-webkit-scrollbar': {
            display: 'none',
        },
    },
    banner: {
        fontSize: 12,
        color: theme.palette.primary.middle,
        backgroundColor: theme.palette.primary.buttonLight,
    },
    loadMoreSpinnerWrapper: {
        position: 'relative',
        width: 20,
        height: 20,
        animation: 'spin 1s linear infinite',
        alignSelf: 'center',
    },
    hide: {
        display: 'none',
    },
    fields: {
        marginTop: 16,
        position: 'relative',
        minHeight: 240,
        [theme.mediaQueries.mUp]: {
            height: '50vh',
        }
    },
    followBtn: {
        fontSize: '11px',
        width: '76px',
        fontWeight: 700,
        height: '32px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 8,
        background: 'transparent',
        color: colors.primary[500],
        transition: 'ease-in-out 300ms',
        cursor: 'pointer',
        border: `2px solid ${colors.primary[500]}`,
    },
    active: {
        transition: 'ease-in 300ms',
        background: colors.primary[500],
        border: `2px solid ${colors.primary[500]}`,
        color: colors.common.white,
    },
    customBox: {
        maxWidth: 350,
        padding: 8,
        display: "contents",
    },
    btnContainer: {
        padding: [16, 0],
    },
}))

const SignupFollowersChoice = () => {
    const classes = useStyles()
    const theme = useTheme()
    const user = useSelector(selectUser)
    const endRef = useRef(null)
    const [suggestions, setSuggestions] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [isLoadingMore, setIsLoadingMore] = useState(false)
    // Pagination
    const fetchLimit = 12
    const [currentPage, setCurrentPage] = useState(0)
    const [lastPage, setLastPage] = useState(0)
    const [perPage, setPerPage] = useState(12)
    const fetchMoreCondition = currentPage < lastPage && perPage >= fetchLimit
    const showBottomLoader = currentPage < lastPage

    const {
        register,
        watch,
        setValue,
        formState: { errors },
    } = useFormContext()

    const hasErrors = !!errors[SIGNUP_MODEL.followedIDs]

    const getFeaturedCreators = async (page, limit) => {
        try {
            const {
                data: { data, current_page, last_page, per_page },
            } = await httpFetchFeaturedCreators(user?.categories, page, limit)
            console.log(data)
            setCurrentPage(current_page)
            setLastPage(last_page)
            setPerPage(+per_page)
            setSuggestions([...suggestions, ...data])
        } catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        if (!currentPage) {
            fetchInitial()
        }
    }, [])

    const fetchInitial = async () => {
        setIsLoading(true)
        await getFeaturedCreators(1, fetchLimit)
        setIsLoading(false)
    }

    const fetchMore = async () => {
        if (fetchMoreCondition) {
            setIsLoadingMore(true)
            await getFeaturedCreators(currentPage + 1, fetchLimit)
            setIsLoadingMore(false)
        } else if (currentPage < lastPage && perPage < fetchLimit) {
            fetchInitial()
        }
    }

    const intersectionObserver = useMemo(
        () =>
            new IntersectionObserver(
                (entries) => {
                    const first = entries[0]
                    if (first.isIntersecting) {
                        fetchMore()
                    }
                },
                {
                    threshold: 0,
                    rootMargin: '0px 0px 100px 0px',
                }
            ),
        [suggestions, currentPage, lastPage]
    )

    useEffect(() => {
        if (endRef.current) {
            intersectionObserver.observe(endRef.current)
        }
        return () => {
            if (endRef.current) {
                intersectionObserver.unobserve(endRef.current)
            }
        }
    }, [endRef.current, intersectionObserver])

    const onFollowToggle = (name, value, id) => {
        value ? setValue(name, false) : setValue(name, id)
    }

    return (
        <>
            <Banner
                icon={
                    <InfoIcon
                        stroke={
                            hasErrors
                                ? theme.palette.primary.middle
                                : theme.palette.primary.middle
                        }
                    />
                }
                variant={hasErrors ? 'error' : 'default'}
                text={
                    hasErrors
                        ? errors[SIGNUP_MODEL.followedIDs].message
                        : 'Select the influencers you want to follow.'
                }
                className={classes.banner}
            />
            <div className={classes.fields}>
                <div className={classes.categoryChoice}>
                    {isLoading && <Spinner overlayFullscreen={true} />}
                    {suggestions &&
                        suggestions.map(
                            (
                                {
                                    id,
                                    display_name,
                                    avatar,
                                    username,
                                    is_verified,
                                    category,
                                },
                                idx
                            ) => {
                                const isFollowed = watch(
                                    `${SIGNUP_MODEL.followedIDs}.${idx}`
                                )
                                return (
                                    <FormCard
                                        key={idx}
                                        image={avatar?.[128]}
                                        color={'secondary'}
                                        overline={`@${username}`}
                                        text={display_name}
                                        hasBadge={is_verified}
                                        isCreators={true}
                                        category={category?.name}
                                    >
                                        <Checkbox
                                            className={classes.hide}
                                            id={`${SIGNUP_MODEL.followedIDs}.${idx}`}
                                            value={id}
                                            defaultChecked={false}
                                            {...register(
                                                `${SIGNUP_MODEL.followedIDs}.${idx}`
                                            )}
                                        />
                                        <button
                                            className={cx(
                                                classes.followBtn,
                                                isFollowed && classes.active
                                            )}
                                            type={'button'}
                                            onClick={() =>
                                                onFollowToggle(
                                                    `${SIGNUP_MODEL.followedIDs}.${idx}`,
                                                    isFollowed,
                                                    id
                                                )
                                            }
                                        >
                                            {isFollowed ? 'Unfollow' : 'Follow'}
                                        </button>
                                    </FormCard>
                                )
                            }
                        )}
                    {showBottomLoader && <div ref={endRef} />}
                    {isLoadingMore && (
                        <div className={classes.loadMoreSpinnerWrapper}>
                            <LoaderIcon />
                        </div>
                    )}
                </div>
            </div>

            <div className={classes.btnContainer}>
                <Button width={'100%'} variant={'filled'} type={'submit'}>
                    Register
                </Button>
            </div>
        </>
    )
}

export default SignupFollowersChoice
