import {createUseStyles} from 'react-jss'
import React, {useEffect, useState} from 'react'
import Box from '../../../components/Box'
import Spinner from '../../../components/Spinner'
import SubscriptionForm from './subscription-form'
import Divider from '../../../components/Divider'
import Banner from '../../../components/Banner'
import {FilledClose, SubscriptionSettingsIcon} from '../../../theme/icons'
import BundleForm from './bundle-form'
import Button from '../../../components/Button'
import cx from 'classnames'
import {
    httpDeleteBundles,
    httpListBundles,
} from '../../../http-requests/subscription'
import {handleApiError} from '../../../utilities/helpers'
import {useForm} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import {validationSchema} from './bundle-form/BundleFormModel'

const useStyles = createUseStyles((theme) => ({
    sectionTitle: {
        letterSpacing: 0.5,
        fontSize: 16,
        fontWeight: 500,
        lineHeight: '140%',
        color: theme.palette.text.primary,
        margin: [theme.spacing, 0],
    },

    sectionSubTitle: {
        fontWeight: 400,
        fontSize: 16,
        letterSpacing: 0.5,
        color: theme.palette.text.secondary,
        paddingBottom: theme.spacing,
    },

    box: {
        margin: [theme.spacing, 0],
        [theme.mediaQueries.mUp]: {
            margin: [theme.spacing * 2, 0],
        },
    },

    boxMobile: {
        marginBottom: theme.spacing * 16,
        [theme.mediaQueries.mUp]: {
            marginBottom: theme.spacing * 7,
        },
    },

    divider: {
        margin: [theme.spacing, 0],
    },

    wrapperBanner: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing * 2,
        padding: [theme.spacing * 2, 0],
    },

    banner: {
        ...theme.utils.grid.centered,
        gridTemplateColumns: "auto 1fr auto",
        alignItems: 'center',
        textAlign: "center",
        padding: theme.spacing,
        backgroundColor: theme.palette.background.section,
        color: theme.palette.text.primary,
        fontSize: 14,
        [theme.mediaQueries.mUp]: {
            textAlign: 'left',
            fontSize: 16,
            padding: [theme.spacing, theme.spacing * 2],
        },
    },

    iconClose: {
        cursor: 'pointer',
        '& svg': {
            gridColumn: 'none',
        },
    },
}))

const SubscriptionSettings = ({user, ...props}) => {
    const [isLoading, setIsLoading] = useState(false)
    const [isOpenBundle, setIsOpenBundle] = useState(false)
    const [bundlesList, setBundlesList] = useState([])

    useEffect(() => {
        getBundleList()
    }, [user?.subscriptions_dollar_price])

    const formMethods = useForm({
        shouldUnregister: false,
        mode: 'all',
        reValidateMode: 'all',
        nativeValidation: false,
        defaultValues: {},
        resolver: yupResolver(validationSchema),
    })
    const {
        setError,
        formState: {},
    } = formMethods

    const getBundleList = async () => {
        setIsLoading(true)
        try {
            const {data} = await httpListBundles({user_id: user?.id})
            setBundlesList(data.data)
        } catch (error) {
            handleApiError({
                isReduxError: false,
                error,
                callbackOnFieldError: setError,
            })
        } finally {
            setIsLoading(false)
        }
    }

    const deleteBundles = async (getID) => {
        setIsLoading(true)
        try {
            await httpDeleteBundles({bundle_id: getID})
            setBundlesList(
                bundlesList?.filter((item) => {
                    return item?.id !== getID
                })
            )
            setIsLoading(false)
        } catch (error) {
            handleApiError({
                isReduxError: false,
                error,
                callbackOnFieldError: setError,
            })
        } finally {
            setIsLoading(false)
        }
    }

    const classes = useStyles()
    return <>
        {isLoading && <Spinner overlayFullscreen={true}/>}
        <p className={classes.sectionTitle}>Subscriptions Settings</p>
        <Box classNames={classes.box}>
            <p className={classes.sectionTitle}>Monthly Plan</p>
            <SubscriptionForm/>
        </Box>

        <Box classNames={cx(classes.box, classes.boxMobile)}>
            <p className={classes.sectionTitle}>Subscription Bundles</p>
            <p className={classes.sectionSubTitle}>
                Off Several months of subscription as a discounted bundle
            </p>

            <Divider className={classes.divider}/>

            <div className={classes.wrapperBanner}>
                {bundlesList.map(({duration_in_months, dollar_price, id}, index) => {
                    return <Banner
                        key={index}
                        icon={<SubscriptionSettingsIcon/>}
                        iconSize={36}
                        className={classes.banner}
                    >
                        Get {duration_in_months} months for $
                        {dollar_price?.toFixed(2)}(TOTAL)
                        <FilledClose
                            className={classes.iconClose}
                            onClick={() => deleteBundles(id)}
                        />
                    </Banner>
                })}
            </div>


            {isOpenBundle ? (
                <BundleForm
                    setIsOpenBundle={setIsOpenBundle}
                    isOpenBundle={isOpenBundle}
                    setIsLoading={setIsLoading}
                    isLoading={isLoading}
                    setBundlesList={setBundlesList}
                    bundlesList={bundlesList}
                    inputPriceMonthlyPlan={user?.subscriptions_dollar_price}
                />
            ) : (
                <Button
                    width={'100%'}
                    disabled={!user?.subscriptions_dollar_price}
                    onClick={() =>
                        setIsOpenBundle(
                            user?.subscriptions_dollar_price &&
                            !isOpenBundle
                        )
                    }
                >
                    Add Bundle
                </Button>
            )}
        </Box>
    </>
}

export default SubscriptionSettings
