import { createUseStyles } from 'react-jss'
import Button from '../../../../../../components/Button'
import colors from '../../../../../../theme/colors'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import Spinner from '../../../../../../components/Spinner'
import {
    handleApiError,
    remapApiOptions,
} from '../../../../../../utilities/helpers'
import Divider from '../../../../../../components/Divider'
import WalletToken from '../index'
import Input from '../../../../../../components/Input'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { SUCCESS } from '../../../../../../utilities/constants'
import {
    httpAddWalletsNetworkToken,
    httpDeleteToken,
    httpGetNetworksStandard,
} from '../../../../../../http-requests/wallet'
import { editTokenSchema, WALLET_MODEL } from '../../../WalletModel'
import {
    selectTokens,
    updateWallet,
} from '../../../../../../store/slices/wallet'
import { setAlertMessage } from '../../../../../../store/slices/app'
import Select from '../../../../../../components/Select'
import UserAvatar from '../../../../../homepage/posts-feed/post/post-header/user-info/user-avatar'

const useStyles = createUseStyles((theme) => ({
    root: {
        padding: 16,
        '& span': {
            '& svg': { margin: '32px auto', display: 'block' },
        },
        '& p': {
            marginBottom: 32,
            textAlign: 'center',
            '& b': {
                fontSize: 16,
                fontWeight: 700,
                color: colors.quaternary[500],
            },
        },
    },
    walletTitle: {
        display: 'grid',
        gridTemplateColumns: '1fr auto',
        alignItems: 'center',
        '& p': {
            margin: 0,
            textAlign: 'left',
            '& > strong': {
                fontSize: 14,
                fontWeight: 700,
                color: theme.palette.primary.background,
            },
        },
    },
    divider: {
        width: '100%',
        height: 1,
        color: colors.greyscale[200],
        margin: [16, 0],
    },
    fields: {
        display: 'grid',
        gap: 16,
        margin: [16, 0],
    },
    tokenLabel: {
        display: 'grid',
        alignItems: 'center',
        gridTemplateColumns: 'auto 1fr',
        gridColumnGap: 8,
    },
}))

const EditToken = ({
    token,
    wallet,
    walletNetworkId,
    networkIndex,
    onCloseCb,
}) => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const tokens = useSelector(selectTokens)
    const [networkStandards, setNetworkStandards] = useState(null)
    const [loading, setLoading] = useState(false)
    const {
        register,
        handleSubmit,
        watch,
        setValue,
        control,
        formState: { touchedFields, errors, isSubmitting },
    } = useForm({
        shouldUnregister: true,
        mode: 'onBlur',
        reValidateMode: 'onChange',
        nativeValidation: false,
        defaultValues: {},
        resolver: yupResolver(editTokenSchema),
    })
    const passwordFilled = watch(WALLET_MODEL.password)

    useEffect(() => {
        const newStandard = wallet.wallet_networks?.filter(
            (item) => item?.id === +walletNetworkId
        )[0]?.network_standard
        setValue(WALLET_MODEL.networkStandardId, {
            label: newStandard?.name,
            value: newStandard?.id,
        })
    }, [wallet])

    useEffect(() => {
        fetchNetworkStandards()
    }, [])

    const fetchNetworkStandards = async () => {
        try {
            const networkId = wallet.wallet_networks?.filter(
                (item) => item?.id === +walletNetworkId
            )[0]?.network_id
            const { data: standards } = await httpGetNetworksStandard(networkId)
            setNetworkStandards(
                standards?.map((standard) => ({
                    label: standard?.name,
                    value: standard?.value,
                }))
            )
        } catch (error) {
            handleApiError({
                isReduxError: false,
                error,
            })
        }
    }

    const onAddToken = async (newToken) => {
        try {
            const { data } = await httpAddWalletsNetworkToken(
                wallet?.id,
                walletNetworkId,
                newToken
            )
            const createdWallet = { ...wallet, wallet_networks: [{ ...data }] }
            dispatch(updateWallet({ wallet: createdWallet }))
            dispatch(
                setAlertMessage({
                    message: 'Token successfully updated!',
                    variant: SUCCESS,
                })
            )
            onCloseCb()
        } catch (error) {
            return handleApiError({
                isReduxError: false,
                error,
            })
        }
    }

    const onEditToken = async (values) => {
        setLoading(true)
        try {
            const { data: newNetworkObj } = await httpDeleteToken(
                wallet?.id,
                walletNetworkId,
                token?.id,
                values?.[WALLET_MODEL.password]
            )
            const walletNetworks = wallet.wallet_networks?.map((item) => {
                if (+item?.id === +walletNetworkId) {
                    item = { ...newNetworkObj }
                }
                return item
            })
            dispatch(
                updateWallet({
                    wallet: { ...wallet, wallet_networks: [...walletNetworks] },
                })
            )
            await onAddToken(values?.[WALLET_MODEL.tokenId].value)
        } catch (error) {
            return handleApiError({
                isReduxError: false,
                error,
            })
        } finally {
            setLoading(false)
        }
    }

    const remapTokenApiOptions = (options) => {
        const tokenAlreadyAdded =
            wallet?.wallet_networks[networkIndex ? networkIndex : 0]?.tokens
        const filteredTokens = options?.filter(
            ({ id: id1 }) =>
                !tokenAlreadyAdded.some(({ id: id2 }) => id2 === id1)
        )

        return filteredTokens?.map(({ id, name, avatar, ...rest }) => ({
            value: id,
            label: (
                <span className={classes.tokenLabel}>
                    <UserAvatar avatar={avatar?.[128]} width={24} height={24} />{' '}
                    {name}
                </span>
            ),
            //data: rest,
        }))
    }

    return (
        <div className={classes.root}>
            {loading && <Spinner />}
            <div className={classes.walletTitle}>
                <p>
                    <strong>Wallet address</strong>
                    <br />
                    {wallet?.address}
                </p>
            </div>
            <Divider className={classes.divider} />
            <WalletToken token={token} />

            <form onSubmit={handleSubmit(onEditToken)}>
                <div className={classes.fields}>
                    <Input
                        iconPosition={null}
                        type={'password'}
                        label={'Password'}
                        helpText={
                            'Enter the password to be able to delete your wallet'
                        }
                        placeholder={'Enter your Password'}
                        touched={touchedFields[WALLET_MODEL.password]}
                        errors={errors[WALLET_MODEL.password]}
                        {...register(WALLET_MODEL.password)}
                    />
                </div>
                <div className={classes.fields}>
                    <Select
                        control={control}
                        disabled={!passwordFilled}
                        label={'Token Name'}
                        placeholder={'Select a token'}
                        name={WALLET_MODEL.tokenId}
                        options={remapTokenApiOptions(tokens)}
                        errors={errors[WALLET_MODEL.tokenId]}
                        multiValueCustomStyle={multiValueCustomStyle}
                        multiValueLabelCustomStyle={multiValueLabelCustomStyle}
                        singleValueCustomStyle={multiValueLabelCustomStyle}
                    />
                </div>
                <div className={classes.fields}>
                    <Select
                        disabled={true}
                        control={control}
                        helpText={
                            'The standard interface implemented by the token'
                        }
                        label={'Token Standard'}
                        placeholder={'Add Token standard'}
                        name={WALLET_MODEL.networkStandardId}
                        options={remapApiOptions(networkStandards)}
                        errors={errors[WALLET_MODEL.networkStandardId]}
                        multiValueCustomStyle={multiValueCustomStyle}
                        multiValueLabelCustomStyle={multiValueLabelCustomStyle}
                        singleValueCustomStyle={multiValueLabelCustomStyle}
                    />
                </div>

                <Button
                    disabled={isSubmitting || !passwordFilled}
                    width={'100%'}
                    type={'submit'}
                    variant={'filled'}
                >
                    Apply changes
                </Button>
            </form>
        </div>
    )
}

const multiValueCustomStyle = {
    padding: '10px 4px',
    borderRadius: 8,
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'rgba(121, 65, 117, 0.05)',
}

const multiValueLabelCustomStyle = {
    color: '#2E2E2E',
    fontWeight: 400,
    fontSize: 16,
    letterSpacing: 0.5,
}

export default EditToken
