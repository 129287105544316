import { setAlertMessage } from '../store/slices/app'
import store from '../store'
import { FAILURE } from './constants'
import dayjs from 'dayjs'

const transformCheckboxNumberValue = (e) => {
    const output = parseInt(e.target.value, 10)
    return isNaN(output) ? 0 : output
}

export const dispatchErrorMessage = (message) => {
    store.dispatch(
        setAlertMessage({ message, visible: true, variant: FAILURE })
    )
}

export const handleApiError = ({
    isReduxError = true,
    error: originalError,
    callbackOnFieldError,
    callbackOnGeneralError = dispatchErrorMessage,
    callbackOnFocusError,
    prependNameSpace,
}) => {
    const error = isReduxError
        ? { response: { data: { ...originalError } } }
        : originalError
    console.log(error)
    if (error?.response?.data) {
        console.log(error)
        Object.keys(error.response.data).forEach((errorFieldName, i) => {
            if (i === 0) {
                saferun(callbackOnFocusError, errorFieldName)
            }
            if (errorFieldName === 'message') {
                const errorValue = error.response.data[errorFieldName]
                // Generic error
                callbackOnGeneralError(errorValue)
            } else {
                // Form field error
                const errorValue = prependNameSpace
                    ? `${prependNameSpace}:${error.response.data[errorFieldName]}`
                    : error.response.data[errorFieldName]
                if(typeof callbackOnFieldError === 'function'){
                    callbackOnFieldError(errorFieldName, {
                        message: Array.isArray(errorValue)
                            ? errorValue.join(' ')
                            : errorValue,
                        type: 'backend',
                    })
                }
            }
        })
    }
}

export const capitalize = ([firstLetter, ...rest]) =>
    firstLetter.toUpperCase() + rest.join('')

export const remapApiOptions = (options) =>
    options?.map(({ id, name, ...rest }) => ({
        value: id,
        label: name,
        //data: rest,
    }))

export const retrieveSingleValueForRs = (options, value) => {
    if (
        value === null ||
        value === '' ||
        value === undefined ||
        options === null
    )
        return null
    return options.find(
        (option) => option.value.toString() === value.toString()
    )
}

export const retrieveValuesForRs = (options, values) => {
    if (
        values === null ||
        values === '' ||
        values === undefined ||
        values.length === 0 ||
        options === null
    )
        return null
    return options.filter((option) =>
        values.find((value) => option.value.toString() === value.id.toString())
    )
}

export const getInitials = (name) => {
    return name.match(/\b\w/g) || []
}
export const getHumanReadableDate = (date, format) => {
    if (format) return dayjs(date).format(format)
    const secondsDiff = dayjs().diff(dayjs(date), 's')
    switch (true) {
        case secondsDiff > 29030400:
            return dayjs(date).format('MM/DD/YYYY')
        case secondsDiff > 2419200:
            const months = dayjs().diff(dayjs(date), 'M')
            return `${months} ${months > 1 ? `months` : `month`} ago`
        case secondsDiff > 604800:
            const weeks = dayjs().diff(dayjs(date), 'w')
            return `${weeks} ${weeks > 1 ? `weeks` : `week`} ago`
        case secondsDiff > 86400:
            return `${dayjs().diff(dayjs(date), 'd')}d`
        case secondsDiff > 3600:
            return `${dayjs().diff(dayjs(date), 'h')}h`
        case secondsDiff > 60:
            return `${dayjs().diff(dayjs(date), 'm')}m`
        default:
            return `${secondsDiff}s`
    }
}

export const iterateOverTouchedFields = (
    touchedFields,
    formValues,
    excludedKeys
) => {
    let dataToSend = {}
    for (const key in touchedFields) {
        if (!excludedKeys?.includes(key)) {
            dataToSend = {
                ...dataToSend,
                [key]: formValues?.[key],
            }
        }
    }
    return dataToSend
}

export const getExtension = (url) => {
    return url.split('.').pop()
}

export const saferun = (callback, params) => {
    if (typeof callback === 'function') {
        params ? callback(params) : callback()
    }
}

export const copyTextToClipboard = async (text) => {
    if ('clipboard' in navigator) {
        return await navigator.clipboard.writeText(text)
    } else {
        return document.execCommand('copy', true, text)
    }
}

export const remapUnsubscribeReason = (reason = '') => {
    switch (reason) {
        case 'NO_REASON':
            return 'No specific reason'
        case 'EXP_SUB':
            return 'Subscription expensive'
        case 'SPAM':
            return 'Content spamming'
        case 'LOW_FREQ':
            return 'Low posting frequency'
        case 'NO_RENEW':
            return "Don't want to renew subscription"
        case 'NO_WALLET_TO_PAY':
            return 'Creator does not has active wallet'
        default:
            return ''
    }
}

export const remapSubscriptionStatus = (reason = '') => {
    switch (reason) {
        case 'active':
            return 'Active'
        case 'inactive':
            return 'Inactive'
        case 'to_renew':
            return 'To renew'
        case 'expired':
            return 'Expired'
        default:
            return 'Unknown'
    }
}

export const secondsToMinutes = (seconds) => {
    if(seconds % 60 === 0){
        return ` ${seconds / 60} ${(seconds / 60) > 1  ? 'minutes' : 'minute'}`
    }else{
         return ` ${(seconds / 60).toFixed(0)} ${(seconds / 60).toFixed(0) > 1   ? 'minutes' : 'minute'} and ${seconds % 60} ${(seconds % 60) > 1  ? 'seconds' : 'second'}`
    }
}
export const delay = (time) => {
    return new Promise((resolve) => setTimeout(resolve, time))
}

export const randomInteger = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1)) + min
}

export const asyncDebounce = (
    func,
    wait = 0,
    { cancelObj = 'canceled' } = {}
) => {
    let timerId, latestResolve, shouldCancel

    return function (...args) {
        shouldCancel = true
        return new Promise((resolve, reject) => {
            latestResolve = resolve
            timerId = setTimeout(
                invokeAtTrailing.bind(this, args, resolve, reject),
                wait
            )
        })
    }

    function invokeAtTrailing(args, resolve, reject) {
        if (shouldCancel && resolve !== latestResolve) {
            reject(cancelObj)
        } else {
            func.apply(this, args).then(resolve).catch(reject)
            shouldCancel = false
            clearTimeout(timerId)
            timerId = latestResolve = null
        }
    }
}

export const pubnubTimetokenToDate = (timetoken) => {
    return dayjs.unix(Number(timetoken) / 10000000)
}

export const dateToPubnubTimetoken = (date) => {
    return String(dayjs(date).unix() * 10000000)
}

const groupBy = (inputKeysArray, inputObjectValues) => {
    return inputKeysArray.reduce((finalObj, currentTimetokenKey) => {
        let groupKey = dayjs(currentTimetokenKey / 10000).format('YYYYMMDD');
        if (!finalObj[groupKey]) {
            finalObj[groupKey] = [];
        }
        finalObj[groupKey].push(inputObjectValues[currentTimetokenKey]);
        return finalObj;
    }, {});
};