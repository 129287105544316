import { createUseStyles } from 'react-jss'
import ChangePasswordForm from '../../authentication/change-password/change-password-form'
import colors from '../../../theme/colors'
import Box from '../../../components/Box'

const useStyles = createUseStyles((theme) => ({
    sectionTitle: {
        fontWeight: 'bold',
        fontSize: '18px',
        lineHeight: '140%',
        color: colors.tertiary[800],
        marginBottom: 24,
    },
}))

const ChangePasswordSection = () => {
    const classes = useStyles()

    return (
        <>
            <p className={classes.sectionTitle}>Change password</p>
            <Box>
                {' '}
                <ChangePasswordForm />{' '}
            </Box>
        </>
    )
}

export default ChangePasswordSection
