import * as yup from 'yup'

const POST_MODEL = {
    content: 'content',
    price: 'price',
    isPublic: 'is_public',
    isPremium: 'is_premium',
}

const postValidationSchema = yup.object().shape({
    [POST_MODEL.content]: yup.string().required('Content is mandatory'),
    [POST_MODEL.isPublic]: yup.bool(),
    [POST_MODEL.isPremium]: yup.bool(),
    [POST_MODEL.price]: yup
        .number()
        .nullable()
        .when(POST_MODEL.isPremium, {
            is: true,
            then: yup
                .number()
                .min(0.99, 'Post price must be minimum $0.99 USD')
                .typeError('You must specify a valid price'),
        })
        .test(
            'maxDigitsAfterDecimal',
            'Price can have a maximum of 2 decimals',
            (number) => /^\d+(\.\d{1,2})?$/.test(number)
        ),
})

export { POST_MODEL, postValidationSchema as validationSchema }
