import {createUseStyles} from 'react-jss'
import KeepSupportingHeader from './keep-supporting-header'
import KeepSupportingList from './keep-supporting-list'

import {useWindowSize} from '../../hooks/useWindowSize'
import React, {useEffect, useState} from 'react'
import {httpSubscriptionToRenew} from '../../http-requests'
import {handleApiError} from '../../utilities/helpers'
import {useNavigate} from 'react-router-dom'
import Spinner from '../../components/Spinner'
import {ROUTE_HOME} from "../../utilities/constants";

const useStyles = createUseStyles((theme) => ({
    keepSupporting: {
        ...theme.utils.flexbox.centered,
        gap: theme.spacing * 2,
        flexDirection: 'column',
        alignSelf: 'center',
        zIndex: 2,
        width: '100%',
        [theme.mediaQueries.mUp]: {
            gap: 30,
            paddingBottom: 0,
            maxWidth: 1076,
        },
    },
    listsWrapper: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing * 3,
        width: '100%',
    },
}))

const KeepSupporting = ({}) => {
    const navigate = useNavigate()
    const {width} = useWindowSize()
    const isMobile = width < 1024
    const [expiredSubscriptions, setExpiredSubscriptions] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        getInactiveSubscriptions()
    }, [])

    const getInactiveSubscriptions = async () => {
        setIsLoading(true)
        try {
            const { data } = await httpSubscriptionToRenew()
            if (!data.length) {
            navigate(ROUTE_HOME)
            }
            setExpiredSubscriptions(data)
        } catch (error) {
            handleApiError({
                isReduxError: false,
                error,
            })
        } finally {
            setIsLoading(false)
        }
    }

    const classes = useStyles()
    return (
        <>
            {isLoading && <Spinner/>}
            <div className={classes.keepSupporting}>
                <KeepSupportingHeader/>
                <div className={classes.listsWrapper}>
                    {expiredSubscriptions?.map(({token, subscriptions}, index) => {
                        return (
                            <KeepSupportingList
                                index={index}
                                token={token}
                                isMobile={isMobile}
                                expiredSubscriptions={subscriptions}/>
                        )
                    })}
                </div>
            </div>
        </>
    )
}

export default KeepSupporting
