import React, { useRef, useState } from 'react'
import axios from 'axios'
import {handleApiError} from "../utilities/helpers";

/**
 * Usage example:
 *
 *    const {
 *         component: FileUploader,
 *         states: { media, loading },
 *         setStates: { setMedia, setLoading },
 *     } = useFileUploader({
 *         uploadHandler: httpUploadPostMedia,
 *         handlerParams: { name: 'user_id', value: '164' },
 *     })
 *
 *     ... ... ...
 *
 *    <FileUploader {...props}>
 *        {children}
 *    </FileUploader>
 **/

const useFileUploader = ({ uploadHandler, handlerParams }) => {
    const uploaderRef = useRef()
    const cancelFileUpload = useRef(null)
    const [uploadedMediaError, setUploadedMediaError] = useState(null)
    const [uploadError, setUploadError] = useState('')
    const [isMediaLoaderCanceled, setIsMediaLoaderCanceled] = useState(false)
    const [loading, setLoading] = useState(false)
    const [progress, setProgress] = useState({})
    const [media, setMedia] = useState([])

    const progressFn = (progressEvent) => {
        setProgress(
            Math.round((progressEvent.loaded * 100) / progressEvent.total)
        )
    }

    const uploader = (e) => {
        const files = e?.target?.files

        Object.values(files)?.forEach(async (file, index) => {
            const formData = new FormData()
            const mediaType = file?.type?.split(/(\/)/)[0]
            const fileSize = file?.size / 1000000
            if (
                (mediaType === 'image' && fileSize >= 5) ||
                (mediaType === 'video' && fileSize >= 100)
            ) {
                setUploadedMediaError(true)
                uploaderRef.current.value = ''
                return null
            } else {
                setUploadedMediaError(false)
            }

            if (handlerParams) {
                formData.append(handlerParams.name, handlerParams.value)
            }
            formData.append(`${mediaType}`, file)

            if (formData && (mediaType === 'video' || mediaType === 'image')) {
                const controller = new AbortController()
                cancelFileUpload.current = controller
                try {
                    setIsMediaLoaderCanceled(false)
                    setLoading(true)
                    const options = {
                        onUploadProgress:
                            Object.keys(files).length === 1 && progressFn,
                        signal: controller.signal,
                    }
                    const { data } = await uploadHandler(
                        formData,
                        mediaType,
                        options
                    )
                    setMedia((media) => [
                        ...media,
                        {
                            id: data?.id,
                            media: data?.path_location,
                            type: mediaType,
                            fileName: e?.target?.files[0].name,
                            fileSize: e?.target?.files[0].size / 1000000,
                            preview: data?.[128],
                            ...(mediaType === 'video' && {
                                videoPreview: data['original'],
                            }),
                        },
                    ])
                    setLoading(false)
                    setProgress(0)
                } catch (error) {
                    handleApiError({
                        isReduxError: false,
                        error,
                    })
                    if (axios.isCancel(error)) {
                        setLoading(false)
                        setProgress(0)
                        setIsMediaLoaderCanceled(true)
                    } else {
                        setUploadError(error)
                        setProgress(0)
                        console.log(error)
                    }
                } finally {
                    if (uploaderRef.current) {
                        uploaderRef.current.value = ''
                    }
                }
            }
        })
    }

    const FileUploader = ({ children, ...props }) => {
        return (
            <label htmlFor={'input'}>
                <input
                    type="file"
                    id={'input'}
                    onChange={uploader}
                    accept="image/jpg, image/png, image/jpeg, image/heif, image/heic, video/mp4, video/mov, video/qt, video/quicktime"
                    ref={uploaderRef}
                    style={{ display: 'none' }}
                    {...props}
                />
                {children}
            </label>
        )
    }

    return {
        component: FileUploader,
        states: {
            uploaderRef,
            cancelFileUpload,
            uploadedMediaError,
            uploadError,
            isMediaLoaderCanceled,
            loading,
            progress,
            media,
        },
        setStates: {
            setUploadedMediaError,
            setUploadError,
            setIsMediaLoaderCanceled,
            setLoading,
            setProgress,
            setMedia,
        },
    }
}

export default useFileUploader
