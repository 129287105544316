import { createUseStyles } from 'react-jss'
import colors from '../../../../../../theme/colors'

const useStyles = createUseStyles((theme) => ({
    root: {
        left: 0,
        right: 0,
        position: 'absolute',
        display: 'grid',
        gridAutoFlow: 'column',
        gridTemplateColumns: '15% 29% 15% 10% 10% auto',
        gridColumnGap: 16,
        alignItems: 'center',
        height: 40,
        padding: [0, 24],
        backgroundColor: theme.palette.primary.main,
        borderRadius: '16px 16px 0 0',
        '& span': {
            color: colors.common.white,
            fontSize: 12,
            fontWeight: 500,
        },
    },
}))

const SpentTableHeader = ({ columns }) => {
    const classes = useStyles({})

    return (
        <div className={classes.root}>
            {columns?.map((item, index) => (
                <span key={index}>{item}</span>
            ))}
        </div>
    )
}

export default SpentTableHeader
