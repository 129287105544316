import React, { useMemo, useState } from 'react'
import { createUseStyles } from 'react-jss'
import { MoreIcon, SuccessIcon, TrashCanIcon } from '../../../../theme/icons'
import NotificationInfo from './notificationInfo/NotificationInfo'
import {
    notifications,
    notificationsItemAssets,
    remapNotificationType,
    renderNotificationIcon,
} from '../../../../utilities/constants/notifications'
import PlatformSection from './platformSection/PlatformSection'
import ContextMenu from '../../../../components/ContextMenu'
import dayjs from 'dayjs'
import {
    PAYMENTS,
    ROUTE_FANPAGE,
    ROUTE_WATCH_SECTION_ID,
    USER_SETTINGS,
} from '../../../../utilities/constants'
import { useDispatch } from 'react-redux'
import {
    decrementNotificationsCounter,
    incrementNotificationsCounter,
} from '../../../../store/slices/user'
import LinkWrapper from '../../../../components/LinkWrapper'
import { handleApiError } from '../../../../utilities/helpers'
import {
    deleteNotification,
    markSingleNotificationAsRead,
    markNotificationAsUnread,
} from '../../../../store/slices/notifications'

const useStyles = createUseStyles((theme) => ({
    notificationItem: {
        display: 'flex',
        flexDirection: 'column',
        gap: 16,
        position: 'relative',
        padding: [16, 0],
        paddingLeft: 8,
    },
    row: {
        ...theme.utils.flexbox.spaceBetween,
    },
    notificationInfo: {
        width: '100%',
    },
    actionWrapper: {
        display: 'flex',
        alignItems: 'center',
        gap: 4,
        whiteSpace: 'nowrap',
    },
    elapsedTime: ({ extended }) => ({
        fontSize: 10,
        width: 'min-content',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        textAlign: 'end',
        color: theme.palette.text.disabled,
        ...(extended && {
            padding: [0, 4],
        }),
        fontWeight: 500,
        letterSpacing: 0.25,
    }),
    iconBackground: {
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: '#F3F3F3',
            borderRadius: 6,
        },
    },
    divider: {
        height: 16,
        borderLeft: '1px solid #E1E1E1',
    },
    contextMenu: {
        backgroundColor: '#FCFCFC',
        borderRadius: 12,
        width: 'max-content',
        padding: 16,
        gap: 8,
        top: 60,
    },
    menuActionWrapper: {
        display: 'flex',
        flexDirection: 'column',
        gap: 16,
    },
    menuAction: {
        ...theme.utils.flexbox.spaceBetween,
        gap: 8,
        whiteSpace: 'nowrap',
        cursor: 'pointer',
        '& p': {
            fontSize: 14,
            color: '#585858',
        },
        '& svg': {
            height: 16,
        },
    },
    link: {
        textDecoration: 'none !important',
    },
}))

const NotificationItem = ({ unreadFilterActive, data, extended }) => {
    const {
        id,
        type,
        from_user,
        created_at,
        seen_at,
        message,
        comment,
        post_like,
    } = data
    const dispatch = useDispatch()
    const [isContextMenuOpen, setIsContextMenuOpen] = useState(false)

    const typeRemapped = useMemo(() => remapNotificationType(type), [type])
    const assets = notificationsItemAssets[typeRemapped]

    const redirectRoute = useMemo(() => {
        switch (typeRemapped) {
            case notifications.COMMENT_REPLY:
            case notifications.COMMENT_POST:
                return ROUTE_WATCH_SECTION_ID.replace(
                    ':section',
                    'post'
                ).replace(':id', comment?.post_id)
            case notifications.LIKE_POST:
                return ROUTE_WATCH_SECTION_ID.replace(
                    ':section',
                    'post'
                ).replace(':id', post_like?.post_id)
            case notifications.FOLLOW:
                return ROUTE_FANPAGE.replace(':username', from_user?.username)
            case notifications.BUNDLE_BUY:
            case notifications.TIP:
            case notifications.SUBSCRIPTION:
                return USER_SETTINGS.replace(':section', PAYMENTS)
            default:
                return false
        }
    }, [typeRemapped])

    const markAsRead = async () => {
        try {
            setIsContextMenuOpen(false)
            if (seen_at) {
                await dispatch(markNotificationAsUnread(id))
                await dispatch(
                    incrementNotificationsCounter({
                        unreadFilter: unreadFilterActive,
                    })
                )
            } else {
                await dispatch(markSingleNotificationAsRead(id))
                await dispatch(
                    decrementNotificationsCounter({
                        unreadFilter: unreadFilterActive,
                    })
                )
            }
        } catch (error) {
            handleApiError({
                isReduxError: false,
                error,
            })
        }
    }

    const handleRemoveNotification = async () => {
        try {
            await dispatch(deleteNotification(id))
            await dispatch(
                decrementNotificationsCounter({
                    isDelete: true,
                    seen_at,
                })
            )
        } catch (error) {
            handleApiError({
                isReduxError: false,
                error,
            })
        } finally {
            setIsContextMenuOpen(false)
        }
    }

    const notifySeenOnOpen = () => {
        if (!seen_at) {
            dispatch(markSingleNotificationAsRead(id))
            dispatch(
                decrementNotificationsCounter({
                    unreadFilter: unreadFilterActive,
                })
            )
        }
    }

    const parsedDate = useMemo(() => {
        const secondsDiff = dayjs().diff(dayjs(created_at), 's')
        switch (true) {
            case secondsDiff < 60:
                return `now`
            case secondsDiff < 3600:
                const minutes = dayjs().diff(dayjs(created_at), 'm')
                return `${minutes} ${minutes > 1 ? `minutes` : `minute`} ago`
            case secondsDiff < 86400:
                const hours = dayjs().diff(dayjs(created_at), 'h')
                return `${hours} ${hours > 1 ? `hours` : `hour`} ago`
            default:
                return dayjs(created_at).format('hh:mm A')
        }
    }, [data])

    const classes = useStyles({ extended })
    return (
        <div className={classes.notificationItem}>
            <div className={classes.row}>
                <LinkWrapper
                    pathname={redirectRoute}
                    target={'_blank'}
                    classname={classes.link}
                    callback={notifySeenOnOpen}
                >
                    <NotificationInfo
                        avatar={from_user?.avatar?.[128]}
                        name={from_user?.display_name}
                        message={assets?.description}
                        decorator={assets?.decorator}
                        revenue={data?.revenue}
                        isSeen={seen_at}
                        icon={renderNotificationIcon(typeRemapped)}
                        classname={classes.notificationInfo}
                    />
                </LinkWrapper>
                <div className={classes.actionWrapper}>
                    <div className={classes.elapsedTime}>{parsedDate}</div>
                    {extended && (
                        <>
                            <div className={classes.divider} />
                            <MoreIcon
                                className={classes.iconBackground}
                                onClick={() =>
                                    setIsContextMenuOpen(!isContextMenuOpen)
                                }
                                width={30}
                                height={30}
                            />
                            {isContextMenuOpen && (
                                <ContextMenu
                                    className={classes.contextMenu}
                                    onCloseHandler={() =>
                                        setIsContextMenuOpen(false)
                                    }
                                >
                                    <div className={classes.menuActionWrapper}>
                                        <div
                                            className={classes.menuAction}
                                            onClick={markAsRead}
                                        >
                                            <p>
                                                Mark as{' '}
                                                {seen_at ? 'unread' : 'read'}
                                            </p>
                                            <SuccessIcon stroke={'#585858'} />
                                        </div>
                                        <div
                                            className={classes.menuAction}
                                            onClick={handleRemoveNotification}
                                        >
                                            <p>Remove this notification</p>
                                            <TrashCanIcon />
                                        </div>
                                    </div>
                                </ContextMenu>
                            )}
                        </>
                    )}
                </div>
            </div>
            {message && (
                <div className={classes.row}>
                    <PlatformSection
                        message={message}
                        type={typeRemapped}
                        title={data?.title}
                    />
                </div>
            )}
        </div>
    )
}

export default NotificationItem
