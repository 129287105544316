import {createUseStyles} from 'react-jss'
import {Link} from 'react-router-dom'
import {VerifiedBadgeIcon} from '../../../../../theme/icons'
import {ROUTE_FANPAGE} from '../../../../../utilities/constants'
import UserAvatar from '../../../../homepage/posts-feed/post/post-header/user-info/user-avatar'
import React from 'react'
import logo from '../../../../../asset/images/notifications/notificationPlatformLogo.png'
import cx from 'classnames'
import colors from '../../../../../theme/colors'

const useStyles = createUseStyles((theme) => ({
    notificationInfo: {
        pointerEvents: 'none',
    },
    section: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        gap: 12,
    },
    name: {
        display: 'flex',
        gap: 4,
        alignItems: 'center',
        marginBottom: 4,
        fontWeight: 700,
        color: theme.palette.primary.background,
        fontSize: 14,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: 120,
        [theme.mediaQueries.sUpM]: {
            maxWidth: 400,
        },
    },
    messageWrapper: {
        display: 'flex',
        fontSize: 12,
        color: theme.palette.text.primary,
    },
    message: {
        fontWeight: 400,
        color: theme.palette.text.secondary,
        letterSpacing: 0.25,

        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: ({usernameMaxWidth}) => usernameMaxWidth,
        [theme.mediaQueries.mUp]: {
            maxWidth: `240px !important`,
        },
    },
    decorator: {
        color: theme.palette.primary.middle,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: 80,
        fontWeight: 400,
        [theme.mediaQueries.mUp]: {
            maxWidth: 200,
        },
        '& span': {
            fontWeight: 900,
            padding: [0, 4],
        },
    },
    icon: {
        position: 'absolute',
        bottom: 1,
        right: -5,
        width: 16,
        height: 16,
        borderRadius: '50%',
        '& svg': {
            margin: `0px !important`,
        },
    },
    revenue: {
        width: 16,
        whiteSpace: 'nowrap',
        paddingTop: 8,
        borderTop: '1px solid #F3F3F3',
        color: theme.palette.text.secondary,
        letterSpacing: 0.25,
        fontSize: 12,
        marginLeft: 60,
        '& span': {
            letterSpacing: 0.25,
            color: '#15B76B',
        },
    },
    onlineDot: ({isSeen}) => ({
        position: 'absolute',
        top: '50%',
        left: -16,
        transform: 'translateY(-50%)',
        width: 10,
        height: 10,
        borderRadius: '50%',
        backgroundColor: isSeen
            ? theme.palette.disabled.main
            : colors.primary[300],
        border: `1px solid ${theme.palette.common.white}`,
    }),
}))

const NotificationInfo = ({
                              name,
                              message,
                              decorator,
                              revenue,
                              isSeen,
                              isVerified = false,
                              avatar,
                              usernameMaxWidth = 116,
                              icon,
                              classname,
                          }) => {

    const classes = useStyles({usernameMaxWidth, isSeen})
    return (
        <div className={cx(classes.notificationInfo, classname)}>
            <div className={classes.section}>
                <div className={classes.onlineDot}/>
                <UserAvatar
                    width={48}
                    height={48}
                    dotSize={16}
                    avatar={name ? avatar : logo}
                    customIcon={<div className={classes.icon}>{icon}</div>}
                />
                <div>
                    <div className={classes.name}>
                        {name ?? 'Happyfans'}
                        {isVerified && (
                            <VerifiedBadgeIcon width={20} height={20}/>
                        )}
                    </div>
                    <div className={classes.messageWrapper}>
                        <div className={classes.message}>{message}</div>
                        {decorator && (
                            <div className={classes.decorator}>
                                <span>·</span>
                                {decorator}
                            </div>
                        )}
                    </div>
                </div>
            </div>
            {revenue && (
                <div className={classes.section}>
                    <div className={classes.revenue}>
                        Revenue: <span>+ ${revenue}/month</span>
                    </div>
                </div>
            )}
        </div>
    )
}

export default NotificationInfo

NotificationInfo.propTypes = {}
