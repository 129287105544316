import React from 'react'
import { createUseStyles } from 'react-jss'
import { MessagesRoundIcon } from '../../theme/icons'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { ROUTE_MESSAGES } from '../../utilities/constants'
import colors from '../../theme/colors'
import { selectUnseenNotificationsCount } from '../../store/slices/user'
import {selectUnreadMessageCounts} from "../../store/slices/messages";

const useStyles = createUseStyles((theme) => ({
    messagesMenuIcon: {
        ...theme.utils.flexbox.centered,
        height: 46,
        width: 46,
        backgroundColor: theme.palette.common.white,
        borderRadius: '50%',
        border: `1px solid #F3F3F3`,
    },
    iconWrapper: {
        ...theme.utils.flexbox.centered,
        height: '100%',
        width: '100%',
        position: 'relative',
        '& svg': {
            fill: 'white !important',
        },
    },
    badge: {
        position: 'absolute',
        ...theme.utils.flexbox.centered,
        backgroundColor: colors.error[500],
        width: 16,
        height: 16,
        top: 6,
        right: 6,
        border: `2px solid #F3F3F3`,
        borderRadius: '50%',
        color: 'white',
        fontSize: 8,
        fontWeight: 600,
    },
    overlay: {
        height: '110vh',
        width: '110vw',
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 1,
        [theme.mediaQueries.mUp]: {
            top: theme.sizing.headerHeight,
        },
    },
    messagesPanelWrapper: {
        zIndex: 2,
        position: 'fixed',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        width: '100%',
        minHeight: 200,
        maxHeight: '90vh',
        maxWidth: 'calc(100vw - 32px)',

        backgroundColor: '#FCFCFC',
        borderRadius: 12,

        [theme.mediaQueries.mUp]: {
            position: 'absolute',
            bottom: 'auto',
            left: 'auto',
            transform: 'translateX(0)',
            top: 70,
            right: 72,
            maxWidth: 344,
            maxHeight: '80vh',
        },
    },
    chatsWrapper: {
        ...theme.utils.flexbox.centered,
        gap: 16,
        position: 'fixed',
        bottom: 0,
        right: 0,
        [theme.mediaQueries.mUp]: {
            marginRight: 80,
        },
    },
    chatItem: {
        background: colors.primary[200],
        [theme.mediaQueries.mUp]: {
            maxWidth: 328,
            height: 455,
        },
    },
}))

const MessagesMenuIcon = ({ callback, isOpen }) => {
    const location = useLocation()
    const navigate = useNavigate()
    //TODO add unseen MESSAGES count instead of notifications count
    const unreadMessages = useSelector(selectUnreadMessageCounts)

    const totalUnreadCount = Object.values(unreadMessages).reduce((acc, curr) => acc + curr, 0)


    const handleRedirect = () => {
        if (!location?.pathname.includes(ROUTE_MESSAGES)) {
            callback()
            navigate(ROUTE_MESSAGES)
        }
    }

    const classes = useStyles({})
    return (
        <div className={classes.messagesMenuIcon}>
            <div className={classes.iconWrapper}>
                <MessagesRoundIcon onClick={handleRedirect} />
                {totalUnreadCount > 0 && (
                    <div className={classes.badge}>
                        {totalUnreadCount < 10 ? totalUnreadCount : '9+'}
                    </div>
                )}
            </div>
        </div>
    )
}

export default MessagesMenuIcon
