import Banner from '../../../../../components/Banner'
import CardSelector from '../../../card-selector'
import { InfoIcon, SideArrowIcon } from '../../../../../theme/icons'
import { SIGNUP_MODEL } from '../signupModel'
import Button from '../../../../../components/Button'
import { createUseStyles, useTheme } from 'react-jss'
import Spinner from '../../../../../components/Spinner'
import { useFormContext } from 'react-hook-form'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { selectUser } from '../../../../../store/slices/user'
import {
    retrieveSingleValueForRs,
    retrieveValuesForRs,
} from '../../../../../utilities/helpers'
import fanIcon from '../../../../../asset/images/authentication/fanIcon.png'
import creatorIcon from '../../../../../asset/images/authentication/creatorIcon.png'

const useStyles = createUseStyles((theme) => ({
    formContent: {
        height: '100%',
        display: 'grid',
        gap: 24,
        gridTemplateRows: 'auto auto 1fr',
        alignItems: 'end',
    },
    categoryChoice: {
        overflowY: 'scroll',
    },
    banner: {
        fontSize: 12,
        color: theme.palette.primary.middle,
        backgroundColor: theme.palette.primary.buttonLight,
    },
    fields: {
        display: 'grid',
        gap: 16,
    },
    errors: {
        alignSelf: 'start',
        fontSize: 12,
        color: theme.palette.error.main,
    },
}))

const SignupRoleChoice = ({ categories }) => {
    const user = useSelector(selectUser)

    const {
        reset,
        formState: { errors },
    } = useFormContext()

    useEffect(() => {
        reset({
            [SIGNUP_MODEL.isCreator]: user && user[SIGNUP_MODEL.isCreator],
            [SIGNUP_MODEL.categoryID]: retrieveSingleValueForRs(
                categories,
                user?.category?.id
            ),
        })
    }, [categories, user])

    const hasErrors = !!errors[SIGNUP_MODEL.categoryID]
    const theme = useTheme()
    const classes = useStyles()

    return !categories.length ? (
        <Spinner />
    ) : (
        <div className={classes.formContent}>
            <Banner
                icon={
                    <InfoIcon
                        stroke={
                            hasErrors
                                ? theme.palette.error.main
                                : theme.palette.tertiary.main
                        }
                    />
                }
                variant={hasErrors ? 'error' : 'default'}
                text={
                    hasErrors
                        ? errors[SIGNUP_MODEL.categoryID].message
                        : 'Select if you want to proceed as a fan or creator.'
                }
                className={classes.banner}
            />
            <div className={classes.fields}>
                <CardSelector
                    image={fanIcon}
                    color={'secondary'}
                    overline={'Account'}
                    text={"I'm a Fan"}
                    inputCreatorName={SIGNUP_MODEL.isCreator}
                    inputCategoryName={SIGNUP_MODEL.categoryID}
                    defaultChecked={user && !user[SIGNUP_MODEL.isCreator]}
                />
                <CardSelector
                    image={creatorIcon}
                    color={'secondary'}
                    overline={'Account'}
                    text={"I'm a Creator"}
                    type={'creator'}
                    categories={categories}
                    inputCreatorName={SIGNUP_MODEL.isCreator}
                    inputCategoryName={SIGNUP_MODEL.categoryID}
                    defaultChecked={user && user[SIGNUP_MODEL.isCreator]}
                />
            </div>
            <Button
                width={'100%'}
                variant={'filled'}
                icon={<SideArrowIcon />}
                type={'submit'}
            >
                Continue
            </Button>
        </div>
    )
}

export default SignupRoleChoice
