import * as yup from 'yup'

const PREMIUM_POST_MODEL = {
    token: 'token',
}

const premiumPostValidationSchema = yup.object().shape({
    [PREMIUM_POST_MODEL.token]: yup
        .object()
        .required('You must choose a token to pay with')
        .typeError('Please select a valid token'),
})

export { PREMIUM_POST_MODEL, premiumPostValidationSchema as validationSchema }
