import React, { useState } from 'react'
import { createUseStyles } from 'react-jss'
import ContextMenu from '../../../../components/ContextMenu'
import {
    CloseIcon,
    MoreIcon,
    SettingIcon,
    SuccessIcon,
} from '../../../../theme/icons'
import Label from '../../../../components/Label'
import { useWindowSize } from '../../../../hooks/useWindowSize'
import { useDispatch, useSelector } from 'react-redux'
import {
    markAllNotificationsAsRead,
    selectIsLoading,
    setUnreadFilterActive,
} from '../../../../store/slices/notifications'
import { useNavigate } from 'react-router-dom'
import {
    NOTIFICATIONS_SETTINGS,
    ROUTE_NOTIFICATIONS,
    USER_SETTINGS,
} from '../../../../utilities/constants'
import Back from '../../../../components/Back'
import { handleApiError } from '../../../../utilities/helpers'
import { resetUnreadNotificationsCounter } from '../../../../store/slices/user'

const useStyles = createUseStyles((theme) => ({
    notificationsPanelHeader: {
        ...theme.utils.flexbox.centered,
        flexDirection: 'column',
    },
    section: {
        ...theme.utils.flexbox.spaceBetween,
        width: '100%',
        height: 60,
        padding: [0, theme.spacing * 2],
        borderBottom: '1px solid #F3F3F3',
    },
    title: {
        color: theme.palette.text.primary,
        fontWeight: 600,
        letterSpacing: 0.5,
        fontSize: 16,
    },
    contextMenu: {
        backgroundColor: '#FCFCFC',
        borderRadius: 12,
        width: 'max-content',
        padding: 16,
        gap: 8,
    },
    menu: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    iconBackground: {
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: '#F3F3F3',
            borderRadius: 6,
        },
    },
    filtersWrapper: {
        position: 'relative',
        ...theme.utils.flexbox.centered,
        gap: theme.spacing,
    },
    labelContent: {
        cursor: 'pointer',
        padding: [0, 8],
    },
    redirect: {
        cursor: 'pointer',
        color: theme.palette.link.normal,
        fontWeight: 700,
        letterSpacing: 0.75,
        fontSize: 14,
    },
    menuActionWrapper: {
        display: 'flex',
        flexDirection: 'column',
        gap: 16,
    },
    menuAction: {
        ...theme.utils.flexbox.spaceBetween,
        gap: 8,
        whiteSpace: 'nowrap',
        cursor: 'pointer',
        '& p': {
            fontSize: 14,
            color: '#585858',
        },
        '& svg': {
            height: 16,
        },
    },
    back: {
        width: '100%',
        display: 'flex',
        padding: [0, 10],
    },
    overlay: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(255,255,255,0.5)',
    },
}))

const NotificationsPanelHeader = ({
    extended,
    isMobilePage,
    notificationsCount,
    notReadCount = 0,
    unreadFilterActive,
    onClose,
}) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const isLoading = useSelector(selectIsLoading)
    const { width } = useWindowSize()
    const isMobile = width < 768
    const [isContextMenuOpen, setIsContextMenuOpen] = useState(false)

    const handleRedirect = () => {
        navigate(ROUTE_NOTIFICATIONS)
        onClose()
    }

    const markAllAsRead = async () => {
        try {
            await dispatch(markAllNotificationsAsRead())
            await dispatch(resetUnreadNotificationsCounter())
        } catch (error) {
            handleApiError({
                isReduxError: false,
                error,
            })
        }
    }

    const handleSettingsRedirect = () => {
        navigate(USER_SETTINGS.replace(':section', NOTIFICATIONS_SETTINGS))
        onClose()
    }

    const classes = useStyles({})
    return (
        <div className={classes.notificationsPanelHeader}>
            {isMobilePage && (
                <div className={classes.back}>
                    <Back callback={() => navigate(-1)} />
                </div>
            )}
            <div className={classes.section}>
                <div className={classes.title}>Notifications</div>
                <div
                    className={classes.menu}
                    onClick={() => setIsContextMenuOpen(!isContextMenuOpen)}
                >
                    <MoreIcon
                        className={classes.iconBackground}
                        width={30}
                        height={30}
                    />
                    {isMobile && !extended && (
                        <CloseIcon
                            className={classes.iconBackground}
                            onClick={() => onClose()}
                            width={20}
                            height={20}
                        />
                    )}
                    {isContextMenuOpen && (
                        <ContextMenu
                            className={classes.contextMenu}
                            onCloseHandler={() => setIsContextMenuOpen(false)}
                        >
                            <div className={classes.menuActionWrapper}>
                                <div
                                    className={classes.menuAction}
                                    onClick={markAllAsRead}
                                >
                                    <p>Mark all as read</p>
                                    <SuccessIcon stroke={'#585858'} />
                                </div>
                                <div
                                    className={classes.menuAction}
                                    onClick={handleSettingsRedirect}
                                >
                                    <p>Notifications Settings</p>
                                    <SettingIcon stroke={'#585858'} />
                                </div>
                            </div>
                        </ContextMenu>
                    )}
                </div>
            </div>
            {!isMobilePage && (
                <div className={classes.section}>
                    <div className={classes.filtersWrapper}>
                        {isLoading && <div className={classes.overlay}></div>}
                        <Label
                            color={'secondary'}
                            minHeight={28}
                            minWidth={50}
                            isActive={!unreadFilterActive}
                        >
                            <div
                                onClick={() =>
                                    dispatch(setUnreadFilterActive(false))
                                }
                                className={classes.labelContent}
                            >
                                All: {notificationsCount}
                            </div>
                        </Label>
                        <Label
                            minHeight={28}
                            minWidth={50}
                            color={'secondary'}
                            isActive={unreadFilterActive}
                        >
                            <div
                                onClick={() =>
                                    dispatch(setUnreadFilterActive(true))
                                }
                                className={classes.labelContent}
                            >
                                Not Reads: {notReadCount > 0 ? notReadCount : 0}
                            </div>
                        </Label>
                    </div>
                    {!extended && (
                        <div
                            className={classes.redirect}
                            onClick={handleRedirect}
                        >
                            See All
                        </div>
                    )}
                </div>
            )}
        </div>
    )
}

export default NotificationsPanelHeader
