import { createUseStyles } from 'react-jss'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import RevenueTableHeader from './revenue-table-header'
import RevenueTableRow from './revenue-table-row'
import { httpFetchActivities } from '../../../../../http-requests'
import { handleApiError } from '../../../../../utilities/helpers'
import RevenueSpentRowLoader from '../revenue-spent-row-loader'
import cx from 'classnames'
import { useWindowSize } from '../../../../../hooks/useWindowSize'
import RevenueTableMobileRow from './revenue-table-mobile-row'
import {
    httpPaymentsRevenue,
    httpPaymentsSpent,
} from '../../../../../http-requests/payments'
import dayjs from 'dayjs'

const useStyles = createUseStyles((theme) => ({
    root: {
        position: 'relative',
    },
    body: ({ isMobile }) => ({
        position: 'relative',
        paddingTop: isMobile ? 0 : 40,
        borderRadius: isMobile ? 'unset' : '16px 16px 0 0',
        boxShadow: isMobile
            ? 'unset'
            : '0px 1px 3px rgb(20 20 42 / 10%), 0px 0px 1px rgb(20 20 42 / 5%)',
        maxHeight: 'calc(100vh - 440px)',
        overflowX: 'hidden',
        overflowY: 'scroll',
        '&::-webkit-scrollbar': {
            display: 'none',
        },
    }),
    loader: {
        padding: [0, 8],
        borderRadius: 0,
        boxShadow: 'none',
        backgroundColor: '#FCFCFC',
    },
    isOdd: {
        backgroundColor: '#F9F9F9',
    },
}))

const RevenueTable = () => {
    const endRef = useRef(null)
    const { width } = useWindowSize()
    const loaderArray = Array.from(Array(10).keys())
    const isMobile = width <= 468
    //loader
    const [isLoading, setIsLoading] = useState(false)
    //expander (here to handle auto close of other expanded )
    const [expanded, setExpanded] = useState(null)
    //pagination
    const [isLoadingMore, setIsLoadingMore] = useState(false)
    const [revenues, setRevenues] = useState([])
    const [currentPage, setCurrentPage] = useState(0)
    const [lastPage, setLastPage] = useState(0)
    const [perPage, setPerPage] = useState(0)
    const fetchLimit = 15
    const fetchMoreCondition = currentPage < lastPage
    //classes
    const classes = useStyles({ isMobile })

    useEffect(() => {
        fetchInitial()
        return () => setRevenues([])
    }, [])

    const getRevenues = async (page) => {
        setIsLoadingMore(true)
        try {
            const {
                data: { data, current_page, last_page, per_page },
            } = await httpPaymentsRevenue(page, fetchLimit)
            if (current_page === 1) {
                setRevenues([...data])
            } else {
                setRevenues([...revenues, ...data])
            }
            setCurrentPage(current_page)
            setLastPage(last_page)
            setPerPage(+per_page)
        } catch (error) {
            handleApiError({
                isReduxError: false,
                error,
            })
        } finally {
            setIsLoadingMore(false)
        }
    }

    const fetchInitial = async () => {
        await getRevenues(1)
    }

    const fetchMore = async () => {
        if (fetchMoreCondition) {
            await getRevenues(currentPage + 1)
        } else if (currentPage < lastPage && perPage < fetchLimit) {
            fetchInitial()
        }
    }

    const intersectionObserver = useMemo(
        () =>
            new IntersectionObserver(
                (entries) => {
                    const first = entries[0]
                    if (first.isIntersecting) {
                        fetchMore()
                    }
                },
                {
                    threshold: 0,
                    rootMargin: '0px 0px 100px 0px',
                }
            ),
        [revenues, currentPage, lastPage]
    )

    useEffect(() => {
        if (endRef.current) {
            intersectionObserver.observe(endRef.current)
        }
        return () => {
            if (endRef.current) {
                intersectionObserver.unobserve(endRef.current)
            }
        }
    }, [endRef.current, intersectionObserver])

    const expandToggle = (index) => {
        index === expanded ? setExpanded(null) : setExpanded(index)
    }

    return (
        <div className={classes.root}>
            {!isMobile && (
                <RevenueTableHeader
                    columns={[
                        'DATE',
                        'TRANSACTION ID',
                        'TYPE',
                        'CRYPTO',
                        'AMOUNT',
                        'SUBJECT',
                    ]}
                />
            )}
            <div className={classes.body}>
                {!isLoading &&
                    revenues?.map((revenue, index) => {
                        return isMobile ? (
                            <RevenueTableMobileRow
                                key={index}
                                index={revenue?.id}
                                user={revenue?.user}
                                token={revenue?.token}
                                type={revenue?.type}
                                date={dayjs(revenue?.created_at)?.format(
                                    'DD/M/YYYY'
                                )}
                                externalID={revenue?.hash}
                                amount={revenue?.total_token_amount_usd_value?.toFixed(2)}
                                expandToggleCb={expandToggle}
                                expanded={expanded}
                            />
                        ) : (
                            <RevenueTableRow
                                key={index}
                                index={revenue?.id}
                                user={revenue?.user}
                                token={revenue?.token}
                                type={revenue?.type}
                                date={dayjs(revenue?.created_at)?.format(
                                    'DD MMM YYYY'
                                )}
                                externalID={revenue?.hash}
                                amount={revenue?.total_token_amount_usd_value?.toFixed(2)}
                            />
                        )
                    })}

                {fetchMoreCondition && <div ref={endRef} />}
                {(isLoading || isLoadingMore) &&
                    loaderArray.map((loader, index) => (
                        <RevenueSpentRowLoader
                            key={index}
                            classNames={cx(
                                classes.loader,
                                index % 2 !== 0 && classes.isOdd
                            )}
                        />
                    ))}
            </div>
        </div>
    )
}

export default RevenueTable
