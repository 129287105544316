import { createUseStyles } from 'react-jss'
import Box from '../../../../components/Box'
import ToggleSwitch from '../../../../components/ToggleSwitch'
import { InfoFilledIcon } from '../../../../theme/icons'
import { useFormContext } from 'react-hook-form'
import colors from '../../../../theme/colors'
import Input from '../../../../components/Input'
import { POST_MODEL } from '../postModel'
import React from 'react'

const useStyles = createUseStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing * 2,
        margin: [0, theme.spacing * 2],
        '& > p': {
            fontSize: 14,
        },
    },
    box: {
        ...theme.utils.flexbox.spaceBetween,
        padding: [0, theme.spacing * 2],
        gridTemplateColumns: 'auto auto',
        '& svg': {
            height: 25,
        },
    },
    infoWrapper: {
        ...theme.utils.flexbox.centered,
        gap: theme.spacing * 2,
        userSelect: ' none',
        '& > p': {
            fontSize: 16,
            fontWeight: 500,
            color: colors.tertiary[800],
        },
    },
    priceWrapper: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing,
        '& p': {
            fontWeight: 700,
        },
        '& p > span': {
            color: theme.palette.primary.main,
        },
    },
    currency: {
        color: theme.palette.input.placeholder,
    },
}))

const PaidPostSection = ({
    isPremium,
    isPublic,
    currency = 'USD',
    currencySymbol = '$',
}) => {
    const classes = useStyles()

    const {
        register,
        formState: { errors, touchedFields },
    } = useFormContext()

    return (
        <div className={classes.root}>
            <Box classNames={classes.box}>
                <div className={classes.infoWrapper}>
                    <InfoFilledIcon />
                    <p>Premium Content</p>
                </div>
                <ToggleSwitch
                    inputType={'checkbox'}
                    touched={touchedFields[POST_MODEL.isPremium]}
                    errors={errors[POST_MODEL.isPremium]}
                    {...register(POST_MODEL.isPremium)}
                    value={true}
                    disabled={isPublic}
                />
            </Box>
            {isPremium && (
                <div className={classes.priceWrapper}>
                    <p>
                        Post Price<span>*</span>
                    </p>
                    <Input
                        type={'text'}
                        placeholder={`${currencySymbol}0.99`}
                        statusIcon={
                            <span className={classes.currency}>{currency}</span>
                        }
                        iconPosition={'right'}
                        touched={touchedFields[POST_MODEL.price]}
                        errors={errors[POST_MODEL.price]}
                        {...register(POST_MODEL.price)}
                        value={true}
                    />
                </div>
            )}
        </div>
    )
}

export default PaidPostSection
