import {createUseStyles, useTheme} from 'react-jss'
import React, {useState} from 'react'
import {yupResolver} from '@hookform/resolvers/yup'
import {useDispatch, useSelector} from 'react-redux'
import {useForm, useWatch} from 'react-hook-form'
import SubscriptionSettingsCard from '../subscriptions-settings-card'
import ToggleSwitch from '../../../../components/ToggleSwitch'
import smallLogo from '../../../../asset/images/smallLogo.svg'
import {SUBSCRIPTIONS_MODEL, validationSchema} from './SubscriptionModel'
import Divider from '../../../../components/Divider'
import Input from '../../../../components/Input'
import MonthlyPlanModal from '../monthly-plan-modal/MonthlyPlanModal'
import {httpUpdateProfile} from '../../../../http-requests'
import {selectUser, updateUser, userMe} from '../../../../store/slices/user'
import {handleApiError} from '../../../../utilities/helpers'
import Button from '../../../../components/Button'
import {PencilIcon} from '../../../../theme/icons'

const useStyles = createUseStyles((theme) => ({
    currency: {
        fontSize: 16,
        fontWeight: 400,
        color: theme.palette.disabled.main,
    },
    priceWrapper: {
        ...theme.utils.grid.start,
        gridTemplateColumns: ({canEditAmount}) => canEditAmount ? '1fr' : '1fr min-content',
        gridTemplateRows: 'auto',
        gap: theme.spacing * 2,
        [theme.mediaQueries.mUp]: {
            ...theme.utils.grid.centered,
            alignItems: 'baseline',
            gridTemplateColumns: ({canEditAmount}) => canEditAmount && '1fr min-content',
            gap: theme.spacing * 2,
            '& svg': {
                fill: 'white !important',
                cursor: 'pointer',
            },
        },
    },
    priceCard: {
        '& > * > span': {
            color: ({canEditAmount}) =>
                canEditAmount
                    ? theme.palette.text.secondary
                    : theme.palette.disabled.main,
            fontWeight: 700,
            fontSize: 14,
        },
    },
    buttonWrapper: {
        ...theme.utils.grid.start,
        gridTemplateColumns: ({canEditAmount}) => canEditAmount ? '1fr 1fr' : '1fr ',
        gridTemplateRows: 'auto',
        marginTop: theme.spacing,
        marginBottom: theme.spacing,
        width: '100%',
        padding: 0,
        gap: theme.spacing * 2,
        borderLeft: ({canEditAmount}) => canEditAmount ? `none` : '1px solid #E1E1E1',
        [theme.mediaQueries.mUp]: {
            height: '100%',
            maxHeight: 56,
            display: 'flex',
            alignSelf: 'start',
            alignItems: 'center',
            gap: theme.spacing,
            padding: [0, theme.spacing * 2],
            margin: [4, 0],
            borderLeft: ({canEditAmount}) => canEditAmount && `1px solid #E1E1E1`,
        },
    },

    pencilIcon: {
        marginLeft: theme.spacing,
    },

    priceHint: {
        fontSize: 12,
        color: theme.palette.label.color,
        letterSpacing: 0.5,
    },
    divider: {
        margin: 0,
    },
    /*Subscription Plan*/
    buttonBundle: {
        padding: theme.spacing,
    },

    select: {
        minWidth: 320,
    },

    input: {
        '& span': {
          zIndex: 0
        },
    }
}))

// TODO get those vars from b-e
const currency = 'USD'
const currencySymbol = '$'

const SubscriptionForm = ({ ...props}) => {
    const theme = useTheme()
    const dispatch = useDispatch()
    const user = useSelector(selectUser)
    const [modalVariant, setModalVariant] = useState('')
    const [canEditAmount, setCanEditAmount] = useState(false)
    const [isLoading, setIsLoading] = useState(true)


    const formMethods = useForm({
        shouldUnregister: false,
        mode: 'all',
        reValidateMode: 'all',
        nativeValidation: false,
        defaultValues: {
            [SUBSCRIPTIONS_MODEL.isFree]: !user?.subscriptions_dollar_price,
        },
        resolver: yupResolver(validationSchema),
    })
    const {
        handleSubmit,
        register,
        setValue,
        control,
        setError,
        reset,
        formState: {touchedFields, errors, isValid},
    } = formMethods

    const onSubmit = async (formValues) => {
        let dataToSend = {
            subscriptions_dollar_price: parseFloat(
                formValues.subscription_price
            ),
        }
        try {
            setModalVariant('')
            const {data: user} = await httpUpdateProfile(dataToSend)
            await dispatch(updateUser(user))
            dispatch(userMe())
            setCanEditAmount(false)
        } catch (error) {
            handleApiError({
                isReduxError: false,
                error: error,
                callbackOnFieldError: setError,
            })
        }finally {
            setIsLoading(false)
        }
    }

    const isFree = useWatch({
        control,
        name: SUBSCRIPTIONS_MODEL.isFree,
        defaultValue: !user?.subscriptions_dollar_price,
    })

    const renderButton = () => {
        return (
            <div className={classes.buttonWrapper}>
                {canEditAmount ? (
                    <>
                        <Button
                            width={'100%'}
                            variant={'ghost'}
                            onClick={() => {
                                setCanEditAmount(false)
                                reset({
                                    [SUBSCRIPTIONS_MODEL.subscriptionPrice]:
                                    user?.subscriptions_dollar_price ?? 0,
                                })
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            width={'100%'}
                            onClick={() => {
                                isValid &&
                                setModalVariant(
                                    isFree
                                        ? 'free_to_paid'
                                        : 'amount_change'
                                )
                            }}
                        >
                            Save
                        </Button>
                    </>
                ) : (
                    <div className={classes.pencilIcon}>
                        <PencilIcon onClick={() => setCanEditAmount(true)}/>
                    </div>
                )}
            </div>
        )
    }

    const classes = useStyles({canEditAmount})
    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <SubscriptionSettingsCard
                title={'Free Subscriptions'}
                withIcon={false}
                action={
                    <ToggleSwitch
                        inputType={'radio'}
                        inputBgColor={theme.palette.secondary.main}
                        pointerBackground={smallLogo}
                        touched={touchedFields[SUBSCRIPTIONS_MODEL.isFree]}
                        errors={errors[SUBSCRIPTIONS_MODEL.isFree]}
                        {...register(SUBSCRIPTIONS_MODEL.isFree)}
                        value={true}
                        onClick={() =>
                            !!user?.subscriptions_dollar_price &&
                            setModalVariant('paid_to_free')
                        }
                        defaultChecked={isFree}
                    />
                }
            />
            <Divider className={classes.divider}/>
            <SubscriptionSettingsCard
                title={'Paid Membership'}
                withIcon={false}
                action={
                    <ToggleSwitch
                        inputType={'radio'}
                        inputBgColor={theme.palette.secondary.main}
                        pointerBackground={smallLogo}
                        touched={touchedFields[SUBSCRIPTIONS_MODEL.isFree]}
                        errors={errors[SUBSCRIPTIONS_MODEL.isFree]}
                        {...register(SUBSCRIPTIONS_MODEL.isFree)}
                        value={false}
                        defaultChecked={!isFree}
                    />
                }
            >
                {(!isFree || isFree === 'false') && (
                    <SubscriptionSettingsCard
                        title={'Subscriptions Price'}
                        withIcon={false}
                        className={classes.priceCard}
                    >
                        <div className={classes.priceWrapper}>
                            <Input
                                className={classes.input}
                                control={control}
                                type={'text'}
                                placeholder={`${currencySymbol}${
                                    user?.subscriptions_dollar_price === 0
                                        ? ' Enter your subscription price here'
                                        : user?.subscriptions_dollar_price
                                }`}
                                statusIcon={
                                    <span className={classes.currency}>
                                        {currency}
                                    </span>
                                }
                                iconPosition={'right'}
                                inputProps={{
                                    disabled: !canEditAmount,
                                }}
                                touched={
                                    touchedFields[
                                        SUBSCRIPTIONS_MODEL.subscriptionPrice
                                        ]
                                }
                                errors={
                                    errors[
                                        SUBSCRIPTIONS_MODEL.subscriptionPrice
                                        ]
                                }
                                {...register(
                                    SUBSCRIPTIONS_MODEL.subscriptionPrice
                                )}
                                value={true}
                            />
                            {renderButton()}
                        </div>
                        <span className={classes.priceHint}>
                            Monthly price, from $4.99 USD to $99.99 USD
                        </span>
                    </SubscriptionSettingsCard>
                )}
            </SubscriptionSettingsCard>

            {modalVariant && (
                <MonthlyPlanModal
                    setValue={setValue}
                    toFreeCallback={() => {
                        setValue(SUBSCRIPTIONS_MODEL.subscriptionPrice, 0)
                    }}
                    amountChangeCallback={() => {
                        setCanEditAmount(false)
                    }}
                    variant={modalVariant}
                    onClose={() => {
                        setModalVariant('')
                    }}
                    currentPrice={user?.subscriptions_dollar_price}
                />
            )}
        </form>
    )
}

export default SubscriptionForm
