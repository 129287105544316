import {createUseStyles} from 'react-jss'
import React from 'react'
import ItemText from '../itemText/ItemText'
import dayjs from 'dayjs'
import {useWindowSize} from '../../../../../../../hooks/useWindowSize'
import {useSelector} from "react-redux";
import {selectUser} from "../../../../../../../store/slices/user";

const useStyles = createUseStyles((theme) => ({
    itemDeleted: {
        display: 'flex',
        flexDirection: 'column',
        gap: 8,
    },
}))

const ItemDeleted = ({message, userData, children}) => {
    const me = useSelector(selectUser)
    const isMine = message?.uuid === me?.pubnub_id
    const hasText = message?.content

    const {width} = useWindowSize()
    const isMobile = width <= 468

    const canShowChild = !message?.actions?.delete_for_all
        && (message?.actions?.delete_for_me?.initiator_data?.[0]?.uuid !== me?.pubnub_id)

    const classes = useStyles({isMine, hasText})
    return (
        <div className={classes.itemDeleted}>
            {!canShowChild &&
                <div className={classes.row}>
                    <ItemText
                        message={{
                            ...message,
                            message: 'Message deleted',
                        }}
                        userData={userData}
                        isMobile={isMobile}
                        isMine={isMine}
                        hasContextMenu={false}
                    />
                </div>
            }
            {canShowChild && children}
        </div>
    )
}

export default ItemDeleted
