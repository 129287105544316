import { createUseStyles } from 'react-jss'
import colors from '../../../theme/colors'
import Box from '../../../components/Box'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import Input from '../../../components/Input'
import Divider from '../../../components/Divider'
import Button from '../../../components/Button'
import { DELETE_ACCOUNT_MODEL, validationSchema } from './DeleteAccountModel'
import { login, userDelete } from '../../../store/slices/user'
import { handleApiError } from '../../../utilities/helpers'
import { useDispatch } from 'react-redux'
import { useState } from 'react'
import Spinner from '../../../components/Spinner'
import { unwrapResult } from '@reduxjs/toolkit'
import { ROUTE_HOME } from '../../../utilities/constants'
import { useNavigate } from 'react-router-dom'

const useStyles = createUseStyles((theme) => ({
    sectionTitle: {
        fontWeight: 'bold',
        fontSize: '18px',
        lineHeight: '140%',
        color: colors.tertiary[800],
    },
    desc: {
        marginTop: 8,
        marginBottom: 24,
    },
    root: {
        display: 'grid',
        gridTemplateRows: 'auto 1fr',
        gap: 16,
        alignItems: 'end',
    },
    fields: {
        display: 'grid',
        gap: 16,
    },
}))

const DeleteAccountSection = () => {
    const dispatch = useDispatch()
    let navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const classes = useStyles()

    const {
        register,
        handleSubmit,
        setError,
        watch,
        formState: { touchedFields, errors, isSubmitting },
    } = useForm({
        shouldUnregister: true,
        mode: 'onBlur',
        reValidateMode: 'onChange',
        nativeValidation: false,
        defaultValues: {},
        resolver: yupResolver(validationSchema),
    })
    const passwordFilled = watch(DELETE_ACCOUNT_MODEL.password)

    const onSubmitHandler = async (values) => {
        setLoading(true)
        try {
            const data = await dispatch(userDelete(values?.password))
            unwrapResult(data)
            navigate(ROUTE_HOME)
        } catch (error) {
            handleApiError({
                error,
                callbackOnFieldError: setError,
            })
        } finally {
            setLoading(false)
        }
    }

    return (
        <>
            {loading && <Spinner overlayFullscreen={true} size={40} />}
            <p className={classes.sectionTitle}>Delete account</p>
            <p className={classes.desc}>
                Once you deleted account, you will lose your history and
                wishlist details
            </p>
            <Box>
                <form
                    className={classes.form}
                    onSubmit={handleSubmit(onSubmitHandler)}
                >
                    <div className={classes.fields}>
                        <Input
                            iconPosition={null}
                            type={'text'}
                            label={'Password'}
                            placeholder={'Enter your Password'}
                            touched={
                                touchedFields[DELETE_ACCOUNT_MODEL.password]
                            }
                            errors={errors[DELETE_ACCOUNT_MODEL.password]}
                            {...register(DELETE_ACCOUNT_MODEL.password)}
                        />
                    </div>
                    <Divider />
                    <Button
                        disabled={isSubmitting || !passwordFilled}
                        width={'100%'}
                        type={'submit'}
                        variant={'primary'}
                    >
                        Delete Account
                    </Button>
                    {/*{process.env.NODE_ENV === 'development' && (
                <DevTool control={control} />
            )}*/}
                </form>
            </Box>
        </>
    )
}

export default DeleteAccountSection
