import { createUseStyles } from 'react-jss'
import Box from '../../../components/Box'
import colors from '../../../theme/colors'
import { Splide, SplideSlide } from '@splidejs/react-splide'
import React, { useEffect, useState } from 'react'
import { httpFetchFeaturedByUser } from '../../../http-requests'
import SuggestionItem from './suggestion-item'
import { handleApiError } from '../../../utilities/helpers'

const useStyles = createUseStyles((theme) => ({
    box: {
        padding: 16,
        overflow: 'hidden',
        height: 586,
        width: 418,
        backgroundColor: '#FFF',
    },
    header: {
        height: 48,
        '& h5': {
            fontSize: 16,
            fontWeight: 700,
            color: colors.tertiary[800],
        },
    },
    cardContainer: {
        width: 396,
        height: '100%',
        padding: [2, 0],
        display: 'grid',
        alignItems: 'center',
        gap: 16,
        gridTemplateRows: '1fr 1fr',
        gridTemplateColumns: '184px 184px',
    },
    carousel: {
        '& .splide__arrow': {
            background: 'transparent',
            width: 'auto',
            zIndex: 'unset',
            '& svg': {
                fill: colors.greyscale[400],
            },
        },
        '& .splide__arrow--prev': {
            left: 'auto',
            position: 'absolute',
            top: '-38px',
            right: '68px',
        },
        '& .splide__arrow--next': {
            left: 'auto',
            position: 'absolute',
            top: '-38px',
            right: '24px',
        },
        '& .splide__pagination': {
            bottom: '-35px',
            zIndex: 'unset',
        },
        '& .splide__pagination__page': {
            border: '1px solid rgb(239, 233, 252, 100%)',
            opacity: 1,
            background: 'rgb(239, 233, 252, 100%)',
            height: 10,
            width: 10,
        },
        '& .splide__pagination__page.is-active': {
            background: theme.palette.primary.main,
            borderRadius: '10px',
            transform: 'none',
        },
    },
}))

const Suggestions = () => {
    const classes = useStyles()
    const [grouped, setGrouped] = useState([])

    useEffect(() => {
        fetchSuggestions()
    }, [])

    const fetchSuggestions = async () => {
        try {
            const { data } = await httpFetchFeaturedByUser()
            let x = new Array(Math.round(data?.data?.length / 4))
            let i = 0
            let y = 0
            while (i < data?.data.length) {
                x[y] = [...data?.data.slice(i, i + 4)]
                ++y
                i = i + 4
            }
            setGrouped([...x])
        } catch (error) {
            handleApiError({
                isReduxError: false,
                error,
            })
        }
    }

    const carouselOptions = {
        pagination: true,
        keyboard: false,
        fixedHeight: 468,
        gap: '1rem',
    }

    return (
        <Box classNames={classes.box}>
            <div className={classes.header}>
                <h5>Suggestions</h5>
            </div>
            <Splide options={carouselOptions} className={classes.carousel}>
                {grouped.map((group, groupIndex) => {
                    return (
                        <SplideSlide key={groupIndex}>
                            <div className={classes.cardContainer}>
                                {group.map((item, itemIndex) => (
                                    <SuggestionItem
                                        item={item}
                                        itemIndex={itemIndex}
                                        groupIndex={groupIndex}
                                        grouped={grouped}
                                        setGroupedCb={setGrouped}
                                        key={itemIndex}
                                    />
                                ))}
                            </div>
                        </SplideSlide>
                    )
                })}
            </Splide>
        </Box>
    )
}

export default Suggestions
