import Popover from '../../../../components/Popover'
import Box from '../../../../components/Box'
import UserInfo from '../../../homepage/posts-feed/post/post-header/user-info'
import Divider from '../../../../components/Divider'
import Banner from '../../../../components/Banner'
import Media from '../../../../components/Media'
import paymentProviderLogo from '../../../../asset/images/8-pay-logo.png'
import Button from '../../../../components/Button'
import { createUseStyles } from 'react-jss'
import { CheckedIcon } from '../../../../theme/icons'
import Select from '../../../../components/Select'
import { useForm, useWatch } from 'react-hook-form'
import React, { useEffect, useState } from 'react'
import {
    dispatchErrorMessage,
    handleApiError,
} from '../../../../utilities/helpers'
import {
    SUBSCRIPTION_MODEL,
    validationSchema,
} from '../../../creator-page/subscription-modal/subscriptionModel'
import { yupResolver } from '@hookform/resolvers/yup'
import { components } from 'react-select'
import { useNavigate, useParams } from 'react-router-dom'
import {
    httpPaidSubscribeToUser,
    httpUserTokens,
} from '../../../../http-requests'
import { ROUTE_FANPAGE } from '../../../../utilities/constants'
import Spinner from '../../../../components/Spinner'

const useStyles = createUseStyles((theme) => ({
    popover: {
        padding: theme.spacing * 2,
    },

    wrapper: {
        padding: theme.spacing,
    },

    wrapperContent: {
        paddingTop: theme.spacing * 2,
        paddingBottom: theme.spacing,
    },

    paymentProvider: {
        ...theme.utils.flexbox.centered,
        gap: theme.spacing,
        padding: 0,
        fontSize: 12,
        color: theme.palette.text.secondary,
        minHeight: 40,
        background: theme.palette.background.body,
        boxShadow: 'none',
        '& div': {
            height: 20,
            width: 43,
        },
    },

    banner: {
        backgroundColor: theme.palette.background.section,
        color: theme.palette.text.secondary,
        fontSize: 16,
    },

    subscription: {
        fontWeight: 500,
        fontSize: 10,
        color: theme.palette.text.inactive,
        paddingBottom: theme.spacing,
    },

    wrapperPrice: {
        display: 'flex',
        justifyContent: 'space-between',
        '& span': {
            fontWeight: 500,
            alignSelf: 'end',
            color: theme.palette.text.primary,
        },
        '& p': {
            alignSelf: 'end',
            fontSize: 14,
            fontWeight: 400,
            color: theme.palette.primary.middle,
            textAlign: 'right',
            [theme.mediaQueries.mUp]: {
                fontSize: 16,
            },
        },
        '& strong': {
            textDecoration: 'line-through',
        },
    },

    singleValue: {
        float: 'right',
        marginRight: theme.spacing,
    },
}))

const SubscriptionsFanPopover = ({
    popOver,
    setPopOver,
    user,
    data,
    setIsLoading,
    isLoading,
    ...props
}) => {
    const formMethods = useForm({
        shouldUnregister: false,
        mode: 'all',
        reValidateMode: 'all',
        nativeValidation: false,
        defaultValues: {},
        resolver: yupResolver(validationSchema),
    })
    const {
        control,
        handleSubmit,
        formState: {},
    } = formMethods

    const [tokens, setTokens] = useState([])
    const { username } = useParams()
    const navigate = useNavigate()

    const selectionToken = useWatch({
        control,
        name: SUBSCRIPTION_MODEL.token,
    })

    useEffect(() => {
        getTokens()
    }, [])

    const onSubmit = async (formValues) => {
        let dataToSend = {
            userID: user?.id,
            tokenID: formValues?.token?.value,
            bundleID: data?.id,
        }
        try {
            await httpPaidSubscribeToUser(dataToSend)
            if (!username) {
                navigate(
                    `${ROUTE_FANPAGE.replace(':username', user.username)}/post`
                )
            } else {
                window.location.reload()
            }
        } catch (error) {
            if (error.response?.status === 302 && error.response?.data?.url) {
                window.location.replace(error.response?.data?.url)
            } else {
                handleApiError({
                    isReduxError: false,
                    error,
                })
            }
        }
    }

    const getTokens = async () => {
        setIsLoading(true)
        try {
            const { data } = await httpUserTokens(user?.id)
            setTokens(
                data?.map(({ id, symbol, discount_percentage }) => ({
                    value: id,
                    label: symbol,
                    discount: discount_percentage,
                }))
            )
        } catch (error) {
            handleApiError({
                error,
                callbackOnGeneralError: dispatchErrorMessage,
            })
        } finally {
            setIsLoading(false)
        }
    }

    const CustomSingleValue = ({ children, ...props }) => {
        return (
            <components.SingleValue {...props}>
                {children}
                <div className={classes.singleValue}>
                    <Button
                        size={'small'}
                        variant={'ghost'}
                        data={'secondary'}
                        width={'auto'}
                    >
                        10% Discount
                    </Button>
                </div>
            </components.SingleValue>
        )
    }

    const indicatorsContainerCustomStyle = {
        alignItems: 'center',
    }

    const singleValueCustomStyle = {
        display: 'flex',
        placeContent: 'space-between',
        alignItems: 'center',
    }

    const isHappyTokenSelected = selectionToken?.label === 'HAPPY'
    const bundlePrice = data?.dollar_price

    const totalAmount = () => {
        if (isHappyTokenSelected) {
            const percentage = bundlePrice - (bundlePrice / 100) * 10
            return (
                <p>
                    <strong>${bundlePrice?.toFixed(2)}</strong> / $
                    {percentage?.toFixed(2)}
                </p>
            )
        } else {
            return <p>${bundlePrice?.toFixed(2)}</p>
        }
    }

    const classes = useStyles()
    return (
        <>
            <Popover
                className={classes.popover}
                title={'Bundle subscriptions'}
                maxWidth={539}
                closeOnOverlay={true}
                onClose={() => setPopOver(!popOver)}
            >
                <div className={classes.wrapper}>
                    <Box>
                        <UserInfo
                            user={user}
                            name={user?.display_name}
                            username={user?.username}
                            direction={'horizontal'}
                            control={control}
                            avatarHeight={68}
                            avatarWidth={68}
                            avatar={user?.avatar?.[128]}
                            isVerified={user?.is_verified}
                            dotSize={16}
                            category={
                                user?.categories?.[0]?.name ||
                                user?.category?.name
                            }
                            isOnline={user?.is_online}
                        />
                        <Divider />
                        <Box>
                            <p className={classes.subscription}>SUBSCRIPTION</p>
                            <div className={classes.wrapperPrice}>
                                <span>Bundle Price:</span>
                                <p>
                                    {data?.discount_percentage}% OFF · $
                                    {bundlePrice?.toFixed(2)} for{' '}
                                    {data?.duration_in_months} months
                                </p>
                            </div>
                        </Box>

                        <div className={classes.wrapperContent}>
                            <Banner
                                className={classes.banner}
                                icon={<CheckedIcon />}
                                text={'Full access to this user’s content'}
                            />
                        </div>
                    </Box>

                    {isLoading && <Spinner overlayFullscreen={true} />}
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className={classes.wrapperContent}>
                            <Select
                                components={
                                    isHappyTokenSelected && {
                                        SingleValue: CustomSingleValue,
                                    }
                                }
                                options={tokens}
                                control={control}
                                label={'Token Name'}
                                placeholder={'Choose a Token to pay with...'}
                                name={SUBSCRIPTION_MODEL.token}
                                defaultValue={'HappyFans(HAPPY)'}
                                helpText={
                                    !isHappyTokenSelected &&
                                    'Only if you pay with "HAPPY" you get 10% discount on all payments.'
                                }
                                indicatorsContainerCustomStyle={
                                    indicatorsContainerCustomStyle
                                }
                                singleValueCustomStyle={singleValueCustomStyle}
                            />
                        </div>

                        <div className={classes.wrapperContent}>
                            <Box>
                                <div className={classes.wrapperPrice}>
                                    <span>Total Amount</span>
                                    <p>{totalAmount()}</p>
                                </div>
                            </Box>
                        </div>

                        <Box classNames={classes.paymentProvider}>
                            POWERED BY
                            <Media image={paymentProviderLogo} />
                        </Box>

                        <Divider />

                        <Button width={'100%'} type={'submit'}>
                            Subscribe
                        </Button>
                    </form>
                </div>
            </Popover>
        </>
    )
}

export default SubscriptionsFanPopover
