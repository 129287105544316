import { createUseStyles } from 'react-jss'
import PostsFeed from './posts-feed'
import { useSelector } from 'react-redux'
import { selectUser } from '../../store/slices/user'
import PostCreation from '../creator-page/post-creation'
import Divider from '../../components/Divider'
import Suggestions from './suggestions'
import { useWindowSize } from '../../hooks/useWindowSize'
import React, { useEffect, useState } from 'react'
import TokenPurchaseBanner from '../creator-page/token-purchase-banner'
import { httpFetchTokens } from '../../http-requests/wallet'
import { handleApiError } from '../../utilities/helpers'
import { useLocation, useNavigate } from 'react-router-dom'
import Popover from '../../components/Popover'
import PaymentError from '../../components/PaymentError'
import PaymentSuccess from '../../components/PaymentSuccess'
import { ROUTE_HOME } from '../../utilities/constants'

const useStyles = createUseStyles((theme) => ({
    root: {
        display: 'grid',
        gridTemplateColumns: '1fr',
        gap: 16,
        padding: 16,
        [theme.mediaQueries.lUp]: {
            padding: [32, 16],
            gridTemplateColumns: '636px auto !important',
            gap: 24,
        },
        '& aside': {
            display: 'none',
            [theme.mediaQueries.lUp]: {
                display: 'block',
            },
        },
    },
    feed: {
        width: '100%',
        overflow: 'hidden',
        padding: [0, 2],
        margin: '0 auto',
        [theme.mediaQueries.mUp]: {
            overflow: 'visible',
        },
    },
    divider: {
        margin: [12, 0],
    },
    paymentError: {
        padding: [0, 16, 16],
        display: 'grid',
        alignItems: 'center',
        justifyItems: 'center',
        textAlign: 'center',
        '& h4': {
            margin: [24, 0],
        },
        '& p': {
            marginBottom: 24,
        },
    },
}))

const Homepage = () => {
    const user = useSelector(selectUser)
    const { width } = useWindowSize()
    const location = useLocation()
    const navigate = useNavigate()
    const [isMobile, setIsMobile] = useState(width <= 448)
    const [happyPrice, setHappyPrice] = useState('0')

    //payment success
    const [showPaymentSuccess, setShowPaymentSuccess] = useState(
        location.state?.paymentOperationStatus === 'success'
    )
    //payment error
    const [showPaymentError, setShowPaymentError] = useState(
        location.state?.paymentOperationStatus === 'failure'
    )

    useEffect(() => {
        getHappyTokenPrice()
    }, [])

    useEffect(() => {
        setIsMobile(width <= 448)
    }, [width])

    const getHappyTokenPrice = async () => {
        try {
            const { data } = await httpFetchTokens()
            setHappyPrice(
                data.filter((item) => item.symbol === 'HAPPY')[0].usd_value
            )
        } catch (error) {
            handleApiError({
                isReduxError: false,
                error,
            })
        }
    }

    const onPaymentErrorClose = () => {
        setShowPaymentError(!showPaymentError)
        navigate(ROUTE_HOME, { replace: true })
    }

    const onPaymentSuccessClose = () => {
        setShowPaymentSuccess(false)
        navigate(ROUTE_HOME, { replace: true })
    }

    const classes = useStyles()

    return (
        <>
            {showPaymentError && (
                <Popover onClose={onPaymentErrorClose} maxWidth={360}>
                    <PaymentError onClose={onPaymentErrorClose} />
                </Popover>
            )}
            {showPaymentSuccess && (
                <Popover onClose={onPaymentSuccessClose} maxWidth={360}>
                    <PaymentSuccess onClose={onPaymentSuccessClose} />
                </Popover>
            )}
            <div className={classes.root}>
                <main className={classes.feed}>
                    {!!user?.is_creator && <PostCreation user={user} />}
                    <PostsFeed />
                </main>
                {!isMobile && (
                    <aside>
                        <TokenPurchaseBanner amount={happyPrice} />
                        <Divider height={0} margin={16} />
                        <Suggestions />
                    </aside>
                )}
            </div>
        </>
    )
}

export default Homepage
