import Pusher from "pusher-js";

const baseNotificationConfig = {
    cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER,
    authEndpoint: process.env.REACT_APP_PUSHER_AUTH_ENDPOINT,
    auth: {
        headers: {

        }
    }
}

const PusherInstance = (() => {

    let instance;
    const createInstance = () => new Pusher(process.env.REACT_APP_PUSHER_APP_KEY, {
        ...baseNotificationConfig
    });

    return {
        getInstance: function () {
            if (!instance ) {
                instance = createInstance();
            }
            return instance;
        },
        setAuthHeaders: function (accessToken) {
            if (instance) {
                instance.config.auth.headers.Authorization = `Bearer ${accessToken}`;
                instance.config.auth.headers.Accept = 'application/json';
            }
        },
        getAuthHeaders: function () {
            return instance?.config.auth.headers.Authorization
        },
        removeAuthHeaders: function () {
            if(instance) instance.config.auth.headers = {}
        }
    };
})()


/*Pusher.log = (message) => {
    if (console.log) {
        console.log(message);
    }
};*/

export default PusherInstance;