import { useEffect, useState } from 'react'
import SignupRoleChoice from '../signup-role-choice'
import SignupInitialStep from '../signup-initial-step'
import { httpFetchCategories } from '../../../../../http-requests'
import { SIGNUP_STATUSES } from '../signupModel'
import SignupCategoriesChoice from '../signup-categories-choice'
import { useSelector } from 'react-redux'
import { selectSignupStatus } from '../../../../../store/slices/user'
import SignupFollowersChoice from '../signup-followers-choice'

const StepSwitch = () => {
    const step = useSelector(selectSignupStatus)
    const [categories, setCategories] = useState([])
    useEffect(() => {
        if (!categories.length) {
            getCategories()
        }
    }, [])

    const getCategories = async () => {
        try {
            const { data } = await httpFetchCategories()
            setCategories(data?.data)
        } catch (e) {
            console.log('error', e)
        }
    }

    switch (step) {
        case SIGNUP_STATUSES.roleChoice:
            return <SignupRoleChoice categories={categories} />
        case SIGNUP_STATUSES.categoriesChoice:
            return <SignupCategoriesChoice categories={categories} />
        case SIGNUP_STATUSES.firstFollowChoice:
            return <SignupFollowersChoice categories={categories} />
        default:
            return <SignupInitialStep />
    }
}

export default StepSwitch
