import { useRoutes, Navigate } from 'react-router'
import PublicRoute from './route-guards/public-route'
import PrivateRoute from './route-guards/private-route'
import * as routeNames from './utilities/constants'
import PageNotFound from './pages/page-not-found'
import Showcase from './pages/showcase'
import AutheticationLayout from './containers/authentication-layout'
import Signup from './pages/authentication/signup'
import Login from './pages/authentication/login'
import ResetPassword from './pages/authentication/reset-password'
import ForgotPassword from './pages/authentication/forgot-password'
import SignupActivation from './pages/authentication/signup-activation'
import Homepage from './pages/homepage'
import UserSettings from './pages/user-settings'
import FanPage from './pages/fan-page'
import PostSection from './pages/fan-page/post-section'
import MediaSection from './pages/fan-page/media-section'
import SharedRoute from './route-guards/shared-route'
import PrivateLayout from './containers/private-layout'
import PaymentCheck from './pages/payment-check'
import SearchPage from './pages/search-page'
import KeepSupporting from './pages/keep-supporting/KeepSupporting'
import Notifications from './pages/notifications-page/Notifications'
import Watch from './pages/watch-page/Watch'
import Messages from './pages/messages-page/Messages'

const AppRoutes = () =>
    useRoutes([
        {
            element: <PrivateRoute />, // Authenticated User Layout Route
            children: [
                {
                    element: <PrivateLayout withHeader />,
                    children: [
                        {
                            exact: true,
                            path: routeNames.USER_SETTINGS,
                            element: <UserSettings />,
                        },
                        {
                            exact: true,
                            path: routeNames.USER_SETTINGS_MOBILE,
                            element: <UserSettings />,
                        },
                        {
                            exact: true,
                            path: routeNames.ROUTE_HOME,
                            element: <Homepage />,
                        },
                        {
                            exact: true,
                            path: routeNames.ROUTE_SEARCH,
                            element: <SearchPage />,
                        },
                        {
                            exact: true,
                            path: routeNames.ROUTE_NOTIFICATIONS,
                            element: <Notifications />,
                        },
                        {
                            exact: true,
                            path: routeNames.ROUTE_WATCH,
                            element: <Watch />,
                            children: [
                                {
                                    exact: true,
                                    path: routeNames.ROUTE_WATCH_SECTION,
                                    element: <Watch />,
                                },
                                {
                                    exact: true,
                                    path: routeNames.ROUTE_WATCH_SECTION_ID,
                                    element: <Watch />,
                                },
                            ],
                        },
                        {
                            exact: true,
                            path: routeNames.ROUTE_MESSAGES,
                            element: <Messages />,
                            children: [
                                {
                                    exact: true,
                                    path: routeNames.ROUTE_MESSAGES_CHAT,
                                    element: <Messages />,
                                },
                            ],
                        },
                    ],
                },
                {
                    element: <PrivateLayout />,
                    children: [
                        {
                            exact: true,
                            path: routeNames.ROUTE_KEEP_SUPPORTING,
                            element: <KeepSupporting />,
                        },
                        {
                            exact: true,
                            path: routeNames.ROUTE_PAYMENT_CHECK_STATUS,
                            element: <PaymentCheck />,
                        },
                    ],
                },
            ],
        },
        {
            element: <PublicRoute />, // Anonymous User Layout Route
            children: [
                {
                    element: <AutheticationLayout />,
                    children: [
                        {
                            exact: true,
                            path: routeNames.ROUTE_SIGNUP,
                            element: <Signup />,
                        },
                        {
                            exact: true,
                            path: routeNames.ROUTE_LOGIN,
                            element: <Login />,
                        },
                        {
                            exact: true,
                            path: routeNames.ROUTE_FORGOT_PASSWORD,
                            element: <ForgotPassword />,
                        },
                        {
                            exact: true,
                            path: routeNames.ROUTE_RESET_PASSWORD,
                            element: <ResetPassword />,
                        },
                        {
                            exact: true,
                            path: routeNames.ROUTE_ACTIVATION,
                            element: <SignupActivation />,
                        },
                    ],
                },
            ],
        },
        {
            element: <SharedRoute />,
            children: [
                /*{
                    exact: true,
                    path: routeNames.ROUTE_SHOWCASE,
                    element: <Showcase />,
                },*/
                {
                    path: routeNames.ROUTE_NOT_FOUND, // Not found route
                    element: <PageNotFound />,
                },
                {
                    exact: true,
                    path: routeNames.ROUTE_FANPAGE,
                    element: <FanPage />,
                    children: [
                        {
                            exact: true,
                            path: 'post',
                            element: <PostSection />,
                        },
                        {
                            exact: true,
                            path: 'media',
                            element: <MediaSection />,
                        },
                    ],
                },
                {
                    path: '/',
                    element: <Navigate to={routeNames.ROUTE_HOME} />,
                },
                {
                    path: '*', // Not found route
                    element: <PageNotFound />,
                },
            ],
        },
    ])

export default AppRoutes
