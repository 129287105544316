import { createUseStyles } from 'react-jss'
import PostHeader from './post-header'
import PostFooter from './post-footer'
import PostContent from './post-content'
import PropTypes from 'prop-types'
import { POST_TYPES } from '../../../../utilities/constants'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { selectUser } from '../../../../store/slices/user'

const useStyles = createUseStyles((theme) => ({
    root: {
        boxShadow: theme.shadows[20],
        backgroundColor: '#FFF',
        borderRadius: 16,
        width: '100%',
        maxWidth: 640,
        [theme.mediaQueries.mUp]: {
            minWidth: 640,
        },
    },
}))

const Post = ({
    id,
    user,
    date,
    content,
    totalComments,
    totalLikes,
    is_liked,
    is_saved,
    comments,
    is_paid,
    ...rest
}) => {
    const loggedUser = useSelector(selectUser)
    const { media, is_subscription_related, price } = rest

    const type = useMemo(() => {
        /* Check permission */
        const isPremium = price > 0
        const isPublic = !is_subscription_related && !isPremium
        const isMine = loggedUser?.id === user.id
        const isBought = is_paid || isMine
        const imSubscribed = user.subscription?.status === 'active'
        const isAlwaysAllowed = isMine || isPublic || imSubscribed || isBought

        if (!isMine && !isBought && isPremium) {
            return POST_TYPES.premium
        }

        if (!isAlwaysAllowed) {
            return POST_TYPES.locked
        }

        /* Check content */
        if (!media) {
            return POST_TYPES.text
        } else if (media.length < 2) {
            return media[0].type
        } else {
            return POST_TYPES.gallery
        }
    }, [media, is_subscription_related, price, loggedUser])

    const classes = useStyles()
    return (
        <div className={classes.root}>
            <PostHeader date={date} user={user} postID={id} />
            <PostContent
                date={date}
                type={type}
                content={content}
                user={user}
                postID={id}
                {...rest}
            />
            <PostFooter
                locked={
                    type === POST_TYPES.locked || type === POST_TYPES.premium
                }
                postID={id}
                totalComments={totalComments}
                totalLikes={totalLikes}
                isLiked={is_liked}
                isSaved={is_saved}
                comments={comments}
                user={user}
                media={media}
                date={date}
            />
        </div>
    )
}

export default Post

Post.propTypes = {
    type: PropTypes.oneOf([
        POST_TYPES.image,
        POST_TYPES.video,
        POST_TYPES.gallery,
        POST_TYPES.locked,
    ]),
}
