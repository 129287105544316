import { createUseStyles } from 'react-jss'
import Box from '../../../../../components/Box'
import colors from '../../../../../theme/colors'
import UserAvatar from '../../../../homepage/posts-feed/post/post-header/user-info/user-avatar'
import { VerifiedBadgeIcon } from '../../../../../theme/icons'
import React from 'react'
import ExpiredSubscriptionFooter from './expired-subscription-footer'
import InactiveSubscriptionFooter from './inactive-subscription-footer'
import ActiveSubscriptionFooter from './active-subscription-footer'
import { SUBSCRIPTION_STATUS } from '../../../../../utilities/constants'
import cx from 'classnames'
import { remapSubscriptionStatus } from '../../../../../utilities/helpers'

const useStyles = createUseStyles((theme) => ({
    root: {
        padding: [16, 0, 0],
        marginBottom: 16,
        overflow: 'hidden',
    },
    resume: {
        padding: [0, 16],
        display: 'grid',
        gridTemplateColumns: '48px 1fr',
        gridColumnGap: 8,
        alignItems: 'center',
    },
    userInfos: {
        '& h4': {
            fontSize: 16,
            fontWeight: 600,
            color: theme.palette.text.primary,
        },
        '& p': {
            fontSize: 12,
            color: colors.greyscale[500],
        },
    },
    detail: {
        margin: [0, 16],
        display: 'grid',
        gridAutoFlow: 'column',
        gridTemplateColumns: '1fr auto',
        alignItems: 'center',
        height: 40,
        borderBottom: '1px solid #E1E1E1',
        '& > b': {
            fontSize: 12,
            fontWeight: 600,
            color: theme.palette.primary.background,
        },
        '& > span': {
            fontSize: 12,
            //color: colors.greyscale[600],
        },
    },
    status: ({ status }) => ({
        color:
            status === SUBSCRIPTION_STATUS.active
                ? '#18A957'
                : theme.palette.tertiary.main,
    }),
}))

const SubscriptionCard = ({
    isMyFans = false,
    subscribedAt,
    expiredAt,
    cost,
    status,
    reason,
    user,
    subscription,
}) => {
    const classes = useStyles({ status })

    const footerRenderer = () => {
        switch (status) {
            case SUBSCRIPTION_STATUS?.inactive:
                return <InactiveSubscriptionFooter user={user} />
            case SUBSCRIPTION_STATUS?.active:
                return (
                    <ActiveSubscriptionFooter
                        subscription={subscription}
                        user={user}
                    />
                )
            case SUBSCRIPTION_STATUS?.expired:
                return <ExpiredSubscriptionFooter user={user} />
            default:
                return <span />
        }
    }

    return (
        <Box classNames={classes.root}>
            <div className={classes.resume}>
                <UserAvatar
                    username={user?.username}
                    avatar={user?.avatar?.[128]}
                />
                <div className={classes.userInfos}>
                    <h4>
                        {user?.display_name}{' '}
                        {user?.is_verified && <VerifiedBadgeIcon height={14} />}
                    </h4>
                    <p>@{user?.username}</p>
                </div>
            </div>
            {expiredAt && (
                <div className={classes.detail}>
                    <b>Status:</b> <span>Expired the {expiredAt}</span>
                </div>
            )}
            {subscribedAt && !expiredAt && (
                <div className={classes.detail}>
                    <b>Subscribed:</b> <span>{subscribedAt}</span>
                </div>
            )}
            {cost && (
                <div className={classes.detail}>
                    <b>Cost:</b> <span>{cost}/month</span>
                </div>
            )}
            {status && (
                <div className={classes.detail}>
                    <b>Status:</b>{' '}
                    <span className={classes.status}>
                        {remapSubscriptionStatus(status)}
                    </span>
                </div>
            )}
            {status === 'expired' && reason && (
                <div className={classes.detail}>
                    <b>Reason:</b> <span> {reason} </span>
                </div>
            )}

            {!isMyFans && footerRenderer()}
        </Box>
    )
}

export default SubscriptionCard
