import PostImageContent from './post-image-content'
import PostVideoContent from './post-video-content'
import PostGalleryContent from './post-gallery-content'
import PostLockedContent from './post-locked-content'
import { POST_TYPES } from '../../../../../utilities/constants'
import PostTextContent from './post-text-content'
import PostPremiumContent from './post-premium-content'

const PostContent = ({ user, type, ...props }) => {
    switch (type) {
        case POST_TYPES.video:
            return <PostVideoContent {...props} />
        case POST_TYPES.gallery:
            return <PostGalleryContent {...props} />
        case POST_TYPES.locked:
            return <PostLockedContent user={user} {...props} />
        case POST_TYPES.premium:
            return <PostPremiumContent user={user} {...props} />
        case POST_TYPES.image:
            return <PostImageContent {...props} />
        default:
            return <PostTextContent {...props} />
    }
}

export default PostContent
