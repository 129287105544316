import { useEffect, useMemo, useRef, useState } from 'react'
import { createUseStyles } from 'react-jss'
import Spinner from '../../../components/Spinner'
import Post from '../../homepage/posts-feed/post'
import { LoaderIcon } from '../../../theme/icons'
import { useOutletContext } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
    fetchUserPosts,
    resetTimelinePostsPagination,
    selectTimelinePostsAllIds,
    selectTimelinePostsById,
    selectTimelinePostsCurrentPage,
    selectTimelinePostsLastPage,
    selectTimelinePostsPerPage,
} from '../../../store/slices/timeline'

const useStyles = createUseStyles((theme) => ({
    postFeed: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 16,
        padding: [16, 0],
        [theme.mediaQueries.lUp]: {
            padding: 16,
        },
    },
    loadMoreSpinnerWrapper: {
        position: 'relative',
        width: 20,
        height: 20,
        animation: 'spin 1s linear infinite',
        margin: '0 auto',
    },
}))

const PostSection = () => {
    const userID = useOutletContext()

    const dispatch = useDispatch()

    // Pagination
    const fetchLimit = 12
    const endRef = useRef(null)
    const posts = useSelector(selectTimelinePostsById)
    const postIds = useSelector(selectTimelinePostsAllIds)
    const currentPage = useSelector(selectTimelinePostsCurrentPage)
    const lastPage = useSelector(selectTimelinePostsLastPage)
    const perPage = useSelector(selectTimelinePostsPerPage)

    const [isLoading, setIsLoading] = useState(true)
    const [isLoadingMore, setIsLoadingMore] = useState(false)

    useEffect(() => {
        fetchInitial()
        return async () => {
            dispatch(resetTimelinePostsPagination())
        }
    }, [])

    const fetchInitial = async () => {
        setIsLoading(true)
        await dispatch(fetchUserPosts({ userID, page: 1, limit: fetchLimit }))
        setIsLoading(false)
    }

    const fetchMore = async () => {
        if (currentPage < lastPage && perPage >= fetchLimit) {
            setIsLoadingMore(true)
            await dispatch(
                fetchUserPosts({
                    userID,
                    page: currentPage + 1,
                    limit: perPage,
                })
            )
            setIsLoadingMore(false)
        } else if (currentPage < lastPage && perPage < fetchLimit) {
            fetchInitial()
        }
    }

    const intersectionObserver = useMemo(
        () =>
            new IntersectionObserver(
                (entries) => {
                    const first = entries[0]
                    if (first.isIntersecting) {
                        fetchMore()
                    }
                },
                {
                    threshold: 0,
                    rootMargin: '0px 0px 100px 0px',
                }
            ),
        [posts, currentPage, lastPage]
    )

    useEffect(() => {
        if (endRef.current) {
            intersectionObserver.observe(endRef.current)
        }
        return () => {
            if (endRef.current) {
                intersectionObserver.unobserve(endRef.current)
            }
        }
    }, [endRef.current, intersectionObserver])

    const classes = useStyles()

    return (
        <div className={classes.postFeed}>
            {isLoading && <Spinner overlayFullscreen />}
            {postIds.map((postID, idx) => {
                const {
                    id,
                    user,
                    created_at,
                    content,
                    comments_count,
                    likes_count,
                    media,
                    ...rest
                } = posts[postID]

                return (
                    <Post
                        key={id}
                        id={id}
                        user={user}
                        content={content}
                        date={created_at}
                        totalComments={comments_count ?? 0}
                        totalLikes={likes_count ?? 0}
                        media={media}
                        {...rest}
                    />
                )
            })}

            {!postIds.length && <div>This user has no posts yet!</div>}
            {!!postIds.length && <div ref={endRef} />}
            {isLoadingMore && (
                <div className={classes.loadMoreSpinnerWrapper}>
                    <LoaderIcon />
                </div>
            )}
        </div>
    )
}

export default PostSection
