import { useEffect } from 'react'
import { createUseStyles } from 'react-jss'
import { useWindowSize } from '../../hooks/useWindowSize'
import Back from '../../components/Back'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import {
    ACTIVITIES,
    CHANGE_PASSWORD,
    CREATOR_SETTINGS_SECTIONS,
    DELETE_ACCOUNT,
    FAN_SETTINGS_SECTIONS,
    NOTIFICATIONS_SETTINGS,
    PAYMENTS,
    PROFILE,
    SUBSCRIPTION_SETTINGS,
    WALLET,
} from '../../utilities/constants'
import SectionsSidebar from './sections-sidebar'
import colors from '../../theme/colors'
import ChangePasswordSection from './change-password-section'
import DeleteAccountSection from './delete-account-section'
import Payments from './payments-section'
import Profile from './profile-section'
import Activities from './activities-section'
import Wallet from './wallet-section'
import { createBrowserHistory } from 'history'
import SubscriptionSettings from './subscription-settngs'
import {useDispatch, useSelector} from 'react-redux'
import {selectUser, updateUser} from '../../store/slices/user'
import NotificationsSection from "./notifications-section";

const useStyles = createUseStyles((theme) => ({
    root: {
        display: 'grid',
        gridTemplateColumns: '1fr',
        alignItems: 'start',
        gridColumnGap: 0,
        padding: [0, 8],
        [theme.mediaQueries.mUp]: {
            padding: 0,
            gridColumnGap: 24,
            gridTemplateColumns: '0.35fr 0.65fr',
        },
    },
    back: {
        padding: [16, 0, 16],
        display: 'grid',
        gridColumn: 'span 2',
        gridTemplateColumns: 'auto 1fr',
        [theme.mediaQueries.mUp]: {
            padding: [16, 0, 32],
        },
    },
    card: {
        background: colors.common.white,
        boxShadow:
            '0px 1px 3px rgba(20, 20, 42, 0.1), 0px 0px 1px rgba(20, 20, 42, 0.05)',
        borderRadius: 24,
        padding: 24,
    },
    sectionTitle: {
        fontWeight: 'bold',
        fontSize: '18px',
        lineHeight: '140%',
        color: theme.palette.text.primary,
        marginBottom: 24,
    },
}))

const UserSettings = () => {
    const navigate = useNavigate()
    const location = useLocation()
    let history = createBrowserHistory()
    const { section } = useParams()
    const { width } = useWindowSize()
    const isCreator = useSelector(selectUser)?.is_creator
    const classes = useStyles()

    const dispatch = useDispatch()
    const user = useSelector(selectUser)

    useEffect(() => {
        const forceNavigate =
            history?.action === 'POP' &&
            location?.pathname !== '/settings/profile'
        if (
            width >= 736 &&
            location.pathname === '/settings' &&
            forceNavigate
        ) {
            navigate('/settings/profile')
        }
    }, [width, location, history])

    const sectionRenderer = () => {
        switch (section) {
            case PROFILE:
                return <Profile />
            case CHANGE_PASSWORD:
                return <ChangePasswordSection />
            case WALLET:
                return <Wallet />
            case PAYMENTS:
                return <Payments />
            case ACTIVITIES:
                return <Activities />
            case DELETE_ACCOUNT:
                return <DeleteAccountSection />
            case SUBSCRIPTION_SETTINGS:
                return <SubscriptionSettings user={user}/>
            case NOTIFICATIONS_SETTINGS:
                return <NotificationsSection />
            default:
                return <></>
        }
    }

    const goBack = () => {
        const shouldSkipSettingsRoute =
            history?.action === 'POP' &&
            location?.pathname === '/settings/profile' &&
            width >= 736
        if (shouldSkipSettingsRoute) {
            return navigate(-2)
        } else {
            return navigate(-1)
        }
    }

    return (
        <div className={classes.root}>
            <div className={classes.back}>
                <Back callback={() => goBack()} />
            </div>
            {(width >= 736 || location.pathname === '/settings') && (
                <div>
                    <SectionsSidebar
                        itemsToRender={
                            isCreator
                                ? CREATOR_SETTINGS_SECTIONS
                                : FAN_SETTINGS_SECTIONS
                        }
                    />
                </div>
            )}
            <div>{sectionRenderer()}</div>
        </div>
    )
}

export default UserSettings
