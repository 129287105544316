import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { ROUTE_MESSAGES } from '../utilities/constants'

const useLocationChange = (action, ignoredLocations) => {
    const location = useLocation()

    useEffect(() => {
        if (
            typeof action === 'function' &&
            !location?.pathname.includes(ROUTE_MESSAGES)
        ) {
            action(location)
        }
    }, [location])
}

export default useLocationChange
