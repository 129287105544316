import React from 'react'
import ContentLoader from 'react-content-loader'
import Box from '../../../../../components/Box'

const ActivityLoader = ({ classNames, ...rest }) => (
    <Box classNames={classNames}>
        <ContentLoader
            height={64}
            width={'100%'}
            primarycolor="#d9d9d9"
            secondarycolor="#ecebeb"
            {...rest}
        >
            <circle x="0" cx="32" cy="32" r="32" />
            <rect x="72" y="8" rx="0" ry="0" width="100%" height="24" />
            <rect x="72" y="40" rx="0" ry="0" width="40" height="8" />
        </ContentLoader>
    </Box>
)

export default ActivityLoader
