import React from 'react'
import { createUseStyles } from 'react-jss'
import { Outlet, useLocation, useNavigate, useMatch } from 'react-router-dom'
import { useSelector } from 'react-redux'
import logo from '../../asset/images/logo.png'
import Back from '../../components/Back'
import SocialBarNoColor from '../../components/SocialBarNoColor'
import { selectSignupStatus, selectUser } from '../../store/slices/user'
import {
    FacebookFrameIcon,
    TelegramFrameIcon,
    TwitterMonoFrameIcon,
} from '../../theme/icons'
import step0 from '../../asset/images/authentication/step0.png'
import step1 from '../../asset/images/authentication/step1.png'
import fanStep2 from '../../asset/images/authentication/fanStep2.png'
import creatorStep2 from '../../asset/images/authentication/creatorStep2.png'
import stepCompleted from '../../asset/images/authentication/stepCompleted.png'
import passwordRecovery from '../../asset/images/authentication/passwordRecover.png'
import {
    ROUTE_ACTIVATION,
    ROUTE_HOME,
    ROUTE_LOGIN,
    ROUTE_RESET_PASSWORD,
    ROUTE_SIGNUP,
} from '../../utilities/constants'
import { SIGNUP_STATUSES } from '../../pages/authentication/signup/signup-form/signupModel'
import backgroundHeart from '../../asset/images/authentication/authBackground.png'
import bgGradient from '../../asset/images/authentication/bgGradient.png'

const useStyles = createUseStyles((theme) => ({
    root: {
        flex: 1,
        display: 'grid',
        width: '100%',
        margin: '0 auto',
        gridTemplateAreas: `"main"`,
        gridTemplateColumns: '1fr',
        gridTemplateRows: '1fr',
        [theme.mediaQueries.mUp]: {
            gridTemplateAreas: `"carousel" "main"`,
            gridTemplateColumns: '1fr 379px',
            gridTemplateRows: '1fr',
        },
        [theme.mediaQueries.lUp]: {
            gridTemplateColumns: '1fr minmax(428px, 0.4fr)',
        },
    },
    bg: {
        background: `url(${bgGradient}) center center`,
        position: 'fixed',
        top: '-60%',
        left: 0,
        right: 0,
        bottom: 0,
    },
    bg2: {
        background: `${theme.palette.background.auth}`,
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
    },
    carousel: {
        position: 'relative',
        display: 'none',
        padding: [72, 48, 80, 48],
        gridTemplateAreas: `'title' 'subtitle'`,
        gridAutoRows: '1fr',
        color: theme.palette.common.white,
        //backdropFilter: 'blur(100px)',
        backgroundImage: `url("${backgroundHeart}")`,
        backgroundSize: '145vh',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: '-570px -60px',
        [theme.mediaQueries.mUp]: {
            display: 'flex',
            flexDirection: 'column',
            gap: 16,
        },
        [theme.mediaQueries.lUp]: {
            padding: [32, 72],
            gap: 8,
        },
        [theme.mediaQueries.xxxlUp]: {
            gap: 8,
            padding: [36, 56],
        },
    },
    title: {
        marginBottom: 8,
    },
    subtitle: {
        fontWeight: 400,
    },
    main: {
        position: 'relative',
        display: 'grid',
        gridTemplateRows: 'auto auto 1fr',
        textAlign: 'center',
        backgroundColor: theme.palette.common.white,
        '& img': {
            width: 156,
        },
    },
    auth: {
        position: 'absolute',
        height: '100%',
        width: '100%',
        padding: [32, 16],
        [theme.mediaQueries.mUp]: {
            padding: [24, 20],
        },
        [theme.mediaQueries.lUp]: {
            padding: [24, 40],
        },
        overflow: 'auto',
    },
    pageHeader: {
        display: 'grid',
        gridAutoFlow: 'column',
        gridTemplateColumns: 'auto 1fr',
        gap: 16,
        padding: [16, 0],
    },
    line: {
        height: 4,
        bottom: 0,
        position: 'absolute',
        width: '100%',
        background: theme.palette.quaternary.background,
    },
    logo: {
        margin: [-24, 0, 16, 0],
        pointerEvents: 'none',
        '& img': {
            position: 'relative',
            zIndex: 1,
            pointerEvents: 'auto',
            cursor: 'pointer',
        },
    },
    image: {
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        maxWidth: 532,
        '& img': {
            width: '100%',
        },
    },
}))

const excludedPathsForBack = [ROUTE_LOGIN, ROUTE_HOME, ROUTE_ACTIVATION]

const boldText = {
    fontWeight: 'bold',
}

const StepImage = () => {
    const step = useSelector(selectSignupStatus)
    const user = useSelector(selectUser)
    let match = useMatch(ROUTE_RESET_PASSWORD)
    /* It's bad, I know, but please forgive me this time. */
    if (match) {
        return <img src={passwordRecovery} alt={'recover'} />
    } else {
        switch (step) {
            case SIGNUP_STATUSES.roleChoice:
                return <img src={step1} alt={'step2'} />
            case SIGNUP_STATUSES.categoriesChoice:
                return !user?.is_creator ? (
                    <img src={fanStep2} alt={'step3'} />
                ) : (
                    <img src={creatorStep2} alt={'step4'} />
                )
            case SIGNUP_STATUSES.firstFollowChoice:
            case SIGNUP_STATUSES.completed:
                return <img src={stepCompleted} alt={'step5'} />
            case SIGNUP_STATUSES.initial:
                return <img src={step0} alt={'step1'} />
            default:
                return <img src={passwordRecovery} alt={'recover'} />
        }
    }
}

const AuthenticationLayout = () => {
    let navigate = useNavigate()
    const classes = useStyles()
    const signupStatus = useSelector(selectSignupStatus)
    const location = useLocation()

    const renderBackArrow =
        location.pathname === ROUTE_SIGNUP &&
        signupStatus === SIGNUP_STATUSES.initial &&
        !excludedPathsForBack.includes(location.pathname)

    return (
        <div className={classes.root}>
            <div className={classes.bg2}>
                <div className={classes.bg} />
            </div>
            <span className={classes.carousel}>
                <h2 className={classes.title}>
                    The first social <br /> platform based on <br /> blockchain.
                </h2>
                <h5 className={classes.subtitle}>
                    Unlock best contents with{' '}
                    <b style={boldText}>crypto payments</b> <br /> Buy{' '}
                    <b style={boldText}>HAPPY</b>
                </h5>
                <div className={classes.image}>
                    <StepImage />
                </div>
                <SocialBarNoColor
                    icons={[
                        <TwitterMonoFrameIcon
                            onClick={() =>
                                window.open(
                                    'https://twitter.com/HappyFansToken',
                                    '_blank'
                                )
                            }
                        />,
                        <FacebookFrameIcon
                            onClick={() =>
                                window.open(
                                    'https://www.facebook.com/HappyFansToken',
                                    '_blank'
                                )
                            }
                        />,
                        <TelegramFrameIcon
                            onClick={() =>
                                window.open(
                                    'https://t.me/happyfanstoken',
                                    '_blank'
                                )
                            }
                        />,
                    ]}
                />
            </span>
            <div className={classes.main}>
                <div className={classes.auth}>
                    <div className={classes.pageHeader}>
                        {renderBackArrow && (
                            <Back callback={() => navigate(-1)} />
                        )}
                    </div>
                    <div
                        className={classes.logo}
                        onClick={() => {
                            navigate(ROUTE_HOME)
                        }}
                    >
                        <img src={logo} alt={'logo'} />
                    </div>
                    <Outlet />
                </div>
                <span className={classes.line}></span>
            </div>
        </div>
    )
}

export default AuthenticationLayout
