import { createUseStyles } from 'react-jss'
import React from 'react'
import ItemText from '../itemText/ItemText'
import { useDispatch, useSelector } from 'react-redux'
import {selectActiveChat, selectMyConversationsByUUID} from '../../../../../../../store/slices/conversations'
import Button from '../../../../../../../components/Button'
import { setSubscriptionModalOpen } from '../../../../../../../store/slices/app'
import logo from '../../../../../../../asset/images/notifications/notificationPlatformLogo.png'
import UserInfo from '../../../../../../homepage/posts-feed/post/post-header/user-info'
import {useParams} from "react-router-dom";

const useStyles = createUseStyles((theme) => ({
    itemExpired: {
        display: 'flex',
        flexDirection: 'column',
        gap: 8,
    },
    row: ({ isMine }) => ({
        display: 'flex',
        justifyContent: 'flex-end',
        flexDirection: isMine ? 'row' : 'row-reverse',
        gap: 8,
    }),
    subscriptionWrapper: {
        maxWidth: 282,
        display: 'flex',
        flexDirection: 'column',
        gap: 16,
        padding: [16, 0],
        borderRadius: 16,
        backgroundColor: 'white',
        boxShadow:
            '0px 1px 3px rgba(20, 20, 42, 0.1), 0px 0px 1px rgba(20, 20, 42, 0.05)',
        marginLeft: 50,
    },
    infoWrapper: {
        padding: [0, 16],
    },
    buttonWrapper: {
        extend: 'infoWrapper',
    },
    divider: {
        height: 1,
        backgroundColor: '#E6E6E6',
    },
}))

const ItemExpired = ({ message, isMobile }) => {
    const dispatch = useDispatch()
    const params = useParams()
    const channelUUID = params?.channelUUID
    const myConversationsByUUID = useSelector(selectMyConversationsByUUID)
    const conversation = myConversationsByUUID[channelUUID]
    const canReactivate = conversation?.subscription?.status === 'inactive'

    const isMine = false
    const hasText = message?.content

    const expiredMessageData = {
        ...message,
        message:
            'Oops, it looks like your membership has expired. Resubscribe to unlock the chat and continue talking with your favorite creators!',
    }

    const renderText = () => {
        if (canReactivate) {
            return 'Reactivate'
        } else if (conversation?.subscription_current_dollar_price) {
            return `Subscribe for ${conversation?.subscription_current_dollar_price}/month`
        } else {
            return 'Subscribe for free'
        }
    }

    const classes = useStyles({ isMine, hasText })
    return (
        <div className={classes.itemExpired}>
            <div className={classes.row}>
                <ItemText
                    message={expiredMessageData}
                    userData={{
                        avatar: {
                            128: logo,
                        },
                    }}
                    isMobile={isMobile}
                    isMine={isMine}
                    hasContextMenu={false}
                />
            </div>
            <div className={classes.subscriptionWrapper}>
                <div className={classes.infoWrapper}>
                    <UserInfo
                        name={conversation?.display_name}
                        username={conversation?.username}
                        avatar={conversation?.avatar?.[128]}
                        category={conversation?.category?.name}
                        isVerified={conversation?.is_verified}
                        avatarWidth={40}
                        avatarHeight={40}
                        isOnline={conversation?.is_online}
                    />
                </div>
                <div className={classes.divider} />
                <div className={classes.buttonWrapper}>
                    <Button
                        width={'100%'}
                        variant={'ghost'}
                        size={'medium'}
                        onClick={() =>
                            dispatch(
                                setSubscriptionModalOpen({ isOpen: true, user: {...conversation, subscriptions_dollar_price: conversation?.subscription_current_dollar_price} }),
                            )
                        }
                    >
                        {renderText()}
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default ItemExpired
