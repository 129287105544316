import { createUseStyles } from 'react-jss'
import { PlayIcon } from '../../../../../../../theme/icons'

const useStyles = createUseStyles((theme) => ({
    thumb: {
        height: '100%',
        width: '100%',
        overflow: 'hidden',
        borderRadius: 16,
        '& > img': {
            height: '100%',
            width: '100%',
            objectFit: 'cover',
            objectPosition: 'center center',
        },
    },
    videoPreview: {
        extend: 'thumb',
        position: 'relative',
        backgroundColor: theme.palette.secondary.background,
        backgroundImage: ({ poster }) => `url("${poster}")`,
        backgroundSize: 'cover',
    },
    playIcon: {
        position: 'absolute',
        top: 12,
        right: 12,
    },
}))

const MediaPostVideo = ({ subtitle, onClick, ...props }) => {
    const classes = useStyles({ poster: props['banner_128'] })
    return (
        <a
            data-poster={props['banner_128']}
            data-video={props['original']}
            {...(subtitle && { 'data-sub-html': `<p>${subtitle}</p>` })}
        >
            <div className={classes.thumb} onClick={onClick}>
                <div className={classes.videoPreview}>
                    <PlayIcon className={classes.playIcon} />
                </div>
            </div>
        </a>
    )
}

export default MediaPostVideo
