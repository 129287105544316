import * as yup from 'yup'

const SIGNUP_MODEL = {
    email: 'email',
    password: 'password',
    adult: 'adult_check',
    isCreator: 'is_creator',
    categoryID: 'category_id',
    categoryIDs: 'categories',
    followedIDs: 'to_follow',
}

const SIGNUP_STATUSES = {
    initial: 'initialStep',
    roleChoice: 'roleChoice',
    categoriesChoice: 'categoriesChoice',
    firstFollowChoice: 'firstFollowChoice',
    completed: 'completed',
}

const signupValidationSchema = {
    [SIGNUP_STATUSES.initial]: yup.object().shape({
        [SIGNUP_MODEL.email]: yup
            .string()
            .email('Email not valid')
            .typeError('Email is mandatory')
            .required('Email is mandatory'),
        [SIGNUP_MODEL.password]: yup
            .string()
            .typeError('Password is mandatory')
            .required('Password is mandatory')
            .min(6, 'Password too short')
            .matches(
                /^(?=.*[0-9])(?=.*[!#$%&*+\-./:;=?@\\^_])(?=.*[A-Z])[a-zA-Z0-9!#$%&*+\-./:;=?@\\^_]{6,16}$/,
                'Password must include at least one number, one uppercase letter and one special character.'
            ),
        [SIGNUP_MODEL.adult]: yup
            .bool()
            .oneOf([true], 'You must be at least 18 years old to signup'),
    }),
    [SIGNUP_STATUSES.roleChoice]: yup.object().shape({
        [SIGNUP_MODEL.isCreator]: yup
            .bool()
            .typeError('You must choose an account type to continue')
            .oneOf(
                [true, false],
                'You must choose an account type to continue'
            ),
        [SIGNUP_MODEL.categoryID]: yup.number().when(SIGNUP_MODEL.isCreator, {
            is: true,
            then: yup
                .number()
                .typeError('You must choose a category to be a Creator'),
        }),
    }),
    [SIGNUP_STATUSES.categoriesChoice]: yup.object().shape({
        [SIGNUP_MODEL.categoryIDs]: yup
            .array(yup.number())
            .compact()
            .min(1, 'Choose at least one category'),
    }),
    [SIGNUP_STATUSES.firstFollowChoice]: yup.object().shape({
        [SIGNUP_MODEL.followedIDs]: yup
            .array(yup.number())
            .compact()
            .min(1, 'Choose at least one Person'),
    }),
}

export {
    signupValidationSchema as validationSchema,
    SIGNUP_MODEL,
    SIGNUP_STATUSES,
}
