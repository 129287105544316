import axios from '../utilities/axios'

export const httpFetchTokens = () => {
    const url = `/tokens`
    return axios.get(url)
}

export const httpFetchNetworks = () => {
    const url = `/networks`
    return axios.get(url)
}

export const httpFetchWallets = (page, limit) => {
    const url = `/wallets`
    return axios.get(url, { params: { page, limit } })
}

export const httpGetNetworksStandard = (networkId) => {
    const url = `/networks/${networkId}/standards`
    return axios.get(url)
}

export const httpStoreWallet = (address) => {
    const url = `/wallets`
    return axios.post(url, { address: address })
}

export const httpStoreWalletsNetworks = (
    walletId,
    networkId,
    networkStandardId
) => {
    const url = `/wallets/${walletId}/networks`
    return axios.post(url, {
        network_id: networkId,
        network_standard_id: networkStandardId,
    })
}

export const httpAddWalletsNetworkToken = (walletId, networkId, tokenId) => {
    const url = `/wallets/${walletId}/networks/${networkId}/token`
    return axios.post(url, { token_id: tokenId })
}

export const httpDeleteWallet = (id, password) => {
    const url = `/wallets/${id}`
    return axios.post(url, { password: password })
}

export const httpDeleteToken = (walletId, networkId, tokenId, password) => {
    const url = `/wallets/${walletId}/networks/${networkId}/token/${tokenId}/delete`
    return axios.post(url, { password: password })
}
