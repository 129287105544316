import {createUseStyles} from 'react-jss'
import colors from "../../../../../../theme/colors";

const useStyles = createUseStyles((theme) => ({
    notificationOptionLabel: ({isActive}) => ({
        ...theme.utils.flexbox.centered,
        height: 17,
        width: 'max-content',
        borderRadius: 2,
        padding: [0, 4],
        fontSize: 10,
        fontWeight: 500,
        letterSpacing: 0.75,
        backgroundColor: isActive ? colors.secondary[500] : null,
        color: isActive ? 'white' : colors.greyscale[500],
        cursor: 'pointer',
    }),
}))

const NotificationOptionLabel = ({
                                     text,
                                     isActive,
                                     onClick,
                                 }) => {

    const classes = useStyles({isActive})
    return (
        <div className={classes.notificationOptionLabel} onClick={onClick}>
            {text}
        </div>
    )
}

export default NotificationOptionLabel
