import axios from '../utilities/axios'

export const httpFetchNotificationHistory = ({
    limit = 10,
    page = 1,
    seen,
}) => {
    return axios.get(`/profile/notifications`, {
        params: { page, limit, seen },
    })
}

export const httpFetchNotificationOptions = () => {
    return axios.get(`profile/notifications/options`)
}

export const httpUpdateNotificationOptions = (optionID, options) => {
    return axios.patch(`profile/notifications/options/${optionID}`, options)
}

export const httpMarkNotificationAsRead = (id) => {
    return axios.patch(`profile/notifications/${id}/seen`)
}

export const httpMarkAllNotificationsAsRead = () => {
    return axios.post(`profile/notifications`)
}

export const httpMarkNotificationAsUnread = (id) => {
    return axios.patch(`profile/notifications/${id}/unseen`)
}

export const httpDeleteNotification = (id) => {
    return axios.delete(`/profile/notifications/${id}`)
}
