import { createUseStyles } from 'react-jss'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import SearchResultItem from './search-result-item'
import { useWindowSize } from '../../hooks/useWindowSize'
import { BackIcon, LoaderIcon } from '../../theme/icons'
import { httpSearchUser } from '../../http-requests'
import { handleApiError } from '../../utilities/helpers'
import Checkbox from '../../components/Checkbox'
import FiltersAccordion from './filters-accordion'

const useStyles = createUseStyles((theme) => ({
    searchPage: {
        display: 'flex',
        alignSelf: 'center',
        padding: 16,
        flexDirection: 'column-reverse',
        gap: theme.spacing * 2,
        maxWidth: 612,
        width: '100%',
        [theme.mediaQueries.lUp]: {
            alignSelf: 'auto',
            maxWidth: '100%',
            flexDirection: 'row',
            gap: theme.spacing * 3,
            padding: theme.spacing * 3,
        },
    },
    listWrapper: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing * 2,
        width: '100%',
        maxWidth: 636,
        paddingBottom: theme.sizing.headerHeightMobile,
        [theme.mediaQueries.mUp]:{
            paddingBottom: 0,
        },
    },
    listHeader: {
        display: 'flex',
        padding: [theme.spacing, 0],
        width: '100%',
        borderBottom: `1px solid ${theme.palette.divider.external}`,
        fontSize: 16,
        letterSpacing: 0.15,
        color: ({ isMobile }) =>
            isMobile
                ? theme.palette.common.black
                : theme.palette.secondary.main,
        '& span': {
            paddingLeft: 4,
            fontWeight: 700,
        },
    },
    filterWrapper: {
        width: '100%',
        [theme.mediaQueries.lUp]: {
            maxWidth: 416,
        },
    },
    filterHeader: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        gap: theme.spacing * 2,
        fontSize: 16,
        letterSpacing: 0.15,
        fontWeight: 700,
        color: theme.palette.secondary.main,
        paddingBottom: theme.spacing * 2,
        [theme.mediaQueries.lUp]: {
            padding: 0,
            border: 0,
        },
    },
    filterList: {
        display: 'flex',
        flexDirection: 'column',
        padding: [2, theme.spacing * 2],
        borderRadius: 16,
        boxShadow: ' 0px 1px 3px rgba(20, 20, 42, 0.1), 0px 0px 1px rgba(20, 20, 42, 0.05)',
        '& > div:not(:last-child)': {
            borderBottom: `1px solid ${theme.palette.divider.external}`,
        },
        [theme.mediaQueries.mUp]: {
            padding: theme.spacing * 2,
            paddingBottom: 0,
            '& > div:first-child': {
                paddingBottom: theme.spacing * 2,
                borderBottom: 0,
            },
        },
    },
    filterItem: {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing * 2,
    },
    loadMoreSpinnerWrapper: {
        position: 'relative',
        width: 20,
        height: 20,
        animation: 'spin 1s linear infinite',
        margin: '0 auto',
    },
}))

const SearchPage = ({}) => {
    const navigate = useNavigate()
    const params = useParams()
    const { width } = useWindowSize()
    const endRef = useRef(null)
    const [isMobile, setIsMobile] = useState(width < 1024)
    const [total, setTotal] = useState(0)
    const [currentPage, setCurrentPage] = useState(0)
    const [lastPage, setLastPage] = useState(0)
    const [perPage, setPerPage] = useState(0)
    const [isLoading, setIsLoading] = useState(false)
    const [isLoadingMore, setIsLoadingMore] = useState(false)
    const [creators, setCreators] = useState([])
    const [categories, setCategories] = useState([])
    const [filterSectionOpen, setFilterSectionOpen] = useState(false)
    const [filters, setFilters] = useState([])

    const fetchLimit = 15
    const fetchMoreCondition = currentPage < lastPage

    useEffect(() => {
        setIsMobile(width < 1024)
    }, [width])

    useEffect(() => {
        fetchInitial()
        return () => setCreators([])
    }, [params, filters])

    const fetchCreators = async (page) => {
        setIsLoading(true)
        try {
            const {
                data: {
                    data,
                    current_page,
                    last_page,
                    per_page,
                    total,
                    categories,
                },
            } = await httpSearchUser({
                name: params?.name,
                page: page,
                limit: fetchLimit,
                filters: filters,
            })
            if (current_page === 1) {
                setCreators([...data])
            } else {
                setCreators([...creators, ...data])
            }
            setLastPage(last_page)
            setPerPage(+per_page)
            setCurrentPage(current_page)
            setTotal(total)
            setCategories(categories)
        } catch (error) {
            handleApiError({
                isReduxError: false,
                error,
            })
        } finally {
            setIsLoading(false)
        }
    }

    const fetchInitial = async () => {
        setIsLoading(true)
        await fetchCreators(1)
        setIsLoading(false)
    }

    const fetchMore = async () => {
        if (fetchMoreCondition) {
            setIsLoadingMore(true)
            await fetchCreators(currentPage + 1)
            setIsLoadingMore(false)
        } else if (currentPage < lastPage && perPage < fetchLimit) {
            fetchInitial()
        }
    }

    const intersectionObserver = useMemo(
        () =>
            new IntersectionObserver(
                (entries) => {
                    const first = entries[0]
                    if (first.isIntersecting) {
                        fetchMore()
                    }
                },
                {
                    threshold: 0,
                    rootMargin: '0px 0px 100px 0px',
                },
            ),
        [creators, currentPage, lastPage],
    )

    useEffect(() => {
        if (endRef.current) {
            intersectionObserver.observe(endRef.current)
        }
        return () => {
            if (endRef.current) {
                intersectionObserver.unobserve(endRef.current)
            }
        }
    }, [endRef.current, intersectionObserver])

    const handleFilterChange = (e) => {
        const filterValue = +e?.target?.value
        if (!filters?.includes(filterValue)) {
            setFilters([...filters, filterValue])
        } else {
            setFilters(filters?.filter(item => item !== filterValue))
        }
    }

    const classes = useStyles({ filterSectionOpen, isMobile })
    return (
        <div className={classes.searchPage}>
            <div className={classes.listWrapper}>
                <div className={classes.listHeader}>
                    {total} results for:<span>{params?.name}</span>
                </div>
                {(!isLoadingMore && isLoading)
                    ?
                    <div className={classes.loadMoreSpinnerWrapper}>
                        <LoaderIcon />
                    </div>

                    :
                    creators?.map((creator) => {
                        return (
                            <SearchResultItem user={creator} />
                        )
                    })}
                {fetchMoreCondition && <div ref={endRef}></div>}
                {isLoadingMore && (
                    <div className={classes.loadMoreSpinnerWrapper}>
                        <LoaderIcon />
                    </div>
                )}
            </div>
            <div className={classes.filterWrapper}>
                {isMobile && (
                    <div className={classes.filterHeader}>
                        <BackIcon onClick={() => navigate(-1)} />
                        <span>Search Filters</span>
                        <FiltersAccordion
                            isOpen={filterSectionOpen}
                            callback={() =>
                                setFilterSectionOpen(!filterSectionOpen)
                            }
                        />
                    </div>
                )}

                {(filterSectionOpen || !isMobile) && (
                    <div className={classes.filterList}>
                        {!isMobile && (
                            <div className={classes.filterHeader}>
                                Search Filters
                            </div>
                        )}

                        {categories?.map((category, index) => {
                            if (!!category?.users_count ) {
                                return (
                                    <div className={classes.filterItem}>
                                        <Checkbox
                                            id={index}
                                            onChange={(e) =>
                                                handleFilterChange(e)
                                            }
                                            value={category?.id}
                                            checked={filters?.includes(
                                                category?.id
                                            )}
                                            disabled={isLoading}
                                        />
                                        <span>
                                            {category?.name} (
                                            {category?.users_count})
                                        </span>
                                    </div>
                                )
                            }
                        })}
                    </div>
                )}
            </div>
        </div>
    )
}

export default SearchPage
