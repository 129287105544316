import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles((theme) => ({
    thumb: {
        height: '100%',
        width: '100%',
        overflow: 'hidden',
        borderRadius: 16,
        '& > img': {
            height: '100%',
            width: '100%',
            objectFit: 'cover',
            objectPosition: 'center center',
        },
    },
}))

const MediaPostImage = ({
    size,
    subtitle,
    thumbSize = 720,
    onClick,
    ...props
}) => {
    const classes = useStyles()
    return (
        <a
            data-lg-size={size}
            data-src={props[1080]}
            {...(subtitle && { 'data-sub-html': `<p>${subtitle}</p>` })}
        >
            <div className={classes.thumb} onClick={onClick}>
                <img src={props[thumbSize]} />
            </div>
        </a>
    )
}

export default MediaPostImage
