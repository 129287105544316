import {
    InfoFilledIcon,
    NotificationCommentIcon,
    NotificationFollowIcon,
    NotificationLikeIcon,
    NotificationPlatformIcon,
    NotificationSubscriptionIcon,
    NotificationTipIcon,
    SuccessFilledIcon,
    WarningFilledIcon,
} from '../../theme/icons'
import React from 'react'
import colors from '../../theme/colors'

export const PRIVATE_CHANNEL_NAME = 'private-users'
export const USER_EVENTS_NAMES = {
    userNotification: 'notifications',
}

export const notifications = {
    FOLLOW: 'new_follower',
    SUBSCRIPTION: 'new_subscriber',
    BUNDLE_BUY: 'purchased_bundle',
    TIP: 'received_tip',
    LIKE_POST: 'liked_post',
    COMMENT_POST: 'commented_post',
    COMMENT_REPLY: 'replied_comment',
    PLATFORM_INFO: 'notification_info',
    PLATFORM_SUCCESS: 'notification_success',
    PLATFORM_WARNING: 'notification_warning',
    REFERRED_USER: 'referred_user'
}

export const remapNotificationType = (type) => {
    switch (type) {
        case notifications.LIKE_POST:
            return notifications.LIKE_POST
        case notifications.COMMENT_POST:
            return notifications.COMMENT_POST
        case notifications.COMMENT_REPLY:
            return notifications.COMMENT_REPLY
        case notifications.FOLLOW:
            return notifications.FOLLOW
        case notifications.TIP:
            return notifications.TIP
        case notifications.SUBSCRIPTION:
            return notifications.SUBSCRIPTION
        case notifications.PLATFORM_INFO:
            return notifications.PLATFORM_INFO
        case notifications.PLATFORM_WARNING:
            return notifications.PLATFORM_WARNING
        case notifications.PLATFORM_SUCCESS:
            return notifications.PLATFORM_SUCCESS
        case notifications.BUNDLE_BUY:
            return notifications.BUNDLE_BUY
        case notifications.REFERRED_USER:
            return notifications.REFERRED_USER
        default:
            return notifications.PLATFORM_INFO
    }
}

export const renderNotificationIcon = (type) => {
    switch (type) {
        case notifications.LIKE_POST:
            return <NotificationLikeIcon />
        case notifications.COMMENT_REPLY:
        case notifications.COMMENT_POST:
            return <NotificationCommentIcon />
        case notifications.FOLLOW:
            return <NotificationFollowIcon />
        case notifications.TIP:
            return <NotificationTipIcon />
        case notifications.SUBSCRIPTION:
            return <NotificationSubscriptionIcon />
        case notifications.PLATFORM_INFO:
        case notifications.PLATFORM_WARNING:
        case notifications.PLATFORM_SUCCESS:
            return <NotificationPlatformIcon />
        default:
            return <NotificationPlatformIcon />
    }
}

export const notificationsItemAssets = {
    [notifications.FOLLOW]: {
        description: 'Started following you',
        decorator: '',
    },
    [notifications.SUBSCRIPTION]: {
        description: 'New Subscriber',
        decorator: '',
    },
    [notifications.BUNDLE_BUY]: {
        description: 'New Subscriber',
        decorator: 'Bundle',
    },
    [notifications.TIP]: {
        description: 'Sent you a tip for this',
        decorator: 'Post',
    },
    [notifications.LIKE_POST]: {
        description: 'Liked your',
        decorator: 'Post',
    },
    [notifications.COMMENT_POST]: {
        description: 'Commented your',
        decorator: 'Post',
    },
    [notifications.COMMENT_REPLY]: {
        description: 'Replied your',
        decorator: 'Comment',
    },
    [notifications.PLATFORM_INFO]: {
        description: 'Message from the platform',
        decorator: '',
    },
    [notifications.PLATFORM_WARNING]: {
        description: 'Message from the platform',
        decorator: '',
    },
    [notifications.PLATFORM_SUCCESS]: {
        description: 'Message from the platform',
        decorator: '',
    },
    [notifications.REFERRED_USER]: {
        description: 'Joined via your referral link!',
        decorator: '',
    },
}

export const notificationsPlatformAssets = {
    [notifications.PLATFORM_INFO]: {
        color: colors.primary[500],
        backgroundColor: colors.primary[100],
        type: 'Info',
        icon: <InfoFilledIcon />,
    },
    [notifications.PLATFORM_WARNING]: {
        color: colors.quinary[500],
        backgroundColor: colors.quinary[100],
        type: 'Warning',
        icon: <WarningFilledIcon />,
    },
    [notifications.PLATFORM_SUCCESS]: {
        color: colors.success[600],
        backgroundColor: colors.success[100],
        type: 'Success',
        icon: <SuccessFilledIcon />,
    },
}

export const notificationsSettingsAssets = {
    [notifications.FOLLOW]: {
        description: 'Someone start to follow me',
    },
    [notifications.SUBSCRIPTION]: {
        description: 'Someone subscribe to my page',
    },
    [notifications.BUNDLE_BUY]: {
        description: 'Someone buy my bundle',
    },
    [notifications.TIP]: {
        description: 'Someone sent me a tip',
    },
    [notifications.LIKE_POST]: {
        description: 'Someone like my post',
    },
    [notifications.COMMENT_POST]: {
        description: 'Someone comment my post',
    },
    [notifications.COMMENT_REPLY]: {
        description: 'Someone reply to my comment',
    },
    [notifications.PLATFORM_INFO]: {
        description: 'Platform send: info message',
    },
    [notifications.PLATFORM_SUCCESS]: {
        description: 'Platform send: success message',
    },
    [notifications.PLATFORM_WARNING]: {
        description: 'Platform send: warning message',
    },
}
