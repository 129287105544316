import { createUseStyles } from 'react-jss'
import { HeartIcon } from '../../../../theme/icons'
import cx from 'classnames'

const useStyles = createUseStyles((theme) => ({
    cardContent: {
        display: 'grid',
        gap: 16,
    },
    roleChoice: {
        display: ({ title }) => title == undefined ? 'none' : 'grid',
        gridTemplateColumns: 'auto 1fr auto',
        gap: 16,
        alignItems: 'center',
    },
    roleTitle: {
        fontSize: 16,
        fontWeight: 700,

        color: theme.palette.primary.background,
        marginTop: 8,
    },
    roleImage: {
        display: 'grid',
        alignItems: 'center',
        justifyContent: 'center',
        width: 32,
        height: 32,
        borderRadius: 6,
    },
    roleImageCreator: {
        extend: 'roleImage',
        backgroundColor: '#FDDEDA',
    },
    roleImageFan: {
        extend: 'roleImage',
        backgroundColor: '#F7DAEA',
    },
}))

const ProfileCard = ({
    icon = <HeartIcon />,
    title,
    description,
    type = 'fan',
    action,
    withIcon = true,
    children,
    className,
}) => {
    const classes = useStyles({ title })

    return (
        <div className={cx(classes.cardContent, className)}>
            {description && <p>{description}</p>}
            <div className={classes.roleChoice}>
                {withIcon && (
                    <span
                        className={
                            type === 'fan'
                                ? classes.roleImageFan
                                : classes.roleImageCreator
                        }
                    >
                        {icon}
                    </span>
                )}
                <span className={classes.roleTitle}>{title}</span>
                {action && <span>{action}</span>}
            </div>
            {children}
        </div>
    )
}

export default ProfileCard
