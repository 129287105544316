import { createUseStyles } from 'react-jss'
import { Link } from 'react-router-dom'
import { ROUTE_LOGIN } from '../../../utilities/constants'
import ForgotPasswordForm from './forgot-password-form'
import { useState } from 'react'
import mailIcon from '../../../asset/images/mail.png'
import colors from "../../../theme/colors";

const useStyles = createUseStyles((theme) => ({
    root: {
        display: 'grid',
        gridTemplateRows: ({ requestSent }) =>
            !requestSent ? 'auto auto' : '1fr',
        gap: 16,
        alignItems: 'start',
        '& p': {
            alignSelf: 'end',
        },
    },
    content: {
        display: 'grid',
        gridAutoFlow: 'row',
        textAlign: 'center',
        justifyItems: 'center',
        gap: 24,
    },
    title: {
        ...theme.pageStyle.title,
    },
    subtitle: {
        ...theme.pageStyle.sectionTitle,
    },
    iconCard: {
        justifySelf: 'center',
        width: 160,
        height: 160,
        borderRadius: 12,
        backgroundColor: theme.palette.common.white,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        backgroundImage: ({ image }) => `url(${image})`,
    },
    wrapper: {
        display: 'grid',
        gap: 16,
    },
    fakeLink: {
        ...theme.link,
    },
}))

const linkColor = {
    color: '#2AB6A1',
}

const ForgotPassword = () => {
    const [requestSent, setRequestSent] = useState(false)

    const classes = useStyles({ requestSent, image: mailIcon })

    return (
        <div className={classes.root}>
            {!requestSent ? (
                <>
                    <div className={classes.wrapper}>
                        <h5 className={classes.title}>Forgot Password?</h5>
                        <p className={classes.subtitle}>
                            Enter the email address you used when you joined and
                            we’ll send you instructions to reset your password
                        </p>
                        <ForgotPasswordForm submitCallback={setRequestSent} />
                        <p>
                            <Link style={linkColor} to={ROUTE_LOGIN}>Return to Log In</Link>
                        </p>
                    </div>
                </>
            ) : (
                <>
                    <div
                        className={classes.wrapper}
                        style={{ alignSelf: 'center' }}
                    >
                        <div className={classes.iconCard} />
                        <div>
                            <h5 className={classes.title}>Check your mail</h5>
                            <p>
                                We have sent a password recover instructions to
                                your email
                            </p>
                        </div>
                    </div>
                    <p>
                        Did not receive the email? Check your spam filter or{' '}
                        <span
                            className={classes.fakeLink}
                            onClick={() => setRequestSent(false)}
                        >
                            try another email address
                        </span>
                    </p>
                </>
            )}
        </div>
    )
}

export default ForgotPassword
