import { Navigate, Outlet } from 'react-router-dom'
import { useSelector } from 'react-redux'
import PublicLayout from '../../containers/public-layout'
import { selectAccessToken } from '../../store/slices/user'
import { ROUTE_HOME } from '../../utilities/constants'

const PublicRoute = () => {
    const accessToken = useSelector(selectAccessToken)

    return accessToken ? (
        <Navigate to={ROUTE_HOME} />
    ) : (
        <PublicLayout>
            <Outlet />
        </PublicLayout>
    )
}

export default PublicRoute
