import { createUseStyles } from 'react-jss'
import UserAvatar from '../../../post-header/user-info/user-avatar'
import { Link } from 'react-router-dom'
import { LikeFilledIcon, LikeIcon } from '../../../../../../../theme/icons'
import { useMemo, useState } from 'react'
import {
    getHumanReadableDate,
    handleApiError,
} from '../../../../../../../utilities/helpers'
import CommentInput from '../comment-input'
import {
    httpFetchNestedComments,
    httpLikeComment,
    httpUnlikeComment,
} from '../../../../../../../http-requests/comment'
import { ROUTE_FANPAGE } from '../../../../../../../utilities/constants'
import NestedCommentsLoader from './loaders/NestedCommentsLoader'

const useStyles = createUseStyles((theme) => ({
    root: {
        padding: ({ isChild }) => (isChild ? '0 0 0 16px ' : '16px'),
        '&:not(:last-child)': {
            borderBottom: ({ isChild }) =>
                isChild ? 'none' : `1px solid ${theme.palette.grey[200]}`,
        },
    },
    commentWrapper: {
        position: 'relative',
        padding: ({ isChild, depth }) =>
            isChild ? `0 0 0 ${24 + (depth - 1) * 28}px` : 0,
    },
    comment: {
        display: 'flex',
        gap: 8,
    },
    contentWrapper: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        gap: 4,
    },
    userName: {
        '& a': {
            fontWeight: 700,
            color: theme.palette.primary.background,
            fontSize: 14,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: 120,
        },
        '&:after': {
            content: `'·'`,
            margin: [0, 4],
        },
        [theme.mediaQueries.sUpM]: {
            maxWidth: 400,
        },
    },
    text: {
        flex: 1,
        display: 'inline-block',
        fontSize: 14,
        //paddingTop: 6,
    },
    commentFooter: {
        display: 'flex',
        marginBottom: 8,
        '& > li > span': {
            color: theme.palette.grey[400],
            fontSize: 12,
            fontWeight: 500,
        },
        '& > li:not(:last-child) > span': {
            '&:after': {
                content: `'·'`,
                margin: [0, 4],
            },
        },
    },
    likeComment: {
        alignSelf: 'start',
        display: 'flex',
        padding: 4,
        borderRadius: 6,
        '&:hover': {
            cursor: 'pointer',
            backgroundColor: theme.palette.secondary.backgroundLight,
            '& > svg > path': {
                stroke: ({ isLiked }) =>
                    isLiked ? `none` : `${theme.palette.tertiary.main}`,
            },
        },
    },
    showReplyBox: {
        color: theme.palette.grey[400],
        fontWeight: 700,
        fontSize: 14,
        cursor: 'pointer',
        marginBottom: 8,
        padding: ({ isChild, depth }) =>
            isChild ? `0 0 0 ${68 + (depth - 1) * 28}px` : `0 0 0 40px`,
    },
    reply: {
        cursor: 'pointer',
    },
    childrenContainer: {},
    replyInput: {
        padding: ({ isChild, depth }) =>
            isChild ? `0 0 0 ${68 + (depth - 1) * 28}px` : `0 0 0 40px`,
    },
    loadMoreSpinnerWrapper: {
        position: 'relative',
        padding: [0, 12],
    },
}))

const Comment = ({
    id,
    user,
    content,
    date,
    likes,
    isLiked,
    isChild,
    depth = 0,
    canReply = false,
    setVisibleRepliesCount,
    nestedCommentsCount,
}) => {
    const [likesCount, setLikesCount] = useState(likes)
    const [isLikedLocal, setIsLikedLocal] = useState(isLiked)
    /* Pagination */
    const [currentPage, setCurrentPage] = useState(0)
    const [lastPage, setLastPage] = useState(0)
    const [perPage, setPerPage] = useState(0)
    const [total, setTotal] = useState(0)
    const [childrenComments, setChildrenComments] = useState([])
    const fetchLimit = 5

    const [isLoading, setIsLoading] = useState(false)
    const [showCommentInput, setShowCommentInput] = useState(false)

    const hasChild = !!childrenComments?.length
    const fetchNestedComments = async (page = 1) => {
        if (page === 1 || (currentPage < lastPage && perPage >= fetchLimit))
            try {
                setIsLoading(true)
                const {
                    data: { data, current_page, last_page, total, per_page },
                } = await httpFetchNestedComments({
                    commentID: id,
                    page,
                    limit: fetchLimit,
                })

                setChildrenComments(
                    current_page === 1 ? data : [...childrenComments, ...data]
                )
                setCurrentPage(current_page)
                setLastPage(last_page)
                setTotal(total)
                setPerPage(per_page)
                setVisibleRepliesCount(data?.length)
            } catch (error) {
                handleApiError({
                    isReduxError: false,
                    error,
                })
            } finally {
                setIsLoading(false)
            }
    }

    const parsedDate = useMemo(() => {
        return getHumanReadableDate(date)
    }, [date])

    const onReplyHandler = () => {
        setShowCommentInput(true)
    }

    const onLikeHandler = async () => {
        try {
            const httpCall = !isLikedLocal ? httpLikeComment : httpUnlikeComment
            const { data } = await httpCall({ commentID: id })
            setLikesCount((prevCount) => {
                return isLikedLocal ? prevCount - 1 : prevCount + 1
            })
            setIsLikedLocal(!isLikedLocal)
        } catch (error) {
            handleApiError({
                isReduxError: false,
                error,
            })
        }
    }

    const classes = useStyles({ isChild, depth, isLiked: isLikedLocal })
    return (
        <li className={classes.root}>
            <div className={classes.commentWrapper}>
                <div className={classes.comment}>
                    <UserAvatar
                        avatar={user?.avatar?.[128]}
                        isOnline={user?.is_online}
                        width={!isChild ? 32 : 20}
                        height={!isChild ? 32 : 20}
                        username={user?.username}
                    />
                    <div className={classes.contentWrapper}>
                        <p className={classes.text}>
                            <span className={classes.userName}>
                                <Link
                                    to={ROUTE_FANPAGE.replace(
                                        ':username',
                                        user?.username
                                    )}
                                >
                                    {user?.display_name}
                                </Link>
                            </span>
                            {content}
                        </p>
                        <ul className={classes.commentFooter}>
                            <li>
                                <span>{parsedDate}</span>
                            </li>
                            <li>
                                <span>{likesCount} Likes</span>
                            </li>
                            {canReply && (
                                <li>
                                    <span
                                        className={classes.reply}
                                        onClick={onReplyHandler}
                                    >
                                        Reply
                                    </span>
                                </li>
                            )}
                        </ul>
                    </div>
                    <div
                        className={classes.likeComment}
                        onClick={onLikeHandler}
                    >
                        {isLikedLocal ? (
                            <LikeFilledIcon />
                        ) : (
                            <LikeIcon width={24} height={24} />
                        )}
                    </div>
                </div>
            </div>

            {!!childrenComments.length && (
                <div className={classes.childrenContainer}>
                    {hasChild && (
                        <ul>
                            {childrenComments.map(
                                (
                                    {
                                        id,
                                        created_at,
                                        comment,
                                        likes_count,
                                        user,
                                        is_liked,
                                    },
                                    idx
                                ) => (
                                    <Comment
                                        id={id}
                                        key={idx}
                                        isChild
                                        likes={likes_count}
                                        date={created_at}
                                        content={comment}
                                        user={user}
                                        depth={depth + 1}
                                        isLiked={is_liked}
                                    />
                                )
                            )}
                            {isLoading && (
                                <div className={classes.loadMoreSpinnerWrapper}>
                                    <NestedCommentsLoader rows={2} />
                                </div>
                            )}
                        </ul>
                    )}
                </div>
            )}

            {!!nestedCommentsCount && (!currentPage || currentPage < lastPage) && (
                <div
                    className={classes.showReplyBox}
                    onClick={() => fetchNestedComments(currentPage + 1)}
                >
                    Show {nestedCommentsCount - childrenComments?.length}{' '}
                    {nestedCommentsCount > 1 ? `replies` : `reply`}
                </div>
            )}

            {showCommentInput && (
                <div className={classes.replyInput}>
                    <CommentInput
                        isSmall
                        parentCommentID={id}
                        submitCallback={(newReplyComment) =>
                            setChildrenComments((currentChildrenComments) => [
                                ...currentChildrenComments,
                                newReplyComment,
                            ])
                        }
                    />
                </div>
            )}
        </li>
    )
}

export default Comment
