import { createUseStyles } from 'react-jss'
import colors from '../../../../theme/colors'
import Box from '../../../../components/Box'
import ActivityLog from './activity-log'
import Divider from '../../../../components/Divider'

const useStyles = createUseStyles((theme) => ({
    groupHeader: {
        fontSize: 12,
        lineHeight: '14px',
        color: colors.greyscale[400],
        marginBottom: 24,
    },
    activitiesGroup: {
        marginBottom: 16,
    },
}))

const ActivitiesGroup = ({ date, logs }) => {
    const classes = useStyles()

    return (
        <Box classNames={classes.activitiesGroup}>
            <p className={classes.groupHeader}>{date}</p>
            {logs.map((log, index) => (
                <div key={index}>
                    <ActivityLog log={log} />
                    {index < logs.length - 1 && <Divider />}
                </div>
            ))}
        </Box>
    )
}

export default ActivitiesGroup
