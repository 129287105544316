import {createUseStyles} from 'react-jss'
import React, {useEffect, useState} from 'react'
import ItemText from '../itemText/ItemText'
import ChatContextMenu from '../../../chatContextMenu/ChatContextMenu'
import dayjs from 'dayjs'
import ChatGalleryContent from './ChatGalleryContent'
import {useWindowSize} from '../../../../../../../hooks/useWindowSize'
import {httpRetrieveChatMedia} from "../../../../../../../http-requests/messages";
import {handleApiError} from "../../../../../../../utilities/helpers";
import Spinner from "../../../../../../../components/Spinner";
import {useDispatch, useSelector} from "react-redux";
import {selectChatMedia, setChatMedia} from "../../../../../../../store/slices/messages";
import {selectUser} from "../../../../../../../store/slices/user";

const useStyles = createUseStyles((theme) => ({
    itemMedia: {
        display: 'flex',
        flexDirection: 'column',
        gap: 8,
    },
    row: ({isMine}) => ({
        display: 'flex',
        justifyContent: 'flex-end',
        flexDirection: isMine ? 'row' : 'row-reverse',
        gap: 8,
    }),
    mediaWrapper: {
        display: 'flex',
        flexDirection: 'column',
        gap: 8,
    },
    image: {
        height: 150,
        width: 150,
    },
    date: {
        fontSize: 10,
        fontWeight: 500,
        color: '#AEAEAE',
        letterSpacing: 0.25,
    },

    mediaFrame: {
        position: 'relative',
        height: 224,
        width: 279,
        borderRadius: 16,
        boxShadow:
            '0px 1px 3px rgba(20, 20, 42, 0.1), 0px 0px 1px rgba(20, 20, 42, 0.05)',
        border: '8px solid white',
        overflow: 'hidden',
    },
}))

const ItemMedia = ({message, userData}) => {
    const me = useSelector(selectUser)
    const isMine = message?.uuid === me?.pubnub_id
    const dispatch = useDispatch()
    const date = dayjs(message?.timetoken / 10000).format('h:mm A ')
    const mediaToRetrieve = message?.meta?.media
    const [media, setMedia] = useState([])
    const hasText = message?.message && message?.message !== ' '
    const mediaSelector = useSelector(selectChatMedia)
    const {width} = useWindowSize()
    const isMobile = width <= 468

    useEffect(() => {
        retrieveMedia(mediaToRetrieve)
    }, [])

    const retrieveMedia = async (media) => {
        let retrievedMedia = []
        for (const m of media) {
            try {
                if (Object.keys(mediaSelector).includes(m + "")) {
                    retrievedMedia.push(mediaSelector[m + ""])
                } else {
                    const {data} = await httpRetrieveChatMedia(m)
                    retrievedMedia.push(data)
                    await dispatch(setChatMedia(data))
                }
            } catch (error) {
                handleApiError({
                    isReduxError: false,
                    error,
                })
            }
        }
        setMedia(retrievedMedia)
    }

    const classes = useStyles({isMine, hasText})
    return (
        <div className={classes.itemMedia}>
            <div className={classes.row}>
                {hasText && (
                    <ItemText
                        message={message}
                        userData={userData}
                        isMobile={isMobile}
                        isMine={isMine}
                    />
                )}
            </div>
            <div className={classes.mediaWrapper}>
                {!hasText && (
                    <div className={classes.row}>
                        <div className={classes.date}>{date}</div>
                    </div>
                )}
                <div className={classes.row}>
                    {!hasText && (
                        <ChatContextMenu
                            isMobile={isMobile}
                            message={message}
                            isMine={isMine}
                        />
                    )}
                    <div className={classes.mediaFrame}>
                        {!media.length
                            ? <Spinner/>
                            : <ChatGalleryContent media={media}/>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ItemMedia
