import { useEffect, useMemo, useState } from 'react'
import { createUseStyles, useTheme } from 'react-jss'
import UserInfo from './user-info'
import { AlertIcon, FavouriteIcon, MoreIcon } from '../../../../../theme/icons'
import ContextMenu from '../../../../../components/ContextMenu'
import Divider from '../../../../../components/Divider'
import {
    copyTextToClipboard,
    getHumanReadableDate,
    handleApiError,
} from '../../../../../utilities/helpers'
import { useDispatch, useSelector } from 'react-redux'
import { selectUser } from '../../../../../store/slices/user'
import { httpFollowUser, httpUnfollowUser } from '../../../../../http-requests'
import { deletePost } from '../../../../../store/slices/timeline'

const useStyles = createUseStyles((theme) => ({
    root: {
        display: 'grid',
        gridTemplateColumns: '1fr auto auto',
        alignItems: 'center',
        gap: 4,
        padding: [16, 8],
        [theme.mediaQueries.sUp]: {
            gap: 8,
            padding: 16,
        },
    },
    postDate: {
        color: theme.palette.grey[500],
        fontSize: 12,
        fontWeight: 500,
    },
    actionArea: {
        display: 'grid',
        gridAutoFlow: 'column',
        padding: [0, 0, 0, 8],
        gap: 4,
        borderLeft: `1px solid ${theme.palette.grey[300]}`,
        '& > span': {
            cursor: 'pointer',
            padding: 6,
            borderRadius: 6,
            '&:hover': {
                color: theme.palette.secondary.main,
                backgroundColor: theme.palette.secondary.backgroundLight,
                stroke: `${theme.palette.secondary.main} !important`,
            },
        },
    },
    menu: {
        position: 'relative',
        cursor: 'pointer',
    },
    contextMenu: {
        backgroundColor: '#FCFCFC',
        borderRadius: 12,
        width: 200,
        padding: 8,
        gap: 8,
    },
    contextSection: {
        '& > span': {
            gridTemplateColumns: '1fr auto',
            color: theme.palette.grey[600],
            '&:not(:last-child)': {
                borderBottom: `unset !important`,
            },
        },
    },
    contextSectionNormal: {
        extend: 'contextSection',
        '& > span': {
            '&:hover': {
                color: theme.palette.primary.middle,
                backgroundColor: theme.palette.primary.buttonLight,
                '& > svg': {
                    stroke: `${theme.palette.tertiary.main} !important`,
                },
            },
        },
    },
    contextSectionDanger: {
        extend: 'contextSection',
        '& > span': {
            '&:hover': {
                color: theme.palette.tertiary.main,
                backgroundColor: theme.palette.secondary.backgroundLight,
                '& > svg': {
                    stroke: `${theme.palette.primary.main} !important`,
                },
            },
        },
    },
    divider: {
        margin: [0, -8],
    },
    iconBackground: {
        '&:hover': {
            backgroundColor: '#F3F3F3',
            borderRadius: 6,
        },
    },
}))

const PostHeader = ({ date, user, postID }) => {
    const loggedUser = useSelector(selectUser)
    const dispatch = useDispatch()
    const [isFollowed, setIsFollowed] = useState(user?.is_followed)
    const [isContextMenuVisible, setIsContextMenuVisible] = useState(false)
    const [isMe, setIsMe] = useState(user?.id === loggedUser?.id)

    useEffect(() => {
        setIsMe(user?.id === loggedUser?.id)
    }, [user, loggedUser])

    const parsedDate = useMemo(() => {
        return getHumanReadableDate(date, 'MM/DD/YYYY')
    }, [date])

    const handleReportPost = () => {
        window.location = `mailto:${process.env.REACT_APP_SUPPORT_EMAIL}?subject=Report Post: #${postID} of user ${user?.id}`
    }

    const handleDeletePost = () => {
        dispatch(deletePost({ postID }))
    }

    const setFollow = async () => {
        const httpCall = isFollowed ? httpUnfollowUser : httpFollowUser
        try {
            const { data } = await httpCall(user?.id)
            setIsFollowed(!isFollowed)
        } catch (error) {
            handleApiError({
                isReduxError: false,
                error,
            })
        }
    }

    const classes = useStyles()

    return (
        <div className={classes.root}>
            <UserInfo
                name={user?.display_name}
                username={user?.username}
                isVerified={user?.is_verified}
                avatar={user?.avatar?.[128]}
                category={user?.category?.name}
                isOnline={user?.is_online}
            />
            <div className={classes.postDate}>{parsedDate}</div>
            <div
                className={classes.menu}
                onClick={() => setIsContextMenuVisible(!isContextMenuVisible)}
            >
                <MoreIcon
                    className={classes.iconBackground}
                    width={30}
                    height={30}
                />
                {isContextMenuVisible && (
                    <ContextMenu
                        className={classes.contextMenu}
                        onCloseHandler={() => setIsContextMenuVisible(false)}
                    >
                        <div className={classes.contextSectionNormal}>
                            {!isMe && (
                                <span onClick={setFollow}>
                                    {isFollowed ? 'Unfollow' : 'Follow'}
                                </span>
                            )}
                            <span
                                onClick={() =>
                                    copyTextToClipboard(
                                        `${window.location.hostname}/${user?.username}`
                                    )
                                }
                            >
                                Copy profile link
                            </span>
                        </div>
                        <Divider className={classes.divider} />
                        <div className={classes.contextSectionDanger}>
                            {user?.id === loggedUser?.id && (
                                <span onClick={handleDeletePost}>Delete</span>
                            )}
                            {user?.id !== loggedUser?.id && (
                                <span onClick={handleReportPost}>Report</span>
                            )}
                        </div>
                    </ContextMenu>
                )}
            </div>
        </div>
    )
}

export default PostHeader
