import * as yup from 'yup'

const WALLET_MODEL = {
    address: 'address',
    networkId: 'network_id',
    tokenId: 'token_id',
    networkStandardId: 'network_standard_id',
    password: 'address',
}

const walletStepOneSchema = yup.object().shape({
    [WALLET_MODEL.address]: yup.string().required('Address is mandatory'),
})

const walletStepTwoSchema = yup.object().shape({
    [WALLET_MODEL.networkId]: yup
        .object()
        .nullable()
        .required('You must choose a Network'),
})

const walletStepThreeSchema = yup.object().shape({
    [WALLET_MODEL.tokenId]: yup
        .object()
        .nullable()
        .required('You must choose a Token'),
    [WALLET_MODEL.networkStandardId]: yup
        .object()
        .required('You must choose a Token standard'),
})

const editTokenSchema = yup.object().shape({
    [WALLET_MODEL.password]: yup.string().required('Password is mandatory'),
    [WALLET_MODEL.tokenId]: yup
        .object()
        .nullable()
        .required('You must choose a Token'),
    [WALLET_MODEL.networkStandardId]: yup
        .object()
        .required('You must choose a Token standard'),
})

export {
    WALLET_MODEL,
    walletStepOneSchema,
    walletStepTwoSchema,
    walletStepThreeSchema,
    editTokenSchema,
}
