import React, { useEffect, useState } from 'react'
import { createUseStyles } from 'react-jss'
import NotificationsPanel from './notificationsPanel/NotificationsPanel'
import { useWindowSize } from '../../hooks/useWindowSize'

const useStyles = createUseStyles((theme) => ({
    notifications: {
        height: '100%',
        ...theme.utils.flexbox.start,
        overflow: 'hidden',
        zIndex: 0,
        [theme.mediaQueries.mUp]: {
            margin: [-4, -10],
            padding: 24,
            width: '100%',
        },
    },
}))

const Notifications = ({}) => {
    const { width } = useWindowSize()
    const [isMobile, setIsMobile] = useState(width <= 448)

    useEffect(() => {
        setIsMobile(width <= 448)
    }, [width])

    const classes = useStyles({})
    return (
        <div className={classes.notifications}>
            <NotificationsPanel extended={true} isMobilePage={isMobile} />
        </div>
    )
}

export default Notifications
