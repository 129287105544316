import React, {useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState} from 'react'
import {createUseStyles} from 'react-jss'
import {GroupedVirtuoso} from 'react-virtuoso'
import MessageItem from './messageItem/MessageItem'
import {
    addMessage, clearUnreadMessageCount, resetMessages,
    selectContextMenuOpen,
    selectFirstMessageTimeToken,
    selectMessages,
    selectMessageToDelete,
    setContextMenuOpen,
    setMessages,
    setMessageToDelete,
} from '../../../../store/slices/messages'
import {useDispatch, useSelector} from 'react-redux'
import ChatInput from './chatInput/ChatInput'
import dayjs from 'dayjs'
import DotsLoader from '../../../../components/DotsLoader'
import colors from '../../../../theme/colors'
import ChatContextMenuMobile from './chatContextMenu/ChatContextMenuMobile'
import cx from 'classnames'
import PPVmodal from './ppvModal/PPVmodal'
import {selectUser} from '../../../../store/slices/user'
import DeleteMessageModal from './deleteMessageModal/DeleteMessageModal'
import TipChatModal from './chatTipModal/ChatTipModal'
import {usePubNub} from 'pubnub-react'
import {
    selectPpvModalOpen,
    selectTipModalOpen,
    setChatTipModalOpen,
    setPpvModalOpen
} from "../../../../store/slices/conversations";
import ChatSection from "./chatSection/ChatSection";
import {PubNubManager} from "../../../../utilities/pubnubManager";
import {MESSAGE_TYPES} from "../../../../utilities/constants/chat";
import {httpSetLastMessageSent, httpSetLastReadTimetoken} from "../../../../http-requests/messages";

const useStyles = createUseStyles((theme) => ({
    chat: {
        height: '100%',
        display: 'grid',
        gridTemplateColumns: '1fr',
        gridTemplateRows: '1fr auto',
        overflowY: 'hidden',
        backgroundColor: colors.greyscale[150],
    },
    header: {
        height: 24,
    },
    footer: {
        height: 24,
    },
}))

const Chat = ({channelUUID, currentConversation, classname, isMobile}) => {
    const virtuosoRef = useRef(null)
    const dispatch = useDispatch()
    const messages = useSelector(selectMessages)
    const isContextMenuOpen = useSelector(selectContextMenuOpen)
    const isPpvModalOpen = useSelector(selectPpvModalOpen)
    const isDeleteModalOpen = useSelector(selectMessageToDelete)
    const isTipModalOpen = useSelector(selectTipModalOpen)
    const userMe = useSelector(selectUser)

    const [isLoading, setIsLoading] = useState(false)
    const [isLoadingMore, setIsLoadingMore] = useState(false)
    const [isNewMessage, setIsNewMessage] = useState(false)
    const [isExpired, setIsExpired] = useState(false)

    const today = dayjs().format('DD/MM/YYYY')
    const yesterday = dayjs().subtract(1, 'day').format('DD/MM/YYYY')

    const START_INDEX = 10000
    const [firstItemIndex, setFirstItemIndex] = useState(START_INDEX)

    const pubnub = usePubNub()
    const firstMessageTimeToken = useSelector(selectFirstMessageTimeToken)
    const [endOfHistory, setEndOfHistory] = useState(false)


    // Add listener for messages
    useEffect(
        () => {
            const pubnubListeners = {
                message: (receivedMessage) => {
                    const {
                        channel,
                        message,
                        userMetadata,
                        publisher,
                        timetoken,
                        ...rest
                    } = receivedMessage
                    if(userMetadata?.type === MESSAGE_TYPES.MEDIA){
                        console.log('media refetch needed') // TODO: implement?
                    }
                    /*dispatch(
                        addMessage({
                            channel,
                            message,
                            timetoken,
                            meta: userMetadata,
                            uuid: publisher,
                        })
                    )*/
                },
            }
            pubnub.addListener(pubnubListeners)
            return () => pubnub.removeListener(pubnubListeners)
        },
        [pubnub]
    )

    useLayoutEffect(() => {
        console.log('layout effect')
        setFirstItemIndex(START_INDEX)
        setEndOfHistory(false)
        setIsExpired(false)
        reloadConversation()

    }, [currentConversation?.channel])

    const reloadConversation = async () => {
        // if channel change, fetch history again
        await dispatch(resetMessages())
        await fetchPubnubHistory()
        if (
            (
                currentConversation?.status === 'expired' ||
                currentConversation?.status === 'inactive' ||
                currentConversation?.status === 'to_renew'

            ) &&
            currentConversation.is_creator
        ) {
            // Set last fake message to force subscription
            dispatch(
                addMessage({
                    channel: currentConversation?.channel,
                    timetoken: `${new Date().getTime() * 10000}`,
                    meta: {
                        type: 'expired',
                    },
                })
            )
            setIsExpired(true)
        }
    }


    // Read message if on active conversation
    useEffect(() => {
        if (messages.allIds.length > 0) {
            const lastReadTimetoken = messages.byId[messages.allIds[messages.allIds.length - 1]]?.timetoken
            markAsRead(lastReadTimetoken)
        }
    }, [currentConversation, messages])


      const markAsRead = useCallback(
          async (messageTimetoken) => {
          // TODO: do this only when message is visible
          if(Object.keys(pubnub).length){
              try {
                  const channelID = currentConversation?.channel
                  const membership = await PubNubManager.markMessageAsRead({
                      pubnub,
                      channel: channelID,
                      messageTimetoken
                  })
                  httpSetLastReadTimetoken({channelID: currentConversation?.channelID, timetoken: messageTimetoken})
                  dispatch(clearUnreadMessageCount({ channel: channelID }))
              }catch (err){
                  console.log('[markAsRead]', err)
              }
          }
        },
          [currentConversation?.channel, pubnub])


    // TODO redo with PubnubManager
    const fetchPubnubHistory = async (start) => {
        const fetchLimit = 10
        try {
            if(start){
                setIsLoadingMore(true)
            }
            const {channels} = await pubnub.fetchMessages({
                channels: [currentConversation?.channel],
                count: fetchLimit,
                includeMeta: true,
                includeMessageActions: true,
                start,
            })

            if (channels[currentConversation?.channel]?.length) {
                setFirstItemIndex(firstItemIndex - channels[currentConversation?.channel]?.length)
                dispatch(setMessages({messages: channels[currentConversation?.channel], start}))
                // if less than "fetchLimit" items, there is no more to fetch
                if(channels[currentConversation?.channel]?.length < fetchLimit){
                    setEndOfHistory(true)
                }
            } else {
                setEndOfHistory(true)
            }
        } catch (e) {
            console.log(e)
        } finally {
            setIsLoadingMore(false)
        }
    }

    // Send message
    const handleMessage = async (input) => {
        // Send the message
        if (input?.type === 'text') {
            await pubnub.publish({
                channel: currentConversation?.channel,
                message: input.value,
                meta: {
                    type: 'text',
                },
            })
            httpSetLastMessageSent({channelID: currentConversation?.channelID})
            setIsNewMessage(true)
        }
    }


    const onSetPage = useCallback((isAtTop) => {
        if (firstMessageTimeToken && !endOfHistory && isAtTop) {
            fetchPubnubHistory(firstMessageTimeToken)
        }
    }, [firstMessageTimeToken, endOfHistory])


    // TODO: review this for VirtuosoGroup
    useEffect(() => {
        if (isNewMessage) {
            virtuosoRef.current.scrollToIndex({
                index: messages.allIds.length - 1,
                behavior: 'smooth',
            })
            setIsNewMessage(false)
        }
    }, [isNewMessage])


    //
    const messagesByDate = useMemo(
        () => {
            return messages.allIds.reduce((finalObj, currentTimetokenKey) => {
                let groupKey = dayjs(currentTimetokenKey / 10000).format('YYYYMMDD');
                if (!finalObj[groupKey]) {
                    finalObj[groupKey] = [];
                }
                finalObj[groupKey].push(messages.byId[currentTimetokenKey]);
                return finalObj;
            }, {});
        },
        [messages, currentConversation]
    )

    // Needed for Virtuoso
    const {messageByDateGroupKeys, messageByDateGroupCount} = useMemo(
        () => {
            const messageByDateGroupKeys = Object.keys(messagesByDate)
            const messageByDateGroupCount = messageByDateGroupKeys.map( key => messagesByDate[key].length)
            return {messageByDateGroupKeys, messageByDateGroupCount}
        },
        [messagesByDate]
    )

    const classes = useStyles()
    return (
        <div className={cx(classname, classes.chat)}>
            {
                isLoading ?
                    <DotsLoader/>
                    :
                    <>
                        <GroupedVirtuoso
                            ref={virtuosoRef}
                            followOutput={true}
                            groupCounts={messageByDateGroupCount}
                            groupContent={index => {
                                return (
                                    <ChatSection
                                        date={messageByDateGroupKeys[index]}
                                        today={today}
                                        yesterday={yesterday}
                                    />
                                )
                            }}
                            atTopStateChange={onSetPage}
                            itemContent={(itemIndex, groupIndex) => {
                                // Needed because itemIndex is global and not relative to groupIndex
                                const alreadyLooped = messageByDateGroupCount.reduce(
                                    (finalCount, currentGroupCount, currentIndex ) => {
                                        return  finalCount += (currentIndex < groupIndex ?  currentGroupCount : 0)
                                    },
                                    0
                                )
                                return (
                                    <MessageItem
                                        message={messagesByDate[messageByDateGroupKeys[groupIndex]][itemIndex - alreadyLooped]}
                                        isMobile={isMobile}
                                        today={today}
                                        yesterday={yesterday}
                                        user={currentConversation}
                                    />
                                )
                            }}
                            components={{
                                Header: () =>
                                    isLoadingMore ? (
                                        <DotsLoader/>
                                    ) : (
                                        <div className={classes.header}></div>
                                    ),
                                Footer: () => <div className={classes.footer}/>,
                            }}
                        />
                        <ChatInput
                            onInput={(input) => handleMessage(input)}
                            isMobile={isMobile}
                            isExpired={isExpired}
                            channelUUID={channelUUID}
                            hasWallet={currentConversation?.has_wallet}
                        />
                    </>
            }

            {isTipModalOpen && (
                <TipChatModal
                    user={currentConversation}
                    onClose={() => dispatch(setChatTipModalOpen(false))}
                />
            )}
            {isMobile && isContextMenuOpen && <ChatContextMenuMobile/>}
            {isPpvModalOpen && (
                <PPVmodal
                    user={currentConversation}
                    onClose={() => dispatch(setPpvModalOpen(false))}
                />
            )}
            {isDeleteModalOpen && (
                <DeleteMessageModal
                    message={isDeleteModalOpen}
                    isMobile={isMobile}
                    userMe={userMe}
                    onClose={() => {
                        dispatch(setMessageToDelete(false))
                        dispatch(setContextMenuOpen(false))
                    }}
                />
            )}
        </div>
    )
}

export default Chat
