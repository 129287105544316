import { createUseStyles } from 'react-jss'
import ActionBar from './action-bar'
import CommentSection from './comment-section'
import { useDispatch, useSelector } from 'react-redux'
import { selectUser } from '../../../../../store/slices/user'
import { POST_ACTION_NAMES } from '../../../../../utilities/constants'
import { setSubscriptionModalOpen } from '../../../../../store/slices/app'

import React, { useState } from 'react'
import {
    likePost,
    savePost,
    selectIsTimelineLoading,
    unlikePost,
    unsavePost,
} from '../../../../../store/slices/timeline'
import TipModal from '../../../../creator-page/tip-modal/TipModal'
import TipSuccessModal from '../../../../creator-page/tip-modal/tipSuccessModal/TipSuccessModal'
import Tooltip from "../../../../../components/Tooltip";

const useStyles = createUseStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
    },
}))

const PostFooter = ({
    locked,
    postID,
    isSaved,
    isLiked,
    totalComments,
    totalLikes,
    comments,
    user,
    media,
    date,
}) => {
    const dispatch = useDispatch()
    const isLoading = useSelector(selectIsTimelineLoading)
    const loggedUser = useSelector(selectUser)
    const isMine = loggedUser?.id === user?.id
    const [showCommentSection, setShowCommentSection] = useState(false)
    const [tipModalOpen, setTipModalOpen] = useState(false)
    const [isTipSuccessful, setIsTipSuccessful] = useState(false)

    const actionsCallbacks = loggedUser
        ? {
              [POST_ACTION_NAMES.comment]: () =>
                  setShowCommentSection(!showCommentSection),
              [POST_ACTION_NAMES.like]: () =>
                  !isLiked
                      ? dispatch(likePost({ postID }))
                      : dispatch(unlikePost({ postID })),
              [POST_ACTION_NAMES.save]: () =>
                  !isSaved
                      ? dispatch(savePost({ postID }))
                      : dispatch(unsavePost({ postID })),
              [POST_ACTION_NAMES.tip]: () => setTipModalOpen(true),
          }
        : {
              [POST_ACTION_NAMES.comment]: () =>
                  dispatch(setSubscriptionModalOpen({ isOpen: true, user })),
              [POST_ACTION_NAMES.like]: () =>
                  dispatch(setSubscriptionModalOpen({ isOpen: true, user })),
              [POST_ACTION_NAMES.save]: () =>
                  dispatch(setSubscriptionModalOpen({ isOpen: true, user })),
              [POST_ACTION_NAMES.tip]: () =>
                  dispatch(setSubscriptionModalOpen({ isOpen: true, user })),
          }

    const classes = useStyles()

    return (
        <>
            <div className={classes.root}>
                <ActionBar
                    user={user}
                    locked={locked}
                    isSaved={isSaved}
                    isLiked={isLiked}
                    totalLikes={totalLikes}
                    totalComments={totalComments}
                    callbacks={!isLoading && actionsCallbacks}
                    isMine={isMine}
                />
                {showCommentSection && (
                    <CommentSection
                        postID={postID}
                        totalComments={totalComments}
                        initialComments={comments}
                    />
                )}
            </div>
            {tipModalOpen && (
                <TipModal
                    user={user}
                    media={media}
                    date={date}
                    onClose={() => setTipModalOpen(false)}
                    onSuccess={() => {
                        setTipModalOpen(false)
                        setIsTipSuccessful(true)
                    }}
                />
            )}
            {isTipSuccessful && (
                <TipSuccessModal onClose={() => setIsTipSuccessful(false)} />
            )}
        </>
    )
}

export default PostFooter
