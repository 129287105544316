import React, { useEffect, useRef, useState, useMemo } from 'react'
import { createUseStyles } from 'react-jss'
import MessagesPanelHeader from './panelHeader/MessagesPanelHeader'
import MessageToast from './messageToast/MessageToast'
import AsyncSearchbar from '../../../components/AsyncSearchbar'
import { httpSearchUser } from '../../../http-requests'
import { usePubNub } from 'pubnub-react'
import { useDispatch, useSelector } from 'react-redux'
import {
    selectIsPresenceFetched, selectMyChannelIDs, selectMyConversations,
    setChatPresence, setIsChatLoaded, setMyConversation,
} from "../../../store/slices/conversations";
import {dateToPubnubTimetoken, pubnubTimetokenToDate} from "../../../utilities/helpers";
import {PubNubManager} from "../../../utilities/pubnubManager";
import {
    selectIsLatestMessagesFetched,
    selectLatestMessages,
    setLatestMessages
} from "../../../store/slices/messages";
import dayjs from "dayjs";
import Spinner from "../../../components/Spinner";
import { Virtuoso } from 'react-virtuoso'
import {httpFetchChatChannels} from "../../../http-requests/messages";
import {models} from "../../../models";
import {selectUser} from "../../../store/slices/user";


const useStyles = createUseStyles((theme) => ({
    messagesPanel: {
        zIndex: 2,
        width: '100%',
        minHeight: '90vh',
        maxHeight: '90vh',
        // overflow: 'scroll',
        display: 'grid',
        gridTemplateColumns: '1fr',
        gridTemplateRows: 'min-content 1fr',
        backgroundColor: '#ffffff',
        boxShadow:
            '0px 1px 3px rgba(20, 20, 42, 0.1), 0px 0px 1px rgba(20, 20, 42, 0.05)',
        [theme.mediaQueries.mUp]: {
            // minHeight: 200,
            maxWidth: 636,
            borderRadius: [12, 0, 0, 0],
        },
    },
    listWrapper: {
        overflowY: 'hidden',
        display: 'grid',
        '& > div:not(:last-child)': {
            borderBottom: '1px solid #F3F3F3',
        },
    },
    list: {
        height: '100%',
        overflowY: 'scroll',
        paddingBottom: 80,
    },
    endRef: {
        height: 1,
        // border: '1px solid red',
    },
    conversationsFooter: {
        padding: '1rem',
        textAlign: 'center',
        fontSize: 12,
        fontWeight: 500,
        color: '#AEAEAE',
        letterSpacing: 0.25,
    }
}))

// TODO: rename to MyConversationPanel
const MessagesPanel = ({extended, isMobilePage, onClose, channelUUID}) => {
    const [searchInput, setSearchInput] = useState('')
    const [isScrolling, setIsScrolling] = useState(false);
    const [isFetchingPresence, setIsFetchingPresence] = useState(false);
    const [searchedChannels, setSearchedChannels] = useState([]);

    const endRef = useRef(null)
    const pubnub = usePubNub()

    const dispatch = useDispatch()
    const myConversationsByUserId = useSelector(selectMyConversations)
    const myChannelIDs = useSelector(selectMyChannelIDs)
    const isLatestMessageFetched = useSelector(selectIsLatestMessagesFetched)
    const latestMessages = useSelector(selectLatestMessages)
    const isPresenceFetched = useSelector(selectIsPresenceFetched)
    const user = useSelector(selectUser)
    const {currentPage, lastPage, total} = myConversationsByUserId

    useEffect(
        () => {
            if(!searchInput && !!searchedChannels.length) setSearchedChannels([])
        },
        [searchInput,searchedChannels]
    )

    // users online / offline
    const getPresence = async () => {
        try {
            setIsFetchingPresence(true)
            const {channels: channelsPresence, totalChannels, totalOccupancy} =
                await PubNubManager.getChannelsPresence({pubnub, channels: myChannelIDs})
            dispatch(setChatPresence(channelsPresence))
        } catch (status) {
            console.log(status);
        }finally {
            setIsFetchingPresence(false)
        }
    }

    useEffect(() => {
        if(Object.keys(pubnub).length && !isFetchingPresence){
            getPresence()
        }
    }, [pubnub, myChannelIDs.length])


    // latest messages for conversation previews
    const loadLatestMessages = async () => {
        try {
            const channels = await PubNubManager.fetchMessageHistory({
                pubnub,
                channels: myChannelIDs,
                count: 1
            })
            let normalizedChannels = {}
            Object.keys(channels).forEach(channelID => normalizedChannels[channelID] = channels[channelID][0])
            dispatch(setLatestMessages(normalizedChannels))
        }catch (err){
            console.log('[loadLatestMessages]', err)
        }
    }
    useEffect(
        () => {
            if(!isLatestMessageFetched &&
                Object.keys(pubnub).length &&
                !!myChannelIDs.length
            ){
                loadLatestMessages()
            }
        },
        [isLatestMessageFetched, pubnub, myChannelIDs]
    )

    // sort conversations by last message
    const myConversationsArrSortedByDate = useMemo(() => {
        return myConversationsByUserId.allIds
            .map((userID, idx) => {
                const conversation = myConversationsByUserId.byId[userID]
                const latestMessageInChannel = latestMessages[conversation.channel]
                // Add last date and last text
                const openChannelDate = myConversationsByUserId.byId[userID].createdAt &&
                    dayjs(myConversationsByUserId.byId[userID].createdAt)
                const latestMessageDate =
                    latestMessageInChannel?.timetoken || dateToPubnubTimetoken(openChannelDate)
                const latestMessageContent = latestMessageInChannel?.message

                return {
                    ...conversation,
                    latestMessageDate,
                    latestMessage: latestMessageContent,
                }
            })
            // sort by last message or creation date
            .sort(
                (a, b) => {
                    const secondMessageDate = b.latestMessageDate && pubnubTimetokenToDate(b.latestMessageDate)
                    const firstMessageDate = a.latestMessageDate && pubnubTimetokenToDate(a.latestMessageDate)
                    return secondMessageDate?.valueOf() - firstMessageDate?.valueOf()
                }

            )
    }, [myConversationsByUserId, latestMessages])



    const loadConversations = async (page = 0) => {
        if(!!searchInput) return // if search active, don't call pagination again
        try{
            if(currentPage < lastPage){
                const {data: {data, current_page, last_page, total, per_page}} = await httpFetchChatChannels({page: page +1, limit: 30})
                const myConversations = data.map(conversation => models.conversations(
                    {data: conversation, extra: {myID: user.id}}
                ))
                dispatch(setMyConversation({data: myConversations, current_page, last_page, total, per_page}))
            }

        } catch (err){
            console.log('[loadConversations]', err)
        }
    }

    const loadSearchConversations = async (results) => {
        const mySearchConversations = results.map(conversation => models.conversations(
            {data: conversation, extra: {myID: user.id}}
        ))
        setSearchedChannels(mySearchConversations )
    }

    const classes = useStyles({isMobilePage, extended, searchInput})


    return (
        <div className={classes.messagesPanel}>
            <MessagesPanelHeader
                extended={extended}
                isMobilePage={isMobilePage}
                onClose={onClose}
            >
                <AsyncSearchbar
                    fetchLimit={50}
                    placeholder={'Search'}
                    apiCall={httpFetchChatChannels}
                    searchInput={(input) => setSearchInput(input)}
                    searchResults={loadSearchConversations}
                />
            </MessagesPanelHeader>

            <div className={classes.listWrapper}>
                <div className={classes.list}>
                    {
                        isLatestMessageFetched ?
                            <Virtuoso
                                components={{
                                    Footer: () => {
                                        return (
                                            <div className={classes.conversationsFooter}>
                                                {searchedChannels.length || total} conversations
                                            </div>
                                        )
                                    },
                                }}
                                endReached={() => loadConversations(currentPage)}
                                isScrolling={setIsScrolling}
                                data={(searchInput && searchedChannels) || myConversationsArrSortedByDate}
                                itemContent={(index, conversation) => {
                                    let isActive = conversation?.channel === channelUUID
                                    return (
                                        <MessageToast
                                            key={conversation?.channel}
                                            user={conversation}
                                            conversation={conversation}
                                            onClose={onClose}
                                            isActive={isActive}
                                            channelUUID={channelUUID}
                                        />
                                    )
                                }}
                            />

                            :
                            <Spinner/>
                    }
                    <div ref={endRef} className={classes.endRef}/>
                </div>
            </div>
        </div>
    )
}

export default MessagesPanel
