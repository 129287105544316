import { createUseStyles } from 'react-jss'
import PostDescription from '../post-description'
import ResponsiveVideo from '../../../../../../components/ResponsiveVideo'

const useStyles = createUseStyles((theme) => ({
    content: {
        display: 'grid',
        gridTemplateRows: 'auto 1fr',
        gap: 8,
    },
}))

const PostVideoContent = ({ content, media }) => {
    const classes = useStyles({})

    return (
        <div className={classes.content}>
            <PostDescription text={content} />
            <ResponsiveVideo video={media[0]?.original} />
        </div>
    )
}

export default PostVideoContent
