import { createUseStyles } from 'react-jss'
import { AlertIcon } from '../theme/icons'
import Button from './Button'

const useStyles = createUseStyles((theme) => ({
    paymentError: {
        padding: [0, 16, 16],
        display: 'grid',
        alignItems: 'center',
        justifyItems: 'center',
        textAlign: 'center',
        '& h4': {
            color: theme.palette.secondary.main,
            margin: [8, 0],
        },
        '& p': {
            marginBottom: 24,
        },
    },
}))

const PaymentError = ({ onClose }) => {
    const classes = useStyles()

    return (
        <div className={classes.paymentError}>
            <AlertIcon width={80} stroke={'#6B2D66'} />
            <h4>Payment Error</h4>
            <p>
                An error occurred during payment process. Retry or contact our
                support.
            </p>

            <Button
                width={'100%'}
                variant={'filled'}
                type={'button'}
                onClick={onClose}
            >
                Close
            </Button>
        </div>
    )
}

export default PaymentError
