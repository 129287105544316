import { createUseStyles } from 'react-jss'
import React, { useEffect, useState } from 'react'
import Button from '../../../../components/Button'
import Popover from '../../../../components/Popover'
import { SuccessFramedIcon } from '../../../../theme/icons'
import { useSelector } from 'react-redux'
import Spinner from '../../../../components/Spinner'
import { ROUTE_FANPAGE } from '../../../../utilities/constants'
import { selectUser } from '../../../../store/slices/user'
import { useNavigate, useParams } from 'react-router-dom'
import { useWindowSize } from '../../../../hooks/useWindowSize'

const useStyles = createUseStyles((theme) => ({
    root: {
        color: theme.palette.secondary.main,
    },
    bold: {
        fontWeight: 700,
    },
    body: {
        ...theme.utils.flexbox.centered,
        flexDirection: 'column',
        gap: theme.spacing,
        gridTemplateColumns: '1fr',
        padding: theme.spacing * 2,
    },
    action: {
        borderTop: `1px solid ${theme.palette.grey[300]}`,
        padding: theme.spacing * 2,
    },
    title: {
        fontSize: 28,
        fontWeight: 700,
        color: theme.palette.primary.background,
        '& p': {
            marginBottom: 8,
        }
    },
}))

const PostSuccessModal = ({ onClose }) => {
    const navigate = useNavigate()
    const { username } = useParams()
    const me = useSelector(selectUser)
    const [isLoading, setIsLoading] = useState(false)
    const { width } = useWindowSize()
    const [isMobile, setIsMobile] = useState(width <= 448)

    useEffect(() => {
        setIsMobile(width <= 448)
    }, [width])

    const handleClick = async () => {
        if (username !== me?.username) {
            navigate(ROUTE_FANPAGE.replace(':username', me?.username))
        } else {
            window.location.reload()
        }
    }

    const classes = useStyles()
    return (
        <Popover onClose={onClose} title={true} maxWidth={isMobile ? 326 : 420}>
            {isLoading && <Spinner />}
            <div className={classes.root}>
                <div className={classes.body}>
                    <SuccessFramedIcon />
                    <span className={classes.title}>Success</span>
                    <p>Your post is successfully published!</p>
                </div>
                <div className={classes.action}>
                    <Button width={'100%'} onClick={handleClick}>
                        {username !== me?.username ? 'Click here' : 'Refresh'}{' '}
                        to see your post
                    </Button>
                </div>
            </div>
        </Popover>
    )
}

export default PostSuccessModal
