import * as yup from 'yup'

const DELETE_MESSAGE_MODEL = {
    forAll: 'for_all',
}

const deleteMessageValidationSchema = yup.object().shape({
    [DELETE_MESSAGE_MODEL.forAll]: yup.mixed(),
})

export {
    DELETE_MESSAGE_MODEL,
    deleteMessageValidationSchema as validationSchema,
}
