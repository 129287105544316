import { createUseStyles } from 'react-jss'
import { InfoIcon } from '../theme/icons'
import PropTypes from 'prop-types'
import cx from 'classnames'

const useStyles = createUseStyles((theme) => ({
    banner: ({ variant, data, height, iconSize }) => ({
        ...theme.utils.grid.centered,
        ...theme.utils.grid.gapM,
        textAlign: 'left',
        gridTemplateColumns: 'auto 1fr',
        width: '100%',
        minHeight: height ?? 56,
        padding: [0, 18],
        borderRadius: 12,
        color: '#6426E1',
        border: '2px solid #F9F6FE',
        backgroundColor: '#F9F6FE',
        textDecoration: 'none',
        ...(variant === 'error' && {
            color: theme.palette.primary.middle,
            backgroundColor: theme.palette.primary.buttonLight,
        }),
        fontSize: 12,
        '& svg': {
            height: iconSize,
        },
        '& a': {
            textDecoration: 'none',
            color: theme.palette.label.color,
            fontWeight: 400,
            cursor: 'pointer',
        },
    }),
}))

const Banner = ({
    data = 'tertiary',
    text,
    url,
    icon = <InfoIcon />,
    iconSize = 20,
    variant = 'default',
    height,
    className,
    children,
}) => {
    const classes = useStyles({ variant, data, height, iconSize })
    return (
        <div className={cx(classes.banner, className)}>
            {icon}
            {text}
            {url && (
                <a href={url} target={'_blank'}>
                    {url}
                </a>
            )}
            {children}
        </div>
    )
}

export default Banner

Banner.propTypes = {
    data: PropTypes.oneOf(['primary', 'secondary', 'tertiary']),
}
