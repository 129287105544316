import {createUseStyles} from 'react-jss'
import Box from '../../../../components/Box'
import ToggleSwitch from '../../../../components/ToggleSwitch'
import Tooltip from '../../../../components/Tooltip'
import colors from '../../../../theme/colors'
import { POST_MODEL } from '../postModel'
import React from 'react'
import { useFormContext } from 'react-hook-form'
import {InfoFilledIcon} from "../../../../theme/icons";

const useStyles = createUseStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing * 2,
        margin: [0, theme.spacing * 2],
        '& > p': {
            fontSize: 14,
        },
    },
    box: {
        ...theme.utils.flexbox.spaceBetween,
        padding: [0, theme.spacing * 2],
        gridTemplateColumns: 'auto auto',
        '& svg': {
            height: 25,
        },
    },
    infoWrapper: {
        ...theme.utils.flexbox.centered,
        gap: theme.spacing * 2,
        userSelect: ' none',
        '& > p': {
            fontSize: 16,
            fontWeight: 500,
            color: colors.tertiary[800],
        },
    },
}))

const PublicPostSection = ({ isPremium }) => {
    const classes = useStyles()

    const {
        register,
        formState: { errors, touchedFields },
    } = useFormContext()

    return (
        <div className={classes.root}>
            <p>
                Your media is available for all your subscribers, or you can
                choose to make this a paid post premium.
            </p>
            <Box classNames={classes.box}>
                <div className={classes.infoWrapper}>
                    <Tooltip
                        content={'This post will be visible to all platform users'}
                    >
                        <InfoFilledIcon />
                    </Tooltip>
                    <p>Public Post</p>
                </div>
                <ToggleSwitch
                    inputType={'checkbox'}
                    touched={touchedFields[POST_MODEL.isPublic]}
                    errors={errors[POST_MODEL.isPublic]}
                    {...register(POST_MODEL.isPublic)}
                    value={true}
                    disabled={isPremium}
                />
            </Box>
        </div>
    )
}

export default PublicPostSection
