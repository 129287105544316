import { createUseStyles } from 'react-jss'
import colors from '../../../../theme/colors'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import cx from 'classnames'
import { useSelector } from 'react-redux'
import { selectUser } from '../../../../store/slices/user'
import { useWindowSize } from '../../../../hooks/useWindowSize'
import { httpFetchActivities } from '../../../../http-requests'
import {
    handleApiError,
    remapUnsubscribeReason,
} from '../../../../utilities/helpers'
import SubscriptionCardLoader from '../my-subscriptions/subscription-card-loader'
import SubscriptionCard from '../my-subscriptions/subscription-card'
import {
    httpPaymentsFans,
    httpPaymentsSubscriptions,
} from '../../../../http-requests/payments'
import dayjs from 'dayjs'

const useStyles = createUseStyles((theme) => ({
    root: {
        background: colors.common.white,
        boxShadow:
            '0px 1px 3px rgba(20, 20, 42, 0.1), 0px 0px 1px rgba(20, 20, 42, 0.05)',
        borderBottomLeftRadius: 12,
        borderBottomRightRadius: 12,
        padding: '16px',
        position: 'relative',
        marginBottom: 16,
    },
    loader: {
        position: 'relative',
        marginBottom: 16,
        padding: [16],
    },
    filters: {
        display: 'grid',
        alignItems: 'center',
        gridColumnGap: 8,
        gridAutoColumns: 'max-content',
        gridAutoFlow: 'column',
        '& p': {
            fontSize: 16,
            color: '#808080',
        },
    },
    label: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: [4, 8],
        cursor: 'pointer',
        fontSize: 12,
        height: 28,
        borderRadius: 28,
        width: 'auto',
        border: '1px solid #F3F3F3',
        backgroundColor: theme.palette.common.white,
        color: theme.palette.label.color,
    },
    labelActive: {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.secondary.main,
    },
    scrollable: {
        maxHeight: 'calc(100vh - 340px)',
        overflowY: 'scroll',
        '&::-webkit-scrollbar': {
            display: 'none',
        },
        padding: [0, 2],
    },
}))

const MyFans = () => {
    const [filter, setFilter] = useState(null)
    //pagination
    const endRef = useRef(null)
    const { width } = useWindowSize()
    const loaderArray = Array.from(Array(10).keys())
    const isMobile = width <= 468
    //loader
    const [isLoading, setIsLoading] = useState(false)
    const [isLoadingMore, setIsLoadingMore] = useState(false)
    const [myFans, setMyFans] = useState([])
    const [currentPage, setCurrentPage] = useState(0)
    const [lastPage, setLastPage] = useState(0)
    const [perPage, setPerPage] = useState(0)
    const [filterCount, setFilterCount] = useState({})
    const fetchLimit = 15
    const fetchMoreCondition = currentPage < lastPage
    //classes
    const classes = useStyles({ isMobile })

    useEffect(() => {
        fetchInitial()
        return () => setMyFans([])
    }, [])

    const getMyFans = async (page, filter = null) => {
        setIsLoading(true)
        try {
            const {
                data: {
                    data,
                    all_subscriptions_count,
                    active_subscriptions_count,
                    inactive_subscriptions_count,
                    expired_subscriptions_count,
                    to_renew_subscriptions_count,
                    current_page,
                    last_page,
                    per_page,
                },
            } = await httpPaymentsFans(page, fetchLimit, filter)
            if (current_page === 1) {
                setMyFans([...data])
            } else {
                setMyFans([...myFans, ...data])
            }
            setCurrentPage(current_page)
            setLastPage(last_page)
            setPerPage(+per_page)
            setFilterCount({
                total: all_subscriptions_count,
                active: active_subscriptions_count,
                inactive: inactive_subscriptions_count,
                expired: expired_subscriptions_count,
                to_renew: to_renew_subscriptions_count,
            })
        } catch (error) {
            handleApiError({
                isReduxError: false,
                error,
            })
        } finally {
            setIsLoading(false)
        }
    }

    const fetchInitial = async () => {
        setIsLoading(true)
        await getMyFans(1)
        setIsLoading(false)
    }

    const fetchMore = async () => {
        if (fetchMoreCondition) {
            setIsLoadingMore(true)
            await getMyFans(currentPage + 1)
            setIsLoadingMore(false)
        } else if (currentPage < lastPage && perPage < fetchLimit) {
            fetchInitial()
        }
    }

    const intersectionObserver = useMemo(
        () =>
            new IntersectionObserver(
                (entries) => {
                    const first = entries[0]
                    if (first.isIntersecting) {
                        fetchMore()
                    }
                },
                {
                    threshold: 0,
                    rootMargin: '0px 0px 100px 0px',
                }
            ),
        [myFans, currentPage, lastPage]
    )

    useEffect(() => {
        if (endRef.current) {
            intersectionObserver.observe(endRef.current)
        }
        return () => {
            if (endRef.current) {
                intersectionObserver.unobserve(endRef.current)
            }
        }
    }, [endRef.current, intersectionObserver])

    const onFilter = (filter) => {
        setFilter(filter)
        getMyFans(1, filter)
    }

    return (
        <>
            <div className={classes.root}>
                <div className={classes.filters}>
                    <p>Filter for:</p>
                    <span
                        className={cx(
                            classes.label,
                            !filter && classes.labelActive
                        )}
                        onClick={() => onFilter(null)}
                    >
                        All: {filterCount?.total}
                    </span>
                    <span
                        className={cx(
                            classes.label,
                            filter === 'active' && classes.labelActive
                        )}
                        onClick={() =>
                            filter !== 'active' && onFilter('active')
                        }
                    >
                        Active: {filterCount?.active}
                    </span>
                    <span
                        className={cx(
                            classes.label,
                            filter === 'inactive' && classes.labelActive
                        )}
                        onClick={() =>
                            filter !== 'inactive' && onFilter('inactive')
                        }
                    >
                        Inactive: {filterCount?.inactive}
                    </span>
                    <span
                        className={cx(
                            classes.label,
                            filter === 'to_renew' && classes.labelActive
                        )}
                        onClick={() =>
                            filter !== 'to_renew' && onFilter('to_renew')
                        }
                    >
                        To renew: {filterCount?.to_renew}
                    </span>
                    <span
                        className={cx(
                            classes.label,
                            filter === 'expired' && classes.labelActive
                        )}
                        onClick={() =>
                            filter !== 'expired' && onFilter('expired')
                        }
                    >
                        Expired: {filterCount?.expired}
                    </span>
                </div>
            </div>

            <div className={classes.scrollable}>
                {isLoading &&
                    loaderArray.map((loader, index) => (
                        <SubscriptionCardLoader
                            key={index}
                            classNames={classes.loader}
                        />
                    ))}
                {myFans.map((subscription, index) => {
                    return (
                        <div key={index}>
                            <SubscriptionCard
                                subscribedAt={
                                    dayjs(subscription?.created_at).isValid() &&
                                    dayjs(subscription?.created_at).format(
                                        'MM/DD/YY'
                                    )
                                }
                                expiredAt={
                                    dayjs(subscription?.expired_at).isValid() &&
                                    dayjs(subscription?.expired_at).format(
                                        'MM/DD/YY'
                                    )
                                }
                                cost={`$${subscription?.dollar_price}`}
                                status={subscription?.status}
                                reason={remapUnsubscribeReason(
                                    subscription?.unsubscription_reason
                                )}
                                user={subscription?.subscriber}
                                isMyFans={true}
                            />
                        </div>
                    )
                })}

                {fetchMoreCondition && <div ref={endRef} />}
                {isLoadingMore &&
                    loaderArray.map((loader, index) => (
                        <SubscriptionCardLoader
                            key={index}
                            classNames={classes.loader}
                        />
                    ))}
            </div>
        </>
    )
}

export default MyFans
