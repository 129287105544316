import { createUseStyles } from 'react-jss'
import Divider from '../../../../components/Divider'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { WALLET_MODEL, walletStepTwoSchema } from '../WalletModel'
import Button from '../../../../components/Button'
import colors from '../../../../theme/colors'
import { SideArrowIcon } from '../../../../theme/icons'
import Select from '../../../../components/Select'
import { useDispatch, useSelector } from 'react-redux'
import {
    selectCurrentWallet,
    selectNetworks,
    setCurrentWallet,
} from '../../../../store/slices/wallet'
import { handleApiError } from '../../../../utilities/helpers'
import {
    httpGetNetworksStandard,
    httpStoreWalletsNetworks,
} from '../../../../http-requests/wallet'
import { useEffect, useState } from 'react'
import Spinner from '../../../../components/Spinner'

const useStyles = createUseStyles((theme) => ({
    root: {
        padding: 16,
        '& p': {
            marginBottom: 16,
            '& b': {
                fontSize: 16,
                fontWeight: 700,
                color: theme.palette.secondary.dark,
            },
        },
    },
    fields: {
        display: 'grid',
        gap: 16,
    },
    select: {
        '& > div': {
            padding: [4, 0],
        }
    },
    next: {
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        right: 16,
    },
}))

const NetworkStep = ({
    setNetworkStandardsCb,
    setPopoverStepCb,
    isNetworkStepEdit,
}) => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const networks = useSelector(selectNetworks)
    const currentWallet = useSelector(selectCurrentWallet)
    const [loading, setLoading] = useState(false)
    const {
        handleSubmit,
        control,
        formState: { errors, isSubmitting },
    } = useForm({
        shouldUnregister: true,
        mode: 'onBlur',
        reValidateMode: 'onChange',
        nativeValidation: false,
        defaultValues: {},
        resolver: yupResolver(walletStepTwoSchema),
    })

    const onSubmitHandler = async (values) => {
        setLoading(true)
        try {
            const { data: standard } = await httpGetNetworksStandard(
                values?.[WALLET_MODEL.networkId].value
            )
            setNetworkStandardsCb(standard)
            const { data: walletNetwork } = await httpStoreWalletsNetworks(
                currentWallet?.id,
                values?.[WALLET_MODEL.networkId].value,
                standard[0]?.id
            )
            dispatch(
                setCurrentWallet({
                    ...currentWallet,
                    wallet_networks: [walletNetwork],
                })
            )
            setPopoverStepCb(3)
        } catch (error) {
            handleApiError({
                isReduxError: false,
                error,
            })
        } finally {
            setLoading(false)
        }
    }

    return (
        <div className={classes.root}>
            {loading && <Spinner />}
            <p>
                <b>STEP {isNetworkStepEdit ? '1' : '2'}: </b>
                Set up a Network for your Wallet
            </p>
            <form onSubmit={handleSubmit(onSubmitHandler)}>
                <div className={classes.fields}>
                    <Select
                        control={control}
                        label={'Network Type*'}
                        placeholder={'Select the Network'}
                        name={WALLET_MODEL.networkId}
                        options={networks}
                        errors={errors[WALLET_MODEL.networkId]}
                        singleValueCustomStyle={multiValueLabelCustomStyle}
                        menuCustomStyle={menuCustomStyle}
                        className={classes.select}
                    />
                </div>
                <Divider />
                <Button
                    disabled={isSubmitting}
                    width={'100%'}
                    type={'submit'}
                    variant={'filled'}
                >
                    Continue
                    <span className={classes.next}>
                        <SideArrowIcon data-color />
                    </span>
                </Button>
            </form>
        </div>
    )
}

const menuCustomStyle = {
    textTransform: 'uppercase',
}

const multiValueLabelCustomStyle = {
    color: '#6B2D66',
    fontWeight: 700,
    fontSize: 14,
    textTransform: 'uppercase',
    letterSpacing: 0.5,
}

export default NetworkStep
