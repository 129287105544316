import { createUseStyles } from 'react-jss'
import colors from '../../../theme/colors'
import ActivitiesGroup from './activities-group'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
    fetchActivities,
    resetActivities,
    selectActivitiesCurrentPage,
    selectActivitiesLastPage,
    selectActivitiesPerPage,
    selectUserActivities,
} from '../../../store/slices/user'
import Spinner from '../../../components/Spinner'
import dayjs from 'dayjs'
import ActivityLoader from './activities-group/activity-loader'

const useStyles = createUseStyles((theme) => ({
    sectionTitle: {
        fontWeight: 'bold',
        fontSize: '18px',
        lineHeight: '140%',
        color: colors.tertiary[800],
    },
    desc: {
        marginTop: 8,
        marginBottom: 24,
    },
    scrollable: {
        height: 'calc(100vh - 265px)',
        overflow: 'scroll',
        '&::-webkit-scrollbar': {
            display: 'none',
        },
        padding: '4px',
    },
    loaderBox: {
        marginBottom: 16,
    },
}))

const Activities = () => {
    const [isLoading, setIsLoading] = useState(false)
    const [isLoadingMore, setIsLoadingMore] = useState(false)
    const dispatch = useDispatch()
    const endRef = useRef(null)
    const activities = useSelector(selectUserActivities)
    const [mappedActivities, setMappedActivities] = useState({})
    // Pagination
    const fetchLimit = 12
    const currentPage = useSelector(selectActivitiesCurrentPage)
    const lastPage = useSelector(selectActivitiesLastPage)
    const perPage = useSelector(selectActivitiesPerPage)
    const fetchMoreCondition = currentPage < lastPage && perPage >= fetchLimit
    const showBottomLoader = currentPage < lastPage
    const classes = useStyles()

    useEffect(() => {
        if (!currentPage) {
            fetchInitial()
        }
        return () => dispatch(resetActivities())
    }, [])

    useEffect(() => {
        if (activities?.length >= 0) {
            //transform created at in a groupable format
            let transformedData = activities?.map((item) => ({
                ...item,
                simple_date: dayjs(item?.created_at).format('DD MMMM YYYY'),
            }))
            //group by
            let newActivities = transformedData.reduce(function (r, a) {
                r[a.simple_date] = r[a.simple_date] || []
                r[a.simple_date].push(a)
                return r
            }, Object.create(null))
            setMappedActivities({ ...newActivities })
        }
    }, [activities])

    const fetchInitial = async () => {
        setIsLoading(true)
        await dispatch(fetchActivities({ page: 1, limit: fetchLimit }))
        setIsLoading(false)
    }

    const fetchMore = async () => {
        if (fetchMoreCondition) {
            setIsLoadingMore(true)
            await dispatch(
                fetchActivities({ page: currentPage + 1, limit: fetchLimit })
            )
            setIsLoadingMore(false)
        } else if (currentPage < lastPage && perPage < fetchLimit) {
            fetchInitial()
        }
    }

    const intersectionObserver = useMemo(
        () =>
            new IntersectionObserver(
                (entries) => {
                    const first = entries[0]
                    if (first.isIntersecting) {
                        fetchMore()
                    }
                },
                {
                    threshold: 0,
                    rootMargin: '0px 0px 100px 0px',
                }
            ),
        [activities, currentPage, lastPage]
    )

    useEffect(() => {
        if (endRef.current) {
            intersectionObserver.observe(endRef.current)
        }
        return () => {
            if (endRef.current) {
                intersectionObserver.unobserve(endRef.current)
            }
        }
    }, [endRef.current, intersectionObserver])

    return (
        <>
            <p className={classes.sectionTitle}>Comment and likes</p>
            <p className={classes.desc}>
                Here is the list of your activities on happyfans
            </p>
            <div className={classes.scrollable}>
                {isLoading && <Spinner overlayFullscreen={true} />}
                {mappedActivities &&
                    Object.keys(mappedActivities).map((key, index) => {
                        return (
                            <ActivitiesGroup
                                date={key}
                                logs={mappedActivities[key]}
                                key={index}
                            />
                        )
                    })}
                {showBottomLoader && <div ref={endRef} />}
                {isLoadingMore && (
                    <>
                        {[0, 1, 2, 4].map((index) => (
                            <ActivityLoader
                                key={index}
                                classNames={classes.loaderBox}
                            />
                        ))}
                    </>
                )}
            </div>
        </>
    )
}

export default Activities
