import {
    CardIcon,
    CryptoWalletIcon,
    KeyIcon,
    ListIcon,
    ListIcons,
    NotificationSettingsIcon,
    ProfileIcon,
    TrashIcon,
    UserHeartIcon,
} from '../../theme/icons'

export const PROFILE = 'profile'
export const CHANGE_PASSWORD = 'change-password'
export const WALLET = 'wallet'
export const PAYMENTS = 'payments'
export const ACTIVITIES = 'activities'
export const DELETE_ACCOUNT = 'delete-account'
export const SUBSCRIPTION_SETTINGS = 'subscription-settings'
export const NOTIFICATIONS_SETTINGS = 'notifications-settings'

export const CREATOR_SETTINGS_SECTIONS = [
    {
        link: `/settings/${PROFILE}`,
        icon: <ProfileIcon data-color width={24} className={'withStroke'} />,
        label: 'Profile',
    },
    {
        link: `/settings/${CHANGE_PASSWORD}`,
        icon: <KeyIcon data-color width={24} className={'withStroke'} />,
        label: 'Change password',
    },
    {
        link: `/settings/${WALLET}`,
        icon: <CryptoWalletIcon data-color width={24} className={'onlyPath'} />,
        label: 'Crypto Wallet',
    },
    {
        link: `/settings/${PAYMENTS}`,
        icon: <CardIcon data-color width={24} className={'onlyPath'} />,
        label: 'Payments',
    },
    {
        link: `/settings/${SUBSCRIPTION_SETTINGS}`,
        icon: <UserHeartIcon data-color width={24} className={'onlyPath'} />,
        label: 'Subscription Settings',
    },
    {
        link: `/settings/${NOTIFICATIONS_SETTINGS}`,
        icon: (
            <NotificationSettingsIcon
                data-color
                width={24}
                className={'onlyStroke'}
            />
        ),
        label: 'Notifications Settings',
    },
    {
        link: `/settings/${ACTIVITIES}`,
        icon: <ListIcon data-color width={24} className={'onlyPath'} />,
        label: 'Activities',
    },
    {
        link: `/settings/${DELETE_ACCOUNT}`,
        icon: <TrashIcon data-color width={24} className={'withStroke'} />,
        label: 'Delete Account',
    },
]

export const FAN_SETTINGS_SECTIONS = [
    {
        link: `/settings/${PROFILE}`,
        icon: <ProfileIcon data-color width={24} className={'withStroke'} />,
        label: 'Profile',
    },
    {
        link: `/settings/${CHANGE_PASSWORD}`,
        icon: <KeyIcon data-color width={24} className={'withStroke'} />,
        label: 'Change password',
    },
    {
        link: `/settings/${WALLET}`,
        icon: <CryptoWalletIcon data-color width={24} className={'onlyPath'} />,
        label: 'Crypto Wallet',
    },
    {
        link: `/settings/${PAYMENTS}`,
        icon: <CardIcon data-color width={24} className={'onlyPath'} />,
        label: 'Payments',
    },
    {
        link: `/settings/${NOTIFICATIONS_SETTINGS}`,
        icon: (
            <NotificationSettingsIcon
                data-color
                width={24}
                className={'onlyStroke'}
            />
        ),
        label: 'Notifications Settings',
    },
    {
        link: `/settings/${ACTIVITIES}`,
        icon: <ListIcon data-color width={24} className={'onlyPath'} />,
        label: 'Activities',
    },
    {
        link: `/settings/${DELETE_ACCOUNT}`,
        icon: <TrashIcon data-color width={24} className={'withStroke'} />,
        label: 'Delete Account',
    },
]
