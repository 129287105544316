import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles((theme) => ({
    dotLoader: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 24,
    },
    dotFlashing: {
        position: 'relative',
        width: 10,
        height: 10,
        borderRadius: 5,
        backgroundColor: '#9880ff',
        color: '#9880ff',
        animation: '$dotFlashing 1s infinite linear alternate',
        animationDelay: '0.5s',
        '&::before': {
            content: '""',
            display: 'inline-block',
            position: 'absolute',
            top: 0,
            left: -15,
            width: 10,
            height: 10,
            borderRadius: 5,
            backgroundColor: '#9880ff',
            color: '#9880ff',
            animation: '$dotFlashing 1s infinite linear alternate',
            animationDelay: '0s',
        },
        '&::after': {
            content: '""',
            display: 'inline-block',
            position: 'absolute',
            top: 0,
            left: 15,
            width: 10,
            height: 10,
            borderRadius: 5,
            backgroundColor: '#9880ff',
            color: '#9880ff',
            animation: '$dotFlashing 1s infinite linear alternate',
            animationDelay: '1s',
        },
    },
    '@keyframes dotFlashing ': {
        '0%': {
            backgroundColor: ' #9880ff',
        },
        '100%': {
            backgroundColor: ' #ebe6ff',
        },
    },
}))

const DotsLoader = () => {
    const classes = useStyles()
    return (
        <div className={classes.dotLoader}>
            <div className={classes.dotFlashing}></div>
        </div>
    )
}
export default DotsLoader
