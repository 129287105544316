import { createUseStyles, useTheme } from 'react-jss'
import cx from 'classnames'
import { SearchIcon } from '../../../theme/icons'
import { components } from 'react-select'
import React, { useCallback, useEffect, useState } from 'react'
import { ROUTE_FANPAGE, ROUTE_SEARCH } from '../../../utilities/constants'
import { useNavigate } from 'react-router-dom'
import { httpSearchUser } from '../../../http-requests'
import UserInfo from '../../homepage/posts-feed/post/post-header/user-info'
import { searchbarCustomStyle } from './searchbarCustomStyle'
import { useWindowSize } from '../../../hooks/useWindowSize'
import AsyncSelect from 'react-select/async'
import { asyncDebounce } from '../../../utilities/helpers'

const useStyles = createUseStyles((theme) => ({
    searchbar: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        cursor: 'pointer',
        padding: [0, 14],
        maxWidth: '100%',
        maxHeight: '100%',
        height: 48,
        borderRadius: 100,
        backgroundColor: theme.palette.disabled.light,
        [theme.mediaQueries.mUp]: {
            width: 280,
        },
    },
    showAllWrapper: {
        paddingTop: theme.spacing,
        [theme.mediaQueries.mUp]: {
            paddingTop: 0,
            borderTop: `1px solid ${theme.palette.divider.external}`,
        },
    },
    showAll: {
        display: 'flex',
        placeContent: 'center',
        alignItems: 'center',
        gap: theme.spacing,
        cursor: 'pointer',
        textAlign: 'center',
        color: theme.palette.primary.middle,
        fontSize: 14,
        height: 56,
        letterSpacing: 0.75,
        '& span': {
            wordBreak: 'break-word',
            fontWeight: 700,
        },
        [theme.mediaQueries.mUp]: {
            borderRadius: 8,
            marginTop: theme.spacing,
            '&:hover': {
                backgroundColor: '#FAFAFA',
            },
        },
    },
    listItem: {
        padding: theme.spacing,
        width: '100%',
        cursor: 'pointer',
        '& div': {
            pointerEvents: 'none',
        },
    },
}))

const CreatorSearchbar = ({
                              className,
                              maxDigits,
                              placeholder,
                              onRedirect,
                              ...props
                          }) => {
    const theme = useTheme()
    const navigate = useNavigate()
    const [total, setTotal] = useState(0)
    const customStyles = searchbarCustomStyle(theme)
    const { width } = useWindowSize()
    const [isMobile, setIsMobile] = useState(width <= 448)
    const [savedInput, setSavedInput] = useState('')
    const [cachedOptions, setCachedOptions] = useState([])

    const fetchLimit = 4
    const searchInputLimit = 50
    const classes = useStyles({})

    useEffect(() => {
        setIsMobile(width <= 448)
    }, [width])

    const getSearchResults = useCallback(async (name) => {
        if (!name || name.length >= searchInputLimit) {
            return []
        }
        try {
            const {
                data: { data, total },
            } = await httpSearchUser(
                {
                    name: name,
                    page: 1,
                    limit: fetchLimit
                })
            const mappedOptions = remapCreatorApiOptions(data)
            setTotal(total)
            setCachedOptions(mappedOptions)
            return mappedOptions
        } catch (error) {
            console.log(error)
        }
    }, [classes])

    const remapCreatorApiOptions = (options) => {
        return options?.map(({ id, display_name, username, category, avatar, is_online }) => ({
            value: id,
            label: (
                <div
                    // previous className={classes.listItem} didn't get recognized
                    // after build, to prevent this usually we put "classes"
                    // as useCallback dependency, in this case doing so
                    // would have prevented debounce from working
                    style={{
                        padding: 8, width: '100%',
                        cursor: 'pointer',
                        '& div': {
                            pointerEvents: 'none',
                        },
                    }}
                    onClick={() => {
                        navigate(
                            `${ROUTE_FANPAGE.replace(':username', username)}`,
                        )
                        onRedirect && onRedirect()
                    }}
                >
                    <UserInfo
                        avatar={avatar?.[128]}
                        name={display_name}
                        username={username}
                        category={category?.name}
                        usernameMaxWidth={100}
                        isOnline={is_online}
                    />
                </div>
            ),
        }))
    }

    const debouncedGetSearchResults = useCallback(
        asyncDebounce(getSearchResults, 500),
        [],
    )

    const handleRedirect = (inputValue) => {
        !!inputValue && navigate(ROUTE_SEARCH.replace(':name', inputValue))
        onRedirect && onRedirect()
    }

    const handleInputChange = (inputValue, { action }) => {
        if (
            action !== 'input-blur' &&
            action !== 'menu-close' &&
            inputValue?.length <= searchInputLimit
        ) {
            setSavedInput(inputValue)
        } else if (!inputValue && !savedInput) {
            setTotal(0)
            setCachedOptions([])
        }
    }

    const handleKeyPress = (e) => {
        if (e?.keyCode === 13 && e?.target?.value) {
            handleRedirect(e?.target?.value)
        }
    }

    const CustomMenuList = ({ children, ...props }) => {
        const inputValue = props.selectProps.inputValue
        return (
            <components.MenuList {...props}>
                {children}
                <div className={classes.showAllWrapper}>
                    <div
                        className={classes.showAll}
                        onClick={() => handleRedirect(inputValue)}
                    >
                        <div className={classes.textWrapper}>
                            {isMobile ? (
                                <div>
                                    Show all results for:{' '}
                                    <span>{inputValue}</span>
                                </div>
                            ) : (
                                <div>
                                    <span>{inputValue}</span> ({total}{' '}
                                    {total === 1 ? 'result' : 'results'})
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </components.MenuList>
        )
    }

    return (
        <div className={cx(classes.searchbar, className)} {...props}>
            <AsyncSelect
                //cacheOptions={cachedOptions}
                // menuIsOpen={true}
                // isLoading={true}
                defaultOptions={cachedOptions}
                inputValue={savedInput}
                loadOptions={debouncedGetSearchResults}
                controlShouldRenderValue={false}
                styles={customStyles}
                placeholder={'Search on Happyfans'}
                onKeyDown={handleKeyPress}
                onInputChange={handleInputChange}
                components={{
                    ValueContainer: CustomContainer,
                    MenuList: CustomMenuList,
                    DropdownIndicator: CustomDropdown,
                    NoOptionsMessage: CustomNoOptionsMessage,
                }}
            />
        </div>
    )
}

const CustomContainer = ({ children, classes, ...props }) => (
    <components.ValueContainer {...props}>
        <SearchIcon />
        {children}
    </components.ValueContainer>
)

const CustomDropdown = ({ children, ...props }) => (
    <components.DropdownIndicator {...props}>
        {children}
        <hr />
    </components.DropdownIndicator>
)

const CustomNoOptionsMessage = ({ children, ...props }) => (
    <components.DropdownIndicator {...props}>
        <span>No results</span>
    </components.DropdownIndicator>
)

export default CreatorSearchbar
