import { useMemo } from 'react'
import { MEDIA_POST_TYPES } from '../../../../../utilities/constants'
import MediaPostImage from './media-post-content/media-post-image'
import MediaPostVideo from './media-post-content/media-post-video'
import MediaPostLocked from './media-post-content/media-post-locked'
import { useSelector } from 'react-redux'
import { selectUser } from '../../../../../store/slices/user'

const MediaPost = ({
    className,
    size = '1920-1080',
    subtitle,
    thumbSize = 128,
    user,
    ...rest
}) => {
    const loggedUser = useSelector(selectUser)
    const { post, type: mediaType } = rest

    const type = useMemo(() => {
        /* Check permission */
        const isPublic = !post?.is_subscription_related && post?.price === 0
        const isMine = loggedUser?.id === user?.id
        const isBought = post?.is_paid || isMine
        const isAlwaysAllowed = isMine || isPublic || isBought

        if (
            !isAlwaysAllowed &&
            !isMine &&
            (!!post?.price || user?.subscription?.status !== 'active')
        ) {
            return MEDIA_POST_TYPES.locked
        } else {
            return mediaType
        }
    }, [mediaType, post?.is_subscription_related, post?.price, loggedUser])

    switch (type) {
        case MEDIA_POST_TYPES.image:
            return <MediaPostImage {...rest} size={size} subtitle={subtitle} />
        case MEDIA_POST_TYPES.video:
            return <MediaPostVideo {...rest} subtitle={subtitle} />
        default:
            return <MediaPostLocked user={user} post={post} />
    }
}

export default MediaPost
