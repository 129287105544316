import { createUseStyles } from 'react-jss'
import Button from '../../../components/Button'
import { CameraIcon, SmileIcon, VideoIcon } from '../../../theme/icons'
import { useState } from 'react'
import PostCreationModal from './post-creation-modal'
import Input from '../../../components/Input'
import PostSuccessModal from './post-success-modal'
import UserAvatar from '../../homepage/posts-feed/post/post-header/user-info/user-avatar'

const useStyles = createUseStyles((theme) => ({
    root: {
        ...theme.utils.grid.centered,
        gridTemplateColumns: '1fr',
        gridTemplateRows: '1fr 1fr',
        borderRadius: 16,
        boxShadow:
            ' 0px 1px 3px rgba(20, 20, 42, 0.1), 0px 0px 1px rgba(20, 20, 42, 0.05)',
        maxWidth: 636,
        margin: '0 auto 16px auto',
        backgroundColor: '#FFF',
    },
    inputWrapper: {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing,
        borderBottom: `1px solid ${theme.palette.divider.internal}`,
        padding: theme.spacing * 2,
    },
    input: {
        display: 'flex',
        alignItems: 'center',
        flexGrow: 1,
        justifyContent: 'space-between',
        cursor: 'pointer',
        '& input': {
            height: 48,
        },
        '& svg': {
            fill: 'none !important',
        },
    },
    buttonWrapper: {
        display: 'flex',
        gap: theme.spacing * 2,
        padding: theme.spacing * 2,
    },
}))

const PostCreation = ({ user }) => {
    const [postCreationModalOpen, setPostCreationModalOpen] = useState(false)
    const [photoUpload, setPhotoUpload] = useState(false)
    const [videoUpload, setVideoUpload] = useState(false)
    const [isCreationSuccessful, setIsCreationSuccessful] = useState(false)

    const classes = useStyles()
    return (
        <>
            <div className={classes.root}>
                <div className={classes.inputWrapper}>
                    <UserAvatar
                        avatar={user?.avatar?.[128]}
                        isOnline={user?.is_online}
                        username={user?.username}
                    />
                    <Input
                        placeholder={'Compose new post'}
                        icon={<SmileIcon />}
                        iconPosition={'right'}
                        rounded={true}
                        width={'100%'}
                        onClick={() => setPostCreationModalOpen(true)}
                        className={classes.input}
                    />
                </div>
                <div className={classes.buttonWrapper}>
                    <Button
                        variant={'borderless'}
                        isLight={true}
                        iconPosition={'left'}
                        width={'100%'}
                        icon={<VideoIcon />}
                        onClick={() => {
                            setPostCreationModalOpen(true)
                            setPhotoUpload(true)
                        }}
                    >
                        VIDEO
                    </Button>
                    <Button
                        variant={'borderless'}
                        isLight={true}
                        iconPosition={'left'}
                        data={'secondary'}
                        width={'100%'}
                        icon={<CameraIcon />}
                        onClick={() => {
                            setPostCreationModalOpen(true)
                            setVideoUpload(true)
                        }}
                    >
                        PHOTO
                    </Button>
                </div>
            </div>
            {postCreationModalOpen && (
                <PostCreationModal
                    user={user}
                    photoUpload={photoUpload}
                    videoUpload={videoUpload}
                    onClose={() => setPostCreationModalOpen(false)}
                    onSuccess={() => {
                        setIsCreationSuccessful(true)
                    }}
                />
            )}
            {isCreationSuccessful && (
                <PostSuccessModal
                    onClose={() => setIsCreationSuccessful(false)}
                />
            )}
        </>
    )
}

export default PostCreation
