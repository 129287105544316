export const EXT_SUPPORT_LINK = `https://happyfans.club/support/`
export const EXT_TERMS_CONDITIONS = 'https://happyfans.club/terms/'
export const EXT_PRIVACY_POLICY_COOKIE_POLICY =
    'https://happyfans.club/privacy/'
export const EXT_COOKIE_POLICY =
    'https://www.iubenda.com/privacy-policy/67061878/cookie-policy'
export const EXT_TOKEN_BUY = 'https://happyfans.club/swap'
export const EXT_ACCEPTABLE_USE_POLICY =
    'https://happyfans.club/acceptable-use-policy/'
export const EXT_COMMUNITY_GUIDELINES =
    'https://happyfans.club/community-guidelines/'
export const EXT_COPYRIGHT =
    'https://happyfans.club/copyright-and-trademark-policy/'
