import { createUseStyles } from 'react-jss'
import UserAvatar from '../../../post-header/user-info/user-avatar'
import Button from '../../../../../../../components/Button'
import TextArea from '../../../../../../../components/TextArea'
import { useForm } from 'react-hook-form'
import { COMMENT_MODEL, validationSchema } from '../comment/commentModel'
import { handleApiError, saferun } from '../../../../../../../utilities/helpers'
import { yupResolver } from '@hookform/resolvers/yup'
import { DevTool } from '@hookform/devtools'
import EmojiInput from '../../../../../../../components/EmojiInput'
import { httpReplyToComment } from '../../../../../../../http-requests/comment'
import { updateTimelinePostCommentCount } from '../../../../../../../store/slices/timeline'
import { useDispatch, useSelector } from 'react-redux'
import { httpCommentPost } from '../../../../../../../http-requests'
import { selectUser } from '../../../../../../../store/slices/user'

const useStyles = createUseStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        padding: ({ isSmall }) => (isSmall ? 8 : 16),
        gap: 4,
        backgroundColor: theme.palette.grey[100],
        borderRadius: ({ isSmall }) => (isSmall ? 100 : 0),
    },
    wrapper: {
        flex: 1,
    },
    textAreaWrapper: {
        display: 'flex',
        '& textarea': {
            border: 'none',
            padding: 8,
        },
    },
    textActions: {
        display: 'flex',
        alignItems: 'center',
        gap: 8,
        '& > span:not(:last-child)': {
            paddingRight: 8,
            borderRight: `1px solid ${theme.palette.grey[300]}`,
        },
    },
    emojiInput: {},
    sendButton: {
        borderRadius: 100,
        fontSize: 14,
    },
}))

const CommentInput = ({ postID, parentCommentID, isSmall, submitCallback }) => {
    const user = useSelector(selectUser)
    const dispatch = useDispatch()

    const {
        register,
        handleSubmit,
        reset,
        setError,
        setValue,
        getValues,
        control,
        formState: { errors, isSubmitting },
    } = useForm({
        mode: 'onBlur',
        reValidateMode: 'onChange',
        nativeValidation: false,
        defaultValues: {
            [COMMENT_MODEL.message]: '',
        },
        resolver: yupResolver(validationSchema),
    })

    const onReplyToComment = async (values) => {
        try {
            const { data } = await httpReplyToComment({
                ...values,
                parentCommentID,
            })
            saferun(submitCallback, data)
            reset()
        } catch (error) {
            handleApiError({
                isReduxError: false,
                error,
                callbackOnFieldError: setError,
            })
        }
    }

    const onPostComment = async (values) => {
        try {
            const { data } = await httpCommentPost({ ...values, postID })
            saferun(submitCallback, data)
            dispatch(updateTimelinePostCommentCount({ postID }))
            reset()
        } catch (error) {
            handleApiError({
                isReduxError: false,
                error,
                callbackOnFieldError: setError,
            })
        }
    }

    const classes = useStyles({ isSmall })

    return (
        <form
            onSubmit={handleSubmit(
                parentCommentID ? onReplyToComment : onPostComment
            )}
        >
            <div className={classes.root}>
                <UserAvatar
                    width={32}
                    height={32}
                    avatar={user?.avatar?.[128]}
                    username={user?.username}
                />
                <TextArea
                    rootClassName={classes.wrapper}
                    className={classes.textAreaWrapper}
                    inputProps={{}}
                    placeholder={'Write a comment...'}
                    {...register(COMMENT_MODEL.message)}
                />
                <div className={classes.textActions}>
                    <span className={classes.emojiInput}>
                        <EmojiInput
                            onSelection={(textWithEmoji) =>
                                setValue(COMMENT_MODEL.message, textWithEmoji)
                            }
                            getCurrentValue={() =>
                                getValues(COMMENT_MODEL.message)
                            }
                        />
                    </span>
                    <span>
                        <Button
                            data={'secondary'}
                            className={classes.sendButton}
                            size={'small'}
                            width={68}
                            height={32}
                            variant={'filled'}
                            type={'submit'}
                            disabled={isSubmitting}
                        >
                            Send
                        </Button>
                    </span>
                </div>
            </div>
            {process.env.NODE_ENV === 'development' && (
                <DevTool control={control} />
            )}
        </form>
    )
}

export default CommentInput
