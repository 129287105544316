import { createUseStyles } from 'react-jss'
import UserAvatar from '../../../../../homepage/posts-feed/post/post-header/user-info/user-avatar'
import colors from '../../../../../../theme/colors'
import { PAYMENT_TYPES } from '../../../paymentsTypes'

const useStyles = createUseStyles((theme) => ({
    root: {
        display: 'grid',
        gridAutoFlow: 'column',
        gridTemplateColumns: '15% 29% 15% 10% 10% auto',
        gridColumnGap: 16,
        alignItems: 'center',
        height: 40,
        padding: [0, 8],
        backgroundColor: ({ index }) =>
            index % 2 === 0 ? '#F9F9F9' : '#FCFCFC',
        '& > span': {
            fontSize: 10,
            color: colors.greyscale[600],
        },
        '& > a': {
            fontSize: 10,
            fontWeight: 400,
            color: colors.primary[500],
        },
    },
    centered: {
        placeSelf: 'center',
    },
    address: {
        maxWidth: '100%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
}))

const SpentTableRow = ({
    user,
    date,
    token,
    amount,
    type,
    externalID,
    index,
}) => {
    const classes = useStyles({ index })

    return (
        <div className={classes.root}>
            <span>{date}</span>
            <span className={classes.address}>
                <a
                    href={`https://bscscan.com/tx/${externalID}`}
                    target={'_blank'}
                >
                    {externalID}
                </a>
            </span>
            <span>{PAYMENT_TYPES[type]}</span>
            <span className={classes.centered}>
                <UserAvatar width={24} height={24} avatar={token?.avatar?.[128]} />
            </span>
            <span className={classes.centered}>${amount}</span>
            <span className={classes.centered}>
                <UserAvatar
                    width={24}
                    height={24}
                    username={user?.username}
                    avatar={user?.avatar?.[128]}
                />
            </span>
        </div>
    )
}

export default SpentTableRow
