import {createUseStyles} from 'react-jss'
import Popover from '../../../components/Popover'
import Button from '../../../components/Button'
import React from 'react'
import {UnsubscribeSVG} from '../asset/UnsubscribeSVG'
import {httpMultipleSubscribe} from '../../../http-requests/subscription'
import {handleApiError} from '../../../utilities/helpers'
import {userMe} from '../../../store/slices/user'
import {useDispatch} from 'react-redux'
import {ROUTE_HOME} from '../../../utilities/constants'
import {useNavigate} from 'react-router-dom'

const useStyles = createUseStyles((theme) => ({
    root: {},
    body: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: theme.spacing,
        padding: theme.spacing * 2,
        textAlign: "center",
        '& h1': {
            fontSize: 28,
            color: theme.palette.text.primary,
            letterSpacing: 0.25,
        },
    },
    action: {
        borderTop: `1px solid ${theme.palette.grey[300]}`,
        padding: theme.spacing * 2,
    },
}))

const UnsubscribeAlertModal = ({onClose, userList, token}) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const unsubscribeFromAll = async () => {
        const dataToSend = {
            subscriptions_to_delete : userList,
            token_id: token?.id,
        }
        try {
            await httpMultipleSubscribe(dataToSend)
            await dispatch(userMe())
            navigate(ROUTE_HOME)
        } catch (error) {
            handleApiError({
                isReduxError: false,
                error,
            })
        }
    }

    const classes = useStyles()

    return (
        <Popover onClose={onClose} maxWidth={539} closeOnOverlay={false}>
            <div className={classes.root}>
                <div className={classes.body}>
                    <UnsubscribeSVG/>
                    <h1>Unsubscribe Alert</h1>
                    <p>
                        Do you want to delete all subscriptions to your
                        creators' channels? Are you sure? In this way you won't
                        be their fun anymore, but you will keep following them
                        as a follower.
                    </p>
                </div>
                <div className={classes.action}>
                    <Button width={'100%'} onClick={unsubscribeFromAll}>
                        I confirm to unsubscribe
                    </Button>
                </div>
            </div>
        </Popover>
    )
}

export default UnsubscribeAlertModal
