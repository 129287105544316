import axios from '../utilities/axios'

export const httpSubscribeToUser = ({ userID }) => {
    const url = `/users/${userID}/subscribe`
    return axios.post(url)
}

export const httpMultipleSubscribe = (usersList) => {
    const url = `/users/multiple-subscribe`
    return axios.post(url, usersList)
}

export const httpUnsubscribeToUser = ({ userID, reason }) => {
    const url = `/users/${userID}/unsubscribe`
    return axios.delete(url, { params: { reason_code: reason } })
}

export const httpUnsubscribeReasons = () => {
    const url = `/public/unsubscription-reasons`
    return axios.get(url)
}

export const httpGetDiscountLabels = () => {
    const url = `/bundles/percentages`
    return axios.get(url)
}

export const httpGetDurationLabels = () => {
    const url = `/bundles/months`
    return axios.get(url)
}

export const httpStoreBundles = ({discount_percentage, duration_in_months}) => {
    const url = `/profile/bundles`
    return axios.post(url, {discount_percentage, duration_in_months})
}

export const httpListBundles = ({user_id}) => {
    const url = `/users/${user_id}/bundles`
    return axios.get(url)
}

export const httpDeleteBundles = ({bundle_id}) => {
    const url = `/profile/bundles/${bundle_id}`
    return axios.delete(url)
}


