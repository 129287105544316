import { createUseStyles } from 'react-jss'
import logo from '../asset/images/logo.png'
import {
    ROUTE_FANPAGE,
    ROUTE_LOGIN,
    ROUTE_SIGNUP,
} from '../utilities/constants'
import { Link, useNavigate } from 'react-router-dom'
import React, { useState } from 'react'
import { selectUser } from '../store/slices/user'
import { useSelector } from 'react-redux'
import Menu from './Menu'
import UserAvatar from '../pages/homepage/posts-feed/post/post-header/user-info/user-avatar'
import Button from './Button'
import CreatorSearchbar from '../pages/search-page/creator-searchbar/CreatorSearchbar'
import NotificationsMenuIcon from '../pages/notifications-page/NotificationsMenuIcon'
import MessagesMenuIcon from '../pages/messages-page/MessagesMenuIcon'

const useStyles = createUseStyles((theme) => ({
    header: {
        ...theme.utils.flexbox.spaceBetween,
        gridTemplateColumns: '1fr auto',
        padding: [0, theme.spacing * 2],
        alignItems: 'center',
        height: 80,
        boxShadow: '0px -1px 0px 0px #0000000D inset',
        backgroundColor: '#FCFCFC',
        position: 'absolute',
        width: '100%',
        left: 0,
        top: 0,
        '& img': {
            maxWidth: 160,
        },
        [theme.mediaQueries.mUp]: {
            padding: [0, 24],
        },
        [theme.mediaQueries.lUp]: {
            padding: [0, 72],
        },
    },
    info: {
        ...theme.utils.flexbox.centered,
        gap: theme.spacing * 2,
        '& svg': {
            cursor: 'pointer',
        },
    },
    userInfo: {
        backgroundColor: theme.palette.common.white,
        border: `1px solid ${theme.palette.disabled.light}`,
        padding: [6, 12, 6, 8],
        borderRadius: 100,
        display: 'flex',
        gap: 8,
        alignItems: 'center',
        color: theme.palette.label.color,
        fontWeight: 500,
        fontSize: 12,
        letterSpacing: 0.5,
        cursor: 'pointer',
    },
    iconWrapper: {
        ...theme.utils.flexbox.centered,
        gap: 8,
    },
    logo: {
        cursor: 'pointer',
    },
    contextSection: {
        '& > span': {
            color: theme.palette.tertiary.main,
            gridTemplateColumns: 'auto 1fr',
            '&:not(:last-child)': {
                borderBottom: `1px solid ${theme.palette.grey[300]}`,
            },
            '&:hover': {
                backgroundColor: theme.palette.tertiary.light,
            },
        },
    },
    link: {
        color: theme.palette.secondary.main,
    },
    divider: {
        height: 24,
        borderRight: `1px solid ${theme.palette.divider.external}`,
    },
}))

const Header = () => {
    const navigate = useNavigate()
    const user = useSelector(selectUser)
    const [activePanel, setActivePanel] = useState(null)

    const handlePanelQueue = (panel) => {
        if (activePanel === panel) {
            setActivePanel(null)
        } else {
            setActivePanel(panel)
        }
    }

    const classes = useStyles()
    return (
        <header className={classes.header}>
            <div className={classes.logo}>
                <img
                    src={logo}
                    alt={'Happyfans'}
                    onClick={() => navigate(ROUTE_LOGIN)}
                />
            </div>
            <div className={classes.info}>
                {user ? (
                    <>
                        <CreatorSearchbar />
                        <div className={classes.divider} />
                        <div
                            className={classes.userInfo}
                            onClick={() =>
                                navigate(
                                    `${ROUTE_FANPAGE.replace(
                                        ':username',
                                        user?.username
                                    )}`
                                )
                            }
                        >
                            <UserAvatar
                                avatar={user?.avatar?.[128]}
                                height={40}
                                width={40}
                                withBoxShadow={false}
                                username={user?.username}
                                isOnline={true}
                            />{' '}
                            {user?.username}
                        </div>
                        <div className={classes.iconWrapper}>
                            <MessagesMenuIcon
                                callback={() => handlePanelQueue('messages')}
                                isOpen={activePanel === 'messages'}
                            />
                            <NotificationsMenuIcon
                                callback={() =>
                                    handlePanelQueue('notifications')
                                }
                                isOpen={activePanel === 'notifications'}
                            />
                            <Menu
                                callback={() => handlePanelQueue('menu')}
                                isOpen={activePanel === 'menu'}
                            />
                        </div>
                    </>
                ) : (
                    <>
                        <Link className={classes.link} to={ROUTE_LOGIN}>
                            Log In
                        </Link>
                        <Button
                            width={140}
                            size={'medium'}
                            variant={'filled'}
                            onClick={() => navigate(ROUTE_SIGNUP)}
                        >
                            Sign-Up
                        </Button>
                    </>
                )}
            </div>
        </header>
    )
}

export default Header
