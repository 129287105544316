import { createUseStyles } from 'react-jss'
import { useDispatch, useSelector } from 'react-redux'
import Alert from '../../components/Alert'
import {
    selectAlertMessage,
    selectIsPremiumPostModalOpen,
    selectIsSubscriptionModalOpen,
    selectIsUnsubscribeModalOpen,
    selectSubscriptionModalUser,
    setPremiumPostModalOpen,
    setSubscriptionModalOpen,
    setUnsubscribeModalOpen,
    toggleAlert,
} from '../../store/slices/app'
import Header from '../../components/Header'
import { useWindowSize } from '../../hooks/useWindowSize'
import HeaderMobile from '../../components/HeaderMobile'
import { useEffect, useRef, useState } from 'react'
import useLocationChange from '../../hooks/useLocationChange'
import { selectIsUpdatingUserData, userMe } from '../../store/slices/user'
import { Outlet } from 'react-router-dom'
import SubscriptionModal from '../../pages/creator-page/subscription-modal'
import UnsubscribeModal from '../../pages/creator-page/creator-cover/unsubscribe-button/unsubscribe-modal'
import PremiumPostModal from '../../pages/creator-page/premium-post-modal/PremiumPostModal'

const useStyles = createUseStyles((theme) => ({
    root: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        '&::-webkit-scrollbar': {
            display: 'none',
        },
    },
    container: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        margin: '0 auto',
        width: '100%',
        maxWidth: 1140, //bootstrap xl
        [theme.mediaQueries.mUp]: {
            padding: ({ isMobile, withHeader }) =>
                `${
                    !withHeader ? 0 : isMobile ? 20 : theme.sizing.headerHeight
                }px 10px 0px 10px`,
        },
    },
    alerts: {
        pointerEvents: 'none',
        position: 'fixed',
        top: 68,
        left: 8,
        right: 8,
        zIndex: theme.zIndex.snackbar,
        [theme.mediaQueries.mUp]: {
            top: 132,
        },
    },
}))

const PrivateLayout = ({ withHeader }) => {
    const isFetchingMe = useSelector(selectIsUpdatingUserData)
    const dispatch = useDispatch()
    const isSubscriptionModalOpen = useSelector(selectIsSubscriptionModalOpen)
    const isUnsubscribeModalOpen = useSelector(selectIsUnsubscribeModalOpen)
    const isPremiumPostModalOpen = useSelector(selectIsPremiumPostModalOpen)

    const user = useSelector(selectSubscriptionModalUser)

    useLocationChange((location) => {
        if (!isFetchingMe) {
            dispatch(userMe())
        }
    })

    const {
        variant: alertVariant,
        message: alertMessage,
        title: alertTitle,
        visible: alertVisbile,
    } = useSelector(selectAlertMessage)

    const { width } = useWindowSize()
    const [isMobile, setIsMobile] = useState(width <= 448)
    useEffect(() => {
        setIsMobile(width <= 448)
    }, [width])

    const classes = useStyles({ withHeader, isMobile })

    return (
        <div className={classes.root}>
            {withHeader && (isMobile ? <HeaderMobile /> : <Header />)}
            <div className={classes.alerts}>
                {alertVisbile && (
                    <Alert
                        visible={alertVisbile}
                        variant={alertVariant}
                        message={alertMessage}
                        title={alertTitle}
                        dismissTimeout={5000}
                        dismissCallback={() =>
                            dispatch(toggleAlert({ visible: false }))
                        }
                    />
                )}
            </div>
            <div className={classes.container}>
                <Outlet />
            </div>
            {isSubscriptionModalOpen && (
                <SubscriptionModal
                    user={user}
                    onClose={() =>
                        dispatch(
                            setSubscriptionModalOpen({
                                isOpen: false,
                                user: null,
                            })
                        )
                    }
                />
            )}
            {isUnsubscribeModalOpen && (
                <UnsubscribeModal
                    user={user}
                    onClose={() =>
                        dispatch(
                            setUnsubscribeModalOpen({
                                isOpen: false,
                                user: null,
                            })
                        )
                    }
                />
            )}
            {isPremiumPostModalOpen && (
                <PremiumPostModal
                    user={user}
                    onClose={() =>
                        dispatch(
                            setPremiumPostModalOpen({
                                isOpen: false,
                                user: null,
                            })
                        )
                    }
                />
            )}
        </div>
    )
}

export default PrivateLayout
